import { Flex } from "@chakra-ui/layout";
import { Button, IconButton } from "@chakra-ui/react";
import { ArrowFatLinesLeft, ArrowFatLinesRight } from "@phosphor-icons/react";

const MAX_BUTTONS = 7;
const MAX_LEFT_BUTTONS = (MAX_BUTTONS - 1) / 2;

type PaginationProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
};

export function Pagination({ page, setPage, totalPages }: PaginationProps) {
  const maxValueFirst = Math.max(totalPages - (MAX_BUTTONS - 1), 1);
  const firstPage = Math.min(
    Math.max(page - MAX_LEFT_BUTTONS, 1),
    maxValueFirst
  );

  return (
    <Flex w="full" justifyContent="center" gap={3}>
      <IconButton
        size="sm"
        bg="green.600"
        _hover={{
          bg: "green.700",
        }}
        color="whiteAlpha.900"
        aria-label="Ir para a primeira página"
        icon={<ArrowFatLinesLeft />}
        _disabled={{
          opacity: 0.3,
          cursor: "not-allowed",
        }}
        isDisabled={page === 1}
        onClick={() => setPage(1)}
      />
      {Array.from({ length: Math.min(MAX_BUTTONS, totalPages) }).map(
        (_, index) => (
          <Button
            size="sm"
            bg={index + firstPage === page ? "green.800" : "green.600"}
            _hover={{
              bg: "green.700",
            }}
            color="whiteAlpha.900"
            key={index}
            _disabled={{
              opacity: 0.3,
              cursor: "not-allowed",
            }}
            isDisabled={index + firstPage === page}
            onClick={() => setPage(index + firstPage)}
          >
            {index + firstPage}
          </Button>
        )
      )}
      <IconButton
        size="sm"
        bg="green.600"
        _hover={{
          bg: "green.700",
        }}
        color="whiteAlpha.900"
        aria-label="Ir para a última página"
        icon={<ArrowFatLinesRight />}
        isDisabled={page === totalPages}
        onClick={() => setPage(totalPages)}
      />
    </Flex>
  );
}
