import { useState } from "react";
import { patchWithAuth } from "../../services/basicService";
import { useToast } from "@chakra-ui/react";

type IProps = {
  closeAlert: () => void;
  refetch: () => void;
};

export function useMarkBalanceAsTransferred({ closeAlert, refetch }: IProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom",
  });

  const execute = (id: number) => {
    if (loading) return;
    setLoading(true);

    const URL = `/api/v1/userExternal/mark-balance-as-transferred/${id}`;

    patchWithAuth(URL)
      .then(() => {
        toast({
          status: "success",
          title: "Requisição realizada com sucesso",
          description: "Saldo marcado como transferido",
        });

        refetch();

        closeAlert();
      })
      .catch((err) =>
        toast({
          status: "error",
          title: "Não foi possivel realizar a requisição",
          description: err.response.data.message,
        })
      )
      .finally(() => setLoading(false));
  };

  return { execute, loading };
}
