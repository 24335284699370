import { z } from "zod";
import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { useSubplazaCachedService } from "../../../../../services/api/subplazas";
import { usePlazaCachedService } from "../../../../../services/api/plaza";
import { Plaza } from "../../../../../dto/plaza-dto";
import { Subplaza } from "../../../../../dto/subplaza-dto";
import { MutateFunction } from "../../../../../services/api/use-cached-service";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { convertTimeToTimeWithTimeZone } from "../../../../../utils/date";

const buildFormFields = (plazas: Plaza[] | undefined): FormField[] => [
  {
    label: "Plaza",
    name: "plazaId",
    type: "select",
    options: plazas?.map((plaza: Plaza) => {
      const { name, id } = plaza;
      return { label: name, value: id } || [];
    }),
  },
  {
    label: "Nome",
    name: "name",
    type: "text",
  },
  {
    label: "Aceite",
    name: "acceptance",
    type: "select",
    options: [
      { value: "default", label: "padrão" },
      { value: "automatic", label: "automático" },
      { value: "manually", label: "manual" },
    ],
  },
  {
    label: "Publicar agendamento antes (dias)",
    name: "publishScaleDaysBefore",
    type: "number",
  },
  {
    label: "Publicar agendamento às",
    name: "publishScaleAt",
    type: "time",
  },
];

const formSchema = z.object({
  name: z.string(),
  plazaId: z.string(),
  acceptance: z.string(),
  publishScaleDaysBefore: z.string().optional(),
  publishScaleAt: z.string().optional(),
});

type FormType = Subplaza & {
  acceptance: string;
  publishScaleDaysBefore?: number;
  publishScaleAt?: string;
};

function buildHandleSubmit(createSubplaza: MutateFunction) {
  return (formData: FormType) => {
    const { acceptance, publishScaleDaysBefore, publishScaleAt } = formData;
    formData.setting = {
      acceptance,
    };

    if (publishScaleDaysBefore && publishScaleAt) {
      formData.setting.publishScaleBefore = {
        daysBefore: publishScaleDaysBefore,
        at: convertTimeToTimeWithTimeZone(publishScaleAt),
      };
    }

    return createSubplaza(formData);
  };
}

interface Props {
  onCreated: () => void;
}

export function SubplazaFormCreate(props: Props) {
  const { onCreated } = props;

  const { useQueryAction: usePlazaQueryAction } = usePlazaCachedService();
  const plazaQuery = usePlazaQueryAction("filter", {});
  const plazas = plazaQuery.data;

  const { useMutationAction: useSubplazaMutationAction } =
    useSubplazaCachedService();

  const [createSubplaza, watchers] = useSubplazaMutationAction("create");

  const toast = useToast();

  useEffect(() => {
    const { status, error } = watchers;
    if (status) {
      if (status === "success") {
        onCreated();
      }
      if (status === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao criar subpraça",
          description: `Falha ao criar subpraça: ${error}`,
        });
      }
    }
  }, [onCreated, toast, watchers, watchers.status]);

  const formFields = buildFormFields(plazas);
  const handleSubmit = buildHandleSubmit(createSubplaza);

  return (
    <GenericForm
      fields={formFields}
      formSchema={formSchema}
      initialData={{}}
      actionLabel="Salvar"
      onSubmit={handleSubmit as (data: unknown) => React.ReactNode}
    />
  );
}
