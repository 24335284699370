import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomModal } from "../../../../../components/modal";
import { patchWithAuth } from "../../../../../services/basicService";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { AxiosDataError } from "../../../../../dto/axios-error-dto";
import { useCreatePayroll } from "../../../../../store/payroll.store";
import { UseFormSetValue } from "react-hook-form";
import { PaymentInputs } from "../payments-send-sheet";

export function UpdatePixKeyButton(props: {
  payrollId?: number;
  setValue?: UseFormSetValue<PaymentInputs>;
}) {
  const toast = useToast();
  const client = useQueryClient();
  const getPayrollDraftById = useCreatePayroll(
    (state) => state.getPayrollDraftById
  );

  const updateTransactionDraftPixKey = useMutation({
    mutationFn: (payrollId?: number) => {
      const baseURL = `/api/v1/transaction-draft/update-pix-key`;
      const URL = payrollId ? `${baseURL}/${payrollId}` : baseURL;
      return patchWithAuth(URL);
    },
    onSuccess: async (_, variables) => {
      toast({
        status: "success",
        title: "Atualização concluida",
        description: `Chave pix das transações atualizadas com sucesso.`,
      });

      client.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey;
          return queryKey[0] === "validated-transaction-draft";
        },
      });

      if (variables) {
        const response = await getPayrollDraftById(String(variables));

        if (response.transactionDrafts.length > 0) {
          const sortedPayments = response.transactionDrafts
            .map((item) => ({
              document: item.document,
              name: item.name,
              description: item.description,
              pix_key: item.pixKey,
              pix_type: item.pixType,
              amount: item.amountInCents / 100,
              status: item.status,
              pixErrorMessage: item.pixErrorMessage,
            }))
            .sort((a, b) => {
              if (a.status !== "validated" && b.status !== "validated") {
                return 0;
              }

              if (a.status !== "validated" && b.status === "validated") {
                return -1;
              }

              return 1;
            });

          if (props.setValue) {
            props?.setValue("payment", sortedPayments);
          }
        }
        return response;
      }
    },
    onError: (err: AxiosError) => {
      const error = err.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao atualizar",
        description: `Erro! ${error.message}`,
      });
    },
  });

  const handleUpdate = (payrollId?: number) => {
    if (updateTransactionDraftPixKey.isLoading) return;

    updateTransactionDraftPixKey.mutate(payrollId);
  };

  return (
    <CustomModal
      confirmButtonText="Atualizar"
      modalHeader="Atualização de chave pix"
      modalBody={
        <div style={{ textAlign: "center" }}>
          Essa opção atualizará a chave Pix para TODAS as transações, com base
          na chave Pix registrada para o entregador.
          <br />
          O processo pode levar alguns segundos para ser concluído.
          <br />
          <br />
          <strong>Deseja continuar?</strong>
          <br />
        </div>
      }
      buttonText="Atualizar chave pix"
      buttonProps={{ size: "lg" }}
      onConfirm={() => handleUpdate(props.payrollId)}
      isLoading={updateTransactionDraftPixKey.isLoading}
      modalIsOpen={updateTransactionDraftPixKey.isLoading}
    />
  );
}
