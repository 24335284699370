import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { convertCentsToReais } from "../../../../utils/convert-money";
import dayjs from "dayjs";
import { useState } from "react";
import { Transaction } from "../../../../dto/transactions-dto";

interface Props {
  transactions: Transaction[];
}

export function UnknownTransactions({ transactions }: Props) {
  const [page, setPage] = useState(1);

  const maxPages = Math.ceil(transactions.length / 10);

  return (
    <TableContainer
      w="full"
      display="flex"
      flexDir="column"
      alignItems="center"
      flex={1}
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      p={8}
      shadow="base"
      bg="whiteAlpha.800"
      gap={8}
    >
      <Table variant="striped" colorScheme="green">
        <Thead>
          <Tr>
            <Th flex={1}>Nome</Th>
            <Th textAlign="center">Valor (R$)</Th>
            <Th textAlign="center">Data da transação</Th>
            <Th textAlign="center">Conta destino</Th>
            <Th textAlign="center">Transaction Code</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactions.slice(0, page * 10).map((transaction) => (
            <Tr key={transaction.id}>
              <Td>{transaction.name}</Td>
              <Td textAlign="center">
                {convertCentsToReais(transaction.amount)}
              </Td>
              <Td textAlign="center">
                {dayjs(transaction.transactionDate).format("DD/MM/YYYY")}
              </Td>
              <Td textAlign="center">{transaction.accountIdTo}</Td>
              <Td textAlign="center">
                {transaction?.transactionResponse?.transactionCode}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {page < maxPages ? (
        <Button onClick={() => setPage((prev) => prev + 1)}>
          Carregar mais
        </Button>
      ) : null}
    </TableContainer>
  );
}
