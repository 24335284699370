import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { VStack, Heading, Badge, HStack, Flex, Box } from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  useColorMode,
  Stat,
  StatLabel,
  StatNumber,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { useAdvanceUserStore } from "../../../store/user-advance.store";
import { useCurrentUserStore } from "../../../store/current-user.store";
import dayjs from "dayjs";
import { Pagination } from "../../../components/pagination";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { ArrowDown, ArrowUp } from "@phosphor-icons/react";
import { colorSchemeMap, textSchemeMap } from "../../../dto/advance-user-dto";
import { convertCentsToReais } from "../../../utils/convert-money";
import { AdvanceStatus } from "../../../enums/advance-status";
import { LoadingLogo } from "../../../components/loading";
import "react-day-picker/dist/style.css";
import { formatCPF } from "../../../utils/format-document";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../services/basicService";
import { CancelProposal } from "../../../dto/cancel-proposal-dto";
import { useExportCsv } from "../../../hooks/adiantamento/use-export-csv";

const statuses = Object.values(AdvanceStatus);

const startHours = [
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const endHours = startHours.slice();
endHours.shift();
endHours.push("23:59");

const filterAdvanceFormSchema = z
  .object({
    cpf: z.string().max(11, "O CPF deve ter 11 caracteres"),
    attendant: z.string(),
    status: z.string(),
    startHour: z
      .string()
      .refine(
        (value) =>
          value === "" || /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
      ),
    endHour: z
      .string()
      .refine(
        (value) =>
          value === "" || /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
      ),
    company: z.union([z.string(), z.array(z.string())]),
  })
  .refine(
    ({ startHour, endHour }) => {
      if (startHour === "" && endHour === "") {
        return true;
      }
      const startTime = new Date(`2000-01-01T${startHour}:00`);
      const endTime = new Date(`2000-01-01T${endHour}:00`);
      return startTime <= endTime;
    },
    {
      message: `O horário de encerramento deve ser maior do que o horário inicial para aplicar o filtro.`,
      path: ["endHour"],
    }
  );

type filterAdvanceFormInputs = z.infer<typeof filterAdvanceFormSchema>;

export function Adiantamento() {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [range, setRange] = useState<DateRange | undefined>();
  const [isOrderByDesc, setisOrderByDesc] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);

  const { exportCsv, loading: loadingExportCsv } = useExportCsv();

  const advanceUserData = useAdvanceUserStore((state) => state.advanceUserData);

  const getAdvanceUserdata = useAdvanceUserStore(
    (state) => state.getAdvanceUserData
  );
  const cancelProposal = useAdvanceUserStore((state) => state.cancelProposal);
  const approvalProposal = useAdvanceUserStore(
    (state) => state.approvalProposal
  );

  const getUsersTrampay = useCurrentUserStore((state) => state.getUsersTrampay);

  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  const toast = useToast();

  const fetchAdvanceData = async () => {
    const dataForm = getValues();
    const filters = urlGenerate(dataForm);
    try {
      const advances = await getAdvanceUserdata(
        `/api/v1/userAdvance/proposal?${filters}`
      );
      return advances;
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Não foi possível buscar os dados de adiantamentos",
      });
    }
  };

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["advances", page, limit, isOrderByDesc],
    queryFn: fetchAdvanceData,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data } = useQuery({
    queryKey: ["trampay-users"],
    queryFn: getUsersTrampay,
  });

  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as { name: string; id: string }[];
    },
  });

  const handleItemsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(event.target.value));
    setPage(1);
    setisOrderByDesc(true);
  };

  const urlGenerate = (data: filterAdvanceFormInputs) => {
    const params = [];

    if (data.cpf) {
      params.push(`cpf=${data.cpf}`);
    }

    if (data.attendant) {
      params.push(`attendants=${data.attendant}`);
    }

    if (data.status !== "all" && data.status !== undefined) {
      params.push(`status=${data.status}`);
    }

    if (data.startHour) {
      params.push(`startHour=${data.startHour}`);
    }

    if (data.endHour) {
      params.push(`endHour=${data.endHour}`);
    }

    if (range) {
      params.push(`startDay=${range.from?.toISOString()}`);
    }

    if (range?.to) {
      let date = range.to.toISOString();

      if (data.startHour === "") {
        date = dayjs(range.to).toISOString();
      }

      params.push(`endDay=${date}`);
    }

    if (range?.to === undefined && range?.from !== undefined) {
      let date = range.from.toISOString();

      if (data.startHour === "") {
        date = dayjs(range.from).toISOString();
      }

      params.push(`endDay=${date}`);
    }

    if (data.company) {
      params.push(`companies=${data.company}`);
    }

    let url = `page=${page}&limit=${limit}`;

    if (isOrderByDesc) {
      url += `&orderBy=DESC`;
    } else {
      url += `&orderBy=ASC`;
    }

    url += `&${params.join("&")}`;

    return url;
  };

  const handleApproval = async (id: number) => {
    if (loading) return;

    setLoading(true);

    toast({
      status: "info",
      title: "Aprovação em andamento",
      description: "A aprovação do adiantamento esta sendo processada...",
    });

    try {
      await approvalProposal(`api/v1/userAdvance/proposal/approve`, {
        userAdvanceId: id,
      });

      refetch();

      toast({
        status: "success",
        title: "Aprovação concluída",
        description: "O adiantamento foi aprovado com sucesso!",
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro na aprovação",
        description: "Não foi possível fazer a aprovação",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async (id: number) => {
    if (loading) return;

    setLoading(true);

    toast({
      status: "info",
      title: "Cancelamento em andamento",
      description: "O cancelamento do adiantamento esta sendo processada...",
    });

    const cancelProposalBody: CancelProposal = {
      userAdvanceId: id,
    };

    try {
      await cancelProposal(
        `/api/v1/userAdvance/proposal/cancel`,
        cancelProposalBody
      );

      refetch();

      toast({
        status: "success",
        title: "Cancelamento concluído",
        description: "O adiantamento foi cancelado com sucesso!",
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro no cancelamento",
        description: "Não foi possível fazer o cancelamento",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm<filterAdvanceFormInputs>({
    resolver: zodResolver(filterAdvanceFormSchema),
  });

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Adiantamentos
      </Heading>

      {isLoading ? (
        <LoadingLogo />
      ) : (
        <>
          <Flex gap={8}>
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize="xl">Total de registros:</StatLabel>
                <StatNumber fontSize="3xl" color="green.500">
                  {advanceUserData.totalRecords}
                </StatNumber>
              </Stat>
            </Box>
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize="xl">Valor total: </StatLabel>
                <StatNumber fontSize="3xl" color="green.500">
                  {convertCentsToReais(advanceUserData.totalAmount)}
                </StatNumber>
              </Stat>
            </Box>
            <Button
              role="a"
              size="lg"
              color="white"
              onClick={() => navigate("/adiantamentos/conciliacao")}
              alignSelf="center"
            >
              Conciliação
            </Button>
            <Flex alignItems="center" gap={15}>
              <Button
                isLoading={loadingExportCsv}
                loadingText="Exportando"
                disabled={loadingExportCsv}
                role="a"
                size="lg"
                color="white"
                onClick={() =>
                  exportCsv(
                    "/api/v1/userAdvance/support/csv",
                    "adiantamentos",
                    urlGenerate(getValues())
                  )
                }
              >
                Exportar CSV
              </Button>
              <Tooltip
                hasArrow
                placement="top"
                label="Os dados exportados serão baseados no filtro dessa página"
                fontSize="md"
              >
                <Icon fontSize={20} color="black" />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex
            w="full"
            flex={1}
            p={8}
            gap={4}
            display="flex"
            flexDir="column"
            border="1px"
            borderRadius={8}
            bg={colorMode === "dark" ? "gray.700" : "whiteAlpha.800"}
            borderColor={colorMode === "dark" ? "" : "gray.300"}
            shadow="base"
          >
            <Flex
              as="form"
              flexDir="column"
              gap={4}
              id="form"
              w="full"
              onSubmit={handleSubmit(() => {
                setPage(1);
                refetch();
              })}
            >
              <Heading
                fontWeight="bold"
                textAlign="left"
                as="h2"
                fontSize="2xl"
              >
                Filtros
              </Heading>
              <Flex
                flexDir={{ base: "column", lg: "row" }}
                w="full"
                justifyContent="center"
                gap={8}
              >
                <FormControl as="fieldset" isInvalid={!!errors.cpf}>
                  <FormLabel as="legend">CPF</FormLabel>
                  <Input
                    type="text"
                    {...register("cpf")}
                    bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                    fontWeight="bold"
                    color={colorMode === "dark" ? "gray.300" : "gray.700"}
                    placeholder="Apenas números"
                    _placeholder={{
                      color: "gray.500",
                    }}
                  />
                  {errors.cpf ? (
                    <FormErrorMessage>{errors.cpf.message}</FormErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl as="fieldset" w="max-content">
                  <FormLabel as="legend">Data do pedido</FormLabel>
                  <Popover placement="right-end">
                    <PopoverTrigger>
                      <Button>Escolha a sua data</Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        bg={colorMode === "dark" ? "gray.900" : "white"}
                        p={2}
                        w="max-content"
                      >
                        <PopoverBody>
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            selected={range}
                            onSelect={setRange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </FormControl>

                <FormControl
                  as="fieldset"
                  id="hoursFilter"
                  isInvalid={!!errors.endHour}
                  gap={4}
                  display="flex"
                >
                  <Flex flex={1} flexDir="column">
                    <FormLabel as="legend">Horário de:</FormLabel>
                    <Select
                      variant="filled"
                      {...register("startHour")}
                      size="md"
                      w="full"
                    >
                      <option value="">Sem filtro</option>
                      {startHours.map((hour) => (
                        <option value={hour} key={hour}>
                          {hour}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Flex flex={1} flexDir="column">
                    <FormLabel as="legend" marginTop="2px">
                      Até:
                    </FormLabel>
                    <Select
                      variant="filled"
                      {...register("endHour")}
                      size="md"
                      w="full"
                    >
                      <option value="">Sem filtro</option>
                      {endHours.map((hour) => (
                        <option value={hour} key={hour}>
                          {hour}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  {errors.endHour ? (
                    <FormErrorMessage>
                      {errors.endHour.message}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </Flex>

              <Flex
                flexDir={{ base: "column", lg: "row" }}
                w="full"
                justifyContent="center"
                gap={8}
              >
                <FormControl as="fieldset">
                  <FormLabel as="legend">Empresa</FormLabel>
                  <Select
                    variant="filled"
                    {...register("company")}
                    size="md"
                    w="full"
                  >
                    <option value="">Todos</option>
                    {companies?.map((company) => (
                      <option value={company.id} key={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl as="fieldset">
                  <FormLabel as="legend">Atendente</FormLabel>
                  <Select
                    variant="filled"
                    {...register("attendant")}
                    size="md"
                    w="full"
                  >
                    <option value="">Todos</option>
                    {data?.map((suporte) => (
                      <option value={suporte.id} key={suporte.id}>
                        {suporte.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl as="fieldset">
                  <FormLabel as="legend">Status</FormLabel>
                  <Select
                    variant="filled"
                    {...register("status")}
                    size="md"
                    flex={1}
                  >
                    <option value="all">Todos</option>
                    {statuses.map((status) => (
                      <option value={status} key={status}>
                        {textSchemeMap[status as keyof typeof textSchemeMap]}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  type="submit"
                  form="form"
                  bg="green.600"
                  w="max-content"
                  color="whiteAlpha.900"
                  _hover={{
                    bg: "green.700",
                  }}
                  px={8}
                  size="md"
                  alignSelf="flex-end"
                  isDisabled={!(isDirty || range)}
                  isLoading={(isDirty || range) && isFetching}
                >
                  Filtrar
                </Button>
              </Flex>
            </Flex>

            <TableContainer style={{ containerType: "inline-size" }}>
              <Table colorScheme="green">
                <Thead>
                  <Tr>
                    <Th flex={1}>Nome</Th>
                    <Th textAlign="center">CPF</Th>
                    <Th textAlign="center">Empresa</Th>
                    <Th isNumeric>Valor</Th>
                    <Th isNumeric>Taxa</Th>
                    <Th
                      textAlign="center"
                      onClick={() => setisOrderByDesc(!isOrderByDesc)}
                      cursor="pointer"
                    >
                      Data de entrada
                      {isOrderByDesc ? (
                        <Icon as={ArrowDown} />
                      ) : (
                        <Icon as={ArrowUp} />
                      )}
                    </Th>
                    <Th textAlign="center">Nº Contrato</Th>
                    <Th textAlign="center">Vendido para</Th>
                    <Th textAlign="center" w="min-content">
                      Status
                    </Th>
                    <Th textAlign="center">Atendente</Th>
                    <Th textAlign="center">Ações</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {advanceUserData?.result.map((advance) => {
                    return (
                      <Tr
                        fontWeight={600}
                        cursor="pointer"
                        onClick={() => navigate(`/adiantamentos/${advance.id}`)}
                        key={advance.id}
                      >
                        <Td
                          maxW="200px"
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          textTransform={"capitalize"}
                        >
                          {advance.username
                            ? advance?.username?.toLowerCase()
                            : "-"}
                        </Td>
                        <Td textAlign="center">
                          {formatCPF(advance.document)}
                        </Td>
                        <Td textAlign="center">{advance.companyName}</Td>
                        <Td isNumeric>{convertCentsToReais(advance.amount)}</Td>
                        <Td isNumeric>
                          {convertCentsToReais(advance.amountFee)}
                        </Td>
                        <Td textAlign="center">
                          {dayjs(advance.createdAt)
                            .subtract(3, "hours")
                            .format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td textAlign="center">{advance.contract_number}</Td>
                        <Td textAlign="center">{advance.release_partner}</Td>
                        <Td textAlign="center" w="min-content">
                          <Badge
                            variant="solid"
                            px={4}
                            colorScheme={colorSchemeMap[advance.status]}
                          >
                            {textSchemeMap[advance.status]}
                          </Badge>
                        </Td>
                        <Td textAlign="center">
                          {advance.attendantName ? advance.attendantName : "-"}
                        </Td>
                        <Td>
                          {(advance.status === AdvanceStatus.VALIDATED ||
                            advance.status === AdvanceStatus.ERROR ||
                            advance.status ===
                              AdvanceStatus.PENDING_SIGNATURE ||
                            advance.status === AdvanceStatus.REVIEW) && (
                            <Flex
                              gap={2}
                              w="full"
                              justifyContent="center"
                              flexDirection="row"
                            >
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleCancel(advance.id);
                                }}
                                color="white"
                                isDisabled={loading}
                                bg="red.500"
                                _hover={{
                                  bg: "red.600",
                                }}
                              >
                                Cancelar
                              </Button>
                              {advance.status === AdvanceStatus.REVIEW && (
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleApproval(advance.id);
                                  }}
                                  color="white"
                                  isDisabled={loading}
                                >
                                  Aprovar
                                </Button>
                              )}
                            </Flex>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <HStack gap={8} pt={8} justifyContent="flex-end">
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={advanceUserData.totalPages}
              />
              <Select
                variant="filled"
                size="md"
                w="100px"
                onChange={handleItemsPerPage}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </Select>
            </HStack>
          </Flex>
        </>
      )}
    </VStack>
  );
}
