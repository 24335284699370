import { PixType } from "../enums/pix-type";

export interface Transaction {
  id: number;
  companyId: number;
  document: string;
  amount: number;
  description: string;
  type: string;
  pixType: PixType;
  pixKey: string;
  name: string;
  payrollId: number;
  transactionDraftId: number;
  uploadedFileId: number;
  userId: number;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  errorMessage: string | null;
  transactionResponse: {
    transactionCode: string;
  };
  externalId?: string;
  accountIdFrom?: number;
  accountIdTo?: number;
  debtId?: number;
  webhookLogId?: number;
  accountNameFrom?: string;
  accountNameTo?: string;
  transactionDate?: Date;
}

export interface TransactionData {
  result: Transaction[];
  totalRecords: number;
  nextPage: boolean;
  totalPages: number;
  page: number;
  limit: number;
}

export const pixErrorTranslations: { [key: string]: string } = {
  "Person not found.": "Conta não encontrada.",
  "Entry not found": "Chave pix não encontrada.",
  "Transaction not allowed due to transactional limit":
    "Transação não permitida devido a limite transacional",
  "Transaction not allowed due to transactional limit.":
    "Transação não permitida devido a limite transacional",
  "Account not available to complete the transaction.":
    "Conta não disponível para concluir a transação.",
  "Account not available to validate key. Try again in a few seconds.":
    "Conta não disponível para validar chave. Tente novamente em alguns segundos.",
  "A P2P transaction is already being processed":
    "Uma transação P2P já está sendo processada",
  "External failure while executing transaction. Try again in a few minutes":
    "Falha externa ao executar a transação. Tente novamente em alguns minutos",
  "External failure while executing transaction. Try again in a few minutes.":
    "Falha externa ao executar a transação. Tente novamente em alguns minutos",
  "Param key is required.": "Chave inválida.",
  "Param payerAnswer contain invalid caracters":
    "O campo descrição da transação não pode ter pontuação.",
  "Path or method does not exist": "Chave inválida.",
  "Transaction not allowed due to risk model or lack of regulatory":
    "Transação não permitida devido ao modelo de risco ou falta de regulamentação",
  "Transaction not approved on fraud process.":
    "Transação não aprovada em processo de fraude.",
  "Unable to send request.": "Não foi possível enviar a solicitação.",
  "Payee Account is not active": "A conta do beneficiário não está ativa",
  "Account has no balance to complete the transaction":
    "A conta não tem saldo para concluir a transação",
  "Account is not active.": "A conta não está ativa",
  "Alias account not found.": "Conta de alias não encontrada",
  "Invalid key format.": "Chave em formato inválido",
  "Destination account must be reported.": "A conta destino deve ser informada",
};
