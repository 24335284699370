import { PayrollDto } from "../../dto/payroll-dto";
import { postWithAuth } from "../basicService";
import { useBaseCrud as baseCrud } from "./base-crud";

const baseURL = "/api/v1/payroll";

const processScheduledPayrollsByCompany = async (data: {
  companyId: number;
  confirmationCode: number;
}): Promise<unknown> => {
  const response = await postWithAuth(`${baseURL}/scheduled/process/confirm`, {
    ...data,
  });
  return response?.data;
};

export const payrollService = () => {
  return {
    ...baseCrud<PayrollDto>(baseURL),
    processScheduledPayrollsByCompany,
  };
};
