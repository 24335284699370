import { acceptanceEnum } from "../enums/acceptance.enum";
import { PublishScaleBefore } from "../types/publish-scale-before.type";

export interface IPlazaSetting {
  acceptance?: string;
  publishScaleBefore?: PublishScaleBefore;
}

export interface Plaza {
  id: number;
  name: string;
  setting: IPlazaSetting;
  companyId: number;
  companyName: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export const textSchemeMap = {
  [acceptanceEnum.AUTOMATIC]: "Automático",
  [acceptanceEnum.MANUALLY]: "Manual",
  [acceptanceEnum.DEFAULT]: "Padrão",
};
