// DebtModal.tsx
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { IUserDebtDTO, debtStatusTextMap } from "../../../../dto/user-debt-dto";
import { convertCentsToReais } from "../../../../utils/convert-money";

interface DebtModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalInfo?: IUserDebtDTO;
}

export const DebtModal: React.FC<DebtModalProps> = ({
  isOpen,
  onClose,
  modalInfo,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Detalhes do débito</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            gap={8}
            w={"100%"}
            p={4}
            flexDir={"row"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
          >
            <Stack w={"full"}>
              <Text fontWeight="bold">Valor Total</Text>
              <Text>
                {" "}
                {convertCentsToReais(modalInfo?.totalAmount as number)}
              </Text>
            </Stack>
            <Stack w={"auto"}>
              <Text fontWeight="bold">Valor Restante</Text>
              <Text>
                {convertCentsToReais(modalInfo?.remainingAmount as number)}
              </Text>
            </Stack>
            <Stack w={"auto"}>
              <Text fontWeight="bold">Valor pago</Text>
              <Text>
                {convertCentsToReais(modalInfo?.paidAmount as number)}
              </Text>
            </Stack>

            <Stack w={"auto"}>
              <Text fontWeight="bold">Data do proximo agendamento</Text>
              <Text>
                {modalInfo?.nextTransferDate &&
                  format(new Date(modalInfo?.nextTransferDate), "dd/MM/yyyy")}
              </Text>
            </Stack>
            <Stack w={"auto"}>
              <Text fontWeight="bold">Data da ultima atualização</Text>
              <Text>
                {modalInfo?.updatedAt &&
                  format(new Date(modalInfo?.updatedAt), "dd/MM/yyyy")}
              </Text>
            </Stack>
            <Stack w={"auto"}>
              <Text fontWeight="bold">Status</Text>
              <Text>
                {modalInfo?.status && debtStatusTextMap[modalInfo?.status]}
              </Text>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
