import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Icon, Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";
import { ArrowDown, ArrowUp } from "@phosphor-icons/react";

const Wrapper = styled.div<{ raised?: boolean }>`
  width: full;
  height: auto;
  display: flex;

  padding: 10px;
  justify-content: left;
  align-items: center;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

export type Item = {
  id: number;
  value: unknown;
  label?: string;
};

type MultiSelectProps = {
  raised?: boolean;
  items: Item[];
  itemsDefault?: Item[];
  placeholder?: string;
};

const SelectedItemsContainer = styled.div`
  display: flex;
  border-color: gray;
  border-radius: 8px;
  align-items: center;
  padding: 4px;
  width: full;
  height: full;
`;

const SelectIcon = styled.span`
  display: "inline-block";
  align-items: center;
  width: 20px;
`;

const DropDown = styled.ul<{
  opened: boolean;
  raised?: boolean;
  wrapperClientHeight: number;
}>`
  position: absolute;
  top: ${({ wrapperClientHeight }) => wrapperClientHeight - 10}px;
  left: 0px;
  height: "inherit";
  background-color: ${({ opened }) => (opened ? "white" : "transparent")};
  border-color: gray;
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  width: full;
  padding: 4px;
  & > li {
    list-style: none;
    border-radius: 8px;
    padding: 4px;

    &:hover {
      background-color: papayawhip;
    }
  }
`;

export const MultiSelect: React.FC<
  MultiSelectProps & { onChange: (selectedItems: Item[]) => void }
> = ({
  raised = true,
  items,
  itemsDefault = [],
  placeholder = "Select an Item",
  onChange,
}) => {
  const [selectedItems, setSelectedItems] = useState<Item[]>(itemsDefault);
  const [opened, setIsOpened] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [wrapperClientHeight, setWrapperClientHeight] = useState(40);

  const onClickWrapper = () => {
    setIsOpened(!opened);
  };

  const onClickDeleteItem = (id: number) => {
    const newSelectedItems = selectedItems.filter((item) => item?.id !== id);
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems);
  };

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      if (selectedItems.length > 0) {
        const newWrapperClientHeight =
          wrapperRef && wrapperRef.current
            ? wrapperRef.current.clientHeight
            : 40;

        setWrapperClientHeight(newWrapperClientHeight);
      } else {
        setWrapperClientHeight(40);
      }
    }
  }, [selectedItems]);

  useEffect(() => {
    if (selectedItems.length === items.length) {
      setIsOpened(false);
    }
  }, [selectedItems, items]);

  const onDropDownClicked = (newItem: Item) => {
    const newSelectedItems = [...selectedItems, newItem];
    setSelectedItems([...selectedItems, newItem]);
    onChange(newSelectedItems);
  };

  const filteredItems = items?.filter(
    (item) => selectedItems.findIndex((sel) => sel?.id === item?.id) === -1
  );
  return (
    <Wrapper raised={raised} onClick={onClickWrapper} ref={wrapperRef}>
      {filteredItems?.length > 0 && (
        <SelectIcon>
          <Icon as={opened ? ArrowUp : ArrowDown} fontSize="20px" />
        </SelectIcon>
      )}
      <SelectedItemsContainer>
        {selectedItems?.length === 0 && <span>{placeholder} </span>}
        {selectedItems?.map((item) => {
          const { id, value } = item;
          const label = item.label ? item.label : value;
          return (
            <Tag
              key={id}
              padding={2}
              marginRight={2}
              bg="green.500"
              color="gray.100"
              fontSize="14"
              fontWeight="bold"
              onClick={(e) => e.stopPropagation()}
            >
              <TagLabel>
                {typeof label === "string"
                  ? (label as string)
                  : "tipo deve ser string para ser renderizado"}
              </TagLabel>
              <TagCloseButton
                onClick={(e) => {
                  e.stopPropagation();
                  onClickDeleteItem(id);
                }}
              />
            </Tag>
          );
        })}
      </SelectedItemsContainer>
      {filteredItems?.length > 0 && (
        <DropDown
          opened={opened}
          raised={raised}
          wrapperClientHeight={wrapperClientHeight}
        >
          {filteredItems?.map((item) => {
            const { id, value } = item;
            const label = item.label ? item.label : (value as string);
            return (
              <li
                key={id}
                onClick={(e) => {
                  e.stopPropagation();
                  onDropDownClicked({ id, value, label });
                }}
              >
                {" "}
                {label}
              </li>
            );
          })}
        </DropDown>
      )}
    </Wrapper>
  );
};
