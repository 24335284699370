import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { Flex, Grid, Heading } from "@chakra-ui/layout";

import { useCreateCompanyForm } from "../../../../../store/company.store";
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Permission } from "../../../../../dto/permission-dto";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

export function CreateCompanyStep2() {
  const { allPermissions, createPermissions, companyData, fetchPermissions } =
    useCreateCompanyForm(
      (state) => ({
        createPermissions: state.createPermissions,
        fetchPermissions: state.fetchPermissions,
        allPermissions: state.allPermissions,
        companyData: state.companyData,
      }),
      shallow
    );

  const navigate = useNavigate();

  const toast = useToast();

  const { data: permissions } = useQuery({
    queryKey: ["all-permissions"],
    queryFn: fetchPermissions,
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  async function onSubmit(data: { [key: string]: string }) {
    const selectedPermissions = allPermissions
      ?.filter((permission) => data[permission.key])
      .reduce(
        (
          formattedPermissions: {
            company: { id: string | undefined };
            url: string | null;
            permission: Permission;
          }[],
          permission
        ) => {
          const formattedPermission = {
            company: {
              id: companyData?.id,
            },
            url: data[`${permission.key}_url`] || null,
            permission,
          };

          formattedPermissions.push(formattedPermission);

          return formattedPermissions;
        },
        []
      );

    if (selectedPermissions) {
      try {
        await createPermissions(selectedPermissions);

        toast({
          status: "success",
          title: `Permissões criadas com sucesso`,
        });

        navigate("/companies");
      } catch (error) {
        if (error instanceof Error) {
          toast({
            status: "error",
            title: `Não foi possível criar as permissões`,
            description: `Erro ao criar permissões: ${error.message}`,
          });
          return;
        }
        toast({
          status: "error",
          title: `Não foi possível criar as permissões`,
          description: `Erro ao criar permissões`,
        });
      }
    }
  }

  function handleSkipPermissions() {
    navigate("/companies");
  }

  useEffect(() => {
    if (!companyData?.id) {
      navigate("create-company");
    }
  }, [companyData, navigate]);

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <Heading>Permissões da Empresa</Heading>
      <Flex
        as="form"
        flexDir="column"
        w="full"
        gap={8}
        flex={1}
        id="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl as="fieldset" flex={1}>
          <Flex direction={"column"} gap={2} flex={1}>
            {permissions?.map((permission) =>
              permission.canEditUrl ? (
                <Grid gridTemplateColumns="30% 1fr" key={permission.id} gap={2}>
                  <Checkbox
                    value={permission.id}
                    {...register(permission.key)}
                    colorScheme="green"
                  >
                    {permission.name}
                  </Checkbox>
                  <Input
                    color="gray.900"
                    _placeholder={{
                      color: "gray.500",
                    }}
                    {...register(`${permission.key}_url`)}
                    placeholder="URL da permissão"
                  />
                </Grid>
              ) : (
                <Checkbox
                  value={permission.id}
                  key={permission.id}
                  colorScheme="green"
                  {...register(permission.key)}
                >
                  {permission.name}
                </Checkbox>
              )
            )}
          </Flex>
        </FormControl>

        <Flex justifyContent={"flex-end"} gap={2}>
          <Button
            colorScheme="gray"
            size="lg"
            isDisabled={isSubmitting}
            onClick={handleSkipPermissions}
          >
            Pular
          </Button>
          <Button
            type="submit"
            form="form"
            size="lg"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
