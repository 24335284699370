import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../basicService";

const baseURL = "/api/v1/mail";

const processScheduledPayrollsConfirmation = async (): Promise<unknown> => {
  const response = await getWithAuth(
    `${baseURL}/process-scheduled-payrolls-confirmation`,
    {},
    {}
  );
  return response?.data;
};

export const mailService = () => {
  return {
    processScheduledPayrollsConfirmation,
  };
};

export const mailQuery = () => ({
  useProcessScheduledPayrolls: (
    onError?: (error: Error) => Promise<void> | void,
    onSuccess?: (result: unknown) => Promise<void> | void
  ) =>
    useQuery({
      queryKey: ["mails", "process-scheduled-payrolls"],
      queryFn: async () => {
        try {
          const result =
            await mailService().processScheduledPayrollsConfirmation();

          if (onSuccess) {
            onSuccess(result);
          }
          return result;
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      },
    }),
});
