export enum ProductControlScheduledPayrollStatus {
  PENDING = "pending",
  CREATED_PAYROLLS = "created_payrolls",
  SCHEDULED_PAYROLLS = "scheduled_payrolls",
  SIGNED_PAYROLLS = "signed_payrolls",
  PROCESSED_PAYROLLS = "processed_payrolls",
  JUDICIAL_RESTRICTION = "judicial_restriction",
  DEADLINE_EXCEEDED = "deadline_exceeded",
}

export const translateProductControlScheduledPayrollStatusMapper = {
  [ProductControlScheduledPayrollStatus.PENDING]: "folhas não criadas",
  [ProductControlScheduledPayrollStatus.CREATED_PAYROLLS]: "folhas criadas",
  [ProductControlScheduledPayrollStatus.SCHEDULED_PAYROLLS]: "agendado",
  [ProductControlScheduledPayrollStatus.SIGNED_PAYROLLS]: "confirmado",
  [ProductControlScheduledPayrollStatus.PROCESSED_PAYROLLS]: "processado",
  [ProductControlScheduledPayrollStatus.JUDICIAL_RESTRICTION]:
    "restrição judicial",
  [ProductControlScheduledPayrollStatus.DEADLINE_EXCEEDED]: "prazo estourado",
};
