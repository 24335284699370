import { Button, Flex, Text, useClipboard } from "@chakra-ui/react";
import { Clipboard } from "@phosphor-icons/react";

export function CopyText({ text }: { text: string }) {
  const { value, onCopy, hasCopied } = useClipboard(text);
  return (
    <Flex gap={4}>
      <Text fontSize="xl" wordBreak="break-all">
        {value}
      </Text>
      <Button onClick={onCopy}>
        {hasCopied ? "Copiado!" : <Clipboard size={24} />}
      </Button>
    </Flex>
  );
}
