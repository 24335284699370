import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { LoginJarvisForm } from "./login-jarvis-form";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLogged: () => void;
}

export function AuthValidateCode(props: ModalProps) {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" id="upload-files">
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>Autenticação no Jarvis</ModalHeader>
        <ModalCloseButton />
        <ModalBody px={16}>
          <Flex gap={16} flexDir="column">
            <Heading size="lg">
              Insira o email e código de verificação do Jarvis
            </Heading>
            <LoginJarvisForm />
          </Flex>
        </ModalBody>

        <ModalFooter my={4}>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
