import { Box, Flex, Heading, VStack } from "@chakra-ui/layout";
import { useReqAutomaticBalanceMigrationConfig } from "../../../../../hooks/balance-migration/req-automatic-balance-migration-config";
import {
  Button,
  FormControl,
  FormLabel,
  Stat,
  StatArrow,
  StatLabel,
  StatNumber,
  Switch,
  Text,
} from "@chakra-ui/react";

export function AutomaticMigration() {
  const { value, setValue, bannerValue, patch, loading } =
    useReqAutomaticBalanceMigrationConfig();

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Configurações da migração de saldo automática
      </Heading>

      <Text fontSize="2xl">
        Caso esteja ligada, ela irá rodar das 9:00 às 18:00 (horário de
        Brasília) a cada hora.
      </Text>

      <Flex
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        borderRadius={8}
      >
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="lg"
          h="min-content"
        >
          <Stat border={1}>
            <StatLabel>Status</StatLabel>
            <StatNumber>
              {bannerValue ? (
                <>
                  <StatArrow type="increase" />
                  Migração de saldo automático ativo
                </>
              ) : (
                <>
                  <StatArrow type="decrease" />
                  Desligado
                </>
              )}
            </StatNumber>
          </Stat>
        </Box>

        <Flex flexDir="column" as="form" gap={8} onSubmit={patch}>
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            gap={4}
          >
            <FormLabel mb="0" fontSize="lg">
              Desligar/Ligar?
            </FormLabel>
            <Switch
              isChecked={value}
              onChange={() => setValue((prev) => !prev)}
              size="lg"
            />
          </FormControl>

          <Button
            type="submit"
            isLoading={loading}
            disabled={loading}
            size="lg"
          >
            Salvar Status
          </Button>
        </Flex>
      </Flex>
    </VStack>
  );
}
