import { Flex, HStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { DeliverymanDto } from "../../../../../../dto/deliveryman-dto";
import { postWithAuth } from "../../../../../../services/basicService";

const createDebtFormSchema = z.object({
  description: z.string(),
  total: z.string(),
  numberOfInstallments: z.number().int().min(1),
  installments: z.array(
    z.object({
      amount: z.number().transform((amount) => Math.round(amount * 100)),
      debtDate: z.coerce
        .date()
        .pipe(z.date().min(dayjs().startOf("date").toDate()))
        .transform((date) => dayjs(date).format("YYYY-MM-DD")),
    })
  ),
});

type createDebtFormInputs = z.infer<typeof createDebtFormSchema>;

type IProps = {
  deliveryman: DeliverymanDto | undefined;
};

export function CustomInstallments({ deliveryman }: IProps) {
  const [loading, setLoading] = useState(false);

  const toast = useToast({ duration: 4000, isClosable: true, position: "top" });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<createDebtFormInputs>({
    resolver: zodResolver(createDebtFormSchema),
  });

  const numberOfInstallments = watch("numberOfInstallments");
  const total = watch("total");
  const installments = watch("installments");
  const installmentsJsonString = JSON.stringify(installments);

  const { fields, append, remove } = useFieldArray({
    name: "installments",
    control,
    rules: {
      required: "Adicione ao menos uma parcela.",
    },
  });

  useEffect(() => {
    if (installments) {
      const totalAmount = installments.reduce((acc, curr) => {
        if (Number.isNaN(curr.amount)) return acc;

        return acc + Number(curr.amount);
      }, 0);

      if (Math.round(Number(total) * 100) !== totalAmount) {
        setValue("total", String(totalAmount));
      }

      if (numberOfInstallments > installments.length) {
        for (let i = installments.length; i < numberOfInstallments; i++) {
          append(
            {
              amount: 0,
              debtDate: "",
            },
            { shouldFocus: false }
          );
        }
      }

      if (numberOfInstallments < installments.length) {
        for (let i = numberOfInstallments; i < installments.length; i++) {
          remove();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfInstallments, total, installmentsJsonString]);

  const submit = (data: createDebtFormInputs) => {
    if (loading) return;
    setLoading(true);

    const { description, installments } = data;

    const body = { deliverymanId: deliveryman?.id, description, installments };

    postWithAuth("/api/v1/debt/create/deliveryman", body)
      .then(() => {
        toast({
          status: "success",
          title: "Requisição realizada com sucesso!",
          description: `O débito para o entregador ${deliveryman?.nameIfood} foi criado com sucesso.`,
        });

        reset();
      })
      .catch((err) =>
        toast({
          status: "error",
          title: "Requisição com erro!",
          description: err?.response?.data?.message,
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Flex
      as="form"
      flexDir="column"
      w="full"
      h="auto"
      gap={6}
      p={8}
      borderRadius={8}
      shadow="base"
      bg="whiteAlpha.800"
      onSubmit={handleSubmit(submit)}
    >
      <Flex gap={8} flexDirection="column" w="full">
        <Flex gap={8} flexDir="row" w="full">
          <FormControl
            as="fieldset"
            isInvalid={!!errors.total}
            isRequired
            disabled={true}
            w="25%"
          >
            <FormLabel as="legend">Valor Total em Reais</FormLabel>

            <Controller
              name="total"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Price is required",
                },
              }}
              render={({ field: { ref, ...restField } }) => (
                <NumberInput
                  {...restField}
                  min={1}
                  borderRadius={4}
                  step={0.2}
                  precision={2}
                  inputMode="decimal"
                  isValidCharacter={(value) => /^[Ee0-9+\-.,]$/.test(value)}
                >
                  <NumberInputField
                    type="number"
                    ref={ref}
                    name={restField.name}
                    fontWeight="bold"
                    _placeholder={{
                      color: "gray.500",
                    }}
                    placeholder="R$"
                  />
                </NumberInput>
              )}
            />

            {errors.total && (
              <FormErrorMessage>{errors.total.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            as="fieldset"
            isInvalid={!!errors.numberOfInstallments}
            isRequired
            w="25%"
          >
            <FormLabel as="legend">Total de parcelas</FormLabel>
            <NumberInput borderRadius={4}>
              <NumberInputField
                {...register("numberOfInstallments", {
                  valueAsNumber: true,
                  required: true,
                })}
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </NumberInput>

            {errors.numberOfInstallments && (
              <FormErrorMessage>
                {errors.numberOfInstallments.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>

        <FormControl as="fieldset" isInvalid={!!errors.description} isRequired>
          <FormLabel>Descrição</FormLabel>
          <Input
            type="text"
            placeholder="Digite a descrição"
            {...register("description")}
          />
          {errors.description ? (
            <FormErrorMessage>{errors.description.message}</FormErrorMessage>
          ) : null}
        </FormControl>

        <Flex flexDirection="column" w="full" gap="10">
          {fields.map((field, index) => (
            <HStack key={field.id} justifyContent="space-around">
              <FormControl
                as="fieldset"
                w="max-content"
                display="flex"
                justifyContent="center"
                isRequired
              >
                <FormLabel as="legend">{index + 1} Parcela</FormLabel>
                <NumberInput borderRadius={4}>
                  <NumberInputField
                    {...register(`installments.${index}.amount`, {
                      valueAsNumber: true,
                      required: true,
                    })}
                    fontWeight="bold"
                    _placeholder={{
                      color: "gray.500",
                    }}
                  />
                </NumberInput>
              </FormControl>

              <FormControl as="fieldset" w="max-content" isRequired>
                <FormLabel as="legend">{index + 1} Vencimento</FormLabel>
                <Input
                  placeholder="Select Date"
                  type="date"
                  {...register(`installments.${index}.debtDate`)}
                />
                {errors?.installments?.[index]?.debtDate ? (
                  <FormErrorMessage>
                    {errors.installments[index]?.debtDate?.message}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
            </HStack>
          ))}
        </Flex>
      </Flex>
      <Button
        type="submit"
        size="lg"
        alignSelf="flex-end"
        isDisabled={!isDirty || loading}
        isLoading={isSubmitting || loading}
      >
        Criar débito
      </Button>
    </Flex>
  );
}
