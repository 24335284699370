import { VStack, Stack, HStack, Flex, Box } from "@chakra-ui/layout";
import {
  Text,
  Button,
  useToast,
  useColorMode,
  Input,
  FormLabel,
  FormControl,
  FormErrorMessage,
  IconButton,
  Textarea,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from "@chakra-ui/react";
import { Trash } from "@phosphor-icons/react";
import { useForm, useFieldArray } from "react-hook-form";
import { useBlockedDeliverymanStore } from "../../../../../store/deliveryman-blocked.store";
import { formatCPF } from "../../../../../utils/format-document";
import {
  BlockCpfDto,
  UnblockCpfDto,
} from "../../../../../dto/deliveryman-blocked-dto";

type FormBlockCpfValues = {
  cpfs: {
    documents: string[];
  }[];
  reason: string;
};

const emptyCpf = {
  documents: [],
};

export function BlockCpfTab() {
  const blockedCpfs = useBlockedDeliverymanStore((state) => state.blockedCpfs);
  const blockOneOrMoreCpfs = useBlockedDeliverymanStore(
    (state) => state.blockOneOrMoreCpfs
  );
  const unblockOneOrMoreCpfs = useBlockedDeliverymanStore(
    (state) => state.unblockOneOrMoreCpfs
  );
  const getBlockedCpfs = useBlockedDeliverymanStore(
    (state) => state.getBlockedCpfs
  );

  const toast = useToast();
  const { colorMode } = useColorMode();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormBlockCpfValues>({
    defaultValues: {
      cpfs: [{ documents: [""] }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "cpfs",
    control,
    rules: {
      required: "Adicione ao menos um CPF.",
    },
  });

  const handleBlock = async (data: FormBlockCpfValues) => {
    try {
      console.log(data);
      const id = {
        documents: data.cpfs.map((document) => document.documents),
        reason: data.reason,
      } as unknown as BlockCpfDto;

      const response = await blockOneOrMoreCpfs(id);
      const { allWereBlocked, unblockedUserDocuments } = response;

      const notBlockedCPF: string = allWereBlocked
        ? ""
        : unblockedUserDocuments.join(", ");

      await getBlockedCpfs();
      toast({
        title: "Bloqueio concluido",
        description: `Os CPFs foram bloqueados com sucesso.${
          allWereBlocked
            ? ""
            : ` Porém, os seguintes CPFs não se encontram na nossa base de dados: ${notBlockedCPF}`
        }`,
        status: "success",
        duration: allWereBlocked ? 3000 : 7000,
        isClosable: true,
        position: "top",
      });
      setValue("cpfs", [{ documents: [""] }]);
      setValue("reason", "");
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao efetuar o bloqueio",
        description: "Não foi possível bloquear o CPF selecionado",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleUnblock = async (documents: UnblockCpfDto) => {
    try {
      await unblockOneOrMoreCpfs(documents);
      await getBlockedCpfs();
      toast({
        title: "Desbloqueio concluido",
        description: `O cpf foi desbloqueado com sucesso`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao efetuar o desbloqueio",
        description: "Não foi possível desbloquear os CPFs",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <VStack alignItems="flex-start" w="full" gap={8}>
      <Flex w="full" justifyContent="space-between" gap={8}>
        <FormControl
          as="fieldset"
          w="40%"
          onSubmit={handleSubmit(handleBlock)}
          isInvalid={!!errors.cpfs}
        >
          <FormLabel as="legend" fontSize={"2xl"} mb={4}>
            Ferramenta de bloqueio por CPF
          </FormLabel>
          <Stack>
            {fields.map((field, index) => (
              <HStack key={field.id} alignItems="flex-start">
                <Input
                  type="text"
                  {...register(`cpfs.${index}.documents`, {
                    required: "Digite o CPF.",
                    minLength: {
                      value: 11,
                      message: "CPF deve ter 11 caracteres.",
                    },
                    maxLength: {
                      value: 11,
                      message: "CPF deve ter 11 caracteres.",
                    },
                  })}
                  bg="whiteAlpha.800"
                  placeholder="11 números"
                />
                {errors.cpfs?.[index]?.documents && (
                  <FormErrorMessage>
                    {errors.cpfs?.[index]?.documents?.message}
                  </FormErrorMessage>
                )}
                <IconButton
                  aria-label="campo para apagar input de cpf"
                  icon={<Trash />}
                  onClick={() => remove(index)}
                />
              </HStack>
            ))}
          </Stack>
          <VStack w="full" gap={4} marginTop={4} width="full">
            <Button
              type="button"
              onClick={() => append(emptyCpf)}
              width="full"
              colorScheme="green"
            >
              Adicionar CPF
            </Button>
            <FormLabel as="legend" fontSize={"2xl"}>
              Qual o motivo do bloqueio?
            </FormLabel>
            <Textarea
              placeholder="*Opcional"
              {...register(`reason`)}
              bg="whiteAlpha.800"
            />
            <Text fontSize="2xl">
              Após selecionar os CPFs, faça o bloqueio clicando no botão abaixo:
            </Text>
            <Button
              mt={4}
              colorScheme="green"
              type="submit"
              onClick={handleSubmit(handleBlock)}
              width="full"
              isDisabled={!isValid}
            >
              Bloquear CPFS
            </Button>
          </VStack>
        </FormControl>
        <Box display={"flex"} flex={1} flexDir={"column"} gap={4}>
          <Text fontSize="2xl" fontWeight="semibold">
            CPFs bloqueados
          </Text>
          <TableContainer
            w="full"
            p={8}
            border="1px"
            flex={1}
            borderRadius={8}
            bg={colorMode === "dark" ? "gray.700" : "white"}
            borderColor={colorMode === "dark" ? "" : "gray.300"}
            shadow="base"
          >
            <HStack gap={1} marginBottom={1}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Nome</Th>
                    <Th>CPF</Th>
                    <Th>Desbloquear</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {blockedCpfs.map((user) => {
                    return (
                      <Tr fontWeight={600} key={user.id}>
                        <Td>{user.name}</Td>
                        <Td>{formatCPF(user.document)}</Td>
                        <Td>
                          <Button
                            size="sm"
                            onClick={() =>
                              handleUnblock({ documents: [user.document] })
                            }
                          >
                            Desbloquear
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </HStack>
          </TableContainer>
        </Box>
      </Flex>
    </VStack>
  );
}
