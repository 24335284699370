import {
  Box,
  Flex,
  HStack,
  Heading,
  StackDivider,
  Text,
} from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
  Switch,
  Table,
  Tbody,
  TableContainer,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { shallow } from "zustand/shallow";
import {
  useCompaniesStore,
  useCreateCompanyForm,
} from "../../store/company.store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getWithAuth } from "../../services/basicService";
import { CompanySaaSPlan } from "../../dto/company-dto";
import { Question } from "@phosphor-icons/react";
import { useEffect } from "react";

export function EditCompanyService() {
  const client = useQueryClient();

  const { company, saas, getCompany } = useCompaniesStore(
    (state) => ({
      company: state.company,
      saas: state.company?.configurations.transactionBasedSaaS,
      getCompany: state.getCompany,
    }),
    shallow
  );

  const editCompanyServiceFormSchema = z.object({
    numberOfTransactions: z
      .number({
        required_error: "Este campo é obrigatório",
      })
      .default(0),
    participatesInSaaS: z
      .boolean({
        required_error: "Este campo é obrigatório",
      })
      .default(false),
    plan: z.nativeEnum(CompanySaaSPlan, {
      required_error: "Este campo é obrigatório",
    }),
    billingDayOfMonth: z
      .number({
        required_error: "Este campo é obrigatório",
      })
      .default(1),
    SaaSValue: z.number().optional(),

    transactionValue: z.number().optional(),

    overLimitTransactionValue: z.number().optional(),
  });

  type editCompanyServiceFormInputs = z.infer<
    typeof editCompanyServiceFormSchema
  >;

  const { data, refetch } = useQuery({
    queryKey: ["saas-data", company?.id],
    queryFn: async () => {
      const response = await getWithAuth(
        "/api/v1/transaction/company/saas/" + company?.id
      );

      return response?.data;
    },
  });

  const { updateCompany } = useCreateCompanyForm(
    (state) => ({
      updateCompany: state.updateCompany,
    }),
    shallow
  );

  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<editCompanyServiceFormInputs>({
    resolver: zodResolver(editCompanyServiceFormSchema),
    defaultValues: {
      numberOfTransactions: saas?.numberOfTransactions ?? 0,
      participatesInSaaS: saas?.participatesInSaaS ?? false,
      plan: saas?.plan,
      billingDayOfMonth: saas?.billingDayOfMonth ?? 1,
      SaaSValue: saas?.SaaSValue ?? 0,
      transactionValue: saas?.transactionValue ?? 0,
      overLimitTransactionValue: saas?.overLimitTransactionValue ?? 0,
    },
  });

  const transactionValue = watch("transactionValue");
  const overLimitTransactionValue = watch("overLimitTransactionValue");
  const SaaSValue = watch("SaaSValue");

  useEffect(() => {
    if (Number.isNaN(transactionValue) || transactionValue === undefined) {
      setValue("transactionValue", saas?.transactionValue ?? 0);
    }
    if (
      Number.isNaN(overLimitTransactionValue) ||
      overLimitTransactionValue === undefined
    ) {
      setValue(
        "overLimitTransactionValue",
        saas?.overLimitTransactionValue ?? 0
      );
    }
    if (Number.isNaN(SaaSValue) || SaaSValue === undefined) {
      setValue("SaaSValue", saas?.transactionValue ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionValue, overLimitTransactionValue, SaaSValue, setValue]);

  const participatesInSaaS = watch("participatesInSaaS");
  const plan = watch("plan");

  async function onSave(data: editCompanyServiceFormInputs) {
    try {
      if (company?.id) {
        await updateCompany(company?.id, {
          configurations: {
            ...company.configurations,
            transactionBasedSaaS: {
              billingDayOfMonth: data?.billingDayOfMonth,
              numberOfTransactions: data?.numberOfTransactions,
              participatesInSaaS: data?.participatesInSaaS,
              plan: data?.plan,
              transactionValue: Number(
                (data?.transactionValue ?? 0).toFixed(2)
              ),
              overLimitTransactionValue: Number(
                (data?.overLimitTransactionValue ?? 0).toFixed(2)
              ),
              SaaSValue: Number((data?.SaaSValue ?? 0).toFixed(2)),
            },
          },
        });

        refetch();

        toast({
          status: "success",
          duration: 2000,
          title: "Dados da empresa atualizados com sucesso!",
        });
        await getCompany(company?.id);
        client.invalidateQueries(["companies"]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          status: "error",
          duration: 4000,
          title: "Não foi possível atualizar os dados da empresa!",
          description:
            "Tente novamente mais tarde ou entre em contato com o suporte!",
        });
        return;
      }

      if (error instanceof Error) {
        toast({
          status: "error",
          duration: 2000,
          title: "Não foi possível atualizar os dados da empresa!",
          description: `Erro ao atualizar empresa: ${error.message}`,
        });
        return;
      }
      toast({
        status: "error",
        duration: 2000,
        title: "Não foi possível atualizar os dados da empresa!",
      });
    }
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <HStack
        divider={<StackDivider borderColor="gray.200" />}
        w="full"
        align="flex-start"
        gap={16}
      >
        <Flex direction="column" w="full" gap={4}>
          <Heading fontSize={"3xl"}>Dados serviços de assinatura</Heading>
          <TableContainer
            w="full"
            style={{
              containerType: "inline-size",
            }}
          >
            <Table variant="striped" colorScheme="green">
              <Tbody fontSize={"lg"} fontWeight={"semibold"}>
                {data?.saasConfiguration?.plan === CompanySaaSPlan.PACKAGE && (
                  <>
                    <Tr>
                      <Th fontSize={"sm"}>
                        Número de transações acima do limite
                      </Th>
                      <Td>{data?.numberOfTransactionsOverLimit}</Td>
                    </Tr>
                    <Tr>
                      <Th fontSize={"sm"}>Número de transações disponíveis</Th>
                      <Td>
                        {saas && data
                          ? saas?.numberOfTransactions -
                            data?.currentPeriodSuccessTransactionsCount
                          : 0}
                      </Td>
                    </Tr>
                  </>
                )}
                <Tr>
                  <Th fontSize={"sm"}>
                    Número de transações com sucesso no período corrente
                  </Th>
                  <Td>{data?.currentPeriodSuccessTransactionsCount}</Td>
                </Tr>
                <Tr>
                  <Th fontSize={"sm"}>
                    Número de transações com sucesso no período anterior
                  </Th>
                  <Td>{data?.lastPeriodSuccessTransactionsCount}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={8}
          id="form-service"
          onSubmit={handleSubmit(onSave)}
        >
          <Controller
            control={control}
            name="participatesInSaaS"
            render={({ field }) => (
              <FormControl
                as="fieldset"
                isInvalid={!!errors.participatesInSaaS}
              >
                <FormLabel as="legend">
                  Participa do serviço de assinatura
                </FormLabel>
                <Switch
                  isChecked={field.value}
                  onChange={field.onChange}
                  colorScheme="green"
                />
                {errors.participatesInSaaS ? (
                  <FormErrorMessage>
                    {errors.participatesInSaaS.message}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
            )}
          />

          <FormControl
            as="fieldset"
            isInvalid={!!errors.plan}
            disabled={!participatesInSaaS}
            isRequired
          >
            <Flex>
              <FormLabel as="legend">Tipo de cobrança </FormLabel>
              <Tooltip
                hasArrow
                textAlign="center"
                fontSize="md"
                label={
                  <Box textAlign="left">
                    <Text>Tipos de cobrança</Text>
                    <Text as="span">
                      •{" "}
                      <Text as="span" fontWeight="bold">
                        Pacote:
                      </Text>{" "}
                      A empresa será cobrada um valor fixo por um número
                      definido de transações. Transações que excederem essa
                      quantidade serão cobradas individualmente.
                    </Text>
                    <br />
                    <Text as="span">
                      •{" "}
                      <Text as="span" fontWeight="bold">
                        Por Transação:
                      </Text>{" "}
                      A empresa será cobrada por cada transação individualmente,
                      sem limite de quantidade.
                    </Text>
                  </Box>
                }
                bg="gray.300"
                color="black"
              >
                <Question size={22} />
              </Tooltip>
            </Flex>
            <Select {...register("plan")}>
              {Object.entries(CompanySaaSPlan).map(([, value]) => {
                const valueMapper = {
                  [CompanySaaSPlan.BY_TRANSACTION]: "Por Transações",
                  [CompanySaaSPlan.PACKAGE]: "Pacote",
                };

                return (
                  <option key={value} value={value}>
                    {valueMapper[value]}
                  </option>
                );
              })}
            </Select>
            {errors.plan ? (
              <FormErrorMessage>{errors.plan.message}</FormErrorMessage>
            ) : null}
          </FormControl>

          <FormControl
            as="fieldset"
            isInvalid={!!errors.billingDayOfMonth}
            disabled={!participatesInSaaS}
            isRequired
          >
            <FormLabel as="legend">Dia do pagamento do serviço</FormLabel>
            <Input
              type="number"
              {...register("billingDayOfMonth", {
                valueAsNumber: true,
              })}
              min={1}
              max={28}
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.billingDayOfMonth ? (
              <FormErrorMessage>
                {errors.billingDayOfMonth.message}
              </FormErrorMessage>
            ) : null}
          </FormControl>

          {plan === CompanySaaSPlan.PACKAGE && (
            <FormControl
              as="fieldset"
              isInvalid={!!errors.SaaSValue}
              disabled={!participatesInSaaS}
              isRequired
            >
              <FormLabel as="legend">Valor do serviço</FormLabel>
              <NumberInput
                min={0}
                inputMode="decimal"
                isValidCharacter={(value) => /^[Ee0-9+\-.,]$/.test(value)}
                precision={2}
              >
                <NumberInputField
                  type="number"
                  {...register("SaaSValue", { valueAsNumber: true })}
                />
              </NumberInput>
              {errors.SaaSValue ? (
                <FormErrorMessage>{errors.SaaSValue.message}</FormErrorMessage>
              ) : null}
            </FormControl>
          )}

          {plan === CompanySaaSPlan.PACKAGE && (
            <FormControl
              as="fieldset"
              isInvalid={!!errors.numberOfTransactions}
              disabled={!participatesInSaaS}
              isRequired
            >
              <FormLabel as="legend">Quantidade máxima de transações</FormLabel>
              <NumberInput
                min={0}
                inputMode="numeric"
                isValidCharacter={(value) => /^\d+$/.test(value)}
              >
                <NumberInputField
                  type="number"
                  {...register("numberOfTransactions", {
                    valueAsNumber: true,
                  })}
                  _placeholder={{
                    color: "gray.500",
                  }}
                />
              </NumberInput>
              {errors.numberOfTransactions ? (
                <FormErrorMessage>
                  {errors.numberOfTransactions.message}
                </FormErrorMessage>
              ) : null}
            </FormControl>
          )}

          {plan === CompanySaaSPlan.BY_TRANSACTION && (
            <FormControl
              as="fieldset"
              isInvalid={!!errors.overLimitTransactionValue}
              disabled={!participatesInSaaS}
              isRequired
            >
              <FormLabel as="legend">Valor por transação avulsa</FormLabel>
              <NumberInput
                min={0}
                inputMode="decimal"
                precision={2}
                isValidCharacter={(value) => /^[Ee0-9+\-.,]$/.test(value)}
              >
                <NumberInputField
                  type="number"
                  {...register("transactionValue", {
                    valueAsNumber: true,
                  })}
                />
              </NumberInput>
              {errors.transactionValue ? (
                <FormErrorMessage>
                  {errors.transactionValue.message}
                </FormErrorMessage>
              ) : null}
            </FormControl>
          )}

          {plan === CompanySaaSPlan.PACKAGE && (
            <FormControl
              as="fieldset"
              isInvalid={!!errors.overLimitTransactionValue}
              disabled={!participatesInSaaS}
              isRequired
            >
              <FormLabel as="legend">Valor por transação excedente</FormLabel>
              <NumberInput
                min={0}
                inputMode="decimal"
                precision={2}
                isValidCharacter={(value) => /^[Ee0-9+\-.,]$/.test(value)}
              >
                <NumberInputField
                  type="number"
                  {...register("overLimitTransactionValue", {
                    valueAsNumber: true,
                  })}
                />
              </NumberInput>
              {errors.overLimitTransactionValue ? (
                <FormErrorMessage>
                  {errors.overLimitTransactionValue.message}
                </FormErrorMessage>
              ) : null}
            </FormControl>
          )}
        </Flex>
      </HStack>
      <Button
        type="submit"
        form="form-service"
        size="lg"
        alignSelf="flex-end"
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  );
}
