import dayjs from "dayjs";

export function fortnightValidForToday() {
  const currentDay = dayjs().date();

  let from: Date;

  let to: Date;

  if (currentDay === 1) {
    const todayMinusOneMonth = dayjs().utc().subtract(1, "month");

    from = todayMinusOneMonth.set("date", 16).startOf("date").toDate();

    to = todayMinusOneMonth.endOf("month").endOf("date").toDate();
  } else if (currentDay === 16) {
    from = dayjs().utc().startOf("month").toDate();

    to = dayjs().utc().set("date", 15).endOf("date").toDate();
  } else if (currentDay > 1 && currentDay < 16) {
    from = dayjs().utc().startOf("month").toDate();

    to = dayjs().utc().toDate();
  } else {
    from = dayjs().utc().set("date", 16).startOf("date").toDate();

    to = dayjs().utc().toDate();
  }

  return { from, to };
}
