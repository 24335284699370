import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { useJarvisAuthService } from "../../../services/api/jarvis-auth";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { isAxiosError } from "axios";

const formSchema = z.object({
  jarvisEmail: z
    .string()
    .email("Por favor insira um email válido do Jarvis")
    .optional()
    .refine((value) => !!value, {
      message: "O campo de email é obrigatório",
    }),
  jarvisCode: z
    .string()
    .length(6, "O código deve ter exatamente 6 dígitos e ser válido")
    .optional()
    .refine((value) => !!value, {
      message: "O campo de código é obrigatório",
    }),
});

type signInFormInputs = z.infer<typeof formSchema>;

export function LoginJarvisForm() {
  const jarvisAuthService = useJarvisAuthService();
  const toast = useToast();

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<signInFormInputs>({
    defaultValues: {
      jarvisEmail: undefined,
      jarvisCode: undefined,
    },
    mode: "onChange",
    resolver: zodResolver(formSchema),
  });

  const { updateCurrentUserCompanyIntegrations } = useCurrentUserStore();

  async function onSubmit(data: signInFormInputs) {
    try {
      const response = await jarvisAuthService.token({
        email: data?.jarvisEmail as string,
        code: data?.jarvisCode as string,
      });

      if (!response?.status) {
        throw new Error("login failed");
      }

      const roles = response.data.integrations.ifood.roles;

      const extractRole = (role: string) => {
        const parts = role.split(" - ");
        return parts.length > 1 ? parts[1] : role;
      };

      const extractedRoles = roles.map(extractRole);

      await updateCurrentUserCompanyIntegrations(extractedRoles);

      toast({
        title: "Login feito com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        const response = error.response;
        const data = response?.data;
        const message = data.message;
        if (message) {
          toast({
            title: "Erro de login",
            description: message,
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Erro de login",
            description: "Não foi possível se conectar ao Jarvis",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Erro de desconhecido",
          description: "Não foi possível se conectar ao Jarvis",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }

  return (
    <Box
      as="form"
      display="flex"
      flexDir="column"
      gap={8}
      w="full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl isInvalid={!!errors.jarvisEmail}>
        <FormLabel htmlFor="jarvisEmail">Email</FormLabel>
        <Input
          id="jarvisEmail"
          size="lg"
          _placeholder={{
            color: "gray.500",
          }}
          {...register("jarvisEmail")}
          title="jarvisEmail"
          placeholder="exemplo@email.com"
        />
        <FormErrorMessage>{errors.jarvisEmail?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.jarvisCode}>
        <FormLabel htmlFor="jarvisCode">Código</FormLabel>
        <Input
          id="jarvisCode"
          size="lg"
          _placeholder={{
            color: "gray.500",
          }}
          {...register("jarvisCode")}
          title="jarvisCode"
          placeholder="Digite o código de verificação..."
        />
        <FormErrorMessage>{errors.jarvisCode?.message}</FormErrorMessage>
      </FormControl>
      <Flex justifyContent="end" gap={4} alignItems="center">
        <Button
          isLoading={isSubmitting}
          type="submit"
          size="lg"
          w="full"
          bg="green.400"
          fontWeight="bold"
          shadow="lg"
          _hover={{
            bg: "green.700",
          }}
        >
          Confirmar
        </Button>
      </Flex>
    </Box>
  );
}
