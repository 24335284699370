import { z } from "zod";
import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { useShiftCachedService } from "../../../../../services/api/shifts";
import { MutateFunction } from "../../../../../services/api/use-cached-service";
import { Shift } from "../../../../../dto/shift-dto";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";

const formFields: FormField[] = [
  {
    label: "Nome",
    name: "name",
    type: "text",
  },
  {
    label: "Horário inicial",
    name: "startHour",
    type: "time",
  },
  {
    label: "Horário final",
    name: "endHour",
    type: "time",
  },
];

type FormType = Shift;

const formSchema = z.object({
  name: z.string(),
  startHour: z.string(),
  endHour: z.string(),
});

interface Props {
  onCreated: () => void;
}

export function ShiftFormCreate(props: Props) {
  const { onCreated } = props;

  const { useMutationAction: useShiftMutationAction } = useShiftCachedService();
  const [createShift, watchers] = useShiftMutationAction("create");

  const toast = useToast();

  useEffect(() => {
    const { status, error } = watchers;
    if (status) {
      if (status === "success") {
        onCreated();
      }
      if (status === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao criar turno",
          description: `Falha ao criar turno: ${error}`,
        });
      }
    }
  }, [onCreated, toast, watchers, watchers.status]);

  function buildHandleSubmit(createShift: MutateFunction) {
    return (data: FormType) => {
      createShift(data);
    };
  }

  const handleSubmit = buildHandleSubmit(createShift) as (
    data: unknown
  ) => void;

  return (
    <GenericForm
      fields={formFields}
      formSchema={formSchema}
      initialData={{}}
      actionLabel="Salvar"
      onSubmit={handleSubmit}
    />
  );
}
