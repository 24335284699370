import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  Bank,
  CaretLeft,
  Lock,
  Newspaper,
  Receipt,
  UserList,
} from "@phosphor-icons/react";
import { HStack, Heading, VStack } from "@chakra-ui/layout";
import { EditCompanyData } from "../../../../components/edit-company/EditCompanyData";
import { EditCompanyPermissions } from "../../../../components/edit-company/EditCompanyPermissions";
import { EditCompanyBankLimit } from "../../../../components/edit-company/EditCompanyBankLimit";
import { EditCompanyService } from "../../../../components/edit-company/EditCompanySevice";
import { CreateB2BUserTab } from "../../../../components/edit-company/CreateUserB2B";

export function EditCompany() {
  const navigate = useNavigate();

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Editar empresa
        </Heading>
      </HStack>

      <VStack
        w="full"
        h="full"
        gap={6}
        p={8}
        borderRadius={8}
        shadow="base"
        bg="whiteAlpha.800"
        flex={1}
      >
        <Tabs w="full" h="full" flex={1} colorScheme="green">
          <TabList>
            <Tab display="flex" gap={2} fontWeight="bold" fontSize="lg">
              <Newspaper />
              Editar dados
            </Tab>
            <Tab display="flex" gap={2} fontWeight="bold" fontSize="lg">
              <Lock />
              Editar permissões
            </Tab>
            <Tab display="flex" gap={2} fontWeight="bold" fontSize="lg">
              <Bank />
              Editar limites bancários
            </Tab>
            <Tab display="flex" gap={2} fontWeight="bold" fontSize="lg">
              <Receipt />
              Editar dados de serviço
            </Tab>
            <Tab display="flex" gap={2} fontWeight="bold" fontSize="lg">
              <UserList />
              Usuário Api B2B
            </Tab>
          </TabList>

          <TabPanels w="full" h="full" flex={1} pb={8}>
            <TabPanel w="full" h="full" flex={1}>
              <EditCompanyData />
            </TabPanel>
            <TabPanel w="full" h="full" flex={1}>
              <EditCompanyPermissions />
            </TabPanel>
            <TabPanel w="full" h="full" flex={1}>
              <EditCompanyBankLimit />
            </TabPanel>
            <TabPanel w="full" h="full" flex={1}>
              <EditCompanyService />
            </TabPanel>
            <TabPanel w="full" h="full" flex={1}>
              <CreateB2BUserTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  );
}
