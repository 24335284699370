import { VStack, Heading } from "@chakra-ui/layout";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { BlockCpfTab } from "./block-cpf-tab";
import { BlockIdTab } from "./block-ifood-tab";

export function BlockDeliveryman() {
  return (
    <VStack alignItems="flex-start" w="full" gap={8}>
      <Heading fontWeight="extrabold" textAlign="left">
        Bloqueio de Entregadores
      </Heading>
      <Tabs w="full" colorScheme="green">
        <TabList>
          <Tab>Bloqueio por CPF</Tab>
          <Tab>Bloqueio por Id do Ifood</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <BlockCpfTab />
          </TabPanel>
          <TabPanel>
            <BlockIdTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
