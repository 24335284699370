import { Flex } from "@chakra-ui/layout";
import { Outlet, useNavigation } from "react-router-dom";
import { SplitPaymentsSubMenu } from "../../components/split-payments/submenu";
import { LoadingLogo } from "../../components/loading";

type configOptions = "company" | "support";

export function SplitPaymentsLayout(props: { config: configOptions }) {
  const { state } = useNavigation();

  return (
    <Flex gap={8} w="full" px={4} position="relative">
      <SplitPaymentsSubMenu config={props.config} />
      {state === "loading" ? (
        <>
          <LoadingLogo />
        </>
      ) : (
        <Outlet />
      )}
    </Flex>
  );
}
