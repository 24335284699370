import { Subplaza } from "../../dto/subplaza-dto";
import { Service, useBaseCrud } from "./base-crud";
import { useCachedService } from "./use-cached-service";

const baseURL = "/api/v1/subplazas";

export const useSubplazaService = () => {
  return {
    ...useBaseCrud<Subplaza>(baseURL),
  };
};

export const useSubplazaCachedService = () => {
  const subplazaService = useSubplazaService() as unknown as Service<Subplaza>;

  return useCachedService(subplazaService, "subplazas");
};
