import { DeliverymanDto } from "../../../../../dto/deliveryman-dto";
import { Flex, Heading, VStack, Text } from "@chakra-ui/layout";
import { SkeletonText } from "@chakra-ui/react";

type IProps = {
  deliveryman: DeliverymanDto | undefined;
  getDeliverymanLoading: boolean;
};

export function GetDeliveryman({ deliveryman, getDeliverymanLoading }: IProps) {
  return (
    <VStack
      align="stretch"
      w="30%"
      h="fit-content"
      padding="10"
      backgroundColor="gray.300"
      borderRadius="2xl"
      boxShadow="4px 6px 6px 0px rgba(0,0,0,0.75)"
      gap={4}
    >
      <Flex gap={4}>
        <Heading size="md" fontWeight="extrabold" textAlign="left">
          Dados do entregador
        </Heading>
      </Flex>

      <SkeletonText
        isLoaded={!getDeliverymanLoading}
        w="full"
        noOfLines={8}
        skeletonHeight={6}
      >
        <VStack align="self-start">
          <Text fontSize="md">
            <strong>Nome:</strong> {deliveryman?.nameIfood}
          </Text>
          <Text fontSize="md">
            <strong>Documento:</strong> {deliveryman?.document}
          </Text>
          <Text fontSize="md">
            <strong>Número da conta:</strong> {deliveryman?.accountId}
          </Text>
          <Text fontSize="md">
            <strong>E-mail:</strong> {deliveryman?.email}
          </Text>
          <Text fontSize="md">
            <strong>Telefone:</strong> {deliveryman?.phone}
          </Text>
        </VStack>
      </SkeletonText>
    </VStack>
  );
}
