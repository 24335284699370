import { acceptanceEnum } from "../enums/acceptance.enum";
import { SubplazaSetting } from "../types/subplaza-setting.type";
import { Plaza } from "./plaza-dto";

export interface ISubplazaSettingDto {
  acceptance?: acceptanceEnum;
}

export interface Subplaza {
  id: number;
  name: string;
  setting: SubplazaSetting;
  plazaId: number;
  plaza: Plaza;
  companyId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export const textSchemeMap = {
  [acceptanceEnum.AUTOMATIC]: "Automático",
  [acceptanceEnum.MANUALLY]: "Manual",
  [acceptanceEnum.DEFAULT]: "Padrão da praça",
};
