import { useState } from "react";
import { VStack, Heading, Badge, Flex } from "@chakra-ui/layout";
import {
  Text,
  Button,
  Textarea,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { useAdvanceUserStore } from "../../../../store/user-advance.store";
import { formatCPF } from "../../../../utils/format-document";
import { convertCentsToReais } from "../../../../utils/convert-money";
import dayjs from "dayjs";
import {
  colorSchemeMap,
  textSchemeMap,
} from "../../../../dto/advance-user-dto";
import { CancelProposal } from "../../../../dto/cancel-proposal-dto";
import { ApproveProposal } from "../../../../dto/approve-proposal-dto";
import { useReceiptAdvance } from "../../../../hooks/adiantamento/use-receipt-advance";
import { Download } from "@phosphor-icons/react";

export function AdvanceDetail() {
  const [reason, setReason] = useState<string>("");
  const advanceUser = useAdvanceUserStore((state) => state.advanceUser);
  const getAdvanceUser = useAdvanceUserStore((state) => state.getAdvanceUser);
  const cancelProposal = useAdvanceUserStore((state) => state.cancelProposal);
  const approvalProposal = useAdvanceUserStore(
    (state) => state.approvalProposal
  );
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: "bottom",
  });
  const { colorMode } = useColorMode();
  const { getReceipt, loadingReceipt } = useReceiptAdvance();
  const [loading, setLoading] = useState(false);

  const handleApproval = async () => {
    if (loading) return;

    setLoading(true);

    toast({
      status: "info",
      title: "Aprovação em andamento",
      description: "A aprovação do adiantamento esta sendo processada...",
    });

    const userAdvanceId: ApproveProposal = {
      userAdvanceId: advanceUser?.id,
    };
    try {
      await approvalProposal(
        `api/v1/userAdvance/proposal/approve`,
        userAdvanceId
      );
      await getAdvanceUser(String(advanceUser?.id));

      toast({
        status: "success",
        title: "Aprovação concluída",
        description: "O adiantamento foi aprovado com sucesso!",
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro na aprovação",
        description: "Não foi possível fazer a aprovação",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    if (loading) return;

    setLoading(true);

    toast({
      status: "info",
      title: "Cancelamento em andamento",
      description: "O cancelamento do adiantamento esta sendo processada...",
    });

    const cancelProposalBody: CancelProposal = {
      userAdvanceId: advanceUser?.id,
    };

    if (reason) {
      cancelProposalBody.reason = reason;
    }

    try {
      await cancelProposal(
        `/api/v1/userAdvance/proposal/cancel`,
        cancelProposalBody
      );
      await getAdvanceUser(String(advanceUser?.id));

      toast({
        status: "success",
        title: "Cancelamento concluído",
        description: "O adiantamento foi cancelado com sucesso!",
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro no cancelamento",
        description: "Não foi possível fazer o cancelamento",
        status: "error",
      });
    } finally {
      setLoading(true);
    }
  };

  return advanceUser ? (
    <VStack gap={1} w="full" alignItems="flex-start" as="header">
      <Heading fontWeight="extrabold" textAlign="left">
        Adiantamento #{advanceUser.id}
      </Heading>
      <Text fontSize="md" fontWeight="bold">{`Criado em: ${dayjs(
        advanceUser.createdAt
      ).format("DD/MM/YYYY HH:mm")}`}</Text>

      <Flex
        flexDir={{ base: "column", lg: "row" }}
        w="full"
        border="1px"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        borderColor={colorMode === "dark" ? "" : "gray.300"}
        p={4}
        borderRadius={8}
      >
        <VStack
          w="full"
          gap={4}
          justifyContent="center"
          borderRight="1px"
          borderColor={colorMode === "dark" ? "" : "gray.300"}
        >
          <Heading as="h3" fontSize="xl">
            Informações do solicitante
          </Heading>
          <Text fontSize="xl">{`Nome: ${advanceUser.username}`}</Text>
          <Text fontSize="xl">{`CPF: ${formatCPF(advanceUser.document)}`}</Text>
          <Text fontSize="xl">{`Empresa: ${
            advanceUser.companyName ? advanceUser.companyName : "-"
          }`}</Text>
          <Text fontSize="xl">
            Contrato assinado:{" "}
            {
              <Badge
                variant="solid"
                px={5}
                bg={
                  advanceUser.status === "validated" ||
                  advanceUser.status === "approved" ||
                  advanceUser.status === "review"
                    ? "green.600"
                    : "red.600"
                }
              >
                {["validated", "approved", "review"].includes(
                  advanceUser.status
                )
                  ? "Sim"
                  : "Não"}
              </Badge>
            }
          </Text>
        </VStack>
        <VStack w="full" gap={4}>
          <Heading as="h3" fontSize="xl">
            Informações do adiantamento
          </Heading>
          <Text fontSize="xl">
            Valor: {convertCentsToReais(advanceUser.amount)}
          </Text>
          <Text fontSize="xl">
            Taxa: {convertCentsToReais(advanceUser.amountFee)}
          </Text>
          <Text fontSize="xl">
            Valor a receber: {convertCentsToReais(advanceUser.amountReceivable)}
          </Text>
          <Text fontSize="xl">
            Motivo: {advanceUser.reason ? advanceUser.reason : "-"}
          </Text>
          <Text fontSize="xl">
            Vendido para: {advanceUser.release_partner || "-"}
          </Text>
          <Text fontSize="xl">
            Data de vencimento:{" "}
            {advanceUser.dueDate
              ? dayjs(advanceUser.dueDate).format("DD/MM/YYYY HH:mm")
              : "-"}
          </Text>
          <Text fontSize="xl">
            Datas dos saldos solicitados:{" "}
            {advanceUser.datas_referencia &&
            advanceUser.datas_referencia.length > 0
              ? advanceUser.datas_referencia
                  .map((data) => dayjs(data).format("DD/MM/YYYY"))
                  .join(", ")
              : "-"}
          </Text>
        </VStack>
      </Flex>
      {advanceUser.status !== "approved" &&
      advanceUser.status !== "canceled" ? (
        <VStack gap={4} w="full" p={4}>
          <Text fontSize="xl">Adicione um comentário:</Text>
          <Textarea
            placeholder="*Opcional"
            onChange={(e) => setReason(e.target.value)}
          />
          <Text fontSize="xl">O que deseja fazer com a solicitação?</Text>
          <Flex gap={4}>
            <Button
              onClick={handleCancel}
              isDisabled={loading}
              bg="red.600"
              _hover={{
                bg: "red.700",
              }}
            >
              Cancelar proposta
            </Button>
            <Button
              onClick={handleApproval}
              isDisabled={loading}
              bg="green.600"
              _hover={{
                bg: "green.700",
              }}
            >
              Aprovar proposta
            </Button>
          </Flex>
        </VStack>
      ) : (
        <VStack
          w="full"
          gap={4}
          p={4}
          border="1px"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderColor={colorMode === "dark" ? "" : "gray.300"}
        >
          <Text fontSize="xl">
            O adiantamento foi encerrado com o status:{" "}
            <Badge
              variant="solid"
              fontSize="0.8em"
              px={5}
              bg={colorSchemeMap[advanceUser.status]}
            >
              {textSchemeMap[advanceUser.status]}
            </Badge>
          </Text>
          <Text fontSize="xl">
            Última atualização:{" "}
            {advanceUser.statuses.length > 0
              ? dayjs(
                  advanceUser.statuses[advanceUser.statuses.length - 1]
                    .createdAt
                ).format("DD/MM/YYYY HH:mm")
              : "-"}
          </Text>
          <Text fontSize="xl">
            Atendido Por:
            {advanceUser.attendantName
              ? ` ${advanceUser.attendantName}`
              : " Sem registro"}
          </Text>
          {advanceUser.status === "approved" && (
            <Button
              isLoading={loadingReceipt}
              loadingText="Gerando PDF"
              variant="solid"
              px={4}
              py={2}
              borderRadius={9}
              colorScheme="linkedin"
              fontSize="md"
              onClick={() => getReceipt(advanceUser.id, advanceUser.username)}
              gap={4}
            >
              <Download weight="bold" size={24} />
              Comprovante
            </Button>
          )}
        </VStack>
      )}
    </VStack>
  ) : (
    <>
      <h1>Não encontrado</h1>
    </>
  );
}
