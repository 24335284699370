import { Heading, Text, VStack } from "@chakra-ui/layout";

export function SplitPaymentTab() {
  return (
    <VStack w="full" h="full" gap={4} p={8} borderRadius={8}>
      <Heading fontSize="3xl" textAlign="left">
        Ferramenta de Pagamentos
      </Heading>
      <Text fontSize="lg" textAlign="left">
        Assista ao nosso vídeo tutorial para entender como a ferramenta de
        pagamentos funciona.
      </Text>
      <iframe
        width="800px"
        height="500px"
        src="https://drive.google.com/file/d/1gCOVdDjQQ2W2nkjSr0Qjd8jKP5rQQYPV/preview"
        title="Video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </VStack>
  );
}
