import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentUserStore } from "../../store/current-user.store";
import { VStack } from "@chakra-ui/layout";
import { useCompaniesStore } from "../../store/company.store";
import { LoadingLogo } from "../loading";

function DashboardIframe() {
  const [url, setUrl] = useState<string | null>(null);
  const [title, setTitle] = useState<string>("");

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const user = useCurrentUserStore((state) => state.user);
  const companyPermissions = useCompaniesStore(
    (state) => state.companyPermissions
  );
  const getCompanyPermissions = useCompaniesStore(
    (state) => state.getCompanyPermissions
  );

  useEffect(() => {
    if (!companyPermissions && user) {
      getCompanyPermissions(Number(user?.companyId));
      return;
    }

    if (companyPermissions) {
      const companyPermission = companyPermissions.find(
        (cp) => cp?.key === pathname.split("/").pop()
      );

      if (
        companyPermission?.url &&
        companyPermission.key === "upload-relatorios"
      ) {
        setTitle(companyPermission.name);
        setUrl(companyPermission.url);

        navigate("/");

        window.open(companyPermission.url, "_blank");

        return;
      }

      setTitle(companyPermission?.name || "Dashboard");
      setUrl(companyPermission?.url as string);
    }
  }, [user, pathname, navigate, companyPermissions, getCompanyPermissions]);

  return (
    <VStack w="full" h="full" borderRadius={8}>
      {url ? (
        <iframe
          src={url}
          title={title}
          style={{ borderRadius: "8px", width: "100%", height: "100%" }}
        />
      ) : (
        <LoadingLogo />
      )}
    </VStack>
  );
}

export default DashboardIframe;
