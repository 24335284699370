import { useQuery } from "@tanstack/react-query";
import { IProductControlScheduledPayrollDto } from "../../dto/product-control-scheduled-payroll.dto";
import { useBaseCrud as baseCrud } from "./base-crud";
import { IPaginationOptions } from "../../dto/generics/pagination-options.dto";
import { ISearchProductControlScheduledPayrollDto } from "../../dto/search-product-control-scheduled-payroll.dto";

const baseURL = "/api/v1/product_control_scheduled_payroll";

export const productControlScheduledPayrollService = () => {
  return {
    ...baseCrud<IProductControlScheduledPayrollDto>(baseURL),
  };
};

export const productControlScheduledPayrollQuery = () => ({
  useFilter: (
    filter: Partial<IProductControlScheduledPayrollDto>,
    onError?: (error: Error) => Promise<void> | void,
    onSuccess?: (result: unknown) => Promise<void> | void
  ) =>
    useQuery({
      queryKey: ["product_control_scheduled_payroll", ...Object.values(filter)],
      queryFn: async () => {
        try {
          const result =
            await productControlScheduledPayrollService().filter(filter);

          if (onSuccess) {
            onSuccess(result);
          }
          return result;
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      },
    }),
  useFilterWithPagination: (
    filter: ISearchProductControlScheduledPayrollDto,
    paginationOptions: IPaginationOptions,
    onError?: (error: Error) => Promise<void> | void,
    onSuccess?: (result: unknown) => Promise<void> | void
  ) =>
    useQuery({
      queryKey: ["product_control_scheduled_payroll", ...Object.values(filter)],
      queryFn: async () => {
        try {
          const result =
            await productControlScheduledPayrollService().filterWithPagination(
              filter,
              paginationOptions
            );

          if (onSuccess) {
            onSuccess(result);
          }
          return result;
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      },
    }),
});
