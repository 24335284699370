import { HStack, VStack } from "@chakra-ui/layout";
import { Button, Progress } from "@chakra-ui/react";
import { useSyncCsvContext } from "../context/sync-csv.context";
import { DownloadSimple } from "@phosphor-icons/react";

const Component = () => {
  const {
    handleCleanFile,
    handleValidateCsv,
    validateLoading,
    isValidated,
    handleDownloadErros,
    handleCreateDeliverymen,
    createLoading,
  } = useSyncCsvContext();

  return (
    <VStack w={"full"} justifyContent={"space-between"} padding={5}>
      <HStack justifyContent={"space-around"} padding={5} w={"full"}>
        <Button
          isLoading={validateLoading}
          backgroundColor={"red"}
          onClick={handleCleanFile}
        >
          Cancelar
        </Button>
        {!isValidated && (
          <Button onClick={handleValidateCsv} isLoading={validateLoading}>
            Validar CSV
          </Button>
        )}
        {isValidated && (
          <Button
            leftIcon={<DownloadSimple />}
            colorScheme={"green"}
            onClick={handleDownloadErros}
            isLoading={validateLoading}
          >
            Baixar erros
          </Button>
        )}
        {isValidated && (
          <Button onClick={handleCreateDeliverymen} isLoading={createLoading}>
            Salvar entregadores
          </Button>
        )}
      </HStack>
      <Progress
        size={"sm"}
        isIndeterminate={validateLoading}
        colorScheme={"green"}
        w={"full"}
      />
    </VStack>
  );
};

export const FunctionsComponent = Component;
