import { VStack, Heading, Flex } from "@chakra-ui/layout";
import { LoginJarvisForm } from "../../../components/ifood/auth/login-jarvis-form";

export function LoginJarvisPage() {
  return (
    <VStack gap={8} flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Login Jarvis
      </Heading>
      <Flex
        w="full"
        gap={6}
        p={8}
        borderRadius={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <LoginJarvisForm />
      </Flex>
    </VStack>
  );
}
