import { Flex, Heading, ListItem, UnorderedList } from "@chakra-ui/layout";

import { UploadEscalesResponseErrors } from "../../../../../types/upload.type";
import { memo } from "react";

const Component = ({ errors }: { errors: UploadEscalesResponseErrors }) => {
  return (
    <div>
      {errors.missingColumns.columns.length ? (
        <Flex w="max-content" p={4} borderRadius={8} gap={2}>
          <Flex gap={2} borderRadius={4} p={4} flexDir="column">
            <Heading size="sm">Colunas não encontradas</Heading>
            <UnorderedList>
              <ListItem>
                <Flex shadow="xs" gap={4} bg="red.100" borderRadius={4} p={4}>
                  <Flex
                    gap={2}
                    flexDir="column"
                  >{`Erro: ${errors.missingColumns.message}`}</Flex>
                </Flex>
              </ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
      ) : null}
      {errors.formattingErrors.length ? (
        <Flex w="max-content" p={4} borderRadius={8} gap={2}>
          <Flex gap={2} borderRadius={4} p={4} flexDir="column">
            <Heading size="sm">Erros de formatação</Heading>
            {errors.formattingErrors.map((error) => (
              <UnorderedList key={error.rowIndex}>
                {error.errors.map((detailedError) => {
                  return (
                    <ListItem key={detailedError.field}>
                      <Flex
                        shadow="xs"
                        gap={4}
                        bg="red.100"
                        borderRadius={4}
                        p={4}
                      >
                        <Flex gap={2} flexDir="column">{`Linha: ${
                          error.rowIndex + 1
                        }`}</Flex>
                        <Flex
                          gap={2}
                          flexDir="column"
                        >{`Campo: ${detailedError.field}`}</Flex>
                        <Flex gap={2} flexDir="column">{`Valor: ${
                          detailedError.field === "data"
                            ? detailedError.value.substring(0, 10)
                            : detailedError.value
                        }`}</Flex>
                        <Flex
                          gap={2}
                          flexDir="column"
                        >{`Erro: ${detailedError.message}`}</Flex>
                      </Flex>
                    </ListItem>
                  );
                })}
              </UnorderedList>
            ))}
          </Flex>
        </Flex>
      ) : null}
      {errors.duplicate.length ? (
        <Flex w="max-content" p={4} borderRadius={8} gap={2}>
          <Flex gap={2} borderRadius={4} p={4} flexDir="column">
            <Heading size="sm">Agendamentos duplicados na planilha</Heading>
            {errors.duplicate.map((error) => (
              <UnorderedList key={error.rowIndex}>
                <ListItem>
                  <Flex shadow="xs" gap={4} bg="red.100" borderRadius={4} p={4}>
                    <Flex gap={2} flexDir="column">{`Linha: ${
                      error.rowIndex + 1
                    }`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Turno: ${error.value.turno}`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Subpraça: ${error.value.subpraca}`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Data: ${error.value.data.substring(0, 10)}`}</Flex>
                  </Flex>
                </ListItem>
              </UnorderedList>
            ))}
          </Flex>
        </Flex>
      ) : null}
      {errors.shiftsNotFound.length ? (
        <Flex w="max-content" p={4} borderRadius={8} gap={2}>
          <Flex gap={2} borderRadius={4} p={4} flexDir="column">
            <Heading size="sm">Turnos não encontrados</Heading>
            {errors.shiftsNotFound.map((error) => (
              <UnorderedList key={error.rowIndex}>
                <ListItem>
                  <Flex shadow="xs" gap={4} bg="red.100" borderRadius={4} p={4}>
                    <Flex gap={2} flexDir="column">{`Linha: ${
                      error.rowIndex + 1
                    }`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                      fontWeight="bold"
                    >{`Turno: ${error.value.turno}`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Subpraça: ${error.value.subpraca}`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Data: ${error.value.data.substring(0, 10)}`}</Flex>
                  </Flex>
                </ListItem>
              </UnorderedList>
            ))}
          </Flex>
        </Flex>
      ) : null}
      {errors.subplazasNotFound.length ? (
        <Flex w="max-content" p={4} borderRadius={8} gap={2}>
          <Flex gap={2} borderRadius={4} p={4} flexDir="column">
            <Heading size="sm">Subpraças não encontradas</Heading>
            {errors.subplazasNotFound.map((error) => (
              <UnorderedList key={error.rowIndex}>
                <ListItem>
                  <Flex shadow="xs" gap={4} bg="red.100" borderRadius={4} p={4}>
                    <Flex gap={2} flexDir="column">{`Linha: ${
                      error.rowIndex + 1
                    }`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Turno: ${error.value.turno}`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                      fontWeight="bold"
                    >{`Subpraça: ${error.value.subpraca}`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Data: ${error.value.data.substring(0, 10)}`}</Flex>
                  </Flex>
                </ListItem>
              </UnorderedList>
            ))}
          </Flex>
        </Flex>
      ) : null}
      {errors.notExpectedErrors.length ? (
        <Flex w="max-content" p={4} borderRadius={8} gap={2}>
          <Flex gap={2} borderRadius={4} p={4} flexDir="column">
            <Heading size="sm">Erros não esperados na planilha</Heading>
            {errors.notExpectedErrors.map((error) => (
              <UnorderedList key={error.rowIndex}>
                <ListItem>
                  <Flex shadow="xs" gap={4} borderRadius={4} p={4}>
                    <Flex gap={2} flexDir="column">{`Linha: ${
                      error.rowIndex + 1
                    }`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Turno: ${error.value.turno}`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Subpraça: ${error.value.subpraca}`}</Flex>
                    <Flex
                      gap={2}
                      flexDir="column"
                    >{`Data: ${error.value.data.substring(0, 10)}`}</Flex>
                  </Flex>
                </ListItem>
              </UnorderedList>
            ))}
          </Flex>
        </Flex>
      ) : null}
      {errors.alreadyCreated.length ? (
        <Flex w="max-content" p={4} borderRadius={8} gap={2}>
          <Flex gap={2} borderRadius={4} p={4} flexDir="column">
            <Heading size="sm">Agendamentos já criados</Heading>
            {errors.alreadyCreated.map((error) => (
              <UnorderedList key={error.rowIndex}>
                {error.alreadyCreated.map((shiftAssignment) => {
                  return (
                    <ListItem key={shiftAssignment.id}>
                      <Flex
                        shadow="xs"
                        gap={4}
                        bg="yellow.200"
                        borderRadius={4}
                        p={4}
                      >
                        <Flex gap={2} flexDir="column">{`Linha: ${
                          error.rowIndex + 1
                        }`}</Flex>
                        <Flex
                          gap={2}
                          flexDir="column"
                        >{`Erro: Já existe um agendamento criada com esses dados, agendamento de ID ${shiftAssignment.id}`}</Flex>
                        <Flex
                          gap={2}
                          flexDir="column"
                        >{`Turno: ${shiftAssignment.shiftId}`}</Flex>
                        <Flex
                          gap={2}
                          flexDir="column"
                        >{`Subpraça: ${shiftAssignment.subplazaId}`}</Flex>
                        <Flex
                          gap={2}
                          flexDir="column"
                        >{`Data: ${shiftAssignment.dateScale.substring(
                          0,
                          10
                        )}`}</Flex>
                      </Flex>
                    </ListItem>
                  );
                })}
              </UnorderedList>
            ))}
          </Flex>
        </Flex>
      ) : null}
    </div>
  );
};

export const ErrorContainer = memo(Component);
