import { Plaza } from "../../dto/plaza-dto";
import { Service, useBaseCrud } from "./base-crud";
import { useCachedService } from "./use-cached-service";

const baseURL = "/api/v1/plazas";

export const usePlazaService = () => {
  return {
    ...useBaseCrud<Plaza>(baseURL),
  };
};

export const usePlazaCachedService = () => {
  const plazaService = usePlazaService() as unknown as Service<Plaza>;

  return useCachedService(plazaService, "plazas");
};
