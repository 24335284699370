import { useEffect, useState, useRef } from "react";
import { Divider, Flex, VStack } from "@chakra-ui/layout";
import { Link, useLocation } from "react-router-dom";
import { House, SignOut, List as ListIcon } from "@phosphor-icons/react";
import { isValidMotionProp, motion, useAnimate } from "framer-motion";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Image,
  chakra,
  List,
  ListItem,
  shouldForwardProp,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";
import {
  useCurrentUserStore,
  UserStore,
} from "../../../store/current-user.store";
import { authenticatedCompaniesRoutes } from "../../../routes/authenticated-enterprises-routes";
import { CustomRouteProps } from "../../../routes/authenticated-support-routes";
import { SideBarButton } from "../side-bar-button";
import { useQuery } from "@tanstack/react-query";
import { convertCentsToReais } from "../../../utils/convert-money";
import LogoImage from "../../../assets/logo/trampay-rings.svg";
import { useCompaniesStore } from "../../../store/company.store";
import { PermissionsEnum } from "../../../enums/permissions.enum";
import { CompanyDto } from "../../../dto/company-dto";
import { isFranchisor } from "../../../utils/company-verifications";

const ChakraBox = chakra(motion.aside, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export function SideBar() {
  const [isHovered, setIsHovered] = useState(false);
  const [scope, animate] = useAnimate();
  const { pathname } = useLocation();
  const signOut = useCurrentUserStore((state: UserStore) => state.signOut);

  const [green500] = useToken("colors", ["green.500", "whiteAlpha.800"]);

  const accountInfoRef = useRef<HTMLDivElement>(null);

  const getCompanyPermissions = useCompaniesStore(
    (state) => state.getCompanyPermissions
  );
  const getPermissions = useCurrentUserStore((state) => state.getPermissions);
  const getBankInfo = useCurrentUserStore((state) => state.getBankInfo);
  const me = useCurrentUserStore((state) => state.user);

  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(
    ["permissions"],
    getPermissions,
    { refetchOnMount: "always" }
  );

  const { data: companyPermissions } = useQuery(
    ["company-permissions"],
    () => getCompanyPermissions(),
    {
      refetchOnMount: "always",
    }
  );

  const hasPermission = permissions?.some(
    (permission) => permission.key === PermissionsEnum["Saldos/Débitos OL"]
  );

  const { data: bankInfo } = useQuery(["bankInfo"], getBankInfo, {
    enabled: hasPermission,
  });

  const availableRoutesForThisUser = authenticatedCompaniesRoutes.flatMap(
    (route) => {
      if (isLoadingPermissions) {
        return [];
      }

      const hasPermission = permissions?.find(
        (permission) => permission.key === route.key
      );

      if (hasPermission) {
        if (route.type === "external" && hasPermission.url) {
          route.path = hasPermission.url;
        }
        if (route.checkPermission) {
          const hasCompanyPermissions = companyPermissions?.find(
            (cp) => cp.key === route.key
          );

          if (hasCompanyPermissions) return [route];
        } else {
          return [route];
        }
      }

      return [];
    }
  );

  function verifyPaymentsIndexPath(
    route: CustomRouteProps,
    company: CompanyDto
  ) {
    const basePath = route?.path || "#";

    if (route.name === "Pagamentos") {
      if (isFranchisor(company)) {
        return `${basePath}/is-processed`;
      }
    }

    return basePath;
  }

  // animations
  useEffect(() => {
    if (isHovered) {
      (async () => {
        if (accountInfoRef.current) {
          animate(
            accountInfoRef.current,
            {
              opacity: 1,
              display: "flex",
            },
            {
              duration: 0.8,
            }
          );
        }
        await animate(
          scope.current,
          {
            minWidth: "300px",
            width: "300px",
          },
          {
            onComplete: () => {
              animate("p", {
                opacity: 1,
                display: "block",
              });
            },
          }
        );
      })();
    } else {
      (async () => {
        if (accountInfoRef.current) {
          animate(accountInfoRef.current, {
            opacity: 0,
            display: "none",
          });
        }
        await animate(
          "p",
          {
            display: "none",
            opacity: 0,
          },
          { duration: 0.01 }
        );
        await animate(scope.current, {
          minWidth: "80px",
          width: "80px",
        });
        await animate(
          "p",
          {
            display: "none",
            opacity: 0,
          },
          { duration: 0.01 }
        );
      })();
    }
  }, [isHovered, animate, scope, permissions, companyPermissions]);

  return (
    <ChakraBox
      bg="blackAlpha.900"
      display="flex"
      flexDir="column"
      gap={2}
      p={4}
      alignItems="center"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      ref={scope}
      position="sticky"
      top={0}
      h="100vh"
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "16px",
          // background: ,
          padding: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "24px",
          background: green500,
          width: "16px",
        },
      }}
    >
      <Image
        src={LogoImage}
        alt="Trampay logo com titulo"
        w="full"
        maxW="80px"
        pb={4}
      />

      <Flex
        flexDir="column"
        color="whiteAlpha.900"
        alignItems="center"
        w="full"
        ref={accountInfoRef}
        pb={4}
      >
        <List gap={2} textAlign="center">
          <ListItem>
            <strong>Empresa: </strong>
            {me?.company?.name}
          </ListItem>
          {hasPermission && bankInfo ? (
            <>
              <ListItem>
                <strong>Agência: </strong>
                {bankInfo?.mainAccount?.bankBranchNumber}
              </ListItem>
              <ListItem>
                <strong>Conta: </strong>
                {bankInfo?.mainAccount?.bankAccountNumber}
              </ListItem>
              <ListItem fontSize="2xl" color="green.300">
                <strong>Saldo: </strong>
                {convertCentsToReais(
                  bankInfo?.mainAccount?.availableBalanceInCents || 0
                )}
                <Button
                  as={Link}
                  to="/settings/add-balance"
                  bg="green.600"
                  w="max-content"
                  color="whiteAlpha.900"
                  _hover={{
                    bg: "green.700",
                  }}
                  px={8}
                  size="md"
                  alignSelf="flex-end"
                >
                  Adicionar saldo
                </Button>
              </ListItem>{" "}
            </>
          ) : null}
        </List>
      </Flex>

      <Divider />

      <VStack
        alignItems={isHovered ? "flex-start" : "center"}
        w="full"
        color="gray.50"
        pt={4}
      >
        <SideBarButton
          isHovered={isHovered}
          isSelected={pathname === "/"}
          to="/"
          h={12}
        >
          <House weight="bold" size={20} />
          <p style={{ opacity: 0 }}>Inicio</p>
        </SideBarButton>
        {!isLoadingPermissions
          ? availableRoutesForThisUser
              .sort((a, b) => {
                if (a.name && b.name) {
                  return a.name.localeCompare(b.name);
                }
                return 0;
              })
              .map((route, index) => (
                <SideBarButton
                  isHovered={isHovered}
                  isSelected={
                    route?.path
                      ? pathname.split("/").includes(route?.path)
                      : false
                  }
                  key={route?.key || index}
                  to={verifyPaymentsIndexPath(route, me?.company as CompanyDto)}
                  target={route?.type === "external" ? "_blank" : "_self"}
                  h={12}
                >
                  {route?.icon}
                  <p style={{ opacity: 0 }}>{route?.name}</p>
                </SideBarButton>
              ))
          : null}

        <Button
          bg="transparent"
          w={isHovered ? "full" : "min-content"}
          p={2}
          gap={4}
          justifyContent={isHovered ? "flex-start" : "center"}
          size="lg"
          _hover={{
            bg: "whiteAlpha.600",
          }}
          onClick={signOut}
          h={12}
        >
          <SignOut weight="bold" size={20} />
          <p style={{ opacity: 0 }}>Logout</p>
        </Button>
      </VStack>
    </ChakraBox>
  );
}

export function SideBarMobile() {
  const [availableMenuItems, setAvailableMenuItems] = useState<
    Partial<CustomRouteProps[]>
  >([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);

  const signOut = useCurrentUserStore((state: UserStore) => state.signOut);

  const me = useCurrentUserStore((state) => state.user);

  const refreshData = useCurrentUserStore((state) => state.refreshData);

  const getPermissions = useCurrentUserStore((state) => state.getPermissions);

  const permissions = useCurrentUserStore(
    (state: UserStore) => state.permissions
  );

  const { pathname } = useLocation();

  useEffect(() => {
    if (permissions.length === 0) getPermissions();

    if (!me) refreshData();
  }, [getPermissions, permissions.length, refreshData, me]);

  useEffect(() => {
    const availableRoutesForThisUser = authenticatedCompaniesRoutes.flatMap(
      (route) => {
        const hasPermission = permissions.find(
          (permission) => permission.key === route.key
        );

        if (hasPermission) {
          if (route.type === "external" && hasPermission.url) {
            route.path = hasPermission.url;
          }
          return [route];
        }

        return [];
      }
    );

    if (availableRoutesForThisUser)
      setAvailableMenuItems(availableRoutesForThisUser);
  }, [permissions]);

  return (
    <>
      <Flex
        bg="blackAlpha.800"
        justifyContent="center"
        position="relative"
        alignItems="center"
        p={2}
      >
        <Button
          ref={btnRef}
          colorScheme="green"
          onClick={onOpen}
          left={0}
          position="absolute"
          h="full"
          px={6}
          borderRadius={0}
        >
          <ListIcon weight="bold" size={24} />
        </Button>
        <Image
          src={LogoImage}
          alt="Trampay logo com titulo"
          w="full"
          maxW="80px"
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg="blackAlpha.900">
          <DrawerCloseButton />
          <DrawerHeader display="flex" justifyContent="center">
            <Image
              src={LogoImage}
              alt="Trampay logo com titulo"
              w="full"
              maxW="80px"
            />
          </DrawerHeader>

          <DrawerBody alignItems="flex-start" w="full" color="gray.50">
            <SideBarButton
              isHovered={false}
              isSelected={pathname === "/"}
              to="/"
              w="full"
            >
              <House weight="bold" size={20} />
              Inicio
            </SideBarButton>
            {availableMenuItems.length > 0
              ? availableMenuItems.map((route) => (
                  <SideBarButton
                    isHovered={false}
                    isSelected={
                      route?.path
                        ? pathname.split("/").includes(route?.path)
                        : false
                    }
                    key={route?.key}
                    to={route?.path || "#"}
                    target={route?.type === "external" ? "_blank" : "_self"}
                    w="full"
                  >
                    {route?.icon}
                    {route?.name}
                  </SideBarButton>
                ))
              : null}
          </DrawerBody>

          <DrawerFooter>
            <Button
              bg="transparent"
              w="full"
              gap={4}
              _hover={{
                bg: "whiteAlpha.600",
              }}
              color={"white"}
              onClick={signOut}
            >
              <SignOut weight="bold" size={20} />
              Logout
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
