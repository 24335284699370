import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { Flex } from "@chakra-ui/layout";
import { DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useReprocessFinancialWorksheets } from "../../../hooks/enterprises/performance-and-balances/use-reprocess-financial-worksheets";
import {
  UploadResponseErrors,
  UploadResponseResults,
} from "../../../types/upload.type";
import dayjs from "dayjs";
import { useConfigurationService } from "../../../services/api/configuration";

type IProps = {
  setResults: Dispatch<SetStateAction<UploadResponseResults[]>>;
  setErrors: Dispatch<SetStateAction<UploadResponseErrors | null>>;
  reprocessUuid: MutableRefObject<string | null>;
  isActivated: boolean;
};

const referenceDateZod = z.object({
  period: z.object({
    from: z.date(),
    to: z.date().optional(),
  }),
});

type ReferenceDateInput = z.infer<typeof referenceDateZod>;

export function ReprocessFinancialWorksheets({
  setErrors,
  setResults,
  reprocessUuid,
  isActivated,
}: IProps) {
  const reprocessModal = useDisclosure({ id: "reprocess-financial-files" });
  const configurationService = useConfigurationService();
  const toast = useToast();

  const { reprocessFinancialWorksheets, loadingReprocess } =
    useReprocessFinancialWorksheets({
      setResults,
      setErrors,
      reprocessUuid,
      closeModal: reprocessModal.onClose,
    });

  const {
    handleSubmit,
    formState: { isDirty },
    control,
  } = useForm<ReferenceDateInput>({
    resolver: zodResolver(referenceDateZod),
  });

  const submit = async ({
    period: { from, to },
  }: {
    period: {
      from: Date;
      to?: Date;
    };
  }) => {
    const COMPANY_CAN_SEND_BALANACE_ID = "companies_can_send_balance";
    const configuration = await configurationService.show(
      COMPANY_CAN_SEND_BALANACE_ID
    );

    if (configuration?.value !== "true") {
      const closedMessage = await configurationService.show(
        "phrase_closed_send_balance"
      );

      toast({
        title: "Subida de saldos indisponível.",
        description:
          closedMessage?.value ||
          "Não é possível subir saldos no momento, tente novamente em alguns minutos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!to) to = from;

    from = dayjs(from).set("hour", 9).toDate();
    to = dayjs(to).set("hour", 9).toDate();
    reprocessFinancialWorksheets(from, to);
  };

  return (
    <>
      {isActivated && (
        <Flex alignItems="center" gap={4}>
          <Tooltip
            hasArrow
            placement="top"
            label="Somente é possível reprocessar as planilhas caso sua empresa tenha enviado as planilhas pelo menos uma vez hoje."
            fontSize="md"
          >
            <Icon fontSize={20} color="black" />
          </Tooltip>
          <Button
            isLoading={loadingReprocess}
            loadingText="Reprocessando"
            disabled={loadingReprocess}
            size="lg"
            w={["full", "auto"]}
            color="white"
            onClick={reprocessModal.onOpen}
          >
            Reprocessar últimas planilhas
          </Button>
        </Flex>
      )}
      <Modal
        isOpen={reprocessModal.isOpen}
        onClose={reprocessModal.onClose}
        size="5xl"
        id="upload-files"
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader>Reprocessamento das planilhas</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <Flex gap={16} flexDir="column">
              <Flex
                justifyContent="center"
                gap={4}
                as="form"
                id="reprocess"
                onSubmit={handleSubmit(submit)}
              >
                <FormControl as="fieldset" w="max-content">
                  <FormLabel
                    as="legend"
                    fontFamily="monospace"
                    fontSize="md"
                    mb={10}
                  >
                    Escolha as datas de referência que deseja reprocessar
                  </FormLabel>
                  <Controller
                    control={control}
                    name="period"
                    render={({ field: { value, onChange } }) => (
                      <FormControl
                        as="fieldset"
                        w="full"
                        display="flex"
                        flexDir="column"
                        gap={4}
                        alignItems="center"
                        backgroundColor="#f8f8f8"
                        borderRadius="2xl"
                        boxShadow="4px 6px 6px 0px rgba(0,0,0,0.75)"
                      >
                        <DayPicker
                          id="dateFilter"
                          mode="range"
                          selected={value}
                          onSelect={onChange}
                          locale={ptBR}
                        />
                      </FormControl>
                    )}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter justifyContent="space-evenly" my={4}>
            <Button variant="ghost" mr={3} onClick={reprocessModal.onClose}>
              Fechar
            </Button>
            <Button
              color="white"
              type="submit"
              form="reprocess"
              isDisabled={!isDirty || loadingReprocess}
              isLoading={loadingReprocess}
              loadingText="Reprocessando"
            >
              Reprocessar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
