import { create } from "zustand";
import { CreateDebt, Debt } from "../dto/debt-dto";
import {
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../services/basicService";
import { DebtStatus } from "../enums/debt-status";
import { DebtType } from "../enums/debt-type";

interface DebtStoreProps {
  result: Debt[];
  totalRecords: number;
  totalPages: number;
  nextPage: boolean;
  debt: Debt | null;
  openDebts: Debt[];
  scheduledDebts: Debt[];
  totalOpenAmount: number;
  totalScheduledAmount: number;

  createDebt: (payload: CreateDebt) => Promise<void>;
  getDebtsByCompanyId: ({
    filters,
    page,
    limit,
    id,
  }: {
    filters?: {
      startDate?: string;
      endDate?: string;
      companyFranchiseeId?: string;
      type: DebtType;
    };
    page?: number;
    limit?: number;
    id: number;
  }) => Promise<Debt[]>;
  getDebtById: (id: number) => Promise<void>;
  getAll: ({
    filters,
    page,
    limit,
  }: {
    filters?: {
      startDate?: string;
      endDate?: string;
      companyId?: string;
      type?: DebtType;
    };
    page?: number;
    limit?: number;
  }) => Promise<Debt[]>;
  getOpenedOrScheduledDebts: () => Promise<{
    openDebts: Debt[];
    scheduledDebts: Debt[];
    totalOpenAmount: number;
    totalScheduledAmount: number;
  }>;
  cancelDebt: (debtId: number) => Promise<void>;
}

export const useDebtStore = create<DebtStoreProps>((set) => ({
  result: [],
  totalRecords: 0,
  totalPages: 0,
  nextPage: false,
  debt: null,
  openDebts: [],
  scheduledDebts: [],
  totalOpenAmount: 0,
  totalScheduledAmount: 0,

  createDebt: async (debt: CreateDebt) => {
    await postWithAuth("/api/v1/debt/", debt);
  },

  getDebtsByCompanyId: async ({ filters, page, limit, id }) => {
    const UrlSearchParams = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });

    if (filters?.startDate) {
      UrlSearchParams.append("startDate", String(filters.startDate));
    }

    if (filters?.endDate) {
      UrlSearchParams.append("endDate", String(filters.endDate));
    }

    if (filters?.companyFranchiseeId) {
      UrlSearchParams.append(
        "companyFranchiseeId",
        String(filters.companyFranchiseeId)
      );
    }

    if (filters?.type) {
      UrlSearchParams.append("type", String(filters.type));
    }

    try {
      const response = await getWithAuth(
        `/api/v1/debt/allDebts/${id}?${UrlSearchParams.toString()}`
      );

      set({
        result: response?.data.result,
        nextPage: response?.data.nextPage,
        totalPages: response?.data.totalPages,
        totalRecords: response?.data.totalRecords,
      });

      return response?.data.result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getDebtById: async (id: number) => {
    try {
      const response = await getWithAuth(`/api/v1/debt/${id}`);

      set({
        debt: response?.data,
      });

      return response?.data.result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getAll: async ({ filters, page, limit }) => {
    const UrlSearchParams = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });

    if (filters?.startDate) {
      UrlSearchParams.append("startDate", String(filters.startDate));
    }

    if (filters?.endDate) {
      UrlSearchParams.append("endDate", String(filters.endDate));
    }

    if (filters?.companyId) {
      UrlSearchParams.append("companyId", String(filters.companyId));
    }

    if (filters?.type) {
      UrlSearchParams.append("type", String(filters.type));
    }

    try {
      const response = await getWithAuth(
        `/api/v1/debt?${UrlSearchParams.toString()}`
      );

      set({
        result: response?.data.result,
        nextPage: response?.data.nextPage,
        totalPages: response?.data.totalPages,
        totalRecords: response?.data.totalRecords,
      });

      return response?.data.result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getOpenedOrScheduledDebts: async () => {
    let openDebts = [] as Debt[];
    let scheduledDebts = [] as Debt[];
    let totalOpenAmount = 0;
    let totalScheduledAmount = 0;

    try {
      const response = await getWithAuth(`/api/v1/debt/allOpenedOrScheduled`);

      if (response?.data) {
        const debts = response?.data as Debt[];

        openDebts = debts.filter((debt) => debt.status === DebtStatus.OPEN);
        scheduledDebts = debts.filter(
          (debt) => debt.status === DebtStatus.SCHEDULED
        );
        totalOpenAmount = openDebts.reduce(
          (total, debt) => total + debt.amount,
          0
        );
        totalScheduledAmount = scheduledDebts.reduce(
          (total, debt) => total + debt.amount,
          0
        );

        set({
          openDebts,
          scheduledDebts,
          totalOpenAmount,
          totalScheduledAmount,
        });
      }

      return {
        openDebts,
        scheduledDebts,
        totalOpenAmount,
        totalScheduledAmount,
      };
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  cancelDebt: async (debtId: number) => {
    await patchWithAuth(`/api/v1/debt/cancel/${debtId}`);
  },
}));
