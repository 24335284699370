import { VStack, Heading, Text, Box, Flex } from "@chakra-ui/layout";
import {
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
  useToast,
  IconButton,
  Link as ChakraLink,
  FormControl,
  FormLabel,
  Select,
  Button,
} from "@chakra-ui/react";
import { usePayrollStore } from "../../../store/payroll.store";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ArrowFatLinesRight } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { COMPANY_TYPE, CompanyDto } from "../../../dto/company-dto";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { getWithAuth } from "../../../services/basicService";
import { z } from "zod";
import { TRAMPAY_COMPANY_ID } from "../../../utils/constants/trampay-company-id";
dayjs.extend(utc);

const filtersFormSchema = z.object({
  companyId: z
    .string()
    .transform((value) => {
      if (value) return Number(value);
    })
    .optional(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

export function PayrollProcessing() {
  const me = useCurrentUserStore((state) => state.user);
  const isFranchisor = me?.company?.companyType === COMPANY_TYPE.FRANQUEADORA;
  const [searchParams, setSearchParams] = useSearchParams({
    companyId: me?.companyId.toString() || "",
  });

  const getProcessingPayroll = usePayrollStore(
    (state) => state.getProcessingPayroll
  );

  const { handleSubmit, register } = useForm<filtersFormInput>({
    defaultValues: {
      companyId: undefined,
    },
  });

  const toast = useToast();

  const navigate = useNavigate();

  async function onSubmit(data: filtersFormInput) {
    setSearchParams((prev) => {
      if (data?.companyId) {
        prev.set("companyId", data?.companyId.toString());
      } else {
        prev.set("companyId", me?.companyId.toString() || "");
      }

      return prev;
    });
  }

  const { data } = useQuery({
    queryKey: ["payroll-processing-sheets", Array.from(searchParams)],
    queryFn: async () => {
      try {
        return me?.companyId === TRAMPAY_COMPANY_ID
          ? await getProcessingPayroll()
          : await getProcessingPayroll(
              isFranchisor
                ? Number(searchParams.get("companyId"))
                : me?.companyId
            );
      } catch (error) {
        if (error instanceof AxiosError) {
          toast({
            status: "error",
            title: "Erro ao buscar informações",
            description: `${error?.response?.data.message}`,
          });
          console.log(error);
        }
      }
    },
  });

  const payrollProcessing = usePayrollStore((state) => state.result);

  const fetchFranchiseesOfCompany = useQuery(
    ["company-franchisees"],
    async () => {
      try {
        const response = await getWithAuth(
          `/api/v1/companies/franchisees/${me?.company?.id}`
        );
        return response?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: isFranchisor,
      initialData: () => ({ id: null, franchisees: [] as CompanyDto[] }),
    }
  );

  return (
    <VStack
      gap={8}
      w="full"
      flex={1}
      alignItems="flex-start"
      style={{ containerType: "inline-size" }}
    >
      <Heading fontWeight="extrabold" textAlign="left">
        Folha em processamento
      </Heading>

      {payrollProcessing.length > 0 ? (
        <Box
          w="full"
          display="flex"
          flexDir="column"
          gap={4}
          flex={1}
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
        >
          {isFranchisor ? (
            <Flex
              as="form"
              flexDir="column"
              w="full"
              gap={8}
              p={8}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Flex
                justifyContent="space-between"
                flexDirection={{ base: "column", md: "row" }}
                gap={8}
              >
                <Flex gap={8} alignItems="end">
                  <>
                    <FormControl as="fieldset">
                      <FormLabel as="legend">Franqueada</FormLabel>
                      <Select
                        {...register("companyId")}
                        placeholder="Escolha uma franqueada"
                        _placeholder={{
                          color: "gray.500",
                        }}
                      >
                        {fetchFranchiseesOfCompany?.data?.franchisees.map(
                          (company: CompanyDto) => (
                            <option value={company.id} key={company.id}>
                              {company.name}
                            </option>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </>
                </Flex>
                <Button
                  type="submit"
                  bg="green.600"
                  w="max-content"
                  color="whiteAlpha.900"
                  _hover={{
                    bg: "green.700",
                  }}
                  px={8}
                  size="md"
                >
                  Filtrar
                </Button>
              </Flex>
            </Flex>
          ) : null}
          <TableContainer w="full" gap={4} display="flex" flexDir="column">
            <Table>
              <Thead>
                <Tr>
                  <Th w="25%">Data de envio</Th>
                  <Th w="50%">Nome da folha</Th>
                  {me?.companyId === 1 ? <Th>Empresa</Th> : null}
                  <Th w="25%" textAlign="center">
                    Consultar
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.result.map((payroll) => (
                  <Tr
                    key={payroll.id}
                    cursor="pointer"
                    onClick={() =>
                      navigate(`../details/${payroll.id}`, {
                        relative: "path",
                      })
                    }
                  >
                    <Td>{dayjs(payroll.created_at).format("DD/MM/YYYY")}</Td>
                    <Td>{payroll.name}</Td>
                    {me?.companyId === 1 ? <Td>{payroll.company_id}</Td> : null}
                    <Td textAlign="center">
                      <IconButton
                        aria-label="botão para ir para detalhes da folha em processamento"
                        role="a"
                        size="lg"
                        bg="green.500"
                        icon={<ArrowFatLinesRight />}
                        color="white"
                        w="50%"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Text fontSize="md">
          Nenhuma folha em processamento no momento. Caso queira criar uma nova,
          clique{" "}
          <ChakraLink as={Link} color="green" to="../create-transactions">
            aqui.
          </ChakraLink>
        </Text>
      )}
    </VStack>
  );
}
