import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { z } from "zod";
import { usePlazaCachedService } from "../../../../../services/api/plaza";
import { Plaza } from "../../../../../dto/plaza-dto";
import { MutateFunction } from "../../../../../services/api/use-cached-service";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { convertTimeToTimeWithTimeZone } from "../../../../../utils/date";

const formFields: FormField[] = [
  {
    label: "Nome",
    name: "name",
    type: "text",
  },
  {
    label: "Aceite",
    name: "acceptance",
    type: "select",
    options: [
      { value: "automatic", label: "automático" },
      { value: "manually", label: "manual" },
    ],
  },
  {
    label: "Publicar agendamento antes (dias)",
    name: "publishScaleDaysBefore",
    type: "number",
  },
  {
    label: "Publicar agendamento às",
    name: "publishScaleAt",
    type: "time",
  },
];

const formSchema = z.object({
  name: z.string(),
  acceptance: z.string(),
  publishScaleDaysBefore: z.string().optional(),
  publishScaleAt: z.string().optional(),
});

type FormType = Plaza & {
  acceptance: string;
  publishScaleDaysBefore?: number;
  publishScaleAt?: string;
};

interface Props {
  onCreated: () => void;
}

export function PlazaFormCreate(props: Props) {
  const { onCreated } = props;

  const { useMutationAction: usePlazaMutationAction } = usePlazaCachedService();
  const [createPlaza, watchers] = usePlazaMutationAction("create");

  const toast = useToast();

  useEffect(() => {
    const { status, error } = watchers;
    if (status) {
      if (status === "success") {
        onCreated();
      }
      if (status === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao criar praça",
          description: `Falha ao criar praça: ${error}`,
        });
      }
    }
  }, [onCreated, toast, watchers, watchers.status]);

  function buildHandleSubmit(createPlaza: MutateFunction) {
    return (formData: FormType) => {
      const { acceptance, publishScaleDaysBefore, publishScaleAt } = formData;
      formData.setting = {
        acceptance,
      };

      if (publishScaleDaysBefore && publishScaleAt) {
        formData.setting.publishScaleBefore = {
          daysBefore: publishScaleDaysBefore,
          at: convertTimeToTimeWithTimeZone(publishScaleAt),
        };
      }

      createPlaza(formData);
    };
  }

  const handleSubmit = buildHandleSubmit(createPlaza) as (
    data: unknown
  ) => void;

  return (
    <GenericForm
      fields={formFields}
      formSchema={formSchema}
      initialData={{}}
      actionLabel="Salvar"
      onSubmit={handleSubmit}
    />
  );
}
