import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Table,
  Spinner,
  Badge,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getWithAuth } from "../../../../services/basicService";
import { useParams } from "react-router-dom";
import { Pagination } from "../../../../components/pagination";
import { useState } from "react";
import { Status } from "../../../../dto/user-dto";

enum STATUS {
  "Active" = "ativo",
  "Inactive" = "inativo",
}

export interface IStatusChangeHistory {
  id: number;
  status: Status;
  origin: "INTERNAL" | "DASHBOARD";
  reason: string;

  createdAt: Date;
}

export function UserStatusHistoryPanel() {
  const { id } = useParams();
  const [page, setPage] = useState(1);

  const { data, isLoading } = useQuery({
    queryKey: ["user-status-history", id, page],
    queryFn: async () => {
      const response = await getWithAuth(
        `/api/v1/users/status-history/${id}?page=${page}`
      );

      return response?.data;
    },
  });

  return (
    <TableContainer>
      <Table variant="striped" colorScheme="green">
        <Thead>
          <Tr>
            <Th>Origem</Th>
            <Th>Razão</Th>
            <Th>Status</Th>
            <Th>Data de mudança</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading ? (
            data?.status.map((status: IStatusChangeHistory) => (
              <Tr key={status.id}>
                <Td>{status.origin}</Td>
                <Td>{status.reason}</Td>
                <Td>
                  <Badge
                    variant="solid"
                    px={4}
                    colorScheme={
                      status.status?.name === "Active" ? "green" : "red"
                    }
                  >
                    {status.status
                      ? STATUS[status.status.name as keyof typeof STATUS]
                      : "undefined"}
                  </Badge>
                </Td>
                <Td>{dayjs(status.createdAt).locale("pt-BR").fromNow()}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4} textAlign="center">
                <Spinner />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Flex w="full" justifyContent="center" gap={3} pt={8}>
        <Pagination
          page={page}
          setPage={setPage}
          totalPages={data?.pages || 1}
        />
      </Flex>
    </TableContainer>
  );
}
