import { useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  VStack,
} from "@chakra-ui/layout";
import {
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  getWithAuth,
  patchWithAuth,
} from "../../../../../services/basicService";
import { Debt, debtColorMap, debtTextMap } from "../../../../../dto/debt-dto";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import { calculateRemainingDebt } from "../../functions/calculate-remaining-debt";
import { DebtStatus } from "../../../../../enums/debt-status";
import { CustomModal } from "../../../../../components/modal";
import { formatCPF } from "../../../../../utils/format-document";
import { Transaction } from "../../../../../dto/transactions-dto";

dayjs.extend(utc);

const createDebtFormSchema = z.object({
  debtDate: z.coerce
    .date()
    .pipe(z.date().min(dayjs().startOf("date").toDate()))
    .transform((date) => dayjs(date).utc().set("hour", 3).toDate()),
});

type createDebtFormInputs = z.infer<typeof createDebtFormSchema>;

export function DetailsInstallments() {
  const transactions = useRef<Transaction[]>();
  const editDebt = useRef<Debt>();

  const params = useParams();
  const transactionsDrawer = useDisclosure({ id: "transactions-drawer" });
  const editDebtModal = useDisclosure({ id: "edit-debit-deliveryman-modal" });

  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom",
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<createDebtFormInputs>({
    resolver: zodResolver(createDebtFormSchema),
  });

  const client = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: [`installmentsId-${params?.id}`],
    queryFn: async () => {
      const response = await getWithAuth(
        `/api/v1/debt/deliverymen/${params?.id}`
      );

      return response?.data as Debt[];
    },
  });

  const cancelMutatation = useMutation(
    (id: number) => patchWithAuth(`/api/v1/debt/cancel/${id}`),
    {
      onSuccess: () => {
        client.invalidateQueries([`installmentsId-${params?.id}`]);
        client.invalidateQueries(["deliverymen-debts"]);

        toast({
          status: "success",
          title: "Cancelamento realizado com sucesso",
          description: `O cancelamento do débito foi feito com sucesso!`,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) =>
        toast({
          status: "error",
          title: "Erro ao cancelar débito",
          description: `Erro: ${err?.response?.data.message}`,
        }),
    }
  );

  const editMutatation = useMutation(
    (body: { debtId: number; debt_date: Date }) =>
      patchWithAuth(`/api/v1/debt/edit/deliveryman`, body),
    {
      onSuccess: () => {
        client.invalidateQueries([`installmentsId-${params?.id}`]);
        client.invalidateQueries(["deliverymen-debts"]);

        reset();
        editDebtModal.onClose();

        toast({
          status: "success",
          title: "Requisição realizado com sucesso",
          description: `A edição foi realizada com sucesso!`,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) =>
        toast({
          status: "error",
          title: "Erro ao editar débito",
          description: `Erro: ${err?.response?.data.message}`,
        }),
    }
  );

  const handleCancel = (debtId: number) => {
    if (cancelMutatation.isLoading) return;

    cancelMutatation.mutate(debtId);
  };

  const handleEdit: SubmitHandler<createDebtFormInputs> = ({ debtDate }) => {
    if (editMutatation.isLoading) return;

    const body = {
      debtId: editDebt.current?.id as number,
      debt_date: dayjs(debtDate).toDate(),
    };

    editMutatation.mutate(body);
  };

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Flex flexDir="column" justifyContent="space-between" w="full">
        <Heading fontWeight="extrabold" textAlign="left">
          Débitos lançados
        </Heading>

        {!isLoading && (
          <Card fontWeight="extrabold" fontSize="xl">
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem w="100%" p={5} textAlign="center">
                Nome: {data?.[0].deliveryman?.nameIfood}
              </GridItem>
              <GridItem w="100%" p={5} textAlign="center">
                Documeto: {formatCPF(data?.[0].deliveryman?.document as string)}
              </GridItem>
              <GridItem w="100%" p={5} textAlign="center">
                ID Conta: {data?.[0].deliveryman?.accountId}
              </GridItem>
            </Grid>
          </Card>
        )}
      </Flex>

      {!isLoading && (
        <TableContainer
          w="full"
          p={8}
          gap={4}
          display="flex"
          flexDir="column"
          border="1px"
          borderRadius={8}
          bg={"whiteAlpha.800"}
          borderColor={"gray.300"}
          shadow="base"
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Valor</Th>
                <Th textAlign="center">Valor restante</Th>
                <Th textAlign="center">Data de débito</Th>
                <Th textAlign="center">Data de quitação</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Ações</Th>
                <Th textAlign="center">Transações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((debt) => (
                <Tr key={debt.id}>
                  <Td>{convertCentsToReais(debt.amount)}</Td>
                  <Td textAlign="center">
                    {convertCentsToReais(
                      debt.amount - calculateRemainingDebt(debt)
                    )}
                  </Td>
                  <Td textAlign="center">
                    {dayjs(debt.debt_date).format("DD/MM/YYYY")}
                  </Td>
                  <Td textAlign="center">
                    {debt.quit_date
                      ? dayjs(debt.quit_date).format("DD/MM/YYYY")
                      : "-"}
                  </Td>
                  <Td textAlign="center">
                    <Badge
                      colorScheme={debtColorMap[debt.status]}
                      variant="solid"
                      px={4}
                    >
                      {debtTextMap[debt.status]}
                    </Badge>
                  </Td>

                  <Td textAlign="center">
                    <Flex gap={2} justifyContent="center">
                      {debt.status === DebtStatus.CANCELED ||
                      debt.status === DebtStatus.CLOSED ? null : (
                        <>
                          <CustomModal
                            modalHeader="Cancelar débito"
                            modalBody="Realmente deseja cancelar este débito?"
                            confirmButtonText="Quero cancelar débito"
                            buttonText="Cancelar"
                            buttonProps={{
                              bg: "red.600",
                              color: "#fff",
                              w: "max-content",
                              _hover: {
                                bg: "red.400",
                              },
                              p: "5px",
                              size: "50px",
                              isLoading: cancelMutatation.isLoading,
                              isDisabled: cancelMutatation.isLoading,
                            }}
                            onConfirm={() => {
                              handleCancel(debt.id);
                            }}
                          />
                          <Button
                            onClick={() => {
                              editDebt.current = debt;
                              setValue(
                                "debtDate",
                                dayjs(debt.debt_date).format(
                                  "YYYY-MM-DD"
                                ) as unknown as Date
                              );
                              editDebtModal.onOpen();
                            }}
                          >
                            Editar
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Td>
                  <Td textAlign="center">
                    <Button
                      colorScheme="teal"
                      onClick={() => {
                        transactions.current = debt.transactions;

                        transactionsDrawer.onOpen();
                      }}
                    >
                      Open
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <HStack gap={8} pt={8} justifyContent="flex-end"></HStack>
        </TableContainer>
      )}

      <Drawer
        isOpen={transactionsDrawer.isOpen}
        placement="right"
        onClose={transactionsDrawer.onClose}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Data do débito: 26/02/2024</DrawerHeader>

          <DrawerBody>
            <TableContainer
              w="full"
              p={8}
              gap={4}
              display="flex"
              flexDir="column"
              border="1px"
              borderRadius={8}
              bg={"whiteAlpha.800"}
              borderColor={"gray.300"}
              shadow="base"
            >
              <Table>
                <Thead>
                  <Tr>
                    <Th>Conta origem</Th>
                    <Th>Conta destino</Th>
                    <Th>Valor</Th>
                    <Th textAlign="center">Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transactions.current?.map((transaction) => (
                    <Tr key={transaction.id}>
                      <Td>
                        {transaction.accountNameFrom
                          ? transaction.accountNameFrom
                          : transaction.accountIdFrom}
                      </Td>
                      <Td>
                        {transaction.accountNameTo
                          ? transaction.accountNameTo
                          : transaction.accountIdTo}
                      </Td>
                      <Td>{convertCentsToReais(transaction.amount)}</Td>
                      <Td textAlign="center">
                        <Badge
                          variant="solid"
                          px={4}
                          backgroundColor={
                            transaction.status === "success" ? "green" : "red"
                          }
                        >
                          {transaction.status}
                        </Badge>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={transactionsDrawer.onClose}
            >
              Voltar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Modal
        isOpen={editDebtModal.isOpen}
        onClose={editDebtModal.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(handleEdit)}>
          <ModalHeader>Editar data do débito</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              as="fieldset"
              name="debtDate"
              isInvalid={!!errors.debtDate}
              w="fit-content"
              isRequired
            >
              <FormLabel as="legend">Data Primeiro Vencimento</FormLabel>
              <Input
                placeholder="Select Date"
                type="date"
                {...register("debtDate")}
              />
              {errors.debtDate && (
                <FormErrorMessage>{errors.debtDate.message}</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Flex w="full" justifyContent="space-around">
              <Button
                colorScheme="green"
                type="submit"
                isLoading={editMutatation.isLoading}
                disabled={editMutatation.isLoading}
              >
                Salvar
              </Button>
              <Button
                colorScheme="red"
                onClick={editDebtModal.onClose}
                disabled={editMutatation.isLoading}
              >
                Cancelar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
