import { create } from "zustand";
import {
  ChangeDeliverymanStatus,
  createDeliveryMan,
  DeliverymanData,
  DeliverymanDto,
  EditDeliverymanDto,
} from "../dto/deliveryman-dto";
import {
  deleteWithAuth,
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../services/basicService";

interface DeliverymansStore {
  deliverymansData: DeliverymanData;
  deliveryman: DeliverymanDto | null;

  getAllDeliverymans: (url: string) => Promise<DeliverymanData>;
  getAllDeliverymansByCompany: (url: string) => Promise<DeliverymanData>;
  getDeliveryman: (id: number) => Promise<DeliverymanDto>;
  createDeliveryMan: (data: createDeliveryMan) => Promise<void>;
  deleteDeliveryman: (id: number) => Promise<void>;
  syncDataDeliveryman: (id: number) => Promise<void>;
  syncDeliverymen: (companyId: number) => Promise<void>;
  editDeliveryman: (data: EditDeliverymanDto) => Promise<void>;
  patchDeliverymanStatus: (data: ChangeDeliverymanStatus) => Promise<void>;
}

export const useDeliverymansStore = create<DeliverymansStore>((set) => ({
  deliverymansData: {} as DeliverymanData,
  deliveryman: null,

  getAllDeliverymans: async (url: string) => {
    const response = await getWithAuth(url);

    if (response) {
      const deliverymansData = response?.data;

      set({ deliverymansData });

      return deliverymansData;
    }

    return {} as DeliverymanData;
  },

  getDeliveryman: async (id: number) => {
    const response = await getWithAuth(`/api/v1/deliveryMan/${id}`);

    if (response) {
      const deliveryman = response?.data;

      set({ deliveryman });

      return deliveryman;
    }

    return null;
  },

  getAllDeliverymansByCompany: async (url: string) => {
    const response = await getWithAuth(url);
    if (response) {
      const deliverymansData = response?.data;

      set({ deliverymansData });

      return deliverymansData;
    }

    return {} as DeliverymanData;
  },

  createDeliveryMan: async (data: createDeliveryMan) => {
    await postWithAuth(`/api/v1/deliveryMan`, data);
  },

  editDeliveryman: async (data: EditDeliverymanDto) => {
    await patchWithAuth(`/api/v1/deliveryMan`, data);
  },

  patchDeliverymanStatus: async (data: ChangeDeliverymanStatus) => {
    await patchWithAuth(`/api/v1/deliveryMan`, data);
  },

  syncDataDeliveryman: async (id: number) => {
    await patchWithAuth(`api/v1/deliveryMan/sync/${id}`);
  },

  syncDeliverymen: async (companyId: number) => {
    await patchWithAuth(`api/v1/deliveryMan/sync/many/${companyId}`);
  },

  deleteDeliveryman: async (id: number) => {
    await deleteWithAuth(`api/v1/deliveryman/${id}`);
  },
}));
