import { Flex } from "@chakra-ui/layout";
import { Image, keyframes } from "@chakra-ui/react";

import Logo from "../../assets/logo/logo-without-title-black.png";

const opacityAnim = keyframes`
  from { opacity: 1 }
  to { opacity:0.3 }
`;

export function LoadingLogo() {
  const imageAnimation = `${opacityAnim} infinite 0.8s alternate`;

  return (
    <Flex w="full" h="full" justifyContent="center" alignItems="center">
      <Image src={Logo} alt="loading logo" animation={imageAnimation} w="xs" />
    </Flex>
  );
}
