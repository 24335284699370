import { create } from "zustand";
import {
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../services/basicService";
import {
  BlockCpfDto,
  BlockIfoodIdDto,
  BlockedCpfDto,
  BlockedCpfsResponse,
  BlockedIdResponse,
  UnblockCpfDto,
  UnblockIfoodIdDto,
} from "../dto/deliveryman-blocked-dto";

interface BlockedDeliverymanStore {
  blockedCpfs: BlockedCpfDto[];
  blockedIfoodIds: BlockedIdResponse[];
  getBlockedCpfs: () => Promise<BlockedCpfDto[]>;
  blockOneOrMoreCpfs: (params: BlockCpfDto) => Promise<BlockedCpfsResponse>;
  unblockOneOrMoreCpfs: (params: UnblockCpfDto) => Promise<void>;
  getBlockedIfoodIds: () => Promise<BlockedIdResponse[]>;
  blockOneOrMoreIfoodIds: (params: BlockIfoodIdDto) => Promise<void>;
  unblockIfoodId: (
    id: number,
    params: UnblockIfoodIdDto
  ) => Promise<BlockedIdResponse>;
}

export const useBlockedDeliverymanStore = create<BlockedDeliverymanStore>(
  (set) => ({
    blockedCpfs: [],
    blockedIfoodIds: [],

    getBlockedCpfs: async () => {
      const response = await getWithAuth(`/api/v1/proposalUser/blocked`);

      if (response?.data.length > 0) {
        const blockedCpfs: BlockedCpfDto[] = response?.data;

        set({ blockedCpfs });

        return blockedCpfs;
      }

      return [] as BlockedCpfDto[];
    },

    blockOneOrMoreCpfs: async (params: BlockCpfDto) => {
      const response = await postWithAuth(`/api/v1/proposalUser/block`, params);
      return response?.data as BlockedCpfsResponse;
    },

    unblockOneOrMoreCpfs: async (params: UnblockCpfDto) => {
      await patchWithAuth(`/api/v1/proposalUser/unblock`, params);
    },

    getBlockedIfoodIds: async () => {
      const response = await getWithAuth(`/api/v1/deliveryMan/blocked`);

      if (response?.data.length > 0) {
        const blockedIfoodIds: BlockedIdResponse[] = response?.data;

        set({ blockedIfoodIds });

        return blockedIfoodIds;
      }

      return [] as BlockedIdResponse[];
    },

    blockOneOrMoreIfoodIds: async (params: BlockIfoodIdDto) => {
      await postWithAuth(`/api/v1/deliveryMan/block`, params);
    },

    unblockIfoodId: async (id: number, params: UnblockIfoodIdDto) => {
      const response = await patchWithAuth(
        `/api/v1/deliveryMan/blocked/${id}`,
        params
      );

      return response?.data;
    },
  })
);
