import { z } from "zod";
import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { usePlazaCachedService } from "../../../../../services/api/plaza";
import { IPlazaSetting, Plaza } from "../../../../../dto/plaza-dto";
import { MutateFunction } from "../../../../../services/api/use-cached-service";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import {
  convertTimeToTimeWithTimeZone,
  convertTimeWithTimeZoneToCurrentTimeZone,
} from "../../../../../utils/date";

const formFields: FormField[] = [
  {
    label: "Nome",
    name: "name",
    type: "text",
  },
  {
    label: "Aceite",
    name: "acceptance",
    type: "select",
    options: [
      { value: "automatic", label: "automático" },
      { value: "manually", label: "manual" },
    ],
  },
  {
    label: "Publicar agendamento antes (dias)",
    name: "publishScaleDaysBefore",
    type: "number",
  },
  {
    label: "Publicar agendamento às",
    name: "publishScaleAt",
    type: "time",
  },
];

const formSchema = z.object({
  id: z.number(),
  name: z.string(),
  acceptance: z.string(),
  publishScaleDaysBefore: z.string().optional(),
  publishScaleAt: z.string().optional(),
});

type FormType = Plaza & {
  acceptance: string;
  publishScaleDaysBefore?: number;
  publishScaleAt?: string;
};

function buildHandleSubmit(updatePlaza: MutateFunction) {
  return (formData: FormType) => {
    const { acceptance, publishScaleDaysBefore, publishScaleAt } = formData;
    formData.setting = {
      acceptance,
    } as IPlazaSetting;

    if (publishScaleDaysBefore && publishScaleAt) {
      formData.setting.publishScaleBefore = {
        daysBefore: publishScaleDaysBefore,
        at: convertTimeToTimeWithTimeZone(publishScaleAt),
      };
    }

    updatePlaza({ id: formData.id, data: formData });
  };
}

interface FormProps {
  plaza: Plaza;
  onUpdate: () => void;
}

export const PlazaFormEdit: React.FC<FormProps> = ({ plaza, onUpdate }) => {
  const { useMutationAction: usePlazaMutationAction } = usePlazaCachedService();
  const [updatePlaza, watchers] = usePlazaMutationAction("update");

  const formData = plaza as FormType;
  formData.acceptance = plaza.setting?.acceptance as string;
  formData.publishScaleDaysBefore =
    plaza.setting?.publishScaleBefore?.daysBefore;
  if (plaza.setting?.publishScaleBefore?.at) {
    const convertedTime = convertTimeWithTimeZoneToCurrentTimeZone(
      plaza.setting?.publishScaleBefore?.at
    ).substring(0, 5);
    formData.publishScaleAt = convertedTime;
  }

  const toast = useToast();

  useEffect(() => {
    const { status, error } = watchers;
    if (status) {
      if (status === "success") {
        onUpdate();
      }
      if (status === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao atualizar praça",
          description: `Falha ao atualizar praça: ${error}`,
        });
      }
    }
  }, [onUpdate, toast, watchers, watchers.status]);

  const handleSubmit = buildHandleSubmit(updatePlaza) as (
    data: unknown
  ) => void;

  return (
    <GenericForm
      fields={formFields}
      formSchema={formSchema}
      initialData={formData}
      actionLabel="Salvar"
      onSubmit={handleSubmit}
    />
  );
};
