import { Box, Flex, VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  UseStepsReturn,
} from "@chakra-ui/react";
import { Calendar } from "@phosphor-icons/react";
import { ptBR } from "date-fns/locale";
import dayjs from "dayjs";
import { DateRange, DayPicker } from "react-day-picker";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

type Props = UseStepsReturn;
const filtersFormSchema = z.object({
  dateRange: z.object({
    to: z.date().optional(),
    from: z.date().optional(),
  }),
  documentType: z.string(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

export function InfoPeriod(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const start = searchParams.get("start");
  const end = searchParams.get("end");
  const docType = searchParams.get("type");
  const formattedStartDate = start ? dayjs(start).toDate() : undefined;
  const formattedEndDate = end ? dayjs(end).toDate() : undefined;

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<filtersFormInput>({
    defaultValues: {
      dateRange: {
        from: formattedStartDate,
        to: formattedEndDate,
      },
      documentType: docType || "",
    },
  });

  async function onSubmit(data: filtersFormInput) {
    setSearchParams((prev) => {
      if (data?.dateRange?.from) {
        prev.set(
          "start",
          dayjs(data?.dateRange?.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.dateRange?.to) {
        prev.set("end", dayjs(data?.dateRange?.to).utc().format("YYYY-MM-DD"));
      } else {
        prev.set(
          "end",
          dayjs(data?.dateRange?.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.documentType) {
        prev.set("type", data.documentType);
      }
      return prev;
    });
    props.goToNext();
  }

  return (
    <VStack
      as="form"
      w="full"
      gap={8}
      id="form"
      alignItems="flex-start"
      justifyContent="center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex w="full" gap={8} alignItems="center">
        <FormControl
          w="full"
          display="flex"
          flexDir="column"
          alignItems="center"
        >
          <Box p={8}>
            <Select
              placeholder="Selecione o tipo de documento"
              {...register("documentType")}
            >
              <option value="NP">Nota Promissória - NP</option>
              <option value="CCB">Cédula de Crédito Bancário - CCB</option>
            </Select>
          </Box>

          <Box p={4}>
            <Popover placement="right-end">
              <PopoverTrigger>
                <Button w={"max-content"}>
                  <Calendar size={24} />
                  {!start
                    ? "Escolha a sua data"
                    : !end
                      ? start
                      : `${start.replaceAll("-", "/")} a ${end.replaceAll(
                          "-",
                          "/"
                        )}`}
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent bg="white" p={2} w="max-content">
                  <PopoverBody>
                    <Controller
                      control={control}
                      name="dateRange"
                      render={({ field }) => (
                        <DayPicker
                          id="dateFilter"
                          mode="range"
                          selected={field.value as DateRange}
                          onSelect={field.onChange}
                          locale={ptBR}
                          modifiersClassNames={{
                            disabled: "btn-disabled",
                            selected: "custom-selected",
                            outside: "outside-day",
                          }}
                        />
                      )}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Box>
        </FormControl>
        <Button
          type="submit"
          form="form"
          w="max-content"
          _hover={{
            bg: "green.700",
          }}
          px={8}
          size="md"
          alignSelf="flex-end"
          isDisabled={!isDirty}
        >
          Ver Detalhes
        </Button>
      </Flex>
    </VStack>
  );
}
