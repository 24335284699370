import { useState } from "react";
import { VStack, Badge } from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  Icon,
  useColorMode,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { Pagination } from "../../../../../components/pagination";
import { ArrowDown, ArrowUp } from "@phosphor-icons/react";
import {
  AdvanceUserData,
  colorSchemeMap,
  textSchemeMap,
} from "../../../../../dto/advance-user-dto";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import { LoadingLogo } from "../../../../../components/loading";
import "react-day-picker/dist/style.css";
import { DownloadReceipt } from "../../../../enterprises/adiantamentos/getReceipt";
import { useQuery } from "@tanstack/react-query";
import { DeliverymanDto } from "../../../../../dto/deliveryman-dto";
import { getWithAuth } from "../../../../../services/basicService";

export function DeliverymanAdvances({
  deliveryman,
}: {
  deliveryman: DeliverymanDto;
}) {
  const [page, setPage] = useState<number>(1);
  const [isOrderByDesc, setisOrderByDesc] = useState<boolean>(true);

  const { colorMode } = useColorMode();

  const fetchAdvanceData = async () => {
    const order = isOrderByDesc ? "DESC" : "ASC";
    try {
      const response = await getWithAuth(
        `/api/v1/userAdvance/proposal?cpf=${deliveryman.document}&page=${page}&orderBy=${order}`
      );

      return (response?.data || {}) as AdvanceUserData;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } = useQuery(
    ["advance-deliveryman", page, isOrderByDesc],
    fetchAdvanceData
  );

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      {isLoading ? (
        <LoadingLogo />
      ) : (
        <>
          <TableContainer
            w="full"
            flex={1}
            p={8}
            gap={4}
            display="flex"
            flexDir="column"
            borderRadius={8}
            bg={colorMode === "dark" ? "gray.700" : "whiteAlpha.800"}
            borderColor={colorMode === "dark" ? "" : "gray.300"}
            shadow="base"
          >
            <Table colorScheme="green" variant="striped">
              <Thead>
                <Tr>
                  <Th isNumeric>Valor Pedido</Th>
                  <Th isNumeric>Taxa</Th>
                  <Th isNumeric>Valor a Receber</Th>
                  <Th
                    textAlign="center"
                    onClick={() => setisOrderByDesc(!isOrderByDesc)}
                    cursor="pointer"
                  >
                    Data de entrada
                    {isOrderByDesc ? (
                      <Icon as={ArrowDown} />
                    ) : (
                      <Icon as={ArrowUp} />
                    )}
                  </Th>
                  <Th textAlign="center">Status</Th>
                  <Th textAlign="center">Comprovante</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.result?.map((advance) => {
                  return (
                    <Tr fontWeight={600} key={advance.id}>
                      <Td isNumeric>{convertCentsToReais(advance.amount)}</Td>
                      <Td isNumeric>
                        {convertCentsToReais(advance.amountFee)}
                      </Td>
                      <Td isNumeric>
                        {convertCentsToReais(advance.amountReceivable)}
                      </Td>
                      <Td textAlign="center">
                        {dayjs(advance.createdAt).format("DD/MM/YYYY HH:mm")}
                      </Td>
                      <Td textAlign="center">
                        <Badge
                          variant="solid"
                          px={4}
                          colorScheme={colorSchemeMap[advance.status]}
                        >
                          {textSchemeMap[advance.status]}
                        </Badge>
                      </Td>
                      <Td textAlign="center">
                        {advance.status === "approved" ? (
                          <DownloadReceipt
                            key={advance.id}
                            id={advance.id}
                            username={advance.username}
                          />
                        ) : (
                          "-"
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <Pagination
              page={page}
              setPage={setPage}
              totalPages={Number(data?.totalPages)}
            />
          </TableContainer>
        </>
      )}
    </VStack>
  );
}
