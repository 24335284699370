import { useState } from "react";
import { postWithAuth } from "../../services/basicService";
import { useToast } from "@chakra-ui/react";

export function useRunBalanceMigration() {
  const [loading, setLoading] = useState(false);
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom",
  });

  const execute = () => {
    if (loading) return;

    setLoading(true);

    const URL = "/api/v1/userExternal/process-balance-migration";

    const body = {
      documents: [],
      isToProcessAllPending: true,
    };

    postWithAuth(URL, body)
      .then(() =>
        toast({
          status: "success",
          title: "Requisição bem-sucedida",
          description: "Migração de saldo em execução",
        })
      )
      .catch((err) =>
        toast({
          status: "error",
          title: "Requisição com erro",
          description: err.response.data.message,
        })
      )
      .finally(() => setLoading(false));
  };

  return { execute, loading };
}
