import { useParams } from "react-router-dom";
import { useCreatePayroll } from "../../store/payroll.store";
import { useQuery } from "@tanstack/react-query";
import { PayrollDto } from "../../dto/payroll-dto";
import { getWithAuth } from "../../services/basicService";

export function useGetPayroll() {
  const params = useParams();

  const payrollState = useCreatePayroll(
    (state) => state.payroll.data
  ) as PayrollDto;

  const { data, isLoading } = useQuery({
    queryKey: [`payroll-${params?.id}`],
    queryFn: async (): Promise<PayrollDto | null> => {
      if (!params?.id) return null;

      const response = await getWithAuth(`/api/v1/payroll/${params.id}`);
      return response?.data as PayrollDto;
    },
  });

  return { payrollCreated: payrollState, isLoading, reprocessedPayroll: data };
}
