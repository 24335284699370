import { Flex } from "@chakra-ui/layout";
import { Outlet, useNavigation } from "react-router-dom";
import { LoadingLogo } from "../components/loading";
import { SubMenu } from "../components/sidebar/support/submenu";

export function ConfigLayout() {
  const { state } = useNavigation();

  return (
    <Flex as="main" w="full" gap={8}>
      <SubMenu />
      <Flex w="full">
        {state === "loading" ? (
          <>
            <LoadingLogo />
          </>
        ) : (
          <Outlet />
        )}
      </Flex>
    </Flex>
  );
}
