import {
  PaginateShiftAssignmentPreference,
  ShiftAssignmentPreference,
} from "../../dto/shift-assignment-preference-dto";
import { Service, useBaseCrud } from "./base-crud";
import { getWithAuth, patchWithAuth } from "../basicService";
import { useCachedService } from "./use-cached-service";

const baseURL = "/api/v1/shift_assignment_preferences";

const paginateFilter = async (
  filter: object
): Promise<PaginateShiftAssignmentPreference | null> => {
  const params = new URLSearchParams();
  Object.entries(filter).forEach(([key, value]) => params.set(key, value));
  const response = await getWithAuth(`${baseURL}`, {}, { params });
  return response?.data || null;
};

const accept = async (params: { id: number }) => {
  try {
    const resp = await patchWithAuth(`${baseURL}/${params.id}/accept`);

    return resp?.data || null;
  } catch (error) {
    throw new Error("Usuário não autenticado");
  }
};

const unaccept = async (params: { id: number }) => {
  try {
    const resp = await patchWithAuth(`${baseURL}/${params.id}/unaccept`);

    return resp?.data || null;
  } catch (error) {
    throw new Error("Usuário não autenticado");
  }
};

const exportScaled = async (params: ShiftAssignmentPreference) => {
  try {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, String(value));
    });
    const resp = await getWithAuth(
      `${baseURL}/accepted`,
      {},
      { params: searchParams }
    );

    return resp?.data || null;
  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
};

export const useShiftAssignmentPreferenceService = () => {
  return {
    ...useBaseCrud<ShiftAssignmentPreference>(baseURL),
    paginateFilter,
    accept,
    unaccept,
    exportScaled,
  };
};

export const useShiftAssignmentPreferenceCachedService = () => {
  const sapService =
    useShiftAssignmentPreferenceService() as unknown as Service<ShiftAssignmentPreference>;

  return useCachedService(sapService, "shiftAssignmentPreferences");
};
