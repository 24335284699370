import { useState } from "react";
import { getWithAuth } from "../../../../services/basicService";

export const useIndicationCode = () => {
  const [indicationCode, setIndicationCode] = useState<string>("");
  const [indications, setIndications] = useState<number>(0);

  const getIndicationCodeByDocument = async (document: string) => {
    const responseCode = await getWithAuth(
      `/api/v1/indication-code/${document}`
    );

    const responseIndications = await getWithAuth(
      `/api/v1/indication-code/converted/count/${document}`
    );

    if (responseCode?.data) {
      setIndicationCode(responseCode.data?.code);
    }

    if (responseIndications?.data) {
      setIndications(responseIndications.data);
    }
  };

  return { indicationCode, indications, getIndicationCodeByDocument };
};
