import { useState } from "react";
import { getWithAuth } from "../../services/basicService";

export function useExportCsv() {
  const [loading, setLoading] = useState(false);

  const exportCsv = (endpoint: string, fileName: string, queries: string) => {
    if (loading) return;
    setLoading(true);

    endpoint += `?${queries}`;

    getWithAuth(
      endpoint,
      {
        "Content-Type": "text/csv",
      },
      { responseType: "arraybuffer" as ResponseType, timeout: 0 }
    )
      .then((res) => {
        const csvBlob = new Blob([res?.data], { type: "text/csv" });
        const url = URL.createObjectURL(csvBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.csv`);
        link.click();
        URL.revokeObjectURL(url);
      })
      .finally(() => setLoading(false));
  };

  return { exportCsv, loading };
}
