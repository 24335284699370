import { useState } from "react";
import dayjs from "dayjs";
import { ptBR } from "date-fns/locale";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getWithAuth } from "../../../../services/basicService";
import { ListTransactionLimitRequests } from "../../../../types/list-limit-requests.type";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  BankLimitStatus,
  idServiceGroupTextMap,
  limitTypeTextMap,
} from "../../../../enums/bank-limit";
import { Pagination } from "../../../../components/pagination";
import { Calendar } from "@phosphor-icons/react";
import { DayPicker } from "react-day-picker";
import { LoadingLogo } from "../../../../components/loading";

const filterFormSchema = z.object({
  status: z.enum(["All", "DENIED", "APPROVED", "PENDING"]),
  requestDate: z.date().optional(),
});

type FilterFormInputs = z.infer<typeof filterFormSchema>;

type IProps = {
  idAccount: number;
};

export function ListLimitsRequest({ idAccount }: IProps) {
  const [page, setPage] = useState(1);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<FilterFormInputs>({
    resolver: zodResolver(filterFormSchema),
  });

  const { data, isFetching, refetch } = useQuery({
    queryKey: [`limits-request-id-account-${idAccount}`, page],
    queryFn: async () => {
      const status = getValues("status");
      const requestDate = getValues("requestDate");

      let URL = `/api/v1/app/account-limit/requests?idAccount=${idAccount}&page=${page}`;

      if (status && status !== "All") {
        URL += `&status=${status}`;
      }

      if (requestDate) {
        URL += `&requestDate=${dayjs(requestDate).format("YYYY-MM-DD")}`;
      }

      const res = await getWithAuth(URL);

      return res?.data as ListTransactionLimitRequests;
    },
    cacheTime: 0,
  });

  const onSubmit = () => {
    refetch();
  };

  return (
    <Box
      w="full"
      display="flex"
      flexDir="column"
      gap={4}
      flex={1}
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      p={8}
      shadow="base"
      bg="whiteAlpha.800"
    >
      <Flex
        as="form"
        flexDir="column"
        w="full"
        gap={4}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
          Filtros
        </Heading>
        <Flex gap={8} alignItems="end">
          <FormControl isInvalid={!!errors.status} w="max-content">
            <FormLabel>Status</FormLabel>
            <Select {...register("status")} defaultValue="All">
              <option key="All" value="All">
                Todos
              </option>
              {Object.entries(BankLimitStatus).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
            {errors.status ? (
              <FormErrorMessage>{errors.status.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <Controller
            control={control}
            name="requestDate"
            render={({ field }) => (
              <FormControl
                as="fieldset"
                w="max-content"
                display="flex"
                justifyContent="center"
              >
                <FormLabel as="legend">Data</FormLabel>
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button variant="outline" bg="white">
                      <Calendar size={24} />
                      {field.value ? (
                        dayjs(field.value).format("DD-MM-YYYY")
                      ) : (
                        <span>Selecione uma data</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent bg={"white"} p={2} w="max-content">
                    <PopoverBody>
                      <DayPicker
                        id="dateFilter"
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        locale={ptBR}
                        modifiersClassNames={{
                          disabled: "btn-disabled",
                          selected: "custom-selected",
                          outside: "outside-day",
                        }}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </FormControl>
            )}
          />
          <Button
            type="submit"
            bg="green.600"
            w="max-content"
            color="whiteAlpha.900"
            _hover={{
              bg: "green.700",
            }}
            px={8}
            size="md"
            isDisabled={isSubmitting}
          >
            Filtrar
          </Button>
        </Flex>
      </Flex>

      {!isFetching ? (
        <TableContainer
          w="full"
          gap={4}
          display="flex"
          flexDir="column"
          style={{
            containerType: "inline-size",
          }}
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={2}
          shadow="base"
        >
          <Table>
            <Thead>
              <Tr>
                <Th textAlign="center">Serviço</Th>
                <Th textAlign="center">Tipo do limite</Th>
                <Th textAlign="center">Valor solicitado</Th>
                <Th textAlign="center">Tipo do beneficiário</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Data da requisição</Th>
                <Th textAlign="center">Data limite para analise</Th>
                <Th textAlign="center">Data do resultado</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.items.map((item) => (
                <Tr key={item.idRequest}>
                  <Td textAlign="center">
                    {idServiceGroupTextMap[item.idServicesGroup]}
                  </Td>
                  <Td textAlign="center">{limitTypeTextMap[item.limitType]}</Td>
                  <Td textAlign="center">
                    {item.requestLimit.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Td>
                  <Td textAlign="center">{item.beneficiaryType}</Td>
                  <Td textAlign="center">{BankLimitStatus[item.status]}</Td>
                  <Td textAlign="center">
                    {dayjs(item.requestDate).format("DD/MM/YYYY HH:mm")}
                  </Td>
                  <Td textAlign="center">
                    {dayjs(item.requestDeadline).format("DD/MM/YYYY HH:mm")}
                  </Td>
                  <Td textAlign="center">
                    {item.requestResultDate &&
                      dayjs(item.requestResultDate).format("DD/MM/YYYY HH:mm")}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <LoadingLogo />
      )}
      {!isFetching && data && (
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={data.currentPage}
            setPage={setPage}
            totalPages={data.totalPages}
          />
        </HStack>
      )}
    </Box>
  );
}
