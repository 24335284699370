import {
  Button,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { memo, useState } from "react";
import { usePayrollScheduleSettingsContext } from "../context/payroll-schedule-settings.context";

const Component = () => {
  const { franchisesToSelect, companyId, setCompanyId, setFranchisesSearch } =
    usePayrollScheduleSettingsContext();
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = () => {
    setFranchisesSearch(searchInput);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.repeat) {
      handleSearch();
    }
  };

  return (
    <FormControl>
      <FormLabel>Nome da Franquia:</FormLabel>
      <VStack>
        <HStack>
          <Input
            placeholder="Busque por nome"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <Button colorScheme="green" onClick={handleSearch}>
            <MagnifyingGlass size={20} />
          </Button>
        </HStack>
        {franchisesToSelect?.map((franchise) => (
          <Container
            key={franchise.id}
            w="100%"
            h="100%"
            p={2}
            backgroundColor={"white"}
            borderRadius={8}
            cursor="pointer"
            _hover={{
              backgroundColor: "gray.200",
            }}
            shadow="base"
            transition="all 0.2s"
            onClick={() => {
              franchise.id && setCompanyId(franchise.id);
            }}
            border={franchise.id === companyId ? "2px" : "1px"}
            borderColor={franchise.id === companyId ? "green.500" : "gray.200"}
          >
            {franchise.name}
          </Container>
        ))}
      </VStack>
    </FormControl>
  );
};

export const SelectFranchise = memo(Component);
