import { PayrollStatus } from "../enums/payroll-status";
import { PayrollType } from "../enums/payroll-type";
import { TransactionDraftResponseItemDto } from "../screens/enterprises/payments/create-payroll-process/dtos/transaction-draft-dto";
import { PayrollAuditItem } from "../types/payroll.type";
import { CompanyDto } from "./company-dto";
import { TransactionDto } from "./transaction-dto";
import { UserDto } from "./user-dto";

export interface PayrollDto {
  id: number;
  name: string;
  user_id: number;
  company_id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  status: PayrollStatus;
  schedulingDate?: Date | null;
  conciliatedPeriodStart?: Date | null;
  conciliatedPeriodEnd?: Date | null;
  isToValidatePixDocument: boolean;
  plaza: string;
  hasError?: boolean;
  isToShowReprocessingButtons?: boolean;
  company: CompanyDto;
  user?: UserDto;
  audit?: PayrollAuditItem[];
  type: PayrollType;
  transactions?: TransactionDto[];
  transaction_drafts?: TransactionDraftResponseItemDto[];
  transactionDrafts?: TransactionDraftResponseItemDto[];
  transactionDraftCount?: number;
}

export interface PayrollResult {
  data?: PayrollDto[];
  result?: PayrollDto[];
  nextPage: boolean;
  totalPages: number;
  totalRecords: number;
}

export const payrollTypeTextMap = {
  [PayrollType.INSTANT]: "Avulsa",
  [PayrollType.SCHEDULED]: "Agendada",
  [PayrollType.CONCILIATED]: "Conciliada",
};

export const payrollStatusTextMap = {
  [PayrollStatus.CREATING]: "Criando conciliação",
  [PayrollStatus.CANCELED]: "Cancelada",
  [PayrollStatus.CONFIRMED]: "Confirmada",
  [PayrollStatus.DRAFT]: "Inacabada",
  [PayrollStatus.NOT_SAVED]: "Iniciada (sem transações)",
  [PayrollStatus.PENDING_CONFIRMATION]: "Pendente Confirmação",
  // [PayrollStatus.PENDING_SCHEDULE]: "Data não definida",
  [PayrollStatus.PROCESSED]: "Processada",
  [PayrollStatus.PROCESSING]: "Processando",
  [PayrollStatus.PARTIALLY_PROCESSED]: "Parcialmente Processada",
  [PayrollStatus.REPROCESSED]: "Solicitado Reprocessamento",
  // [PayrollStatus.SCHEDULED]: "Agendada",
};
