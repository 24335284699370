import { Box, Flex, Text, VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  UseStepsReturn,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Calendar } from "@phosphor-icons/react";
import { ptBR } from "date-fns/locale";
import dayjs from "dayjs";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { getWithAuth } from "../../../services/basicService";
import { CustomModal } from "../../../components/modal";

const filtersFormSchema = z.object({
  folder: z.string({ required_error: "Este campo é obrigatório" }),
  data: z.date(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;
type Props = UseStepsReturn;
export function SaveCnab(props: Props) {
  const me = useCurrentUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [link, setLink] = useState("");
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<filtersFormInput>({ resolver: zodResolver(filtersFormSchema) });

  const [searchParams] = useSearchParams();
  const start = searchParams.get("start");
  const end = searchParams.get("end");
  const doctype = searchParams.get("type");
  const disabledDays = [
    {
      from: dayjs(start).subtract(1, "year").toDate(),
      to: dayjs().subtract(1, "day").toDate(),
    },
  ];
  const toast = useToast();
  const navigate = useNavigate();

  async function onSubmit(data: filtersFormInput) {
    try {
      setIsLoading(true);
      const userName = `${me?.firstName} ${me?.lastName}`;
      const datav = dayjs(data.data).format("YYYY-MM-DD");
      const response = await getWithAuth(
        `/api/v1/cnab/cnab_pdf?folder=${data.folder}&datai=${start}&dataf=${end}&user=${userName}&datav=${datav}&type=${doctype}`,
        {}
      );
      if (response?.data.status === "Success") {
        toast({
          status: "success",
          duration: 3000,
          title: "Aguarde enquanto o Cnab é salvo no drive",
        });
        setLink(response.data.message);
        setIsModalOpen(true);
      }
      if (response?.data.status === "Error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Erro",
          description: response?.data.message,
        });
      }
    } catch (error) {
      toast({
        status: "error",
        duration: 3000,
        title: "Erro",
        description: String(error),
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <VStack
      as="form"
      w="full"
      gap={8}
      id="form"
      alignItems="flex-start"
      justifyContent="center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex w="full" gap={8} alignItems="center">
        <FormControl
          w="full"
          display="flex"
          flexDir="column"
          alignItems="center"
        >
          <Box p={8}>
            <FormLabel as="legend">Nome da pasta</FormLabel>
            <Input
              {...register("folder")}
              type="text"
              placeholder="Digite aqui nome da pasta"
              style={{ width: "400px" }}
            />
          </Box>

          <Box p={4}>
            <Popover placement="right-end">
              <PopoverTrigger>
                <Button w={"max-content"}>
                  <Calendar size={24} />
                  Escolha a data de venda
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent bg="white" p={2} w="max-content">
                  <PopoverBody>
                    <Controller
                      control={control}
                      name="data"
                      render={({ field }) => (
                        <DayPicker
                          id="dateFilter"
                          mode="single"
                          selected={field.value as Date}
                          onSelect={field.onChange}
                          locale={ptBR}
                          disabled={disabledDays}
                          modifiersClassNames={{
                            disabled: "btn-disabled",
                            selected: "custom-selected",
                            outside: "outside-day",
                          }}
                        />
                      )}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Box>
        </FormControl>
      </Flex>
      <Button
        type="submit"
        form="form"
        w="max-content"
        _hover={{
          bg: "green.700",
        }}
        px={8}
        size="md"
        alignSelf="flex-end"
        isLoading={isLoading}
        isDisabled={!isDirty}
      >
        Gerar Cnab
      </Button>
      <CustomModal
        modalHeader="Pasta no Drive criada com sucesso!"
        modalBody={
          <VStack textAlign={"center"} fontSize="medium">
            <Text marginBottom={5}>
              Aguarde enquanto o download dos arquivos no Drive é concluído.
              Essa ação tem duração média de aproximadamente 10 minutos. Clique
              em Confirmo para acessar a pasta no Drive.
            </Text>
          </VStack>
        }
        modalIsOpen={isModalOpen}
        confirmButtonText="Confirmo"
        onConfirm={() => {
          setIsModalOpen(false);
          window.open(link, "_blank");
          props.setActiveStep(0);
          navigate("/cnab");
        }}
        doNotShowCloseButton={true}
      />
    </VStack>
  );
}
