import { useRef, useState } from "react";
import {
  VStack,
  Heading,
  Flex,
  HStack,
  Box,
  SimpleGrid,
  Text,
} from "@chakra-ui/layout";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  FormErrorMessage,
  Select,
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ptBR } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Calendar, WarningOctagon, X, Trash } from "@phosphor-icons/react";
import { Pagination } from "../../../components/pagination";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorsContainer } from "../../enterprises/performance-and-balances";
import {
  UploadResponseErrors,
  UploadedFilesResponse,
} from "../../../types/upload.type";
import { useQuery } from "@tanstack/react-query";
import { deleteWithAuth, getWithAuth } from "../../../services/basicService";
import { DrawerDownloadSheets } from "./drawer-download-sheets";
import { SHEETS_INFO } from "../../../types/sheet-info.type";
import { LoadingLogo } from "../../../components/loading";
dayjs.extend(utc);

const typesOfSpreadsheets = [
  "todos",
  "financeiro",
  "performance",
  "entregador",
  "pagamento",
] as const;

const sheetsFiltersSchema = z.object({
  selectDate: z.date().optional(),
  company: z.string().optional(),
  type: z.enum(typesOfSpreadsheets).optional(),
});

type DeleteBalancesData = {
  companyName: string;
  idSheetsSend: string;
  date: Date;
};

type SelectedSheet = {
  company: string;
  uploadFileCreatedAt: Date;
  spreadsheets: SHEETS_INFO[];
};

type sheetsFiltersInputs = z.infer<typeof sheetsFiltersSchema>;

export function Planilhas() {
  const [deleteBalancesLoading, setDeleteBalancesLoading] = useState(false);
  const selectedSheets = useRef<SelectedSheet>();
  const deleteBalancesData = useRef<DeleteBalancesData>();
  const [sheetErrors, setSheetErrors] = useState<UploadResponseErrors>(
    {} as UploadResponseErrors
  );

  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const company = searchParams.get("companies");
  const type = searchParams.get("type");
  const date = searchParams.get("date");
  const page = Number(searchParams.get("page"));

  const { data, isLoading } = useQuery(
    ["sheets", page, company, type, date],
    async () => {
      const companyId = company ? `${company}` : undefined;

      const UrlSearchParams = new URLSearchParams({
        page: String(page),
        limit: String(10),
      });

      if (companyId) {
        UrlSearchParams.append("companies", String(companyId));
      }

      if (type) {
        UrlSearchParams.append("type", type);
      }

      if (date) {
        UrlSearchParams.append("day", String(date));
      }

      const response = await getWithAuth(
        `/api/v1/uploaded-file?${UrlSearchParams.toString()}`
      );

      return response?.data as UploadedFilesResponse;
    }
  );

  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as { name: string; id: string }[];
    },
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<sheetsFiltersInputs>({
    resolver: zodResolver(sheetsFiltersSchema),
    defaultValues: {
      company: company || undefined,
      selectDate: date ? dayjs(date).toDate() : undefined,
    },
  });

  const suporte = useDisclosure({ id: "erros-suporte" });
  const deleteBalances = useDisclosure({ id: "delete-balances" });
  const drawerDowloadSheets = useDisclosure({ id: "drawer-dowload-sheets" });

  const toast = useToast({ duration: 3000, isClosable: true });

  async function onSubmit(data: sheetsFiltersInputs) {
    setSearchParams((prev) => {
      prev.set("page", "1");

      if (data.company === "todas") {
        prev.delete("companies");
      } else if (data.company !== undefined) {
        prev.set("companies", `${data.company}`);
      }

      if (data.type === "todos") {
        prev.delete("type");
      } else if (data.type) {
        prev.set("type", data.type);
      }

      if (data.selectDate) {
        prev.set("date", dayjs(data.selectDate).utc().format("YYYY-MM-DD"));
      }
      return prev;
    });
  }

  function formatPage() {
    const page = searchParams.get("page");

    if (page) {
      return parseInt(page);
    }
    return 1;
  }

  function openErrorsModal(errors: UploadResponseErrors) {
    setSheetErrors(errors);
    suporte.onOpen();
  }

  function handleOpenDeleteBalancesModal(data: DeleteBalancesData) {
    deleteBalancesData.current = data;

    deleteBalances.onOpen();
  }

  async function handleDeleteBalances() {
    if (deleteBalancesLoading) return;

    setDeleteBalancesLoading(true);

    const { companyName, date, idSheetsSend } =
      deleteBalancesData.current as DeleteBalancesData;

    const URL = `/api/v1/userAdvanceBalance?idSheetsSend=${idSheetsSend}`;
    deleteWithAuth(URL)
      .then(() => {
        deleteBalances.onClose();

        toast({
          status: "success",
          title: "Sucesso ao deletar os saldos",
          description: `Saldos da ${companyName} do dia ${dayjs(date).format(
            "DD/MM/YYYY"
          )} foram deletados`,
        });
      })
      .catch(() => {
        toast({
          status: "error",
          title: "Erro ao deletar os saldos",
          description: `Não foi possível deletar os saldos`,
        });
      })
      .finally(() => setDeleteBalancesLoading(false));
  }

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Planilhas
      </Heading>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={4}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex gap={8} alignItems="end">
            <FormControl isInvalid={!!errors.company} w="max-content">
              <FormLabel>Empresa</FormLabel>
              <Select {...register("company")} defaultValue="todas">
                <option key="todas" value="todas">
                  Todas
                </option>
                {companies
                  ? companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))
                  : null}
              </Select>
              {errors.company ? (
                <FormErrorMessage>{errors.company.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl isInvalid={!!errors.type} w="max-content">
              <FormLabel>Tipo</FormLabel>
              <Select {...register("type")} defaultValue="todos">
                {typesOfSpreadsheets.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
              {errors.type ? (
                <FormErrorMessage>{errors.type.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <Controller
              control={control}
              name="selectDate"
              render={({ field }) => (
                <FormControl
                  as="fieldset"
                  w="max-content"
                  display="flex"
                  justifyContent="center"
                >
                  <FormLabel as="legend">Data</FormLabel>
                  <Popover placement="right-end">
                    <PopoverTrigger>
                      <Button variant="outline" bg="white">
                        <Calendar size={24} />
                        {field.value ? (
                          dayjs(field.value).format("DD-MM-YYYY")
                        ) : (
                          <span>Selecione uma data</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent bg={"white"} p={2} w="max-content">
                      <PopoverBody>
                        <DayPicker
                          id="dateFilter"
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          locale={ptBR}
                          modifiersClassNames={{
                            disabled: "btn-disabled",
                            selected: "custom-selected",
                            outside: "outside-day",
                          }}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </FormControl>
              )}
            />
            <Button
              type="submit"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={8}
              size="md"
              isDisabled={isSubmitting}
            >
              Filtrar
            </Button>
            {isDirty || company ? (
              <Button
                w="max-content"
                px={4}
                size="md"
                onClick={() => {
                  setSearchParams((prev) => {
                    prev.set("page", "1");
                    prev.delete("companies");
                    prev.delete("date");
                    return prev;
                  });
                  reset();
                }}
                display="flex"
                gap={4}
              >
                <X size={12} />
                Limpar filtros
              </Button>
            ) : null}
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          pb={8}
          gap={4}
          display="flex"
          flexDir="column"
          style={{
            containerType: "inline-size",
          }}
        >
          <Modal
            isOpen={suporte.isOpen}
            onClose={suporte.onClose}
            size="5xl"
            id="erros-suporte"
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent px={4}>
              <ModalHeader>Erros na planilha</ModalHeader>
              <ModalCloseButton />
              <ModalBody px={16}>
                <ErrorsContainer
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  errors={sheetErrors}
                />
              </ModalBody>

              <ModalFooter my={4}>
                <Button variant="ghost" mr={3} onClick={suporte.onClose}>
                  Fechar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {!isLoading ? (
            <Table>
              <Thead>
                <Tr>
                  <Th textAlign="center">Empresa</Th>
                  <Th textAlign="center">Tipos</Th>
                  <Th textAlign="center">Data de envio</Th>
                  <Th textAlign="center">Lista de planilhas</Th>
                  <Th textAlign="center">Erros de validação</Th>
                  <Th textAlign="center">Deletar saldos</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.result?.map((sheet) => {
                  const types = sheet.spreadsheets.map(({ type }) => type);

                  const filteredTypes = types.reduce((acc: string[], curr) => {
                    if (!acc.includes(curr)) {
                      acc.push(curr);

                      return acc;
                    }

                    return acc;
                  }, []);

                  return (
                    <Tr key={sheet.spreadsheets[0].id} textAlign="center">
                      <Td textAlign="center">{sheet.company}</Td>
                      <Td textAlign="center">
                        <SimpleGrid columns={1} spacing="40px">
                          {filteredTypes.map((type) => (
                            <Text key={type}>{type}</Text>
                          ))}
                        </SimpleGrid>
                      </Td>
                      <Td textAlign="center">
                        {dayjs(sheet.uploadFileCreatedAt).format("DD-MM-YYYY")}
                      </Td>
                      <Td textAlign="center">
                        <Button
                          onClick={() => {
                            selectedSheets.current = {
                              company: sheet.company,
                              uploadFileCreatedAt: sheet.uploadFileCreatedAt,
                              spreadsheets: sheet.spreadsheets,
                            };

                            drawerDowloadSheets.onOpen();
                          }}
                        >
                          Abrir
                        </Button>
                      </Td>
                      <Td textAlign="center">
                        <Flex justify="center">
                          {sheet.parseResult ? (
                            <>
                              <Button
                                size="lg"
                                variant="ghost"
                                bg="yellow.300"
                                _hover={{
                                  bgColor: "yellow.200",
                                }}
                                onClick={() =>
                                  openErrorsModal(sheet?.parseResult?.errors)
                                }
                                display="flex"
                                gap={4}
                              >
                                <WarningOctagon color="orange" size={24} />
                                Abrir erros de validação
                              </Button>
                            </>
                          ) : (
                            <p>Sem erros</p>
                          )}
                        </Flex>
                      </Td>
                      <Td textAlign="center">
                        {sheet.parseResult !== null ? (
                          <Button
                            backgroundColor="red.400"
                            onClick={() =>
                              handleOpenDeleteBalancesModal({
                                companyName: sheet.company,
                                idSheetsSend: sheet.idSheetsSend,
                                date: sheet.uploadFileCreatedAt,
                              })
                            }
                          >
                            <Trash size={24} color="white" />
                          </Button>
                        ) : (
                          <Flex justifyContent="center">-</Flex>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <LoadingLogo />
          )}
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          {!isLoading && (
            <Pagination
              page={formatPage()}
              setPage={(page) =>
                setSearchParams((prev) => {
                  prev.set("page", String(page));
                  return prev;
                })
              }
              totalPages={data?.totalPages as number}
            />
          )}
        </HStack>
      </Box>

      <Modal
        isOpen={deleteBalances.isOpen}
        onClose={deleteBalances.onClose}
        size="5xl"
        id="delete-balances"
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader fontSize="3xl">
            Confirmar Ação de Deletar Saldos
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <Flex fontSize="2xl">
              Essa ação ira deletar os saldos da{" "}
              {deleteBalancesData.current?.companyName} do dia{" "}
              {dayjs(deleteBalancesData.current?.date).format("DD/MM/YYYY")}
            </Flex>
          </ModalBody>
          <ModalFooter my={4}>
            <Button
              onClick={deleteBalances.onClose}
              isDisabled={deleteBalancesLoading}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteBalances}
              ml={3}
              isLoading={deleteBalancesLoading}
              isDisabled={deleteBalancesLoading}
            >
              Deletar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {selectedSheets.current && (
        <DrawerDownloadSheets
          isOpen={drawerDowloadSheets.isOpen}
          onClose={drawerDowloadSheets.onClose}
          sheet={selectedSheets.current}
        />
      )}
    </VStack>
  );
}
