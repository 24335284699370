import { Table, Thead, Tbody, Tr, Th, Td, Text } from "@chakra-ui/react";
import { useSyncCsvContext } from "../context/sync-csv.context";

const CsvTable = () => {
  const { csvData } = useSyncCsvContext();

  return (
    <Table variant="striped" size="md" mt={4}>
      <Thead>
        <Tr>
          <Th>ID IFood</Th>
          <Th>Nome</Th>
          <Th>CPF</Th>
          <Th>Empresa</Th>
          <Th>Erros</Th> {/* Nova coluna para erros */}
        </Tr>
      </Thead>
      <Tbody>
        {csvData?.length > 0 ? (
          csvData.map((row, index) => (
            <Tr
              key={index}
              sx={{
                border: row.errors?.length > 0 ? "2px solid red" : "none",
              }}
            >
              <Td>{row.deliverymanExternalId}</Td>
              <Td>{row.name}</Td>
              <Td>{row.document}</Td>
              <Td>{row.companyName}</Td>
              <Td>
                {row.errors?.length > 0 ? (
                  <ul>
                    {row.errors.map((error, i) => (
                      <li key={i}>
                        <Text color="red.500">{error}</Text>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Text color="green.500">Sem erros</Text>
                )}
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={5} textAlign="center">
              Nenhum registro importado
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default CsvTable;
