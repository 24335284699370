import { UserFeedbackQuestionState } from "../enums/user-feedback-questions-state.enum";
import { Pagination } from "./generics/pagination-dto";

export interface UserFeedbackQuestionDto {
  id: number;
  question: string;
  isRequired: boolean;
  isActive: boolean;
  startDate: Date;
  endDate?: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface UserFeedbackQuestionCreateDto {
  question: string;
  isRequired: boolean;
}

export interface UserFeedbackQuestionResponseDto extends Pagination {
  data: UserFeedbackQuestionDto[];
}

export const userFeedbackStateTextMap = {
  [UserFeedbackQuestionState.ALL]: "Todos status",
  [UserFeedbackQuestionState.INACTIVE]: "Inativa",
  [UserFeedbackQuestionState.ACTIVE]: "Ativa",
};
