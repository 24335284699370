import { useRef, useState } from "react";
import { useQueries } from "@tanstack/react-query";
import {
  DockIndividual,
  DockIndividualDocumentsList,
} from "../../../../dto/docker-individuals-dto";
import { useNavigate, useParams } from "react-router-dom";
import { getWithAuth } from "../../../../services/basicService";
import {
  Badge,
  Divider,
  Flex,
  HStack,
  Heading,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/layout";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SkeletonText,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CaretLeft, Download } from "@phosphor-icons/react";
import { DragDrop, IRef } from "../../../../components/forms/fileUpload";
import { isAxiosError } from "axios";

export function AppUserDetails() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { userId, document } = useParams<{
    userId: string;
    document: string;
  }>();

  const sendDocumentRef = useRef<IRef>(null);
  const documentTypeRef = useRef<HTMLSelectElement>(null);

  const navigate = useNavigate();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const toast = useToast({
    isClosable: true,
    duration: 3000,
  });

  const [
    { data: userInfo, isLoading: isLoadingInfo },
    { data: userDocuments, isLoading: isLoadingDocuments, refetch },
  ] = useQueries({
    queries: [
      {
        queryKey: ["app-user-details", userId],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/userExternal/app-users/${userId}`
          );

          return response?.data as DockIndividual;
        },
        keepPreviousData: true,
      },
      {
        queryKey: ["app-user-documents", document],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/userExternal/get-documents/${document}`
          );

          return response?.data as DockIndividualDocumentsList;
        },
        keepPreviousData: true,
      },
    ],
  });

  async function handleUpload() {
    const hasFiles = sendDocumentRef?.current?.hasFiles();

    if (!hasFiles) {
      return;
    }

    try {
      setIsSubmitting(true);
      await sendDocumentRef?.current?.processUpload<DockIndividual>({
        sendId: "",
      });

      refetch();

      toast({
        status: "success",
        title: "Documento enviado com sucesso",
      });

      onClose();
    } catch (error) {
      if (isAxiosError(error)) {
        return toast({
          status: "error",
          title: "Documento enviado com sucesso",
          description: error?.response?.data.errorMessage,
        });
      }

      toast({
        status: "error",
        title: "Documento enviado com sucesso",
        description: JSON.stringify(error),
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <VStack gap={4} flex={1} alignItems="flex-start">
      <HStack gap={4}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Detalhes usuário
        </Heading>
      </HStack>

      <Button onClick={onOpen} size="lg" color="whiteAlpha.900" maxW="200px">
        Enviar documento
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent padding={4}>
          <ModalHeader>Enviar novo documento</ModalHeader>
          <ModalCloseButton />

          <Divider />

          <ModalBody display="flex" flexDir="column" gap={4}>
            <fieldset>
              <label htmlFor="doc-type">Tipo de documento</label>
              <Select
                ref={documentTypeRef}
                id="doc-type"
                defaultValue="DIGITAL_DRIVER_LICENSE"
              >
                <option value="DIGITAL_DRIVER_LICENSE">
                  Carteira de motorista digital
                </option>
                <option value="SELFIE">Selfie</option>
              </Select>
            </fieldset>
            <DragDrop
              maxFiles={1}
              uploadUrl={`/api/v1/userExternal/upload-document/${userInfo?.document}?category=${documentTypeRef?.current?.value}`}
              accept={{
                "image/jpeg": [".jpg", ".jpeg"],
                "application/pdf": [".pdf"],
              }}
              ref={sendDocumentRef}
            />
          </ModalBody>
          <ModalFooter
            display="flex"
            w="full"
            justifyContent="flex-end"
            gap={4}
          >
            <Button onClick={onClose}>Cancelar</Button>
            <Button
              bg={"green.500"}
              isDisabled={!sendDocumentRef?.current?.hasFiles() || isSubmitting}
              isLoading={isSubmitting}
              onClick={handleUpload}
            >
              Enviar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <HStack
        w="full"
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        spacing={8}
        shadow="base"
        bg="whiteAlpha.800"
        divider={<StackDivider borderColor="gray.200" />}
        align="stretch"
      >
        <VStack align="stretch" w="full" gap={4}>
          <Flex gap={4}>
            <Heading size="md" fontWeight="extrabold" textAlign="left">
              Dados do usuário
            </Heading>
          </Flex>

          <SkeletonText
            isLoaded={!isLoadingInfo}
            w="full"
            noOfLines={8}
            skeletonHeight={6}
          >
            <VStack align="self-start">
              <Text>
                <strong>Nome:</strong> {userInfo?.name}
              </Text>
              <Text>
                <strong>Documento:</strong> {userInfo?.document}
              </Text>
              <Text>
                <strong>E-mail:</strong> {userInfo?.email}
              </Text>
              <Text>
                <strong>Nome da mãe:</strong> {userInfo?.motherName}
              </Text>
              <Text>
                <strong>Código do banco:</strong> {userInfo?.bankNumber}
              </Text>
              <Text>
                <strong>Agência:</strong> {userInfo?.branchNumber}
              </Text>
              <Text>
                <strong>Conta:</strong> {userInfo?.accountNumber}
              </Text>
              <Text>
                <strong>Status da conta:</strong> {userInfo?.status}
              </Text>
            </VStack>
          </SkeletonText>
        </VStack>
        <VStack align="stretch" gap={2} w="full">
          <Heading size="md" fontWeight="extrabold" textAlign="left">
            Documentos do usuário
          </Heading>

          <Flex align="stretch" flexDir="row" wrap="wrap" gap={4}>
            {!isLoadingDocuments ? (
              userDocuments?.result.map((document) => (
                <Card maxW="sm" key={document.documentId} minW="300px">
                  <CardBody>
                    <Flex gap={4} justifyContent="space-between">
                      <VStack>
                        <Text>{document.category}</Text>
                        {document.invalidDetails ? (
                          <Badge colorScheme="red">
                            {`Inválido, código: ${document.invalidDetails.reasonCode}`}
                          </Badge>
                        ) : null}
                      </VStack>
                      <Button
                        onClick={async () => {
                          const response = await getWithAuth(
                            `/api/v1/userExternal/get-documents/download/${document.documentId}`
                          );

                          window.open(response?.data, "_blank");
                        }}
                        size="sm"
                        color="whiteAlpha.900"
                      >
                        <Download size={20} weight="bold" />
                      </Button>
                    </Flex>
                  </CardBody>
                </Card>
              ))
            ) : (
              <SkeletonText w="full" noOfLines={8} skeletonHeight={6} />
            )}
          </Flex>
        </VStack>
      </HStack>
    </VStack>
  );
}
