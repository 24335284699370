import { VStack, Heading, HStack, Box, Flex } from "@chakra-ui/layout";
import {
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
  useToast,
  IconButton,
  FormControl,
  FormLabel,
  Select,
  Button,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  Stat,
  StatLabel,
  StatNumber,
  Input,
  useColorMode,
} from "@chakra-ui/react";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ArrowFatLinesRight, FileCsv } from "@phosphor-icons/react";
import { TRAMPAY_COMPANY_ID } from "../../../utils/constants/trampay-company-id";
import {
  PayrollDto,
  PayrollResult,
  payrollStatusTextMap,
} from "../../../dto/payroll-dto";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Pagination } from "../../../components/pagination";
import { LoadingLogo } from "../../../components/loading";
import { getWithAuth, postWithAuth } from "../../../services/basicService";
import { z } from "zod";
import { Controller, UseFormRegister, useForm } from "react-hook-form";
import { COMPANY_TYPE, CompanyDto } from "../../../dto/company-dto";
import { PayrollStatus } from "../../../enums/payroll-status";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { isFranchisor } from "../../../utils/company-verifications";
import { exportCsv } from "../../../utils/export-csv";
import { PayrollType } from "../../../enums/payroll-type";
import { useCreatePayroll } from "../../../store/payroll.store";

dayjs.extend(utc);

const filterConciliatedPayrollSchema = z.object({
  companyId: z.string().optional(),
  status: z.string().optional(),
  name: z.string().optional(),
  dateRange: z
    .object({
      to: z.date().optional(),
      from: z.date().optional(),
    })
    .optional(),
});

type filterConciliatedPayrollInputs = z.infer<
  typeof filterConciliatedPayrollSchema
>;

export function PayrollInstant() {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const [searchParams, setSearchParams] = useSearchParams({ page: "1" });
  const toast = useToast();
  const page = searchParams.get("page");
  const name = searchParams.get("name");
  const companyId = searchParams.get("companyId");
  const status = searchParams.get("status") as PayrollStatus;
  const createdStartDate = searchParams.get("createdStartDate");
  const createdEndDate = searchParams.get("createdEndDate");

  const me = useCurrentUserStore((state) => state.user);
  const changePayrollStatus = useCreatePayroll(
    (state) => state.changePayrollStatus
  );
  const setIsScheduled = useCreatePayroll((state) => state.setScheduledFlag);
  const setIsConciliated = useCreatePayroll(
    (state) => state.setConciliatedFlag
  );
  const setReprocessingScheduledFlag = useCreatePayroll(
    (state) => state.setReprocessingScheduledFlag
  );

  const {
    data: payrollsResult,
    error,
    isLoading,
  } = useQuery<PayrollResult>({
    queryKey: [
      "get-payrolls-instant",
      page,
      name,
      status,
      companyId,
      createdEndDate,
      createdStartDate,
    ],
    queryFn: async () => {
      const route = `/api/v1/payroll/by/filter?page=${page}&limit=10`;
      const filter: Record<string, unknown> = { type: PayrollType.INSTANT };

      if (me?.companyId === TRAMPAY_COMPANY_ID) {
        filter.company_id = companyId ?? me?.companyId;
      }
      if (name) {
        filter.name = name;
      }
      if (status) {
        filter.status = status;
      }

      if (createdStartDate) {
        filter.created_at = createdEndDate
          ? { from: createdStartDate, to: createdEndDate }
          : createdStartDate;
      }

      const response = await postWithAuth(route, filter);

      const aux = {
        ...response?.data,
        data: response?.data?.data.map(
          (payroll: { scheduling_date: Date }) => ({
            ...payroll,
            schedulingDate: payroll.scheduling_date,
          })
        ),
      };

      return aux;
    },
  });

  const fetchFranchiseesOfCompany = useQuery(
    ["company-franchisees"],
    async () => {
      try {
        const response = await getWithAuth(
          `/api/v1/companies/franchisees/${me?.company?.id}`
        );
        return response?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: me?.company?.companyType === COMPANY_TYPE.FRANQUEADORA,
      initialData: () => ({ id: null, franchisees: [] as CompanyDto[] }),
    }
  );

  const { data: companies } = useQuery<{ name: string; id: string }[]>({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data;
    },
    enabled: me?.companyId === TRAMPAY_COMPANY_ID,
  });

  const { handleSubmit, register, control, reset } =
    useForm<filterConciliatedPayrollInputs>({
      defaultValues: {
        name: undefined,
        status: status || undefined,
        companyId: companyId !== null ? String(companyId) : undefined,
        dateRange: {
          from: createdStartDate ? dayjs(createdStartDate).toDate() : undefined,
          to: createdEndDate ? dayjs(createdStartDate).toDate() : undefined,
        },
      },
    });

  const mutation = useMutation(
    async (payroll: PayrollDto) => {
      const endpoint =
        payroll.status === PayrollStatus.PROCESSED
          ? "transaction/transactionsByPayroll"
          : "transaction-draft/transactionsDraftByPayroll";
      const response = await getWithAuth(
        `/api/v1/${endpoint}/csv/${payroll.id}`
      );
      return response?.data;
    },
    {
      onSuccess: (data, payroll) => {
        exportCsv(data, payroll.name);
      },
      onError: (error) => {
        console.log(error);
        toast({
          status: "error",
          title: "Erro ao exportar csv",
          description: `Erro: ${error}`,
        });
      },
    }
  );

  if (isLoading) {
    return <LoadingLogo />;
  }

  if (error) {
    toast({
      status: "error",
      title: "Erro ao carregar folhas",
      description: `Erro: ${error}`,
    });
  }

  const handlePageChange = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", page.toString());
      return prev;
    });
  };

  const handleNavigateToDetails = (payroll: PayrollDto) => {
    changePayrollStatus(true);
    setIsScheduled(false);
    setIsConciliated(true);
    setReprocessingScheduledFlag(!!payroll.schedulingDate);
    const payrollId = payroll.id;
    const status = payroll.status;
    let route = "";

    if (![PayrollStatus.DRAFT, PayrollStatus.NOT_SAVED].includes(status)) {
      route = `../details/${payrollId}`;
    } else {
      route = `../create-transactions/${payrollId}`;
    }

    navigate(route, {
      relative: "path",
    });
  };

  async function onSubmit(data: filterConciliatedPayrollInputs) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("name");
      prev.delete("status");
      prev.delete("companyId");
      prev.delete("createdEndDate");
      prev.delete("createdStartDate");

      if (data?.companyId) {
        prev.set("companyId", data?.companyId.toString());
      }

      if (data?.status) {
        prev.set("status", data?.status);
      }

      if (data?.name) {
        prev.set("name", data?.name);
      }

      if (data?.dateRange?.from) {
        prev.set(
          "createdStartDate",
          dayjs(data?.dateRange?.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.dateRange?.to) {
        prev.set(
          "createdEndDate",
          dayjs(data?.dateRange?.to).utc().format("YYYY-MM-DD")
        );
      }

      return prev;
    });
  }
  const clearFilters = () => {
    setSearchParams((prev) => {
      prev.delete("name");
      prev.delete("status");
      prev.delete("companyId");
      prev.delete("createdEndDate");
      prev.delete("createdStartDate");
      return prev;
    });
    reset();
  };

  const DynamicSelect: React.FC<{
    label: string;
    options: {
      value: string;
      label: string;
    }[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: ReturnType<UseFormRegister<any>>;
    placeholder?: string;
    props?: unknown;
  }> = ({ label, options, register, placeholder, ...props }) => (
    <FormControl as="fieldset" {...props}>
      <FormLabel as="legend">{label}</FormLabel>
      <Select variant="filled" placeholder={placeholder} {...register}>
        {options.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
    </FormControl>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createOptions = (data: any[], valueKey: string, labelKey: string) => {
    return data.map((item) => ({
      value: item[valueKey],
      label: item[labelKey],
    }));
  };

  console.log(payrollsResult?.data);

  return (
    <VStack
      gap={8}
      w="full"
      flex={1}
      alignItems="flex-start"
      style={{ containerType: "inline-size" }}
    >
      <Heading fontWeight="extrabold" textAlign="left">
        Folhas Avulsas
      </Heading>

      <Flex gap={8} alignItems="flex-start">
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          w="max-content"
          h="full"
          shadow="base"
          p={4}
        >
          <Stat>
            <StatLabel fontSize="xl">Total de registros:</StatLabel>
            <StatNumber fontSize="3xl" color="green.500">
              {Number(payrollsResult?.totalRecords)}
            </StatNumber>
          </Stat>
        </Box>
      </Flex>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={4}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={4}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={4}
          p={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex gap={8} alignItems="end">
            <FormControl as="fieldset" w="max-content">
              <FormLabel as="legend">Data de envio</FormLabel>
              <Popover placement="bottom">
                <PopoverTrigger>
                  <Button>
                    {!createdStartDate
                      ? "Escolha data que foi criada"
                      : !createdEndDate
                        ? createdStartDate
                        : `${createdStartDate.replaceAll(
                            "-",
                            "/"
                          )} a ${createdEndDate.replaceAll("-", "/")}`}
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent bg="white" p={2} w="max-content">
                    <PopoverBody>
                      <Controller
                        control={control}
                        name="dateRange"
                        render={({ field }) => (
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            initialFocus
                            selected={field.value as DateRange}
                            onSelect={field.onChange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        )}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </FormControl>

            <FormControl as="fieldset" maxW="2xl">
              <FormLabel as="legend">Nome da folha</FormLabel>
              <Input
                {...register("name")}
                bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                placeholder="Busque pelo nome..."
                size="lg"
                maxW="3xl"
                w="full"
              />
            </FormControl>

            <DynamicSelect
              label="Status da folha"
              options={createOptions(
                Object.entries(PayrollStatus)
                  .filter(([, value]) =>
                    [
                      PayrollStatus.DRAFT,
                      PayrollStatus.NOT_SAVED,
                      PayrollStatus.PROCESSED,
                      PayrollStatus.PROCESSING,
                      PayrollStatus.CANCELED,
                      PayrollStatus.REPROCESSED,
                    ].includes(value)
                  )
                  .map(([, value]) => ({
                    value,
                    label: payrollStatusTextMap[value],
                  })),
                "value",
                "label"
              )}
              register={register("status")}
              placeholder="Todos"
            />

            {isFranchisor(me?.company as CompanyDto) && (
              <DynamicSelect
                label="Franqueada"
                options={createOptions(
                  fetchFranchiseesOfCompany?.data?.franchisees || [],
                  "id",
                  "name"
                )}
                register={register("companyId")}
                placeholder="Escolha uma franqueada"
              />
            )}

            {me?.companyId === TRAMPAY_COMPANY_ID && (
              <DynamicSelect
                label="Empresa"
                options={createOptions(companies || [], "id", "name")}
                register={register("companyId")}
                placeholder="Todas"
              />
            )}

            <Flex>
              <Button
                type="submit"
                bg="green.600"
                w="max-content"
                color="whiteAlpha.900"
                _hover={{ bg: "green.700" }}
                size="md"
              >
                Filtrar
              </Button>
            </Flex>
            <Flex>
              <Button w="max-content" size="md" onClick={clearFilters}>
                Limpar Filtros
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          p={4}
          gap={4}
          display="flex"
          flexDir="column"
          style={{
            containerType: "inline-size",
          }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th w="fit-content">Data de envio</Th>
                <Th w="50%" textAlign="center">
                  Nome da folha
                </Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Transações</Th>
                {me?.companyId === TRAMPAY_COMPANY_ID ? (
                  <Th textAlign="center">Empresa</Th>
                ) : null}
                <Th textAlign="center">Exportar CSV</Th>
                <Th textAlign="center">Consultar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payrollsResult?.data?.map((payroll) => (
                <Tr
                  key={payroll.id}
                  cursor="pointer"
                  onClick={() => handleNavigateToDetails(payroll)}
                >
                  <Td textAlign="center">
                    {dayjs(payroll.created_at).utc().format("DD/MM/YYYY")}
                  </Td>
                  <Td textAlign="center">{payroll?.name}</Td>
                  <Td textAlign="center">
                    {payroll.status
                      ? payrollStatusTextMap[payroll.status]
                      : null}
                  </Td>
                  <Td textAlign="center">
                    {payroll?.transactionDraftCount ?? 0}
                  </Td>
                  {me?.companyId === TRAMPAY_COMPANY_ID ||
                  isFranchisor(me?.company as CompanyDto) ? (
                    <Td textAlign="center">{payroll?.company?.name}</Td>
                  ) : null}
                  <Td textAlign="center">
                    {payroll.status !== PayrollStatus.NOT_SAVED &&
                      payroll.status !== PayrollStatus.CANCELED && (
                        <IconButton
                          aria-label="exportar em csv"
                          role="a"
                          size="lg"
                          bg="green.500"
                          icon={<FileCsv size={20} />}
                          color="white"
                          px={4}
                          onClick={(e) => {
                            e.stopPropagation();
                            mutation.mutate(payroll);
                          }}
                          disabled={mutation.isLoading}
                          isLoading={mutation.isLoading}
                        />
                      )}
                  </Td>

                  <Td textAlign="center">
                    <IconButton
                      aria-label="botão para ir para detalhes da folha"
                      role="a"
                      size="lg"
                      bg="green.500"
                      icon={<ArrowFatLinesRight size={20} />}
                      color="white"
                      px={4}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={page ? Number(page) : 1}
            setPage={
              handlePageChange as React.Dispatch<React.SetStateAction<number>>
            }
            totalPages={
              payrollsResult?.totalPages ? payrollsResult?.totalPages : 1
            }
          />
        </HStack>
      </Box>
    </VStack>
  );
}
