import {
  VStack,
  Heading,
  Box,
  Flex,
  SimpleGrid,
  HStack,
} from "@chakra-ui/layout";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Thead,
  Tr,
  Th,
  Tooltip,
  useToken,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ProcessedAdvances } from "./processed-advances";
import { UnprocessedAdvances } from "./unprocessed-advances";
import { UnknownTransactions } from "./unknown-transactions";
import {
  AdvanceUserDto,
  AdvanceUserWithTransactionInfoDto,
} from "../../../../dto/advance-user-dto";
import { getWithAuth } from "../../../../services/basicService";
import { useQuery } from "@tanstack/react-query";
import { convertCentsToReais } from "../../../../utils/convert-money";
import { Transaction } from "../../../../dto/transactions-dto";
import { CaretLeft, Info } from "@phosphor-icons/react";
import { LoadingLogo } from "../../../../components/loading";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { DateRange, DayPicker } from "react-day-picker";
import dayjs from "dayjs";
import { ptBR } from "date-fns/locale";

export type ConciliationDataResponse = {
  advance_transactions_count: number;
  advance_transactions_sum: number;
  advances_count: number;
  advances_sum: number;
  processed_advances: AdvanceUserWithTransactionInfoDto[];
  unprocessed_advances: AdvanceUserDto[];
  unknown_transactions: Transaction[];
};

export function Conciliation() {
  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const [range, setRange] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["conciliation", range?.from, range?.to],
    queryFn: async () => {
      if (!range?.from) {
        setURLSearchParams((prev) => {
          prev.delete("fromDate");
          prev.delete("toDate");

          return prev;
        });
      }

      if (range?.from) {
        setURLSearchParams((prev) => {
          prev.set("fromDate", dayjs(range?.from).format("YYYY-MM-DD"));
          prev.set("toDate", dayjs(range?.to).format("YYYY-MM-DD"));
          return prev;
        });
      }

      const response = await getWithAuth(
        `/api/v1/userAdvance/conciliation?${urlSearchParams.toString()}`
      );

      return response?.data as ConciliationDataResponse;
    },
  });

  const [blue500] = useToken("colors", ["blue.500"]);

  const totalProcessedAdvance = data?.unprocessed_advances.reduce(
    (acc, cur) => acc + Number(cur.amount) - Number(cur.amountFee),
    0
  );

  const totalUnknownTransactions = data?.unknown_transactions.reduce(
    (acc, cur) => acc + Number(cur.amount),
    0
  );

  const conciliation = data
    ? data?.advances_sum - data?.advance_transactions_sum
    : 0;

  return (
    <VStack
      w="full"
      alignItems="flex-start"
      style={{ containerType: "inline-size" }}
      gap={4}
    >
      <HStack gap={8}>
        <Button as={Link} to="/adiantamentos" colorScheme="green">
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Conciliação
        </Heading>
      </HStack>

      <Flex gap={[4, 8]} w="full" flexWrap="wrap">
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          minW="180px"
          h="120px"
          shadow="base"
        >
          <Stat>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <StatLabel fontSize={["md"]} color="black">
                      Transações
                    </StatLabel>
                  </Th>
                  <Th>
                    <StatLabel fontSize={["md"]} color="black">
                      Adiantamentos
                    </StatLabel>
                  </Th>
                </Tr>
              </Thead>
            </Table>

            <SimpleGrid columns={2} justifyItems="center">
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {`${data?.advance_transactions_count || 0}`}
              </StatNumber>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {`${data?.advances_count || 0}`}
              </StatNumber>
            </SimpleGrid>
          </Stat>
        </Box>
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          minW="180px"
          h="120px"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>
              Total adian. sem transação
            </StatLabel>
            <StatNumber fontSize={["xl", "3xl"]} color="green.500">
              {`${convertCentsToReais(totalProcessedAdvance || 0)}`}
            </StatNumber>
          </Stat>
        </Box>
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          minW="180px"
          h="120px"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>
              Total transações sem adian.
            </StatLabel>
            <StatNumber fontSize={["xl", "3xl"]} color="red.500">
              {`${convertCentsToReais(totalUnknownTransactions || 0)}`}
            </StatNumber>
          </Stat>
        </Box>
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          minW="180px"
          h="120px"
          shadow="base"
          position="relative"
        >
          <Tooltip
            hasArrow
            label="Total dos adiantamentos - transações, tem que ser zero."
            rounded={"md"}
          >
            <Button
              rounded={"full"}
              position="absolute"
              top="8px"
              right="8px"
              p={0}
              zIndex={10}
            >
              <Info size={24} weight="bold" color={blue500} />
            </Button>
          </Tooltip>
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>Conciliação</StatLabel>
            <StatNumber
              fontSize={["xl", "3xl"]}
              color={conciliation >= 0 ? "green.500" : "red.500"}
            >
              {`${convertCentsToReais(conciliation)}`}
            </StatNumber>
          </Stat>
        </Box>
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          minW="180px"
          h="120px"
          shadow="base"
          position="relative"
        >
          <Heading size="md" fontWeight="semibold" paddingBottom={4}>
            Período
          </Heading>
          <Popover placement="right-end">
            <PopoverTrigger>
              <Button>Escolha a sua data</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent w="max-content">
                <PopoverBody>
                  <DayPicker
                    id="dateFilter"
                    mode="range"
                    selected={range}
                    onSelect={setRange}
                    locale={ptBR}
                    modifiersClassNames={{
                      disabled: "btn-disabled",
                      selected: "custom-selected",
                      outside: "outside-day",
                    }}
                  />
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Box>
      </Flex>

      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" w="full" h="full">
          <LoadingLogo />
        </Flex>
      ) : (
        <Tabs variant="soft-rounded" colorScheme="green" w="full">
          <TabList>
            <Tab>
              Adiantamentos conciliado: {data?.processed_advances.length}
            </Tab>
            <Tab>
              Adiantamentos sem transação: {data?.unprocessed_advances.length}
            </Tab>
            <Tab>
              Transações sem adiantamento: {data?.unknown_transactions.length}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ProcessedAdvances advances={data?.processed_advances || []} />
            </TabPanel>
            <TabPanel>
              <UnprocessedAdvances
                advances={data?.unprocessed_advances || []}
              />
            </TabPanel>
            <TabPanel>
              <UnknownTransactions
                transactions={data?.unknown_transactions || []}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
}
