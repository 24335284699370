import { Flex, HStack, Heading } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Calendar, CaretLeft } from "@phosphor-icons/react";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebtStore } from "../../../../../store/debt.store";
import dayjs from "dayjs";
import { DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { AxiosError } from "axios";
import { DebtType } from "../../../../../enums/debt-type";
import { debtTypeTextMap } from "../../../../../dto/debt-dto";

const createDebtFormSchema = z.object({
  description: z.string().nonempty("O id do entregador é obrigatório"),
  amount: z.number().transform((amount) => amount * 100),
  company_id: z.coerce.number(),
  debt_date: z.date(),
  type: z.nativeEnum(DebtType, {
    errorMap: () => {
      return { message: "Selecione o tipo do débito." };
    },
  }),
});

type createDebtFormInputs = z.infer<typeof createDebtFormSchema>;

export function CreateDebt() {
  const createDebt = useDebtStore((state) => state.createDebt);
  const { companies } = useLoaderData() as {
    companies: { id: number; name: string }[];
  };

  const navigate = useNavigate();
  const toast = useToast();

  interface AxiosResponseData {
    error: string;
    message: string;
    statusCode: number;
  }

  async function onSubmit(data: createDebtFormInputs) {
    try {
      const body = { ...data, amount: Math.round(data.amount) };

      await createDebt(body);
      toast({
        status: "success",
        title: "Débito criado com sucesso",
        duration: 2000,
      });
      navigate(-1);
    } catch (error) {
      const err = error as AxiosError;
      const errorData = err.response?.data as AxiosResponseData;
      toast({
        status: "error",
        title: "Não foi possível criar débito.",
        description: `Erro: ${errorData.message}`,
      });
    }
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<createDebtFormInputs>({
    resolver: zodResolver(createDebtFormSchema),
  });

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading>Criar Débito para empresa</Heading>
      </HStack>
      <Heading size="lg">Insira as informações abaixo:</Heading>

      <Flex
        as="form"
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        p={8}
        borderRadius={8}
        shadow="base"
        bg="whiteAlpha.800"
        id="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex gap={8} flexDir="row" w="full">
          <FormControl isInvalid={!!errors.company_id} w="55%" isRequired>
            <FormLabel>Empresa</FormLabel>
            <Select {...register("company_id")}>
              {companies
                ? companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))
                : null}
            </Select>
            {errors.company_id ? (
              <FormErrorMessage>{errors.company_id.message}</FormErrorMessage>
            ) : null}
          </FormControl>

          <Controller
            control={control}
            name="debt_date"
            render={({ field }) => (
              <FormControl
                as="fieldset"
                w="max-content"
                display="flex"
                justifyContent="center"
                isRequired
              >
                <FormLabel as="legend">Data</FormLabel>
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button variant="outline" bg="white">
                      <Calendar size={24} />
                      {field.value ? (
                        dayjs(field.value).format("DD-MM-YYYY")
                      ) : (
                        <span>Selecione uma data</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent bg={"white"} p={2} w="max-content">
                    <PopoverBody>
                      <DayPicker
                        id="dateFilter"
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        locale={ptBR}
                        modifiersClassNames={{
                          disabled: "btn-disabled",
                          selected: "custom-selected",
                          outside: "outside-day",
                        }}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </FormControl>
            )}
          />

          <FormControl
            as="fieldset"
            isInvalid={!!errors.amount}
            isRequired
            w="45%"
          >
            <FormLabel as="legend">Valor</FormLabel>
            <NumberInput
              borderRadius={4}
              step={0.2}
              precision={2}
              inputMode="decimal"
              isValidCharacter={(value) => /^[Ee0-9+\-.,]$/.test(value)}
            >
              <NumberInputField
                type="number"
                {...register("amount", { valueAsNumber: true, required: true })}
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
                placeholder="R$"
              />
            </NumberInput>

            {errors.amount && (
              <FormErrorMessage>{errors.amount.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl as="fieldset" isInvalid={!!errors.type} w="55%">
            <FormLabel as="legend">Tipo</FormLabel>
            <Select {...register("type")} placeholder="Selecione o tipo">
              {Object.values(DebtType).map((value) => {
                return (
                  <option key={value} value={value}>
                    {debtTypeTextMap[value]}
                  </option>
                );
              })}
            </Select>
            {errors.type && (
              <FormErrorMessage>{errors.type.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            as="fieldset"
            isInvalid={!!errors.description}
            isRequired
          >
            <FormLabel>Descrição</FormLabel>
            <Input
              type="text"
              placeholder="Digite a descrição"
              {...register("description")}
            />
            {errors.description ? (
              <FormErrorMessage>{errors.description.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        <Button
          type="submit"
          form="form"
          size="lg"
          alignSelf="flex-end"
          isDisabled={!isDirty}
          isLoading={isSubmitting}
        >
          Criar débito para empresa
        </Button>
      </Flex>
    </Flex>
  );
}
