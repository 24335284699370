import { postWithAuth } from "../basicService";

const baseURL = "/api/v1/uploaded-file";

async function syncBalance(payload: {
  startDate: Date;
  endDate: Date;
  sendId: string;
}) {
  const response = await postWithAuth(`${baseURL}/sync_balances`, payload);
  return response?.data || null;
}

export const useBalanceService = () => {
  return {
    syncBalance,
  };
};
