export enum RoleEnum {
  ADMIN_TRAMPAY = "Admin_Trampay",
  ADMIN_COMPANY = "Admin_Company",
  ATENDIMENTO = "Atendimento",
  EMULADOR = "Emulador",
  FINANCEIRO_COMPANY = "Financeiro_Company",
  FINANCEIRO_TRAMPAY = "Financeiro_Trampay",
  OPS_TRAMPAY = "Ops_Trampay",
  TECH_DADOS = "Tech_Dados",
  USER_COMPANY = "User_Company",
  USER_TRAMPAY = "User_Trampay",
}
