import { create } from "zustand";
import { getWithAuth } from "../services/basicService";
import { Transaction } from "../dto/transactions-dto";
import { ITransactionDraft } from "../dto/transactions-draft-dto";
import { useModalStore } from "./components/modal.store";

interface TransactionsStoreProps {
  result: Transaction[];
  totalRecords: number;
  totalPages: number;
  nextPage: boolean;

  getTransactionsByPayrollId: ({
    filters,
    page,
    limit,
  }: {
    filters?: { name?: string; document?: string; status?: string };
    page?: number;
    limit?: number;
    id: string;
  }) => Promise<Transaction[]>;
  generateTransactionPdf(id: number): Promise<void>;
  generateAllTransactionsPdf(id: number, sheetName: string): Promise<void>;
  getTransactionsByDebtID(debtId: number): Promise<Transaction[]>;
  generateTransactionCsv(filters: {
    startDate: string;
    endDate?: string;
    page?: number;
    document?: string;
    amountStart?: number;
    amountEnd?: number;
    name?: string;
    limit?: number;
    status?: string;
    companyId?: string;
    onlyPayroll?: boolean;
    type?: string;
  }): Promise<void>;
  generateTransactionsByPayrollCsv(payrollId: string): Promise<void>;
}

export const useTransactionsStore = create<TransactionsStoreProps>((set) => ({
  result: [],
  nextPage: false,
  totalPages: 0,
  totalRecords: 0,

  generateTransactionCsv: async (filters) => {
    let query = "";
    Object.entries(filters).forEach(([key, value]) => {
      query += `&${key}=${value}`;
    });

    await getWithAuth(
      `api/v1/transaction/csv?${query}`,
      {
        "Content-Type": "text/csv",
      },
      { responseType: "arraybuffer" as ResponseType }
    ).then((res) => {
      const csvBlob = new Blob([res?.data], { type: "text/csv" });
      const url = URL.createObjectURL(csvBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filters.startDate}.csv`);
      link.click();
      URL.revokeObjectURL(url);
    });
  },

  generateTransactionsByPayrollCsv: async (payrollId: string) => {
    try {
      const res = await getWithAuth(
        `api/v1/transaction/transactionsByPayroll/csv/${payrollId}`
      );

      const blob = new Blob([res?.data], { type: "text/csv" });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `resumo-folha-de-pagamento.csv`);
      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getTransactionsByPayrollId: async ({ filters, page = 1, limit = 10, id }) => {
    const UrlSearchParams = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });

    if (filters?.name) {
      UrlSearchParams.append("name", String(filters.name));
    }

    if (filters?.document) {
      UrlSearchParams.append("document", String(filters.document));
    }

    if (filters?.status) {
      UrlSearchParams.append("status", String(filters.status));
    }

    try {
      const response = await getWithAuth(
        `/api/v1/transaction/allByPayrollId/${id}?${UrlSearchParams.toString()}`
      );
      set({
        result: response?.data.result,
        nextPage: response?.data.nextPage,
        totalPages: response?.data.totalPages,
        totalRecords: response?.data.totalRecords,
      });

      return response?.data.result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  generateTransactionPdf: async (id: number) => {
    try {
      const res = await getWithAuth(
        `/api/v1/transaction/receipt/${id}`,
        {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
        { responseType: "arraybuffer" as ResponseType }
      );

      const blob = new Blob([res?.data], {
        type: "application/pdf",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `comprovante-${id}.pdf`);
      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erro ao gerar o PDF da transação:", error);
      throw new Error(JSON.stringify(error));
    }
  },

  generateAllTransactionsPdf: async (sheetId: number, sheetName: string) => {
    const changeGeneratingPdfStatus =
      useModalStore.getState().changeGeneratingPdfStatus;

    changeGeneratingPdfStatus(true);

    try {
      const res = await getWithAuth(
        `/api/v1/transaction/receipt/${sheetId}/all`,
        {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
        { responseType: "arraybuffer" as ResponseType }
      );

      const blob = new Blob([res?.data], {
        type: "application/pdf",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${sheetName}.pdf`);
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error(error);
      throw new Error("Não foi possível exportar pdf.");
    } finally {
      changeGeneratingPdfStatus(false);
    }
  },

  getTransactionsByDebtID: async (id: number) => {
    try {
      const response = await getWithAuth(
        `/api/v1/transaction/allByDebtId/${id}`
      );

      set({
        result: response?.data,
      });

      return response?.data.result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },
}));

interface TransactionsDraftStoreProps {
  result: ITransactionDraft[];
  totalRecords: number;
  totalPages: number;
  nextPage: boolean;

  getTransactionsDraftByCompanyAndPayrollId: ({
    filters,
    page,
    limit,
  }: {
    filters?: { name?: string; document?: string };
    page?: number;
    limit?: number;
    id: string;
  }) => Promise<void>;
}

export const useTransactionsDraftStore = create<TransactionsDraftStoreProps>(
  (set) => ({
    result: [],
    nextPage: false,
    totalPages: 0,
    totalRecords: 0,

    getTransactionsDraftByCompanyAndPayrollId: async ({
      filters,
      page = 1,
      limit = 10,
      id,
    }) => {
      const UrlSearchParams = new URLSearchParams({
        page: String(page),
        limit: String(limit),
      });

      if (filters?.name) {
        UrlSearchParams.append("name", String(filters.name));
      }

      if (filters?.document) {
        UrlSearchParams.append("document", String(filters.document));
      }

      try {
        const response = await getWithAuth(
          `/api/v1/transaction-draft/allPayroll/${id}?${UrlSearchParams.toString()}`
        );
        set({
          result: response?.data.data,
          nextPage: response?.data.nextPage,
          totalPages: response?.data.totalPages,
          totalRecords: response?.data.totalRecords,
        });
      } catch (error) {
        throw new Error(JSON.stringify(error));
      }
    },
  })
);
