import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import {
  getWithAuth,
  patchWithAuth,
} from "../../../../../services/basicService";

const messageFormSchema = z.object({
  defaultMessages: z.enum(["1", "2", "customMessage"]),
  customMessage: z.string(),
});

type MessageFormInputs = z.infer<typeof messageFormSchema>;

const defaultMessages = {
  "1": "Operação de empréstimo finalizada para o dia de hoje. Lembrete: os horários de solicitação são de 09h - 18:30 (seg. a sexta (horário de Brasília)) / 09h ás 15h (sáb, domingo e feriado (horário de Brasília)).",
  "2": "Operação de empréstimo fechada para ajustes no sistema. Em breve retomaremos. Por favor, aguarde",
};

export function AdvanceClosedMessage() {
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom",
  });

  const client = useQueryClient();

  const { data: phraseClosedAdvance, isLoading } = useQuery({
    queryKey: ["phrase_closed_advance"],
    queryFn: async () => {
      const URL = "/api/v1/configuration/phrase-closed-advance";

      const response = await getWithAuth(URL);

      return response?.data as { id: number; key: string; value: string };
    },
  });

  const editMutatation = useMutation(
    (message: string) =>
      patchWithAuth(`/api/v1/configuration/phrase-closed-advance`, { message }),
    {
      onSuccess: () => {
        client.invalidateQueries([`phrase_closed_advance`]);

        toast({
          status: "success",
          title: "Edição realizado com sucesso",
          description: `A edição da mensagem foi realizada com sucesso!`,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) =>
        toast({
          status: "error",
          title: "Erro ao editar a mensagem",
          description: `Erro: ${err?.response?.data.message}`,
        }),
    }
  );

  const { register, handleSubmit, control } = useForm<MessageFormInputs>({
    resolver: zodResolver(messageFormSchema),
    defaultValues: { customMessage: "" },
  });

  const submit: SubmitHandler<MessageFormInputs> = (data) => {
    if (editMutatation.isLoading) return;

    const message =
      data.defaultMessages === "customMessage"
        ? data.customMessage
        : defaultMessages[data.defaultMessages];

    editMutatation.mutate(message);
  };

  return (
    <Card w="full" variant="elevated">
      <CardHeader>
        <Heading size="md" textAlign="center">
          Mensagem de adiantamento fechado
        </Heading>
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <Box>
            <Heading size="md">Mensagem atual</Heading>
            {!isLoading && (
              <Text pt="2" fontSize="md">
                {phraseClosedAdvance?.value}
              </Text>
            )}
          </Box>
          <Flex
            as="form"
            flexDirection="column"
            gap={10}
            onSubmit={handleSubmit(submit)}
          >
            <Heading size="md" textAlign="center">
              Editar mensagem
            </Heading>
            <FormControl>
              <Controller
                name="defaultMessages"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    onChange={onChange}
                    value={value}
                    defaultValue="1"
                  >
                    <Stack spacing={5} direction="column">
                      {Object.entries(defaultMessages).map(([label, value]) => (
                        <Radio value={label} fontSize="lg" key={label}>
                          {value}
                        </Radio>
                      ))}

                      <Radio value="customMessage" fontSize="md">
                        Outra mensagem
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
            <FormControl as="fieldset">
              <Textarea
                {...register("customMessage")}
                bg="blackAlpha.100"
                color="gray.700"
                fontWeight="bold"
                placeholder="Digite aqui outra mensagem desejada"
                focusBorderColor="green.500"
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </FormControl>
            <Flex justifyContent="center">
              <Button
                type="submit"
                bg="green.500"
                color="white"
                _hover={{
                  bg: "green.600",
                }}
              >
                Editar mensagem
              </Button>
            </Flex>
          </Flex>
        </Stack>
      </CardBody>
    </Card>
  );
}
