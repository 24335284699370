import { Box } from "@chakra-ui/layout";
import { memo } from "react";
import { usePayrollScheduleSettingsContext } from "../context/payroll-schedule-settings.context";
import { Stat, StatArrow, StatLabel, StatNumber } from "@chakra-ui/react";

const Component = () => {
  const { payrollStatusState } = usePayrollScheduleSettingsContext();

  return (
    <Box
      w="100%"
      backgroundColor={"white"}
      border={"1px"}
      padding={3}
      shadow={"base"}
      borderRadius={8}
    >
      <Stat border={1}>
        <StatLabel>Status</StatLabel>
        <StatNumber>
          {payrollStatusState ? (
            <>
              <StatArrow type="increase" />
              Aberto
            </>
          ) : (
            <>
              <StatArrow type="decrease" />
              Fechado
            </>
          )}
        </StatNumber>
      </Stat>
    </Box>
  );
};

export const StatusCard = memo(Component);
