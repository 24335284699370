import { Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Warning } from "@phosphor-icons/react";
import { useRunBalanceMigration } from "../../../../../hooks/balance-migration/run-balance-migration";

export function RunBalanceMigration() {
  const { execute, loading } = useRunBalanceMigration();

  return (
    <VStack alignItems="center" w="full" h="full" gap="30px">
      <Heading as="h1">Rodar a migração manualmente</Heading>
      <Flex flexDirection="column" alignItems="center" gap={15}>
        <Button
          w="fit-content"
          mt={4}
          colorScheme="green"
          onClick={execute}
          isLoading={loading}
          loadingText="Em execução..."
          isDisabled={loading}
        >
          Executar
        </Button>
        <Flex alignItems="center" gap={15}>
          <Warning size={32} color="red" />
          <Text fontSize="medium" color="orange.700">
            Não é possivel rodar a migração caso ela já esteja em execução
          </Text>
        </Flex>
      </Flex>
    </VStack>
  );
}
