import { Configuration } from "../../dto/configuration-dto";
import { getWithAuth } from "../basicService";
import { useBaseCrud } from "./base-crud";

const baseURL = "/api/v1/configuration";

export const useConfigurationService = () => {
  return {
    ...useBaseCrud<Configuration>(baseURL),
    show: async (key: string): Promise<Configuration | null> => {
      const response = await getWithAuth(`${baseURL}/${key}`);
      return response?.data || null;
    },
  };
};
