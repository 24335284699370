import { Flex, List, ListItem, Text } from "@chakra-ui/layout";
import { Link, Outlet, useNavigate, useNavigation } from "react-router-dom";
import { SideBar, SideBarMobile } from "../components/sidebar/enterprise";
import { LoadingLogo } from "../components/loading";
import {
  Avatar,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";

import { UserStore, useCurrentUserStore } from "../store/current-user.store";
import { useQuery } from "@tanstack/react-query";
import { convertCentsToReais } from "../utils/convert-money";
import {
  DotsThreeVertical,
  HandCoins,
  Lock,
  User,
  Wallet,
} from "@phosphor-icons/react";
import { motion } from "framer-motion";
import { PermissionsEnum } from "../enums/permissions.enum";
import { calculateTotalDebtAmount } from "../utils/calculate-total-debt-amount";
import { useState } from "react";
import { COMPANY_TYPE } from "../dto/company-dto";
import { useJarvisAuthService } from "../services/api/jarvis-auth";
// import { INTEGRATION } from "../enums/company-integration";
// import { FlagBadgeIntegration } from "../components/badge/flag-badge-integration";

export function RootLayout() {
  const { state } = useNavigation();

  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const user = useCurrentUserStore((state: UserStore) => state.user);
  // TODO: adicionar esta info quando tiver o método "me" da infra ifood concluido
  // let integrationStatus: boolean = false;
  // if(user?.company?.integration === INTEGRATION.JARVIS_IFOOD){
  //  integrationStatus = !!user?.company?.integrations?.ifood?.renewable
  // }
  const getBankInfo = useCurrentUserStore(
    (state: UserStore) => state.getBankInfo
  );

  const myPermissions = useCurrentUserStore((state) => state.permissions);

  const { checkAuth } = useJarvisAuthService();

  const hasPermission = myPermissions?.some(
    (permission) => permission.key === PermissionsEnum["Saldos/Débitos OL"]
  );

  const { data: bankInfo } = useQuery(["bankInfo"], getBankInfo, {
    placeholderData: {
      mainAccount: {
        bankBranchNumber: "0000",
        bankAccountNumber: "00000000",
        availableBalanceInCents: 0,
        bankAccountDigit: "",
        bankAccountType: "CC",
        bankBranchDigit: "",
        bankNumber: "00000000",
        status: "",
        pix: {
          pixKeys: [
            {
              keyType: "evp",
              key: "",
            },
          ],
        },
        debts: [],
      },
      paymentAccount: {
        bankBranchNumber: "0000",
        bankAccountNumber: "00000000",
        availableBalanceInCents: 0,
        bankAccountDigit: "",
        bankAccountType: "CC",
        bankBranchDigit: "",
        bankNumber: "00000000",
        status: "",
        pix: {
          pixKeys: [
            {
              keyType: "evp",
              key: "",
            },
          ],
        },
        debts: [],
      },
    },
  });

  const totalMainAccountDebt = bankInfo
    ? calculateTotalDebtAmount(bankInfo?.mainAccount?.debts)
    : 0;
  const navigate = useNavigate();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLogged, setIsLogged] = useState(true);

  const [role, setRole] = useState<string | null>(null);

  useQuery(["checkAuth"], checkAuth, {
    refetchInterval: 300000,
    onSuccess: (data) => {
      if (data) {
        setIsFirstLoad(false);
        setIsLogged(true);

        const roleNames = data.roles.map(
          (role: string) => role.split(" - ")[1]
        );
        const preferredRole = roleNames.includes("Administrative")
          ? "Administrative"
          : roleNames[0];
        setRole(preferredRole);
      } else {
        setIsFirstLoad(false);
        setIsLogged(false);
        setRole(null);
      }
    },
    onError: () => {
      setIsFirstLoad(false);
      setIsLogged(false);
      setRole(null);
    },
  });

  return (
    <Flex
      as="main"
      minH="100dvh"
      flex={1}
      bg="gray.100"
      position="relative"
      flexDir={isLargerThan800 ? "row" : "column"}
      style={{
        containerType: "inline-size",
      }}
    >
      {isLargerThan800 ? <SideBar /> : <SideBarMobile />}
      <Flex w="full" flexDir="column">
        {isLargerThan800 ? (
          <Flex
            as="nav"
            w="full"
            px={16}
            py={3}
            gap={6}
            bg="blackAlpha.900"
            justifyContent="flex-end"
            alignItems="center"
            shadow="md"
          >
            {hasPermission ? (
              <>
                {totalMainAccountDebt ? (
                  <Flex
                    gap={2}
                    w="max-content"
                    color="whiteAlpha.900"
                    bg="red.500"
                    p={2}
                    lineHeight={1}
                    borderRadius={4}
                    textAlign="center"
                  >
                    <HandCoins size={20} />
                    <Text fontSize="xl">
                      {convertCentsToReais(totalMainAccountDebt * -1)}
                    </Text>
                  </Flex>
                ) : null}
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Flex
                      as={motion.div}
                      bg="whiteAlpha.400"
                      borderRadius={4}
                      p={2}
                      lineHeight={1}
                      textAlign="center"
                      fontSize="xl"
                      color="whiteAlpha.900"
                      gap={2}
                    >
                      <Wallet size={20} />
                      {bankInfo?.mainAccount?.availableBalanceInCents
                        ? convertCentsToReais(
                            bankInfo?.mainAccount?.availableBalanceInCents
                          )
                        : convertCentsToReais(0)}
                    </Flex>
                  </PopoverTrigger>
                  <PopoverContent
                    bg="black"
                    borderRadius={4}
                    borderColor="whiteAlpha.400"
                    p={2}
                  >
                    <PopoverArrow bg="whiteAlpha.400" />
                    <PopoverCloseButton />
                    <PopoverHeader
                      textAlign="center"
                      color="whiteAlpha.900"
                      borderColor="whiteAlpha.400"
                    >
                      <strong>Contas</strong>
                    </PopoverHeader>
                    <PopoverBody>
                      <Flex
                        flexDir="column"
                        color="whiteAlpha.900"
                        alignItems="center"
                        w="full"
                        pb={4}
                      >
                        <List gap={2} p={2} textAlign="center">
                          {hasPermission && bankInfo ? (
                            <>
                              <ListItem pb={2}>
                                <Button
                                  as={Link}
                                  to="/settings/add-balance?accountType=main-account"
                                  borderRadius={4}
                                  bg="whiteAlpha.200"
                                  _hover={{
                                    bg: "whiteAlpha.400",
                                  }}
                                  alignSelf="flex-end"
                                  p={8}
                                >
                                  <Flex
                                    as={motion.div}
                                    borderRadius={4}
                                    p={2}
                                    lineHeight={1}
                                    textAlign="center"
                                    fontSize="sm"
                                    color="whiteAlpha.900"
                                    gap={2}
                                  >
                                    <Flex direction="column" gap={2}>
                                      <Wallet size={30} />
                                    </Flex>
                                    <Flex direction="column" gap={2}>
                                      <Flex gap={2}>
                                        <strong>Conta Principal</strong>
                                      </Flex>
                                      <Flex gap={2}>
                                        <strong>Conta: </strong>
                                        {
                                          bankInfo?.mainAccount
                                            ?.bankAccountNumber
                                        }
                                      </Flex>
                                    </Flex>
                                    <Flex direction="column" gap={2}>
                                      <Flex gap={2}>
                                        <strong>Agência: </strong>
                                        {
                                          bankInfo?.mainAccount
                                            ?.bankBranchNumber
                                        }
                                      </Flex>
                                      <Flex gap={2}>
                                        <strong>Saldo: </strong>
                                        {convertCentsToReais(
                                          bankInfo?.mainAccount
                                            ?.availableBalanceInCents || 0
                                        )}
                                      </Flex>
                                    </Flex>
                                    <Flex direction="column" gap={2}>
                                      <DotsThreeVertical size={30} />
                                    </Flex>
                                  </Flex>
                                </Button>
                              </ListItem>
                              <ListItem>
                                <Button
                                  as={Link}
                                  to="/settings/add-balance?accountType=payment-account"
                                  borderRadius={4}
                                  bg="whiteAlpha.200"
                                  _hover={{
                                    bg: "whiteAlpha.400",
                                  }}
                                  alignSelf="flex-end"
                                  p={8}
                                >
                                  <Flex
                                    as={motion.div}
                                    borderRadius={4}
                                    p={2}
                                    lineHeight={1}
                                    textAlign="center"
                                    fontSize="sm"
                                    color="whiteAlpha.900"
                                    gap={2}
                                  >
                                    <Flex direction="column" gap={2}>
                                      <Lock size={30} />
                                    </Flex>
                                    <Flex direction="column" gap={2}>
                                      <Flex gap={2}>
                                        <strong>Conta Repasse</strong>
                                      </Flex>
                                      <Flex gap={2}>
                                        <strong>Conta: </strong>
                                        {
                                          bankInfo?.paymentAccount
                                            ?.bankAccountNumber
                                        }
                                      </Flex>
                                    </Flex>
                                    <Flex direction="column" gap={2}>
                                      <Flex gap={2}>
                                        <strong>Agência: </strong>
                                        {
                                          bankInfo?.paymentAccount
                                            ?.bankBranchNumber
                                        }
                                      </Flex>
                                      <Flex gap={2}>
                                        <strong>Saldo: </strong>
                                        {convertCentsToReais(
                                          bankInfo?.paymentAccount
                                            ?.availableBalanceInCents || 0
                                        )}
                                      </Flex>
                                    </Flex>
                                    <Flex direction="column" gap={2}>
                                      <DotsThreeVertical size={30} />
                                    </Flex>
                                  </Flex>
                                </Button>
                              </ListItem>
                            </>
                          ) : null}
                        </List>
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </>
            ) : null}
            {user?.company?.companyType === COMPANY_TYPE.FRANQUEADO &&
              user.company.franchisorId ===
                Number(import.meta.env.VITE_IFOOD_COMPANY_ID) &&
              !isFirstLoad && (
                <Tooltip
                  label="Somente perfil administrativo consegue cadastrar ou atualizar os entregadores"
                  aria-label="A tooltip"
                >
                  <Flex
                    as={motion.div}
                    bg="#fff"
                    borderRadius={4}
                    p={2}
                    lineHeight={1}
                    textAlign="center"
                    direction={"column"}
                    fontSize="xl"
                    color={isLogged ? "green.500" : "#EA1D2C"}
                    gap={2}
                    cursor={"pointer"}
                  >
                    <Text>
                      {isLogged ? "Logado no IFood" : "Deslogado no IFood"}
                    </Text>
                    <Text fontSize={"sm"}>{role}</Text>
                  </Flex>
                </Tooltip>
              )}
            <Avatar
              border="1px"
              borderColor="whiteAlpha.900"
              name={user?.firstName + " " + user?.lastName}
              icon={<User size={24} />}
              onClick={() => navigate("/settings")}
              cursor="pointer"
              _hover={{
                borderWidth: "2px",
              }}
            />
          </Flex>
        ) : null}
        <Flex w="full" flex={1} py={8} px={8}>
          {state === "loading" ? <LoadingLogo /> : <Outlet />}
        </Flex>
      </Flex>
    </Flex>
  );
}
