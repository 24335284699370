import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { ListTransferred } from "./list-transferred";
import { ListPending } from "./list-pending";
import { RunBalanceMigration } from "./run-balance-migration";
import { AutomaticMigration } from "./automatic-migration";

export function BalanceMigration() {
  return (
    <Tabs w="full" colorScheme="green">
      <TabList>
        <Tab>Transferidos</Tab>
        <Tab>Pendentes</Tab>
        <Tab>Rodar migração</Tab>
        <Tab>Desativar/Habilitar migração automática</Tab>
      </TabList>

      <TabPanels h="full">
        <TabPanel>
          <ListTransferred />
        </TabPanel>
        <TabPanel>
          <ListPending />
        </TabPanel>
        <TabPanel>
          <RunBalanceMigration />
        </TabPanel>
        <TabPanel>
          <AutomaticMigration />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
