import React from "react";
import {
  ActionFunction,
  LazyRouteFunction,
  LoaderFunction,
  Outlet,
  RouteObject,
  ShouldRevalidateFunction,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import {
  ChartLine,
  IdentificationCard,
  MicrosoftExcelLogo,
  Money,
  Prohibit,
  Storefront,
  UserCircle,
  Moped,
  DeviceMobile,
  Gear,
  ArchiveBox,
  Planet,
  Receipt,
  ArrowFatLinesUp,
  Coins,
  CurrencyDollar,
  Wallet,
} from "@phosphor-icons/react";

import { useCompaniesStore } from "../store/company.store";
import { useAdvanceUserStore } from "../store/user-advance.store";
import { useBlockedDeliverymanStore } from "../store/deliveryman-blocked.store";
import { useCurrentUserStore } from "../store/current-user.store";
import { useDeliverymansStore } from "../store/deliveryman.store";
import { useDebtStore } from "../store/debt.store";
import {
  useTransactionsDraftStore,
  useTransactionsStore,
} from "../store/transaction.store";

import { Companies } from "../screens/support/companies";
import { Home } from "../screens/support/home";
import { RootLayout } from "../layout/RootSupportLayout";
import { Adiantamento } from "../screens/support/advances";
import DashboardIframe from "../components/dashboard-iframe";
import { EditCompany } from "../screens/support/companies/edit-company";
import { CreateCompany } from "../screens/support/companies/create-company";
import { AdvanceDetail } from "../screens/support/advances/details";
import { BlockDeliveryman } from "../screens/support/deliveryman/block";
import { Users } from "../screens/support/users";
import { CreateUser } from "../screens/support/users/create-user";
import { ConfigLayout } from "../layout/ConfigSupportLayout";
import { AdiantamentoConfig } from "../screens/support/configurations/advance-configs";
import { ApprovalConfig } from "../screens/support/configurations/approval-configs";
import { getWithAuth } from "../services/basicService";
import { Profile } from "../screens/profile";
import { Planilhas } from "../screens/support/sheets";
import { Deliverymans } from "../screens/deliverymans";
import { CreateDeliveryman } from "../screens/deliverymans/create-one";
import { CreateManyDeliverymans } from "../screens/deliverymans/create-many";
import { EditDeliveryman } from "../screens/deliverymans/edit";

import { AxiosError } from "axios";
import { BalanceConfig } from "../screens/support/configurations/balance-configs";
import { CreateDebt } from "../screens/support/debt/companies/create";
import { Debts } from "../screens/support/debt/companies";
import { DebtDetails } from "../screens/enterprises/debts/details";
import { queryClient } from "../main";
import { UserDetails } from "../screens/support/users/user-details";
import { AppMobile } from "../screens/support/app/send-message";
import { AppUsers } from "../screens/support/dock-users";
import { AppUserDetails } from "../screens/support/dock-users/details";
import { AppLayout } from "../layout/app-menu/app-layout";
import { UserBalanceSupport } from "../screens/support/balances";
import { Conciliation } from "../screens/support/adiantamento/conciliation";
import { SplitPaymentsLayout } from "../layout/split-payments/split-payments-layout";
import { PaymentDetails } from "../screens/payments/transactions-by-payroll";
import { PayrollProcessing } from "../screens/payments/list-processing-payroll";
import { PaymentsSupport } from "../screens/support/payments";
import { ListAllTransactionsSupport } from "../screens/support/payments/list-all-transactions";
import { usePayrollStore } from "../store/payroll.store";
import { BalanceMigration } from "../screens/support/app/balance-migration";
import { DefaultErrorElementSupport } from "../screens/error/default-error-support";
import { DebtLayout } from "../layout/debt-menu/debt-layout";
import { ListDeliverymenDebts } from "../screens/support/debt/deliverymen";
import { CreateDebtDeliveryman } from "../screens/support/debt/deliverymen/create";
import { WebviewEmulation } from "../screens/support/app/web-view-emulation";
import { DetailsInstallments } from "../screens/support/debt/deliverymen/details-installments";
import { PayrollScheduled } from "../screens/payments/list-scheduled-payroll";
import { CompaniesFee } from "../screens/support/companiesfee";
import { PayrollType } from "../enums/payroll-type";
import { AccountLimits } from "../screens/support/app/account-limits";
import { GenerateCnabProcess } from "../screens/support/cnab";
import { Questionnaire } from "../screens/support/app/questionnaire";
import { ScheduledPayrollDetails } from "../screens/payments/transactions-drafts-by-payroll";
import { DebtType } from "../enums/debt-type";
import { LoginIfoodSupport } from "../screens/support/configurations/login-ifood";
import { UserPaymentDebtProvider } from "../screens/payments/user-debt/context/user-payment-debt-context.provider";
import { UserDebt } from "../screens/payments/user-debt";
import { PayrollConciliated } from "../screens/payments/list-conciliated-payroll";
import { PayrollInstant } from "../screens/payments/list-intants-payroll";
import { PortfolioManagementLayout } from "../layout/portfolio-management/portfolio-management-layout";
import { ProductControlScheduledPayrollList } from "../layout/portfolio-management/scheduled-payroll/product-control-scheduled-payroll-list";
import { SyncCsv } from "../screens/deliverymans/sync-csv";
import { SyncCsvProvider } from "../screens/deliverymans/sync-csv/context/sync-csv.provider";
import { PayrollScheduleSettings } from "../screens/support/configurations/payroll-schedule-settings";
import { PayrollScheduleSettingsProvider } from "../screens/support/configurations/payroll-schedule-settings/context/payroll-schedule-settings.provider";

export declare type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

export type Children = {
  icon?: React.ReactElement;
  type?: string;
  name?: string;
  key?: string;
  hidden?: boolean;
  index?: boolean;
  path?: string;
  element?: React.ReactNode | null;
  loader?: LoaderFunction;
};

export interface CustomRouteProps {
  path?: string;
  type?: string;
  name?: string;
  key?: string;
  icon?: React.ReactNode;
  hidden?: boolean;
  index?: boolean;
  children?: Children[];
  checkPermission?: boolean;
  caseSensitive?: boolean;
  id?: string;
  loader?: LoaderFunction;
  action?: ActionFunction;
  element?: React.ReactNode | null;
  Component?: React.ComponentType | null;
  errorElement?: React.ReactNode | null;
  ErrorBoundary?: React.ComponentType | null;
  handle?: RouteObject["handle"];
  shouldRevalidate?: ShouldRevalidateFunction;
  lazy?: LazyRouteFunction<RouteObject>;
}

export const authenticatedSupportRoutes = [
  {
    type: "internal",
    name: "Dashboard",
    index: true,
    icon: <ChartLine weight="bold" size={20} />,
    element: <Home />,
    hidden: true,
  },
  {
    type: "internal",
    name: "Planilhas",
    key: "planilhas",
    path: "sheets",
    icon: <MicrosoftExcelLogo weight="bold" size={20} />,
    element: <Planilhas />,
  },

  {
    type: "internal",
    path: "settings",
    key: "configurations",
    name: "Configurações",
    icon: <Gear weight="bold" size={20} />,
    loader: () => {
      const user = useCurrentUserStore.getState().user;
      const company = user?.company;

      if (company && +company.id !== 1) {
        return redirect("/");
      }

      return null;
    },
    element: <ConfigLayout />,
    hidden: true,
    children: [
      {
        element: <AdiantamentoConfig />,
        hidden: true,
        index: true,
        loader: async () => {
          const status = await getWithAuth(
            "/api/v1/configuration/adiantamento-status"
          );

          return { status: /true/.test(status?.data.value) };
        },
      },
      {
        name: "Configurações",
        key: "payroll-schedule",
        path: "payroll-schedule",
        element: (
          <PayrollScheduleSettingsProvider>
            <PayrollScheduleSettings />
          </PayrollScheduleSettingsProvider>
        ),
      },
      {
        name: "Aprovação automática",
        key: "approval",
        path: "approval",
        element: <ApprovalConfig />,
        loader: async () => {
          const status = await getWithAuth(
            "/api/v1/configuration/aprovacao-status"
          );

          return { status: /true/.test(status?.data.value) };
        },
      },
      {
        name: "Cadastramento de saldo",
        path: "balance",
        element: <BalanceConfig />,
        loader: async () => {
          const status = await getWithAuth(
            "/api/v1/configuration/companies-can-send-balance"
          );

          return { status: /true/.test(status?.data.value) };
        },
      },
      {
        name: "Perfil",
        icon: <IdentificationCard weight="bold" size={24} />,
        path: "perfil",
        element: <Profile />,
      },
      {
        name: "Login Ifood",
        path: "login-ifood",
        element: <LoginIfoodSupport />,
      },
    ],
  },
  {
    type: "internal",
    name: "Empresas",
    key: "companies",
    icon: <Storefront weight="bold" size={20} />,
    path: "companies",
    element: (
      <>
        <Outlet />
      </>
    ),
    checkPermission: true,
    children: [
      {
        type: "internal",
        index: true,
        element: <Companies />,
      },
      {
        path: "edit-company/:companyId",
        element: <EditCompany />,

        loader: async ({ params }: { params: Params }) => {
          const getCompany = useCompaniesStore.getState().getCompany;
          const getCompanyPermissions =
            useCompaniesStore.getState().getCompanyPermissions;
          const getCompanyBankLimits =
            useCompaniesStore.getState().getCompanyBankLimits;

          if (params.companyId)
            return await Promise.allSettled([
              getCompanyPermissions(Number(params.companyId)),
              getCompany(Number(params.companyId)),
              getCompanyBankLimits(Number(params.companyId)),
            ]);
        },
      },
      {
        path: "create-company",
        element: <CreateCompany />,
      },
    ],
  },

  {
    type: "internal",
    name: "Usuários",
    key: "users",
    icon: <UserCircle weight="bold" size={20} />,
    path: "users",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <Users />,
      },
      {
        type: "internal",
        path: "create-user",
        element: <CreateUser />,
        loader: async () => {
          try {
            const companies = await queryClient.value().fetchQuery({
              queryKey: ["all-companies"],
              queryFn: async () => {
                const response = await getWithAuth("/api/v1/companies/all");
                return response?.data;
              },
            });
            return { companies };
          } catch (error) {
            return { companies: [] };
          }
        },
      },
      {
        type: "internal",
        path: "edit-user/:id",
        element: <UserDetails />,
        loader: async () => {
          try {
            const companies = await queryClient.value().fetchQuery({
              queryKey: ["all-companies"],
              queryFn: async () => {
                const response = await getWithAuth("/api/v1/companies/all");
                return response?.data;
              },
            });
            return { companies };
          } catch (error) {
            return { companies: [] };
          }
        },
      },
    ],
  },
  {
    type: "internal",
    name: "Adiantamentos",
    key: "adiantamentos",
    icon: <Money weight="bold" size={20} />,
    path: "adiantamentos",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <Adiantamento />,
      },
      {
        type: "internal",
        path: ":advanceId",
        loader: async ({ params }: { params: Params }) => {
          const getAdvanceUser = useAdvanceUserStore.getState().getAdvanceUser;
          if (params.advanceId) {
            await getAdvanceUser(params.advanceId);
          }

          return null;
        },
        element: <AdvanceDetail />,
      },
      {
        type: "internal",
        path: "conciliacao",
        key: "conciliation",
        element: <Conciliation />,
      },
    ],
  },
  {
    type: "internal",
    name: "Saldos",
    key: "track-user-balance",
    icon: <Coins weight="bold" size={20} />,
    path: "balances",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <UserBalanceSupport />,
      },
    ],
  },
  {
    type: "internal",
    name: "Cnab",
    key: "cnab",
    icon: <CurrencyDollar size={20} />,
    path: "cnab",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <GenerateCnabProcess />,
      },
    ],
  },
  {
    type: "internal",
    name: "Taxas",
    key: "company-fee",
    icon: <CurrencyDollar size={20} />,
    path: "companiesfee",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <CompaniesFee />,
      },
    ],
  },
  {
    type: "internal",
    name: "Bloqueio de entregadores",
    icon: <Prohibit weight="bold" />,
    path: "block-deliverymen",
    key: "block-deliverymen",
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <BlockDeliveryman />,
        loader: async () => {
          const user = useCurrentUserStore.getState().user;

          const company = user?.company;

          if (company && +company.id !== 1) {
            return redirect("/");
          }

          const hasBlockedCpf =
            useBlockedDeliverymanStore.getState().blockedCpfs.length > 0;

          const hasBlockedId =
            useBlockedDeliverymanStore.getState().blockedIfoodIds.length > 0;

          if (!hasBlockedCpf || !hasBlockedId) {
            const getBlockedCpf =
              useBlockedDeliverymanStore.getState().getBlockedCpfs;

            const getBlockedIds =
              useBlockedDeliverymanStore.getState().getBlockedIfoodIds;

            return await Promise.all([getBlockedCpf(), getBlockedIds()]);
          }

          return null;
        },
      },
    ],
  },
  {
    type: "internal",
    name: "Entregadores",
    key: "deliverymen",
    icon: <Moped fontWeight="bold" size={20} />,
    path: "/deliverymans",
    element: (
      <>
        <Outlet />
      </>
    ),
    checkPermission: true,
    children: [
      {
        type: "internal",
        index: true,
        element: <Deliverymans />,
      },
      {
        type: "internal",
        path: "sync-csv",
        element: (
          <SyncCsvProvider>
            <SyncCsv />
          </SyncCsvProvider>
        ),
      },
      {
        type: "internal",
        path: "create-one",
        element: <CreateDeliveryman />,
      },
      {
        type: "internal",
        path: "create-many",
        element: <CreateManyDeliverymans />,
      },
      {
        type: "internal",
        path: "edit/:id",
        element: <EditDeliveryman />,
        loader: async ({ params }: { params: Params }) => {
          if (params.id) {
            await useDeliverymansStore
              .getState()
              .getDeliveryman(Number(params.id));
          }
          return null;
        },
      },
    ],
  },
  {
    type: "internal",
    name: "Débitos",
    key: "debts",
    path: "debts-support",
    icon: <ArchiveBox weight="bold" size={20} />,
    element: <DebtLayout />,
    children: [
      {
        type: "internal",
        index: true,
        loader: async ({ request }: { request: Request }) => {
          const url = new URL(request.url);
          const startDate = url.searchParams.get("startDate");
          const endDate = url.searchParams.get("endDate");
          const companyId = url.searchParams.get("companyId");
          const page = url.searchParams.get("page");
          const type = url.searchParams.get("type");

          try {
            await useDebtStore.getState().getAll({
              filters: {
                startDate: startDate || undefined,
                endDate: endDate || undefined,
                companyId: companyId || undefined,
                type: (type as DebtType) || undefined,
              },
              page: page ? +page : 1,
              limit: 10,
            });
          } catch (error) {
            if (error instanceof AxiosError) {
              throw error.message;
            }
          }

          return null;
        },
        element: <Debts />,
      },
      {
        type: "internal",
        path: "company/create",
        element: <CreateDebt />,
        loader: async () => {
          try {
            const companies = await queryClient.value().fetchQuery({
              queryKey: ["all-companies"],
              queryFn: async () => {
                const response = await getWithAuth("/api/v1/companies/all");
                return response?.data;
              },
            });
            return { companies };
          } catch (error) {
            return { companies: [] };
          }
        },
      },
      {
        type: "internal",
        path: "deliveryman/create/:id",
        element: <CreateDebtDeliveryman />,
      },
      {
        type: "internal",
        path: "details/:id",
        loader: async ({ params }: { params: Params }) => {
          if (params.id) {
            try {
              await Promise.all([
                useCurrentUserStore.getState().refreshData(),
                useDebtStore.getState().getDebtById(+params.id),
              ]);
              const user = useCurrentUserStore.getState().user;
              const debt = useDebtStore.getState().debt;
              if (
                user?.company?.id !== 1 &&
                user?.company?.id !== debt?.company.id
              ) {
                return redirect("/debts");
              }

              return null;
            } catch (error) {
              if (error instanceof AxiosError) {
                throw error.message;
              }
            }
          }
          return null;
        },
        element: <DebtDetails />,
      },
      {
        type: "internal",
        path: "deliveryman",
        element: <ListDeliverymenDebts />,
      },
      {
        type: "internal",
        path: "deliveryman/details/:id",
        element: <DetailsInstallments />,
      },
    ],
  },
  {
    type: "internal",
    name: "App",
    icon: <DeviceMobile weight="bold" size={20} />,
    path: "app",
    key: "mobile-app",
    element: <AppLayout />,
    children: [
      {
        type: "internal",
        index: true,
        element: <AppMobile />,
        loader: async () => {
          return null;
        },
      },
      {
        type: "internal",
        path: "app-users",
        element: <AppUsers />,
      },
      {
        type: "internal",
        path: "app-users/details/:userId/:document",
        element: <AppUserDetails />,
      },
      {
        name: "Migração de saldo",
        key: "balance-migration",
        path: "balance-migration",
        element: <BalanceMigration />,
      },
      {
        name: "Emulador webview",
        key: "webview-emulator",
        path: "webview-emulator",
        element: <WebviewEmulation />,
      },
      {
        name: "Limites da conta",
        key: "account-limits",
        path: "account-limits",
        element: <AccountLimits />,
      },
      {
        name: "Questionário",
        key: "questionnaire",
        path: "questionnaire",
        element: <Questionnaire />,
      },
    ],
  },
  {
    type: "internal",
    name: "App",
    path: "/app-users",
    icon: <DeviceMobile weight="bold" size={20} />,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <AppUsers />,
      },
    ],
  },
  {
    type: "internal",
    name: "Dashboard adiantamento",
    key: "dashboard-adiantamentos",
    icon: <ChartLine weight="bold" size={20} />,
    path: "dashboard-adiantamentos",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Dashboard CS",
    key: "dashboard-cs",
    icon: <ChartLine weight="bold" size={20} />,
    path: "dashboard-cs",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Dashboard Financeiro",
    key: "dashboard-trampay-financial",
    icon: <ChartLine weight="bold" size={20} />,
    path: "dashboard-trampay-financial",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Dashboard Comercial",
    key: "dashboard-commercial",
    icon: <ChartLine weight="bold" size={20} />,
    path: "dashboard-commercial",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Dashboard usuários",
    key: "dashboard-usuarios",
    icon: <ChartLine weight="bold" size={20} />,
    path: "dashboard-usuarios",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Gestão de Carteira",
    key: "portfolio-management",
    icon: <Wallet weight="bold" size={20} />,
    path: "portfolio-management",
    element: <PortfolioManagementLayout />,
    checkPermission: true,
    children: [
      {
        type: "internal",
        path: "scheduled-payroll",
        element: <ProductControlScheduledPayrollList />,
      },
    ],
  },
  {
    type: "internal",
    name: "Upload Relatórios",
    key: "upload-relatorios",
    icon: <ArrowFatLinesUp weight="bold" size={20} />,
    path: "upload-relatorios",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "external",
    name: "Área interna",
    key: "area-interna",
    icon: <Planet weight="bold" size={20} />,
    path: "area-interna",
    element: null,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Pagamentos",
    key: "split-payments",
    path: "payments",
    icon: <MicrosoftExcelLogo weight="bold" size={20} />,
    element: <SplitPaymentsLayout config="support" />,
    children: [
      {
        type: "internal",
        path: "user-debt",
        element: (
          <UserPaymentDebtProvider>
            <UserDebt />,
          </UserPaymentDebtProvider>
        ),
      },
      {
        type: "internal",
        index: true,
        element: <PaymentsSupport />,
        loader: async ({ request }: { request: Request }) => {
          let user = useCurrentUserStore.getState().user;

          if (!user) {
            await queryClient.value().refetchQueries(["user-data"]);
            user = useCurrentUserStore.getState().user;
          }

          const url = new URL(request.url);

          const endDate = url.searchParams.get("endDate");
          const startDate = url.searchParams.get("startDate");
          const page = url.searchParams.get("page");
          const companyId = url.searchParams.get("companyId");
          const type = url.searchParams.get("type") as PayrollType;

          try {
            await usePayrollStore.getState().getAllSheets({
              filters: {
                startDate: startDate || undefined,
                endDate: endDate || undefined,
                companyId: companyId ? +companyId : undefined,
                type: type || undefined,
              },
              page: page ? +page : 1,
              limit: 10,
            });

            return null;
          } catch (error) {
            if (error instanceof AxiosError) {
              throw error.message;
            }
          }

          return null;
        },
      },
      {
        type: "internal",
        path: "details/:id",
        loader: async ({
          params,
          request,
        }: {
          params: Params;
          request: Request;
        }) => {
          if (params.id) {
            const url = new URL(request.url);
            const name = url.searchParams.get("name");
            const document = url.searchParams.get("document");
            const page = url.searchParams.get("page");
            const status = url.searchParams.get("status");

            await Promise.all([
              usePayrollStore.getState().getPayroll(+params.id),
            ]);

            try {
              await useTransactionsStore.getState().getTransactionsByPayrollId({
                filters: {
                  name: name || undefined,
                  document: document || undefined,
                  status: status || undefined,
                },
                page: page ? +page : 1,
                limit: 10,
                id: params.id,
              });

              return null;
            } catch (error) {
              if (error instanceof AxiosError) {
                throw error.message;
              }
            }
          }
          return null;
        },
        element: <PaymentDetails />,
      },
      {
        type: "internal",
        path: "extract",
        element: <ListAllTransactionsSupport />,
        loader: async ({ request }: { request: Request }) => {
          await useCurrentUserStore.getState().refreshData();
          const urlRequest = new URL(request.url);

          const getAllSearchParams = new URLSearchParams([
            ...Array.from(urlRequest.searchParams.entries()),
          ]);

          try {
            const response = await getWithAuth(
              `api/v1/transaction/extract/all?onlyPayroll=true&${getAllSearchParams}`
            );

            return response?.data;
          } catch (error) {
            if (error instanceof AxiosError) {
              throw error.message;
            }
          }

          return null;
        },
      },
      {
        type: "internal",
        path: "scheduled/details/:id",
        loader: async ({
          params,
          request,
        }: {
          params: Params;
          request: Request;
        }) => {
          if (params.id) {
            const url = new URL(request.url);
            const name = url.searchParams.get("name");
            const document = url.searchParams.get("document");
            const page = url.searchParams.get("page");
            const status = url.searchParams.get("status");

            await Promise.all([
              useCurrentUserStore.getState().refreshData(),
              usePayrollStore.getState().getPayroll(+params.id),
            ]);
            const user = useCurrentUserStore.getState().user;
            const payroll = usePayrollStore.getState().payroll;

            if (
              user?.company?.id !== 1 &&
              user?.company?.id !== payroll?.company_id
            ) {
              return redirect("/payments");
            }

            try {
              if (!name || !page || !document || !status) {
                await useTransactionsDraftStore
                  .getState()
                  .getTransactionsDraftByCompanyAndPayrollId({
                    page: 1,
                    limit: 10,
                    id: params.id,
                  });
              }

              await useTransactionsDraftStore
                .getState()
                .getTransactionsDraftByCompanyAndPayrollId({
                  filters: {
                    name: name || undefined,
                    document: document || undefined,
                  },
                  page: page ? +page : 1,
                  limit: 10,
                  id: params.id,
                });
            } catch (error) {
              if (error instanceof AxiosError) {
                throw error.message;
              }
            }
          }
          return null;
        },
        element: <ScheduledPayrollDetails />,
      },
      {
        type: "internal",
        path: "is-processing",
        element: <PayrollProcessing />,
        loader: async () => {
          try {
            await usePayrollStore.getState().getProcessingPayroll();
          } catch (error) {
            if (error instanceof AxiosError) {
              throw error.message;
            }
          }

          return null;
        },
      },
      {
        type: "internal",
        path: "is-scheduled",
        element: <PayrollScheduled />,
      },
      {
        type: "internal",
        path: "is-conciliated",
        element: <PayrollConciliated />,
      },
      {
        type: "internal",
        path: "is-instant",
        element: <PayrollInstant />,
      },
    ],
  },
  {
    type: "internal",
    name: "Extratos",
    path: "/extract",
    key: "extract",
    icon: <Receipt weight="bold" size={20} />,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <ListAllTransactionsSupport />,
        loader: async ({ request }: { request: Request }) => {
          await useCurrentUserStore.getState().refreshData();
          const urlRequest = new URL(request.url);

          const getAllSearchParams = new URLSearchParams([
            ...Array.from(urlRequest.searchParams.entries()),
          ]);

          try {
            const response = await getWithAuth(
              `api/v1/transaction/extract/all?${getAllSearchParams}`
            );

            return response?.data;
          } catch (error) {
            if (error instanceof AxiosError) {
              throw error.message;
            }
          }

          return null;
        },
      },
    ],
  },
];

const authenticatedRoutesWithLayout = [
  {
    path: "/",
    element: <RootLayout />,
    children: authenticatedSupportRoutes,
    errorElement: <DefaultErrorElementSupport />,
  },
];

export const authenticatedRouter = createBrowserRouter(
  authenticatedRoutesWithLayout
);
