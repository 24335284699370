import { create } from "zustand";
import { AxiosError } from "axios";
import { getWithAuth, patchWithAuth } from "../services/basicService";
import { api } from "../services/api";
import { useDebtStore } from "./debt.store";
import { useModalStore } from "./components/modal.store";
import { UserDto, UserTrampay } from "../dto/user-dto";
import { bankInfoResponse } from "../types/bank-info.type";
import { RolePermissionDto } from "../dto/role-permission-dto";
import { CompanyDto } from "../dto/company-dto";
import * as Sentry from "@sentry/react";

export interface UserStore {
  user: UserDto | null;
  token: string | null;
  twoFactorHash: string | null;
  permissions: RolePermissionDto[];
  usersTrampay: UserTrampay[];

  signIn: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<boolean>;
  signOut: () => Promise<void>;
  sendTwoFactorCode(uuid: string): Promise<void>;
  verifyTwoFactorCode(code: number): Promise<void>;
  refreshData: () => Promise<UserDto>;
  updateProfile: (data: {
    firstName?: string;
    lastName?: string;
    oldPassword?: string;
    password?: string;
  }) => Promise<void>;
  getBankInfo: () => Promise<bankInfoResponse>;
  getPermissions: () => Promise<RolePermissionDto[]>;
  getUsersTrampay: () => Promise<UserTrampay[]>;
  updateCurrentUserCompanyIntegrations: (roles: string[]) => Promise<void>;
}

export const useCurrentUserStore = create<UserStore>((set, get) => ({
  user: null,
  token: null,
  twoFactorHash: null,
  permissions: [],
  usersTrampay: [],
  refreshData: async () => {
    try {
      const response = await getWithAuth("/api/v1/auth/me");

      if (response?.data) {
        Sentry.setUser({
          id: response.data.id,
          email: response.data.email,
        });

        set({ user: response.data });
        return response.data;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error?.response?.data);
      }

      throw new Error(JSON.stringify(error));
    }
  },

  signIn: async ({ email, password }) => {
    try {
      const response = await api.post(`/api/v1/auth/email/login`, {
        email,
        password,
      });

      if (response.data.user) {
        localStorage.setItem("trampay-token", response.data.token);
        set({ user: response.data.user });
        set({ token: response.data.token });

        Sentry.setUser({
          id: response.data.user.id,
          email: response.data.user.email,
        });

        const debts = await useDebtStore.getState().getOpenedOrScheduledDebts();

        if (debts.openDebts.length > 0 || debts.scheduledDebts.length > 0) {
          useModalStore.getState().changeDebtsModalStatus(true);
        }

        return false;
      }

      set({ twoFactorHash: response?.data?.uuid });
      await get().sendTwoFactorCode(response?.data?.uuid);

      return true;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error("Não foi possível autenticar usuário");
    }
  },

  sendTwoFactorCode: async (uuid) => {
    try {
      await api.post(`/api/v1/two-factor-auth-code/generate`, {
        uuid,
      });
    } catch (error) {
      throw new Error("Não foi possível enviar email");
    }
  },

  verifyTwoFactorCode: async (code) => {
    const response = await api.post(`/api/v1/two-factor-auth-code/verify`, {
      uuid: get().twoFactorHash,
      code,
    });

    if (response.data.user) {
      localStorage.setItem("trampay-token", response.data.token);
      set({ user: response.data.user, token: response.data.token });

      try {
        const debts = await useDebtStore.getState().getOpenedOrScheduledDebts();

        if (debts.openDebts.length > 0 || debts.scheduledDebts.length > 0) {
          useModalStore.getState().changeDebtsModalStatus(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },

  signOut: async () => {
    localStorage.removeItem("trampay-token");
    set({ user: null, token: null, twoFactorHash: null });
  },

  getPermissions: async () => {
    try {
      const response = await getWithAuth("/api/v1/auth/myPermissions");

      if (response?.data) {
        const permissions: RolePermissionDto[] = response.data;

        set({ permissions });

        return permissions;
      }

      return [] as RolePermissionDto[];
    } catch (error) {
      throw new Error("Não foi possível autenticar usuário");
    }
  },

  getUsersTrampay: async () => {
    const response = await getWithAuth(
      "/api/v1/users/trampay?statusId=1&roleId=7"
    );

    if (response?.data) {
      const usersTrampay: UserTrampay[] = response.data;

      set({ usersTrampay });

      return usersTrampay;
    }

    return [] as UserTrampay[];
  },

  updateProfile: async (
    data: Partial<{
      firstName?: string;
      lastName?: string;
      oldPassword?: string;
      password?: string;
    }>
  ) => {
    (Object.keys(data) as (keyof typeof data)[]).forEach((key) => {
      if (!data[key]) {
        delete data[key];
      }
    });
    try {
      await patchWithAuth("/api/v1/auth/me", data);
    } catch (error) {
      throw new Error("Não foi possível atualizar dados do usuário");
    }
  },

  getBankInfo: async () => {
    const company = await get().user?.company;
    const mainAccountId = company?.account_id;
    const paymentAccountId = company?.noAliasAccountId;
    const bankData = {
      mainAccount: {
        pix: {
          pixKeys: [],
          qrCode: {},
        },
        debts: [],
      },
      paymentAccount: {
        pix: {
          pixKeys: [],
          qrCode: {},
        },
        debts: [],
      },
    } as unknown as bankInfoResponse;
    let mainAccountError = false;
    try {
      if (mainAccountId) {
        const bankMainAccountData = await getWithAuth(
          `/api/v1/companies/bank/data/${mainAccountId}`
        );
        Object.assign(bankData.mainAccount, bankMainAccountData?.data);
      }
    } catch (error) {
      console.log(error);
      mainAccountError = true;
    }
    try {
      if (paymentAccountId) {
        const bankPaymentAccountData = await getWithAuth(
          `/api/v1/companies/bank/data/${paymentAccountId}`
        );
        Object.assign(bankData.paymentAccount, bankPaymentAccountData?.data);
      }
    } catch (error) {
      console.log(error);
      if (mainAccountError) throw error;
    }
    return bankData;
  },
  updateCurrentUserCompanyIntegrations: async (roles) => {
    const currentUser = await get().user;

    if (!currentUser || !currentUser.company) {
      throw new Error("Usuário ou empresa não encontrado.");
    }

    const updatedCompany: CompanyDto = {
      ...currentUser?.company,
      integrations: {
        ...currentUser?.company?.integrations,
        ifood: {
          ...currentUser?.company?.integrations?.ifood,
          roles,
        },
      },
    };

    set({
      user: {
        ...currentUser,
        company: updatedCompany,
      },
    });
  },
}));
