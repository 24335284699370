import { Badge, Box, Flex, HStack, Heading, VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ArrowFatLinesRight } from "@phosphor-icons/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useSearchParams } from "react-router-dom";
import { ptBR } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Pagination } from "../../../../components/pagination";
import { getWithAuth } from "../../../../services/basicService";
import { debtColorMap, debtTextMap } from "../../../../dto/debt-dto";
import { DebtStatus } from "../../../../enums/debt-status";
import { IDeliverymenDebtsResponse } from "../interfaces/deliverymen-debts.interface";
import { format } from "date-fns";
import { CompanyDto } from "../../../../dto/company-dto";

const debtStatus = [
  "",
  "draft",
  "scheduled",
  "open",
  "closed",
  "canceled",
] as const;

const referenceDateZod = z.object({
  period: z
    .object({
      from: z.date(),
      to: z.date().optional(),
    })
    .optional(),
  document: z.string().optional(),
  status: z.enum(debtStatus).optional(),
  companyId: z.string().optional(),
});

type ReferenceDateInput = z.infer<typeof referenceDateZod>;

export function ListDeliverymenDebts() {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    control,
    getValues,
    reset,
  } = useForm<ReferenceDateInput>({
    resolver: zodResolver(referenceDateZod),
  });

  const getDeliverymenDebts = async () => {
    const { period, document, status, companyId } = getValues();

    let from: Date;
    let to: Date;
    let documentWithoutPunctuation: string;

    let queries = "?";

    if (period && !period?.to) period.to = period.from;

    if (period?.from && period?.to) {
      from = dayjs(period.from).set("hour", 9).toDate();
      to = dayjs(period.to).set("hour", 9).toDate();
    }
    if (document) {
      documentWithoutPunctuation = document?.replace(/[.-]/g, "");
    }

    setSearchParams((query) => {
      if (from) query.set("debtStartDate", String(from));
      if (to) query.set("debtEndDate", String(to));
      if (documentWithoutPunctuation)
        query.set("document", documentWithoutPunctuation);
      if (status) query.set("status", status);
      else query.delete("status");

      if (companyId) query.set("companyId", String(companyId));
      if (companyId === "all") query.delete("companyId");

      queries += query.toString();

      return query;
    });

    const URL = `/api/v1/debt/deliverymen${queries}`;

    const { data } = (await getWithAuth(URL)) as {
      data: IDeliverymenDebtsResponse;
    };

    return data;
  };

  const { data, refetch } = useQuery({
    queryKey: [
      "deliverymen-debts",
      { page: searchParams.get("page"), limit: searchParams.get("limit") },
    ],
    queryFn: getDeliverymenDebts,
    enabled: true,
  });

  const { data: companies } = useQuery({
    queryKey: ["companies"],
    queryFn: async () => {
      const response = await getWithAuth("api/v1/companies/all");
      return response?.data as CompanyDto[];
    },
    enabled: true,
  });

  const submit: SubmitHandler<ReferenceDateInput> = () => {
    refetch();
  };

  const cleanFilters = () => {
    setSearchParams((prev) => {
      prev.delete("debtStartDate");
      prev.delete("debtEndDate");
      prev.delete("document");
      prev.delete("companyId");

      return prev;
    });

    reset();
    refetch();
  };

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Flex justifyContent="space-between" w="full">
        <Heading fontWeight="extrabold" textAlign="left">
          Débitos lançados
        </Heading>
        <Link to="/deliverymans">
          <Button
            type="submit"
            bg="green.600"
            w="max-content"
            color="whiteAlpha.900"
            _hover={{
              bg: "green.700",
            }}
            px={8}
            size="lg"
          >
            Lançar débito
          </Button>
        </Link>
      </Flex>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={8}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={8}
          p={8}
          onSubmit={handleSubmit(submit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
            gap={8}
          >
            <Flex gap={8} alignItems="end">
              <FormControl as="fieldset" w="max-content">
                <FormLabel as="legend">Data do débito</FormLabel>
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button>Escolha a sua data</Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent bg="white" p={2} w="max-content">
                      <PopoverBody>
                        <Controller
                          control={control}
                          name="period"
                          render={({ field: { value, onChange } }) => (
                            <DayPicker
                              id="dateFilter"
                              mode="range"
                              initialFocus={true}
                              selected={value}
                              onSelect={onChange}
                              locale={ptBR}
                              modifiersClassNames={{
                                disabled: "btn-disabled",
                                selected: "custom-selected",
                                outside: "outside-day",
                              }}
                            />
                          )}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </FormControl>

              <FormControl as="fieldset" name="document">
                <FormLabel as="legend">CPF</FormLabel>
                <Input {...register("document")} />
              </FormControl>

              <FormControl as="fieldset">
                <FormLabel as="legend">Status</FormLabel>
                <Select
                  variant="filled"
                  size="md"
                  {...register("status")}
                  w="full"
                >
                  <option value="">Todos</option>
                  {Object.entries(DebtStatus).map(([key, value]) => (
                    <option value={value} key={key}>
                      {debtTextMap[value]}
                    </option>
                  ))}
                </Select>
                {errors.status && (
                  <FormErrorMessage>{errors.status.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Empresa</FormLabel>
                <Select
                  variant="filled"
                  size="md"
                  {...register("companyId")}
                  w="full"
                >
                  <option value="all">Todos</option>
                  {companies?.map((company) => (
                    <option value={company.id} key={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
                {errors.status && (
                  <FormErrorMessage>{errors.status.message}</FormErrorMessage>
                )}
              </FormControl>

              <Button
                type="submit"
                bg="green.600"
                w="max-content"
                color="whiteAlpha.900"
                _hover={{
                  bg: "green.700",
                }}
                px={8}
                size="md"
                isDisabled={!isDirty}
              >
                Filtrar
              </Button>

              <Button w="max-content" px={10} size="md" onClick={cleanFilters}>
                Limpar Filtros
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          pb={4}
          gap={4}
          display="flex"
          flexDir="column"
          style={{
            containerType: "inline-size",
          }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th textAlign="center">Nome</Th>
                <Th textAlign="center">Documento</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Data do débito</Th>
                <Th textAlign="center">Ver detalhes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.result?.map((debt) => (
                <Tr key={debt.installmentsId}>
                  <Td>{debt.deliveryman.name}</Td>
                  <Td textAlign="center">{debt.deliveryman.document}</Td>
                  <Td textAlign="center">
                    <Flex
                      display="grid"
                      gridTemplateColumns="repeat(4, 1fr)"
                      gridRowGap="2"
                      gridColumnGap="2"
                    >
                      {debt.installments.map((installment) => (
                        <Badge
                          key={installment.id}
                          colorScheme={debtColorMap[installment.status]}
                          variant="solid"
                          px={4}
                        >
                          {debtTextMap[installment.status]}
                        </Badge>
                      ))}
                    </Flex>
                  </Td>
                  <Td textAlign="center">
                    {(() => {
                      const launchedDate = debt.installments.filter(
                        (e) => e?.launchedDate !== null
                      )[0]?.launchedDate;

                      return launchedDate
                        ? format(new Date(launchedDate), "dd/MM/yyyy")
                        : "Data não disponível";
                    })()}
                  </Td>

                  <Td textAlign="center">
                    <Link
                      to={`/debts-support/deliveryman/details/${debt.installmentsId}`}
                    >
                      <IconButton
                        aria-label="campo para ir a tela de detalhes da folha pendente"
                        role="a"
                        size="md"
                        icon={<ArrowFatLinesRight />}
                        color="white"
                        w="50%"
                        _hover={{
                          bg: "green.600",
                        }}
                      />
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={Number(searchParams.get("page"))}
            setPage={(page) =>
              setSearchParams((prev) => {
                prev.set("page", String(page));
                return prev;
              })
            }
            totalPages={data?.totalPages ?? 1}
          />
        </HStack>
      </Box>
    </VStack>
  );
}
