import { Box, Flex, HStack, Heading, Text, VStack } from "@chakra-ui/layout";
import { useDebtStore } from "../../../store/debt.store";
import { useModalStore } from "../../../store/components/modal.store";
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { Debt } from "../../../dto/debt-dto";
import { convertCentsToReais } from "../../../utils/convert-money";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
// import { RegisterDelvierymanTab } from "./register-deliveryman";
import { SiteNavigationTab } from "./site-navigation";
import { SplitPaymentTab } from "./split-payment";
import { ScaleTab } from "./scale-tutorial";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { isFranchisor } from "../../../utils/company-verifications";
import { CompanyDto } from "../../../dto/company-dto";
import WelcomeImage from "../../../assets/welcome-ifood.png";
import { TRAMPAY_COMPANY_ID } from "../../../utils/constants/trampay-company-id";

export function Home() {
  const me = useCurrentUserStore((state) => state.user);
  const openDebts = useDebtStore((state) => state.openDebts);
  const scheduledDebts = useDebtStore((state) => state.scheduledDebts);
  const totalOpenAmount = useDebtStore((state) => state.totalOpenAmount);
  const totalScheduledAmount = useDebtStore(
    (state) => state.totalScheduledAmount
  );
  const showPendentDebts = useModalStore((state) => state.showPendentDebts);
  const changeModalStatus = useModalStore(
    (state) => state.changeDebtsModalStatus
  );

  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const isUserTrampay = me?.companyId === TRAMPAY_COMPANY_ID;

  function closeModal() {
    changeModalStatus(false);
    onClose();
  }

  function writeOpenDebtDateMessage(debts: Debt[]) {
    if (debts.length === 0) {
      return <StatHelpText>Não há débitos em aberto.</StatHelpText>;
    }

    const olderDebt = debts[debts.length - 1];

    if (dayjs().isSame(olderDebt.debt_date, "day")) {
      return <StatHelpText>Um débito vencerá hoje.</StatHelpText>;
    }

    return (
      <StatHelpText>
        Existe débito pendente na data{" "}
        {dayjs(olderDebt.debt_date).format("DD/MM/YYYY")}.
      </StatHelpText>
    );
  }

  if (isFranchisor(me?.company as CompanyDto)) {
    return (
      <VStack
        justifyContent="center"
        alignItems="center"
        w="100%"
        h="100%"
        maxH="100vh"
      >
        <Image
          src={WelcomeImage}
          alt="Instruções de boas vindas para o usuário"
          objectFit="fill"
          loading="eager"
          h="50%"
          w="50%"
          borderRadius="8px"
        />
      </VStack>
    );
  }

  return (
    <HStack gap={4} w="full" alignItems="flex-start">
      <VStack alignItems="flex-start" w="full" gap={8}>
        <Heading fontWeight="extrabold" textAlign="left">
          Tutoriais
        </Heading>
        <Tabs w="full" colorScheme="green">
          <TabList>
            {!isUserTrampay && me?.company?.scheduledSplitFlow ? (
              <Tab>Fluxo de Pagamento</Tab>
            ) : null}
            {/*   <Tab>Cadastro de entregadores</Tab> */}
            <Tab>Funcionalidades</Tab>
            <Tab>Agendamentos</Tab>
          </TabList>

          <TabPanels>
            {!isUserTrampay && me?.company?.scheduledSplitFlow ? (
              <TabPanel>
                <SplitPaymentTab />
              </TabPanel>
            ) : null}
            {/* <TabPanel>
              <RegisterDelvierymanTab />
            </TabPanel> */}
            <TabPanel>
              <SiteNavigationTab />
            </TabPanel>
            <TabPanel>
              <ScaleTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>

      <Modal onClose={closeModal} isOpen={showPendentDebts} isCentered>
        <ModalOverlay />
        <ModalContent maxW="50rem">
          <ModalHeader>Existem débitos abertos/agendados</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex gap={2} flexDir={{ base: "column", lg: "row" }}>
              <Box
                bg="whiteAlpha.800"
                border="1px"
                borderColor="gray.300"
                borderRadius={8}
                p={4}
                w="full"
                h="full"
                shadow="base"
              >
                <Stat w="max-content">
                  <Flex flexDir="row" alignItems="center" gap={2}>
                    <StatLabel fontSize="xl">Abertos: </StatLabel>
                    <StatNumber fontSize="3xl" color="green.500">
                      {convertCentsToReais(totalOpenAmount)}
                    </StatNumber>
                  </Flex>
                  {writeOpenDebtDateMessage(openDebts)}
                </Stat>
              </Box>
              <Box
                bg="whiteAlpha.800"
                border="1px"
                borderColor="gray.300"
                borderRadius={8}
                p={4}
                w="full"
                h="full"
                shadow="base"
              >
                <Stat w="max-content">
                  <Flex flexDir="row" alignItems="center" gap={2}>
                    <StatLabel fontSize="xl">Agendados: </StatLabel>
                    <StatNumber fontSize="3xl" color="green.500">
                      {convertCentsToReais(totalScheduledAmount)}
                    </StatNumber>
                  </Flex>

                  <StatHelpText>
                    {scheduledDebts.length === 0
                      ? "Não há débitos agendados."
                      : `Próximo débito agendado para a data ${dayjs(
                          scheduledDebts[scheduledDebts.length - 1].debt_date
                        ).format("DD/MM/YYYY")}.`}
                  </StatHelpText>
                </Stat>
              </Box>
            </Flex>

            <Text textAlign="center" color="gray" mt="5">
              OBS: Os valores estão sujeitos a alterações até o fechamento ser
              realizado.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                closeModal();
                navigate("/debts", { relative: "path" });
              }}
            >
              Verificar Débitos
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
}
