import { ShiftAssignmentStatus as statusesEnum } from "../enums/shift-assignment-status";
import { Shift } from "./shift-dto";
import { Subplaza } from "./subplaza-dto";

export interface ShiftAssignment {
  id: number;
  companyId: number;
  shiftId: number;
  shift: Shift;
  subplazaId: number;
  subplaza: Subplaza;
  maxDeliveryman: number;
  dateScale: string;
  maxSurplusDeliveryman: number;
  setting: {
    acceptance: string;
  };
  status?: statusesEnum;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface PaginateShiftAssignment {
  page: number;
  limit: number;
  nextPage: boolean;
  totalPages: number;
  result: ShiftAssignment[];
}

export interface ISearchShiftAssignmentDto {
  shiftId?: number;
  subplazaId?: number;
  uploadedFileId?: number;
  maxDeliveryman?: number;
  setting: {
    acceptance: string;
  };
  fromDateScale?: Date;
  toDateScale?: Date;
  maxSurplusDeliveryman?: number;
  status?: statusesEnum;
  companyId?: number;
}

export const textSchemeMap = {
  [statusesEnum.CREATED]: "Criada",
  [statusesEnum.PUBLISHED]: "Publicada",
  [statusesEnum.CLOSED]: "Fechada",
};

export const colorSchemeMap = {
  [statusesEnum.PUBLISHED]: "green",
  [statusesEnum.CLOSED]: "red",
  [statusesEnum.CREATED]: "gray",
};
