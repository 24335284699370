import { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { AdvanceUserWithTransactionInfoDto } from "../../../../dto/advance-user-dto";
import dayjs from "dayjs";
import { convertCentsToReais } from "../../../../utils/convert-money";
import { useSearchParams } from "react-router-dom";
import { useExportCsv } from "../../../../hooks/adiantamento/use-export-csv";

interface Props {
  advances: AdvanceUserWithTransactionInfoDto[];
}

export function ProcessedAdvances({ advances }: Props) {
  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const { exportCsv, loading: loadingExportCsv } = useExportCsv();

  const filteredAdvances = advances.filter((advance) => {
    const hasCompanyFilter = !!urlSearchParams.get("companyName");
    const hasPracaFilter = !!urlSearchParams.get("praca");

    if (hasCompanyFilter && hasPracaFilter) {
      return (
        advance.praca === urlSearchParams.get("praca") &&
        advance.companyName === urlSearchParams.get("companyName")
      );
    }

    if (hasCompanyFilter && !hasPracaFilter) {
      return advance.companyName === urlSearchParams.get("companyName");
    }

    if (!hasCompanyFilter && hasPracaFilter) {
      return advance.praca === urlSearchParams.get("praca");
    }

    return false;
  });

  const maxPages =
    filteredAdvances.length > 0
      ? Math.ceil(filteredAdvances.length / 10)
      : Math.ceil(advances.length / 10);

  const companiesSet = new Set(advances.map((advance) => advance.companyName));
  const pracaSet = new Set(advances.map((advance) => advance.praca));

  const exportUserAdvances = () => {
    const URL = "/api/v1/userAdvance/conciliation/csv";
    let queries = "";

    urlSearchParams.forEach((value, key) => {
      queries += `${key}=${value}&`;
    });

    exportCsv(URL, "adiantamentos-conciliacao", queries);
  };

  return (
    <TableContainer
      w="full"
      display="flex"
      flexDir="column"
      alignItems="center"
      flex={1}
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      p={8}
      shadow="base"
      bg="whiteAlpha.800"
      gap={8}
    >
      <Flex w="100%" justifyContent="space-between">
        <Flex alignSelf="flex-start" flexDir="column" gap={4}>
          <Heading size="lg">Filtros</Heading>
          <Flex gap={4}>
            <FormControl>
              <FormLabel>Empresas</FormLabel>
              <Select
                placeholder="Seleciona uma empresa"
                onChange={(ev) => {
                  setURLSearchParams((prev) => {
                    prev.set("companyName", ev.currentTarget.value);
                    return prev;
                  });
                }}
              >
                {[...companiesSet].map((company) => (
                  <option value={company} key={company}>
                    {company}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Praças</FormLabel>
              <Select
                placeholder="Seleciona uma praca"
                onChange={(ev) => {
                  setURLSearchParams((prev) => {
                    prev.set("praca", ev.currentTarget.value);
                    return prev;
                  });
                }}
              >
                {[...pracaSet].map((praca) => (
                  <option value={praca} key={praca}>
                    {praca}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
        </Flex>
        <Flex alignItems="center" gap={15}>
          <Tooltip
            hasArrow
            placement="top"
            label="Os dados exportados serão baseados no filtro dessa página"
            fontSize="md"
          >
            <Icon fontSize={20} color="black" />
          </Tooltip>
          <Button
            isLoading={loadingExportCsv}
            loadingText="Exportando"
            disabled={loadingExportCsv}
            role="a"
            size="lg"
            bg="green.500"
            color="white"
            onClick={exportUserAdvances}
          >
            Exportar CSV
          </Button>
        </Flex>
      </Flex>

      <Table variant="striped" colorScheme="green">
        <Thead>
          <Tr>
            <Th flex={1} textAlign="center">
              Nome
            </Th>
            <Th textAlign="center">Documento</Th>
            <Th textAlign="center">Valor (R$)</Th>
            <Th textAlign="center">Data da transação</Th>
            <Th textAlign="center">Data da vencimento</Th>
            <Th textAlign="center">N° Contrato</Th>
            <Th textAlign="center">Conta destino</Th>
            <Th textAlign="center">Empresa</Th>
            <Th textAlign="center">Praça</Th>
            {/* <Th textAlign="center">Detalhes</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {(filteredAdvances.length > 0 ? filteredAdvances : advances)
            .slice(0, page * 10)
            .map((advance) => (
              <Tr key={advance.id}>
                <Td textAlign="center">{advance.userName}</Td>
                <Td textAlign="center">{advance.document}</Td>
                <Td textAlign="center">
                  {convertCentsToReais(advance.amount - advance.amountFee)}
                </Td>
                <Td textAlign="center">
                  {dayjs(
                    advance.money_transfer_response?.transactionDate
                  ).format("DD/MM/YYYY")}
                </Td>
                <Td textAlign="center">
                  {dayjs(advance.due_date).format("DD/MM/YYYY")}
                </Td>
                <Td textAlign="center">{advance.contract_number}</Td>
                <Td textAlign="center">
                  {advance.money_transfer_request?.data.destinationAccount}
                </Td>
                <Td textAlign="center">{advance.companyName}</Td>
                <Td textAlign="center">{advance.praca}</Td>
                {/* <Td textAlign="center">
                <Button>Ver</Button>
              </Td> */}
              </Tr>
            ))}
        </Tbody>
        {page < maxPages ? (
          <Button onClick={() => setPage((prev) => prev + 1)}>
            Carregar mais
          </Button>
        ) : null}
      </Table>
    </TableContainer>
  );
}
