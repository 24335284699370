export function totalDays(date1: Date, date2: Date) {
  const difference = Math.abs(date1.getTime() - date2.getTime());
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return totalDays;
}

export function currentTimeZone() {
  const currentDate = new Date();

  const timeZoneMinutes = currentDate.getTimezoneOffset();

  const sinal = timeZoneMinutes > 0 ? "-" : "+";
  const hours = Math.abs(Math.floor(timeZoneMinutes / 60));
  const minutes = Math.abs(timeZoneMinutes % 60);

  const timeZone = `${sinal}${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return timeZone;
}

export function convertTimeToTimeWithTimeZone(time: string) {
  const currentDate = new Date();
  const timeParts = time.split(":");

  const hours = parseInt(timeParts[0], 10) || 0;
  const minutes = parseInt(timeParts[1], 10) || 0;
  const seconds = parseInt(timeParts[2], 10) || 0;

  currentDate.setHours(hours, minutes, seconds, 0);

  const timeWithTimeZone = currentDate.toISOString().split("T")[1];

  return timeWithTimeZone;
}

export function convertTimeWithTimeZoneToCurrentTimeZone(time: string) {
  const currentDate = new Date();
  const timeParts = time.split(":");

  const hours = parseInt(timeParts[0], 10) || 0;
  const minutes = parseInt(timeParts[1], 10) || 0;
  const seconds = parseInt(timeParts[2], 10) || 0;

  currentDate.setUTCHours(hours, minutes, seconds, 0);

  const timeWithCurrentTimeZone = currentDate.toLocaleTimeString();

  return timeWithCurrentTimeZone;
}
