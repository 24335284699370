import { IconButton, Td, Tr } from "@chakra-ui/react";
import { IUserDebtDTO, debtStatusTextMap } from "../../../../dto/user-debt-dto";
import { memo } from "react";
import { format } from "date-fns";
import { Upload } from "@phosphor-icons/react";
import { useUserPaymentDebtContext } from "../context/use-user-payment-debt-context";
import { convertCentsToReais } from "../../../../utils/convert-money";
const Component = ({ data }: { data: IUserDebtDTO }) => {
  const { handleChangeModalStatus, setModalInfo } = useUserPaymentDebtContext();

  return (
    <Tr>
      <Td>{data?.deliverymanName}</Td>
      <Td>{data?.deliverymanCpf}</Td>
      <Td>{convertCentsToReais(data?.totalAmount)}</Td>
      <Td>{format(new Date(data?.launchDate), "dd/MM/yyyy")}</Td>
      <Td>{format(new Date(data?.nextTransferDate), "dd/MM/yyyy")}</Td>
      <Td>{debtStatusTextMap[data?.status]}</Td>
      <Td textAlign="center">
        <IconButton
          aria-label="details"
          onClick={() => {
            handleChangeModalStatus();
            setModalInfo(data);
          }}
        >
          <Upload />
        </IconButton>
      </Td>
    </Tr>
  );
};

export const TableRowUserDebt = memo(Component);
