import { z } from "zod";
import { useUsersDashboardStore } from "../../../../store/user-company.store";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flex, HStack, Heading } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { CaretLeft } from "@phosphor-icons/react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useRoleStore } from "../../../../store/role.store";
import { RoleEnum } from "../../../../enums/roles-enum";
import { TRAMPAY_COMPANY_ID } from "../../../../utils/constants/trampay-company-id";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { verifyIfUserHasRole } from "../../../../utils/verify-if-user-has-role";
import { AxiosError } from "axios";
import { AxiosDataError } from "../../../../dto/axios-error-dto";

const createUserFormSchema = z
  .object({
    email: z.string().email("Insira um email válido"),
    password: z.string().min(8, "Mínimo 8 caracteres"),
    confirmPassword: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    isAdmin: z.boolean().default(false),
    company: z.coerce.number().positive(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "As senhas não batem",
    path: ["confirmPassword"],
  });

type createUserFormInputs = z.infer<typeof createUserFormSchema>;

export function CreateUser() {
  const client = useQueryClient();
  const createUser = useUsersDashboardStore((state) => state.createUser);
  const { companies } = useLoaderData() as {
    companies: { id: number; name: string }[];
  };
  const getRoleByName = useRoleStore((state) => state.getRoleByName);
  const me = useCurrentUserStore((state) => state.user);
  const isAdmin = me?.roles
    ? verifyIfUserHasRole(me?.roles, RoleEnum.ADMIN_TRAMPAY)
    : null;

  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<createUserFormInputs>({
    resolver: zodResolver(createUserFormSchema),
  });

  async function newUserRoles(companyId: number, isAdmin: boolean) {
    const isCompanyTrampay = TRAMPAY_COMPANY_ID === companyId;

    if (isAdmin) {
      const userRole = isCompanyTrampay
        ? await getRoleByName(RoleEnum.USER_TRAMPAY)
        : await getRoleByName(RoleEnum.USER_COMPANY);

      const adminRole = isCompanyTrampay
        ? await getRoleByName(RoleEnum.ADMIN_TRAMPAY)
        : await getRoleByName(RoleEnum.ADMIN_COMPANY);

      return [userRole, adminRole];
    }

    return [
      isCompanyTrampay
        ? await getRoleByName(RoleEnum.USER_TRAMPAY)
        : await getRoleByName(RoleEnum.USER_COMPANY),
    ];
  }

  async function onSubmit(data: createUserFormInputs) {
    const formatData = {
      ...data,
      company: {
        id: data.company,
      },
      status: {
        id: 1,
      },
      roles: await newUserRoles(data.company, data.isAdmin),
    };

    try {
      await createUser(formatData);
      client.invalidateQueries(["list-users"]);
      toast({
        status: "success",
        title: "Usuário criado com sucesso!",
        duration: 2000,
      });
      navigate("/users");
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data as AxiosDataError;
        const errorMessage = errorData.message;
        toast({
          status: "error",
          title: "Não foi possível criar o usuário",
          description: `Não foi possível criar o usuário: ${errorMessage}`,
          duration: 2000,
        });
        return;
      }
      toast({
        status: "error",
        title: "Não foi possível criar o usuário",
        description: `Não foi possível criar o usuário, contate o suporte`,
        duration: 2000,
      });
    }
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading>Criar usuário</Heading>
      </HStack>
      <Heading size="lg">Dados do usuário</Heading>

      <Flex
        as="form"
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        p={8}
        borderRadius={8}
        shadow="base"
        bg="whiteAlpha.800"
        id="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.firstName} isRequired>
            <FormLabel>Nome</FormLabel>
            <Input {...register("firstName")} />
            {errors.firstName ? (
              <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.lastName} isRequired>
            <FormLabel>Sobrenome</FormLabel>
            <Input {...register("lastName")} />
            {errors.lastName ? (
              <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        <Flex gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.email} isRequired>
            <FormLabel>Email</FormLabel>
            <Input {...register("email")} />
            {errors.email ? (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.company} isRequired>
            <FormLabel>Empresa</FormLabel>
            <Select placeholder="Selecione a empresa" {...register("company")}>
              {companies?.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
            {errors.company ? (
              <FormErrorMessage>{errors.company.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        <Flex gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.password} isRequired>
            <FormLabel>Senha</FormLabel>
            <Input {...register("password")} />
            {errors.password ? (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl
            as="fieldset"
            isInvalid={!!errors.confirmPassword}
            isRequired
          >
            <FormLabel>Confirme a senha</FormLabel>
            <Input {...register("confirmPassword")} />
            {errors.confirmPassword ? (
              <FormErrorMessage>
                {errors.confirmPassword.message}
              </FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        {isAdmin ? (
          <FormControl as="fieldset" isInvalid={!!errors.isAdmin}>
            <FormLabel htmlFor="is-admin" mb="0">
              Administrador?
            </FormLabel>
            <Switch
              id="is-admin"
              {...register("isAdmin")}
              colorScheme="green"
              size="lg"
            />
            {errors.confirmPassword ? (
              <FormErrorMessage>
                {errors.confirmPassword.message}
              </FormErrorMessage>
            ) : null}
          </FormControl>
        ) : null}
        <Button
          type="submit"
          form="form"
          size="lg"
          alignSelf="flex-end"
          isLoading={isSubmitting}
        >
          Criar Usuário
        </Button>
      </Flex>
    </Flex>
  );
}
