import { Flex, Heading } from "@chakra-ui/layout";
import { useLocation } from "react-router-dom";
import { SubMenuButton } from "../../split-payments/submenu/sub-menu-button";

export function AppSubMenu() {
  const { pathname } = useLocation();
  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      flexDir="column"
      bg="whiteAlpha.800"
      borderRadius={8}
      h="min-content"
      gap={4}
      minW="sm"
      shadow="base"
      border="1px"
      borderColor="gray.300"
    >
      <Heading size="md">Menu App</Heading>

      <Flex w="full" justifyContent="flex-start" flexDir="column" gap={2}>
        <SubMenuButton to="/app" isSelected={pathname === "/app"} fontSize="md">
          Envio de mensagem
        </SubMenuButton>
        <SubMenuButton
          to="/app/app-users"
          isSelected={pathname.includes("/app/app-users")}
          fontSize="md"
        >
          Usuário do app
        </SubMenuButton>
        <SubMenuButton
          to="/app/balance-migration"
          isSelected={pathname.includes("/app/balance-migration")}
          fontSize="md"
        >
          Migração de saldo
        </SubMenuButton>
        <SubMenuButton
          to="/app/webview-emulator"
          isSelected={pathname.includes("/app/webview-emulator")}
          fontSize="md"
        >
          Emulador webview
        </SubMenuButton>
        <SubMenuButton
          to="/app/account-limits"
          isSelected={pathname.includes("/app/account-limits")}
          fontSize="md"
        >
          Limites transacionais
        </SubMenuButton>
        <SubMenuButton
          to="/app/questionnaire?state=all"
          isSelected={pathname.includes("/app/questionnaire")}
          fontSize="md"
        >
          Questionários
        </SubMenuButton>
      </Flex>
    </Flex>
  );
}
