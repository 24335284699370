export enum TransactionStatus {
  ERROR = "error",
  SUCCESS = "success",
  PENDING = "pending",
  PROCESSING = "processing",
  DENIED = "denied",
  SCHEDULED = "scheduled",
  SCHEDULE_CANCELED = "schedule_canceled",
  ROLLBACK = "rollback",
  APPROVED = "approved",
  REVERSAL = "reversal",
  NOT_EXECUTED = "not_executed",
}
