import { Box, HStack } from "@chakra-ui/layout";
import { Button, useToast } from "@chakra-ui/react";
import { GenericList } from "../../../../components/scales/generic-list";
import {
  useShiftAssignmentPreferenceCachedService,
  useShiftAssignmentPreferenceService,
} from "../../../../services/api/shift-assignment-preferences";
import {
  ShiftAssignmentPreferenceStatus,
  ShiftAssignmentPreferenceStatus as statuses,
} from "../../../../enums/shift-assignment-preference-status";
import {
  ShiftAssignmentPreference,
  textSchemeMap,
} from "../../../../dto/shift-assignment-preference-dto";
import { ShiftAssignmentPreferenceFormSearch } from "./search";
import { ReactNode, useEffect, useState } from "react";
import { formatDate, format } from "../../../../utils/format-date";
import { ShiftAssignmentStatus } from "../../../../enums/shift-assignment-status";
import { useSubplazaCachedService } from "../../../../services/api/subplazas";
import { useShiftCachedService } from "../../../../services/api/shifts";
import { Shift } from "../../../../dto/shift-dto";
import { Subplaza } from "../../../../dto/subplaza-dto";
import { Pagination } from "../../../../components/pagination";
import { useSearchParams } from "react-router-dom";
import { textSchemeMap as shiftAssignmentTextSchemeMap } from "../../../../dto/shift-assignment-dto";
import { SubmenuSearch } from "../../../../components/scales/submenu-filter";
import { formatCPF } from "../../../../utils/format-document";

export function ShiftAssignmentPreferences() {
  const { useMutationAction: useSAPMutationAction } =
    useShiftAssignmentPreferenceCachedService();
  const { paginateFilter } = useShiftAssignmentPreferenceService();
  const { useQueryAction: useSubplazaQueryAction } = useSubplazaCachedService();
  const { useQueryAction: useShiftQueryAction } = useShiftCachedService();
  const [unaccept, { status: unacceptStatus, error: unacceptError }] =
    useSAPMutationAction("unaccept");
  const [accept, { status: acceptStatus, error: acceptError }] =
    useSAPMutationAction("accept");

  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const [filter, setFilter] = useState<Record<string, unknown>>({
    page: "0",
    limit: "10",
  });
  const subplazaQuery = useSubplazaQueryAction("filter", {});
  const shiftQuery = useShiftQueryAction("filter", {});

  const toast = useToast();

  useEffect(() => {
    if (acceptStatus) {
      if (acceptStatus === "success") {
        toast({
          status: "success",
          duration: 3000,
          title: "Manifestação aceita com sucesso!",
        });
        const fetch = async () => {
          setIsLoading(true);
          const response = await paginateFilter(filter);
          if (response) {
            setData(response.result);
            setTotalPages(response.totalPages);
          }
          setIsLoading(false);
        };
        if (acceptStatus === "success") {
          fetch();
        }
      }
      if (acceptStatus === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao aceitar manifestação",
          description: `Falha ao aceitar manifestação: ${acceptError}`,
        });
      }
    }
  }, [toast, filter, acceptStatus, acceptError, paginateFilter]);

  useEffect(() => {
    if (unacceptStatus) {
      if (unacceptStatus === "success") {
        toast({
          status: "success",
          duration: 3000,
          title: "Manifestação recusada com sucesso!",
        });
        const fetch = async () => {
          setIsLoading(true);
          const response = await paginateFilter(filter);
          if (response) {
            setData(response.result);
            setTotalPages(response.totalPages);
          }
          setIsLoading(false);
        };
        if (unacceptStatus === "success") {
          fetch();
        }
      }
      if (unacceptStatus === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao recusar manifestação",
          description: `Falha ao recusar manifestação: ${unacceptError}`,
        });
      }
    }
  }, [toast, filter, unacceptStatus, unacceptError, paginateFilter]);

  const updateFilter = (formData: Record<string, unknown>) => {
    const filters: Record<string, unknown> = Object.entries(formData).reduce(
      (filters: Record<string, unknown>, [key, value]) => {
        if (value !== undefined && value !== "") {
          filters[key] = value;
        }
        return filters;
      },
      {}
    );
    filters.createdAt &&= formatDate(filters, "createdAt", format.US);

    Object.assign(filters, {
      page: searchParams.get("page"),
      limit: searchParams.get("limit"),
    });

    setFilter(filters);
  };

  function formatPage() {
    const page = searchParams.get("page");

    return page ? parseInt(page) : 1;
  }

  const [data, setData] = useState<ShiftAssignmentPreference[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error] = useState<Error>();
  const [totalPages, setTotalPages] = useState<number>(10);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const response = await paginateFilter(filter);
      if (response) {
        setData(response.result);
        setTotalPages(response.totalPages);
      }
      setIsLoading(false);
    };
    fetch();
  }, [filter, filter.page, paginateFilter]);

  const formatCreatedAt = (row: ShiftAssignmentPreference) => {
    const obj = row as unknown as Record<string, unknown>;
    return formatDate(obj, "createdAt", format.BR, "/");
  };

  const formatUpdatedAt = (row: ShiftAssignmentPreference) => {
    const obj = row as unknown as Record<string, unknown>;
    return formatDate(obj, "updatedAt", format.BR, "/");
  };

  function mapperTranslate(row: ShiftAssignmentPreference) {
    const status = row.status as ShiftAssignmentPreferenceStatus;
    return textSchemeMap[status];
  }

  function mapperSATranslate(row: ShiftAssignmentPreference) {
    const status = getStatusScale(row) as ShiftAssignmentStatus;
    return shiftAssignmentTextSchemeMap[status];
  }

  function getShiftById(shiftId: number): Shift | null {
    return shiftQuery.data?.find((shift) => shift.id === shiftId) || null;
  }

  function getSubplazaById(subplazaId: number): Subplaza | null {
    return (
      subplazaQuery.data?.find((subplaza) => subplaza.id === subplazaId) || null
    );
  }

  function getDateScale(row: ShiftAssignmentPreference) {
    const sa = row.shiftAssignment as unknown as Record<string, unknown>;
    return formatDate(sa, "dateScale", format.BR, "/");
  }

  function getStatusScale(row: ShiftAssignmentPreference) {
    const scaleStatus: string = row.shiftAssignment?.status || "";
    return scaleStatus;
  }

  const columns = [
    {
      header: "Agendamento",
      accessor: "scalesStatus",
      dataTransform: mapperSATranslate,
    },
    { header: "Status", accessor: "status", dataTransform: mapperTranslate },
    {
      header: "Data da agendamento",
      accessor: "dataScale",
      dataTransform: getDateScale,
    },
    {
      header: "Data de sinalização",
      accessor: "createdAt",
      dataTransform: formatCreatedAt,
    },
    {
      header: "Sub-praça",
      accessor: "subplaza",
      dataTransform: (row: ShiftAssignmentPreference) => {
        return getSubplazaById(row.shiftAssignment.subplazaId as number)?.name;
      },
    },
    {
      header: "Turno",
      accessor: "shift",
      dataTransform: (row: ShiftAssignmentPreference) => {
        return getShiftById(row.shiftAssignment.shiftId as number)?.name;
      },
    },
    {
      header: "Entregador",
      accessor: "deliveryman",
      dataTransform: (row: ShiftAssignmentPreference) => {
        return row.deliveryman.nameIfood;
      },
    },
    {
      header: "Documento",
      accessor: "document",
      dataTransform: (row: ShiftAssignmentPreference) => {
        return formatCPF(row.deliveryman.document);
      },
    },
    {
      header: "Última atualização",
      accessor: "updatedAt",
      dataTransform: formatUpdatedAt,
    },
  ];

  const paginate = (
    <HStack gap={8} pt={8} justifyContent="flex-end">
      <Pagination
        page={formatPage()}
        setPage={(page) =>
          setSearchParams((prev) => {
            prev.set("page", String(page));
            const filters = Object.assign(filter, {
              page: searchParams.get("page"),
              limit: searchParams.get("limit"),
            });
            setFilter(filters);
            return prev;
          })
        }
        totalPages={totalPages}
      />
    </HStack>
  ) as ReactNode;

  const actions = (row: ShiftAssignmentPreference) => {
    let actionButtons: ReactNode;
    const { id } = row;

    const acceptButton = (
      <Button
        size="sm"
        colorScheme="green"
        mr={2}
        onClick={() => accept({ id })}
      >
        Aceitar
      </Button>
    );

    const rejectButton = (
      <Button
        size="sm"
        colorScheme="red"
        mr={2}
        onClick={() => unaccept({ id })}
      >
        Rejeitar
      </Button>
    );

    const scaleStatus: string = getStatusScale(row);
    if (scaleStatus !== ShiftAssignmentStatus.PUBLISHED) {
      actionButtons = <></>;
    } else {
      if (row.status === statuses.PENDING) {
        actionButtons = (
          <>
            {acceptButton} {rejectButton}
          </>
        );
      }

      if (row.status === statuses.ACCEPTED) {
        actionButtons = <>{rejectButton}</>;
      }

      if (row.status === statuses.UNACCEPTED) {
        actionButtons = <></>;
      }
    }

    return <Box>{actionButtons}</Box>;
  };

  return (
    <>
      <HStack>
        <SubmenuSearch />
        <ShiftAssignmentPreferenceFormSearch updateFilter={updateFilter} />
      </HStack>
      <GenericList
        title="Gestão de disponibilidade"
        dataQuery={{ data, isLoading, error }}
        beforeTable={<div />}
        afterTable={paginate}
        rowActions={actions as (data: unknown) => ReactNode}
        actionsLabel="Ações"
        columns={columns}
      />
    </>
  );
}
