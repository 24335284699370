import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Flex, Heading, VStack } from "@chakra-ui/layout";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { GetDeliveryman } from "../get-deliveryman";
import { getWithAuth } from "../../../../../services/basicService";
import { DeliverymanDto } from "../../../../../dto/deliveryman-dto";
import { CreateDebtDeliverymanMonth } from "./month";
import { CustomInstallments } from "./custom/custom-installments";

export function CreateDebtDeliveryman() {
  const { id } = useParams();

  const { data: deliveryman, isLoading } = useQuery({
    queryKey: [`deliveryman-id-${id}`],
    queryFn: async () => {
      const response = await getWithAuth(`/api/v1/deliveryMan/${id}`);

      return response?.data as DeliverymanDto;
    },
  });

  return (
    <VStack w="full" gap={4}>
      <Heading fontWeight="extrabold" textAlign="left">
        Criar Débito para entregador
      </Heading>
      <Flex display="flex" w="full" gap={8}>
        <Tabs w="full" colorScheme="green">
          <TabList>
            <Tab>Mensal</Tab>
            <Tab>Personalizado</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <CreateDebtDeliverymanMonth {...{ deliveryman }} />
            </TabPanel>
            <TabPanel>
              <CustomInstallments {...{ deliveryman }} />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <GetDeliveryman
          deliveryman={deliveryman}
          getDeliverymanLoading={isLoading}
        />
      </Flex>
    </VStack>
  );
}
