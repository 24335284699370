import { Tr, Td, Input } from "@chakra-ui/react";
import { memo } from "react";

// eslint-disable-next-line react/display-name
export const TableRowWedukaCSV = memo(
  ({
    vacancy,
    period,
    subPraza,
    handleChangeSurplus,
    plaza,
  }: {
    vacancy: { date: string; vacancies: number; surplus: number };
    period: string;
    subPraza: string;
    handleChangeSurplus: (
      subPraza: string,
      date: string,
      period: string,
      value: number,
      plaza?: string
    ) => void;
    plaza?: string;
  }) => {
    return (
      <Tr>
        <Td>{vacancy.date.replace(/-/g, "/")}</Td>
        <Td>{vacancy.vacancies}</Td>
        <Td>
          <Input
            type="number"
            value={vacancy.surplus}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              handleChangeSurplus(subPraza, vacancy.date, period, value, plaza);
            }}
          />
        </Td>
      </Tr>
    );
  }
);
