import { Button } from "@chakra-ui/react";
import { useReceiptAdvance } from "../../../../hooks/adiantamento/use-receipt-advance";
import { Download } from "@phosphor-icons/react";

type IProps = {
  id: number;
  username: string;
};

export function DownloadReceipt({ id, username }: IProps) {
  const { getReceipt, loadingReceipt } = useReceiptAdvance();

  return (
    <Button
      isLoading={loadingReceipt}
      fontSize="sm"
      variant="solid"
      colorScheme="green"
      onClick={() => getReceipt(id, username)}
    >
      <Download weight="bold" size={24} />
    </Button>
  );
}
