import { useUsersDashboardStore } from "../../../../store/user-company.store";
import { Badge, Flex, Heading, Stack, Text } from "@chakra-ui/layout";
import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Trash } from "@phosphor-icons/react";
import { getWithAuth } from "../../../../services/basicService";
import { Role } from "../../../../dto/user-dto";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CustomModal } from "../../../../components/modal";
import { TRAMPAY_COMPANY_ID } from "../../../../utils/constants/trampay-company-id";
import { RoleEnum } from "../../../../enums/roles-enum";

const selectRoleFormSchema = z.object({
  roleId: z.string().array(),
});

type selectRoleInput = z.infer<typeof selectRoleFormSchema>;

const rolesToTrampayUsers: RoleEnum[] = [
  RoleEnum.ADMIN_TRAMPAY,
  RoleEnum.USER_TRAMPAY,
  RoleEnum.ATENDIMENTO,
  RoleEnum.FINANCEIRO_TRAMPAY,
  RoleEnum.TECH_DADOS,
];

export function EditRoles() {
  const client = useQueryClient();
  const user = useUsersDashboardStore((state) => state.user);
  const editUser = useUsersDashboardStore((state) => state.editUser);

  const toast = useToast();

  const fetchRoles = async () => {
    try {
      const data = await getWithAuth(`api/v1/roles`);
      return data;
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Erro ao buscar cargos: " + error,
        duration: 2000,
      });
    }
  };

  const { data, isLoading } = useQuery(["all-roles"], fetchRoles);

  const roleOptions = () => {
    let allRoles = (data?.data as Role[]) ?? [];

    if (user?.companyId !== TRAMPAY_COMPANY_ID) {
      allRoles = allRoles.filter(
        (role) => !rolesToTrampayUsers.includes(role.name as RoleEnum)
      );
    }

    const roleOptions = allRoles?.filter((role) => {
      return !user?.roles.some((item) => item.id === role.id);
    });

    return roleOptions;
  };

  async function handleDelete(id: number) {
    const roles = user?.roles?.filter((role) => role.id !== id);

    if (user?.id) {
      try {
        await editUser(String(user.id), { roles });
        client.invalidateQueries(["user-details"]);
        toast({
          status: "success",
          title: "Cargo removido com sucesso!",
          duration: 2000,
        });
      } catch {
        toast({
          status: "error",
          title: "Não foi possível remover cargo",
          description: `Verifique se o usuário tem pelo menos um cargo.`,
          duration: 2000,
        });
      }
    }
  }

  const submit = async (formData: selectRoleInput) => {
    const allRoles = data?.data as Role[];
    const roles = allRoles.filter((role) =>
      formData.roleId.includes(String(role.id))
    );

    if (user?.id) {
      try {
        await editUser(String(user?.id), { roles: [...roles, ...user.roles] });
        client.invalidateQueries(["user-details"]);
        toast({
          status: "success",
          title: "Usuário editado com sucesso!",
          duration: 2000,
        });
      } catch (error) {
        if (error instanceof Error) {
          toast({
            status: "error",
            title: "Não foi possível editar o usuário",
            description: `Não foi possível editar o usuário, contate o suporte: ${error.message}`,
            duration: 2000,
          });
          return;
        }
        toast({
          status: "error",
          title: "Não foi possível editar o usuário",
          description: `Não foi possível editar o usuário, contate o suporte`,
          duration: 2000,
        });
      }
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<selectRoleInput>({
    resolver: zodResolver(selectRoleFormSchema),
  });

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <Heading size="lg">Editar cargos</Heading>
      <Flex
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        borderRadius={8}
        bg="whiteAlpha.800"
      >
        {!isLoading && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th w="20%">Usuário</Th>
                  <Th textAlign="center" w="40%">
                    Cargos
                  </Th>
                  <Th w="40%">Adicionar cargo</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{`${user?.firstName} ${user?.lastName}`}</Td>
                  <Td textAlign="center">
                    {user?.roles?.map((role) => (
                      <Badge
                        padding={2}
                        mb={2}
                        mx={2}
                        borderRadius={8}
                        key={role.id}
                      >
                        {role?.name}
                        <CustomModal
                          buttonProps={{
                            rightIcon: <Trash size={18} />,
                            color: "white",
                            size: "sm",
                            ml: 2,
                          }}
                          modalHeader="Remover cargo do usuário"
                          modalBody="Realmente deseja remover o cargo do usuário?"
                          confirmButtonText="Remover"
                          onConfirm={() => handleDelete(role.id)}
                        />
                      </Badge>
                    ))}
                  </Td>
                  <Td>
                    {roleOptions()?.length === 0 ? (
                      <Text>Não há cargos disponíveis para esse usuário.</Text>
                    ) : (
                      <Flex
                        gap={4}
                        as="form"
                        onSubmit={handleSubmit(submit)}
                        id="role-form"
                        alignItems="center"
                      >
                        <FormControl as="fieldset" w="fit-content">
                          <Controller
                            control={control}
                            name={"roleId"}
                            render={({ field: { onChange, value } }) => (
                              <CheckboxGroup onChange={onChange} value={value}>
                                <Stack
                                  spacing={[1, 5]}
                                  direction={["column", "row"]}
                                >
                                  {roleOptions()?.map((role) => (
                                    <Checkbox
                                      textTransform="capitalize"
                                      colorScheme="green"
                                      key={role.id}
                                      value={`${role.id}`}
                                    >
                                      {role.name}
                                    </Checkbox>
                                  ))}
                                </Stack>
                              </CheckboxGroup>
                            )}
                          />
                        </FormControl>
                        <CustomModal
                          buttonProps={{
                            color: "whiteAlpha.900",
                            w: "max-content",
                            _hover: {
                              bg: "green.700",
                            },
                            px: 8,
                            size: "md",
                            isDisabled: !isDirty,
                          }}
                          buttonText="Adicionar"
                          modalHeader="Adicionar cargo"
                          modalBody="Realmente deseja adicionar o/s cargo/s ao usuário?"
                          confirmButtonText="Adicionar"
                          onConfirm={handleSubmit(submit)}
                        />
                      </Flex>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Flex>
    </Flex>
  );
}
