import { Table, TableContainer, Tbody, Th, Thead } from "@chakra-ui/react";
import { memo } from "react";
import { IUserDebtDTO } from "../../../../dto/user-debt-dto";
import { TableRowUserDebt } from "./tableRowUserDebt";

const Component = ({ data }: { data: IUserDebtDTO[] }) => {
  return (
    <TableContainer mt={2}>
      <Table variant="striped" size={"sm"}>
        <Thead>
          <Th>Nome</Th>
          <Th>CPF/CNPJ</Th>
          <Th>Valor Negativo</Th>
          <Th>Data da Negativação</Th>
          <Th>Agendamento da Quitação</Th>
          <Th>Status</Th>
          <Th>Detalhamento</Th>
        </Thead>
        <Tbody>
          {data?.map((debt) => <TableRowUserDebt key={debt.id} data={debt} />)}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export const TableUserDebt = memo(Component);
