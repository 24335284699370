import { VStack, Heading, Box, Text } from "@chakra-ui/layout";
import { Button, Textarea } from "@chakra-ui/react";
import { memo, useState } from "react";
import { usePayrollScheduleSettingsContext } from "../context/payroll-schedule-settings.context";

const Component = () => {
  const { currentMessage, handleAddByPassMessage } =
    usePayrollScheduleSettingsContext();

  const [messageInput, setMessageInput] = useState("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setMessageInput(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <VStack
      w="60%"
      alignItems="flex-start"
      spacing={6}
      backgroundColor={"white"}
      padding={6}
      shadow={"base"}
    >
      <Heading size="sm">Mensagem de Folha Agendada</Heading>

      <Box w="100%">
        <Text fontWeight="bold">Mensagem Atual:</Text>
        <Text>{currentMessage}</Text>
      </Box>
      <Box w="100%">
        <Text fontWeight="bold">Editar Mensagem:</Text>
        <Text>
          Prezada empresa, Informamos que o prazo para realizar o processo de
          agendamento foi encerrado e não registramos a conclusão dentro do
          período estipulado. Dessa forma, a liberação da plataforma será
          retomada no dia ________ as ______h, até o dia ______ as ______h, para
          que seja finalizado o processo. Reforçamos que é de extrema
          importância que o processo seja feito por completo, dentro do prazo
          firmado. Caso tenha alguma dúvida ou precise de assistência, nossa
          equipe está à disposição para ajudar.
        </Text>
      </Box>

      <Box w="100%">
        <Text fontWeight="bold">Editar mensagem</Text>
        <Textarea
          placeholder="Digite aqui outra mensagem desejada"
          value={messageInput}
          onChange={handleInputChange}
          resize="none"
          lineHeight="2"
          overflow="hidden"
          rows={5}
        />

        <Button
          colorScheme="green"
          w="100%"
          mt={4}
          onClick={() => handleAddByPassMessage(messageInput)}
        >
          Editar mensagem
        </Button>
      </Box>
    </VStack>
  );
};

export const ByPassMessage = memo(Component);
