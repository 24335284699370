import { create } from "zustand";
import { UserBalanceData } from "../dto/user-balance-dto";
import {
  deleteWithAuth,
  getWithAuth,
  postWithAuth,
} from "../services/basicService";
import dayjs from "dayjs";

interface UserBalanceStore {
  userBalance: UserBalanceData | null;

  getUserBalance: (url: string) => Promise<UserBalanceData | null>;

  deleteUserBalanceByPlaza: (plazas: string[]) => Promise<void>;

  deleteUserBalanceByPeriod: (fromDate: Date, toDate?: Date) => Promise<void>;
}

export const useUserBalanceStore = create<UserBalanceStore>((set) => ({
  userBalance: null,

  getUserBalance: async (url: string) => {
    const response = await getWithAuth(url);
    if (response?.data) {
      const userBalance: UserBalanceData = response?.data;

      set({ userBalance });

      return userBalance;
    }

    return null;
  },

  deleteUserBalanceByPlaza: async (plazas: string[]) => {
    await postWithAuth(`/api/v1/userAdvanceBalance/delete/fortnight/squares`, {
      squares: plazas,
    });
  },

  deleteUserBalanceByPeriod: async (fromDate, toDate) => {
    const periodMap = new Map();
    periodMap.set("fromDate", dayjs(fromDate).startOf("day").toDate());

    if (toDate && !dayjs(toDate).isBefore(fromDate)) {
      periodMap.set("toDate", dayjs(toDate).endOf("day").toDate());
    }

    const period = Object.fromEntries(periodMap.entries());
    await deleteWithAuth(
      `/api/v1/userAdvanceBalance/delete/fortnight/period`,
      {},
      {
        ...period,
      }
    );
  },
}));
