import { create } from "zustand";
import { TransactionStatus } from "../enums/transaction-status";
import {
  deleteWithAuth,
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../services/basicService";
import { PayrollDto } from "../dto/payroll-dto";
import { AxiosError } from "axios";
import { useTransactionsStore } from "./transaction.store";
import {
  TransactionDraftValidationResponse,
  ValidatedTransactionDraft,
} from "../types/transaction.type";
import { GetPayrollDraftById } from "../types/payroll.type";
import { PayrollType } from "../enums/payroll-type";
import { PayrollStatus } from "../enums/payroll-status";
import { PixKeyType } from "../enums/pix-type";
import { ICreateConciliatedTransactionDraftDto } from "../dto/create-conciliated-transaction-draft-dto";
import { TransactionDraftRequestItemDto } from "../screens/enterprises/payments/create-payroll-process/dtos/transaction-draft-dto";
import { ApiTransactionDraftService } from "../services/api/transaction-draft";

interface PayrollStoreProps {
  result: PayrollDto[];
  totalRecords: number;
  totalPages: number;
  nextPage: boolean;
  payroll: PayrollDto | null;
  totalSuccessTransactions: number;
  totalErrorTransactions: number;
  totalPendingTransactions: number;

  getSheets: ({
    filters,
    page,
    limit,
    companyId,
    companyFranchiseeId,
  }: {
    filters?: { startDate?: string; endDate?: string; type?: PayrollType };
    page?: number;
    limit?: number;
    companyId?: number;
    companyFranchiseeId?: string;
  }) => Promise<PayrollDto[]>;
  getAllSheets: ({
    filters,
    page,
    limit,
  }: {
    filters?: {
      startDate?: string;
      endDate?: string;
      companyId?: number;
      type: PayrollType;
    };
    page?: number;
    limit?: number;
  }) => Promise<PayrollDto[]>;
  getSheetsDraft: ({
    filters,
    page,
    limit,
  }: {
    filters?: { startDate?: string; endDate?: string };
    page?: number;
    limit?: number;
    companyId?: number;
  }) => Promise<PayrollDto[]>;
  getScheduledPayroll: (companyId?: number) => Promise<void>;
  getProcessingPayroll: (
    companyId?: number
  ) => Promise<{ result: PayrollDto[] }>;
  getPayroll(id: number): Promise<PayrollDto | null>;
  getPayrollById: (id: number) => Promise<PayrollDto>;
  setPayroll(payrolls: PayrollDto[]): void;
  reprocessPayroll(
    payroll: PayrollDto,
    reprocessOnlyErrors: boolean
  ): Promise<ValidatedTransactionDraft[]>;
  deletePayroll(id: number): Promise<void>;
}

export const usePayrollStore = create<PayrollStoreProps>((set) => ({
  result: [],
  nextPage: false,
  totalPages: 0,
  totalRecords: 0,
  payroll: null,
  totalSuccessTransactions: 0,
  totalErrorTransactions: 0,
  totalPendingTransactions: 0,

  getSheets: async ({
    filters,
    page = 1,
    limit = 10,
    companyId,
    companyFranchiseeId,
  }) => {
    const UrlSearchParams = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });

    if (filters?.startDate) {
      UrlSearchParams.append("startDate", String(filters.startDate));
    }

    if (filters?.endDate) {
      UrlSearchParams.append("endDate", String(filters.endDate));
    }

    if (filters?.type) {
      UrlSearchParams.append("type", filters?.type);
    }

    if (companyFranchiseeId) {
      UrlSearchParams.append("companyFranchiseeId", companyFranchiseeId);
    }

    try {
      const response = await getWithAuth(
        `/api/v1/payroll/sheets/${companyId}?status=processed,reprocessed&${UrlSearchParams.toString()}`
      );

      set({
        result: response?.data.result,
        nextPage: response?.data.nextPage,
        totalPages: response?.data.totalPages,
        totalRecords: response?.data.totalRecords,
      });

      return response?.data.result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getAllSheets: async ({ filters, page = 1, limit = 10 }) => {
    const UrlSearchParams = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });

    if (filters?.startDate) {
      UrlSearchParams.append("startDate", String(filters.startDate));
    }

    if (filters?.endDate) {
      UrlSearchParams.append("endDate", String(filters.endDate));
    }

    if (filters?.companyId) {
      UrlSearchParams.append("companyId", String(filters.companyId));
    }

    if (filters?.type) {
      UrlSearchParams.append("type", filters?.type);
    }

    try {
      const response = await getWithAuth(
        `/api/v1/payroll/sheets?status=processed&${UrlSearchParams.toString()}`
      );

      set({
        result: response?.data.result,
        nextPage: response?.data.nextPage,
        totalPages: response?.data.totalPages,
        totalRecords: response?.data.totalRecords,
      });

      return response?.data.result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getSheetsDraft: async ({ filters, page = 1, limit = 10, companyId }) => {
    const UrlSearchParams = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });

    if (filters?.startDate) {
      UrlSearchParams.append("startDate", String(filters.startDate));
    }

    if (filters?.endDate) {
      UrlSearchParams.append("endDate", String(filters.endDate));
    }

    try {
      const response = await getWithAuth(
        `/api/v1/payroll/sheets/${companyId}?status=draft&${UrlSearchParams.toString()}`
      );

      set({
        result: response?.data.result,
        nextPage: response?.data.nextPage,
        totalPages: response?.data.totalPages,
        totalRecords: response?.data.totalRecords,
      });

      return response?.data.result;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getProcessingPayroll: async (companyId?: number) => {
    const baseUrl = "/api/v1/payroll/sheets";
    try {
      const response = companyId
        ? await getWithAuth(`${baseUrl}/${companyId}?status=processing`)
        : await getWithAuth(`${baseUrl}?status=processing&limit=50`);

      set({
        result: response?.data.result,
        nextPage: response?.data.nextPage,
        totalPages: response?.data.totalPages,
        totalRecords: response?.data.totalRecords,
      });

      return response?.data;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getScheduledPayroll: async (companyId?: number) => {
    const baseUrl = "/api/v1/payroll/sheets";
    try {
      const response = companyId
        ? await getWithAuth(`${baseUrl}/${companyId}?type=scheduled`)
        : await getWithAuth(`${baseUrl}?type=scheduled&limit=50`);

      set({
        result: response?.data.result,
        nextPage: response?.data.nextPage,
        totalPages: response?.data.totalPages,
        totalRecords: response?.data.totalRecords,
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  },

  getPayroll: async (id: number): Promise<PayrollDto | null> => {
    const response = await getWithAuth(`/api/v1/payroll/${id}`);

    if (response) {
      const payroll = response?.data;

      const transactions = await useTransactionsStore
        .getState()
        .getTransactionsByPayrollId({ id: `${payroll.id}`, limit: 100000 });

      const transactionsError = transactions.filter(
        (transaction) => transaction.status === TransactionStatus.ERROR
      );

      const transactionsPending = transactions.filter(
        (transaction) => transaction.status === TransactionStatus.PENDING
      );

      const transactionsSucess = transactions.filter(
        (transaction) => transaction.status === TransactionStatus.SUCCESS
      );

      if (transactionsError.length > 0 && payroll) {
        payroll.hasError = true;
      }

      set({
        payroll,
        totalErrorTransactions: transactionsError.length,
        totalPendingTransactions: transactionsPending.length,
        totalSuccessTransactions: transactionsSucess.length,
      });

      return payroll;
    }

    return null;
  },

  getPayrollById: async (id: number) => {
    const response = await getWithAuth(`/api/v1/payroll/${id}`);

    if (response) {
      const payroll = response?.data;

      set({ payroll });

      return payroll;
    }

    return null;
  },

  setPayroll: (payrolls: PayrollDto[]) => {
    set({ result: payrolls });
  },

  reprocessPayroll: async (
    payrollToBeReprocessed: PayrollDto,
    reprocessOnlyErrors: boolean
  ) => {
    const transactions = await useTransactionsStore
      .getState()
      .getTransactionsByPayrollId({
        id: `${payrollToBeReprocessed.id}`,
        limit: 100000,
      });

    const serializedTransaction = transactions.map((transaction) => {
      return {
        id: transaction.id,
        document: transaction.document,
        name: transaction.name,
        amountInCents: transaction.amount,
        description: transaction.description,
        pixType: transaction.pixType ? transaction.pixType : "",
        pixKey: transaction.pixKey,
        status: transaction.status,
        transactionType: transaction.type,
        pixErrorMessage: transaction.errorMessage,
      };
    }) as unknown as ValidatedTransactionDraft[];

    let filteredTransactions = serializedTransaction;

    if (reprocessOnlyErrors) {
      filteredTransactions = serializedTransaction.filter(
        (transaction) =>
          transaction.status === TransactionStatus.ERROR ||
          transaction.status === TransactionStatus.NOT_EXECUTED
      );
    }

    useCreatePayroll.setState({
      transactionValidated: {
        duplicateDocuments: [],
        hasError: false,
        result: filteredTransactions,
      },
    });
    return filteredTransactions;
  },

  deletePayroll: async (id: number) => {
    await deleteWithAuth(`api/v1/payroll/${id}`);
  },
}));

interface UseCreatePayrollProps {
  payroll: {
    data: {
      name: string;
      id: number;
      type?: PayrollType;
      status?: PayrollStatus;
      isToValidatePixDocument?: boolean;
      isDraft?: boolean;
    } | null;
  };
  isGetting: boolean;
  transactionValidated: TransactionDraftValidationResponse | null;
  isDraftPayroll: boolean;
  isScheduledPayroll: boolean;
  isConciliated: boolean;
  isReprocessingPayroll: boolean;
  isReprocessingScheduledPayroll: boolean;
  step: number;
  createPayroll: (
    name: string,
    type: PayrollType,
    reprocessingOptions?: {
      isReprocessingScheduledPayroll: boolean;
      reprocessedPayrollId: number;
    }
  ) => Promise<PayrollDto>;

  createConciliatedPayroll: (
    name: string,
    conciliatedPeriodStart: Date,
    conciliatedPeriodEnd: Date,
    isToValidatePixDocument?: boolean
  ) => Promise<PayrollDto>;
  validatePayroll: (payload: {
    payrollId: number;
    transactionDrafts: {
      document: string;
      name: string;
      description?: string;
      pixType?: PixKeyType | "";
      pixKey?: string | undefined;
      amountInCents: number;
    }[];
  }) => Promise<TransactionDraftValidationResponse | void>;
  savePayrollDraft: (payload: {
    payrollId: number;
    transactionDrafts: {
      document: string;
      name: string;
      description?: string;
      pixType?: PixKeyType | "" | undefined;
      pixKey?: string | undefined;
      amountInCents: number;
      discountInCents?: number;
      deliverymanExternalId?: string;
      initialPaymentBalance?: number;
      discount?: number;
      advanceValue?: number;
    }[];
  }) => Promise<TransactionDraftValidationResponse | void>;
  getNotSavedPayrollById: (payrollId: string) => Promise<PayrollDto>;
  getPayrollDraftById: (id: string) => Promise<GetPayrollDraftById>;
  setScheduledFlag: (isScheduledPayroll: boolean) => void;
  setConciliatedFlag: (isConciliated: boolean) => void;
  setScheduledPayrollCreationStep: (step: number) => void;
  setReprocessingFlag: (isReprocessingPayroll: boolean) => void;
  setReprocessingScheduledFlag: (
    isReprocessingScheduledPayroll: boolean
  ) => void;
  changePayrollStatus: (isDraft: boolean) => void;
  saveConciliatedDraft: (
    payload: ICreateConciliatedTransactionDraftDto
  ) => Promise<TransactionDraftValidationResponse | void>;
  syncPixFromTransactionDrafts: (
    transactionDrafts: TransactionDraftRequestItemDto[]
  ) => Promise<ValidatedTransactionDraft[]>;
  updatePayroll: (payroll: PayrollDto) => Promise<PayrollDto>;
}

export const initialCreatePayrollState = {
  payroll: {
    data: null,
  },
  isGetting: false,
  transactionValidated: null,
  isDraftPayroll: false,
  isScheduledPayroll: false,
  isConciliated: false,
  isReprocessingPayroll: false,
  isReprocessingScheduledPayroll: false,
  step: 0,
};

export const useCreatePayroll = create<UseCreatePayrollProps>((set) => ({
  ...initialCreatePayrollState,
  createPayroll: async (
    name,
    type: PayrollType,
    reprocessingOptions?: {
      isReprocessingScheduledPayroll: boolean;
      reprocessedPayrollId: number;
    }
  ) => {
    set({ isGetting: true });

    try {
      const response = await postWithAuth("/api/v1/payroll", {
        name,
        type,
        reprocessingOptions,
      });
      set({ payroll: { data: response?.data || null }, isGetting: false });
      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        set({ isGetting: false });
        throw new Error(error.response?.data.message, error.response?.data);
      }
      set({ isGetting: false });
      console.log(error);
    }
  },
  createConciliatedPayroll: async (
    name: string,
    conciliatedPeriodStart: Date,
    conciliatedPeriodEnd: Date,
    isToValidatePixDocument?: boolean
  ) => {
    set({ isGetting: true });
    try {
      const response = await postWithAuth(
        "/api/v1/payroll/conciliated/create",
        {
          name,
          isToValidatePixDocument,
          conciliatedPeriodStart,
          conciliatedPeriodEnd,
        }
      );

      set({ payroll: { data: response?.data || null }, isGetting: false });
      set({ isGetting: false });
      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        set({ isGetting: false });
        throw new Error(error.response?.data.message, error.response?.data);
      }
      set({ isGetting: false });
    }
  },
  validatePayroll: async (payload) => {
    const response = await postWithAuth("api/v1/transaction-draft", payload);

    set({ transactionValidated: response?.data || null });

    return response?.data;
  },
  savePayrollDraft: async (payload) => {
    const response = await postWithAuth(
      "api/v1/transaction-draft/save",
      payload
    );

    set({ transactionValidated: response?.data || null });

    return response?.data;
  },
  saveConciliatedDraft: async (
    payload: ICreateConciliatedTransactionDraftDto
  ) => {
    const response = await postWithAuth(
      "api/v1/transaction-draft/conciliated/create",
      payload
    );

    set({ transactionValidated: response?.data || null });
    return response?.data;
  },
  syncPixFromTransactionDrafts: async (
    transactionDrafts: TransactionDraftRequestItemDto[]
  ) => {
    const { syncPixFromTransactionDrafts } = ApiTransactionDraftService();

    const response = await syncPixFromTransactionDrafts(transactionDrafts);

    set({
      transactionValidated: {
        result: response as ValidatedTransactionDraft[],
        hasError: false,
        duplicateDocuments: [],
      },
    });

    return response;
  },
  getPayrollDraftById: async (id) => {
    try {
      const response = await getWithAuth(
        `api/v1/transaction-draft/payroll/${id}`
      );

      const payroll = response?.data as GetPayrollDraftById;

      set({
        transactionValidated: {
          result: payroll.transactionDrafts || null,
          duplicateDocuments: [],
          hasError: false,
        },
        payroll: {
          data: id
            ? {
                ...payroll,
                id: Number(id),
                name: payroll.name,
                isToValidatePixDocument: payroll.isToValidatePixDocument,
                status: payroll.status,
                type: payroll.type,
              }
            : null,
        },
      });
      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message);
      }

      console.error(error);
    }
  },
  getNotSavedPayrollById: async (payrollId: string) => {
    const result = await getWithAuth(`/api/v1/payroll/${payrollId}`);

    const payroll = result?.data as PayrollDto;

    if (payroll && payroll.status !== "not saved") {
      throw new Error("folha não é do tipo não salva!");
    }

    set({
      payroll: { data: payroll },
      transactionValidated: {
        hasError: false,
        duplicateDocuments: [],
        result: [],
      },
    });

    return payroll;
  },

  setScheduledFlag: (isScheduledPayroll: boolean) => {
    set({ isScheduledPayroll });
  },
  setConciliatedFlag: (isConciliated: boolean) => {
    set({ isConciliated });
  },
  setReprocessingFlag: (isReprocessingPayroll: boolean) => {
    set({ isReprocessingPayroll });
  },
  setReprocessingScheduledFlag: (isReprocessingScheduledPayroll: boolean) => {
    set({ isReprocessingPayroll: isReprocessingScheduledPayroll });
    set({ isReprocessingScheduledPayroll });
  },
  setScheduledPayrollCreationStep: (step: number) => {
    set({ step });
  },
  changePayrollStatus: (isDraft: boolean) => {
    set({ isDraftPayroll: isDraft });
  },
  updatePayroll: async (payroll: PayrollDto): Promise<PayrollDto> => {
    const result = await patchWithAuth(`/api/v1/payroll`, payroll);
    const data = result?.data as PayrollDto;
    set({ payroll: { data } });
    return data;
  },
}));
