import { Flex } from "@chakra-ui/layout";
import { Button, Icon, Tooltip, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { postWithAuth } from "../../../../../services/basicService";

export function SendMessageButton() {
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom",
  });

  const sendMessageMutatation = useMutation(
    () => postWithAuth(`/api/v1/deliveryMan/message/unscheduled`, undefined),
    {
      onSuccess: () => {
        toast({
          status: "success",
          title: "Mensagem enviada com sucesso",
          description: `O envio da mensagem foi realizada com sucesso!`,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) =>
        toast({
          status: "error",
          title: "Erro ao enviar a mensagem",
          description: `Erro: ${err?.response?.data.message}`,
        }),
    }
  );

  const handleSendMessage = () => {
    if (sendMessageMutatation.isLoading) {
      return;
    }

    sendMessageMutatation.mutate();
  };

  return (
    <Flex w="full" justifyContent="end" alignItems="center" gap={5}>
      <Button
        fontSize={17}
        w={200}
        whiteSpace="normal"
        textAlign="center"
        h="fit-content"
        p={3}
        onClick={handleSendMessage}
        isDisabled={sendMessageMutatation.isLoading}
        isLoading={sendMessageMutatation.isLoading}
      >
        Enviar mensagem aos não agendados
      </Button>
      <Tooltip
        hasArrow
        placement="top"
        label="Será enviado uma mensagem aos entregadores que possui pelo menos um agendado disponivel"
        fontSize="md"
      >
        <Icon fontSize={20} color="black" />
      </Tooltip>
    </Flex>
  );
}
