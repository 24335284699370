import { Dispatch, MutableRefObject, SetStateAction, useState } from "react";
import { useToast } from "@chakra-ui/react";
import {
  UploadResponseErrors,
  UploadResponseResults,
} from "../../../types/upload.type";
import { postWithAuth } from "../../../services/basicService";
import { AxiosError } from "axios";

interface IDeliveryman extends UploadResponseResults {
  indexResult: number;
}

export type IProps = {
  setResults: Dispatch<SetStateAction<UploadResponseResults[]>>;
  setErrors: Dispatch<SetStateAction<UploadResponseErrors | null>>;
  setPage: Dispatch<SetStateAction<number>>;
  setDeliverymenWithInvalidCPF: Dispatch<SetStateAction<IDeliveryman[]>>;
};

export function useSaveBalances({
  setResults,
  setErrors,
  setPage,
  setDeliverymenWithInvalidCPF,
}: IProps) {
  const [loadingSaveBalance, setLoadingSaveBalance] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const toast = useToast();

  const handleSaveData = async (
    results: UploadResponseResults[],
    {
      uuid,
      reprocessUuid,
    }: {
      uuid: MutableRefObject<string>;
      reprocessUuid: MutableRefObject<string | null>;
    }
  ) => {
    if (loadingSaveBalance) return;
    setLoadingSaveBalance(true);

    const data = {
      idSheetsSent: reprocessUuid.current ?? uuid.current,
      balances: results.map((result) => ({
        name: result.name,
        document: result.document,
        balance: Math.round(result.editedAmount * 100),
        praca: result.praca,
        tipo: result.type,
        due_date: result.data_do_repasse,
        datas_referencias: result.data_do_periodo_de_referencia,
        idEntregador: result.id_da_pessoa_entregadora,
        isBalanceDeleted: result.isBalanceDeleted,
      })),
    };

    postWithAuth("/api/v1/userAdvanceBalance", data)
      .then(() => {
        toast({
          status: "success",
          title: "Saldos salvos com sucesso",
        });

        setResults([]);
        setErrors(null);
        setPage(1);
      })
      .catch((err) => {
        const error = err as AxiosError;

        if (error) {
          if (
            error.response?.status === 422 ||
            error.response?.status === 400
          ) {
            const data = error.response.data as {
              statusCode: number;
              message: string[];
              error: string;
            };

            const foundCpfError = [];
            for (const message of data.message) {
              if (message.includes("CPF")) {
                const find = message.match(/\d+/);

                if (find) {
                  const indexResult = Number(
                    find[0].match(/\d+/)?.[0]
                  ) as number;
                  foundCpfError.push({ indexResult, ...results[indexResult] });
                }
              }
            }

            if (foundCpfError.length > 0) {
              setDeliverymenWithInvalidCPF(foundCpfError);
              setOpenModal(true);
            }
            toast({
              status: "error",
              title: "Não foi possível salvar os saldos",
              description: `${data.message.join(";  ")}`,
            });
            return;
          }

          if (
            err instanceof AxiosError &&
            err.response?.status === 403 &&
            err.response.data.message !== undefined
          ) {
            toast({
              status: "error",
              title: "Não foi possível enviar os saldos",
              description: err.response.data.message,
              duration: 5000,
            });
            return;
          }

          toast({
            status: "error",
            title: "Não foi possível salvar os saldos",
            description: `Erro ao salvar os saldos: ${error.message}`,
          });
          return;
        }
        toast({
          status: "error",
          title: "Não foi possível salvar os saldos",
          description: `Erro ao salvar os saldos`,
        });
      })
      .finally(() => setLoadingSaveBalance(false));
  };
  return {
    handleSaveData,
    openModal,
    setOpenModal,
    loadingSaveBalance,
  };
}
