import { z } from "zod";
import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { useShiftCachedService } from "../../../../../services/api/shifts";
import { Shift } from "../../../../../dto/shift-dto";
import { MutateFunction } from "../../../../../services/api/use-cached-service";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";

const formFields: FormField[] = [
  {
    label: "Nome",
    name: "name",
    type: "text",
  },
  {
    label: "Horário inicial",
    name: "startHour",
    type: "time",
  },
  {
    label: "Horário final",
    name: "endHour",
    type: "time",
  },
];

const formSchema = z.object({
  id: z.number(),
  name: z.string(),
  startHour: z.string(),
  endHour: z.string(),
});

function buildHandleSubmit(updateShift: MutateFunction) {
  return (data: Record<string, unknown>) => {
    updateShift({ id: data.id, data });
  };
}

const formatHour = (time: string) => {
  const regex = /^(\d{2}:\d{2})/;
  const correspondencia = time.match(regex);
  return correspondencia ? correspondencia[1] : "";
};

interface FormProps {
  shift: Shift;
  onUpdate: () => void;
}

export const ShiftFormEdit: React.FC<FormProps> = ({ shift, onUpdate }) => {
  const { useMutationAction: useShiftMutationAction } = useShiftCachedService();
  const [updateShift, watchers] = useShiftMutationAction("update");

  shift.startHour = formatHour(shift.startHour);
  shift.endHour = formatHour(shift.endHour);

  const toast = useToast();

  useEffect(() => {
    const { status, error } = watchers;
    if (status) {
      if (status === "success") {
        onUpdate();
      }
      if (status === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao criar turno",
          description: `Falha ao atualizar turno: ${error}`,
        });
      }
    }
  }, [onUpdate, toast, watchers, watchers.status]);

  const handleSubmit = buildHandleSubmit(updateShift);

  return (
    <GenericForm
      fields={formFields}
      formSchema={formSchema}
      initialData={shift}
      actionLabel="Salvar"
      onSubmit={handleSubmit as (data: unknown) => void}
    />
  );
};
