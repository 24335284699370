import { create } from "zustand";
import { UserDto } from "../dto/user-dto";
import {
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../services/basicService";

interface createUser {
  email: string | null;

  firstName: string | null;

  lastName: string | null;

  roles: {
    id: number;
  }[];

  status: {
    id: number;
  };

  company: {
    id: number;
  };
}

interface useUsersDashboardStore {
  users: { users: UserDto[]; pages: number };
  user: UserDto | null;
  listAllUsers: (
    page: number,
    search?: string
  ) => Promise<{ users: UserDto[]; pages: number }>;
  createUser: (data: createUser) => Promise<void>;
  editUser: (id: string, data: Partial<createUser>) => Promise<void>;
  getUser: (id: string) => Promise<UserDto | null>;
}

export const useUsersDashboardStore = create<useUsersDashboardStore>((set) => ({
  users: { users: [], pages: 1 },
  user: null,

  getUser: async (id: string) => {
    try {
      const response = await getWithAuth(`/api/v1/users/${id}`);

      if (response) {
        set({ user: response.data });

        return response.data;
      }

      return null;
    } catch (error) {
      console.error(error);
    }
  },

  listAllUsers: async (page: number, search?: string) => {
    try {
      const response = await getWithAuth(
        `/api/v1/users?page=${page}&limit=10&${search && `search=${search}`}`
      );

      if (response) {
        set({
          users: {
            users: response.data.users,
            pages: response.data.pages,
          },
        });

        return response.data;
      }

      return { users: [], pages: 1 };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createUser: async (data) => {
    await postWithAuth("/api/v1/users", data);
  },

  editUser: async (id, data) => {
    await patchWithAuth(`/api/v1/users/${id}`, data);
  },
}));
