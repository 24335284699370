import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Heading,
  List,
  ListItem,
  VStack,
} from "@chakra-ui/layout";
import {
  UseStepsReturn,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  PinInput,
  PinInputField,
  useToast,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useCreatePayroll } from "../../../../store/payroll.store";
import { convertCentsToReais } from "../../../../utils/convert-money";
import {
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../../../../services/basicService";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ptBR from "dayjs/locale/pt-br";
import { Controller, useForm } from "react-hook-form";
import { GetPayrollDraftById } from "../../../../types/payroll.type";
import { ValidatedTransactionDraft } from "../../../../types/transaction.type";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

dayjs.extend(relativeTime);

type Props = UseStepsReturn;

export function SummaryTransactionsConfirmation(props: Props) {
  const [deathLine, setDeathLine] = useState<Date>();
  const [timer, setTimer] = useState<string>();

  const payroll = useCreatePayroll((state) => state.payroll.data);
  const id = payroll?.id;

  const isConciliated = payroll?.type === "conciliated";

  const { data: transactionValidated } = useQuery({
    queryKey: ["validated-transaction-draft"],
    queryFn: async (): Promise<ValidatedTransactionDraft[]> => {
      if (!id) return [];

      const response = await getWithAuth(
        `api/v1/transaction-draft/payroll/${id}`
      );
      const payrollResponse = response?.data as GetPayrollDraftById;
      return payrollResponse.transactionDrafts;
    },
  });

  const duplicateDocuments = useCreatePayroll(
    (state) => state.transactionValidated?.duplicateDocuments
  );

  const toast = useToast({
    duration: 5000,
    isClosable: true,
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<{ pin: string }>();

  const {
    isOpen: modalPinOpened,
    onClose: closeModalPin,
    onOpen: openModalPin,
  } = useDisclosure({
    id: "confirm-transactions-modal",
  });

  async function handleConfirmTransactions() {
    openModalPin();

    setDeathLine(dayjs().add(12, "minutes").toDate());

    try {
      await postWithAuth("/api/v1/mail/transaction-confirmation", {});

      toast({
        status: "success",
        title: "Token de confirmação enviado para seu e-mail cadastrado",
      });
    } catch (error) {
      console.error(error);

      toast({
        status: "error",
        title: "Não foi possível enviar token de confirmação!",
      });
    }
  }

  async function handleConfirmationToken(data: { pin: string }) {
    try {
      await patchWithAuth("/api/v1/payroll/confirm", {
        id,
        confirmationCode: Number(data.pin),
      });
      toast({
        status: "success",
        title: "Token confirmado, transações em processamento!",
      });

      closeModalPin();

      props.goToNext();
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status !== 403) {
        return toast({
          status: "error",
          title: "Não foi possível confirmar o envio!",
          description: `Erro: ${error.response?.data.message}`,
          duration: 5000,
        });
      }

      setError("pin", {
        type: "Token Inválido",
        message: "Este token não é válido",
      });
      toast({
        status: "error",
        title: "Token inválido!",
      });
    }
  }

  const amount = transactionValidated?.reduce((acc, item) => {
    if (item.amountInCents === undefined || item.amountInCents === null) {
      return acc;
    }

    return (acc += item.amountInCents / 100);
  }, 0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(dayjs(deathLine).locale(ptBR).fromNow());
    }, 1000);

    return () => clearInterval(interval);
  }, [deathLine]);

  return (
    <VStack w="full" h="max-content" gap={8} pt={8}>
      <Modal
        isOpen={modalPinOpened}
        onClose={closeModalPin}
        id="confirm-transactions-modal"
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Entre com o PIN enviado ao seu e-mail</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={8}
          >
            <VStack
              gap={8}
              flexDir="column"
              as="form"
              onSubmit={handleSubmit(handleConfirmationToken)}
              id="confirmation-token-form"
            >
              <HStack>
                <Controller
                  control={control}
                  name="pin"
                  render={({ field }) => (
                    <PinInput
                      type="number"
                      size="lg"
                      colorScheme="red"
                      onChange={field.onChange}
                      value={field.value}
                      isInvalid={!!errors.pin}
                    >
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                    </PinInput>
                  )}
                />
              </HStack>

              <Heading size="md">Expira {timer}</Heading>
            </VStack>
          </ModalBody>

          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={closeModalPin}>
              Fechar
            </Button>
            <Button
              color="white"
              type="submit"
              form="confirmation-token-form"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              loadingText="Checando"
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex
        gap={[4, 8]}
        justifyContent="space-between"
        overflow="auto"
        h="max-content"
      >
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          minW={["0", "180px"]}
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>Valor total</StatLabel>
            <StatNumber fontSize={["xl", "3xl"]} color="green.500">
              {amount
                ? convertCentsToReais(amount * 100)
                : convertCentsToReais(0)}
            </StatNumber>
          </Stat>
        </Box>
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>Quantidade de saldos</StatLabel>
            <StatNumber fontSize={["xl", "3xl"]} color="green.500">
              {transactionValidated?.length}
            </StatNumber>
          </Stat>
        </Box>

        <Flex gap={4} flexDir={["column", "row"]}>
          {duplicateDocuments?.map((error) => (
            <Box
              bg="red.200"
              maxW={["80px", "150px"]}
              p={[4]}
              borderRadius={8}
              shadow="base"
              key={error}
            >
              <Heading size="md">Documentos duplicados</Heading>
              <List>
                <ListItem>{error}</ListItem>
              </List>
            </Box>
          ))}
        </Flex>
      </Flex>

      <TableContainer
        w="full"
        overflow="auto"
        style={{ containerType: "inline-size" }}
      >
        <Table variant="striped" colorScheme="green">
          <Thead>
            <Tr>
              <Th textAlign="center">Nome</Th>
              <Th textAlign="center">Descrição</Th>
              <Th textAlign="center">CPF/CNPJ</Th>
              <Th textAlign="center">Chave Pix</Th>
              <Th textAlign="center">Tipo da Chave Pix</Th>
              <Th textAlign="center">Tipo de transação</Th>
              <Th textAlign="center">Valor</Th>
              {isConciliated && <Th textAlign="center">Desconto</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {transactionValidated?.map((item) => (
              <Tr key={item.id}>
                <Td textTransform="capitalize" textAlign="center">
                  {item.name}
                </Td>
                <Td textAlign="center">{item.description}</Td>
                <Td textAlign="center">{item.document}</Td>
                <Td textAlign="center">{item.pixKey}</Td>
                <Td textAlign="center">{item.pixType}</Td>
                <Td textAlign="center">{item.transactionType}</Td>
                <Td textAlign="center">
                  {convertCentsToReais(item.amountInCents)}
                </Td>
                {isConciliated && (
                  <Td textAlign="center">
                    {item.discount ? convertCentsToReais(item?.discount) : 0}
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex justifyContent="flex-end" w="full" gap={4}>
        <Button size="lg" w={["full", "auto"]} onClick={props.goToPrevious}>
          Voltar
        </Button>

        <Button
          size="lg"
          w={["full", "auto"]}
          color="white"
          onClick={handleConfirmTransactions}
        >
          Confirmar transações
        </Button>
      </Flex>
    </VStack>
  );
}
