import { Flex, HStack, Heading } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDeliverymansStore } from "../../../store/deliveryman.store";
import { createDeliveryMan } from "../../../dto/deliveryman-dto";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { TestCPF } from "../../../utils/cpf-validator";

const createDeliverymanFormSchema = z.object({
  idEntregador: z.string().optional(),
  nameIfood: z.string().nonempty("O nome do entregador no ifood é obrigatório"),
  nameTrampay: z
    .string()
    .nonempty("O nome do entregador na Trampay é obrigatório"),
  document: z
    .string()
    .length(11, "O CPF deve conter 11 dígitos")
    .refine((value) => TestCPF(value), { message: "CPF inválido." }),
});

type createDeliverymanFormInputs = z.infer<typeof createDeliverymanFormSchema>;

export function CreateDeliveryman() {
  const createDeliveryman = useDeliverymansStore(
    (state) => state.createDeliveryMan
  );

  const toast = useToast();
  const navigate = useNavigate();

  const client = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<createDeliverymanFormInputs>({
    resolver: zodResolver(createDeliverymanFormSchema),
  });

  async function onSubmit(data: createDeliverymanFormInputs) {
    const newDeliveryman: createDeliveryMan = data;

    try {
      await createDeliveryman(newDeliveryman);
      client.refetchQueries(["delivery-mans-data"]);
      navigate("/deliverymans");
    } catch (err) {
      const error = err as AxiosError;
      const errorData = error.response?.data as { message: string[] };

      let description =
        "Não foi possível criar o entregador, contate o suporte";
      if (error.response?.status === 422 || error.response?.status === 404) {
        description = errorData.message.join(", ");
      }

      if (error.response?.status === 409 || error.response?.status === 400) {
        const err = error.response?.data as { message: string };

        description = err.message;
      }

      toast({
        status: "error",
        title: "Não foi possível criar o entregador",
        description,
        duration: 4000,
      });
    }
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading>Criar Entregador</Heading>
      </HStack>
      <Heading size="lg">Dados do entregador</Heading>

      <Flex
        as="form"
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        p={8}
        borderRadius={8}
        shadow="base"
        bg="whiteAlpha.800"
        id="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.idEntregador}>
            <FormLabel>ID do Entregador</FormLabel>
            <Input {...register("idEntregador")} />
            {errors.idEntregador ? (
              <FormErrorMessage>{errors.idEntregador.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.nameIfood} isRequired>
            <FormLabel>Nome Cadastro Sistema</FormLabel>
            <Input {...register("nameIfood")} />
            {errors.nameIfood ? (
              <FormErrorMessage>{errors.nameIfood.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>
        <Flex gap={8}>
          <FormControl
            as="fieldset"
            isInvalid={!!errors.nameTrampay}
            isRequired
          >
            <FormLabel>Nome Trampay</FormLabel>
            <Input {...register("nameTrampay")} />
            {errors.nameTrampay ? (
              <FormErrorMessage>{errors.nameTrampay.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.document} isRequired>
            <FormLabel>CPF</FormLabel>
            <Input {...register("document")} isRequired />
            {errors.document ? (
              <FormErrorMessage>{errors.document.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        </Flex>

        <Button
          type="submit"
          form="form"
          size="lg"
          alignSelf="flex-end"
          isLoading={isSubmitting}
        >
          Criar Entregador
        </Button>
      </Flex>
    </Flex>
  );
}
