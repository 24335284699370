import { z } from "zod";
import { useForm } from "react-hook-form";
import { VStack, Heading, Grid, GridItem } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";

import { PasswordInput } from "../../components/forms/password-button";
import { useCurrentUserStore } from "../../store/current-user.store";
import { zodResolver } from "@hookform/resolvers/zod";

const profileUpdateFormSchema = z
  .object({
    firstName: z
      .string()
      .min(1, "O nome precisar ter pelo menos um caractere")
      .optional(),
    lastName: z
      .string()
      .min(1, "O sobrenome precisar ter pelo menos um caractere")
      .optional(),
    oldPassword: z.string().optional(),
    password: z
      .string()
      .optional()
      .refine((val) => !val || val.length >= 6, {
        message: "A senha deve ter pelo menos 6 caracteres",
      }),
    confirmPassword: z.string().optional(),
  })
  .refine((values) => values.password === values.confirmPassword, {
    message: "A senha nova e a confirmação não são iguais",
    path: ["confirmPassword"],
  });

type profileUpdateFormInputs = z.infer<typeof profileUpdateFormSchema>;

export function Profile() {
  const profile = useCurrentUserStore((state) => state.user);
  const updateProfileInfo = useCurrentUserStore((state) => state.updateProfile);

  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<profileUpdateFormInputs>({
    defaultValues: {
      firstName: profile?.firstName || undefined,
      lastName: profile?.lastName || undefined,
    },
    resolver: zodResolver(profileUpdateFormSchema),
  });

  async function onSubmit(data: profileUpdateFormInputs) {
    try {
      await updateProfileInfo(data);
      reset();
      toast({
        status: "success",
        title: "Dados do usuário atualizados com sucesso",
      });
    } catch (error) {
      toast({
        status: "error",
        title: "Dados do usuário não foram atualizados!",
      });
    }
  }

  return (
    <VStack gap={8} flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Perfil do usuário
      </Heading>

      <Grid
        as="form"
        w="full"
        gap={6}
        p={8}
        borderRadius={8}
        shadow="base"
        bg="whiteAlpha.800"
        id="form"
        onSubmit={handleSubmit(onSubmit)}
        templateColumns="1fr 1fr"
      >
        <FormControl as={GridItem} isInvalid={!!errors.firstName}>
          <FormLabel>Nome</FormLabel>
          <Input {...register("firstName")} />
          {errors?.firstName && errors.firstName.message ? (
            <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl isInvalid={!!errors.lastName}>
          <FormLabel>Sobrenome</FormLabel>
          <Input {...register("lastName")} />
          {errors?.lastName && errors.lastName.message ? (
            <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl as={GridItem} isInvalid={!!errors.oldPassword}>
          <FormLabel>Senha antiga</FormLabel>
          <PasswordInput {...register("oldPassword")} />
          {errors?.oldPassword && errors.oldPassword.message ? (
            <FormErrorMessage>{errors.oldPassword.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl as={GridItem} isInvalid={!!errors.password}>
          <FormLabel>Senha nova</FormLabel>
          <PasswordInput {...register("password")} />
          {errors?.password && errors.password.message ? (
            <FormErrorMessage>{errors.password.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl as={GridItem} isInvalid={!!errors.confirmPassword}>
          <FormLabel>Confirmar senha nova</FormLabel>
          <PasswordInput {...register("confirmPassword")} />
          {errors?.confirmPassword && errors.confirmPassword.message ? (
            <FormErrorMessage>
              {errors.confirmPassword.message}
            </FormErrorMessage>
          ) : null}
        </FormControl>
        <GridItem colSpan={2} display="flex" justifyContent="flex-end">
          <Button type="submit" size="lg" isLoading={isSubmitting}>
            Atualizar Dados
          </Button>
        </GridItem>
      </Grid>
    </VStack>
  );
}
