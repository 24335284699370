import { useEffect, useState } from "react";
import { VStack } from "@chakra-ui/layout";
import { useLocation } from "react-router-dom";
import { isValidMotionProp, motion, useAnimate } from "framer-motion";
import {
  Button,
  Image,
  chakra,
  shouldForwardProp,
  useToken,
} from "@chakra-ui/react";
import {
  UserStore,
  useCurrentUserStore,
} from "../../../store/current-user.store";
import { authenticatedSupportRoutes } from "../../../routes/authenticated-support-routes";
import LogoImage from "../../../assets/logo/trampay-rings.svg";
import { SideBarButton } from "../side-bar-button";
import { useQuery } from "@tanstack/react-query";

import { House, SignOut } from "@phosphor-icons/react";

const ChakraBox = chakra(motion.aside, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export function SideBar() {
  const [isHovered, setIsHovered] = useState(false);
  const [scope, animate] = useAnimate();
  const { pathname } = useLocation();

  const [green500] = useToken("colors", ["green.500", "whiteAlpha.800"]);

  const getPermissions = useCurrentUserStore((state) => state.getPermissions);

  const signOut = useCurrentUserStore((state: UserStore) => state.signOut);

  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(
    ["permissions"],
    getPermissions,
    { refetchOnMount: "always" }
  );

  const availableRoutesForThisUser = authenticatedSupportRoutes.flatMap(
    (route) => {
      const hasPermission = permissions?.find(
        (permission) => permission.key === route.key
      );

      if (hasPermission) {
        if (route.type === "external" && hasPermission.url) {
          route.path = hasPermission.url;
        }
        return [route];
      }

      return [];
    }
  );

  // animations
  useEffect(() => {
    if (isHovered) {
      (async () => {
        await animate(
          scope.current,
          {
            width: "300px",
            minWidth: "300px",
          },
          {
            onComplete: () => {
              animate("p", {
                opacity: 1,
                display: "block",
              });
            },
          }
        );
      })();
    } else {
      (async () => {
        await animate(
          "p",
          {
            display: "none",
            opacity: 0,
          },
          { duration: 0.01 }
        );
        await animate(scope.current, {
          width: "80px",
          minWidth: "80px",
        });
        await animate(
          "p",
          {
            display: "none",
            opacity: 0,
          },
          { duration: 0.01 }
        );
      })();
    }
  }, [isHovered, animate, scope, permissions]);

  return (
    <ChakraBox
      bg="blackAlpha.900"
      display="flex"
      flexDir="column"
      gap={2}
      p={4}
      alignItems="center"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      ref={scope}
      position="sticky"
      top={0}
      h="100vh"
      minW="80px"
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "16px",
          // background: ,
          padding: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "24px",
          background: green500,
          width: "16px",
        },
      }}
    >
      <Image
        src={LogoImage}
        alt="Trampay logo com titulo"
        w="full"
        maxW="80px"
        pb={4}
      />

      <VStack
        alignItems={isHovered ? "flex-start" : "center"}
        w="full"
        color="gray.50"
        pt={4}
      >
        <SideBarButton
          isHovered={isHovered}
          isSelected={pathname === "/"}
          to="/"
          h={12}
        >
          <House weight="bold" size={20} />
          <p style={{ opacity: 0 }}>Inicio</p>
        </SideBarButton>

        {!isLoadingPermissions
          ? availableRoutesForThisUser
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((route, index) => (
                <SideBarButton
                  isHovered={isHovered}
                  isSelected={
                    route?.path
                      ? pathname.split("/").includes(route?.path)
                      : false
                  }
                  key={route?.key || index}
                  to={route?.path || "#"}
                  target={route?.type === "external" ? "_blank" : "_self"}
                  h={12}
                >
                  {route?.icon}
                  <p style={{ opacity: 0 }}>{route?.name}</p>
                </SideBarButton>
              ))
          : null}

        <Button
          bg="transparent"
          w={isHovered ? "full" : "min-content"}
          p={2}
          gap={4}
          justifyContent={isHovered ? "flex-start" : "center"}
          size="lg"
          _hover={{
            bg: "whiteAlpha.600",
          }}
          onClick={signOut}
          h={12}
        >
          <SignOut weight="bold" size={20} />
          <p style={{ opacity: 0 }}>Logout</p>
        </Button>
      </VStack>
    </ChakraBox>
  );
}
