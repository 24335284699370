import {
  Badge,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { UserBalanceDto } from "../../../dto/user-balance-dto";
import { formatCPF } from "../../../utils/format-document";
import { convertCentsToReais } from "../../../utils/convert-money";

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  balance: UserBalanceDto;
};

export function DrawerBalanceDetails({ isOpen, onClose, balance }: IProps) {
  const { colorMode } = useColorMode();

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent gap={10} w="7xl" maxW="7xl" bg="gray.100">
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex h="full" justify="space-around">
            Detalhes do saldo
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Flex flexDir="column" gap={7}>
            <Flex
              flexDir={{ base: "column", lg: "row" }}
              w="full"
              border="1px"
              bg={colorMode === "dark" ? "gray.700" : "white"}
              borderColor={colorMode === "dark" ? "" : "gray.300"}
              p={4}
              borderRadius={8}
              shadow="xl"
            >
              <VStack
                w="full"
                gap={4}
                justifyContent="center"
                borderRight="1px"
                borderColor={colorMode === "dark" ? "" : "gray.300"}
              >
                <Heading as="h3" fontSize="xl">
                  Informações do profissional de entrega
                </Heading>
                <Text fontSize="xl">{`Nome: ${balance.name}`}</Text>
                <Text fontSize="xl">{`CPF: ${formatCPF(
                  balance.document
                )}`}</Text>
                <Text fontSize="xl">{`ID Entregador: ${balance.idEntregador}`}</Text>
                <Text fontSize="xl">{`Empresa: ${
                  balance.company ? balance.company : "-"
                }`}</Text>
              </VStack>
              <VStack w="full" gap={4}>
                <Heading as="h3" fontSize="xl">
                  Informações básicas do saldo
                </Heading>
                <Text fontSize="xl">
                  Valor: {convertCentsToReais(balance.balance)}
                </Text>
                <Text fontSize="xl">
                  Taxa fixa diária:{" "}
                  {convertCentsToReais(Number(balance.amountFeeFixed))}
                </Text>
                <Text fontSize="xl">
                  Taxa percentual diária:{" "}
                  {`${balance.amountFeePercentage ?? 0}%`}
                </Text>
                <Text fontSize="xl">
                  Data de referência:{" "}
                  {dayjs(balance.balanceDate).format("DD/MM/YYYY")}
                </Text>
                <Text fontSize="xl">
                  Data de criação:{" "}
                  {dayjs(balance.createdAt).format("DD/MM/YYYY HH:mm")}
                </Text>
              </VStack>
            </Flex>

            <Flex
              flexDir={{ base: "column", lg: "row" }}
              w="full"
              border="1px"
              bg={colorMode === "dark" ? "gray.700" : "white"}
              borderColor={colorMode === "dark" ? "" : "gray.300"}
              p={4}
              borderRadius={8}
              shadow="xl"
            >
              <VStack w="full" gap={4} justifyContent="center">
                <Heading as="h3" fontSize="xl">
                  Status:{" "}
                  {balance.deletedAt ? (
                    <Badge
                      colorScheme="red"
                      fontSize="md"
                      p={2}
                      borderRadius={7}
                    >
                      Deletado
                    </Badge>
                  ) : balance.wasUsed ? (
                    <Badge
                      colorScheme="green"
                      fontSize="md"
                      p={2}
                      borderRadius={7}
                    >
                      Utilizado
                    </Badge>
                  ) : (
                    <Badge
                      colorScheme="purple"
                      fontSize="md"
                      p={2}
                      borderRadius={7}
                    >
                      Não utilizado
                    </Badge>
                  )}
                </Heading>
                <Text fontSize="xl">{`Foi usado em um empréstimo?: ${
                  balance.wasUsed ? "Sim" : "Não"
                }`}</Text>
                {balance.deletedAt && (
                  <>
                    <Text fontSize="xl">
                      Data em que foi deletado:{" "}
                      {dayjs(balance.deletedAt).format("DD/MM/YYYY HH:mm")}
                    </Text>
                    <Text fontSize="xl">Motivo: {balance.whyWasDeleted}</Text>
                  </>
                )}
              </VStack>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
