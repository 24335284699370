import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { SHEETS_INFO } from "../../../types/sheet-info.type";
import { Download } from "@phosphor-icons/react";

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  sheet: {
    spreadsheets: SHEETS_INFO[];
    company: string;
    uploadFileCreatedAt: Date;
  };
};

export function DrawerDownloadSheets({ isOpen, onClose, sheet }: IProps) {
  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent gap={10} w="7xl" maxW="7xl">
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex h="full" justify="space-around">
            <p>{sheet.company}</p>
            <p>{dayjs(sheet.uploadFileCreatedAt).format("DD-MM-YYYY")}</p>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <TableContainer
            w="full"
            gap={4}
            display="flex"
            flexDir="column"
            style={{
              containerType: "inline-size",
            }}
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={2}
            shadow="base"
          >
            <Table>
              <Thead>
                <Tr>
                  <Th textAlign="center">Nome</Th>
                  <Th textAlign="center">Tipo</Th>
                  <Th textAlign="center">Data de criação</Th>
                  <Th textAlign="center">Download</Th>
                </Tr>
              </Thead>
              <Tbody>
                {sheet.spreadsheets.map((sheet) => (
                  <Tr key={sheet.id}>
                    <Td textAlign="center">{sheet.fileName}</Td>
                    <Td textAlign="center">{sheet.type}</Td>
                    <Td textAlign="center">
                      {dayjs(sheet.createdAt).format("DD-MM-YYYY")}
                    </Td>
                    <Td textAlign="center">
                      <Button
                        onClick={() => {
                          const urlValue = sheet.fileUrl;

                          window.open(urlValue, "_blank");
                        }}
                      >
                        <Download weight="bold" size={24} />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
