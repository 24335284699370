/* eslint-disable react-refresh/only-export-components */
import { createContext, useState } from "react";
import { useUserPaymentDebtService } from "../../../../services/api/user-payment-debt";
import {
  ISearchUserDebtDTO,
  IUserDebtDTO,
} from "../../../../dto/user-debt-dto";

import { z } from "zod";
import { UseFormRegister, useForm } from "react-hook-form";
import { UserPaymentDebtStatus } from "../../../../enums/user-payment-debt.enum";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";

export const searchUserPaymentDebtSchema = z.object({
  deliverymanName: z.string().optional(),
  deliverymanCpf: z.string().optional(),
  launchDate: z.date().optional(),
  nextTransferDate: z.date().optional(),
  status: z.nativeEnum(UserPaymentDebtStatus).optional(),
});

export type ISearchUserPaymentDebt = z.infer<
  typeof searchUserPaymentDebtSchema
>;

interface IFilter extends ISearchUserDebtDTO {
  page: string;
  limit: string;
}

export interface IUserPaymentDebtContextProps {
  data: IUserDebtDTO[];
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  handleFetchData: () => void;
  handleSetPage: (page: number) => void;

  register: UseFormRegister<ISearchUserPaymentDebt>;
  currentPage: number;
  totalPages: number;
  hasNextPage: boolean;
  openModal: boolean;
  handleChangeModalStatus: () => void;
  modalIfon: IUserDebtDTO | undefined;
  setModalInfo: React.Dispatch<React.SetStateAction<IUserDebtDTO | undefined>>;
  isLoading: boolean;
  totalValue: number;
}

export const UserPaymentDebtContext =
  createContext<IUserPaymentDebtContextProps>(
    {} as IUserPaymentDebtContextProps
  );

type UserPaymentDebtContextProviderProps = {
  children: React.ReactNode;
};

export function UserPaymentDebtProvider({
  children,
}: UserPaymentDebtContextProviderProps) {
  const { paginateFilter, getTotalDebts } = useUserPaymentDebtService();

  const { register, getValues } = useForm<ISearchUserPaymentDebt>({
    resolver: zodResolver(searchUserPaymentDebtSchema),
  });

  const [filter, setFilter] = useState<IFilter>({
    page: "1",
    limit: "10",
    ...getValues(),
  });

  const [openModal, setOpenModal] = useState(false);
  const [modalIfon, setModalInfo] = useState<IUserDebtDTO | undefined>();
  const [totalValue, setTotalValue] = useState(0);

  const handleChangeModalStatus = () => {
    setOpenModal(!openModal);
  };

  const fetchPaginateFilter = async (filter: IFilter) => {
    const response = await paginateFilter(filter);
    const totalDebts = await getTotalDebts();
    if (totalDebts) {
      setTotalValue(totalDebts || 0);
    }
    if (response) {
      return {
        currentPage: response.page,
        totalPages: response.totalPages,
        hasNextPage: response.nextPage,
        data: response.data,
      };
    }
    throw new Error("Failed to fetch data");
  };

  const { data, isLoading } = useQuery(
    ["paginateFilter", filter],
    () => fetchPaginateFilter(filter),
    {
      keepPreviousData: true,
    }
  );

  const handleFetchData = () => {
    const filtersFields = getValues();
    const fields: ISearchUserDebtDTO = {};
    if (filtersFields.deliverymanName) {
      fields.deliverymanName = filtersFields.deliverymanName;
    }

    if (filtersFields.deliverymanCpf) {
      fields.deliverymanCpf = filtersFields.deliverymanCpf;
    }

    if (filtersFields.launchDate) {
      fields.launchDate = filtersFields.launchDate;
    }

    if (filtersFields.nextTransferDate) {
      fields.nextTransferDate = filtersFields.nextTransferDate;
    }

    if (filtersFields.status) {
      fields.status = filtersFields.status;
    }

    setFilter({
      ...fields,
      page: "0",
      limit: "10",
    });
  };

  const handleSetPage = (page: number) => {
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        page: page.toString(),
        limit: "10",
      };
    });
  };

  return (
    <UserPaymentDebtContext.Provider
      value={{
        data: data?.data || [],
        setFilter,
        handleFetchData,
        handleSetPage,
        register,
        currentPage: data?.currentPage || 1,
        totalPages: data?.totalPages || 1,
        hasNextPage: data?.hasNextPage || false,
        openModal,
        handleChangeModalStatus,
        modalIfon,
        setModalInfo,
        isLoading,
        totalValue,
      }}
    >
      {children}
    </UserPaymentDebtContext.Provider>
  );
}
