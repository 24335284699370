import { Tag, TagLabel } from "@chakra-ui/react";

interface ChipProps {
  value: string;
}

export function Chip({ value }: ChipProps) {
  return (
    <div>
      <Tag
        marginLeft={2}
        padding={2}
        bg="green.500"
        color="gray.100"
        fontSize="14"
        fontWeight="bold"
        _hover={{
          bg: "green.600",
        }}
      >
        <TagLabel>{value}</TagLabel>
      </Tag>
    </div>
  );
}
