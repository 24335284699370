import {
  VStack,
  Heading,
  Grid,
  Flex,
  Divider,
  List,
  ListItem,
  Text,
  Stack,
  Box,
  SimpleGrid,
} from "@chakra-ui/layout";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SkeletonText,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { convertCentsToReais } from "../../../utils/convert-money";
import { useForm } from "react-hook-form";
import { postWithAuth } from "../../../services/basicService";
import { AxiosError } from "axios";
import { CopyText } from "../../../components/forms/CopyText";
import { documentFormatter } from "../../../utils/format-document";
import {
  Bank,
  Buildings,
  Files,
  Lock,
  Vault,
  Wallet,
  Warning,
} from "@phosphor-icons/react";
import dayjs from "dayjs";
import { calculateTotalDebtAmount } from "../../../utils/calculate-total-debt-amount";
import { PermissionsEnum } from "../../../enums/permissions.enum";
import { UserDto } from "../../../dto/user-dto";
import { bankInfoResponse } from "../../../types/bank-info.type";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const tabAccountMapper = {
  "main-account": 0,
  "payment-account": 1,
};

export function AddBalanceToCompany() {
  const getBankInfo = useCurrentUserStore((state) => state.getBankInfo);
  const user = useCurrentUserStore((state) => state.user);
  const myPermissions = useCurrentUserStore((state) => state.permissions);

  const canSeeBalances = myPermissions?.some(
    (permission) => permission.key === PermissionsEnum["Saldos/Débitos OL"]
  );

  const toast = useToast();

  const [searchParams, setSearchParams] = useSearchParams({
    accountType: "main-account",
  });

  type AccountType = "main-account" | "payment-account";

  const accountType = searchParams.get("accountType") as AccountType;

  const [tabIndex, setTabIndex] = useState<number>(
    accountType ? tabAccountMapper[accountType] : 0
  );

  useEffect(() => {
    const index = tabAccountMapper[accountType] || 0;
    setTabIndex(index);
  }, [accountType]);

  const { data: bankInfo, isLoading } = useQuery(["bankInfo"], getBankInfo, {
    onError: (error) => {
      if (error instanceof AxiosError) {
        toast({
          status: "error",
          title: "Não foi possível buscar suas informações bancárias",
          description: error?.response?.data.message,
        });
      }

      toast({
        status: "error",
        title: "Não foi possível buscar suas informações bancárias",
      });
    },
  });

  const totalMainAccountDebts = bankInfo
    ? calculateTotalDebtAmount(bankInfo?.mainAccount?.debts)
    : 0;

  const totalPaymentAccountDebts = bankInfo
    ? calculateTotalDebtAmount(bankInfo?.paymentAccount?.debts)
    : 0;

  function changeSelectedAccount(tabIndex: number) {
    setTabIndex(tabIndex);
    const mappedAccountType = Object.entries(tabAccountMapper).find(
      ([, value]) => value === tabIndex
    )?.[0] as string;
    setSearchParams({
      accountType: mappedAccountType,
    });
  }

  if (!canSeeBalances) {
    throw new Error("Você não tem permissão para acessar esta página.");
  }

  return (
    <VStack gap={8} flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Adicionar saldo a empresa
      </Heading>

      <Flex gap={4} wrap="wrap">
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>Saldo total: </StatLabel>
            <StatNumber fontSize={["xl", "3xl"]} color="green.500">
              {bankInfo
                ? accountType === "main-account"
                  ? convertCentsToReais(
                      bankInfo?.mainAccount?.availableBalanceInCents || 0
                    )
                  : convertCentsToReais(
                      bankInfo?.paymentAccount?.availableBalanceInCents || 0
                    )
                : convertCentsToReais(0)}
            </StatNumber>
          </Stat>
        </Box>

        {(accountType === "main-account"
          ? totalMainAccountDebts
          : totalPaymentAccountDebts) > 0 ? (
          <>
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize={["md", "xl"]}>Dívida total: </StatLabel>
                <StatNumber fontSize={["xl", "3xl"]} color="red.500">
                  {bankInfo
                    ? accountType === "main-account"
                      ? convertCentsToReais(totalMainAccountDebts)
                      : convertCentsToReais(totalPaymentAccountDebts)
                    : convertCentsToReais(0)}
                </StatNumber>
              </Stat>
            </Box>

            {(accountType === "main-account"
              ? bankInfo?.mainAccount?.debts
              : bankInfo?.paymentAccount?.debts
            )?.map((debt) => (
              <Box
                bg="whiteAlpha.800"
                border="1px"
                borderColor="gray.300"
                borderRadius={8}
                p={4}
                w="max-content"
                shadow="base"
                key={`${debt.debt_date}-${debt.amount}`}
              >
                <Stat>
                  <StatLabel fontSize={["md", "xl"]}>
                    Dívida para o dia {dayjs(debt.debt_date).format("DD/MM/YY")}
                    :
                  </StatLabel>
                  <StatNumber fontSize={["xl", "3xl"]} color="red.500">
                    {bankInfo
                      ? convertCentsToReais(debt.amount - debt.paid)
                      : convertCentsToReais(0)}
                  </StatNumber>
                </Stat>
              </Box>
            ))}
          </>
        ) : null}

        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>Status da conta: </StatLabel>
            <StatNumber
              fontSize={["xl", "3xl"]}
              color={
                (accountType === "main-account"
                  ? bankInfo?.mainAccount
                  : bankInfo?.paymentAccount
                )?.status?.toLowerCase() !== "normal"
                  ? "red.500"
                  : "green.500"
              }
            >
              {(accountType === "main-account"
                ? bankInfo?.mainAccount?.status
                : bankInfo?.paymentAccount?.status) || ""}
            </StatNumber>
          </Stat>
        </Box>
      </Flex>

      <Tabs
        variant="soft-rounded"
        colorScheme="green"
        index={tabIndex}
        onChange={changeSelectedAccount}
      >
        <TabList color="blackAlpha.600">
          <Tab key="main-account">
            <strong>Conta Principal</strong>
          </Tab>
          <Tab key="payment-account">
            <strong>Conta Repasse</strong>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>{bankDatasMainAccount(isLoading, user, bankInfo)}</TabPanel>
          <TabPanel>{bankDatasPaymentAccount(isLoading, bankInfo)}</TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}

function bankDatasMainAccount(
  isLoading: boolean,
  user: UserDto | null,
  bankInfo: bankInfoResponse | undefined
) {
  return (
    <Grid
      w="full"
      gap={6}
      p={8}
      shadow="base"
      borderRadius={8}
      bg="whiteAlpha.800"
      templateColumns={{ base: "1fr", xl: "minmax(200px, 400px) 10px 1fr" }}
      templateRows={{ base: "min-content 10px min-content", xl: "1fr" }}
    >
      <Flex flexDir="column" gap={4}>
        <Flex>
          <Wallet size={30} />
          <Heading fontWeight="extrabold" textAlign="left" size="lg" pl={2}>
            Dados bancários
          </Heading>
        </Flex>

        <Divider />

        {isLoading ? (
          <Stack>
            <Skeleton height="30px" />
            <Skeleton height="30px" />
            <Skeleton height="30px" />
            <Skeleton height="30px" />
            <Skeleton height="30px" />
          </Stack>
        ) : (
          <SimpleGrid
            spacing={4}
            minChildWidth="150px"
            css={`
              p {
                color: var(--chakra-colors-blackAlpha-800);
              }
            `}
          >
            <Box display="flex" flexDir="column" gap={2} css>
              <Flex
                alignItems="center"
                gap={4}
                css={`
                  svg {
                    color: var(--chakra-colors-green-500);
                  }
                `}
              >
                <Buildings size={28} weight="bold" />
                <Heading fontSize="2xl" color="blackAlpha.900">
                  Empresa
                </Heading>
              </Flex>
              <Text fontSize="xl">{user?.company?.name}</Text>
            </Box>
            <Box display="flex" flexDir="column" gap={2}>
              <Flex
                alignItems="center"
                gap={4}
                css={`
                  svg {
                    color: var(--chakra-colors-green-500);
                  }
                `}
              >
                <Files size={28} weight="bold" />
                <Heading fontSize="2xl" color="blackAlpha.900">
                  CNPJ
                </Heading>
              </Flex>
              <Text fontSize="xl">
                {documentFormatter(user?.company?.cnpj || "", "cnpj")}
              </Text>
            </Box>
            <Box display="flex" flexDir="column" gap={2}>
              <Flex
                alignItems="center"
                gap={4}
                css={`
                  svg {
                    color: var(--chakra-colors-green-500);
                  }
                `}
              >
                <Bank size={28} weight="bold" />
                <Heading fontSize="2xl" color="blackAlpha.900">
                  Agência
                </Heading>
              </Flex>
              <Text fontSize="xl">
                {bankInfo?.mainAccount?.bankBranchNumber}
              </Text>
            </Box>
            <Box display="flex" flexDir="column" gap={2}>
              <Flex
                alignItems="center"
                gap={4}
                css={`
                  svg {
                    color: var(--chakra-colors-green-500);
                  }
                `}
              >
                <Vault size={28} weight="bold" />
                <Heading fontSize="2xl" color="blackAlpha.900">
                  Conta
                </Heading>
              </Flex>
              <Text fontSize="xl">
                {bankInfo?.mainAccount?.bankAccountNumber}
              </Text>
            </Box>
          </SimpleGrid>
        )}
      </Flex>

      <Divider orientation="vertical" />

      <Flex flexDir="column" gap={4}>
        <Heading fontWeight="extrabold" textAlign="left" size="lg">
          Dados de PIX
        </Heading>

        <Divider />

        {isLoading ? (
          <Stack direction="row" w="full" gap={4}>
            <Skeleton width="250px" height="250px" />
            <SkeletonText flex={1} height="30px" skeletonHeight={6} />
          </Stack>
        ) : (
          <Flex gap={4} wrap="wrap">
            {bankInfo?.mainAccount?.pix.qrCode ? (
              <Flex justifyContent="center" w="max-content">
                <Image
                  src={`data:image/png;base64,${bankInfo?.mainAccount?.pix.qrCode.image}`}
                  alt="pix qr code"
                  w="xs"
                  h="xs"
                />
              </Flex>
            ) : null}

            <Flex flexDir="column" gap={8}>
              <Heading fontSize="xl">Chaves cadastradas:</Heading>
              {bankInfo?.mainAccount?.pix.pixKeys &&
              bankInfo?.mainAccount?.pix.pixKeys.length > 0 ? (
                <Flex as={List} flexDir="column" gap={4}>
                  {bankInfo?.mainAccount?.pix.pixKeys.map((pix) => (
                    <ListItem key={pix.key}>
                      <CopyText
                        text={
                          pix.keyType !== "nationalRegistration"
                            ? pix.key
                            : documentFormatter(
                                pix.key,
                                pix.key.length > 11 ? "cnpj" : "cpf"
                              )
                        }
                      />
                    </ListItem>
                  ))}
                </Flex>
              ) : (
                <CreatePixModal idAccount={user?.company?.account_id} />
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Grid>
  );
}

function bankDatasPaymentAccount(
  isLoading: boolean,
  bankInfo: bankInfoResponse | undefined
) {
  return (
    <Grid
      w="full"
      gap={6}
      p={8}
      shadow="base"
      borderRadius={8}
      bg="whiteAlpha.800"
      templateColumns={{ base: "1fr", xl: "minmax(200px, 400px) 10px 1fr" }}
      templateRows={{ base: "min-content 10px min-content", xl: "1fr" }}
    >
      <Flex flexDir="column" gap={4}>
        <Flex>
          <Lock size={30} />
          <Heading fontWeight="extrabold" textAlign="left" size="lg" pl={2}>
            Dados bancários
          </Heading>
        </Flex>

        <Divider />

        {isLoading ? (
          <Stack>
            <Skeleton height="30px" />
            <Skeleton height="30px" />
            <Skeleton height="30px" />
            <Skeleton height="30px" />
            <Skeleton height="30px" />
          </Stack>
        ) : (
          <>
            <SimpleGrid
              spacing={4}
              minChildWidth="150px"
              css={`
                p {
                  color: var(--chakra-colors-blackAlpha-800);
                }
              `}
            >
              <Box display="flex" flexDir="column" gap={2}>
                <Flex
                  alignItems="center"
                  gap={4}
                  css={`
                    svg {
                      color: var(--chakra-colors-green-500);
                    }
                  `}
                >
                  <Vault size={28} weight="bold" />
                  <Heading fontSize="2xl" color="blackAlpha.900">
                    Conta
                  </Heading>
                </Flex>
                <Text fontSize="xl">
                  {bankInfo?.paymentAccount?.bankAccountNumber}
                </Text>
              </Box>
            </SimpleGrid>
          </>
        )}
      </Flex>

      <Divider orientation="vertical" />

      <Flex flexDir="column" gap={4}>
        <Heading fontWeight="extrabold" textAlign="left" size="lg">
          <Flex
            alignItems="center"
            gap={4}
            css={`
              svg {
                color: var(--chakra-colors-yellow-500);
              }
            `}
          >
            <Warning size={28} weight="bold" />
            Atenção!
          </Flex>
        </Heading>

        <Divider />

        <Flex gap={4} wrap="wrap">
          <Flex justifyContent="center" w="max-content">
            <Box display="flex" flexDir="column" gap={2}>
              <Text fontSize="xl">
                Essa é uma conta de débitos, não é possível movimentar
                livremente o dinheiro
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Grid>
  );
}

export function CreatePixModal({ idAccount }: { idAccount?: number }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const toast = useToast();

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { isSubmitting },
  } = useForm<{
    pix_type: "CNPJ" | "EMAIL" | "PHONE" | "RANDOM";
    pix_key?: string;
  }>({
    mode: "onChange",
    defaultValues: {
      pix_type: "CNPJ",
    },
  });

  const pixTypeWatcher = watch("pix_type");

  async function onSubmit(data: {
    pix_type: "CNPJ" | "EMAIL" | "PHONE" | "RANDOM";
    pix_key?: string;
  }) {
    if (!idAccount) {
      return;
    }

    const body = {
      idAccount: Number(idAccount),
      pixType: data.pix_type,
      key: data.pix_key || "",
    };

    try {
      await postWithAuth("/api/v1/companies/create-pix-key", body);

      toast({
        status: "success",
        title: "Chave PIX criada com sucesso!",
      });

      queryClient.invalidateQueries({
        queryKey: ["bankInfo"],
      });

      onClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        return toast({
          status: "error",
          title: "Não foi possível criar Chave PIX!",
          description: error?.response?.data.message,
        });
      }

      toast({
        status: "error",
        title: "Não foi possível criar Chave PIX!",
      });
    }
  }

  return (
    <Flex flexDir="column" alignItems="center" justifyContent="center" gap={4}>
      <Text>
        Não há chaves PIX cadastradas, use o botão abaixo para criar uma
      </Text>
      <Button onClick={onOpen} color="white">
        Criar nova chave
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar chave PIX</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              as="form"
              id="form-create-pix"
              flexDir="column"
              gap={4}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Select
                {...register("pix_type", {
                  onChange: () => {
                    resetField("pix_key");
                  },
                })}
              >
                <option value="CNPJ">CNPJ</option>
                <option value="EMAIL">E-mail</option>
                <option value="PHONE">Telefone</option>
                <option value="RANDOM">Aleatória</option>
              </Select>
              <Input
                hidden={
                  pixTypeWatcher === "RANDOM" || pixTypeWatcher === "CNPJ"
                }
                {...register("pix_key")}
                placeholder="Insira a chave a ser registrada"
                bg="gray.100"
                w="full"
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex gap={4}>
              <Button onClick={onClose}>Fechar</Button>
              <Button
                form="form-create-pix"
                type="submit"
                color="white"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                loadingText="Criando..."
              >
                Criar chave
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
