import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Heading,
  TableContainer,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface TableColumn {
  header: string;
  accessor: string;
  dataTransform?: <T>(row: T) => ReactNode;
}

interface TableProps {
  title?: string;
  before?: ReactNode;
  columns: TableColumn[];
  data: Record<string, string | number>[];
  actions?: (data: unknown) => ReactNode;
  actionsLabel?: string;
  after?: ReactNode;
}

const GenericTable: React.FC<TableProps> = ({
  title,
  before,
  columns,
  data,
  actions,
  actionsLabel,
  after,
}) => {
  return (
    <VStack gap={8} alignItems="flex-start" w="100%">
      {title && (
        <Heading fontWeight="extrabold" textAlign="left">
          {title}
        </Heading>
      )}

      {before}

      <TableContainer
        style={{
          overflowX: "auto",
          containerType: "inline-size",
          width: "100%",
        }}
        flexDir="column"
        display="flex"
        gap={8}
        mt={8}
      >
        <Table flexDir="column" variant="striped" colorScheme="green">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column.accessor}>{column.header}</Th>
              ))}
              {actions && <Th>{actionsLabel}</Th>}
            </Tr>
          </Thead>
          <Tbody overflowY="auto">
            {data?.map((row, index) => (
              <Tr key={index}>
                {columns.map((column) => (
                  <Td key={column.accessor}>
                    {column.dataTransform
                      ? column.dataTransform(row)
                      : row[column.accessor]}
                  </Td>
                ))}
                {actions && <Td>{actions(row)}</Td>}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {after}
    </VStack>
  );
};

export default GenericTable;
