import { Flex, HStack, Heading } from "@chakra-ui/layout";
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { CaretLeft } from "@phosphor-icons/react";
import { EditUser } from "../edit-user";
import { TwoFactorAuthCodes } from "../two-factor-user-codes";
import { Link } from "react-router-dom";
import { EditRoles } from "../edit-roles";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { RoleEnum } from "../../../../enums/roles-enum";
import { UserStatusHistoryPanel } from "./user-status-history-panel";

export function UserDetails() {
  const me = useCurrentUserStore((state) => state.user);
  const isAdmin = me?.roles.some(
    (role) => role.name === RoleEnum.ADMIN_TRAMPAY
  );

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      alignSelf="center"
      w="full"
      h="full"
      gap={8}
    >
      <HStack gap={8}>
        <Button as={Link} to="/users">
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading>Detalhes do usuário</Heading>
      </HStack>

      <Flex
        flexDir="column"
        w="full"
        h="full"
        alignSelf="center"
        shadow="base"
        gap={6}
        p={8}
        borderRadius={8}
        bg="whiteAlpha.800"
      >
        <Tabs colorScheme="green">
          <TabList>
            <Tab>Editar Dados</Tab>
            <Tab>Códigos 2FA</Tab>
            <Tab>Editar Cargos</Tab>
            <Tab>Histórico de status</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <EditUser />
            </TabPanel>
            <TabPanel>
              <TwoFactorAuthCodes />
            </TabPanel>
            <TabPanel>
              {isAdmin ? (
                <EditRoles />
              ) : (
                "Você não tem permissão para editar cargos."
              )}
            </TabPanel>
            <TabPanel>
              <UserStatusHistoryPanel />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
}
