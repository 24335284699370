import { create } from "zustand";
import {
  CompanyBankLimitDto,
  AccountLimit,
} from "../dto/company-bank-limit-dto";
import {
  COMPANY_TYPE,
  CompanyConfigurations,
  CompanyDto,
} from "../dto/company-dto";
import { Permission } from "../dto/permission-dto";
import {
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../services/basicService";
import { BENEFICIARY_TYPE, ID_SERVICES_GROUP } from "../enums/bank-limit";
import { CompanyPermissionDto } from "../dto/company-permission-dto";
import { CompanyPermissionsResponse } from "../types/get-company-permissions.type";
import { INTEGRATION } from "../enums/company-integration";
import { TypeAdvancePeriod } from "../enums/type-advance-period.enum";
import { SpreadsheetTypes } from "../enums/spreadsheet-types.enum";

export type AccountLimits = {
  limitP2PtoPF: AccountLimit[];
  limitPIXtoPF: AccountLimit[];
  limitTEDtoPF?: AccountLimit[];
  limitP2PtoPJ: AccountLimit[];
  limitPIXtoPJ: AccountLimit[];
  limitTEDtoPJ?: AccountLimit[];
};

interface CompaniesStore {
  companies: { companies: CompanyDto[]; pages: number };
  company: CompanyDto | null;
  companyPermissions: Array<
    Permission & { companyPermissionId: string }
  > | null;
  allPermissions: Permission[] | null;
  changingStatus: boolean;
  companyBankLimits: {
    mainAccountLimits: AccountLimits;
    paymentAccountLimits: AccountLimits;
  };

  getCompanies: (
    page?: number,
    search?: string
  ) => Promise<{ companies: CompanyDto[]; pages: number }>;
  getCompany: (id: number) => Promise<CompanyDto | null>;
  getCompanyPermissions: (
    id?: number
  ) => Promise<CompanyPermissionsResponse | void>;
  fetchPermissions: () => Promise<void>;
  changeCompanyStatus: (id: number, status: number) => Promise<void>;
  changeAllCompaniesStatus: (status?: number) => Promise<void>;
  getCompanyBankLimits: (companyId: number) => Promise<void>;
}

function formatAccountLimits(companyBankLimits: CompanyBankLimitDto) {
  const limitP2PtoPF = companyBankLimits.limits.filter(
    (limit) =>
      limit.beneficiaryType === BENEFICIARY_TYPE.NATURAL_PERSON &&
      limit.idServicesGroup === ID_SERVICES_GROUP.P2P
  );

  const limitP2PtoPJ = companyBankLimits.limits.filter(
    (limit) =>
      limit.beneficiaryType === BENEFICIARY_TYPE.LEGAL_PERSON &&
      limit.idServicesGroup === ID_SERVICES_GROUP.P2P
  );

  const limitPIXtoPJ = companyBankLimits.limits.filter(
    (limit) =>
      limit.beneficiaryType === BENEFICIARY_TYPE.LEGAL_PERSON &&
      limit.idServicesGroup === ID_SERVICES_GROUP.PIX
  );

  const limitPIXtoPF = companyBankLimits.limits.filter(
    (limit) =>
      limit.beneficiaryType === BENEFICIARY_TYPE.NATURAL_PERSON &&
      limit.idServicesGroup === ID_SERVICES_GROUP.PIX
  );

  return {
    limitP2PtoPF,
    limitP2PtoPJ,
    limitPIXtoPF,
    limitPIXtoPJ,
  };
}

export const useCompaniesStore = create<CompaniesStore>((set, get) => ({
  companies: { companies: [], pages: 1 },
  company: null,
  companyPermissions: null,
  allPermissions: null,
  changingStatus: false,
  companyBankLimits: {
    mainAccountLimits: {
      limitP2PtoPF: [],
      limitPIXtoPF: [],
      limitP2PtoPJ: [],
      limitPIXtoPJ: [],
    },
    paymentAccountLimits: {
      limitP2PtoPF: [],
      limitPIXtoPF: [],
      limitP2PtoPJ: [],
      limitPIXtoPJ: [],
    },
  },

  getCompanies: async (page, search) => {
    const url = new URLSearchParams({ page: "1", limit: "10" });

    if (search) {
      url.append("search", search);
    }

    if (page) {
      url.set("page", page.toString());
    }

    try {
      const response = await getWithAuth(`/api/v1/companies?${url.toString()}`);

      if (response?.data) {
        const result: { companies: CompanyDto[]; pages: number } =
          response.data;

        set({ companies: result });

        return result;
      }

      return { companies: [], pages: 1 };
    } catch (error) {
      throw new Error("Não foi possível autenticar usuário");
    }
  },

  getCompany: async (id: number) => {
    try {
      const response = await getWithAuth(`/api/v1/companies/${id}`);

      if (response?.data) {
        const company: CompanyDto = response.data;

        set({ company });

        return company;
      }

      return null;
    } catch (error) {
      throw new Error("Não foi possível autenticar usuário");
    }
  },

  getCompanyPermissions: async (companyId?: number) => {
    try {
      const resource = "/api/v1/company_permissions";

      const url = companyId ? `${resource}?companyId=${companyId}` : resource;

      const response = await getWithAuth(url);

      if (response?.data) {
        const companyPermissions: CompanyPermissionsResponse = response.data;

        set({ companyPermissions });

        return companyPermissions;
      }
    } catch (error) {
      throw new Error("Não foi possível carregar permissões para esta empresa");
    }
  },

  fetchPermissions: async () => {
    try {
      const permissions = await getWithAuth(
        "/api/v1/permission?page-1&limit=50&isCompanyPermission=true"
      );

      if (!permissions) return;

      return set({ allPermissions: permissions.data.data });
    } catch (error) {
      console.log(error);
    }
  },

  changeCompanyStatus: async (id: number, status: number) => {
    set({ changingStatus: true });
    try {
      await patchWithAuth(`/api/v1/companies/status/${id}`, {
        status: { id: status },
      });

      await get().getCompanies();

      set({ changingStatus: false });
    } catch (error) {
      console.log(error);
      set({ changingStatus: false });
      throw error;
    }
  },

  changeAllCompaniesStatus: async (status?: number) => {
    set({ changingStatus: true });
    try {
      if (status) {
        await patchWithAuth(`/api/v1/companies/status/`, {
          status: { id: status },
        });
        set({ changingStatus: false });
        await get().getCompanies();
        return;
      }
      await patchWithAuth(`/api/v1/companies/status/`);
      await get().getCompanies();
      set({ changingStatus: true });
    } catch (error) {
      console.log(error);
      set({ changingStatus: false });
      throw new Error(JSON.stringify(error));
    }
  },

  getCompanyBankLimits: async (companyId: number) => {
    try {
      const company = await get().getCompany(companyId);

      const getMainAccountLimits = getWithAuth(
        `/api/v1/companies/bank/limits/${company?.account_id}`
      );

      const getPaymentAccountLimits = getWithAuth(
        `/api/v1/companies/bank/limits/${company?.paymentAccountId}`
      );

      const [mainAccountLimitsResponse, paymentAccountLimitsResponse] =
        await Promise.allSettled([
          getMainAccountLimits,
          getPaymentAccountLimits,
        ]);

      const companyBankLimits = {
        mainAccountLimits: {} as AccountLimits,
        paymentAccountLimits: {} as AccountLimits,
      };

      if (mainAccountLimitsResponse.status === "fulfilled") {
        const mainAccountLimitData = mainAccountLimitsResponse.value?.data;
        const mainAccountLimits: CompanyBankLimitDto = mainAccountLimitData;

        companyBankLimits.mainAccountLimits =
          formatAccountLimits(mainAccountLimits);
      }

      if (paymentAccountLimitsResponse.status === "fulfilled") {
        const paymentAccountLimitData =
          paymentAccountLimitsResponse.value?.data;
        const paymentAccountLimits: CompanyBankLimitDto =
          paymentAccountLimitData;

        companyBankLimits.paymentAccountLimits =
          formatAccountLimits(paymentAccountLimits);
      }

      set({
        companyBankLimits,
      });
    } catch (error) {
      throw new Error("Não foi possível autenticar usuário");
    }
  },
}));

type CreateCompanyData = {
  id: string;
  name: string;
  pracas?: string;
  dailyFeePercentage: number;
  dailyFeeFixed: number;
  weeklyFeePercentage: number;
  weeklyFeeFixed: number;
  integration?: INTEGRATION;
  cnpj: string;
  socialReason: string;
  dailyDescriptionFee?: string;
  weeklyDescriptionFee?: string;
  periodType?: TypeAdvancePeriod | null;
  spreadsheetTypes?: SpreadsheetTypes | null;
  amountDaysDueDateAfterEnd?: number | null;
  companyType: COMPANY_TYPE;
  configurations?: CompanyConfigurations;
  franchisorId?: number;
  scheduledSplitFlow?: boolean;
  conciliatedPaymentSplitFlow?: boolean;
};

interface CreateCompanyFormStore {
  companyData: CreateCompanyData | null;
  companyPermissions: Permission[] | null;
  allPermissions: Permission[] | null;
  createCompany: (companyData: Omit<CreateCompanyData, "id">) => Promise<void>;
  updateCompany: (
    id: number,
    companyData: Omit<Partial<CreateCompanyData>, "id">
  ) => Promise<void>;
  fetchPermissions: () => Promise<Permission[]>;
  createPermissions: (permissions: CompanyPermissionDto[]) => Promise<void>;
  updatePermissions: (
    permissions: {
      company: { id: number | undefined };
      url: string | null;
      permission: Permission;
    }[]
  ) => Promise<void>;
}

export const useCreateCompanyForm = create<CreateCompanyFormStore>((set) => ({
  companyData: null,
  companyPermissions: [],
  allPermissions: [],
  createCompany: async (data) => {
    const response = await postWithAuth("/api/v1/companies", {
      ...data,
      razaoSocial: data.socialReason,
      status: {
        id: 1,
        name: "Active",
      },
    });
    set({ companyData: { ...data, id: response?.data.id } });
  },
  updateCompany: async (id, data) => {
    await patchWithAuth(`/api/v1/companies/${id}`, {
      ...data,
      razaoSocial: data.socialReason,
    });
  },
  fetchPermissions: async () => {
    try {
      const permissions = await getWithAuth(
        "/api/v1/permission?page-1&limit=50"
      );

      if (!permissions) return;

      set({ allPermissions: permissions.data.data });

      return permissions.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  createPermissions: async (data) => {
    try {
      await postWithAuth<CompanyPermissionDto | CompanyPermissionDto[]>(
        "/api/v1/company_permissions",
        data
      );
    } catch (error) {
      console.log(error);
    }
  },
  updatePermissions: async (data) => {
    try {
      const newPermissions = await patchWithAuth(
        `/api/v1/company_permissions/${data[0].company.id}`,
        data
      );
      if (newPermissions?.data) {
        set({ companyPermissions: newPermissions?.data });
      }
    } catch (error) {
      console.log(error);
    }
  },
}));
