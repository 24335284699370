import { format, formatDate } from "../../../../../utils/format-date";

export const formatDateScaled = (row: Record<string, unknown>) =>
  formatDate(row, "dateScale", format.BR, "/");

export const formatCreatedAt = (row: Record<string, unknown>) =>
  formatDate(row, "createdAt", format.BR, "/");

export const formatUpdatedAt = (row: Record<string, unknown>) =>
  formatDate(row, "updatedAt", format.BR, "/");
