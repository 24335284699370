import { VStack, Stack, HStack, Flex, Box } from "@chakra-ui/layout";
import {
  Text,
  Button,
  useToast,
  Input,
  FormLabel,
  FormControl,
  FormErrorMessage,
  IconButton,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { FileArrowDown, Trash } from "@phosphor-icons/react";
import { useForm, useFieldArray } from "react-hook-form";
import { AxiosError } from "axios";
import { postWithAuth } from "../../../../../services/basicService";
import { DragDrop, IRef } from "../../../../../components/forms/fileUpload";
import { useRef, useState } from "react";

type FormSendMessage = {
  cpfs: {
    documents: string;
  }[];
  message: string;
  title?: string;
};

const emptyCpf = {
  documents: "",
};

export function SendToSomeTab() {
  const documentsRef = useRef<IRef>(null);
  const [isSending, setIsSending] = useState(false);
  const toast = useToast();

  const uploadFiles = useDisclosure({ id: "upload-files" });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormSendMessage>({
    defaultValues: {
      cpfs: [{ documents: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "cpfs",
    control,
    rules: {
      required: "Adicione ao menos um documento.",
    },
  });

  async function handleUpload() {
    if (isSending) return;

    setIsSending(true);

    const csvFounded = documentsRef.current?.hasFiles();

    if (!csvFounded) {
      toast({
        status: "error",
        title: "Planilha faltante",
        description: "É necessário enviar uma planilha primeiro",
      });
    }

    try {
      const result = await documentsRef.current?.processUpload<{
        documents: string[];
      }>({
        sendId: crypto.randomUUID(),
      });

      if (fields[0].documents === "") {
        remove(0);
      }

      result?.documents.forEach((document) => {
        append({ documents: document });
      });

      uploadFiles.onClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          status: "error",
          title: "Erro ao enviar o CSV",
          description: error.response?.data.message,
        });
      }
    } finally {
      setIsSending(false);
    }
  }

  const submit = async (data: FormSendMessage) => {
    const documents = data.cpfs.map((item) => item.documents);
    const title = data.title?.trim() === "" ? undefined : data.title;

    const body = {
      title,
      message: data.message,
      documents,
    };

    try {
      await postWithAuth("/api/v1/app/send-message", body);

      toast({
        title: "Mensagem enviada",
        description: `A mensagem foi enviada com sucesso!`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });

      setValue("message", "");
      setValue("title", "");
      setValue("cpfs", [{ documents: "" }]);
    } catch (error) {
      let description = "Não foi possível enviar a mensagem";

      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          description = error.response.data.message.join("; ");
        }
      }

      toast({
        title: "Erro ao mandar a mensagem",
        description,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <VStack alignItems="flex-start" w="full" gap={8}>
      <Flex w="full" justifyContent="space-between" gap={8}>
        <FormControl
          as="form"
          w={{ lg: "40%", sm: "full" }}
          onSubmit={handleSubmit(submit)}
          isInvalid={!!errors.cpfs}
        >
          <FormLabel as="legend" fontSize="2xl" textAlign="center">
            Título da mensagem
          </FormLabel>
          <VStack w="full" gap={4} marginTop={4} width="full">
            <Input
              type="text"
              {...register("title", { required: false })}
              bg="whiteAlpha.800"
              placeholder="Título da mensagem *Opcional"
            />
            <FormLabel as="legend" fontSize="2xl" textAlign="center">
              Mensagem
            </FormLabel>
            <Textarea
              placeholder="Mensagem que será enviada"
              {...register(`message`, { required: true })}
              bg="whiteAlpha.800"
            />
            <Text fontSize="2xl">
              Após selecionar os CPFs, mande a mensagem clicando no botão
              abaixo:
            </Text>
            <Button
              mt={4}
              colorScheme="green"
              type="submit"
              width="full"
              isDisabled={!isValid}
            >
              Enviar mensagem
            </Button>
          </VStack>
        </FormControl>
        <Box display={"flex"} flex={1} flexDir={"column"} gap={4}>
          <Flex justifyContent="space-between">
            <Text fontSize="2xl" fontWeight="semibold">
              CPF&apos;s que serão notificados
            </Text>
            <Button color="white" onClick={uploadFiles.onOpen}>
              Preencher documentos por CSV
            </Button>
            <Button color="white">
              <FileArrowDown size={24} />
              <a
                href="/assets/template-sheet-for-message/modelo-documentos.csv"
                download
              >
                Modelo .CSV
              </a>
            </Button>
          </Flex>
          <Stack>
            {fields.map((field, index) => (
              <HStack key={field.id} alignItems="flex-start">
                <Input
                  type="text"
                  {...register(`cpfs.${index}.documents`, {
                    required: "Digite o documento.",
                    minLength: {
                      value: 11,
                      message: "Documento deve ter no mínimo 11 caracteres.",
                    },
                    maxLength: {
                      value: 12,
                      message: "Documento deve ter no máximo 12 caracteres.",
                    },
                  })}
                  bg="whiteAlpha.800"
                  placeholder="11 a 12 números"
                />
                {errors.cpfs?.[index]?.documents && (
                  <FormErrorMessage>
                    {errors.cpfs?.[index]?.documents?.message}
                  </FormErrorMessage>
                )}
                <IconButton
                  aria-label="campo para apagar input de documento"
                  icon={<Trash />}
                  onClick={() => remove(index)}
                />
              </HStack>
            ))}
            <Button
              type="button"
              onClick={() => append(emptyCpf)}
              width="full"
              colorScheme="green"
            >
              Adicionar documento
            </Button>
          </Stack>
        </Box>
      </Flex>
      <Modal
        isOpen={uploadFiles.isOpen}
        onClose={uploadFiles.onClose}
        size="5xl"
        id="upload-files"
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader>Upload CSV de documentos</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <Flex gap={16} flexDir="column">
              <Flex flexDir="column" gap={4}>
                <DragDrop
                  uploadUrl="/api/v1/uploaded-file/document"
                  maxFiles={1}
                  ref={documentsRef}
                />
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={uploadFiles.onClose}>
              Fechar
            </Button>
            <Button
              color="white"
              onClick={handleUpload}
              isLoading={isSending}
              isDisabled={isSending}
            >
              Enviar .CSV
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
