import { useRef, useState } from "react";
import { useUserBalanceStore } from "../../../../../store/user-balance.store";
import { VStack, Badge, HStack, Flex, Text } from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  Button,
  Icon,
  useColorMode,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowDown, ArrowUp, Trash } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { Pagination } from "../../../../../components/pagination";
import { useQuery } from "@tanstack/react-query";
import { deleteWithAuth } from "../../../../../services/basicService";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import { DeliverymanDto } from "../../../../../dto/deliveryman-dto";

type DeleteBalanceData = {
  id: number;
  name: string;
  balanceDate: string;
  createdAt: string;
};

export function DeliverymanBalances({
  deliveryman,
}: {
  deliveryman: DeliverymanDto;
}) {
  const deleteBalanceData = useRef<DeleteBalanceData>();
  const [deleteBalanceLoading, setDeleteBalanceLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [isOrderByDesc, setIsOrderByDesc] = useState<boolean>(true);

  const userBalance = useUserBalanceStore((state) => state.userBalance);
  const getUserBalance = useUserBalanceStore((state) => state.getUserBalance);

  const { colorMode } = useColorMode();
  const toast = useToast({ duration: 4000, isClosable: true, position: "top" });

  const fetchBalanceData = async () => {
    try {
      return await getUserBalance(
        `/api/v1/userAdvanceBalance?search=${deliveryman.document}`
      );
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao buscar saldos",
        description:
          "Não foi possível buscar saldos. Tente novamente ou contate a tecnologia.",
        status: "error",
      });
    }
  };

  const { isLoading, refetch } = useQuery(
    ["balance-data", page, isOrderByDesc],
    fetchBalanceData
  );

  const deleteBalance = useDisclosure({ id: "delete-balance" });

  const handleOpenDeleteBalancesModal = (data: DeleteBalanceData) => {
    deleteBalanceData.current = data;

    deleteBalance.onOpen();
  };

  const handleDeleteBalances = () => {
    if (deleteBalanceLoading) {
      return;
    }

    setDeleteBalanceLoading(true);

    const URL = `/api/v1/userAdvanceBalance/${deleteBalanceData.current?.id}`;

    deleteWithAuth(URL)
      .then(() => {
        refetch();

        deleteBalance.onClose();

        toast({
          status: "success",
          title: "Sucesso ao deletar o saldo",
          description: `Saldo do(a) ${deleteBalanceData.current?.name} foi deletado`,
        });
      })
      .catch(() => {
        toast({
          status: "error",
          title: "Erro ao deletar o saldo",
          description: `Não foi possível deletar o saldo`,
        });
      })
      .finally(() => setDeleteBalanceLoading(false));
  };

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      {userBalance && userBalance?.result?.length >= 0 ? (
        <TableContainer
          w="full"
          flex={1}
          p={8}
          gap={4}
          display="flex"
          flexDir="column"
          bg={colorMode === "dark" ? "gray.700" : "whiteAlpha.800"}
          borderColor={colorMode === "dark" ? "" : "gray.300"}
          shadow="base"
        >
          <Table colorScheme="green" variant="striped">
            <Thead>
              <Tr>
                <Th textAlign="center">Empresa</Th>
                <Th
                  textAlign="center"
                  onClick={() => setIsOrderByDesc(!isOrderByDesc)}
                  cursor="pointer"
                >
                  Data de referência
                  {isOrderByDesc ? (
                    <Icon as={ArrowDown} />
                  ) : (
                    <Icon as={ArrowUp} />
                  )}
                </Th>
                <Th isNumeric>Saldo</Th>
                <Th textAlign="center">Data de criação</Th>
                <Th textAlign="center">Pedido foi realizado?</Th>
                <Th textAlign="center">Deletar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? userBalance?.result.map((balance) => {
                    return (
                      <Tr fontWeight={600} key={balance.id}>
                        <Td textAlign="center">{balance.company}</Td>
                        <Td textAlign="center">
                          {dayjs(balance.balanceDate).format("DD/MM/YYYY")}
                        </Td>
                        <Td isNumeric>
                          {convertCentsToReais(balance.balance)}
                        </Td>
                        <Td textAlign="center">
                          {dayjs(balance.createdAt).format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td textAlign="center">
                          <Badge
                            variant="solid"
                            px={4}
                            colorScheme={balance.wasUsed ? "green" : "red"}
                          >
                            {balance.wasUsed ? "Sim" : "Não"}
                          </Badge>
                        </Td>
                        <Td textAlign="center">
                          {balance.wasUsed || balance.deletedAt !== null ? (
                            <Flex justifyContent="center">-</Flex>
                          ) : (
                            <Button
                              backgroundColor="red.400"
                              onClick={() =>
                                handleOpenDeleteBalancesModal({
                                  id: balance.id,
                                  name: balance.name,
                                  balanceDate: balance.balanceDate,
                                  createdAt: balance.createdAt,
                                })
                              }
                            >
                              <Trash size={24} color="white" />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    );
                  })
                : null}
            </Tbody>
          </Table>
          <HStack gap={8} pt={8} justifyContent="flex-end">
            <Pagination
              page={page}
              setPage={setPage}
              totalPages={userBalance?.totalPages || 1}
            />
          </HStack>
        </TableContainer>
      ) : (
        <Text fontSize="x-large" fontWeight="extrabold">
          Ainda não foram feitos adiantamentos no dia de hoje.
        </Text>
      )}
      <Modal
        isOpen={deleteBalance.isOpen}
        onClose={deleteBalance.onClose}
        size="5xl"
        id="delete-balance"
      >
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader fontSize="3xl">
            Confirmar Ação de Deletar Saldo
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <Flex flexDirection="column" fontSize="2xl">
              <Flex marginBottom="5">
                Essa ação ira deletar o saldo do(a){" "}
                {deleteBalanceData.current?.name}
              </Flex>
              <Flex as="ul" flexDirection="column" gap="5px">
                <li>
                  data de referencia:{" "}
                  {dayjs(deleteBalanceData.current?.balanceDate).format(
                    "DD/MM/YYYY"
                  )}
                </li>
                <li>
                  data de criação:{" "}
                  {dayjs(deleteBalanceData.current?.createdAt).format(
                    "DD/MM/YYYY"
                  )}
                </li>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter my={4}>
            <Button
              onClick={deleteBalance.onClose}
              isDisabled={deleteBalanceLoading}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteBalances}
              ml={3}
              isLoading={deleteBalanceLoading}
              isDisabled={deleteBalanceLoading}
            >
              Deletar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
