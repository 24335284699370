import { Divider, Flex, Heading } from "@chakra-ui/layout";
import { SubMenuButton } from "./sub-menu-button";
import { useLocation } from "react-router-dom";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { isFranchisor } from "../../../utils/company-verifications";
import { CompanyDto } from "../../../dto/company-dto";
import { TRAMPAY_COMPANY_ID } from "../../../utils/constants/trampay-company-id";

type configOptions = "company" | "support";

export function SplitPaymentsSubMenu(props: { config: configOptions }) {
  const { pathname } = useLocation();
  const me = useCurrentUserStore((state) => state.user);

  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      flexDir="column"
      bg="whiteAlpha.800"
      borderRadius={8}
      h="min-content"
      gap={4}
      minW="sm"
      shadow="base"
      border="1px"
      borderColor="gray.300"
      position="sticky"
      top={8}
    >
      <Heading size="md">Menu de Pagamentos</Heading>

      <Flex w="full" justifyContent="flex-start" flexDir="column" gap={2}>
        {props.config !== "company" ||
          isFranchisor(me?.company as CompanyDto) || (
            <SubMenuButton
              to="/payments/create-transactions"
              isSelected={
                pathname.includes("/payments/create-transactions") ||
                pathname === "/payments"
              }
              fontSize="md"
            >
              Nova Folha
            </SubMenuButton>
          )}
        {props.config !== "company" ||
          isFranchisor(me?.company as CompanyDto) || (
            <SubMenuButton
              to="/payments/transactions-draft"
              isSelected={pathname === "/payments/transactions-draft"}
              fontSize="md"
            >
              Folhas em rascunho
            </SubMenuButton>
          )}
        <SubMenuButton
          to="/payments/is-processing"
          isSelected={pathname === "/payments/is-processing"}
          fontSize="md"
        >
          Folhas em processamento
        </SubMenuButton>
        {props.config === "company" ? (
          <SubMenuButton
            to="/payments/is-processed"
            isSelected={pathname === "/payments/is-processed"}
            fontSize="md"
          >
            Folhas processadas
          </SubMenuButton>
        ) : (
          <SubMenuButton
            to="/payments"
            isSelected={pathname === "/payments"}
            fontSize="md"
          >
            Folhas processadas
          </SubMenuButton>
        )}
        <Divider />
        <SubMenuButton
          to="/payments/is-instant"
          isSelected={pathname === "/payments/is-instant"}
          fontSize="md"
        >
          Folhas avulsas
        </SubMenuButton>
        {(me?.company?.scheduledSplitFlow &&
          !me?.company?.conciliatedPaymentSplitFlow) ||
        me?.companyId === TRAMPAY_COMPANY_ID ? (
          <SubMenuButton
            to="/payments/is-scheduled"
            isSelected={pathname === "/payments/is-scheduled"}
            fontSize="md"
          >
            Folhas agendadas
          </SubMenuButton>
        ) : null}

        {(me?.company?.conciliatedPaymentSplitFlow &&
          !me?.company?.scheduledSplitFlow) ||
        me?.companyId === TRAMPAY_COMPANY_ID ? (
          <SubMenuButton
            to="/payments/is-conciliated"
            isSelected={pathname === "/payments/is-conciliated"}
            fontSize="md"
          >
            Folhas de conciliação automatizadas
          </SubMenuButton>
        ) : null}
        {me?.company?.scheduledSplitFlow &&
          !me?.company?.conciliatedPaymentSplitFlow && (
            <>
              <Divider />
              <SubMenuButton
                to="/payments/user-debt"
                isSelected={pathname === "/payments/user-debt"}
                fontSize="md"
              >
                Entregadores negativos
              </SubMenuButton>
            </>
          )}
        <Divider />
        <SubMenuButton
          to="/payments/extract"
          isSelected={pathname === "/payments/extract"}
          fontSize="md"
        >
          Extrato de transações
        </SubMenuButton>
      </Flex>
    </Flex>
  );
}
