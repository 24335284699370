import { Flex, Heading } from "@chakra-ui/layout";
import { SubMenuButton } from "./sub-menu-button";
import { useLocation } from "react-router-dom";

export function SubMenu() {
  const { pathname } = useLocation();
  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      flexDir="column"
      bg="whiteAlpha.800"
      borderRadius={8}
      h="min-content"
      gap={4}
      minW="sm"
      shadow="base"
      border="1px"
      borderColor="gray.300"
    >
      <Heading size="md">Configurações</Heading>

      <Flex w="full" justifyContent="flex-start" flexDir="column" gap={2}>
        <SubMenuButton
          to="/settings"
          isSelected={pathname === "/settings"}
          fontSize="md"
        >
          Adiantamentos
        </SubMenuButton>
        <SubMenuButton
          to="payroll-schedule"
          isSelected={pathname.includes("payroll-schedule")}
          fontSize="md"
        >
          Folha Agendada
        </SubMenuButton>
        <SubMenuButton
          to="balance"
          isSelected={pathname.split("/").includes("balance")}
          fontSize="md"
        >
          Saldo
        </SubMenuButton>
        <SubMenuButton
          to="approval"
          isSelected={pathname.split("/").includes("approval")}
          fontSize="md"
        >
          Aprovação automática
        </SubMenuButton>
        <SubMenuButton
          to="login-ifood"
          isSelected={pathname.split("/").includes("login-ifood")}
          fontSize="md"
        >
          Login ifood
        </SubMenuButton>
        <SubMenuButton
          to="perfil"
          isSelected={pathname.split("/").includes("perfil")}
          fontSize="md"
        >
          Atualizar Perfil
        </SubMenuButton>
      </Flex>
    </Flex>
  );
}
