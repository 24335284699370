export type DockIndividual = {
  id: number;
  idRegistration: string;
  name: string;
  document: string;
  motherName: string;
  bankNumber: string;
  branchNumber: string;
  accountNumber: string;
  email: string;
  status:
    | "WAITING_DOCUMENTS"
    | "WAITING_ANALYSIS"
    | "ACTIVE"
    | "DECLINED"
    | "CANCELED";
};

export type DockIndividualDocumentsList = {
  result: {
    documentId: string;
    category: string;
    url: string;
    created: Date;
    invalidDetails?: {
      action: string;
      reasonCode: number;
    };
  }[];
};

export type DockIndividualsList = {
  totalPages: number;
  totalItems: number;
  items: DockIndividual[];
};

export enum DockIndividualStatusColors {
  "WAITING_DOCUMENTS" = "facebook",
  "WAITING_ANALYSIS" = "yellow.600",
  "ACTIVE" = "green",
  "DECLINED" = "red.500",
  "CANCELED" = "red.500",
}
