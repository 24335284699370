import {
  RouteObject,
  createBrowserRouter,
  Outlet,
  redirect,
} from "react-router-dom";
import { RootLayout } from "../layout/RootEnterpriseLayout";
import {
  ChartLine,
  Calendar,
  House,
  CurrencyDollar,
  Planet,
  UploadSimple,
  IdentificationCard,
  MicrosoftExcelLogo,
  Bank,
  List,
  Coins,
  ArchiveBox,
  Gear,
  Receipt,
  ArrowFatLinesUp,
} from "@phosphor-icons/react";
import { Home } from "../screens/support/home";
import { PerformanceAndBalances } from "../screens/enterprises/performance-and-balances";
import { EditDeliveryman } from "../screens/deliverymans/edit";
import { CreateManyDeliverymans } from "../screens/deliverymans/create-many";
import { CreateDeliveryman } from "../screens/deliverymans/create-one";
import { Deliverymans } from "../screens/deliverymans";
import {
  initialCreatePayrollState,
  useCreatePayroll,
  usePayrollStore,
} from "../store/payroll.store";
import { useDebtStore } from "../store/debt.store";
import { useDeliverymansStore } from "../store/deliveryman.store";
import {
  useTransactionsDraftStore,
  useTransactionsStore,
} from "../store/transaction.store";
import { useCurrentUserStore } from "../store/current-user.store";
import { useVerifyReprocessButton } from "../store/components/reprocess-button.store";
import { AdiantamentoEnterprise } from "../screens/enterprises/adiantamentos";
import DashboardIframe from "../components/dashboard-iframe";
import { UserBalanceEnterprise } from "../screens/enterprises/balances";
import { ConfigLayout } from "../layout/ConfigEnterpriseLayout";
import { Profile } from "../screens/profile";
import { Payments } from "../screens/enterprises/payments";
import { AxiosError } from "axios";
import { PaymentDetails } from "../screens/payments/transactions-by-payroll";
import { ListAllTransactions } from "../screens/enterprises/payments/list-all-transactions";
import { SplitPaymentsLayout } from "../layout/split-payments/split-payments-layout";
import { CreatePayrollProcess } from "../screens/enterprises/payments/create-payroll-process";
import { PayrollDraft } from "../screens/enterprises/payments/list-drafts";
import { DraftDetails } from "../screens/enterprises/payments/list-drafts/draft-details";
import { AddBalanceToCompany } from "../screens/enterprises/settings/add-balance-to-company";
import { queryClient } from "../main";
import { DebtsEnterprise } from "../screens/enterprises/debts";
import { DebtDetails } from "../screens/enterprises/debts/details";
import { ShiftAssignments } from "../screens/enterprises/scales/shift-assignments";
import { ShiftAssignmentPreferences } from "../screens/enterprises/scales/shift-assignment-preferences";
import { ShiftAssignmentFormCreate } from "../screens/enterprises/scales/shift-assignments/create-one";
import { ScalesLayout } from "../layout/scales/scales-layout";
import { ScaleConfigurations } from "../screens/enterprises/scales/configurations";
import { PayrollProcessing } from "../screens/payments/list-processing-payroll";
import { DefaultErrorElementEnterprise } from "../screens/error/default-error-enterprise";
import { PayrollScheduled } from "../screens/payments/list-scheduled-payroll";
import { ScheduledPayrollDetails } from "../screens/payments/transactions-drafts-by-payroll";
import { CompaniesFeeEnterprise } from "../screens/enterprises/companiesfee";
import { UserDebt } from "../screens/payments/user-debt";
import { UserPaymentDebtProvider } from "../screens/payments/user-debt/context/user-payment-debt-context.provider";
import { isFranchisor } from "../utils/company-verifications";
import { CompanyDto } from "../dto/company-dto";
import { PayrollConciliated } from "../screens/payments/list-conciliated-payroll";
import { PayrollInstant } from "../screens/payments/list-intants-payroll";
import { LoginJarvisPage } from "../screens/enterprises/settings/login-jarvis-ifood";

export declare type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

export const authenticatedCompaniesRoutes = [
  {
    type: "internal",
    name: "Dashboard",
    index: true,
    icon: <House weight="bold" size={20} />,
    element: <Home />,
    hidden: true,
  },
  {
    type: "internal",
    path: "settings",
    name: "Configurações",
    key: "configurations",
    element: <ConfigLayout />,
    icon: <Gear weight="bold" size={20} />,
    hidden: true,
    children: [
      {
        element: <Profile />,
        hidden: true,
        index: true,
        name: "Perfil",
        key: "perfil",
        icon: <IdentificationCard weight="bold" size={24} />,
      },
      {
        element: <AddBalanceToCompany />,
        hidden: true,
        path: "add-balance",
        name: "Adicionar saldo",
        icon: <Bank weight="bold" size={24} />,
      },
      {
        element: <LoginJarvisPage />,
        hidden: true,
        path: "jarvis-login",
        name: "Adicionar saldo",
      },
    ],
  },
  {
    type: "internal",
    name: "Saldo & performance",
    key: "planilhas",
    path: "saldo-e-performance",
    icon: <UploadSimple weight="bold" size={20} />,
    element: <PerformanceAndBalances />,
    checkPermission: true,
    loader: () => {
      queryClient.value().invalidateQueries({ queryKey: ["permissions"] });

      const permissions = useCurrentUserStore.getState().permissions;

      const sheetsPermission = "planilhas";

      const hasPermission = permissions.find(
        (permission) => permission.key === sheetsPermission
      );

      if (!hasPermission) return redirect("/");

      useVerifyReprocessButton.getState().getVerifyReprocess();

      return null;
    },
  },
  {
    type: "internal",
    name: "Adiantamentos",
    key: "adiantamentos",
    icon: <CurrencyDollar weight="bold" size={20} />,
    path: "adiantamentos",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <AdiantamentoEnterprise />,
      },
    ],
  },
  {
    type: "internal",
    name: "Saldos",
    key: "track-user-balance",
    icon: <Coins weight="bold" size={20} />,
    path: "balances",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <UserBalanceEnterprise />,
      },
    ],
  },
  {
    type: "internal",
    name: "Entregadores",
    key: "deliverymen",
    icon: <List weight="bold" size={20} />,
    path: "/deliverymans",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <Deliverymans />,
      },

      {
        type: "internal",
        path: "create-one",
        element: <CreateDeliveryman />,
      },
      {
        type: "internal",
        path: "create-many",
        element: <CreateManyDeliverymans />,
      },
      {
        type: "internal",
        path: "edit/:id",
        element: <EditDeliveryman />,
        loader: async ({ params }: { params: Params }) => {
          if (!params.id) {
            return redirect("/deliverymans");
          }

          const deliveryman = await useDeliverymansStore
            .getState()
            .getDeliveryman(Number(params.id));
          await queryClient.value().getQueryData(["user-data"]);
          const user = useCurrentUserStore.getState().user;
          if (user?.company?.id !== 1) {
            if (deliveryman.company.id !== user?.company?.id) {
              return redirect("/deliverymans");
            }
          }

          return null;
        },
      },
    ],
  },
  {
    type: "internal",
    name: "Agendamento",
    key: "scales",
    icon: <Calendar weight="bold" size={20} />,
    path: "/scales",
    checkPermission: true,
    element: (
      <>
        <ScalesLayout />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <ShiftAssignments />,
      },
      {
        type: "internal",
        path: "shift_assignment_preferences",
        element: <ShiftAssignmentPreferences />,
      },
      {
        type: "internal",
        path: "configurations",
        element: <ScaleConfigurations />,
      },
    ],
  },
  {
    type: "internal",
    name: "Taxas",
    key: "company-fee",
    icon: <CurrencyDollar weight="bold" size={20} />,
    path: "companiesfee",
    checkPermission: true,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <CompaniesFeeEnterprise />,
      },
    ],
  },
  {
    type: "internal",
    path: "scales/create-one",
    element: <ShiftAssignmentFormCreate />,
  },
  {
    type: "internal",
    name: "Pagamentos",
    key: "split-payments",
    path: "payments",
    icon: <MicrosoftExcelLogo weight="bold" size={20} />,
    element: <SplitPaymentsLayout config="company" />,
    children: [
      {
        type: "internal",
        path: "user-debt",
        element: (
          <UserPaymentDebtProvider>
            <UserDebt />,
          </UserPaymentDebtProvider>
        ),
      },
      {
        type: "internal",
        index: true,
        loader: () => {
          useCreatePayroll.setState({ ...initialCreatePayrollState });
          return null;
        },
        element: <CreatePayrollProcess />,
      },
      {
        type: "internal",
        path: "is-processed/details/:id",
        // loader: async ({
        //   params,
        //   request,
        // }: {
        //   params: Params;
        //   request: Request;
        // }) => {
        //   if (params.id) {
        //     const url = new URL(request.url);
        //     const name = url.searchParams.get("name");
        //     const document = url.searchParams.get("document");
        //     const page = url.searchParams.get("page");
        //     const status = url.searchParams.get("status");

        //     await Promise.all([
        //       useCurrentUserStore.getState().refreshData(),
        //       usePayrollStore.getState().getPayroll(+params.id),
        //     ]);
        //     const user = useCurrentUserStore.getState().user;
        //     const payroll = usePayrollStore.getState().payroll;

        //     if (
        //       user?.company?.id !== 1 &&
        //       user?.company?.id !== payroll?.company_id
        //     ) {
        //       return redirect("/payments");
        //     }

        //     try {
        //       if (!name || !page || !document || !status) {
        //         await useTransactionsStore
        //           .getState()
        //           .getTransactionsByPayrollId({
        //             page: 1,
        //             limit: 10,
        //             id: params.id,
        //           });
        //       }

        //       await useTransactionsStore.getState().getTransactionsByPayrollId({
        //         filters: {
        //           name: name || undefined,
        //           document: document || undefined,
        //           status: status || undefined,
        //         },
        //         page: page ? +page : 1,
        //         limit: 10,
        //         id: params.id,
        //       });
        //     } catch (error) {
        //       if (error instanceof AxiosError) {
        //         throw error.message;
        //       }
        //     }
        //   }
        //   return null;
        // },
        element: <PaymentDetails />,
      },
      {
        type: "internal",
        path: "details/:id",
        loader: async ({
          params,
          request,
        }: {
          params: Params;
          request: Request;
        }) => {
          if (params.id) {
            const url = new URL(request.url);
            const name = url.searchParams.get("name");
            const document = url.searchParams.get("document");
            const page = url.searchParams.get("page");
            const status = url.searchParams.get("status");

            await Promise.all([
              useCurrentUserStore.getState().refreshData(),
              usePayrollStore.getState().getPayroll(+params.id),
            ]);
            const user = useCurrentUserStore.getState().user;
            const payroll = usePayrollStore.getState().payroll;

            if (
              user?.company?.id !== 1 &&
              user?.company?.id !== payroll?.company_id
            ) {
              return redirect("/payments");
            }

            try {
              if (!name || !page || !document || !status) {
                await useTransactionsStore
                  .getState()
                  .getTransactionsByPayrollId({
                    page: 1,
                    limit: 10,
                    id: params.id,
                  });
              }

              await useTransactionsStore.getState().getTransactionsByPayrollId({
                filters: {
                  name: name || undefined,
                  document: document || undefined,
                  status: status || undefined,
                },
                page: page ? +page : 1,
                limit: 10,
                id: params.id,
              });
            } catch (error) {
              if (error instanceof AxiosError) {
                throw error.message;
              }
            }
          }
          return null;
        },
        element: <PaymentDetails />,
      },
      {
        type: "internal",
        path: "scheduled/details/:id",
        loader: async ({
          params,
          request,
        }: {
          params: Params;
          request: Request;
        }) => {
          if (params.id) {
            const url = new URL(request.url);
            const name = url.searchParams.get("name");
            const document = url.searchParams.get("document");
            const page = url.searchParams.get("page");
            const status = url.searchParams.get("status");

            await Promise.all([
              useCurrentUserStore.getState().refreshData(),
              usePayrollStore.getState().getPayroll(+params.id),
            ]);
            const user = useCurrentUserStore.getState().user;
            const payroll = usePayrollStore.getState().payroll;

            if (
              user?.company?.id !== 1 &&
              user?.company?.id !== payroll?.company_id &&
              !isFranchisor(user?.company as CompanyDto)
            ) {
              return redirect("/payments");
            }

            try {
              if (!name || !page || !document || !status) {
                await useTransactionsDraftStore
                  .getState()
                  .getTransactionsDraftByCompanyAndPayrollId({
                    page: 1,
                    limit: 10,
                    id: params.id,
                  });
              }

              await useTransactionsDraftStore
                .getState()
                .getTransactionsDraftByCompanyAndPayrollId({
                  filters: {
                    name: name || undefined,
                    document: document || undefined,
                  },
                  page: page ? +page : 1,
                  limit: 10,
                  id: params.id,
                });
            } catch (error) {
              if (error instanceof AxiosError) {
                throw error.message;
              }
            }
          }
          return null;
        },
        element: <ScheduledPayrollDetails />,
      },
      {
        type: "internal",
        path: "transactions-drafts",
        element: <ScheduledPayrollDetails />,
      },
      {
        type: "internal",
        path: "transactions",
        element: <PaymentDetails />,
      },
      {
        type: "internal",
        path: "extract",
        element: <ListAllTransactions />,
      },
      {
        type: "internal",
        path: "create-transactions",
        loader: () => {
          useCreatePayroll.setState({ ...initialCreatePayrollState });
          return null;
        },
        element: <CreatePayrollProcess />,
      },
      {
        type: "internal",
        path: "create-transactions/confirmation",
        element: <CreatePayrollProcess />,
      },
      {
        type: "internal",
        path: "create-transactions/:id",
        loader: async ({ params }: { params: Params }) => {
          if (!params.id) {
            redirect("/");
            return null;
          }
          if (!useCreatePayroll.getState().isReprocessingPayroll) {
            try {
              await useCreatePayroll.getState().getPayrollDraftById(params.id);
            } catch (error) {
              console.log(error);
              try {
                await useCreatePayroll
                  .getState()
                  .getNotSavedPayrollById(params.id);
              } catch (error) {
                console.log(error);

                throw error;
              }
            }
          }
          return null;
        },
        element: <CreatePayrollProcess />,
      },
      {
        type: "internal",
        path: "is-processed",
        element: <Payments />,
        // loader: async ({ request }: { request: Request }) => {
        //   let user = useCurrentUserStore.getState().user;

        //   if (!user) {
        //     await queryClient.value().refetchQueries(["user-data"]);
        //     user = useCurrentUserStore.getState().user;
        //   }

        //   const url = new URL(request.url);

        //   const endDate = url.searchParams.get("endDate");
        //   const startDate = url.searchParams.get("startDate");
        //   const page = url.searchParams.get("page");
        //   const companyId = user?.company?.id;

        //   try {
        //     const response = await usePayrollStore.getState().getSheets({
        //       filters: {
        //         startDate: startDate || undefined,
        //         endDate: endDate || undefined,
        //       },
        //       page: page ? +page : 1,
        //       limit: 10,
        //       companyId,
        //     });
        //     for (const payroll of response) {
        //       const transactions = await useTransactionsStore
        //         .getState()
        //         .getTransactionsByPayrollId({ id: `${payroll.id}` });

        //       const isTransactionError = transactions.some(
        //         (transaction) => transaction.status === "error"
        //       );
        //       if (isTransactionError) {
        //         payroll.hasError = true;
        //       }
        //     }
        //     usePayrollStore.getState().setPayroll(response);
        //     return null;
        //   } catch (error) {
        //     if (error instanceof AxiosError) {
        //       throw error.message;
        //     }
        //   }

        //   return null;
        // },
      },
      {
        type: "internal",
        path: "transactions-draft",
        element: <PayrollDraft />,
        // loader: async ({ request }: { request: Request }) => {
        //   await useCurrentUserStore.getState().refreshData();
        //   const user = useCurrentUserStore.getState().user;

        //   const url = new URL(request.url);

        //   const endDate = url.searchParams.get("endDate");
        //   const startDate = url.searchParams.get("startDate");
        //   const page = url.searchParams.get("page");
        //   const companyId = user?.company?.id;

        //   try {
        //     if (!startDate || !page || !endDate) {
        //       await usePayrollStore.getState().getSheetsDraft({
        //         page: 1,
        //         limit: 10,
        //         companyId,
        //       });
        //     }
        //     await usePayrollStore.getState().getSheetsDraft({
        //       filters: {
        //         startDate: startDate || undefined,
        //         endDate: endDate || undefined,
        //       },
        //       page: page ? +page : 1,
        //       limit: 10,
        //       companyId,
        //     });
        //   } catch (error) {
        //     if (error instanceof AxiosError) {
        //       throw error.message;
        //     }
        //   }

        //   return null;
        // },
      },
      {
        type: "internal",
        path: "is-processing",
        element: <PayrollProcessing />,
        // loader: async () => {
        //   await useCurrentUserStore.getState().refreshData();
        //   const user = useCurrentUserStore.getState().user;

        //   const companyId = user?.company?.id;

        //   try {
        //     if (companyId) {
        //       await usePayrollStore.getState().getProcessingPayroll(+companyId);
        //     }
        //   } catch (error) {
        //     if (error instanceof AxiosError) {
        //       throw error.message;
        //     }
        //   }

        //   return null;
        // },
      },
      {
        type: "internal",
        path: "is-scheduled",
        element: <PayrollScheduled />,
      },
      {
        type: "internal",
        path: "is-conciliated",
        element: <PayrollConciliated />,
      },
      {
        type: "internal",
        path: "is-instant",
        element: <PayrollInstant />,
      },
      {
        type: "internal",
        path: "transactions-draft/:id",
        loader: async ({
          params,
          request,
        }: {
          params: Params;
          request: Request;
        }) => {
          if (params.id) {
            const url = new URL(request.url);
            const name = url.searchParams.get("name");
            const document = url.searchParams.get("document");
            const page = url.searchParams.get("page");

            await Promise.all([
              useCurrentUserStore.getState().refreshData(),
              usePayrollStore.getState().getPayroll(+params.id),
            ]);
            const user = useCurrentUserStore.getState().user;
            const payroll = usePayrollStore.getState().payroll;

            if (
              user?.company?.id !== 1 &&
              user?.company?.id !== payroll?.company_id
            ) {
              return redirect("/transactions-draft");
            }

            try {
              if (!name || !page || !document) {
                await useTransactionsDraftStore
                  .getState()
                  .getTransactionsDraftByCompanyAndPayrollId({
                    page: page ? +page : 1,
                    limit: 10,
                    id: params.id,
                  });
              }

              await useTransactionsDraftStore
                .getState()
                .getTransactionsDraftByCompanyAndPayrollId({
                  filters: {
                    name: name || undefined,
                    document: document || undefined,
                  },
                  page: page ? +page : 1,
                  limit: 10,
                  id: params.id,
                });
            } catch (error) {
              if (error instanceof AxiosError) {
                throw error.message;
              }
            }
          }
          return null;
        },
        element: <DraftDetails />,
      },
    ],
  },
  {
    type: "internal",
    name: "Débitos",
    key: "debts",
    path: "debts",
    icon: <ArchiveBox weight="bold" size={20} />,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        type: "internal",
        index: true,
        element: <DebtsEnterprise />,
      },
      {
        type: "internal",
        path: "details/:id",
        loader: async ({ params }: { params: Params }) => {
          if (params.id) {
            try {
              await Promise.all([
                useCurrentUserStore.getState().refreshData(),
                useDebtStore.getState().getDebtById(+params.id),
              ]);
              const user = useCurrentUserStore.getState().user;
              const debt = useDebtStore.getState().debt;
              if (
                user?.companyId !== 1 &&
                user?.company?.id !== debt?.company.id
              ) {
                return redirect("/debts");
              }

              return null;
            } catch (error) {
              if (error instanceof AxiosError) {
                throw error.message;
              }
            }
          }
          return null;
        },
        element: <DebtDetails />,
      },
    ],
  },
  {
    type: "internal",
    name: "Dashboard usuários",
    key: "dashboard-usuarios",
    icon: <ChartLine weight="bold" size={20} />,
    path: "dashboard-usuarios",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Upload Relatórios",
    key: "upload-relatorios",
    icon: <ArrowFatLinesUp weight="bold" size={20} />,
    path: "upload-relatorios",
    element: <DashboardIframe />,
    checkPermission: true,
  },

  {
    type: "external",
    name: "Área interna",
    key: "area-interna",
    icon: <Planet weight="bold" size={20} />,
    path: "area-interna",
    element: null,
  },
  {
    type: "internal",
    name: "Indicadores gerais",
    key: "bidashgeral",
    icon: <ChartLine weight="bold" size={20} />,
    path: "bidashgeral",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Dashboard IF",
    key: "dashboard-adiantamentos",
    icon: <ChartLine weight="bold" size={20} />,
    path: "dashboard-adiantamentos",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Indicadores",
    key: "bidashdonos",
    icon: <ChartLine weight="bold" size={20} />,
    path: "bidashdonos",
    element: <DashboardIframe />,
    checkPermission: true,
  },
  {
    type: "internal",
    name: "Extratos",
    path: "/extract",
    key: "extract",
    icon: <Receipt weight="bold" size={20} />,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <ListAllTransactions />,
        // loader: async ({ request }: { request: Request }) => {
        //   await useCurrentUserStore.getState().refreshData();
        //   const urlRequest = new URL(request.url);

        //   const getAllSearchParams = new URLSearchParams([
        //     ...Array.from(urlRequest.searchParams.entries()),
        //   ]);

        //   try {
        //     const response = await getWithAuth(
        //       `api/v1/transaction/extract?${getAllSearchParams}`
        //     );

        //     return response?.data;
        //   } catch (error) {
        //     if (error instanceof AxiosError) {
        //       throw error.message;
        //     }
        //   }

        //   return null;
        // },
      },
    ],
  },
];

export const authenticatedCompaniesRouter = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: authenticatedCompaniesRoutes,
    errorElement: <DefaultErrorElementEnterprise />,
  },
] as RouteObject[]);
