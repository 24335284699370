import { Box } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { postWithAuth } from "../../../../../services/basicService";
import { AxiosError } from "axios";

const signInFormSchema = z.object({
  email: z.string().email("Por favor insira um email válido"),
  code: z
    .string()
    .length(6, "Por favor digite uma senha com pelo menos 6 dígitos"),
});

type signInFormInputs = z.infer<typeof signInFormSchema>;

export function IfoodLoginForm({ companyId }: { companyId: number }) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const loginTrampayIfood = useMutation({
    mutationFn: (data: signInFormInputs) => {
      return postWithAuth(
        `/api/v1/ifood_integration/jarvis_auth/trampay-account/${companyId}`,
        {
          ...data,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["get-company", `${companyId}`]);

      reset();

      toast({
        title: "Login feito com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError: (err: AxiosError) => {
      console.log(err);
      toast({
        title: "Erro de login",
        description: "Não foi possível fazer login",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleLogin = (data: signInFormInputs) => {
    if (loginTrampayIfood.isLoading) return;

    loginTrampayIfood.mutate(data);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<signInFormInputs>({
    defaultValues: {
      email: undefined,
      code: undefined,
    },
    resolver: zodResolver(signInFormSchema),
  });

  return (
    <Box
      as="form"
      display="flex"
      flexDir="row"
      gap={8}
      w="full"
      onSubmit={handleSubmit(handleLogin)}
    >
      <FormControl isInvalid={!!errors.email}>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          id="email"
          size="lg"
          _placeholder={{
            color: "gray.500",
          }}
          {...register("email")}
          title="Email"
          placeholder="example@email.com"
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.code}>
        <FormLabel htmlFor="code">Código do Jarvis</FormLabel>
        <Input
          id="code"
          size="lg"
          _placeholder={{
            color: "gray.500",
          }}
          {...register("code")}
          title="code"
          placeholder="Digite o código de 6 dígitos enviado no email"
          maxLength={6}
        />
        <FormErrorMessage>
          {errors.code && errors.code.message}
        </FormErrorMessage>
      </FormControl>
      <Button
        alignSelf="flex-end"
        isLoading={isSubmitting}
        type="submit"
        size="lg"
        w="full"
        bg="green.400"
        fontWeight="bold"
        _hover={{
          bg: "green.700",
        }}
        width="max-content"
        px={8}
      >
        Login
      </Button>
    </Box>
  );
}
