import { Heading, Text, VStack } from "@chakra-ui/layout";
import { CheckCircle } from "@phosphor-icons/react";
import { useCreatePayroll } from "../../../../store/payroll.store";

export function DoneCreatePayrollTransactions() {
  const isScheduledPayroll = useCreatePayroll(
    (state) => state.isScheduledPayroll
  );

  return (
    <>
      {isScheduledPayroll ? (
        <VStack w="full" gap={8} pb={16}>
          <Heading size="md">
            Sua folha de pagamento foi agendada para o próximo repasse.
          </Heading>
          <CheckCircle color="green" size={92} weight="fill" />
          <Text>
            Acesse a opção Folhas agendadas no menu lateral para verificar os
            agendamentos.
          </Text>
          <Text>
            Atenção: os pagamentos agendados são feitos através de sua conta
            repasse.
          </Text>
        </VStack>
      ) : (
        <VStack w="full" gap={8} pb={16}>
          <Heading size="md">
            Sua folha de pagamento está sendo processada.
          </Heading>
          <CheckCircle color="green" size={92} weight="fill" />
          <Text>Em breve enviaremos um e-mail com os resultados.</Text>
          <Text>
            Acesse a opção Folha em processamento no menu lateral para verificar
            o andandamento das transações.
          </Text>
        </VStack>
      )}
    </>
  );
}
