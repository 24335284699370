import { create } from "zustand";
import { AdvanceUserData, AdvanceUserDto } from "../dto/advance-user-dto";
import { ApproveProposal } from "../dto/approve-proposal-dto";
import { CancelProposal } from "../dto/cancel-proposal-dto";
import {
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../services/basicService";

interface AdvanceUserStore {
  advancesUsers: AdvanceUserDto[];
  advanceUser: AdvanceUserDto | null;
  advanceUserData: AdvanceUserData;
  advanceUserByEnterprise: AdvanceUserData;

  getAdvancesUsers: () => Promise<AdvanceUserDto[]>;
  getAdvanceUserData: (url: string) => Promise<AdvanceUserData | null>;
  getAdvanceUserDataByEnterprise: (
    url: string
  ) => Promise<AdvanceUserData | null>;
  getAdvanceUser: (id: string) => Promise<AdvanceUserDto | null>;
  approvalProposal: (url: string, params: ApproveProposal) => Promise<void>;
  cancelProposal: (url: string, params: CancelProposal) => Promise<void>;
}

export const useAdvanceUserStore = create<AdvanceUserStore>((set) => ({
  advancesUsers: [],
  advanceUser: null,
  advanceUserData: {} as AdvanceUserData,
  advanceUserByEnterprise: {} as AdvanceUserData,

  getAdvancesUsers: async () => {
    const response = await getWithAuth(
      `/api/v1/userAdvance/proposal?page=40&limit=50&status=validated`
    );

    if (response?.data.result.length > 0) {
      const advancesUsers: AdvanceUserDto[] = response?.data.result;

      set({ advancesUsers });

      return advancesUsers;
    }

    return [] as AdvanceUserDto[];
  },

  getAdvanceUser: async (id: string) => {
    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    const response = await getWithAuth(
      `/api/v1/userAdvance/new-by-id/${id}?timezoneOffset=${timezoneOffset}`
    );

    if (response?.data) {
      const advanceUser: AdvanceUserDto = response.data;
      set({ advanceUser });
      return advanceUser;
    }
    return null;
  },

  getAdvanceUserData: async (url: string) => {
    const response = await getWithAuth(url);
    if (response?.data) {
      const advanceUserData: AdvanceUserData = response?.data;

      set({ advanceUserData });

      return advanceUserData;
    }

    return {} as AdvanceUserData;
  },

  getAdvanceUserDataByEnterprise: async (url: string) => {
    const response = await getWithAuth(url);
    if (response?.data) {
      const advanceUserByEnterprise: AdvanceUserData = response?.data;
      set({ advanceUserByEnterprise });

      return advanceUserByEnterprise;
    }

    return {} as AdvanceUserData;
  },

  approvalProposal: async (url: string, params: ApproveProposal) => {
    await postWithAuth(url, params);
  },

  cancelProposal: async (url: string, params: CancelProposal) => {
    await patchWithAuth(url, params);
  },
}));
