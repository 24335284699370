import { VStack, Stack, HStack, Flex, Box } from "@chakra-ui/layout";
import {
  Text,
  Button,
  useToast,
  useColorMode,
  Input,
  FormLabel,
  FormControl,
  FormErrorMessage,
  IconButton,
  Textarea,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from "@chakra-ui/react";
import { Trash } from "@phosphor-icons/react";
import { useForm, useFieldArray } from "react-hook-form";
import { useBlockedDeliverymanStore } from "../../../../../store/deliveryman-blocked.store";
import {
  BlockIfoodIdDto,
  UnblockIfoodIdDto,
} from "../../../../../dto/deliveryman-blocked-dto";
import { AxiosError } from "axios";

type FormBlockIdValues = {
  ids: {
    ifoodIds: string[];
  }[];
  reason: string;
};

const emptyId = {
  ifoodIds: [],
};

export function BlockIdTab() {
  const blockedIfoodIds = useBlockedDeliverymanStore(
    (state) => state.blockedIfoodIds
  );
  const getBlockedIds = useBlockedDeliverymanStore(
    (state) => state.getBlockedIfoodIds
  );
  const blockIfoodIds = useBlockedDeliverymanStore(
    (state) => state.blockOneOrMoreIfoodIds
  );
  const unblockIfoodId = useBlockedDeliverymanStore(
    (state) => state.unblockIfoodId
  );

  const toast = useToast();
  const { colorMode } = useColorMode();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormBlockIdValues>({
    defaultValues: {
      ids: [{ ifoodIds: [""] }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "ids",
    control,
    rules: {
      required: "Adicione ao menos um Id do Ifood.",
    },
  });

  const handleBlock = async (data: FormBlockIdValues) => {
    try {
      console.log(data);
      const ids = {
        ifoodIds: data.ids.map((ids) => ids.ifoodIds),
        reason: data.reason,
      } as unknown as BlockIfoodIdDto;

      await blockIfoodIds(ids);

      await getBlockedIds();
      toast({
        title: "Bloqueio concluido",
        description: `Os Ids foram bloqueados com sucesso.`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setValue("ids", [{ ifoodIds: [""] }]);
      setValue("reason", "");
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 422) {
        toast({
          title: "Erro ao efetuar o bloqueio",
          description: error.response.data.message[0],
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  const handleUnblock = async (id: number, params: UnblockIfoodIdDto) => {
    console.log(params);
    try {
      await unblockIfoodId(id, params);
      await getBlockedIds();
      toast({
        title: "Desbloqueio concluido",
        description: `O id do iFood foi desbloqueado com sucesso`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao efetuar o desbloqueio",
        description: "Não foi possível desbloquear o Id do iFood",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <VStack alignItems="flex-start" w="full" gap={8}>
      <Flex w="full" justifyContent="space-between" gap={8}>
        <FormControl
          as="fieldset"
          w="40%"
          onSubmit={handleSubmit(handleBlock)}
          isInvalid={!!errors.ids}
        >
          <FormLabel as="legend" fontSize={"2xl"} mb={4}>
            Ferramenta de bloqueio por Id do iFood
          </FormLabel>
          <Stack>
            {fields.map((field, index) => (
              <HStack key={field.id} alignItems="flex-start">
                <Input
                  type="text"
                  {...register(`ids.${index}.ifoodIds`, {
                    required: "Digite o id",
                  })}
                  bg="whiteAlpha.800"
                  placeholder="O formato do id do iFood é um uuid"
                />
                {errors.ids?.[index]?.ifoodIds && (
                  <FormErrorMessage>
                    {errors.ids?.[index]?.ifoodIds?.message}
                  </FormErrorMessage>
                )}
                <IconButton
                  aria-label="campo para apagar input de id"
                  icon={<Trash />}
                  onClick={() => remove(index)}
                />
              </HStack>
            ))}
          </Stack>
          <VStack w="full" gap={4} marginTop={4} width="full">
            <Button
              type="button"
              onClick={() => append(emptyId)}
              width="full"
              colorScheme="green"
            >
              Adicionar Id do iFood
            </Button>
            <FormLabel as="legend" fontSize={"2xl"}>
              Qual o motivo do bloqueio?
            </FormLabel>
            <Textarea
              placeholder="*Opcional"
              {...register(`reason`)}
              bg="whiteAlpha.800"
            />
            <Text fontSize="2xl">
              Após selecionar os Ids, faça o bloqueio clicando no botão abaixo:
            </Text>
            <Button
              mt={4}
              colorScheme="green"
              type="submit"
              onClick={handleSubmit(handleBlock)}
              width="full"
              isDisabled={!isValid}
            >
              Bloquear Ids do iFood
            </Button>
          </VStack>
        </FormControl>
        <Box display={"flex"} flex={1} flexDir={"column"} gap={4}>
          <Text fontSize="2xl" fontWeight="semibold">
            Ids bloqueados
          </Text>
          <TableContainer
            w="full"
            p={8}
            border="1px"
            flex={1}
            borderRadius={8}
            bg={colorMode === "dark" ? "gray.700" : "white"}
            borderColor={colorMode === "dark" ? "" : "gray.300"}
            shadow="base"
          >
            <HStack gap={1} marginBottom={1}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Id do iFood</Th>
                    <Th>Desbloquear</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {blockedIfoodIds.map((ifoodId) => {
                    return (
                      <Tr fontWeight={600} key={ifoodId.id}>
                        <Td>{ifoodId.idEntregador}</Td>
                        <Td>
                          <Button
                            size="sm"
                            onClick={() =>
                              handleUnblock(ifoodId.id, { isBlocked: false })
                            }
                          >
                            Desbloquear
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </HStack>
          </TableContainer>
        </Box>
      </Flex>
    </VStack>
  );
}
