/* eslint-disable camelcase */
import { Flex, HStack, Heading, VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
} from "@chakra-ui/react";
import { getWithAuth } from "../../../services/basicService";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Pagination } from "../../../components/pagination";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import dayjs from "dayjs";
import { LoadingLogo } from "../../../components/loading";
import { convertCentsToReais } from "../../../utils/convert-money";
import { UserCompanyFee } from "../../../dto/company-fee-dto";
import { useSearchParams } from "react-router-dom";

const filterCompanyFeeSchema = z.object({
  companyId: z.string(),
  dateRange: z.object({
    to: z.date().optional(),
    from: z.date().optional(),
  }),
});

type filterCompanyFeeInputs = z.infer<typeof filterCompanyFeeSchema>;

export function CompaniesFee() {
  const { colorMode } = useColorMode();

  const [searchParams, setSearchParams] = useSearchParams();
  // adicionar ao zod as validacoes depois
  const startTransferDate = searchParams.get("startTransferDate");
  const endTransferDate = searchParams.get("endTransferDate");
  const companyId = searchParams.get("companyId");
  const limit = searchParams.get("limit");
  const page = searchParams.get("page");
  const formattedStartDate = startTransferDate
    ? dayjs(startTransferDate).toDate()
    : undefined;
  const formattedEndDate = endTransferDate
    ? dayjs(endTransferDate).toDate()
    : undefined;

  const {
    handleSubmit,
    register,
    control,
    formState: { isDirty },
  } = useForm<filterCompanyFeeInputs>({
    defaultValues: {
      dateRange: {
        from: formattedStartDate,
        to: formattedEndDate,
      },
      companyId: companyId !== null ? String(companyId) : undefined,
    },
  });

  async function onSubmit(data: filterCompanyFeeInputs) {
    setSearchParams((prev) => {
      prev.delete("startTransferDate");
      prev.delete("endTransferDate");
      prev.delete("companyId");

      if (data?.dateRange?.from) {
        prev.set(
          "startTransferDate",
          dayjs(data?.dateRange?.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.dateRange?.to) {
        prev.set(
          "endTransferDate",
          dayjs(data?.dateRange?.to).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.companyId) {
        prev.set("companyId", data?.companyId.toString());
      }
      return prev;
    });
  }

  const {
    data: companiesFee,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: [
      "get-company-fees",
      startTransferDate,
      endTransferDate,
      companyId,
      limit,
      page,
    ],
    queryFn: async () => {
      const queryParams = searchParams.toString();
      const response = await getWithAuth(`/api/v1/company_fees?${queryParams}`);
      return response?.data as UserCompanyFee;
    },
  });

  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as { name: string; id: string }[];
    },
  });

  const handleItemsPerPage = (limit: string) => {
    setSearchParams((prev) => {
      prev.set("limit", String(limit));
      return prev;
    });
    handlePageChange(1);
  };

  const handlePageChange = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", page.toString());
      return prev;
    });
  };

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Taxas
      </Heading>
      <TableContainer
        w="full"
        flex={1}
        p={8}
        gap={4}
        display="flex"
        flexDir="column"
        border="1px"
        borderRadius={8}
        bg={colorMode === "dark" ? "gray.700" : "whiteAlpha.800"}
        borderColor={colorMode === "dark" ? "" : "gray.300"}
        shadow="base"
      >
        <Flex
          as="form"
          flexDir="column"
          gap={4}
          id="form"
          w="full"
          p={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            w="full"
            justifyContent="center"
            alignItems="center"
            gap={8}
          >
            <FormControl>
              <FormLabel as="legend">Empresa</FormLabel>
              <Select
                variant="filled"
                {...register("companyId")}
                size="md"
                w="full"
              >
                <option value="">Todos</option>
                {companies?.map((company) => (
                  <option value={company.id} key={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl as="fieldset" w="20%">
              <FormLabel as="legend">Data do Repasse</FormLabel>
              <Popover placement="right-end">
                <PopoverTrigger>
                  <Button w="100%">
                    {!startTransferDate
                      ? "Escolha a sua data"
                      : !endTransferDate
                        ? startTransferDate
                        : `${startTransferDate.replaceAll(
                            "-",
                            "/"
                          )} a ${endTransferDate.replaceAll("-", "/")}`}
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent bg="white" p={2} w="max-content">
                    <PopoverBody>
                      <Controller
                        control={control}
                        name="dateRange"
                        render={({ field }) => (
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            initialFocus={true}
                            selected={field.value as DateRange}
                            onSelect={field.onChange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        )}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </FormControl>
            <Button
              type="submit"
              form="form"
              w="max-content"
              _hover={{
                bg: "green.700",
              }}
              px={8}
              size="md"
              alignSelf="flex-end"
              isDisabled={isFetching || !isDirty}
              isLoading={isFetching}
            >
              Filtrar
            </Button>
          </Flex>
        </Flex>
        <Table>
          <Thead>
            <Tr>
              <Th textAlign="center"> Empresa</Th>
              <Th isNumeric>Saldo</Th>
              <Th textAlign="center">Data de repasse</Th>
              <Th textAlign="center">Data de criação</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading ? (
              companiesFee?.result.map((companyFee) => {
                return (
                  <Tr fontWeight={600} key={companyFee.id}>
                    <Td textAlign="center">{companyFee.company.name}</Td>
                    <Td isNumeric>{convertCentsToReais(companyFee.amount)}</Td>
                    <Td textAlign="center">
                      {dayjs(companyFee.transferDate).format("DD/MM/YYYY")}
                    </Td>
                    <Td textAlign="center">
                      {dayjs(companyFee.createdAt).format("DD/MM/YYYY HH:mm")}
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Td colSpan={4} textAlign="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  h="full"
                >
                  <LoadingLogo />
                </Flex>
              </Td>
            )}
          </Tbody>
        </Table>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={page ? Number(page) : 1}
            setPage={
              handlePageChange as React.Dispatch<React.SetStateAction<number>>
            }
            totalPages={companiesFee?.totalPages || 1}
          />
          <Select
            variant="filled"
            size="md"
            w="100px"
            onChange={(e) => handleItemsPerPage(e.target.value)}
          >
            <option value={"10"}>10</option>
            <option value={"25"}>25</option>
            <option value={"50"}>50</option>
          </Select>
        </HStack>
      </TableContainer>
    </VStack>
  );
}
