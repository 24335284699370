import { VStack } from "@chakra-ui/layout";
import { Collapse, Fade } from "@chakra-ui/react";
import { HeadingSyncCsv } from "./components/heading";
import { UploadCsv } from "./components/upload-file";
import { useSyncCsvContext } from "./context/sync-csv.context";
import CsvTable from "./components/table";
import { FunctionsComponent } from "./components/functions";

const Component = () => {
  const { csvData } = useSyncCsvContext();

  return (
    <VStack w="100%">
      <HeadingSyncCsv />

      {csvData?.length === 0 && (
        <Fade in={csvData.length === 0}>
          <UploadCsv />
        </Fade>
      )}

      <Collapse in={csvData?.length > 0} animateOpacity>
        <FunctionsComponent />
        <CsvTable />
      </Collapse>
    </VStack>
  );
};

export const SyncCsv = Component;
