import { Shift } from "../../dto/shift-dto";
import { Service, useBaseCrud } from "./base-crud";
import { useCachedService } from "./use-cached-service";

const baseURL = "/api/v1/shifts";

export const useShiftService = () => {
  return {
    ...useBaseCrud<Shift>(baseURL),
  };
};

export function useShiftCachedService() {
  const shiftService = useShiftService() as unknown as Service<Shift>;

  return useCachedService(shiftService, "shifts");
}
