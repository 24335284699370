import { Box, HStack, Heading, VStack } from "@chakra-ui/layout";
import {
  Button,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import { CaretLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { CreateCompanyStep1 } from "./step-1";
import { CreateCompanyStep2 } from "./step-2";

const steps = [
  { title: "Preencher dados", description: "Preencha os dados da empresa" },
  {
    title: "Adicionar permissões",
    description: "Adicione as permissões para a empresa criada",
  },
];

export function CreateCompany() {
  const navigate = useNavigate();

  const { activeStep, goToNext } = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <VStack gap={4} w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Criar Empresa
        </Heading>
      </HStack>

      <Stepper size="lg" index={activeStep} colorScheme="green">
        {steps.map((step, index) => (
          <Step
            key={index}
            style={{ backgroundColor: "white", padding: 16, borderRadius: 16 }}
          >
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <VStack
        w="full"
        h="full"
        gap={6}
        p={8}
        borderRadius={8}
        bg="whiteAlpha.800"
      >
        {activeStep === 0 ? (
          <CreateCompanyStep1 goToNext={goToNext} />
        ) : (
          <CreateCompanyStep2 />
        )}
      </VStack>
    </VStack>
  );
}
