/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, HStack } from "@chakra-ui/layout";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { GenericList } from "../../../../components/scales/generic-list";
import {
  useShiftAssignmentCachedService,
  useShiftAssignmentService,
} from "../../../../services/api/shift-assignments";
import { ShiftAssignmentStatus as statuses } from "../../../../enums/shift-assignment-status";
import { ShiftAssignment } from "../../../../dto/shift-assignment-dto";
import { ReactNode, useEffect, useRef, useState } from "react";
import { format, formatDate } from "../../../../utils/format-date";
import { SearchShiftAssignment } from "./search";
import { useSubplazaCachedService } from "../../../../services/api/subplazas";
import { useShiftCachedService } from "../../../../services/api/shifts";
import { Link, useSearchParams } from "react-router-dom";
import { DragDrop, IRef } from "../../../../components/forms/fileUpload";
import { AxiosError } from "axios";
import { Subplaza } from "../../../../dto/subplaza-dto";
import { Shift } from "../../../../dto/shift-dto";
import { Pagination } from "../../../../components/pagination";
import { useShiftAssignmentPreferenceCachedService } from "../../../../services/api/shift-assignment-preferences";
import { ShiftAssignmentPreference } from "../../../../dto/shift-assignment-preference-dto";
import { usePapaParse } from "react-papaparse";
import { SubmenuSearch } from "../../../../components/scales/submenu-filter";
import { ExportScaledFilter } from "./export-scaled-filter";
import dayjs from "dayjs";
import { UploadEscalesResponseErrors } from "../../../../types/upload.type";
import { SendMessageButton } from "./send-message-button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { RowAddVacanciesComponent } from "./components/row-add-vacancies";
import { RowActionsShiftAssignmentComponent } from "./components/row-actions";
import {
  formatCreatedAt,
  formatDateScaled,
  formatUpdatedAt,
} from "./functions/format-functions";
import { mapperTranslate } from "./functions/mapper-translate";
import { AddButton } from "./components/add-button";

const updateVacanciesSchema = z.object({
  vacancies: z.number(),
  shiftAssignmentId: z.number(),
});

type updateVacanciesType = z.infer<typeof updateVacanciesSchema>;

export function ShiftAssignments() {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const { useQueryAction: useShiftQueryAction } = useShiftCachedService();
  const { useQueryAction: useSubplazaQueryAction } = useSubplazaCachedService();

  const shiftQuery = useShiftQueryAction("filter", {});

  const subplazaQuery = useSubplazaQueryAction("filter", {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const { register, setValue, handleSubmit, reset } =
    useForm<updateVacanciesType>({
      mode: "onChange",
    });

  const { jsonToCSV } = usePapaParse();

  const ShiftName = (row: Record<string, string | number>) => {
    const sa = row as unknown as ShiftAssignment;
    return getShiftById(sa.shiftId as number)?.name;
  };

  const SubplazaName = (row: Record<string, string | number>) => {
    const sa = row as unknown as ShiftAssignment;
    return getSubplazaById(sa.subplazaId as number)?.name;
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function getShiftById(shiftId: number): Shift | null {
    return shiftQuery.data?.find((shift) => shift.id === shiftId) || null;
  }

  function getSubplazaById(subplazaId: number): Subplaza | null {
    return (
      subplazaQuery.data?.find((subplaza) => subplaza.id === subplazaId) || null
    );
  }

  const columns = [
    { header: "ID", accessor: "id", dataTransform: undefined },
    { header: "Status", accessor: "status", dataTransform: mapperTranslate },
    { header: "Turno", accessor: "shiftId", dataTransform: ShiftName },
    {
      header: "Data do agendamento",
      accessor: "dateScale",
      dataTransform: formatDateScaled,
    },
    { header: "Agendados", accessor: "scaled", dataTransform: undefined },
    {
      header: "Vagas",
      accessor: "maxDeliveryman",
      dataTransform: (row: Record<string, string | number>) => (
        <RowAddVacanciesComponent
          handleOpenModal={handleOpenModal}
          row={row}
          setValue={setValue}
          key={row.id}
          setMinValue={setMinValue}
        />
      ),
    },
    {
      header: "Excedente",
      accessor: "maxSurplusDeliveryman",
    },
    {
      header: "Sub-praça",
      accessor: "subplazaId",
      dataTransform: SubplazaName,
    },
    {
      header: "Data de criação",
      accessor: "createdAt",
      dataTransform: formatCreatedAt,
    },
    {
      header: "Última atualização",
      accessor: "updatedAt",
      dataTransform: formatUpdatedAt,
    },
  ];

  const { useMutationAction } = useShiftAssignmentCachedService();
  const [publish, { status: publishStatus, error: publishError }] =
    useMutationAction("publish");
  const [close, { status: closeStatus, error: closeError }] =
    useMutationAction("close");
  const { update } = useShiftAssignmentService();

  const [exportScaled, { status: exportScaledStatus, data: exportScaledData }] =
    useShiftAssignmentPreferenceCachedService().useMutationAction(
      "exportScaled"
    );

  const { baseSheet, paginateFilter } = useShiftAssignmentService();

  const fileUpload = useDisclosure({ id: "upload-files" });

  const exportScaledDeliverymen = useDisclosure({
    id: "export-scaled-deliverymen",
  });

  const [filter, setFilter] = useState<Record<string, unknown>>({
    page: "0",
    limit: "10",
  });
  const [isSending, setIsSending] = useState(false);
  const [, setResults] = useState<unknown[]>([]);
  const [errors, setErrors] = useState<UploadEscalesResponseErrors | null>(
    null
  );
  const uuid = useRef<string>("");
  const scalesRef = useRef<IRef>(null);
  const toast = useToast();

  const [data, setData] = useState<ShiftAssignment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error] = useState<Error>();
  const [totalPages, setTotalPages] = useState<number>(10);

  const uploadFiles = useDisclosure({ id: "upload-files" });
  const readErrors = useDisclosure({ id: "read-errors" });

  const handleFetch = async () => {
    setIsLoading(true);
    const response = await paginateFilter(filter);
    if (response) {
      setData(response.result);
      setTotalPages(response.totalPages);
    }
    setIsLoading(false);
  };

  const handleAddVacancies = async ({
    shiftAssignmentId,
    vacancies,
  }: {
    shiftAssignmentId: number;
    vacancies: number;
  }) => {
    try {
      update({
        id: shiftAssignmentId,
        data: { maxDeliveryman: vacancies },
      });
      setIsModalOpen(false);
      toast({
        status: "success",
        title: "Vagas adicionadas com sucesso!",
        duration: 2000,
      });
      reset();
      handleFetch();
    } catch (error) {
      toast({
        status: "error",
        title: "Erro ao adicionar vagas",
        duration: 2000,
      });
    }
  };

  useEffect(() => {
    if (publishStatus) {
      if (publishStatus === "success") {
        toast({
          status: "success",
          duration: 3000,
          title: "Agendamento publicado com sucesso!",
        });
        const fetch = async () => {
          setIsLoading(true);
          const response = await paginateFilter(filter);
          if (response) {
            setData(response.result);
            setTotalPages(response.totalPages);
          }
          setIsLoading(false);
        };
        if (publishStatus === "success") {
          fetch();
        }
      }
      if (publishStatus === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao publicar agendamento",
          description: `Falha ao publicar agendamento: ${publishError}`,
        });
      }
    }
  }, [publishStatus, publishError]);

  useEffect(() => {
    if (closeStatus) {
      if (closeStatus === "success") {
        toast({
          status: "success",
          duration: 3000,
          title: "Agendamento fechada com sucesso!",
        });
        const fetch = async () => {
          setIsLoading(true);
          const response = await paginateFilter(filter);
          if (response) {
            setData(response.result);
            setTotalPages(response.totalPages);
          }
          setIsLoading(false);
        };
        if (closeStatus === "success") {
          fetch();
        }
      }
      if (closeStatus === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao fechar agendamento",
          description: `Falha ao fechar agendamento: ${closeError}`,
        });
      }
    }
  }, [closeStatus, closeError]);

  useEffect(() => {
    function generateCsv(data: object[], fileName: string) {
      if (!data || data.length === 0) {
        throw new Error("No data provided for CSV generation");
      }

      const csvContent = jsonToCSV(data, { delimiter: ";" });

      const csvBlob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(csvBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.csv`);
      link.click();
      URL.revokeObjectURL(url);
    }

    try {
      if (exportScaledStatus === "success") {
        const saps = exportScaledData as unknown as ShiftAssignmentPreference[];
        const rows = saps.map((shiftAssignmentPreference) => {
          const {
            deliveryman: { document, nameIfood },
            shiftAssignment: {
              dateScale,
              subplaza: {
                name: subpraca,
                plaza: { name: praca },
              },
              shift: { name: turno },
            },
          } = shiftAssignmentPreference;

          return {
            dataEscala: dayjs(dateScale).format("DD/MM/YYYY"),
            nomeIfood: nameIfood,
            cpf: `${document.replace(
              /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
              "$1.$2.$3-$4"
            )}, `,
            turno,
            subpraca,
            praca,
          };
        });

        const shiftAssignment = saps[0].shiftAssignment;
        const { subplaza, shiftId, dateScale } = shiftAssignment;
        const { id: subplazaId, plazaId } = subplaza;

        const fileName = `P${plazaId}_SP${subplazaId}_T${shiftId}_${formatDate(
          { dateScale },
          "dateScale",
          format.BR,
          "-"
        )}`;
        generateCsv(rows, fileName);
      }
    } catch (error) {
      toast({
        status: "error",
        duration: 3000,
        title: "Não há entregadores",
        description:
          "Nenhum entregador manifestou interesse em correr nesse agendamento",
      });
    }
  }, [exportScaledData, exportScaledStatus, jsonToCSV, toast]);

  const updateFilter = (formData: Record<string, unknown>) => {
    const filters: Record<string, unknown> = Object.entries(formData).reduce(
      (filters: Record<string, unknown>, [key, value]) => {
        if (value !== undefined && value !== "") {
          filters[key] = value;
        }
        return filters;
      },
      {}
    );
    filters.dateScale &&= formatDate(filters, "dateScale", format.US);

    Object.assign(filters, {
      page: searchParams.get("page"),
      limit: searchParams.get("limit"),
    });

    setFilter(filters);
  };
  useEffect(() => {
    handleFetch();
  }, [JSON.stringify(filter)]);

  const exportDeliverymen = (formData: Record<string, unknown>) => {
    const filters: Record<string, unknown> = Object.entries(formData).reduce(
      (filters: Record<string, unknown>, [key, value]) => {
        if (value !== undefined && value !== "") {
          filters[key] = value;
        }
        return filters;
      },
      {}
    );

    exportScaled(filters);
  };

  function formatPage() {
    const page = searchParams.get("page");

    return page ? parseInt(page) : 1;
  }

  const paginate = (
    <HStack gap={8} pt={8} justifyContent="flex-end">
      <Pagination
        page={formatPage()}
        setPage={(page) =>
          setSearchParams((prev) => {
            prev.set("page", String(page));
            const filters = Object.assign(filter, {
              page: searchParams.get("page"),
              limit: searchParams.get("limit"),
            });
            setFilter(filters);
            return prev;
          })
        }
        totalPages={totalPages}
      />
    </HStack>
  ) as ReactNode;

  async function handleUpload() {
    setIsSending(true);
    uuid.current = crypto.randomUUID();
    const hasFiles = scalesRef.current?.hasFiles();

    if (!hasFiles) {
      toast({
        status: "error",
        title: "Planilha faltante",
        description: "É necessário enviar as planilhas de agendamento",
      });
      setIsSending(false);
      return;
    }

    try {
      const result = await scalesRef.current?.processUpload<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any | undefined | null
      >({
        sendId: uuid.current,
      });

      if (!result) return;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formattedResult = result.result.map((value: any) => ({
        ...value,
        editedAmount: value.valor,
      }));

      setResults(formattedResult);

      if (!result.hasError) {
        setErrors(null);
        fileUpload.onClose();
        setIsSending(false);
        return;
      }

      setErrors(result.errors);

      fileUpload.onClose();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.code === "ERR_NETWORK") {
          toast({
            status: "error",
            title: "Conexão com internet instável",
            description:
              "Verifique sua conexão com internet ou tente novamente mais tarde.",
            duration: 5000,
          });
        } else {
          toast({
            status: "error",
            title: `${error?.response?.data?.message}`,
            description: String(error),
            duration: 5000,
          });
        }
      }

      setIsSending(false);
      return;
    }

    setIsSending(false);
    fileUpload.onClose();
  }

  return (
    <>
      {isModalOpen && (
        <Modal
          isOpen={true}
          onClose={handleCloseModal}
          size="xl"
          id="add-vacancies-modal"
        >
          <ModalOverlay />
          <ModalContent as={"form"} onSubmit={handleSubmit(handleAddVacancies)}>
            <ModalHeader>Adicionar Vagas ao Agendamento</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <NumberInput>
                <NumberInputField
                  type="number"
                  {...register("vacancies")}
                  min={minValue}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </ModalBody>
            <ModalFooter gap={2}>
              <Button type="submit">Salvar</Button>
              <Button onClick={handleCloseModal}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <HStack>
        <SubmenuSearch />
        <SearchShiftAssignment
          updateFilter={(data) => {
            updateFilter(data);
          }}
        />
      </HStack>
      <SendMessageButton />
      <GenericList
        title="Gestão de agendamento"
        dataQuery={{ data, isLoading, error }}
        beforeTable={
          <AddButton
            errors={errors}
            exportScaledDeliverymen={exportScaledDeliverymen}
            fileUpload={fileUpload}
            handleUpload={handleUpload}
            isSending={isSending}
            readErrors={readErrors}
            uploadFiles={uploadFiles}
            key={uuid.current}
          />
        }
        afterTable={paginate}
        rowActions={(row: any) => (
          <RowActionsShiftAssignmentComponent
            row={row}
            close={() => close({ id: row.id })}
            publish={() => publish({ id: row.id })}
            exportScaled={() => exportScaled({ shiftAssignmentId: row.id })}
            statuses={statuses}
          />
        )}
        actionsLabel="Ações"
        columns={columns}
        key={"shift-assignment"}
      />
      {fileUpload.isOpen && (
        <Modal
          isOpen={fileUpload.isOpen}
          onClose={fileUpload.onClose}
          size="5xl"
          id="upload-files"
        >
          <ModalOverlay />
          <ModalContent mx={4}>
            <ModalHeader>Upload - Arquivo de Agendamentos</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={16}>
              <Button
                as={Link}
                role="a"
                size="lg"
                color="white"
                ml={2}
                mb={2}
                onClick={(e) => {
                  e.preventDefault();
                  console.log(baseSheet);
                  baseSheet();
                }}
              >
                Baixar Template
              </Button>
              <Flex gap={16} flexDir="column">
                <Flex flexDir="column" gap={4}>
                  <DragDrop
                    uploadUrl="/api/v1/shift_assignments/create/upload"
                    maxFiles={7}
                    ref={scalesRef}
                  />
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter my={4}>
              <Button variant="ghost" mr={3} onClick={fileUpload.onClose}>
                Fechar
              </Button>
              <Button
                color="white"
                onClick={handleUpload}
                isLoading={isSending}
                isDisabled={isSending}
              >
                Enviar .CSV
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {exportScaledDeliverymen.isOpen && (
        <Modal
          isOpen={exportScaledDeliverymen.isOpen}
          onClose={exportScaledDeliverymen.onClose}
          size="5xl"
          id="export-scaled-deliverymen"
        >
          <ModalOverlay />
          <ModalContent mx={4}>
            <ModalHeader>Exportar Entregadores Agendados</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={16}>
              <ExportScaledFilter updateFilter={exportDeliverymen} />
            </ModalBody>

            <ModalFooter my={4}>
              <Button
                variant="ghost"
                mr={3}
                onClick={exportScaledDeliverymen.onClose}
              >
                Fechar
              </Button>
              <Button
                color="white"
                onClick={handleUpload}
                isLoading={isSending}
                isDisabled={isSending}
                display="none"
              >
                Exportar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
