import { useEffect, useRef, useState, useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

type Props = {
  isRegressive?: boolean;
  limit: number;
};

/** useTimer Hook
 * This hook can be used to render a timer, regressive or not.
 * @param {number} limit - Give the limit time you want to start counting in seconds.
 * @param {number} isRegressive - Boolean to specify if the timer should be regressive or not. Default is true.
 */

export function useTimer({ isRegressive = true, limit }: Props) {
  const dateRef = useRef<Dayjs | null>(null);

  const [counter, setCounter] = useState<number | null>(null);
  const [maxLimit, setMaxLimit] = useState<number>(limit);

  const timeString = (() => {
    if (!counter) return null;

    const minutes = Math.floor(counter / 60);
    const seconds = counter % 60;
    const string = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return string;
  })();

  const counterInterval = useRef<ReturnType<typeof setInterval> | undefined>(
    undefined
  );

  const resetTimer = useCallback(() => {
    clearInterval(counterInterval.current);
    setCounter(null);
  }, []);

  const counterDiff = useCallback(() => {
    const totalSeconds = !isRegressive
      ? dayjs().diff(dateRef.current, "seconds") + maxLimit + 1
      : dayjs(dateRef.current).diff(dayjs(), "seconds");

    if (totalSeconds <= 0) resetTimer();

    setCounter(totalSeconds);
  }, [maxLimit, isRegressive, resetTimer]);

  /** Start timer function.
   * @param {number} startLimit - Give the limit time you want to start counting in seconds.
   * If none is given the hook default will be used instead.
   */
  function startTimer(startLimit: number = maxLimit) {
    if (maxLimit !== startLimit) {
      setMaxLimit(startLimit);
    }

    dateRef.current = dayjs().add(startLimit, "seconds");
    const duration = !isRegressive
      ? dayjs().diff(dateRef.current, "seconds") + startLimit + 1
      : dayjs(dateRef.current).diff(dayjs(), "seconds");
    setCounter(duration);
  }

  useEffect(() => {
    if (counter === null) {
      return;
    }

    if (counter <= 0) {
      resetTimer();
    }

    counterInterval.current = setInterval(counterDiff, 1000);
    return () => clearInterval(counterInterval.current);
  }, [counterDiff, counter, resetTimer]);

  return {
    counter,
    startTimer,
    resetTimer,
    timeString,
  };
}
