import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { shallow } from "zustand/shallow";
import { Flex, Grid } from "@chakra-ui/layout";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Checkbox,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";

import {
  useCompaniesStore,
  useCreateCompanyForm,
} from "../../store/company.store";
import { useCurrentUserStore } from "../../store/current-user.store";
import { Permission } from "../../dto/permission-dto";
import { useQueryClient } from "@tanstack/react-query";

export function EditCompanyPermissions() {
  const client = useQueryClient();

  const {
    company,
    companyPermissions,
    getCompanyPermissions,
    fetchPermissions,
    allPermissions,
  } = useCompaniesStore(
    (state) => ({
      company: state.company,
      allPermissions: state.allPermissions,
      companyPermissions: state.companyPermissions,
      getCompanyPermissions: state.getCompanyPermissions,
      fetchPermissions: state.fetchPermissions,
    }),
    shallow
  );

  const { user } = useCurrentUserStore(
    (state) => ({ user: state.user }),
    shallow
  );

  const { updatePermissions } = useCreateCompanyForm(
    (state) => ({
      updatePermissions: state.updatePermissions,
    }),
    shallow
  );

  const toast = useToast({
    position: "top-right",
  });

  const navigate = useNavigate();

  function setDefaultValues(data: Permission[]) {
    const companyPermissions = data.reduce(
      (
        formattedPermissions: {
          [key: string]: string;
        },
        permission
      ) => {
        formattedPermissions[permission.key] = String(permission.id);

        if (!permission.canEditUrl) return formattedPermissions;

        const getPermissionUrl = data.find(
          (companyPermission) => companyPermission.id === permission.id
        )?.url;

        if (getPermissionUrl) {
          formattedPermissions[`${permission.key}_url`] = getPermissionUrl;
        }

        return formattedPermissions;
      },
      {}
    );

    return companyPermissions;
  }

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: companyPermissions
      ? setDefaultValues(companyPermissions)
      : {},
  });

  async function onSubmit(data: { [key: string]: string }) {
    const selectedPermissions = allPermissions
      ?.filter((permission) => data[permission.key])
      .reduce(
        (
          formattedPermissions: {
            company: { id: number | undefined };
            url: string | null;
            permission: Permission;
          }[],
          permission
        ) => {
          const formattedPermission = {
            company: {
              id: company?.id,
            },
            url: data[`${permission.key}_url`] || null,
            permission,
          };

          formattedPermissions.push(formattedPermission);

          return formattedPermissions;
        },
        []
      );

    try {
      if (selectedPermissions) {
        await updatePermissions(selectedPermissions);
      }

      if (company?.id === user?.company?.id)
        client.refetchQueries(["permissions"]);

      toast({
        status: "success",
        title: "Permissões atualizadas com sucesso",
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          duration: 2000,
          title: "Não foi possível atualizar as permissões!",
          description: `Erro ao atualizar permissões: ${error.message}`,
        });
        return;
      }
      toast({
        status: "error",
        duration: 2000,
        title: "Não foi possível atualizar as permissões!",
      });
    }
  }

  useEffect(() => {
    if (!company?.id) {
      navigate("companies");
    }

    fetchPermissions();

    if (company?.id) {
      getCompanyPermissions(company?.id);
    }
  }, [fetchPermissions, company, navigate, getCompanyPermissions]);

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      flex={1}
      gap={8}
    >
      <Flex
        as="form"
        flexDir="column"
        w="full"
        gap={8}
        h="full"
        flex={1}
        id="form_permissions"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl as="fieldset" flex={1}>
          <Flex direction={"column"} gap={2} flex={1}>
            {allPermissions?.map((permission) =>
              permission.canEditUrl ? (
                <Grid gridTemplateColumns="30% 1fr" key={permission.id} gap={2}>
                  <Checkbox
                    value={permission.id}
                    {...register(permission.key)}
                    colorScheme="green"
                  >
                    {permission.name}
                  </Checkbox>
                  <Input
                    bg="whiteAlpha.800"
                    color="gray.900"
                    _placeholder={{
                      color: "gray.500",
                    }}
                    {...register(`${permission.key}_url`)}
                    placeholder="URL da permissão"
                  />
                </Grid>
              ) : (
                <Checkbox
                  value={permission.id}
                  key={permission.id}
                  colorScheme="green"
                  {...register(permission.key)}
                >
                  {permission.name}
                </Checkbox>
              )
            )}
          </Flex>
        </FormControl>

        <Flex justifyContent={"flex-end"} gap={2}>
          <Button
            type="submit"
            form="form_permissions"
            size="lg"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
