import { SubMenu } from "../sub-menu";

export function SubmenuSearch() {
  const buttons = [
    { label: "Agendamento", to: "/scales" },
    { label: "Disponibilidades", to: "/scales/shift_assignment_preferences" },
    { label: "Configurações", to: "/scales/configurations" },
  ];

  return (
    <>
      <SubMenu title="Menu Agendamento" buttons={buttons} />
    </>
  );
}
