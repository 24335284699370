import React, { useState } from "react";
import { Badge, Box, Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  AlertDialogProps,
} from "@chakra-ui/react";
import {
  useCompaniesStore,
  useCreateCompanyForm,
} from "../../../store/company.store";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth, postWithAuth } from "../../../services/basicService";
import { AxiosError, isAxiosError } from "axios";
import { Pagination } from "../../../components/pagination";
import { INTEGRATION } from "../../../enums/company-integration";

const searchFormSchema = z.object({
  search: z.string(),
});

type searchFormInputs = z.infer<typeof searchFormSchema>;

export function Companies() {
  const [searchParams, setSearchParams] = useSearchParams({ page: "1" });
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const accountStatusDialog = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  const companies = useCompaniesStore((state) => state.companies);
  const getCompanies = useCompaniesStore((state) => state.getCompanies);
  const isChangingStatus = useCompaniesStore((state) => state.changingStatus);
  const inactivateAllCompanies = useCompaniesStore(
    (state) => state.changeAllCompaniesStatus
  );
  const changeCompanyStatus = useCompaniesStore(
    (state) => state.changeCompanyStatus
  );

  const { updateCompany } = useCreateCompanyForm((state) => ({
    updateCompany: state.updateCompany,
  }));

  const page = Number(searchParams.get("page"));
  const search = searchParams.get("search") || undefined;

  const { isLoading } = useQuery({
    queryKey: ["companies", page, search],
    queryFn: () => getCompanies(page, search),
    keepPreviousData: true,
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<searchFormInputs>({
    resolver: zodResolver(searchFormSchema),
  });

  const toast = useToast();

  async function handleSearch(data: searchFormInputs) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("search", data.search);
      return prev;
    });
  }

  async function handleStatusChange(status: number, id: number) {
    try {
      await changeCompanyStatus(id, status);

      toast({
        status: "success",
        title: `Status alterado com sucesso para: ${
          status === 1 ? "Ativo" : "Inativo"
        }`,
      });
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: `Status não alterado, contate o suporte para mais informações`,
      });
    }
  }

  async function handleIntegrationChange(integration: INTEGRATION, id: number) {
    try {
      await updateCompany(id, { integration });

      toast({
        status: "success",
        title: `Integração alterada com sucesso para: ${integration}`,
      });
    } catch (error) {
      toast({
        status: "error",
        title: `Falha ao alterar integração, contate o suporte para mais informações`,
      });
    }
  }

  return (
    <VStack gap={8} flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Empresas
      </Heading>

      <VStack w="full" h="full" gap={6} borderRadius={8}>
        <CompanyAccountStatus
          cancelRef={cancelRef}
          onClose={accountStatusDialog.onClose}
          isOpen={accountStatusDialog.isOpen}
          company={selectedCompany}
        />

        <Flex w="full" gap={4} alignItems="center">
          <Button
            size="lg"
            color="white"
            onClick={() => navigate("create-company", { relative: "route" })}
          >
            Criar empresa
          </Button>

          <Button
            size="lg"
            color="white"
            onClick={onOpen}
            isDisabled={isChangingStatus}
          >
            Inativar todas
          </Button>
        </Flex>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Inativar empresas
              </AlertDialogHeader>

              <AlertDialogBody>
                Você tem certeza que deseja inativar todas as empresas?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  colorScheme="red"
                  onClick={async () => {
                    await inactivateAllCompanies();
                    onClose();
                  }}
                  ml={3}
                >
                  Inativar empresas
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <Box
          w="full"
          display="flex"
          flexDir="column"
          gap={8}
          flex={1}
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
        >
          <Flex
            as="form"
            w="full"
            gap={4}
            onSubmit={handleSubmit(handleSearch)}
            flexDir="column"
          >
            <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
              Filtros
            </Heading>
            <Flex gap={4} w="full" alignItems="center">
              <FormControl as="fieldset" isInvalid={!!errors.search} maxW="3xl">
                <Input
                  {...register("search")}
                  placeholder="Busque nome..."
                  size="lg"
                  maxW="3xl"
                  w="full"
                />
                {errors.search ? (
                  <FormErrorMessage>{errors.search.message}</FormErrorMessage>
                ) : null}
              </FormControl>
              <Button type="submit" title="search" size="lg">
                <MagnifyingGlass weight="bold" />
              </Button>
            </Flex>
          </Flex>
          <TableContainer
            w="full"
            display="flex"
            flexDir="column"
            flex={1}
            gap={8}
            pb={4}
            style={{
              containerType: "inline-size",
            }}
          >
            <Table variant="striped" colorScheme="green">
              <Thead>
                <Tr>
                  <Th flex={1}>Nome</Th>
                  <Th textAlign="center">Status</Th>
                  <Th>Mudar Status</Th>
                  <Th>Integração</Th>
                  <Th isNumeric>Data de entrada</Th>
                  <Th textAlign="center">Mudar status da conta bancária</Th>
                  <Th textAlign="center">Editar</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isLoading
                  ? companies.companies.map((company) => (
                      <Tr key={company.id}>
                        <Td>{company.name}</Td>
                        <Td textAlign="center">
                          <Badge
                            variant="solid"
                            px={4}
                            colorScheme={
                              company?.status?.name === "Active"
                                ? "green"
                                : "red"
                            }
                          >
                            {company.status ? company.status.name : "undefined"}
                          </Badge>
                        </Td>
                        <Td>
                          <Select
                            defaultValue={company.status?.id.toString()}
                            onChange={async (e) =>
                              await handleStatusChange(
                                parseInt(e.currentTarget.value),
                                company.id
                              )
                            }
                          >
                            <option value="1">Ativa</option>
                            <option value="2">Inativa</option>
                          </Select>
                        </Td>
                        <Td>
                          <Select
                            defaultValue={company.integration}
                            onChange={async (e) =>
                              await handleIntegrationChange(
                                e.currentTarget.value as INTEGRATION,
                                company.id
                              )
                            }
                          >
                            {Object.entries(INTEGRATION).map(
                              ([label, value]) => (
                                <option key={value} value={value}>
                                  {label}
                                </option>
                              )
                            )}
                          </Select>
                        </Td>
                        <Td isNumeric>
                          {company.createdAt
                            ? dayjs(company.createdAt).format("DD/MM/YYYY")
                            : "undefined"}
                        </Td>
                        <Td w="10%" textAlign="center">
                          <Button
                            onClick={() => {
                              setSelectedCompany(company.id);
                              accountStatusDialog.onOpen();
                            }}
                            size="sm"
                          >
                            Mudar status da conta
                          </Button>
                        </Td>
                        <Td w="10%" textAlign="center">
                          <Button
                            onClick={() =>
                              navigate(`edit-company/${company.id}`, {
                                relative: "route",
                              })
                            }
                            size="sm"
                          >
                            Editar
                          </Button>
                        </Td>
                      </Tr>
                    ))
                  : null}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex w="full" justifyContent="center" gap={3}>
            <Pagination
              page={page}
              setPage={(page) => setSearchParams({ page: page.toString() })}
              totalPages={companies.pages}
            />
          </Flex>
        </Box>
      </VStack>
    </VStack>
  );
}

interface Props extends Pick<AlertDialogProps, "isOpen" | "onClose"> {
  cancelRef: React.MutableRefObject<HTMLButtonElement | null>;
  company: number | null;
}

function CompanyAccountStatus({ isOpen, onClose, cancelRef, company }: Props) {
  const toast = useToast();

  const { data, error, isFetching } = useQuery({
    queryKey: ["company_status_account"],
    queryFn: async () => {
      try {
        const response = await getWithAuth(
          `api/v1/companies/status-account/${company}`
        );

        return response?.data;
      } catch (error) {
        if (isAxiosError(error)) {
          console.log("to aqui");
          toast({
            status: "error",
            title: "Erro ao recuperar status",
            description: error.response?.data.message,
            isClosable: true,
          });
          return Promise.reject(error);
        }

        toast({
          status: "error",
          title: "Erro ao recuperar status",
          description: JSON.stringify(error),
          isClosable: true,
        });
        return Promise.reject(error);
      }
    },
    enabled: isOpen,
    placeholderData: "carregando",
    refetchOnWindowFocus: false,
    retry: false,
  });

  async function changeAccountStatus() {
    try {
      if (data === "Normal") {
        await postWithAuth(`api/v1/companies/block/${company}`, {});
      }

      if (data === "Blocked") {
        await postWithAuth(`api/v1/companies/reactivate/${company}`, {});
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          status: "error",
          title: "Erro ao alterar status da conta",
          description: error.message,
        });
      }

      toast({
        status: "error",
        title: "Erro ao alterar status da conta",
        description: JSON.stringify(error),
      });
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered={true}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Mudar status de conta da empresa
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              Você tem certeza que deseja mudar status de conta da empresa?
            </Text>
            <Text>
              Status atual:{" "}
              <Badge colorScheme={data === "Normal" ? "green" : "red"}>
                {data || (isAxiosError(error) && error?.response?.data.message)}
              </Badge>
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose}>Cancelar</Button>
            <Button
              colorScheme={data === "Normal" ? "red" : "green"}
              loadingText="Carregando..."
              isLoading={isFetching}
              isDisabled={isFetching}
              onClick={async () => {
                await changeAccountStatus();
                onClose();
              }}
              ml={3}
            >
              {data === "Normal" ? "Bloquear" : "Reativar"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
