import { Flex } from "@chakra-ui/layout";
import { Outlet, useNavigation } from "react-router-dom";
import { PortfolioManagementSubMenu } from "./portfolio-management-submenu";
import { LoadingLogo } from "../../components/loading";

export function PortfolioManagementLayout() {
  const { state } = useNavigation();

  return (
    <Flex gap={8} w="full" px={4} position="relative">
      <PortfolioManagementSubMenu />
      {state === "loading" ? (
        <>
          <LoadingLogo />
        </>
      ) : (
        <Outlet />
      )}
    </Flex>
  );
}
