import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { AccountLimits, useCompaniesStore } from "../../store/company.store";
import { AccountLimit } from "../../dto/company-bank-limit-dto";
import { LIMIT_TYPE } from "../../enums/bank-limit";

type amountType = "accountLimit" | "availableLimit";

const generateTd = (
  limits: AccountLimit[],
  limitType: keyof typeof LIMIT_TYPE,
  amountType: amountType
) => {
  const hasLimit = limits.find(
    (limit) => limit.limitType === LIMIT_TYPE[limitType]
  );

  return (
    <Td textAlign="center">
      {hasLimit
        ? hasLimit[amountType].toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        : null}
    </Td>
  );
};

const tableFactory = (limits: AccountLimit[]) => {
  return (
    <TableContainer
      w="full"
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      p={8}
      gap={8}
      shadow="base"
      justifyContent="space-between"
      display="flex"
      flexDir="column"
      bg="whiteAlpha.800"
    >
      <Table variant="striped" colorScheme="green">
        <Thead>
          <Tr>
            <Th p={0}></Th>
            <Th textAlign="center">Limite diário por transação</Th>
            <Th textAlign="center">Limite diário total</Th>
            <Th textAlign="center">Limite mensal</Th>
            <Th textAlign="center">Limite noturno total</Th>
            <Th textAlign="center">Limite noturno por transação</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td p={0} textAlign="center">
              Total
            </Td>
            {Object.keys(LIMIT_TYPE).map((limitType) =>
              generateTd(
                limits,
                limitType as keyof typeof LIMIT_TYPE,
                "accountLimit"
              )
            )}
          </Tr>
          <Tr>
            <Td p={0} textAlign="center">
              Disponível
            </Td>
            {Object.keys(LIMIT_TYPE).map((limitType) =>
              generateTd(
                limits,
                limitType as keyof typeof LIMIT_TYPE,
                "availableLimit"
              )
            )}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

function LimitItem({
  title,
  limits,
}: {
  title: string;
  limits: AccountLimit[];
}) {
  return (
    <AccordionItem>
      <Heading fontSize="md">
        <AccordionButton py={6}>
          <Box as="span" flex="1" textAlign="left" fontWeight="semibold">
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Heading>
      <AccordionPanel pb={4}>
        {limits?.length > 0 ? (
          tableFactory(limits)
        ) : (
          <Text fontSize="md">Não foi possível obter as informações.</Text>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

function accountLimits(accountLimits: AccountLimits) {
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <Flex flexDir="column" w="full" gap={8} flex={1}>
        <Accordion allowMultiple>
          <LimitItem
            title="Limite PIX para PF"
            limits={accountLimits.limitPIXtoPF}
          ></LimitItem>
          <LimitItem
            title="Limite PIX para PJ"
            limits={accountLimits.limitPIXtoPJ}
          ></LimitItem>
          <LimitItem
            title="Limite P2P para PF"
            limits={accountLimits.limitP2PtoPF}
          ></LimitItem>
          <LimitItem
            title="Limite P2P para PJ"
            limits={accountLimits.limitP2PtoPJ}
          ></LimitItem>
        </Accordion>
      </Flex>
    </Flex>
  );
}

export function EditCompanyBankLimit() {
  const { mainAccountLimits, paymentAccountLimits } = useCompaniesStore(
    (state) => state.companyBankLimits
  );

  return (
    <Tabs variant="soft-rounded" colorScheme="green">
      <TabList color="blackAlpha.600">
        <Tab key="main-account">
          <strong>Conta Principal</strong>
        </Tab>
        <Tab key="payment-account">
          <strong>Conta Repasse</strong>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{accountLimits(mainAccountLimits)}</TabPanel>
        <TabPanel>{accountLimits(paymentAccountLimits)}</TabPanel>
      </TabPanels>
    </Tabs>
  );
}
