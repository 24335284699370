import { forwardRef, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { Eye, EyeSlash } from "@phosphor-icons/react";

export const PasswordInput = forwardRef(function PasswordInput(
  props: InputProps,
  ref
) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="lg" display="flex" alignItems="center">
      <Input type={show ? "text" : "password"} {...props} ref={ref} />
      <InputRightElement pr={2}>
        <Button bg="transparent" onClick={handleClick} p={1}>
          {show ? <Eye size={20} /> : <EyeSlash size={20} />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});
