import { VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel, Switch, Button } from "@chakra-ui/react";
import { SelectFranchise } from "./select-franchise";
import { StatusCard } from "./status-card";
import { memo } from "react";
import { usePayrollScheduleSettingsContext } from "../context/payroll-schedule-settings.context";

const Component = () => {
  const { switchControl, setSwitchControl, onSubmitByPass } =
    usePayrollScheduleSettingsContext();

  return (
    <VStack w="30%" alignItems="flex-start" spacing={6}>
      <StatusCard />

      <SelectFranchise />
      <FormControl display="flex" flexDir={"column"} alignItems="center">
        <FormLabel mb="0">Abrir/Fechar Folha Agendada?</FormLabel>
        <Switch
          size="lg"
          isChecked={switchControl}
          onChange={() => setSwitchControl(!switchControl)}
        />
      </FormControl>

      <Button colorScheme="green" w="100%" onClick={onSubmitByPass}>
        Salvar Status
      </Button>
    </VStack>
  );
};

export const StatusSwitchComponent = memo(Component);
