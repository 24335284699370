import { FormEvent, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth, patchWithAuth } from "../../services/basicService";
import { useToast } from "@chakra-ui/react";

export function useReqAutomaticBalanceMigrationConfig() {
  const [bannerValue, setBannerValue] = useState(false);
  const [value, setValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom",
  });

  const fetch = async () => {
    const URL = `/api/v1/configuration/automatic-balance-migration`;

    try {
      const res = await getWithAuth(URL);

      const data = res?.data as { id: number; key: string; value: string };

      setValue(JSON.parse(data.value));
      setBannerValue(JSON.parse(data.value));

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const patch = async (event: FormEvent) => {
    event.preventDefault();

    if (loading) return;
    setLoading(true);

    const URL = `/api/v1/configuration/automatic-balance-migration`;

    const body = {
      key: "automatic_balance_migration",
      value: JSON.stringify(value),
    };

    patchWithAuth(URL, body)
      .then(() => {
        setBannerValue(value);

        toast({ status: "success", title: "Requisição realizada com sucesso" });
      })
      .catch((err) =>
        toast({
          status: "error",
          title: "Ocorreu um erro na requisição",
          description: err.response?.data?.message,
        })
      )
      .finally(() => setLoading(false));
  };

  const { data, isLoading } = useQuery(["automatic-balance-migration"], fetch);

  return { data, isLoading, value, setValue, patch, loading, bannerValue };
}
