import { VStack, Heading, Flex, Box } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Stat,
  StatArrow,
  StatLabel,
  StatNumber,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { postWithAuth } from "../../../../services/basicService";

const proposalFormSchema = z.object({
  changeState: z.boolean(),
});

type proposalFormInputs = z.infer<typeof proposalFormSchema>;

export function ApprovalConfig() {
  const { status } = useLoaderData() as { status: boolean };

  const toast = useToast();

  const revalidator = useRevalidator();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<proposalFormInputs>({
    resolver: zodResolver(proposalFormSchema),
    defaultValues: {
      changeState: status,
    },
  });

  async function onSubmit(data: proposalFormInputs) {
    try {
      await postWithAuth(
        `/api/v1/configuration/aprovacaoStartStop`,
        { value: data.changeState },
        {}
      );

      toast({
        status: "success",
        title: `O status do adiantamento foi mudado para ${
          data.changeState ? "aberto" : "fechado"
        }`,
      });

      revalidator.revalidate();
    } catch (error) {}
  }

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Configurações de Aprovação automática
      </Heading>

      <Flex
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        borderRadius={8}
      >
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="lg"
          h="min-content"
        >
          <Stat border={1}>
            <StatLabel>Status</StatLabel>
            <StatNumber>
              {status ? (
                <>
                  <StatArrow type="increase" />
                  Aprovando automaticamente
                </>
              ) : (
                <>
                  <StatArrow type="decrease" />
                  Pausado
                </>
              )}
            </StatNumber>
          </Stat>
        </Box>

        <Flex
          flexDir="column"
          as="form"
          gap={8}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            gap={4}
          >
            <FormLabel htmlFor="changeState" mb="0" fontSize="lg">
              Aprovar/Pausar?
            </FormLabel>
            <Switch id="changeState" {...register("changeState")} size="lg" />
          </FormControl>

          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            size="lg"
          >
            Salvar Status
          </Button>
        </Flex>
      </Flex>
    </VStack>
  );
}
