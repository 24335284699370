import { HStack, Heading } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/react";
import { CaretLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

const Component = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <HStack w="100%">
      <IconButton
        onClick={handleGoBack}
        icon={<CaretLeft weight="bold" size={28} color="black" />}
        aria-label="back"
      />
      <Heading>Sincronizar CSV</Heading>
    </HStack>
  );
};

export const HeadingSyncCsv = Component;
