import { z } from "zod";

export const parseSearchParamsToObject = <T extends z.ZodTypeAny>({
  URLSearchParams,
  schema,
}: {
  URLSearchParams: URLSearchParams;
  schema: T;
}): z.infer<T> => {
  try {
    return schema.parse(
      Object.fromEntries(
        Array.from(URLSearchParams.entries()).reduce((acc, cur) => {
          const [key, value] = cur;
          if (acc.has(key) && Array.isArray(acc.get(key))) {
            const temp = acc.get(key);
            temp.push(value);
            acc.set(key, temp);
            return acc;
          }

          if (acc.has(key) && !Array.isArray(acc.get(key))) {
            const temp = acc.get(key);
            acc.set(key, [temp, value]);
            return acc;
          }
          acc.set(key, value);
          return acc;
        }, new Map())
      )
    );
  } catch (error) {
    console.log(error);
  }
};
