import { Flex, HStack, Heading, VStack } from "@chakra-ui/layout";
import {
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Button,
} from "@chakra-ui/react";
import { Plazas } from "../plazas";
import { Subplazas } from "../subplazas";
import { Shifts } from "../shifts";
import { CaretLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

export function ScaleConfigurations() {
  const navigate = useNavigate();
  const title = "Configurações de Agendamento";
  return (
    <VStack alignItems="flex-start" w="100%">
      <HStack>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading margin={6} fontWeight="extrabold" textAlign="left">
          {title}
        </Heading>
      </HStack>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        alignItems="flex-start"
        w="full"
        h="full"
        p={2}
      >
        <Tabs
          position="relative"
          size="md"
          variant="soft-rounded"
          colorScheme="green"
          w="100%"
        >
          <TabList>
            <Tab mt="8" ml="4">
              <h1>Praças</h1>
            </Tab>
            <Tab mt="8">
              <h1>Subpraças</h1>
            </Tab>
            <Tab mt="8">
              <h1>Turnos</h1>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Plazas />
            </TabPanel>
            <TabPanel>
              <Subplazas />
            </TabPanel>
            <TabPanel>
              <Shifts />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </VStack>
  );
}
