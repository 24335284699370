import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../../services/basicService";
import { useParams } from "react-router-dom";
import {
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useUsersDashboardStore } from "../../../../store/user-company.store";
import dayjs from "dayjs";

type AuthCodeData = {
  id: number;
  uuid: string;
  code: number;
  userId: number;
  attemptedUse: number;
  expirationDate: Date;
  redeemDate: Date | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export function TwoFactorAuthCodes() {
  const { id: userId } = useParams();
  const user = useUsersDashboardStore((state) => state.user);

  const toast = useToast();

  const { data, isLoading } = useQuery({
    queryKey: ["list-user-auth-codes", userId],
    queryFn: async () => {
      try {
        const codes = await getWithAuth(
          `/api/v1/two-factor-auth-code/listAll/${userId}`
        );
        return codes?.data as AuthCodeData[];
      } catch (error) {
        if (error instanceof Error) {
          toast({
            status: "error",
            title: "Não foi possível recuperar códigos do usuário",
            description: `Não foi possível recuperar códigos do usuário, contate o suporte: ${error.message}`,
            duration: 2000,
          });
          throw error;
        }
        toast({
          status: "error",
          title: "Não foi possível recuperar códigos do usuário",
          description: `Não foi possível recuperar códigos do usuário, contate o suporte`,
          duration: 2000,
        });
        throw error;
      }
    },
    keepPreviousData: true,
  });

  function BadgeSelect(code: AuthCodeData): React.ReactNode {
    const isExpired = dayjs(code.expirationDate).isAfter(new Date());

    if (!isExpired) return <Badge colorScheme="red">Expirado</Badge>;

    if (code.redeemDate) return <Badge colorScheme="gray">Usado</Badge>;

    return <Badge colorScheme="green">Válido</Badge>;
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Usuário</Th>
            <Th>Código</Th>
            <Th>Tentativas</Th>
            <Th>Data de criação</Th>
            <Th>Data de expiração</Th>
            <Th>Status de validade</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading
            ? data?.map((code) => (
                <Tr key={code.uuid}>
                  <Td>{`${user?.firstName} ${user?.lastName}`}</Td>
                  <Td>{code.code}</Td>
                  <Td>{code.attemptedUse}</Td>
                  <Td>{dayjs(code.createdAt).format("DD/MM/YYYY HH:mm")}</Td>
                  <Td>
                    {dayjs(code.expirationDate).locale("pt-BR").fromNow()}
                  </Td>
                  <Td>{BadgeSelect(code)}</Td>
                </Tr>
              ))
            : null}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
