import { Heading, Text, VStack } from "@chakra-ui/layout";

export function SiteNavigationTab() {
  return (
    <VStack w="full" h="full" gap={4} p={8} borderRadius={8}>
      <Heading fontSize="3xl" textAlign="left">
        Painel de controle Trampay
      </Heading>
      <Text fontSize="lg" textAlign="left">
        Assista ao nosso vídeo tutorial para descobrir como acessar e usar todas
        as funcionalidades de forma simples e rápida.
      </Text>
      <iframe
        width="800px"
        height="500px"
        src="https://www.youtube.com/embed/jmmlBN8ujkU?si=ztnJLXiBtk-uyy2N"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </VStack>
  );
}
