export enum AdvanceStatus {
  VALIDATED = "validated",
  APPROVED = "approved",
  CANCELED = "canceled",
  PROCESSING = "processing",
  ERROR = "error dock",
  ERRORBMP = "error bmp",
  PENDING_SIGNATURE = "pending signature",
  REVIEW = "review",
}
