/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  UseDisclosureReturn,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";

import { useCurrentUserStore } from "../../store/current-user.store";
import { PasswordInput } from "../../components/forms/password-button";

import LogoImage from "../../assets/logo/trampay-rings.svg";
import SignInImage from "../../assets/sign-in-image.webp";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useTimer } from "../../components/Timer";

const signInFormSchema = z.object({
  email: z.string().email("Por favor insira um email válido"),
  password: z
    .string()
    .min(6, "Por favor digite uma senha com pelo menos 6 dígitos"),
});

type signInFormInputs = z.infer<typeof signInFormSchema>;

export function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<signInFormInputs>({
    defaultValues: {
      email: undefined,
      password: undefined,
    },
    resolver: zodResolver(signInFormSchema),
  });

  const queryClient = useQueryClient();

  const toast = useToast();

  const navigator = useNavigate();

  const signIn = useCurrentUserStore((state) => state.signIn);

  const user = useCurrentUserStore((state) => state.user);

  const checkLoggedUser = useCurrentUserStore((state) => state.refreshData);

  const getPermissions = useCurrentUserStore((state) => state.getPermissions);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const timer = useTimer({ limit: 10, isRegressive: true });

  const { startTimer, resetTimer } = timer;

  async function onSubmit(data: signInFormInputs) {
    try {
      const isTwoFactor = await signIn({
        email: data.email,
        password: data.password,
      });

      if (isTwoFactor) {
        onOpen();
        resetTimer();
        startTimer(30);
        return;
      }

      queryClient.invalidateQueries();

      toast({
        title: "Login feito com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      return navigator("/");
    } catch (error) {
      toast({
        title: "Erro de login",
        description: "Não foi possível fazer login",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    const hasToken = localStorage.getItem("trampay-token");
    (async () => {
      try {
        if (hasToken && !user) {
          await checkLoggedUser();
        }

        if (user) {
          await getPermissions();
          navigator("/");
        }
      } catch (error) {
        if (error instanceof Error) {
          toast({
            status: "error",
            title: "Não foi possível realizar o login",
            description: "Erro: " + error.message,
          });
          return;
        }

        toast({
          status: "error",
          title: "Não foi possível realizar o login",
        });
        console.log(error);
      }
    })();
  }, [user, checkLoggedUser, navigator, getPermissions, toast]);

  return (
    <Flex
      w="100vw"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      gap={16}
      px={4}
      as="main"
    >
      <TwoFactorModal
        queryClient={queryClient}
        disclosure={{ isOpen, onClose }}
        timer={timer}
      />
      <HStack
        bg="gray.50"
        borderRadius={48}
        p={0}
        maxW="65rem"
        maxH="2xl"
        w="full"
        h="full"
        overflow="hidden"
        shadow="lg"
      >
        <Container h="full" w="full" p={0} maxW="50%">
          <Image
            src={SignInImage}
            alt="Trampay logo com titulo"
            objectFit="cover"
            loading="eager"
            h="full"
            w="full"
          />
        </Container>
        <VStack p={14} borderRadius="lg" w="full" gap={4} flex={1}>
          <Heading size="lg">Faça seu login</Heading>
          <Box
            as="form"
            display="flex"
            flexDir="column"
            gap={8}
            w="full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                size="lg"
                _placeholder={{
                  color: "gray.500",
                }}
                {...register("email")}
                title="Email"
                placeholder="example@email.com"
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel htmlFor="password">Senha</FormLabel>
              <PasswordInput
                id="password"
                size="lg"
                _placeholder={{
                  color: "gray.500",
                }}
                {...register("password")}
                title="Password"
                placeholder="Digite sua senha..."
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Button
              variant={"link"}
              onClick={() =>
                toast({
                  status: "info",
                  title: "Contate o suporte",
                  description: "Contate o suporte para obter ajuda!",
                })
              }
            >
              Esqueceu sua senha?
            </Button>
            <Flex justifyContent="end" gap={4} alignItems="center">
              <Button
                isLoading={isSubmitting}
                type="submit"
                size="lg"
                w="full"
                bg="green.400"
                fontWeight="bold"
                shadow="lg"
                _hover={{
                  bg: "green.700",
                }}
              >
                Login
              </Button>
            </Flex>
          </Box>
          {/* <VStack gap={4}>
            <Heading size="md">Não tem conta?</Heading>
            <Button>
              <Link>Cadastrar</Link>
            </Button>
          </VStack> */}
          <Image
            src={LogoImage}
            alt="Trampay logo com titulo"
            objectFit="cover"
            w={75}
          />
          <Text color="gray.500">All rights reserved &copy; Trampay</Text>
        </VStack>
      </HStack>
    </Flex>
  );
}

const twoFactorFormSchema = z.object({
  code: z.string().min(6, "Insira os 6 dígitos do código"),
});

type twoFactorFormInputs = z.infer<typeof twoFactorFormSchema>;

function TwoFactorModal({
  queryClient,
  disclosure,
  timer,
}: {
  queryClient: QueryClient;
  disclosure: Pick<UseDisclosureReturn, "isOpen" | "onClose">;
  timer: ReturnType<typeof useTimer>;
}) {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setError } = useForm<twoFactorFormInputs>({
    resolver: zodResolver(signInFormSchema),
    mode: "onChange",
  });

  const toast = useToast();

  const navigator = useNavigate();

  const verifyTwoFactorCode = useCurrentUserStore(
    (state) => state.verifyTwoFactorCode
  );
  const twoFactorHash = useCurrentUserStore((state) => state.twoFactorHash);
  const sendTwoFactorCode = useCurrentUserStore(
    (state) => state.sendTwoFactorCode
  );

  async function sendCode({ code }: twoFactorFormInputs) {
    try {
      await verifyTwoFactorCode(Number(code));

      queryClient.invalidateQueries();

      toast({
        title: "Login feito com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      return navigator("/");
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Erro de login",
          description: "Erro: " + error?.response?.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setError("code", {
          message: error.response?.data.message,
        });
        return;
      }

      setError("code", {
        message: "Não foi possível verificar o código, contate o suporte",
      });

      toast({
        title: "Erro de login",
        description: "Não foi possível verificar o código, contate o suporte",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  function close() {
    timer.resetTimer();
    disclosure.onClose();
  }

  async function resendCodeEmail() {
    setLoading(true);

    try {
      if (twoFactorHash === null) return close();

      await sendTwoFactorCode(twoFactorHash);
      timer.startTimer(2 * 60);

      toast({
        title: "Código reenviado",
        status: "success",
        description: "Um novo código foi enviado para sua caixa de e-mail",
        duration: 3000,
        isClosable: true,
      });

      return navigator("/");
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          title: "Erro de reenvio",
          description: "Erro: " + error?.response?.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      toast({
        title: "Erro de reenvio",
        description: "Não foi possível reenviar código, contate o suporte",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal isOpen={disclosure.isOpen} onClose={close} isCentered size="lg">
      <ModalOverlay />
      <ModalContent padding={4}>
        <ModalHeader>Autenticação de dois fatores</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody
          as="form"
          id="two-factor-form"
          onSubmit={handleSubmit(sendCode)}
          display="flex"
          alignItems="center"
          flexDir="column"
          gap={8}
        >
          <Text fontSize="md">
            Enviamos para o e-mail cadastrado o código de verificação, por favor
            insira o código abaixo. Caso não tenha encontrado, verifique a caixa
            de spam.
          </Text>
          <Controller
            control={control}
            name="code"
            render={({ field, fieldState }) => (
              <FormControl
                w="full"
                as="fieldset"
                display="flex"
                flexDir="column"
                alignItems="center"
                isInvalid={!!fieldState.error}
              >
                <HStack>
                  <PinInput
                    type="number"
                    colorScheme="green"
                    {...field}
                    isInvalid={!!fieldState.error}
                    onComplete={(value) => sendCode({ code: value })}
                    size="lg"
                    focusBorderColor="green.500"
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                {fieldState.error?.message && (
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
          />
        </ModalBody>
        <ModalFooter
          display="flex"
          justifyContent="flex-end"
          gap={4}
          alignItems="center"
        >
          {timer.timeString ? (
            <Text lineHeight="normal">{timer.timeString}</Text>
          ) : null}
          <Button
            variant="link"
            colorScheme="green"
            isDisabled={timer.counter !== null || loading}
            onClick={resendCodeEmail}
          >
            Reenviar código
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
