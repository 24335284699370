export enum ID_SERVICES_GROUP {
  PIX = 1,
  P2P = 2,
  BILL_PAYMENTS = 3,
  PIX_WITHDRAW_AND_CHANGE = 4,
  TED = 5,
}

export const idServiceGroupTextMap = {
  [ID_SERVICES_GROUP.PIX]: "PIX",
  [ID_SERVICES_GROUP.P2P]: "P2P",
  [ID_SERVICES_GROUP.BILL_PAYMENTS]: "PAGAMENTO DE CONTAS",
  [ID_SERVICES_GROUP.PIX_WITHDRAW_AND_CHANGE]: "PIX SAQUE E TROCO",
  [ID_SERVICES_GROUP.TED]: "TED",
};

export enum BENEFICIARY_TYPE {
  NATURAL_PERSON = "F",
  LEGAL_PERSON = "J",
}

export enum LIMIT_TYPE {
  DAYTIME_TRANSACTIONAL_LIMIT = "1",
  DAYTIME_TOTAL_LIMIT = "2",
  MONTHLY_LIMIT = "3",
  NIGHTTIME_TOTAL_LIMIT = "4",
  NIGHTTIME_TRANSACTIONAL_LIMIT = "5",
}

export const limitTypeTextMap = {
  [LIMIT_TYPE.DAYTIME_TRANSACTIONAL_LIMIT]: "Limite diário por transação",
  [LIMIT_TYPE.DAYTIME_TOTAL_LIMIT]: "Limite diário total",
  [LIMIT_TYPE.MONTHLY_LIMIT]: "Limite mensal",
  [LIMIT_TYPE.NIGHTTIME_TOTAL_LIMIT]: "Limite noturno total",
  [LIMIT_TYPE.NIGHTTIME_TRANSACTIONAL_LIMIT]: "Limite noturno por transação",
};

export enum BankLimitStatus {
  DENIED = "Negado",
  APPROVED = "Aprovado",
  PENDING = "Pendente",
}
