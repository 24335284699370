import { z } from "zod";
import { useUsersDashboardStore } from "../../../../store/user-company.store";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flex, Heading } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  ToastId,
  useToast,
} from "@chakra-ui/react";
import { useLoaderData, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LoadingLogo } from "../../../../components/loading";
import { UserDto } from "../../../../dto/user-dto";

const editUserFormSchema = z
  .object({
    email: z.string().email("Insira um email válido").optional(),
    password: z.string().optional(),
    confirmPassword: z.string().optional(),
    firstName: z.string(),
    lastName: z.string(),
    company: z.coerce.number().positive(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "As senhas não batem",
    path: ["confirmPassword"],
  });

type editUserFormInputs = z.infer<typeof editUserFormSchema>;

interface EditUserFormProps {
  user: UserDto;
  onSubmit: (formData: editUserFormInputs) => Promise<ToastId | undefined>;
}

function EditUserForm({ user, onSubmit }: EditUserFormProps) {
  const { companies } = useLoaderData() as {
    companies: { id: number; name: string }[];
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<editUserFormInputs>({
    resolver: zodResolver(editUserFormSchema),
    defaultValues: {
      company: Number(user?.company?.id),
      email: user?.email || undefined,
      firstName: user?.firstName || undefined,
      lastName: user?.lastName || undefined,
    },
  });

  return (
    <Flex
      as="form"
      flexDir="column"
      w="full"
      h="full"
      gap={6}
      borderRadius={8}
      bg="whiteAlpha.800"
      id="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex gap={8}>
        <FormControl as="fieldset" isInvalid={!!errors.firstName} isRequired>
          <FormLabel>Nome</FormLabel>
          <Input {...register("firstName")} />
          {errors.firstName ? (
            <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl as="fieldset" isInvalid={!!errors.lastName} isRequired>
          <FormLabel>Sobrenome</FormLabel>
          <Input {...register("lastName")} />
          {errors.lastName ? (
            <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>
      <Flex gap={8}>
        <FormControl as="fieldset" isInvalid={!!errors.email} isRequired>
          <FormLabel>Email</FormLabel>
          <Input {...register("email")} />
          {errors.email ? (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl as="fieldset" isInvalid={!!errors.company} isRequired>
          <FormLabel>Empresa</FormLabel>
          <Select placeholder="Selecione a empresa" {...register("company")}>
            {companies?.map((company) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </Select>
          {errors.company ? (
            <FormErrorMessage>{errors.company.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>
      <Flex gap={8}>
        <FormControl as="fieldset" isInvalid={!!errors.password}>
          <FormLabel>Senha</FormLabel>
          <Input {...register("password")} />
          {errors.password ? (
            <FormErrorMessage>{errors.password.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl as="fieldset" isInvalid={!!errors.confirmPassword}>
          <FormLabel>Confirme a senha</FormLabel>
          <Input {...register("confirmPassword")} />
          {errors.confirmPassword ? (
            <FormErrorMessage>
              {errors.confirmPassword.message}
            </FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>
      <Button
        type="submit"
        form="form"
        size="lg"
        alignSelf="flex-end"
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  );
}

export function EditUser() {
  const client = useQueryClient();
  const getUser = useUsersDashboardStore((state) => state.getUser);
  const editUser = useUsersDashboardStore((state) => state.editUser);
  const user = useUsersDashboardStore((state) => state.user);

  const toast = useToast();
  const { id } = useParams();

  const fetchUserData = async () => {
    try {
      const data = await getUser(id as string);
      return data;
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Erro ao buscar dados do usuário: " + error,
        duration: 2000,
      });
    }
  };

  const { refetch, data } = useQuery(["user-details", id], fetchUserData);

  async function onSubmit(formData: editUserFormInputs) {
    if (!formData.password) {
      delete formData.password;
    }

    if (!formData.confirmPassword) {
      delete formData.confirmPassword;
    }

    if (formData.email === user?.email) {
      delete formData.email;
    }

    const formatData = {
      ...formData,
      company: {
        id: formData.company,
      },
      status: {
        id: 1,
      },
    };

    try {
      if (!id) {
        return toast({
          status: "error",
          title: "Não foi possível editar o usuário",
          description: "Não foi possível editar o usuário, contate o suporte",
          duration: 2000,
        });
      }

      await editUser(id, formatData);
      client.invalidateQueries(["list-users"]);
      refetch();
      toast({
        status: "success",
        title: "Usuário editado com sucesso!",
        duration: 2000,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          title: "Não foi possível editar o usuário",
          description: `Não foi possível editar o usuário, contate o suporte: ${error.message}`,
          duration: 2000,
        });
        return;
      }
      toast({
        status: "error",
        title: "Não foi possível editar o usuário",
        description: `Não foi possível editar o usuário, contate o suporte`,
        duration: 2000,
      });
    }
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <Heading size="lg">Editar usuário</Heading>

      {data ? (
        <EditUserForm user={data} onSubmit={onSubmit} />
      ) : (
        <LoadingLogo />
      )}
    </Flex>
  );
}
