import { useState } from "react";
import { getWithAuth } from "../../services/basicService";

export function useExportCsv() {
  const [loading, setLoading] = useState(false);

  const exportCsv = (company: string, companyId: number, queries: string) => {
    if (loading) return;
    setLoading(true);

    const ENDPOINT = `/api/v1/deliveryMan/csv/${companyId}?${queries}`;

    getWithAuth(
      ENDPOINT,
      {
        "Content-Type": "text/csv",
      },
      { responseType: "arraybuffer" as ResponseType }
    )
      .then((res) => {
        const csvBlob = new Blob([res?.data], { type: "text/csv" });
        const url = URL.createObjectURL(csvBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `entregadores-${company}.csv`);
        link.click();
        URL.revokeObjectURL(url);
      })
      .finally(() => setLoading(false));
  };

  return { exportCsv, loading };
}
