export enum PayrollStatus {
  NOT_SAVED = "not saved",
  DRAFT = "draft",
  CREATING = "creating",
  // PENDING_VALIDATION = 'pending_validation',
  PENDING_CONFIRMATION = "pending_confirmation",
  // PENDING_SCHEDULE = "pending_schedule",
  CONFIRMED = "confirmed",
  // SCHEDULED = "scheduled",
  PROCESSING = "processing",
  PROCESSED = "processed",
  PARTIALLY_PROCESSED = "partially_processed",
  REPROCESSED = "reprocessed",
  CANCELED = "canceled",
}
