import { Badge, HStack, VStack } from "@chakra-ui/layout";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { Pagination } from "../../../../../components/pagination";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import {
  TransactionDto,
  transactionColorMap,
  transactionTextMap,
} from "../../../../../dto/transaction-dto";
import { useState } from "react";
import { useTransactionsStore } from "../../../../../store/transaction.store";
import { Download } from "@phosphor-icons/react";
import { TransactionStatus } from "../../../../../enums/transaction-status";
import {
  TransactionTypes,
  transactionTypeTextMap,
} from "../../../../../enums/transaction-types.enum";
import { DeliverymanDto } from "../../../../../dto/deliveryman-dto";
import { getWithAuth } from "../../../../../services/basicService";
import { useQuery } from "@tanstack/react-query";

type dataResponse = {
  transactions: TransactionDto[];
  hasNextPage: boolean;
  page: number;
  totalEntries: number;
};

export function DeliverymanTransactions({
  deliveryman,
}: {
  deliveryman: DeliverymanDto;
}) {
  const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);

  const generatePdf = useTransactionsStore(
    (state) => state.generateTransactionPdf
  );

  const { data } = useQuery({
    queryKey: ["app-user-details", currentPage],
    queryFn: async () => {
      const response = await getWithAuth(
        `/api/v1/transaction/extract/all?name=${deliveryman.nameIfood}&page=${currentPage}`
      );
      return response?.data as dataResponse;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retryOnMount: false,
  });

  const { colorMode } = useColorMode();
  const toast = useToast();

  const handlePageChange = (page: number) => {
    if (!data?.hasNextPage) {
      return;
    }
    setCurrentPage(page);
  };

  async function handleDownload(id: number) {
    if (id) {
      try {
        setIsGeneratingPDF(true);
        await generatePdf(id);
      } catch (error) {
        toast({
          status: "error",
          title: "Erro ao fazer download do PDF",
          description: `Tente novamente mais tarde ou entre em contato com o suporte.`,
        });
      } finally {
        setIsGeneratingPDF(false);
      }
    }
  }

  return (
    <VStack gap={8} w="full">
      <TableContainer
        w="full"
        p={8}
        display="flex"
        flexDir="column"
        bg={colorMode === "dark" ? "gray.700" : "whiteAlpha.800"}
        borderColor={colorMode === "dark" ? "" : "gray.300"}
        shadow="base"
        style={{ containerType: "inline-size" }}
      >
        <Table colorScheme="green" variant="striped">
          <Thead>
            <Tr>
              <Th textAlign="center">Tipo de Transferência</Th>
              <Th textAlign="center">Pix Key</Th>
              <Th isNumeric>Valor</Th>
              <Th textAlign="center" cursor="pointer">
                Data de entrada
              </Th>
              <Th textAlign="center">Status</Th>
              <Th textAlign="center">Comprovante</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.transactions?.map((transaction) => {
              return (
                <Tr fontWeight={600} key={transaction.id}>
                  <Td textAlign="center">
                    {
                      transactionTypeTextMap[
                        transaction.type as TransactionTypes
                      ]
                    }
                  </Td>
                  <Td textAlign="center">{transaction.pixKey}</Td>
                  <Td isNumeric>{convertCentsToReais(transaction.amount)}</Td>
                  <Td textAlign="center">
                    {dayjs(transaction.createdAt).format("DD/MM/YYYY HH:mm")}
                  </Td>
                  <Td textAlign="center">
                    <Badge
                      variant="solid"
                      px={4}
                      backgroundColor={
                        transactionColorMap[
                          transaction.status as TransactionStatus
                        ]
                      }
                    >
                      {
                        transactionTextMap[
                          transaction.status as TransactionStatus
                        ]
                      }
                    </Badge>
                  </Td>
                  <Td textAlign="center">
                    {transaction.status === TransactionStatus.SUCCESS ||
                    transaction.status === TransactionStatus.APPROVED ? (
                      <Button
                        bg="green.600"
                        w="max-content"
                        color="whiteAlpha.900"
                        _hover={{
                          bg: "green.700",
                        }}
                        size="md"
                        alignSelf="flex-end"
                        isDisabled={isGeneratingPDF}
                        isLoading={isGeneratingPDF}
                        onClick={() => handleDownload(transaction.id)}
                      >
                        <Download weight="bold" size={24} />
                      </Button>
                    ) : null}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={data?.page || 1}
            setPage={
              handlePageChange as React.Dispatch<React.SetStateAction<number>>
            }
            totalPages={
              data?.totalEntries ? Math.floor(data.totalEntries / 10) : 1
            }
          />
        </HStack>
      </TableContainer>
    </VStack>
  );
}
