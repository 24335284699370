import {
  VStack,
  Heading,
  Flex,
  Box,
  HStack,
  Badge,
  Text,
} from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stat,
  StatLabel,
  StatNumber,
  useToast,
  FormErrorMessage,
  Select,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import {
  useCreatePayroll,
  usePayrollStore,
} from "../../../store/payroll.store";
import { useLoading } from "../../../store";
import { useTransactionsStore } from "../../../store/transaction.store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { formatCPF } from "../../../utils/format-document";
import { convertCentsToReais } from "../../../utils/convert-money";
import {
  useRouteError,
  useSearchParams,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Pagination } from "../../../components/pagination";
import {
  pixErrorTranslations,
  TransactionData,
} from "../../../dto/transactions-dto";
import { ArrowsClockwise, CaretLeft, Download } from "@phosphor-icons/react";
import { TransactionStatus } from "../../../enums/transaction-status";
import { PayrollDto } from "../../../dto/payroll-dto";
import {
  transactionColorMap,
  transactionTextMap,
} from "../../../dto/transaction-dto";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { CustomModal } from "../../../components/modal";
import { useModalStore } from "../../../store/components/modal.store";
import { TRAMPAY_COMPANY_ID } from "../../../utils/constants/trampay-company-id";
import { PayrollStatus } from "../../../enums/payroll-status";
import { useQuery } from "@tanstack/react-query";
import { LoadingLogo } from "../../../components/loading";
import { getWithAuth } from "../../../services/basicService";
import { useState } from "react";

const filterFormSchema = z.object({
  name: z.string().optional(),
  document: z
    .string()
    .max(11, "O CPF deve ter no máximo 11 caracteres")
    .optional(),
  status: z.string().optional(),
});

type filterFormInputs = z.infer<typeof filterFormSchema>;

export function PaymentDetails() {
  const generatePdf = useTransactionsStore(
    (state) => state.generateTransactionPdf
  );
  const generateAllTransactionsPdf = useTransactionsStore(
    (state) => state.generateAllTransactionsPdf
  );
  const [loadingButtom, setLoadingButtom] = useState<string | null>(null);
  const loading = useLoading((state) => state.loading);
  const showPDFModal = useModalStore((state) => state.generatingPdf);
  const reprocess = usePayrollStore((state) => state.reprocessPayroll);
  const changePayrollStatus = useCreatePayroll(
    (state) => state.changePayrollStatus
  );
  const setReprocessingScheduledFlag = useCreatePayroll(
    (state) => state.setReprocessingScheduledFlag
  );
  // const payroll = usePayrollStore((state) => state.payroll);
  const totalErrors = usePayrollStore((state) => state.totalErrorTransactions);
  const totalPending = usePayrollStore(
    (state) => state.totalPendingTransactions
  );
  const totalSuccess = usePayrollStore(
    (state) => state.totalSuccessTransactions
  );
  const me = useCurrentUserStore((state) => state.user);
  const generateTransactionsByPayrollCsv = useTransactionsStore(
    (state) => state.generateTransactionsByPayrollCsv
  );
  const routeFetchError = useRouteError();
  const toast = useToast();
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });
  const navigate = useNavigate();

  const name = searchParams.get("name");
  const document = searchParams.get("document");
  const status = searchParams.get("status");
  const page = searchParams.get("page");

  const {
    data: actualPayroll,
    isError,
    isFetching,
    isSuccess,
  } = useQuery({
    queryKey: ["payroll-transactions", name, document, status, page],
    queryFn: async () => {
      const convertedId = id as string;
      const payroll = await usePayrollStore.getState().getPayroll(+convertedId);

      if (
        me?.company?.id !== TRAMPAY_COMPANY_ID &&
        me?.company?.id !== payroll?.company_id
      ) {
        throw new Error("Você não tem permissão para acessar essa folha");
      }

      const { data: transactions } = (await getWithAuth(
        `/api/v1/transaction/allByPayrollId/${convertedId}?${searchParams.toString()}`
      )) as { data: TransactionData };

      if (payroll) {
        payroll.isToShowReprocessingButtons =
          payroll?.status === "processed" &&
          !transactions.result.some(
            (transaction) => transaction.status === "pending"
          );
      }
      return { payroll, transactions };
    },
    retry: false,
    enabled: !!id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<filterFormInputs>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: {
      document: document || undefined,
      name: name || undefined,
      status: status || undefined,
    },
  });

  if (isError) {
    throw new Error("Você não tem permissão para acessar essa folha");
  }

  if (isFetching) {
    return <LoadingLogo />;
  }

  if (routeFetchError) {
    toast({
      status: "error",
      title: "Erro ao carregar informação",
      description: `Erro: ${routeFetchError}`,
    });
  }

  function formatPage() {
    const page = searchParams.get("page");

    if (page) {
      return parseInt(page);
    }
    return 1;
  }

  async function onSubmit(data: filterFormInputs) {
    setSearchParams((prev) => {
      prev.set("page", "1");

      if (data.document) {
        prev.set("document", `${data.document}`);
      }
      if (data.name) {
        prev.set("name", `${data.name}`);
      }
      if (data.status) {
        prev.set("status", `${data.status}`);
      }
      if (!data.document) {
        prev.delete("document");
      }
      if (!data.name) {
        prev.delete("name");
      }
      if (!data.status) {
        prev.delete("status");
      }

      return prev;
    });
  }

  async function handleDownload(id: number | undefined, downloadAll: boolean) {
    if (!id) return;
    setLoadingButtom(downloadAll ? "resumePdf" : "generatePdf");
    try {
      downloadAll
        ? await generateAllTransactionsPdf(
            id,
            actualPayroll?.payroll?.name as string
          )
        : await generatePdf(id);
    } catch (error) {
      toast({
        status: "error",
        title: "Erro ao fazer download do PDF",
        description: `Tente novamente mais tarde ou entre em contato com o suporte.`,
      });
    } finally {
      setLoadingButtom(null);
    }
  }

  async function handleDownloadCSV() {
    if (id) {
      setLoadingButtom("csv");
      try {
        await generateTransactionsByPayrollCsv(id);
      } catch (error) {
        toast({
          status: "error",
          title: "Erro ao fazer download do CSV",
          description: `Tente novamente mais tarde ou entre em contato com o suporte.`,
        });
      } finally {
        setLoadingButtom(null);
      }
    }
  }

  function getTranslatedErrorMessage(errorKey: string) {
    return pixErrorTranslations[errorKey] || errorKey;
  }

  async function reprocessPayroll(
    payrollToBeReprocessed: PayrollDto,
    reprocessOnlyErrors: boolean
  ) {
    try {
      setLoadingButtom("reprocess");

      changePayrollStatus(false);

      await reprocess(payrollToBeReprocessed, reprocessOnlyErrors);

      setReprocessingScheduledFlag(
        Boolean(payrollToBeReprocessed.schedulingDate)
      );

      navigate(`/payments/create-transactions/${payrollToBeReprocessed.id}`, {
        relative: "route",
      });
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        title: "Erro ao enviar folha para reprocessamento",
        description: `Erro: ${error}`,
      });
    } finally {
      setLoadingButtom(null);
    }
  }
  return isSuccess ? (
    <VStack gap={8} w="full" alignItems="flex-start">
      <HStack w="full" alignItems="flex-start">
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <VStack>
          <Heading fontWeight="extrabold" textAlign="left">
            Transações da folha{" "}
            {actualPayroll.payroll?.type === "scheduled"
              ? "tipo agendada"
              : "processada"}
          </Heading>
          <Text fontSize="md" textAlign={"left"}>
            {actualPayroll.payroll?.name}
          </Text>
        </VStack>
      </HStack>
      <Flex
        gap={4}
        alignItems="center"
        style={{ containerType: "inline-size" }}
        w="full"
      >
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize="xl">Total de registros:</StatLabel>
            <StatNumber fontSize="3xl" color="green.500">
              {actualPayroll.transactions.totalRecords}
            </StatNumber>
          </Stat>
        </Box>
        {totalSuccess ? (
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize="xl">Total de sucessos:</StatLabel>
              <StatNumber fontSize="3xl" color="green.500">
                {totalSuccess}
              </StatNumber>
            </Stat>
          </Box>
        ) : null}
        {totalPending ? (
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={2}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize="xl">Total de pendentes:</StatLabel>
              <StatNumber fontSize="3xl" color="green.500">
                {totalPending}
              </StatNumber>
            </Stat>
          </Box>
        ) : null}
        {totalErrors ? (
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={2}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize="xl">Total de erros:</StatLabel>
              <StatNumber fontSize="3xl" color="green.500">
                {totalErrors}
              </StatNumber>
            </Stat>
          </Box>
        ) : null}
        {me?.companyId !== TRAMPAY_COMPANY_ID &&
        actualPayroll.payroll?.status === PayrollStatus.PROCESSED &&
        actualPayroll.payroll.isToShowReprocessingButtons &&
        !actualPayroll.payroll.schedulingDate ? (
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={2}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Text fontSize="xl" fontWeight="semibold" mb={2}>
              Reprocessar:
            </Text>
            <IconButton
              aria-label="Reprocessar planilha"
              isLoading={loadingButtom === "reprocess"}
              isDisabled={loadingButtom === "reprocess"}
              role="a"
              size="md"
              bg="green.500"
              color="white"
              _hover={{
                bg: "green.600",
              }}
              w="full"
              icon={<ArrowsClockwise size={20} />}
              onClick={() => {
                reprocessPayroll(actualPayroll.payroll as PayrollDto, false);
              }}
            />
          </Box>
        ) : null}

        {actualPayroll.payroll?.hasError &&
        me?.companyId !== TRAMPAY_COMPANY_ID &&
        actualPayroll.payroll.isToShowReprocessingButtons ? (
          <Tooltip
            label={
              actualPayroll.payroll.schedulingDate
                ? "Reprocessar resíduos da folha agendada"
                : ""
            }
          >
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={2}
              w="max-content"
              h="full"
              shadow="base"
            >
              <Text fontSize="xl" fontWeight="semibold" mb={2}>
                {actualPayroll.payroll.schedulingDate
                  ? "Reprocessar Resíduos"
                  : "Reprocessar erro"}
              </Text>
              <IconButton
                aria-label="Reprocessar planilha"
                isLoading={loading}
                isDisabled={
                  loading || actualPayroll.payroll?.status === "processing"
                }
                role="a"
                size="md"
                bg="red.500"
                color="white"
                _hover={{
                  bg: "green.600",
                }}
                w="full"
                icon={<ArrowsClockwise size={20} />}
                onClick={() => {
                  reprocessPayroll(actualPayroll.payroll as PayrollDto, true);
                }}
              />
            </Box>
          </Tooltip>
        ) : (
          ""
        )}
        <Flex alignItems="center" gap={15}>
          <Button
            loadingText="Exportando"
            role="a"
            size="lg"
            color="white"
            onClick={() => handleDownload(id ? +id : 0, true)}
            isDisabled={
              showPDFModal || actualPayroll.payroll?.status === "processing"
            }
            isLoading={loadingButtom === "resumePdf" || showPDFModal}
          >
            Exportar resumo PDF
          </Button>
        </Flex>
        <Flex alignItems="center" gap={15}>
          <Button
            loadingText="Exportando"
            role="a"
            size="lg"
            color="white"
            isDisabled={loadingButtom === "csv"}
            isLoading={loadingButtom === "csv"}
            onClick={handleDownloadCSV}
          >
            Exportar CSV
          </Button>
        </Flex>
      </Flex>

      <TableContainer
        w="full"
        flex={1}
        p={8}
        gap={4}
        display="flex"
        flexDir="column"
        border="1px"
        borderRadius={8}
        bg="whiteAlpha.800"
        borderColor="gray.300"
        shadow="base"
      >
        <Flex
          as="form"
          flexDir="column"
          gap={4}
          id="form"
          w="full"
          p={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>

          <Flex
            flexDir={{ base: "column", lg: "row" }}
            w="full"
            justifyContent="center"
            gap={8}
          >
            <FormControl as="fieldset">
              <FormLabel as="legend">Nome</FormLabel>
              <Input
                type="text"
                {...register("name")}
                fontWeight="bold"
                placeholder="Busque pelo nome"
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </FormControl>
            <FormControl as="fieldset" isInvalid={!!errors.document}>
              <FormLabel as="legend">CPF</FormLabel>
              <Input
                type="text"
                {...register("document")}
                bg="gray.100"
                fontWeight="bold"
                placeholder="Apenas números"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              {errors.document ? (
                <FormErrorMessage>{errors.document.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            {(actualPayroll.payroll?.status === PayrollStatus.PROCESSED ||
              actualPayroll.payroll?.status === PayrollStatus.REPROCESSED) && (
              <FormControl as="fieldset">
                <FormLabel as="legend">Status</FormLabel>
                <Select
                  variant="filled"
                  size="md"
                  {...register("status")}
                  w="full"
                >
                  <option value="">Todos</option>
                  <option value={TransactionStatus.SUCCESS}>Sucesso</option>
                  <option value={TransactionStatus.ERROR}>Erro</option>
                  <option value={TransactionStatus.PENDING}>Pendente</option>
                </Select>
              </FormControl>
            )}
            <Button
              type="submit"
              form="form"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={8}
              size="md"
              alignSelf="flex-end"
              isDisabled={!isDirty}
            >
              Filtrar
            </Button>
          </Flex>
        </Flex>

        <Table>
          <Thead>
            <Tr>
              <Th flex={1}>Nome</Th>
              <Th textAlign="center">CPF</Th>
              <Th isNumeric>Valor</Th>
              {actualPayroll.payroll?.status === PayrollStatus.PROCESSED && (
                <>
                  <Th textAlign="center">Status</Th>
                  <Th textAlign="center">Descrição do erro</Th>
                  <Th textAlign="center">Comprovante</Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {actualPayroll.transactions?.result.map((transaction) => {
              return (
                <Tr fontWeight={600} key={transaction.id}>
                  <Td
                    maxW="200px"
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    textTransform={"capitalize"}
                  >
                    {transaction.name}
                  </Td>
                  <Td textAlign="center">{formatCPF(transaction.document)}</Td>
                  <Td isNumeric>{convertCentsToReais(transaction.amount)}</Td>
                  <Td textAlign="center">
                    <Badge
                      variant="solid"
                      px={4}
                      backgroundColor={
                        transactionColorMap[
                          transaction.status as TransactionStatus
                        ]
                      }
                    >
                      {
                        transactionTextMap[
                          transaction.status as TransactionStatus
                        ]
                      }
                    </Badge>
                  </Td>
                  <Td textAlign="center">
                    {transaction.errorMessage
                      ? getTranslatedErrorMessage(transaction.errorMessage)
                      : null}
                  </Td>
                  <Td textAlign="center">
                    <Button
                      type="submit"
                      size="md"
                      alignSelf="flex-end"
                      onClick={() => {
                        handleDownload(transaction.id, false);
                      }}
                      isDisabled={
                        loadingButtom === `generatePdf-${transaction.id}`
                      }
                      isLoading={
                        loadingButtom === `generatePdf-${transaction.id}`
                      }
                    >
                      <Download weight="bold" size={24} />
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={formatPage()}
            setPage={(page) =>
              setSearchParams((prev) => {
                prev.set("page", String(page));
                return prev;
              })
            }
            totalPages={actualPayroll.transactions.totalPages}
          />
        </HStack>
      </TableContainer>

      <CustomModal
        modalHeader="Seus PDF está sendo gerado."
        confirmButtonText="Ok"
        modalBody="Aguarde um momento enquanto seu PDF é gerado. Essa ação pode demorar alguns segundos."
        modalIsOpen={showPDFModal}
        confirmButtonIsDisabled={showPDFModal}
      />
    </VStack>
  ) : (
    <></>
  );
}
