import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Box,
  Grid,
  FormErrorMessage,
} from "@chakra-ui/react";
import { CustomSelect, OptionsSelect } from "./custom-select";
import { ZodSchema, typeToFlattenedError } from "zod";

export interface FormField {
  label: string;
  name: string;
  type: "number" | "text" | "date" | "time" | "select";
  options?: OptionsSelect[];
}

interface FormProps {
  fields: FormField[];
  formSchema: ZodSchema;
  initialData: unknown;
  actionLabel?: string;
  onSubmit: (data: unknown) => void;
  columns?: number;
  layout?: "row" | "column";
}

function FieldInput({
  field,
  value,
  onChange,
}: {
  field: FormField;
  value: string | number | readonly string[];
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}) {
  if (field.type === "select") {
    return (
      <CustomSelect
        name={field.name}
        options={field.options}
        value={value}
        width="100%"
        onChange={onChange}
      />
    );
  }

  return (
    <Input
      type={field.type}
      name={field.name}
      value={value as string | number | readonly string[] | undefined}
      width="100%"
      onChange={onChange}
    />
  );
}

export function GenericForm({
  fields,
  formSchema,
  initialData,
  actionLabel,
  onSubmit,
  columns = 1,
  layout = "column",
}: FormProps) {
  const [formData, setFormData] = useState<Record<string, string>>(
    initialData as Record<string, string>
  );
  const [formErrors, setFormErrors] = useState<typeToFlattenedError<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    string
  > | null>(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getFieldError = (fieldName: string) => {
    return !!(formErrors && formErrors.fieldErrors[fieldName]);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const result = formSchema.safeParse(formData);

    if (result.success) {
      onSubmit(result.data);
    } else {
      setFormErrors(result.error.flatten());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {layout === "row" ? (
        <Flex as="form" flexDir="row" w="full" id="form">
          {fields.map((field) => (
            <Flex key={field.name} p={4}>
              <FormControl>
                <FormLabel>{field.label}</FormLabel>
                <FieldInput
                  field={field}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Grid
          templateColumns={`repeat(${columns}, 1fr)`}
          gap={8}
          p={4}
          borderRadius={8}
          id="form"
        >
          {fields?.map((field) => (
            <Box key={field.name}>
              <FormControl isInvalid={getFieldError(field.name)}>
                <FormLabel>{field.label}</FormLabel>
                <FieldInput
                  field={field}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{getFieldError(field.name)}</FormErrorMessage>
              </FormControl>
            </Box>
          ))}
        </Grid>
      )}

      <Flex p={4}>
        <Button type="submit" size="lg" color="white">
          {actionLabel || "Enviar"}
        </Button>
      </Flex>
    </form>
  );
}
