import { Flex } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { shallow } from "zustand/shallow";
import {
  useCompaniesStore,
  useCreateCompanyForm,
} from "../../store/company.store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { INTEGRATION } from "../../enums/company-integration";
import { TypeAdvancePeriod } from "../../enums/type-advance-period.enum";
import { COMPANY_TYPE, CompanyDto } from "../../dto/company-dto";
import { getWithAuth } from "../../services/basicService";
import { AxiosError } from "axios";
import { SpreadsheetTypes } from "../../enums/spreadsheet-types.enum";

const editCompanyFormSchema = z
  .object({
    name: z.string({ required_error: "Este campo é obrigatório" }),
    pracas: z.string().optional(),
    dailyFeePercentage: z.number(),
    dailyFeeFixed: z.number(),
    dailyDescriptionFee: z.string().optional(),
    integration: z.nativeEnum(INTEGRATION).optional(),
    socialReason: z.string(),
    cnpj: z.coerce
      .string()
      .length(14, "Preencha um CNPJ válido de 14 dígitos")
      .transform((value) => String(value)),
    periodType: z.nativeEnum(TypeAdvancePeriod).nullable().optional(),
    spreadsheetTypes: z.nativeEnum(SpreadsheetTypes).nullable().optional(),
    companyType: z.nativeEnum(COMPANY_TYPE).default(COMPANY_TYPE.NORMAL),
    franchisorId: z.string().or(z.number()).optional(),
    scheduledSplitFlow: z.boolean().optional(),
    conciliatedPaymentSplitFlow: z.boolean().optional(),
    splitFlow: z.string().optional(),
    amountDaysDueDateAfterEnd: z
      .number()
      .int("O valor tem que ser um inteiro")
      .min(0, "O valor tem que ser maior ou igual 0")
      .nullable()
      .optional(),
  })
  .refine(
    (data) => {
      if (data.companyType === COMPANY_TYPE.FRANQUEADO) {
        const hasFranchisorId = !!data.franchisorId;
        return hasFranchisorId;
      }

      return true;
    },
    {
      message: "Uma empresa franqueadora é necessária",
      path: ["franchisorId"],
    }
  );

type editCompanyFormInputs = z.infer<typeof editCompanyFormSchema>;

export function EditCompanyData() {
  const client = useQueryClient();

  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as CompanyDto[];
    },
  });

  const { companyData, getCompany } = useCompaniesStore(
    (state) => ({
      companyData: state.company,
      getCompany: state.getCompany,
    }),
    shallow
  );

  const { updateCompany } = useCreateCompanyForm(
    (state) => ({
      updateCompany: state.updateCompany,
    }),
    shallow
  );

  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<editCompanyFormInputs>({
    resolver: zodResolver(editCompanyFormSchema),
    defaultValues: companyData
      ? {
          name: companyData.name,
          cnpj: companyData.cnpj
            ? companyData.cnpj.replace(/[^0-9]/g, "")
            : undefined,
          dailyDescriptionFee: companyData.dailyDescriptionFee || "",
          dailyFeeFixed: Number(companyData.dailyFeeFixed),
          dailyFeePercentage: Number(companyData.dailyFeePercentage),
          pracas: companyData.pracas || "",
          socialReason: companyData?.razaoSocial,
          integration: companyData?.integration,
          periodType: companyData?.periodType,
          amountDaysDueDateAfterEnd: companyData?.amountDaysDueDateAfterEnd,
          companyType: companyData?.companyType,
          spreadsheetTypes: companyData?.spreadsheetTypes,
          scheduledSplitFlow: companyData?.scheduledSplitFlow,
          conciliatedPaymentSplitFlow: companyData?.conciliatedPaymentSplitFlow,
          splitFlow: companyData?.scheduledSplitFlow
            ? "scheduledSplitFlow"
            : companyData?.conciliatedPaymentSplitFlow
              ? "conciliatedSplitFlow"
              : "false",
          franchisorId: companyData?.franchisorId
            ? String(companyData?.franchisorId)
            : "",
        }
      : {},
  });

  const companyTypeWatch = watch("companyType");

  async function onSave(data: editCompanyFormInputs) {
    try {
      if (companyData?.id) {
        await updateCompany(companyData?.id, {
          name: data.name === companyData.name ? undefined : data.name,
          cnpj: data.cnpj,
          dailyDescriptionFee: data.dailyDescriptionFee || "",
          dailyFeeFixed: Number(data.dailyFeeFixed),
          dailyFeePercentage: Number(data.dailyFeePercentage),
          pracas: data.pracas || "",
          integration: data.integration,
          socialReason: data?.socialReason,
          periodType: data.periodType,
          amountDaysDueDateAfterEnd: data.amountDaysDueDateAfterEnd,
          companyType: data?.companyType,
          spreadsheetTypes: data.spreadsheetTypes,
          franchisorId:
            data?.franchisorId !== "" ? Number(data?.franchisorId) : undefined,
          scheduledSplitFlow: data?.splitFlow === "scheduledSplitFlow",
          conciliatedPaymentSplitFlow:
            data?.splitFlow === "conciliatedSplitFlow",
        });
        toast({
          status: "success",
          duration: 2000,
          title: "Dados da empresa atualizados com sucesso!",
        });
        await getCompany(companyData?.id);
        client.invalidateQueries(["companies"]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          status: "error",
          duration: 4000,
          title: "Não foi possível atualizar os dados da empresa!",
          description: error.response?.data.message,
        });
        return;
      }

      if (error instanceof Error) {
        toast({
          status: "error",
          duration: 2000,
          title: "Não foi possível atualizar os dados da empresa!",
          description: `Erro ao atualizar empresa: ${error.message}`,
        });
        return;
      }
      toast({
        status: "error",
        duration: 2000,
        title: "Não foi possível atualizar os dados da empresa!",
      });
    }
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <Flex
        as="form"
        flexDir="column"
        w="full"
        gap={8}
        flex={1}
        id="form"
        onSubmit={handleSubmit(onSave)}
      >
        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.name} isRequired>
            <FormLabel as="legend">Nome da Empresa</FormLabel>
            <Input
              type="text"
              {...register("name")}
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.name ? (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            ) : null}
          </FormControl>

          <FormControl as="fieldset" isInvalid={!!errors.splitFlow}>
            <FormLabel as="legend">Ferramenta de pagamento</FormLabel>
            <Select {...register("splitFlow")}>
              <option value="false">Somente avulso</option>
              <option value="scheduledSplitFlow">Split Agendado</option>
              <option value="conciliatedSplitFlow">
                Conciliação Automática
              </option>
            </Select>

            {errors.splitFlow && (
              <FormErrorMessage>{errors.splitFlow.message}</FormErrorMessage>
            )}
          </FormControl>
        </Flex>

        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.pracas}>
            <FormLabel as="legend">Praças</FormLabel>
            <Input
              type="text"
              {...register("pracas")}
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.pracas && (
              <FormErrorMessage>{errors.pracas.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.companyType}>
            <FormLabel as="legend">Tipo da empresa</FormLabel>
            <Select {...register("companyType")}>
              {Object.entries(COMPANY_TYPE).map(([label, value]) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
            {errors.companyType && (
              <FormErrorMessage>{errors.companyType.message}</FormErrorMessage>
            )}
          </FormControl>
          {companyTypeWatch === "franchisee" ? (
            <FormControl as="fieldset" isInvalid={!!errors.franchisorId}>
              <FormLabel as="legend">Franqueadora</FormLabel>
              <Select {...register("franchisorId")}>
                <option value={""}>Sem empresa franqueadora</option>
                {companies?.map((company) => (
                  <option value={company.id} key={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
              {errors.franchisorId && (
                <FormErrorMessage>
                  {errors.franchisorId.message}
                </FormErrorMessage>
              )}
            </FormControl>
          ) : null}
        </Flex>

        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.dailyFeePercentage}>
            <FormLabel as="legend">Taxa Percentual Diária</FormLabel>
            <NumberInput borderRadius={4} step={0.2}>
              <NumberInputField
                {...register("dailyFeePercentage", { valueAsNumber: true })}
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {errors.dailyFeePercentage && (
              <FormErrorMessage>
                {errors.dailyFeePercentage.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.dailyFeeFixed}>
            <FormLabel as="legend">Taxa Fixa Diária</FormLabel>
            <NumberInput borderRadius={4} step={0.2}>
              <NumberInputField
                {...register("dailyFeeFixed", { valueAsNumber: true })}
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {errors.dailyFeeFixed && (
              <FormErrorMessage>
                {errors.dailyFeeFixed.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.dailyDescriptionFee}>
            <FormLabel as="legend">Descrição Taxa Diária</FormLabel>
            <Input
              type="text"
              {...register("dailyDescriptionFee")}
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.dailyDescriptionFee && (
              <FormErrorMessage>
                {errors.dailyDescriptionFee.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>

        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl
            as="fieldset"
            isInvalid={!!errors.socialReason}
            isRequired
          >
            <FormLabel as="legend">Razão Social</FormLabel>
            <Input
              type="text"
              {...register("socialReason")}
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.socialReason && (
              <FormErrorMessage>{errors.socialReason.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.cnpj}>
            <FormLabel as="legend">CNPJ</FormLabel>
            <Input
              type="number"
              {...register("cnpj")}
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.cnpj && (
              <FormErrorMessage>{errors.cnpj.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl as="fieldset" isInvalid={!!errors.integration}>
            <FormLabel as="legend">Integração</FormLabel>
            <Select {...register("integration")}>
              {Object.entries(INTEGRATION).map(([label, value]) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>

            {errors.integration && (
              <FormErrorMessage>{errors.integration.message}</FormErrorMessage>
            )}
          </FormControl>
        </Flex>

        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.spreadsheetTypes}>
            <FormLabel as="legend">Tipo de Planilha Financeira</FormLabel>
            <Select {...register("spreadsheetTypes")}>
              {Object.entries(SpreadsheetTypes).map(([label, value]) => {
                const aux: Record<string, string> = {
                  FINANCIAL_AND_PERFORMANCE: "Financeiro e Perfomance",
                  FINANCIAL: "Financeiro",
                };
                return (
                  <option key={value} value={value}>
                    {aux[label] ?? "Não mapeado"}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          <FormControl as="fieldset" isInvalid={!!errors.periodType}>
            <FormLabel as="legend">Período do adiantamento</FormLabel>
            <Select {...register("periodType")}>
              {Object.entries(TypeAdvancePeriod).map(([label, value]) => {
                const aux: Record<string, string> = {
                  MONTH_FULL: "Mensal",
                  MONTH_HALF: "Quinzenal",
                  WEEKLY: "Semanal",
                  DAILY: "Diário",
                };

                return (
                  <option key={value} value={value}>
                    {aux[label] ?? "Não mapeado"}
                  </option>
                );
              })}
            </Select>

            {errors.periodType && (
              <FormErrorMessage>{errors.periodType.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            as="fieldset"
            isInvalid={!!errors.amountDaysDueDateAfterEnd}
          >
            <FormLabel as="legend">
              Dias adicionais para a data de vencimento
            </FormLabel>
            <NumberInput borderRadius={4} step={0.2}>
              <NumberInputField
                {...register("amountDaysDueDateAfterEnd", {
                  valueAsNumber: true,
                })}
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {errors.amountDaysDueDateAfterEnd && (
              <FormErrorMessage>
                {errors.amountDaysDueDateAfterEnd.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      </Flex>
      <Button
        type="submit"
        form="form"
        size="lg"
        alignSelf="flex-end"
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  );
}
