import React, { memo } from "react";
import { ShiftAssignment } from "../../../../../dto/shift-assignment-dto";
import { ShiftAssignmentStatus } from "../../../../../enums/shift-assignment-status";

import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";

import { ArrowFatLineUp } from "@phosphor-icons/react";
import { UseFormSetValue } from "react-hook-form";

const Component = ({
  row,
  setValue,
  handleOpenModal,
  setMinValue,
}: {
  row: Record<string, string | number>;
  setValue: UseFormSetValue<{
    vacancies: number;
    shiftAssignmentId: number;
  }>;
  handleOpenModal: (id: number) => void;
  setMinValue: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const sa = row as unknown as ShiftAssignment;
  const handleOpen = () => {
    setValue("vacancies", sa.maxDeliveryman);
    setValue("shiftAssignmentId", sa.id);
    setMinValue(sa.maxDeliveryman);
    handleOpenModal(sa.id);
  };

  if (
    sa.status === ShiftAssignmentStatus.PUBLISHED ||
    sa.status === ShiftAssignmentStatus.CREATED
  ) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        key={sa.id}
      >
        <Box>{sa.maxDeliveryman}</Box>
        <Button onClick={() => handleOpen()} size="sm">
          <ArrowFatLineUp size={20} />
        </Button>
      </Box>
    );
  }
  return <Box>{sa.maxDeliveryman}</Box>;
};

export const RowAddVacanciesComponent = memo(Component);
