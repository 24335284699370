import { AdvanceStatus } from "../enums/advance-status";
import { Transaction } from "./transactions-dto";

export enum Status {
  VALIDATED = "validated",
  APPROVED = "approved",
  CANCELED = "canceled",
  PROCESSING = "processing",
  PENDING_SIGNATURE = "pending signature",
}

export interface FinishStatus {
  id: number;
  createdAt: string;
  source: string;
  value: string;
}

export interface AdvanceUserDto {
  id: number;
  idEntregador: string;
  release_partner: string | null;
  userName: string;
  username: string;
  document: string;
  companyId: string;
  companyName: string;
  amount: number;
  amountFee: number;
  createdAt: string;
  status: AdvanceStatus;
  due_date: string | null;
  dueDate: string | null;
  attendantName: string | null;
  amountFeeFixed: number | null;
  amountFeePercentage: number | null;
  money_transfer_date: Date | null;
  praca: string;
  contract_number: string;
  money_transfer_request?: {
    data: {
      destinationAccount: number;
    };
  };
  money_transfer_response?: {
    transactionCode: string;
    transactionDate: Date;
  };
  amountReceivable: number;
  reason: string | null;
  statuses: FinishStatus[];
  square: string;
  datas_referencia?: string[];
}

export interface AdvanceUserData {
  currentPage: number;
  limit: number;
  totalPages: number;
  totalRecords: number;
  totalAmount: number;
  result: AdvanceUserDto[];
}

export interface AdvanceUserWithTransactionInfoDto extends AdvanceUserDto {
  transaction: Transaction;
}

export const colorSchemeMap = {
  [AdvanceStatus.APPROVED]: "green",
  [AdvanceStatus.CANCELED]: "red",
  [AdvanceStatus.VALIDATED]: "gray",
  [AdvanceStatus.PROCESSING]: "yellow",
  [AdvanceStatus.ERROR]: "gray",
  [AdvanceStatus.ERRORBMP]: "red",
  [AdvanceStatus.PENDING_SIGNATURE]: "facebook",
  [AdvanceStatus.REVIEW]: "yellow",
};

export const textSchemeMap = {
  [AdvanceStatus.APPROVED]: "Aprovado",
  [AdvanceStatus.CANCELED]: "Cancelado",
  [AdvanceStatus.VALIDATED]: "Aprovação pendente",
  [AdvanceStatus.PROCESSING]: "Processando",
  [AdvanceStatus.ERROR]: "Erro Dock",
  [AdvanceStatus.ERRORBMP]: "Erro BMP",
  [AdvanceStatus.PENDING_SIGNATURE]: "Falta Assinar",
  [AdvanceStatus.REVIEW]: "Em Revisão",
};
