import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getWithAuth } from "../../services/basicService";
import { GetUserWithMigrateBalances } from "../../types/get-user-with-migrated-balances.type";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const filterAdvanceFormSchema = z.object({
  search: z.string(),
});

type filterAdvanceFormInputs = z.infer<typeof filterAdvanceFormSchema>;

export function useGetBalanceMigrationUsers(basicUrl: string) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState<"DESC" | "ASC">("DESC");

  const { register, handleSubmit, getValues } =
    useForm<filterAdvanceFormInputs>({
      resolver: zodResolver(filterAdvanceFormSchema),
    });

  const fetchBalanceData = async () => {
    let URL = `${basicUrl}?page=${page}&limit=${limit}`;

    const { search } = getValues();

    if (search) {
      URL += `&document=${search}`;
    }

    try {
      const res = await getWithAuth(URL);

      return res?.data as GetUserWithMigrateBalances;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading, refetch } = useQuery(
    [basicUrl, page, limit, orderBy],
    fetchBalanceData
  );

  const getQueryParams = () => {
    let query = "";

    const { search } = getValues();

    if (search) {
      query += `document=${search}`;
    }

    return query;
  };

  return {
    data,
    isLoading,
    page,
    refetch,
    setPage,
    setLimit,
    setOrderBy,
    register,
    handleSubmit,
    getValues,
    getQueryParams,
  };
}
