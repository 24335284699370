import { VStack, Heading, HStack, Box, Flex, Text } from "@chakra-ui/layout";
import {
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
  useToast,
  IconButton,
  FormControl,
  FormLabel,
  Select,
  Button,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  Tooltip,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ArrowFatLinesRight, FileCsv } from "@phosphor-icons/react";
import { TRAMPAY_COMPANY_ID } from "../../../utils/constants/trampay-company-id";
import {
  PayrollDto,
  PayrollResult,
  payrollStatusTextMap,
} from "../../../dto/payroll-dto";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Pagination } from "../../../components/pagination";
import { LoadingLogo } from "../../../components/loading";
import { getWithAuth, patchWithAuth } from "../../../services/basicService";
import { z } from "zod";
import { Controller, UseFormRegister, useForm } from "react-hook-form";
import { COMPANY_TYPE, CompanyDto } from "../../../dto/company-dto";
import { PayrollStatus } from "../../../enums/payroll-status";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { isFranchisor } from "../../../utils/company-verifications";
import { Plaza } from "../../../dto/plaza-dto";
import { exportCsv } from "../../../utils/export-csv";
import {
  initialCreatePayrollState,
  useCreatePayroll,
} from "../../../store/payroll.store";

dayjs.extend(utc);

const filterScheduledPayrollSchema = z.object({
  companyId: z.string().optional(),
  status: z.string().optional(),
  plaza: z.string().optional(),
  dateRange: z
    .object({
      to: z.date().optional(),
      from: z.date().optional(),
    })
    .optional(),
});

type filterScheduledPayrollInputs = z.infer<
  typeof filterScheduledPayrollSchema
>;

export function PayrollScheduled() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({ page: "1" });
  const toast = useToast();
  const page = searchParams.get("page");
  const companyId = searchParams.get("companyId");
  const status = searchParams.get("status") as PayrollStatus;
  const schedulingStartDate = searchParams.get("schedulingStartDate");
  const schedulingEndDate = searchParams.get("schedulingEndDate");

  const allowServiceToMakeMassCancellation =
    status === PayrollStatus.PENDING_CONFIRMATION;
  const plaza = searchParams.get("plaza");

  const me = useCurrentUserStore((state) => state.user);
  const setScheduledFlag = useCreatePayroll((state) => state.setScheduledFlag);
  const setScheduledPayrollCreationStep = useCreatePayroll(
    (state) => state.setScheduledPayrollCreationStep
  );

  const {
    data: payrollsResult,
    error,
    isLoading,
  } = useQuery<PayrollResult>({
    queryKey: [
      "get-payrolls-scheduled",
      page,
      status,
      companyId,
      schedulingEndDate,
      schedulingStartDate,
      plaza,
    ],
    queryFn: async () => {
      const baseUrl = "/api/v1/payroll/sheets";
      const queryParams = searchParams.toString();
      // TODO: depois melhorar o back e consequentemente essa budega
      const route =
        me?.companyId === TRAMPAY_COMPANY_ID
          ? `${baseUrl}?type=scheduled&${queryParams}`
          : companyId
            ? `${baseUrl}/${companyId}?type=scheduled&${queryParams}`
            : `${baseUrl}/${me?.companyId}?type=scheduled&${queryParams}`;

      const response = await getWithAuth(route);
      return response?.data;
    },
  });

  const { data: pendingConfirmationPayrolls } = useQuery<PayrollResult>({
    queryKey: ["get-payrolls-scheduled-pending-confirmation"],
    queryFn: async () => {
      const baseUrl = "/api/v1/payroll/sheets";

      const route = `${baseUrl}/${me?.companyId}?type=scheduled&status=pending_confirmation`;

      const response = await getWithAuth(route);
      return response?.data;
    },
    enabled:
      me?.companyId !== TRAMPAY_COMPANY_ID &&
      !isFranchisor(me?.company as CompanyDto),
    initialData: {
      result: [],
      nextPage: false,
      totalPages: 1,
      totalRecords: 0,
    },
  });

  const {
    isOpen: modalCancelOpened,
    onClose: closeModalCancel,
    onOpen: openModalCancel,
  } = useDisclosure({
    id: "payroll-scheduled-cancel-modal",
  });

  const fetchFranchiseesOfCompany = useQuery(
    ["company-franchisees"],
    async () => {
      try {
        const response = await getWithAuth(
          `/api/v1/companies/franchisees/${me?.company?.id}`
        );
        return response?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: me?.company?.companyType === COMPANY_TYPE.FRANQUEADORA,
      initialData: () => ({ id: null, franchisees: [] as CompanyDto[] }),
    }
  );

  const { data: companies } = useQuery<{ name: string; id: string }[]>({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data;
    },
    enabled: me?.companyId === TRAMPAY_COMPANY_ID,
  });

  const { data: plazas } = useQuery({
    queryKey: ["all-plazas"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/plazas");
      return response?.data as Plaza[];
    },
    initialData: [],
  });

  const { handleSubmit, register, control, reset } =
    useForm<filterScheduledPayrollInputs>({
      defaultValues: {
        status: status || undefined,
        companyId: companyId !== null ? String(companyId) : undefined,
        plaza: plaza ?? undefined,
        dateRange: {
          from: schedulingStartDate
            ? dayjs(schedulingStartDate).toDate()
            : undefined,
          to: schedulingEndDate ? dayjs(schedulingEndDate).toDate() : undefined,
        },
      },
    });

  const mutation = useMutation(
    async (payroll: PayrollDto) => {
      const endpoint =
        payroll.status === PayrollStatus.PROCESSED
          ? "transaction/transactionsByPayroll"
          : "transaction-draft/transactionsDraftByPayroll";
      const response = await getWithAuth(
        `/api/v1/${endpoint}/csv/${payroll.id}`
      );
      return response?.data;
    },
    {
      onSuccess: (data, payroll) => {
        exportCsv(data, payroll.name);
      },
      onError: (error) => {
        console.log(error);
        toast({
          status: "error",
          title: "Erro ao exportar csv",
          description: `Erro: ${error}`,
        });
      },
    }
  );

  if (isLoading) {
    return <LoadingLogo />;
  }

  if (error) {
    toast({
      status: "error",
      title: "Erro ao carregar folhas",
      description: `Erro: ${error}`,
    });
  }

  const handlePageChange = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", page.toString());
      return prev;
    });
  };

  async function onSubmit(data: filterScheduledPayrollInputs) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("status");
      prev.delete("companyId");
      prev.delete("schedulingEndDate");
      prev.delete("schedulingStartDate");
      prev.delete("plaza");

      if (data?.companyId) {
        prev.set("companyId", data?.companyId.toString());
      }

      if (data?.status) {
        prev.set("status", data?.status);
      }

      if (data?.dateRange?.from) {
        prev.set(
          "schedulingStartDate",
          dayjs(data?.dateRange?.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.dateRange?.to) {
        prev.set(
          "schedulingEndDate",
          dayjs(data?.dateRange?.to).utc().format("YYYY-MM-DD")
        );
      }

      if (data.plaza) {
        prev.set("plaza", data.plaza);
      }

      return prev;
    });
  }
  const clearFilters = () => {
    setSearchParams((prev) => {
      prev.delete("status");
      prev.delete("companyId");
      prev.delete("schedulingEndDate");
      prev.delete("schedulingStartDate");
      prev.delete("plaza");
      return prev;
    });
    reset();
  };

  async function handleCancelPayroll() {
    try {
      await patchWithAuth("/api/v1/payroll/scheduled/cancel", {
        companyId,
        schedulingEndDate,
        schedulingStartDate,
      });

      toast({
        status: "success",
        title: "As folhas foram canceladas com sucesso",
      });

      closeModalCancel();
      onSubmit({
        status: PayrollStatus.PENDING_CONFIRMATION,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          title: "Não foi possível cancelar as folhas",
          description: error.message,
        });
      }
    }
  }

  function redirectToScheduledPayrollConfirmation() {
    const createPayrollState = {
      ...initialCreatePayrollState,
      isScheduledPayroll: true,
    };
    createPayrollState.step = 2;
    useCreatePayroll.setState(createPayrollState);
    setScheduledFlag(true);
    setScheduledPayrollCreationStep(2);
    navigate("../create-transactions/confirmation", {
      relative: "path",
    });
  }

  const DynamicSelect: React.FC<{
    label: string;
    options: {
      value: string;
      label: string;
    }[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: ReturnType<UseFormRegister<any>>;
    placeholder?: string;
    props?: unknown;
  }> = ({ label, options, register, placeholder, ...props }) => (
    <FormControl as="fieldset" {...props}>
      <FormLabel as="legend">{label}</FormLabel>
      <Select variant="filled" placeholder={placeholder} {...register}>
        {options.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
    </FormControl>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createOptions = (data: any[], valueKey: string, labelKey: string) => {
    return data.map((item) => ({
      value: item[valueKey],
      label: item[labelKey],
    }));
  };

  return (
    <VStack
      gap={8}
      w="full"
      flex={1}
      alignItems="flex-start"
      style={{ containerType: "inline-size" }}
    >
      <Heading fontWeight="extrabold" textAlign="left">
        Folhas tipo agendada
      </Heading>

      <Flex gap={8} alignItems="flex-start">
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          w="max-content"
          h="full"
          shadow="base"
          p={4}
        >
          <Stat>
            <StatLabel fontSize="xl">Total de registros:</StatLabel>
            <StatNumber fontSize="3xl" color="green.500">
              {Number(payrollsResult?.totalRecords)}
            </StatNumber>
          </Stat>
        </Box>

        {!isFranchisor(me?.company as CompanyDto) && (
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Text fontSize="large" textAlign="center" p={4}>
              Pendentes confirmar
            </Text>
            <Flex w="full" gap={8} justify="flex-end">
              <Flex gap={8} align="center">
                <Tooltip
                  hasArrow
                  label={`Filtre pelo status ${
                    payrollStatusTextMap[PayrollStatus.PENDING_CONFIRMATION]
                  } para habilitar a função.`}
                  bg="yellow.300"
                  color="black"
                  isDisabled={allowServiceToMakeMassCancellation}
                >
                  <Button
                    type="button"
                    bg="green.600"
                    w="max-content"
                    color="whiteAlpha.900"
                    _hover={{ bg: "green.700" }}
                    size="md"
                    marginStart="auto"
                    isDisabled={!allowServiceToMakeMassCancellation}
                    onClick={openModalCancel}
                  >
                    Cancelar
                  </Button>
                </Tooltip>

                {(pendingConfirmationPayrolls?.result || []).length > 0
                  ? me?.companyId !== TRAMPAY_COMPANY_ID && (
                      <Button
                        type="button"
                        bg="yellow.300"
                        w="max-content"
                        color="black"
                        _hover={{ bg: "yellow.500" }}
                        size="md"
                        marginStart="auto"
                        onClick={redirectToScheduledPayrollConfirmation}
                      >
                        Confirmar
                      </Button>
                    )
                  : me?.companyId !== TRAMPAY_COMPANY_ID && (
                      <Tooltip
                        hasArrow
                        label="Não há folhas agendadas pendentes de confirmação."
                        bg="yellow.300"
                        color="black"
                      >
                        <Button
                          type="button"
                          bg="yellow.300"
                          w="max-content"
                          color="black"
                          _hover={{ bg: "yellow.500" }}
                          size="md"
                          marginStart="auto"
                          isDisabled
                        >
                          Confirmar
                        </Button>
                      </Tooltip>
                    )}
              </Flex>
            </Flex>
          </Box>
        )}
      </Flex>

      <Modal
        isOpen={modalCancelOpened}
        onClose={closeModalCancel}
        id="confirm-transactions-modal"
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="red">ATENÇÃO!</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            alignItems="left"
            justifyContent="left"
            py={8}
          >
            <VStack>
              {schedulingStartDate &&
              schedulingEndDate &&
              schedulingStartDate !== schedulingEndDate ? (
                <Text fontSize="large" textAlign="justify">
                  Serão canceladas total de{" "}
                  {Number(payrollsResult?.totalRecords)} folhas agendadas
                  {` para as datas de ${dayjs(schedulingStartDate).format(
                    "DD/MM/YYYY"
                  )} à ${dayjs(schedulingEndDate).format("DD/MM/YYYY")}`}
                  {companyId
                    ? ` pertencentes a empresa ${
                        companies?.find(
                          (company) => Number(company.id) === Number(companyId)
                        )?.name
                      }.`
                    : " que sejam de qualquer uma das empresas vinculadas a sua."}
                </Text>
              ) : (
                <Text fontSize="large" textAlign="justify">
                  Selecione as datas inicial e final que deseja cancelar as
                  folhas agendadas.
                </Text>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter my={4}>
            {Number(payrollsResult?.totalRecords) > 0 ? (
              <>
                <Button variant="ghost" mr={3} onClick={closeModalCancel}>
                  Fechar
                </Button>
                <Button color="white" onClick={handleCancelPayroll}>
                  Continuar
                </Button>
              </>
            ) : (
              <Button variant="ghost" mr={3} onClick={closeModalCancel}>
                Fechar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={4}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={4}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={4}
          p={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex gap={8} alignItems="end">
            <FormControl as="fieldset" w="max-content">
              <FormLabel as="legend">Data do agendamento</FormLabel>
              <Popover placement="bottom">
                <PopoverTrigger>
                  <Button>
                    {!schedulingStartDate
                      ? "Escolha data do agendamento"
                      : !schedulingEndDate
                        ? schedulingStartDate
                        : `${schedulingStartDate.replaceAll(
                            "-",
                            "/"
                          )} a ${schedulingEndDate.replaceAll("-", "/")}`}
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent bg="white" p={2} w="max-content">
                    <PopoverBody>
                      <Controller
                        control={control}
                        name="dateRange"
                        render={({ field }) => (
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            initialFocus
                            selected={field.value as DateRange}
                            onSelect={field.onChange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        )}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </FormControl>

            <DynamicSelect
              label="Status da folha"
              options={createOptions(
                Object.entries(PayrollStatus).map(([, value]) => ({
                  value,
                  label: payrollStatusTextMap[value],
                })),
                "value",
                "label"
              )}
              register={register("status")}
              placeholder="Todos"
            />

            <DynamicSelect
              label="Praça"
              options={createOptions(
                Array.from(new Set(plazas.map((a) => a.name))).map((name) =>
                  plazas.find((a) => a.name === name)
                ),
                "name",
                "name"
              )}
              register={register("plaza")}
              placeholder="Todas"
            />
            {isFranchisor(me?.company as CompanyDto) && (
              <DynamicSelect
                label="Franqueada"
                options={createOptions(
                  fetchFranchiseesOfCompany?.data?.franchisees || [],
                  "id",
                  "name"
                )}
                register={register("companyId")}
                placeholder="Escolha uma franqueada"
              />
            )}

            {me?.companyId === TRAMPAY_COMPANY_ID && (
              <DynamicSelect
                label="Empresa"
                options={createOptions(companies || [], "id", "name")}
                register={register("companyId")}
                placeholder="Todas"
              />
            )}

            <Flex>
              <Button
                type="submit"
                bg="green.600"
                w="max-content"
                color="whiteAlpha.900"
                _hover={{ bg: "green.700" }}
                size="md"
              >
                Filtrar
              </Button>
            </Flex>
            <Flex>
              <Button w="max-content" size="md" onClick={clearFilters}>
                Limpar Filtros
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          p={4}
          gap={4}
          display="flex"
          flexDir="column"
          style={{
            containerType: "inline-size",
          }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th w="fit-content">Data de envio</Th>
                <Th w="fit-content" textAlign="center">
                  Data agendada
                </Th>
                <Th w="50%" textAlign="center">
                  Nome da folha
                </Th>
                <Th textAlign="center">Enviado por</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Praça</Th>
                {me?.companyId === TRAMPAY_COMPANY_ID ||
                isFranchisor(me?.company as CompanyDto) ? (
                  <Th textAlign="center">Empresa</Th>
                ) : null}
                <Th textAlign="center">Exportar CSV</Th>
                <Th textAlign="center">Consultar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payrollsResult?.result?.map((payroll) => (
                <Tr
                  key={payroll.id}
                  cursor="pointer"
                  onClick={() =>
                    navigate(
                      payroll.status === PayrollStatus.PROCESSED
                        ? `../details/${payroll.id}`
                        : `../scheduled/details/${payroll.id}`,
                      {
                        relative: "path",
                      }
                    )
                  }
                >
                  <Td>
                    {dayjs(payroll.created_at).utc().format("DD/MM/YYYY")}
                  </Td>
                  <Td textAlign="center">
                    {payroll.schedulingDate
                      ? dayjs(payroll.schedulingDate).utc().format("DD/MM/YYYY")
                      : "Próximo repasse"}
                  </Td>
                  <Td textAlign="center">{payroll.name}</Td>
                  <Td>{payroll?.user?.firstName}</Td>
                  <Td textAlign="center">
                    {payroll.status
                      ? payrollStatusTextMap[payroll.status as PayrollStatus]
                      : null}
                  </Td>
                  <Td textAlign="center">
                    {payroll.plaza ? payroll.plaza : "-"}
                  </Td>
                  {me?.companyId === TRAMPAY_COMPANY_ID ||
                  isFranchisor(me?.company as CompanyDto) ? (
                    <Td textAlign="center">{payroll.company.name}</Td>
                  ) : null}
                  <Td textAlign="center">
                    <IconButton
                      aria-label="exportar em csv"
                      role="a"
                      size="lg"
                      bg="green.500"
                      icon={<FileCsv size={20} />}
                      color="white"
                      px={4}
                      onClick={(e) => {
                        e.stopPropagation();
                        mutation.mutate(payroll);
                      }}
                      disabled={mutation.isLoading}
                      isLoading={mutation.isLoading}
                    />
                  </Td>
                  <Td textAlign="center">
                    <IconButton
                      aria-label="botão para ir para detalhes da folha agendada"
                      role="a"
                      size="lg"
                      bg="green.500"
                      icon={<ArrowFatLinesRight size={20} />}
                      color="white"
                      px={4}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={page ? Number(page) : 1}
            setPage={
              handlePageChange as React.Dispatch<React.SetStateAction<number>>
            }
            totalPages={
              payrollsResult?.totalPages ? payrollsResult?.totalPages : 1
            }
          />
        </HStack>
      </Box>
    </VStack>
  );
}
