export function formatCPF(cpf: string) {
  if (!cpf) {
    return "-";
  }

  cpf = cpf.replace(/\D/g, "");

  const formatedCpf = `${cpf.substring(0, 3)}.${cpf.substring(
    3,
    6
  )}.${cpf.substring(6, 9)}-${cpf.substring(9)}`;

  return formatedCpf;
}

export function formatCNPJ(cnpj: string) {
  if (!cnpj) {
    return "-";
  }

  cnpj = cnpj.replace(/\D/g, "");
  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
  return cnpj;
}

type documentType = "cpf" | "cnpj";

export function documentFormatter(
  document: string,
  documentType: documentType
) {
  if (documentType === "cpf") {
    document = document.replace(/\D/g, "");
    document = document.replace(/(\d{3})(\d)/, "$1.$2");
    document = document.replace(/(\d{3})(\d)/, "$1.$2");
    document = document.replace(/(\d{3})(\d{2})$/, "$1-$2");
    return document;
  }

  if (documentType === "cnpj") {
    document = document.replace(/\D/g, "");
    document = document.replace(/^(\d{2})(\d)/, "$1.$2");
    document = document.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    document = document.replace(/\.(\d{3})(\d)/, ".$1/$2");
    document = document.replace(/(\d{4})(\d)/, "$1-$2");
    return document;
  }

  return document;
}

export function formatReais(money: number): string {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return formatter.format(money);
}
