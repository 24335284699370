import { Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import { useMediaQuery, Avatar, Button } from "@chakra-ui/react";
import { Wallet, Plus, User, HandCoins } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import {
  Link,
  useNavigate,
  useNavigation,
  useRouteError,
} from "react-router-dom";
import { LoadingLogo } from "../../components/loading";
import { SideBar, SideBarMobile } from "../../components/sidebar/enterprise";
import { useCurrentUserStore, UserStore } from "../../store/current-user.store";
import { convertCentsToReais } from "../../utils/convert-money";
import { useQuery } from "@tanstack/react-query";

import { calculateTotalDebtAmount } from "../../utils/calculate-total-debt-amount";

export function DefaultErrorElementEnterprise() {
  const { state } = useNavigation();

  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const user = useCurrentUserStore((state: UserStore) => state.user);
  const getBankInfo = useCurrentUserStore(
    (state: UserStore) => state.getBankInfo
  );
  const error = useRouteError() as { message: string };

  const { data: bankInfo } = useQuery(["bankInfo"], getBankInfo, {
    placeholderData: {
      mainAccount: {
        bankBranchNumber: "0000",
        bankAccountNumber: "00000000",
        availableBalanceInCents: 0,
        bankAccountDigit: "",
        bankAccountType: "CC",
        bankBranchDigit: "",
        bankNumber: "00000000",
        status: "",
        pix: {
          pixKeys: [
            {
              keyType: "evp",
              key: "",
            },
          ],
        },
        debts: [],
      },
      paymentAccount: {
        bankBranchNumber: "0000",
        bankAccountNumber: "00000000",
        availableBalanceInCents: 0,
        bankAccountDigit: "",
        bankAccountType: "CC",
        bankBranchDigit: "",
        bankNumber: "00000000",
        status: "",
        pix: {
          pixKeys: [
            {
              keyType: "evp",
              key: "",
            },
          ],
        },
        debts: [],
      },
    },
  });

  const totalDebt = bankInfo
    ? calculateTotalDebtAmount(bankInfo?.mainAccount?.debts)
    : 0;

  const navigate = useNavigate();

  return (
    <Flex
      as="main"
      minH="100dvh"
      flex={1}
      bg="gray.100"
      position="relative"
      flexDir={isLargerThan800 ? "row" : "column"}
    >
      {isLargerThan800 ? <SideBar /> : <SideBarMobile />}
      <Flex w="full" flexDir="column">
        {isLargerThan800 ? (
          <Flex
            as="nav"
            w="full"
            px={16}
            py={3}
            gap={6}
            bg="blackAlpha.900"
            justifyContent="flex-end"
            alignItems="center"
            shadow="md"
          >
            {totalDebt ? (
              <Flex
                gap={2}
                w="max-content"
                color="whiteAlpha.900"
                bg="red.500"
                p={2}
                lineHeight={1}
                borderRadius={4}
                textAlign="center"
              >
                <HandCoins size={20} />
                <Text fontSize="xl">{convertCentsToReais(totalDebt * -1)}</Text>
              </Flex>
            ) : null}

            <Flex
              as={motion.div}
              bg="whiteAlpha.400"
              borderRadius={4}
              p={2}
              lineHeight={1}
              textAlign="center"
              fontSize="xl"
              color="whiteAlpha.900"
              gap={2}
            >
              <Wallet size={20} />
              {bankInfo?.mainAccount?.availableBalanceInCents
                ? convertCentsToReais(
                    bankInfo?.mainAccount?.availableBalanceInCents - totalDebt
                  )
                : convertCentsToReais(0)}
            </Flex>
            <Button
              as={Link}
              to="/settings/add-balance"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={2}
              size="md"
              gap={2}
            >
              <Plus weight="bold" size={16} />
            </Button>
            <Avatar
              border="1px"
              borderColor="whiteAlpha.900"
              name={user?.firstName + " " + user?.lastName}
              icon={<User size={24} />}
              onClick={() => navigate("/settings")}
              cursor="pointer"
              _hover={{
                borderWidth: "2px",
              }}
            />
          </Flex>
        ) : null}
        <Flex w="full" flex={1} py={16} px={8}>
          {state === "loading" ? (
            <>
              <LoadingLogo />
            </>
          ) : (
            <>
              <VStack>
                <Heading>Ops, ocorreu um erro 😯</Heading>

                {error.message ? (
                  <Text fontSize="xl">
                    Ocorreu um erro: {JSON.stringify(error.message)}
                  </Text>
                ) : (
                  <>
                    <Text>
                      Ocorreu um erro inesperado, tente voltar a{" "}
                      <Link to="/">Home</Link>
                    </Text>
                    <Text>{JSON.stringify(error)}</Text>
                  </>
                )}
              </VStack>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
