import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { z } from "zod";
import { FormField, GenericForm } from "../forms/generic-form";
import { useState } from "react";
import { LoadingLogo } from "../loading";
// import { usePerformanceService } from "../../services/api/performance";
import { CheckCircle } from "@phosphor-icons/react";
import { HttpStatusCode, isAxiosError } from "axios";
import { useBalanceService } from "../../services/api/balance";
import { UploadBalancesResponse } from "../../dto/upload-balances-response-dto";
import { totalDays } from "../../utils/date";
import { useConfigurationService } from "../../services/api/configuration";
import { AuthValidateCode } from "../ifood/auth/auth-validate-code";

interface Props {
  onSync: (
    balanceSyncResult: UploadBalancesResponse,
    performanceSyncResult: unknown,
    sendId: string
  ) => void;
}

export function UpdateByIfoodApi(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onSync } = props;
  const configurationService = useConfigurationService();

  enum STEP {
    SELECTING_DATE = 1,
    UPDATING = 2,
    UPDATED = 3,
    AUTHENTICATING = 4,
  }

  const [step, setStep] = useState<STEP>(STEP.SELECTING_DATE);

  const closeModal = () => {
    setStep(STEP.SELECTING_DATE);
    onClose();
  };

  function onLoggedInJarvis() {
    setStep(STEP.SELECTING_DATE);
  }

  const toast = useToast();

  const balanceService = useBalanceService();
  // const performanceService = usePerformanceService();

  async function onSubmit(data: unknown) {
    const COMPANY_CAN_SEND_BALANACE_ID = "companies_can_send_balance";
    const configuration = await configurationService.show(
      COMPANY_CAN_SEND_BALANACE_ID
    );

    if (configuration?.value !== "true") {
      const closedMessage = await configurationService.show(
        "phrase_closed_send_balance"
      );

      toast({
        title: "Subida de saldos indisponível.",
        description:
          closedMessage?.value ||
          "Não é possível subir saldos no momento, tente novamente em alguns minutos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setStep(STEP.UPDATING);

    const params = data as {
      startDate: Date;
      endDate: Date;
      sendId: string;
    };

    params.sendId = crypto.randomUUID();
    try {
      const balanceSyncResult = await balanceService.syncBalance(params);
      // const performanceSyncResult = await performanceService.syncPerformance(
      //  params
      // );
      const performanceSyncResult = [{}];
      onSync(balanceSyncResult, performanceSyncResult, params.sendId);
      setStep(STEP.UPDATED);
      toast({
        title: "Saldos e performaces atualizados com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      if (error) {
        if (!isAxiosError(error)) throw error;

        if (error.response?.status === HttpStatusCode.Forbidden) {
          setStep(STEP.AUTHENTICATING);
          toast({
            title: "Não autenticado",
            description: "Não foi possível se conectar ao Jarvis",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          setStep(STEP.SELECTING_DATE);
          toast({
            title: "Campos obrigatórios",
            description: "Preencha corretamente os campos",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
  }

  const formFields: FormField[] = [
    {
      label: "Data inicial",
      name: "startDate",
      type: "date",
    },
    {
      label: "Data final",
      name: "endDate",
      type: "date",
    },
  ];

  const formSchema = z
    .object({
      startDate: z.string(),
      endDate: z.string(),
    })
    .refine((data) => Date.parse(data.endDate) >= Date.parse(data.startDate), {
      message: "Data inicial não pode ser maior que a data final",
      path: ["startDate"],
    })
    .refine(
      (data) =>
        totalDays(new Date(data.endDate), new Date(data.startDate)) < 17,
      {
        message: "Não pode ser maior que 17 dias",
        path: ["startDate"],
      }
    );

  return (
    <div>
      <Button
        size="lg"
        w={["full", "auto"]}
        color="white"
        onClick={() => {
          onOpen();
        }}
      >
        Atualizar
      </Button>

      <Modal isOpen={isOpen} onClose={closeModal} size="5xl" id="upload-files">
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader>Atualizar Saldos e Performaces</ModalHeader>
          <ModalCloseButton />
          {step === STEP.SELECTING_DATE && (
            <ModalBody px={16}>
              <Flex gap={16} flexDir="column">
                <Heading size="lg">
                  Selecione o dia inicial e final para atualizar
                </Heading>
                <GenericForm
                  fields={formFields}
                  formSchema={formSchema}
                  columns={4}
                  actionLabel="Atualizar"
                  initialData={{}}
                  onSubmit={onSubmit}
                ></GenericForm>
              </Flex>
            </ModalBody>
          )}
          {step === STEP.UPDATING && (
            <ModalBody px={16}>
              <Flex gap={16} flexDir="column">
                <Heading size="lg">Atualizando...</Heading>
                <VStack w="full" h="full" align={"flex-start"}>
                  <LoadingLogo />
                </VStack>
              </Flex>
            </ModalBody>
          )}
          {step === STEP.UPDATED && (
            <ModalBody px={16}>
              <Flex gap={16} flexDir="column">
                <Heading size="lg">Atualização concluída</Heading>
                <VStack w="full" h="full" align={"flex-center"}>
                  <CheckCircle weight="bold" size={70} color="green" />
                </VStack>
              </Flex>
            </ModalBody>
          )}
          {step === STEP.AUTHENTICATING && (
            <ModalBody px={16}>
              <Flex gap={16} flexDir="column">
                <AuthValidateCode
                  isOpen={true}
                  onClose={onLoggedInJarvis}
                  onLogged={onLoggedInJarvis}
                ></AuthValidateCode>
              </Flex>
            </ModalBody>
          )}

          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={closeModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
