import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { ListQuestions } from "./list-questions";
import { CreateQuestion } from "./create-question";

export function Questionnaire() {
  return (
    <Tabs w="full" colorScheme="green">
      <TabList>
        <Tab>Perguntas</Tab>
        <Tab>Criar pergunta</Tab>
      </TabList>

      <TabPanels h="fit-content">
        <TabPanel>
          <ListQuestions />
        </TabPanel>
        <TabPanel>
          <CreateQuestion />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
