import { VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  UseStepsReturn,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useCreatePayroll } from "../../../../store/payroll.store";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { CompanyStatus } from "../../../../enums/company-status";
import { PayrollType } from "../../../../enums/payroll-type";
import { PayrollDto } from "../../../../dto/payroll-dto";
import { getWithAuth } from "../../../../services/basicService";

type Props = UseStepsReturn;

export function CreateReprocessingPayroll(props: Readonly<Props>) {
  const params = useParams();

  const company = useCurrentUserStore.getState().user?.company;
  const statusCompany = company?.statusId;

  if (Number(statusCompany) === CompanyStatus.INACTIVE) {
    throw new Error(" OL inativa. Entre em contato com o suporte!");
  }
  const createPayroll = useCreatePayroll((state) => state.createPayroll);
  const payrollLoadingState = useCreatePayroll((state) => state.isGetting);

  const { data: payroll } = useQuery({
    queryKey: [`payroll-${params?.id}`],
    queryFn: async (): Promise<PayrollDto | null> => {
      if (!params?.id) {
        return null;
      }

      const response = await getWithAuth(`/api/v1/payroll/${params.id}`);
      return response?.data as PayrollDto;
    },
  });

  const { handleSubmit, register } = useForm<{ payrollName: string }>();

  const toast = useToast({
    duration: 5000,
    isClosable: true,
  });

  const [scheduled] = useState<boolean>(false);

  async function handleCreatePayroll({ payrollName }: { payrollName: string }) {
    try {
      const type = scheduled ? PayrollType.SCHEDULED : PayrollType.INSTANT;

      const reprocessedOptions = payroll
        ? {
            isReprocessingScheduledPayroll: Boolean(payroll?.schedulingDate),
            reprocessedPayrollId: payroll.id,
          }
        : undefined;

      const response = await createPayroll(
        payrollName,
        type,
        reprocessedOptions
      );
      if (response) {
        props.goToNext();
      }

      toast({
        status: "success",
        title: "Folha de pagamento criada com sucesso",
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          status: "error",
          title: "Não foi possível criar a folha de pagamento",
          description: error.message,
        });
      }
    }
  }

  return (
    <VStack
      as="form"
      w="full"
      gap={8}
      onSubmit={handleSubmit(handleCreatePayroll)}
    >
      <FormControl display="flex" flexDir="column" alignItems="center">
        <FormLabel marginTop={8} marginBottom={4}>
          Qual o nome dessa folha de reprocessamento?
        </FormLabel>
        <Input
          placeholder="Insira aqui o nome para esse pagamento"
          bg="gray.100"
          maxW="3xl"
          {...register("payrollName")}
        />
        <Button
          marginTop={8}
          loadingText="Criando folha"
          size="lg"
          w={["full", "auto"]}
          color="white"
          type="submit"
          isLoading={payrollLoadingState}
          isDisabled={payrollLoadingState}
        >
          Criar folha Avulsa
        </Button>
      </FormControl>
    </VStack>
  );
}
