import { Flex, Heading } from "@chakra-ui/layout";
import { SubMenuButton } from "../../components/sub-menu/sub-menu-button";
import { useLocation } from "react-router-dom";

export function PortfolioManagementSubMenu() {
  const { pathname } = useLocation();

  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      flexDir="column"
      bg="whiteAlpha.800"
      borderRadius={8}
      h="min-content"
      gap={4}
      minW="sm"
      shadow="base"
      border="1px"
      borderColor="gray.300"
      position="sticky"
      top={8}
    >
      <Heading size="md">Menu Gestão de Carteira</Heading>

      <Flex w="full" justifyContent="flex-start" flexDir="column" gap={2}>
        <SubMenuButton
          to="/portfolio-management/create-credits"
          isSelected={
            pathname.includes("/portfolio-management/credits") ||
            pathname === "/portfolio-management "
          }
          fontSize="md"
        >
          Gestão de Crédito
        </SubMenuButton>
        <SubMenuButton
          to="/portfolio-management/scheduled-payroll"
          isSelected={pathname === "/portfolio-management/scheduled-payroll"}
          fontSize="md"
        >
          Gestão de Folha
        </SubMenuButton>
      </Flex>
    </Flex>
  );
}
