import { useCallback, useState, Ref, useImperativeHandle } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { api } from "../../services/api";
import { filesize } from "filesize";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Progress,
  forwardRef,
} from "@chakra-ui/react";
import {
  CheckCircle,
  File as FileIcon,
  FileCsv,
  Trash,
  XCircle,
} from "@phosphor-icons/react";
import { CSVContentWedukaForm } from "./csvContentWedukaForm";

interface IFile {
  id: string;
  name: string;
  readableSize: string;
  uploaded?: boolean;
  preview?: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url?: string;
  content?: string;
  contentType?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buffer?: any;
}

export interface IRef {
  processUpload: <T>({ sendId }: { sendId: string }) => Promise<T>;
  hasFiles: () => boolean;
}

interface IProps {
  maxFiles: number;
  uploadUrl: string;
  accept?: Accept;
  isPatch?: boolean;
}

export const DragDrop = forwardRef(function DragDrop(
  props: IProps,
  ref: Ref<IRef>
) {
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);

  // const updateFile = useCallback((id: string, data: Partial<IFile>) => {
  //   setUploadedFiles((state) =>
  //     state.map((file) => (file.id === id ? { ...file, ...data } : file)),
  //   )
  // }, [])

  const hasFiles = useCallback(() => uploadedFiles.length > 0, [uploadedFiles]);

  const processUpload = useCallback(
    async <T,>({ sendId }: { sendId: string }): Promise<T> => {
      const data = new FormData();
      data.append("sendId", sendId);

      uploadedFiles.forEach((file) => {
        if (file.file) {
          const modifiedFile = new Blob([file?.content as string], {
            type: file.contentType,
          });

          let newFile: File;
          try {
            newFile = new File([modifiedFile], file.name, {
              type: file.contentType,
            });
          } catch (error) {
            console.error("Erro ao criar o novo arquivo:", error);
            return;
          }

          data.append("files", newFile, file.name);
        }
      });

      const token = localStorage.getItem("trampay-token");

      const method = props.isPatch ? "patch" : "post";
      const response = await api[method](props.uploadUrl, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    [props.uploadUrl, uploadedFiles, props.isPatch]
  );

  useImperativeHandle(ref, () => ({
    processUpload,
    hasFiles,
  }));

  async function handleOnDrag(files: File[]) {
    if (uploadedFiles.length + files.length > props.maxFiles) {
      return;
    }

    const filesToUpload: IFile[] = await Promise.all(
      files.map(async (file) => {
        const content = await readFileContent(file);
        return {
          file,
          id: crypto.randomUUID(),
          name: file.name,
          readableSize: String(filesize(file.size)),
          progress: 0,
          uploaded: false,
          error: false,
          content,
          contentType: file.type,
        };
      })
    );

    if (props.maxFiles > 1) {
      setUploadedFiles((state) => state.concat(filesToUpload));
    }

    setUploadedFiles(filesToUpload);
    // filesToUpload.forEach((file) => processUpload(file))
  }

  function readFileContent(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  }

  function handleFileRemove(fileId: string) {
    const filteredList = uploadedFiles.filter((file) => file.id !== fileId);
    setUploadedFiles(filteredList);
  }

  return (
    <Flex flexDir="column" gap={4} overflow="hidden" as="div">
      <DragAndDropUpload
        maxFiles={props.maxFiles}
        uploadUrl={props.uploadUrl}
        handleOnDrag={handleOnDrag}
        accept={props.accept}
      />
      {uploadedFiles.map((file) => (
        <CSVContentWedukaForm
          key={file.id}
          fileName={file.name}
          content={file?.content || ""}
          onContentChange={(fileName, newContent) => {
            setUploadedFiles((prevUploadedFiles) =>
              prevUploadedFiles.map((f) =>
                f.name === fileName
                  ? {
                      ...f,
                      content: newContent,
                    }
                  : f
              )
            );
          }}
        />
      ))}
      {uploadedFiles.length ? (
        <FileList files={uploadedFiles} handleDeleteFile={handleFileRemove} />
      ) : null}
    </Flex>
  );
});

interface DragAndDropUploadProps {
  maxFiles: number;
  uploadUrl: string;
  accept?: Accept;
  handleOnDrag: (files: File[]) => void;
}

function DragAndDropUpload({
  accept = {
    "text/plain": [".csv"],
    "application/vnd.ms-excel": [".csv"],
  },
  ...props
}: DragAndDropUploadProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: props.maxFiles,
    accept,
    onDropAccepted: (files: File[]) => props.handleOnDrag(files),
  });

  return (
    <Box
      border="4px"
      borderColor={isDragActive ? "green.500" : "gray.300"}
      borderStyle="dashed"
      display="flex"
      flexDir="column"
      {...getRootProps()}
      p={16}
      borderRadius={8}
      alignItems="center"
      gap={4}
      bg="whiteAlpha.800"
    >
      <FileIcon weight="bold" size={48} />
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Solte aqui os arquivos selecionados</p>
      ) : (
        <p>Arraste e solte aqui os arquivos</p>
      )}
    </Box>
  );
}

interface FileListProps {
  files: IFile[];
  handleDeleteFile: (fileId: string) => void;
}

function FileList({ files, handleDeleteFile }: FileListProps) {
  return (
    <Flex gap={4} w="full" overflowX="auto" py={4}>
      {files.map((file) => (
        <Card w="150px" key={file.id} h="120px">
          <Button
            variant="ghost"
            position="absolute"
            left={0}
            top={0}
            onClick={() => handleDeleteFile(file.id)}
          >
            <Trash size={24} weight="fill" color="red" />
          </Button>
          {file.uploaded ? (
            <CheckCircle
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                transform: "translateX(30%) translateY(-30%)",
              }}
              size={24}
              weight="fill"
              color="green"
            />
          ) : null}
          {file.error ? (
            <XCircle
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                transform: "translateX(30%) translateY(-30%)",
              }}
              size={24}
              weight="fill"
              color="red"
            />
          ) : null}
          <CardBody
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            h="80%"
            gap={4}
          >
            <FileCsv weight="bold" size={48} />
            <span>{file.name.substring(0, 15).concat("...")}</span>
          </CardBody>
          <CardFooter p={0}>
            <Progress
              value={file.uploaded ? 100 : file.progress}
              hasStripe
              isAnimated
              colorScheme="green"
            />
          </CardFooter>
        </Card>
      ))}
    </Flex>
  );
}
