import { Box, UseRadioProps, useRadio } from "@chakra-ui/react";
import { ReactNode } from "react";

interface RadioCardProps extends UseRadioProps {
  children: ReactNode;
}

export function RadioCard(props: RadioCardProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius={8}
        boxShadow="base"
        _checked={{
          bg: "green.600",
          color: "whiteAlpha.900",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={8}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}
