import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { createWebviewToken } from "../../../../services/api/webview-emulator";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { RoleEnum } from "../../../../enums/roles-enum";

const webviewEmulatorFormSchema = z.object({
  document: z.string(),
});

type webviewEmulatorFormInputs = z.infer<typeof webviewEmulatorFormSchema>;

export function WebviewEmulation() {
  const [tabIndex, setTabIndex] = useState(0);
  const webViewRef = useRef<HTMLIFrameElement>(null);
  const uuidRef = useRef<HTMLParagraphElement>(null);

  const userRoles = useCurrentUserStore((state) => state.user?.roles);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<webviewEmulatorFormInputs>({
    resolver: zodResolver(webviewEmulatorFormSchema),
  });

  async function EmulateWebview({ document }: webviewEmulatorFormInputs) {
    const url = import.meta.env.VITE_WEBVIEW_URL;
    try {
      const response = await createWebviewToken(document);

      const data = response?.data;

      if (!uuidRef.current) return;

      uuidRef.current.innerHTML = `<strong>Token: </strong>${data?.uuid}`;

      if (!webViewRef.current) return;

      webViewRef.current.src =
        tabIndex === 0
          ? `${url}?identifier=${data?.uuid}`
          : `${url}/scales.html?identifier=${data?.uuid}`;
    } catch (error) {
      console.log("webview error =>", error);
    }
  }

  const hasEmulatorRole = userRoles?.find(
    (role) => role.name === RoleEnum.EMULADOR
  );

  if (!hasEmulatorRole)
    return (
      <Text>
        Você não tem permissão para essa função, contate seu supervisor
      </Text>
    );

  return (
    <HStack
      w="max-content"
      h="max-content"
      justifyContent="space-between"
      gap={16}
      alignItems="flex-start"
    >
      <Flex
        as="form"
        flexDir="column"
        padding={8}
        gap={8}
        onSubmit={handleSubmit(EmulateWebview)}
        bg="whiteAlpha.800"
        border="1px"
        borderColor="gray.300"
        borderRadius={8}
        shadow="base"
        p={4}
      >
        <Heading size="lg">Dados do usu&aacute;rio</Heading>
        <FormControl isInvalid={!!errors.document}>
          <FormLabel>Documento</FormLabel>
          <Input
            placeholder="Insira aqui o documento..."
            minW="md"
            {...register("document")}
          />

          {errors.document?.message && (
            <FormErrorMessage>{errors?.document.message}</FormErrorMessage>
          )}
        </FormControl>
        <Text ref={uuidRef} />
        <Button
          type="submit"
          size="lg"
          alignSelf="flex-end"
          isLoading={isSubmitting}
        >
          Emular
        </Button>
      </Flex>

      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        colorScheme="green"
        gap={4}
        variant="soft-rounded"
      >
        <TabList paddingBottom={4}>
          <Tab>Adiantamentos</Tab>
          <Tab>Agendamento</Tab>
        </TabList>
        {!webViewRef.current?.src ? (
          <Text>
            Nenhum token gerado. Selecione a webview nas abas acima, insira um
            documento e clique em emular
          </Text>
        ) : null}
        <iframe ref={webViewRef} width="365px" height="715px" />
      </Tabs>
    </HStack>
  );
}
