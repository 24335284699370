import { VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { postWithAuth } from "../../../../../services/basicService";

type FormSendMessage = {
  message: string;
  title?: string;
  isToSendToAllUsers: boolean;
};

export function SendToEveryoneTab() {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<FormSendMessage>({
    defaultValues: {
      isToSendToAllUsers: true,
    },
  });

  const submit = async (data: FormSendMessage) => {
    if (data.title?.trim() === "") {
      delete data.title;
    }

    try {
      await postWithAuth("/api/v1/app/send-message", data);

      toast({
        title: "Mensagem enviada",
        description: `A mensagem foi enviada com sucesso!`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });

      setValue("message", "");
      setValue("title", "");
    } catch (error) {
      toast({
        title: "Erro ao mandar a mensagem",
        description: "Não foi possível enviar a mensagem",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <VStack justifyContent="center" w="full">
      <FormControl
        as="form"
        w={{ lg: "40%", sm: "full" }}
        onSubmit={handleSubmit(submit)}
      >
        <FormLabel as="legend" fontSize="2xl" textAlign="center">
          Título da mensagem
        </FormLabel>
        <Input
          type="text"
          {...register("title", { required: false })}
          bg="whiteAlpha.800"
          placeholder="Título da mensagem *Opcional"
        />
        <FormLabel as="legend" fontSize="2xl" textAlign="center">
          Mensagem
        </FormLabel>
        <Textarea
          placeholder="Mensagem que será enviada"
          {...register(`message`, { required: true })}
          bg="whiteAlpha.800"
        />
        <Button
          mt={4}
          colorScheme="green"
          type="submit"
          width="full"
          isDisabled={!isValid}
        >
          Enviar mensagem
        </Button>
      </FormControl>
    </VStack>
  );
}
