import { Table, Thead, Tbody, Tr, Th, TableContainer } from "@chakra-ui/react";
import { memo } from "react";
import { TableRowWedukaCSV } from "./tableRowWedukaCSV";

// eslint-disable-next-line react/display-name
export const DataTableWeduka = memo(
  ({
    subPraza,
    period,
    vacancy,
    handleChangeSurplus,
    plaza,
  }: {
    subPraza: string;
    period: string;
    vacancy: { date: string; vacancies: number; surplus: number }[];
    handleChangeSurplus: (
      subPraza: string,
      date: string,
      period: string,
      value: number,
      plaza?: string
    ) => void;
    plaza?: string;
  }) => {
    return (
      <TableContainer
        sx={{
          border: "1px solid gray",
          borderRadius: "4px",
        }}
      >
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th>Data</Th>
              <Th>Vagas</Th>
              <Th>Excedentes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {vacancy.map((vacancy) => (
              <TableRowWedukaCSV
                key={vacancy.date}
                vacancy={vacancy}
                period={period}
                subPraza={subPraza}
                handleChangeSurplus={handleChangeSurplus}
                plaza={plaza}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
);
