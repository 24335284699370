import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Pagination } from "../../../../../components/pagination";
import dayjs from "dayjs";
import { z } from "zod";
import { useRouteError, useSearchParams } from "react-router-dom";
import { UserFeedbackQuestionState } from "../../../../../enums/user-feedback-questions-state.enum";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getWithAuth } from "../../../../../services/basicService";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import {
  UserFeedbackQuestionResponseDto,
  userFeedbackStateTextMap,
} from "../../../../../dto/user-feedback-question-dto";
import { LoadingLogo } from "../../../../../components/loading";
import { CalendarX } from "@phosphor-icons/react";
import { CustomModal } from "../../../../../components/modal";
import userFeedbackQuestionService from "../../../../../services/api/user-feedback-question";

const filtersFormSchema = z.object({
  dateRange: z
    .object({
      to: z.date().optional(),
      from: z.date().optional(),
    })
    .optional(),
  state: z.nativeEnum(UserFeedbackQuestionState),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

export function ListQuestions() {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const routeFetchError = useRouteError();
  const toast = useToast();
  const client = useQueryClient();

  if (routeFetchError) {
    toast({
      status: "error",
      title: "Erro ao carregar informação",
      description: `Erro: ${routeFetchError}`,
    });
  }

  const state = searchParams.get("state");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const page = searchParams.get("page");

  const { data: questions, isLoading } =
    useQuery<UserFeedbackQuestionResponseDto>({
      queryKey: ["list-questions", page, state, startDate, endDate],
      queryFn: async () => {
        const query = searchParams.toString();

        const response = await getWithAuth(
          `/api/v1/user-feedback-question?${query}`
        );
        return response?.data;
      },
    });

  function formatPage() {
    const page = searchParams.get("page");

    if (page) {
      return parseInt(page);
    }
    return 1;
  }

  const { handleSubmit, control, register } = useForm<filtersFormInput>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      state: (state as UserFeedbackQuestionState) || undefined,
      dateRange: {
        from: startDate ? dayjs(startDate).toDate() : undefined,
        to: endDate ? dayjs(endDate).toDate() : undefined,
      },
    },
  });

  async function onSubmit(data: filtersFormInput) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("endDate");
      prev.delete("startDate");
      prev.delete("state");
      if (data.dateRange?.from) {
        prev.set(
          "startDate",
          dayjs(data.dateRange.from).utc().format("YYYY-MM-DD")
        );

        if (!data.dateRange.to) {
          prev.set(
            "endDate",
            dayjs(data.dateRange.from).utc().format("YYYY-MM-DD")
          );
        }
      }
      if (data.dateRange?.to) {
        prev.set(
          "endDate",
          dayjs(data.dateRange.to).utc().format("YYYY-MM-DD")
        );
      }
      if (data.state) {
        prev.set("state", data.state);
      }
      return prev;
    });
  }

  const { mutate, isLoading: isLoadingMutation } = useMutation(
    userFeedbackQuestionService.disableQuetion,
    {
      onSuccess: () => {
        client.invalidateQueries(["list-questions"]);
      },
      onError: () => {
        toast({
          status: "error",
          title: "Erro ao inativar pergunta.",
          description: `Não foi possível inativar a pergunta`,
        });
      },
    }
  );

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={8}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={8}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
            gap={8}
          >
            <FormControl as="fieldset">
              <FormLabel as="legend">Status</FormLabel>
              <Select {...register("state")}>
                {Object.entries(UserFeedbackQuestionState).map(
                  ([key, value]) => (
                    <option key={key} value={value}>
                      {userFeedbackStateTextMap[value]}
                    </option>
                  )
                )}
              </Select>
            </FormControl>

            <FormControl as="fieldset" w="max-content">
              <FormLabel as="legend">Data de criação</FormLabel>
              <Popover placement="right-end">
                <PopoverTrigger>
                  <Button>
                    {!startDate
                      ? "Escolha a sua data"
                      : !endDate
                        ? startDate
                        : `${startDate.replaceAll(
                            "-",
                            "/"
                          )} a ${endDate.replaceAll("-", "/")}`}
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent bg="white" p={2} w="max-content">
                    <PopoverBody>
                      <Controller
                        control={control}
                        name="dateRange"
                        render={({ field }) => (
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            initialFocus={true}
                            selected={field.value as DateRange}
                            onSelect={field.onChange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        )}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </FormControl>

            <Button
              type="submit"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={8}
              size="md"
              alignSelf="flex-end"
            >
              Filtrar
            </Button>
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          pb={4}
          gap={4}
          display="flex"
          flexDir="column"
          style={{
            containerType: "inline-size",
          }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Pergunta</Th>
                <Th textAlign="center">Obrigatória?</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Data de criação</Th>
                <Th textAlign="center">Início</Th>
                <Th textAlign="center">Término</Th>
                <Th textAlign="center">Inativar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {questions?.data?.map((question) => (
                <Tr key={question.id}>
                  <Td whiteSpace="pre-wrap">{question.question}</Td>
                  <Td textAlign="center">
                    {question.isRequired ? "Sim" : "Não"}
                  </Td>
                  <Td textAlign="center">
                    <Badge colorScheme={question.isActive ? "green" : "red"}>
                      {question.isActive ? "Ativa" : "Inativa"}
                    </Badge>
                  </Td>
                  <Td textAlign="center">
                    {dayjs(question.createdAt).format("DD/MM/YYYY")}
                  </Td>
                  <Td textAlign="center">
                    {dayjs(question.startDate).format("DD/MM/YYYY")}
                  </Td>
                  <Td textAlign="center">
                    {question.endDate
                      ? dayjs(question.endDate).format("DD/MM/YYYY")
                      : "-"}
                  </Td>
                  <Td>
                    {question.isActive && (
                      <CustomModal
                        modalHeader="Inativar"
                        modalBody={`Tem certeza que deseja inativar a pergunta "${question.question}"?`}
                        confirmButtonText="Inativar"
                        buttonProps={{
                          bg: "transparent",
                          w: "max-content",
                          color: "red.600",
                          _hover: {
                            color: "red.800",
                          },
                          isLoading: isLoadingMutation,
                          leftIcon: <CalendarX size={28} />,
                        }}
                        onConfirm={() => mutate(question.id)}
                      />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={formatPage()}
            setPage={(page) =>
              setSearchParams((prev) => {
                prev.set("page", String(page));
                return prev;
              })
            }
            totalPages={questions?.totalPages || 1}
          />
        </HStack>
      </Box>
    </VStack>
  );
}
