import { Flex, Heading } from "@chakra-ui/layout";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { WarningOctagon } from "@phosphor-icons/react";
import { ErrorContainer } from "./error-container";
import { memo } from "react";
import { UploadEscalesResponseErrors } from "../../../../../types/upload.type";
const Compoennt = ({
  errors,
  readErrors,
  handleUpload,
  isSending,
  uploadFiles,
  fileUpload,
  exportScaledDeliverymen,
}: {
  errors: UploadEscalesResponseErrors | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readErrors: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleUpload: any;
  isSending: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadFiles: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileUpload: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportScaledDeliverymen: any;
}) => {
  return (
    <div>
      <Flex
        justifyContent="space-between"
        w="full"
        paddingBottom={2}
        flexDir="column"
        gap={8}
      >
        {errors ? (
          <Flex gap={4} flexDir={["column", "row"]}>
            <Button
              size="lg"
              variant="ghost"
              bg="red.200"
              _hover={{
                bgColor: "red.100",
              }}
              onClick={readErrors.onOpen}
              display="flex"
              gap={4}
            >
              <WarningOctagon color="red" size={24} />
              Falhas ao criar agendamento por planilha
            </Button>

            <Modal
              isOpen={readErrors.isOpen}
              onClose={readErrors.onClose}
              size="5xl"
              id="read-errors"
              scrollBehavior="inside"
            >
              <ModalOverlay />
              <ModalContent px={4}>
                <ModalHeader>Erros na planilha</ModalHeader>
                <ModalCloseButton />
                <ModalBody px={16}>
                  <ErrorContainer errors={errors} />
                </ModalBody>

                <ModalFooter my={4}>
                  <Button variant="ghost" mr={3} onClick={readErrors.onClose}>
                    Fechar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        ) : null}

        <Modal
          isOpen={uploadFiles.isOpen}
          onClose={uploadFiles.onClose}
          size="5xl"
          id="upload-files"
        >
          <ModalOverlay />
          <ModalContent mx={4}>
            <ModalHeader>Upload de Arquivos</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={16}>
              <Flex gap={16} flexDir="column">
                <Flex flexDir="column" gap={4}>
                  <Heading>Saldos</Heading>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter my={4}>
              <Button variant="ghost" mr={3} onClick={uploadFiles.onClose}>
                Fechar
              </Button>
              <Button
                color="white"
                onClick={handleUpload}
                isLoading={isSending}
                isDisabled={isSending}
              >
                Enviar .CSV
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Button as={Link} role="a" size="lg" color="white" to="create-one">
        Adicionar
      </Button>
      <Button
        as={Link}
        role="a"
        size="lg"
        color="white"
        ml={2}
        onClick={() => {
          fileUpload.onOpen();
        }}
      >
        Enviar .CSV
      </Button>
      <Button
        as={Link}
        role="a"
        size="lg"
        color="white"
        ml={2}
        onClick={() => {
          exportScaledDeliverymen.onOpen();
        }}
      >
        Agendados
      </Button>
    </div>
  );
};

export const AddButton = memo(Compoennt);
