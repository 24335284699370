import { useEffect, useState } from "react";
import { Badge, Box, Flex, Heading, HStack, VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  useDisclosure,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  PinInput,
  PinInputField,
  ModalFooter,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { AxiosError, isAxiosError } from "axios";
import { Pagination } from "../../../components/pagination";
import {
  ProductControlScheduledPayrollStatus,
  translateProductControlScheduledPayrollStatusMapper,
} from "../../../enums/product-control-scheduled-payroll-status.enum";
import {
  ConciliationValidationStatus,
  translateConciliationValidationStatusMapper,
} from "../../../enums/conciliation-validation-status.enum";
import { productControlScheduledPayrollQuery } from "../../../services/api/product-control-scheduled-payroll";
import { companyQuery } from "../../../services/api/companies";
import { mailService } from "../../../services/api/mails";
import { payrollService } from "../../../services/api/payroll";
import dayjs from "dayjs";
import ptBR from "dayjs/locale/pt-br";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { RoleEnum } from "../../../enums/roles-enum";

const searchFormSchema = z.object({
  search: z.string(),
});

type searchFormInputs = z.infer<typeof searchFormSchema>;

export function ProductControlScheduledPayrollList() {
  const [searchParams, setSearchParams] = useSearchParams({ page: "1" });
  const [companyFilter, setCompanyFilter] = useState<{ search?: string }>({});
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [deathLine, setDeathLine] = useState<Date>();
  const [timer, setTimer] = useState<string>();

  const page = Number(searchParams.get("page"));

  const user = useCurrentUserStore.getState().user;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<searchFormInputs>({
    resolver: zodResolver(searchFormSchema),
  });

  const search = watch("search");

  const { data: companyResult, isLoading: isLoadingCompany } =
    companyQuery().useFindByFilter(companyFilter, (error) => {
      if (isAxiosError(error)) {
        toast({
          status: "error",
          title: "Erro ao recuperar lista de empresas",
          description: error.response?.data.message,
          isClosable: true,
        });
      }

      toast({
        status: "error",
        title: "Erro ao recuperar lista de empresas",
        description: JSON.stringify(error),
        isClosable: true,
      });
    });

  const filteredCompanies = companyResult?.companies.map(
    (company) => company.id
  );

  const filter: {
    companyId?: number | number[];
  } = {
    companyId:
      filteredCompanies && filteredCompanies.length > 0
        ? filteredCompanies
        : -1,
  };

  const { data: productControlScheduledPayrollResult, isLoading } =
    productControlScheduledPayrollQuery().useFilterWithPagination(
      filter,
      {
        page,
        limit: 10,
      },
      (error) => {
        if (isAxiosError(error)) {
          toast({
            status: "error",
            title: "Erro ao recuperar status",
            description: error.response?.data.message,
            isClosable: true,
          });
        }

        toast({
          status: "error",
          title: "Erro ao recuperar status",
          description: JSON.stringify(error),
          isClosable: true,
        });
      }
    );

  const toast = useToast();

  async function handleSearch(data: searchFormInputs) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("search", data.search);
      return prev;
    });

    setCompanyFilter({ search });
  }

  const {
    isOpen: modalPinOpened,
    onClose: closeModalPin,
    onOpen: openModalPin,
  } = useDisclosure({
    id: "confirm-processing-modal",
  });

  const {
    control: codeControl,
    handleSubmit: codeHandleSubmit,
    reset,
    setError: codeSetError,
    formState: { isSubmitting, errors: codeErrors },
  } = useForm<{ pin: string }>();

  useEffect(() => {
    if (!modalPinOpened) {
      reset();
    }
  }, [modalPinOpened, reset]);

  async function handleConfirmProcessing(companyId: number) {
    openModalPin();

    setSelectedCompany(companyId);

    setDeathLine(dayjs().add(12, "minutes").toDate());

    try {
      await mailService().processScheduledPayrollsConfirmation();

      toast({
        status: "success",
        title: "Token de confirmação enviado para seu e-mail cadastrado",
      });
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        title: "Não foi possível enviar token de confirmação!",
      });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(dayjs(deathLine).locale(ptBR).fromNow());
    }, 1000);

    return () => clearInterval(interval);
  }, [deathLine]);

  async function handleConfirmationToken(data: { pin: string }) {
    try {
      if (!selectedCompany) {
        return toast({
          status: "error",
          title: "Não foi possível confirmar o processamento!",
          description: `Erro: Nenhuma empresa selecionada`,
          duration: 5000,
        });
      }

      await payrollService().processScheduledPayrollsByCompany({
        companyId: selectedCompany,
        confirmationCode: Number(data.pin),
      });

      toast({
        status: "success",
        title: "Token confirmado. As folhas serão processadas!",
      });
      closeModalPin();
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status !== 403) {
        return toast({
          status: "error",
          title: "Não foi possível confirmar o processamento!",
          description: `Erro: ${error.response?.data.message}`,
          duration: 5000,
        });
      }

      codeSetError("pin", {
        type: "Token Inválido",
        message: "Este token não é válido",
      });
      toast({
        status: "error",
        title: "Token inválido!",
      });
    }
  }

  return (
    <VStack gap={8} flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Gestão de Folha Agendada
      </Heading>

      <VStack w="full" h="full" gap={6} borderRadius={8}>
        <Modal
          isOpen={modalPinOpened}
          onClose={closeModalPin}
          id="confirm-processing-modal"
          size="xl"
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Entre com o PIN enviado ao seu e-mail</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={8}
            >
              <VStack
                gap={8}
                flexDir="column"
                as="form"
                onSubmit={codeHandleSubmit(handleConfirmationToken)}
                id="confirmation-token-form"
              >
                <HStack>
                  <Controller
                    control={codeControl}
                    name="pin"
                    render={({ field }) => (
                      <PinInput
                        type="number"
                        size="lg"
                        colorScheme="red"
                        onChange={field.onChange}
                        value={field.value}
                        isInvalid={!!codeErrors.pin}
                      >
                        <PinInputField
                          _focus={{
                            borderColor: "green.500",
                          }}
                          type="number"
                        />
                        <PinInputField
                          _focus={{
                            borderColor: "green.500",
                          }}
                          type="number"
                        />
                        <PinInputField
                          _focus={{
                            borderColor: "green.500",
                          }}
                          type="number"
                        />
                        <PinInputField
                          _focus={{
                            borderColor: "green.500",
                          }}
                          type="number"
                        />
                        <PinInputField
                          _focus={{
                            borderColor: "green.500",
                          }}
                          type="number"
                        />
                        <PinInputField
                          _focus={{
                            borderColor: "green.500",
                          }}
                          type="number"
                        />
                      </PinInput>
                    )}
                  />
                </HStack>

                <Heading size="md">Expira {timer}</Heading>
              </VStack>
            </ModalBody>

            <ModalFooter my={4}>
              <Button variant="ghost" mr={3} onClick={closeModalPin}>
                Fechar
              </Button>
              <Button
                color="white"
                type="submit"
                form="confirmation-token-form"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                loadingText="Checando"
              >
                Confirmar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          w="full"
          display="flex"
          flexDir="column"
          gap={8}
          flex={1}
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
        >
          <Flex
            as="form"
            w="full"
            gap={4}
            onSubmit={handleSubmit(handleSearch)}
            flexDir="column"
          >
            <Heading
              fontWeight="bold"
              textAlign="left"
              as="h2"
              fontSize="2xl"
            ></Heading>
            <Flex gap={4} w="full" alignItems="center">
              <FormControl as="fieldset" isInvalid={!!errors.search} maxW="3xl">
                <FormLabel as="legend">Nome da Empresa</FormLabel>
                <Input
                  {...register("search")}
                  placeholder="Busque por nome..."
                  size="lg"
                  maxW="3xl"
                  w="full"
                />
                {errors.search ? (
                  <FormErrorMessage>{errors.search.message}</FormErrorMessage>
                ) : null}
              </FormControl>
              <Button
                type="submit"
                title="search"
                size="lg"
                style={{ marginTop: "30px" }}
              >
                <MagnifyingGlass weight="bold" />
              </Button>
            </Flex>
          </Flex>
          <TableContainer
            w="full"
            display="flex"
            flexDir="column"
            flex={1}
            gap={8}
            pb={4}
            style={{
              containerType: "inline-size",
            }}
          >
            <Table variant="striped" colorScheme="green">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th flex={1}>Nome da Empresa</Th>
                  <Th>CNPJ</Th>
                  <Th>Período</Th>
                  <Th>Controle de Processos e Restrições</Th>
                  <Th textAlign="center">Conciliação Aprovada</Th>
                  <Th textAlign="center">Consolidação de Relatórios</Th>
                  <Th>Data de Quitação de Negativos</Th>
                  {user?.roles.find((role) =>
                    [
                      RoleEnum.ADMIN_TRAMPAY,
                      RoleEnum.FINANCEIRO_TRAMPAY,
                      RoleEnum.OPS_TRAMPAY,
                      RoleEnum.TECH_DADOS,
                    ].includes(role.name as RoleEnum)
                  ) && <Th>Envio de Conciliação para o Cliente</Th>}
                  {user?.roles.find(
                    (role) => role.name === RoleEnum.TECH_DADOS
                  ) && <Th textAlign="center">Processar</Th>}
                </Tr>
              </Thead>
              <Tbody>
                {!isLoading && !isLoadingCompany
                  ? productControlScheduledPayrollResult?.data?.map(
                      (productControl) => (
                        <Tr key={productControl.id}>
                          <Td>{productControl.id}</Td>
                          <Td>
                            {
                              companyResult?.companies?.find(
                                (company) =>
                                  company.id === productControl.companyId
                              )?.name
                            }
                          </Td>
                          <Td>
                            {
                              companyResult?.companies?.find(
                                (company) =>
                                  company.id === productControl.companyId
                              )?.cnpj
                            }
                          </Td>
                          <Td>{productControl.period}</Td>
                          <Td textAlign="center">
                            <Badge
                              variant="solid"
                              px={4}
                              colorScheme={
                                productControl.status ===
                                ProductControlScheduledPayrollStatus.PROCESSED_PAYROLLS
                                  ? "green"
                                  : [
                                        ProductControlScheduledPayrollStatus.DEADLINE_EXCEEDED,
                                        ProductControlScheduledPayrollStatus.JUDICIAL_RESTRICTION,
                                      ].includes(productControl.status)
                                    ? "red"
                                    : "gray"
                              }
                            >
                              {
                                translateProductControlScheduledPayrollStatusMapper[
                                  productControl.status
                                ]
                              }
                            </Badge>
                          </Td>
                          <Td textAlign="center">
                            <Badge
                              variant="solid"
                              px={4}
                              colorScheme={
                                productControl.conciliationValidationStatus ===
                                ConciliationValidationStatus.PENDING
                                  ? "gray"
                                  : productControl.conciliationValidationStatus ===
                                      ConciliationValidationStatus.FAILURE
                                    ? "red"
                                    : "green"
                              }
                            >
                              {
                                translateConciliationValidationStatusMapper[
                                  productControl.conciliationValidationStatus
                                ]
                              }
                            </Badge>
                          </Td>
                          <Td textAlign="center">
                            <Badge
                              variant="solid"
                              px={4}
                              colorScheme={
                                productControl.userPaymentBalancesSynchronized &&
                                productControl.companyFeeSynchronized
                                  ? "green"
                                  : "gray"
                              }
                            >
                              {productControl.userPaymentBalancesSynchronized &&
                              productControl.companyFeeSynchronized
                                ? "CONCLUÍDO"
                                : "INCOMPLETO"}
                            </Badge>
                          </Td>
                          <Td isNumeric>
                            {productControl.debtsWrittenOffAt
                              ? dayjs(productControl.debtsWrittenOffAt).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </Td>
                          {user?.roles.find((role) =>
                            [
                              RoleEnum.ADMIN_TRAMPAY,
                              RoleEnum.FINANCEIRO_TRAMPAY,
                              RoleEnum.OPS_TRAMPAY,
                              RoleEnum.TECH_DADOS,
                            ].includes(role.name as RoleEnum)
                          ) && (
                            <Td isNumeric>
                              {productControl.conciliationEmailSendedAt
                                ? dayjs(
                                    productControl.conciliationEmailSendedAt
                                  ).format("DD/MM/YYYY HH:mm:ss")
                                : ""}
                            </Td>
                          )}
                          {user?.roles.find(
                            (role) => role.name === RoleEnum.TECH_DADOS
                          ) && (
                            <Td w="10%" textAlign="center">
                              {productControl.conciliationValidationStatus ===
                                ConciliationValidationStatus.SUCCESS &&
                                productControl.status ===
                                  ProductControlScheduledPayrollStatus.SIGNED_PAYROLLS && (
                                  <Button
                                    onClick={() =>
                                      handleConfirmProcessing(
                                        productControl.companyId
                                      )
                                    }
                                    size="sm"
                                  >
                                    Processar
                                  </Button>
                                )}
                            </Td>
                          )}
                        </Tr>
                      )
                    )
                  : null}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex w="full" justifyContent="center" gap={3}>
            <Pagination
              page={page}
              setPage={(page) => setSearchParams({ page: page.toString() })}
              totalPages={productControlScheduledPayrollResult?.totalPages || 0}
            />
          </Flex>
        </Box>
      </VStack>
    </VStack>
  );
}
