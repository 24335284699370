import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  NumberInput,
  NumberInputField,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { getWithAuth } from "../../../../services/basicService";
import { AccountLimitsAccordion } from "../../../../components/account-limit";
import { ListLimitsRequest } from "./list-limits-request";
import { GetAccountLimit } from "../../../../types/get-account-limit.type";
import { AxiosError } from "axios";

const getAccountSchema = z.object({
  accountId: z
    .number()
    .int("O número deve ser um inteiro")
    .positive("O número deve ser positivo")
    .min(1, "O número deve ser maior que 0"),
});

type getAccountFormInputs = z.infer<typeof getAccountSchema>;

export function AccountLimits() {
  const { colorMode } = useColorMode();
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom",
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<getAccountFormInputs>({
    resolver: zodResolver(getAccountSchema),
  });

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["account-limit"],
    queryFn: async () => {
      const URL = `/api/v1/app/account-limit/${getValues("accountId")}`;

      try {
        const res = await getWithAuth(URL);

        return res?.data as GetAccountLimit;
      } catch (error) {
        const err = error as AxiosError;
        const errorResponse = err.response?.data as unknown as {
          message: string;
        };

        toast({
          status: "error",
          title: "Erro na requisição",
          description: errorResponse.message as string,
        });
      }
    },
    enabled: false,
  });

  const onSubmit: SubmitHandler<getAccountFormInputs> = () => {
    refetch();
  };
  return (
    <VStack
      w="full"
      h="full"
      gap={6}
      p={8}
      borderRadius={8}
      shadow="base"
      bg="whiteAlpha.800"
      flex={1}
    >
      <Heading fontWeight="extrabold" textAlign="left">
        Limites transacionais de uma conta
      </Heading>

      <Flex w="full" gap={10}>
        <Flex
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          flexDir="column"
          alignItems="center"
          gap={5}
          w="45%"
          border="1px"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderColor={colorMode === "dark" ? "" : "gray.300"}
          p={4}
          borderRadius={8}
          shadow="xl"
        >
          <FormControl
            as="fieldset"
            isInvalid={!!errors.accountId}
            w="full"
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <FormLabel fontSize="2xl" textAlign="center">
              Digite o ID da Conta
            </FormLabel>

            <NumberInput borderRadius={4} w="50%">
              <NumberInputField
                {...register("accountId", { valueAsNumber: true })}
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </NumberInput>
            {errors && (
              <FormErrorMessage>{errors?.accountId?.message}</FormErrorMessage>
            )}
          </FormControl>
          <Button
            w="fit-content"
            type="submit"
            isLoading={isFetching}
            isDisabled={isFetching}
          >
            Buscar
          </Button>
        </Flex>
        {data && (
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            w="45%"
            border="1px"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            borderColor={colorMode === "dark" ? "" : "gray.300"}
            p={4}
            borderRadius={8}
            shadow="xl"
          >
            <Text w="full" fontSize="2xl" textAlign="center">
              Informações da Conta
            </Text>
            <Flex
              w="full"
              h="full"
              gap={5}
              alignItems="center"
              justifyContent="center"
            >
              <Box w="full" gap={2}>
                <Text fontWeight="bold" fontSize="md" textAlign="center">
                  Nome
                </Text>
                <Text textAlign="center" fontSize="md">
                  {data.name}
                </Text>
              </Box>
              <Box w="full" gap={2}>
                <Text fontWeight="bold" textAlign="center" fontSize="md">
                  Documento
                </Text>
                <Text textAlign="center" fontSize="md">
                  {data.document}
                </Text>
              </Box>
              <Box w="full" gap={2}>
                <Text fontWeight="bold" textAlign="center" fontSize="md">
                  Status conta
                </Text>
                <Text textAlign="center" fontSize="md">
                  {data.status}
                </Text>
              </Box>
            </Flex>
          </Flex>
        )}
      </Flex>

      {data && (
        <Tabs w="full" variant="soft-rounded" colorScheme="green">
          <TabList>
            <Tab>Consultar/Editar</Tab>
            <Tab>Histórico de requisição de limites</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <AccountLimitsAccordion
                accountLimits={data.limits}
                idAccount={getValues("accountId")}
              />
            </TabPanel>
            <TabPanel>
              <ListLimitsRequest idAccount={getValues("accountId")} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
}
