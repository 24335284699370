import { z } from "zod";
import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { useSubplazaCachedService } from "../../../../../services/api/subplazas";
import { useShiftCachedService } from "../../../../../services/api/shifts";
import { Shift } from "../../../../../dto/shift-dto";
import { Subplaza } from "../../../../../dto/subplaza-dto";
import dayjs from "dayjs";

// const statusesEnum = z.enum(["created", "published", "closed"]);

const buildFormFields = (
  shifts: Shift[] | undefined,
  subplazas: Subplaza[] | undefined
) => {
  const formFields: FormField[] = [
    {
      label: "Status",
      name: "status",
      type: "select",
      options: [
        { value: "created", label: "Criada" },
        { value: "published", label: "Publicada" },
        { value: "closed", label: "Fechada" },
      ],
    },
    {
      label: "Turno",
      name: "shiftId",
      type: "select",
      options:
        shifts?.map(({ name, id }) => ({ label: name, value: id })) || [],
    },
    {
      label: "Sub-praça",
      name: "subplazaId",
      type: "select",
      options:
        subplazas?.map(({ name, id }) => ({ label: name, value: id })) || [],
    },
    {
      label: "Data a partir de",
      name: "fromDateScale",
      type: "date",
    },
    {
      label: "Data até",
      name: "toDateScale",
      type: "date",
    },
  ];

  return formFields;
};

// TODO: change to use correctly form schema
const formSchema = z.object({
  subplazaId: z.string().optional(),
  shiftId: z.string().optional(),
  id: z.string().optional(),
  fromDateScale: z.string().optional(),
  toDateScale: z.string().optional(),
  maxDeliveryman: z.string().optional(),
  maxSurplusDeliveryman: z.string().optional(),
  status: z.string().optional(),
});

interface SearchProps {
  updateFilter: (data: Record<string, unknown>) => void;
}

export function SearchShiftAssignment({ updateFilter }: SearchProps) {
  const { useQueryAction: useSubplazaQueryAction } = useSubplazaCachedService();
  const subplazaQuery = useSubplazaQueryAction("filter", {});
  const subplazas = subplazaQuery.data;

  const { useQueryAction: useShiftQueryAction } = useShiftCachedService();
  const shiftQuery = useShiftQueryAction("filter", {});
  const shifts = shiftQuery.data;

  const formFields = buildFormFields(shifts, subplazas);

  return (
    <GenericForm
      formSchema={formSchema}
      fields={formFields}
      columns={5}
      layout="column"
      initialData={{
        fromDateScale: dayjs().subtract(2, "day").format("YYYY-MM-DD"),
        toDateScale: dayjs().add(20, "day").format("YYYY-MM-DD"),
      }}
      actionLabel="Filtrar"
      onSubmit={updateFilter as (data: unknown) => void}
    />
  );
}
