import { Badge, Flex, Heading, Link, Text, VStack } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
  Table,
  Tbody,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../../services/basicService";
import { useParams } from "react-router-dom";
import { UserB2BResponseDto } from "../../dto/user-b2b.dto";
import { LoadingLogo } from "../loading";
import { B2BUserStatus } from "../../enums/user-b2b-status.enum";
import dayjs from "dayjs";
import {
  CopySimple,
  UserCircleMinus,
  UserCirclePlus,
} from "@phosphor-icons/react";
import { CensorItem } from "../../utils/censor-item";
import { AxiosError } from "axios";
import { AxiosDataError } from "../../dto/axios-error-dto";

const createB2BUserSchema = z.object({
  username: z.string({
    required_error: "Este campo é obrigatório",
  }),
});

type createB2BUserFormInputs = z.infer<typeof createB2BUserSchema>;

export function CreateB2BUserTab() {
  const client = useQueryClient();
  const toast = useToast();
  const params = useParams();

  const { data: B2BUser, isLoading } = useQuery({
    queryKey: ["get-user-b2b", params.companyId],
    queryFn: async () => {
      const userB2B = await getWithAuth(
        `/api/v1/b2b-user/by/company-id/${params.companyId}`
      );

      return userB2B?.data as UserB2BResponseDto;
    },
    enabled: !!params.companyId,
    staleTime: 60 * 60 * 1000,
  });

  const createB2BUserMutation = useMutation({
    mutationFn: (data: createB2BUserFormInputs) =>
      postWithAuth(`/api/v1/companies/create/b2b-user`, {
        username: data.username,
        companyId: params.companyId,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Sucesso",
        description: `Usuário criado com sucesso`,
      });

      client.invalidateQueries(["get-user-b2b", params.companyId]);
    },
    onError: (err: AxiosError) => {
      const error = err.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao criar usuário",
        description: `Erro! ${error.message}`,
      });
    },
  });

  const changeB2BUserStatusMutation = useMutation({
    mutationFn: (status: B2BUserStatus) =>
      patchWithAuth(`/api/v1/b2b-user/status/${B2BUser?.id}`, {
        status,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Sucesso",
        description: `Status do usuário alterado com sucesso.`,
      });

      client.invalidateQueries(["get-user-b2b", params.companyId]);
    },
    onError: (err: AxiosError) => {
      const error = err.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao alterar status",
        description: `Erro! ${error.message}`,
      });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<createB2BUserFormInputs>({
    resolver: zodResolver(createB2BUserSchema),
  });

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(B2BUser?.updateApiKey as string);
      toast({
        title: "Chave copiada!",
        description: "O texto foi copiado para a área de transferência.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Erro ao copiar",
        description: "Não foi possível copiar a chave.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleCreate = (data: createB2BUserFormInputs) => {
    if (createB2BUserMutation.isLoading) return;

    const body = {
      username: data.username,
      companyId: params.companyId,
    };

    createB2BUserMutation.mutate(body);
  };

  const handleChangeStatus = (status: B2BUserStatus) => {
    if (changeB2BUserStatusMutation.isLoading) return;

    changeB2BUserStatusMutation.mutate(status);
  };

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      {B2BUser ? (
        <TableContainer
          w="full"
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          gap={8}
          shadow="base"
          justifyContent="space-between"
          display="flex"
          flexDir="column"
          bg="whiteAlpha.800"
        >
          <Table variant="striped" colorScheme="green">
            <Thead>
              <Tr>
                <Th>Usuário</Th>
                <Th textAlign="center">Chave da Api</Th>
                <Th>Status</Th>
                <Th textAlign="center">Data de criação</Th>
                <Th textAlign="center">Última atualização</Th>
                <Th textAlign="center">Alterar Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{B2BUser.username}</Td>
                <Td>
                  <Flex gap={2}>
                    {CensorItem(B2BUser.updateApiKey)}{" "}
                    <CopySimple
                      cursor="pointer"
                      onClick={handleCopy}
                      size={20}
                    />
                  </Flex>
                </Td>
                <Td>
                  {B2BUser.status === B2BUserStatus.ACTIVE ? (
                    <Badge colorScheme="green">Ativo</Badge>
                  ) : (
                    <Badge colorScheme="red">Inativo</Badge>
                  )}
                </Td>
                <Td textAlign="center">
                  {dayjs(B2BUser.createdAt).format("DD/MM/YYYY")}
                </Td>
                <Td textAlign="center">
                  {dayjs(B2BUser.updatedAt).format("DD/MM/YYYY")}
                </Td>
                <Td textAlign="center">
                  {B2BUser.status === B2BUserStatus.ACTIVE ? (
                    <Button
                      leftIcon={<UserCircleMinus size={24} />}
                      backgroundColor="red.500"
                      _hover={{
                        bg: "red.700",
                      }}
                      variant="solid"
                      onClick={() => handleChangeStatus(B2BUserStatus.INACTIVE)}
                    >
                      Desativar
                    </Button>
                  ) : (
                    <Button
                      leftIcon={<UserCirclePlus size={24} />}
                      variant="solid"
                      onClick={() => handleChangeStatus(B2BUserStatus.ACTIVE)}
                    >
                      Ativar
                    </Button>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <VStack w="full" align="flex-start" gap={16}>
          <Flex
            as="form"
            flexDir="column"
            w="full"
            gap={8}
            id="create-b2b-user"
            onSubmit={handleSubmit(handleCreate)}
          >
            <Heading fontSize="3xl">
              Essa empresa ainda não possui usuário B2B. Deseja cadastrar?
            </Heading>
            <FormControl as="fieldset" isInvalid={!!errors.username} isRequired>
              <FormLabel as="legend">Nome do usuário</FormLabel>
              <Input
                type="text"
                {...register("username")}
                placeholder="Escolha um nome único"
              />
              {errors.username ? (
                <FormErrorMessage>{errors.username.message}</FormErrorMessage>
              ) : null}
            </FormControl>
          </Flex>
          <Button
            type="submit"
            form="create-b2b-user"
            size="lg"
            alignSelf="flex-end"
            isLoading={isSubmitting}
          >
            Criar
          </Button>
        </VStack>
      )}
      <Text fontSize="lg" alignSelf="center">
        Em caso de dúvidas, consulte a documentação{" "}
        <Link
          color="green"
          fontWeight="bold"
          href="https://docs.google.com/document/d/15qLT6-fFNmYxaYPbPi_WZHwkP5VhnHebyoxbZnarPTs/edit?usp=sharing"
          isExternal
        >
          clicando aqui!
        </Link>
      </Text>
    </Flex>
  );
}
