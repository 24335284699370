import { useState, useEffect } from "react";
import { Box, Flex, HStack, Heading, VStack } from "@chakra-ui/layout";
import {
  UseStepsReturn,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  PinInput,
  PinInputField,
  useToast,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { convertCentsToReais } from "../../../../utils/convert-money";
import {
  getWithAuth,
  patchWithAuth,
  postWithAuth,
} from "../../../../services/basicService";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ptBR from "dayjs/locale/pt-br";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { ITransactionDraftScheduledPayrollWithPagination } from "../../../../dto/transactions-draft-dto";
import { ScheduledPayrollConfig } from "../../../../types/payroll.type";
import { Pagination } from "../../../../components/pagination";
import { PayrollType } from "../../../../enums/payroll-type";
import { AxiosError } from "axios";
import { UpdatePixKeyButton } from "./components/update-pix-key-button";
import { formatCPF } from "../../../../utils/format-document";
dayjs.extend(relativeTime);

type Props = UseStepsReturn;

export function SummaryScheduledTransactionsConfirmation(props: Props) {
  const [deathLine, setDeathLine] = useState<Date>();
  const [timer, setTimer] = useState<string>();
  const [page, setPage] = useState<number>(1);

  const companyId = useCurrentUserStore((state) => state.user?.companyId);

  const { data: scheduledConfig } = useQuery({
    queryKey: [`scheduled-config`],
    queryFn: async (): Promise<ScheduledPayrollConfig | null> => {
      if (!companyId) return null;

      const response = await getWithAuth(
        `/api/v1/payroll/scheduled/dates_from_business_rule/${companyId}`
      );
      return response?.data as ScheduledPayrollConfig;
    },
  });

  const { data: payrollCreating } = useQuery({
    queryKey: [`payroll-creating`],
    queryFn: async (): Promise<boolean> => {
      if (!companyId) return false;

      const response = await getWithAuth(
        `/api/v1/payroll/creating/${companyId}`
      );

      if (response?.data && !modalCreatingOpened) {
        openModalCreating();
      }
      return response?.data;
    },
  });

  const { data: transactionValidated } = useQuery({
    queryKey: ["validated-transaction-draft", page],
    queryFn:
      async (): Promise<ITransactionDraftScheduledPayrollWithPagination> => {
        const response = await getWithAuth(
          `/api/v1/transaction-draft/scheduled-payroll/${companyId}?limit=10&page=${page}`
        );
        const transactionDraftsResponse =
          response?.data as ITransactionDraftScheduledPayrollWithPagination;

        if (transactionDraftsResponse.totalRecords > 0) {
          closeModalCreating();
        }
        return transactionDraftsResponse;
      },
  });

  const toast = useToast({
    duration: 5000,
    isClosable: true,
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<{ pin: string }>();

  const {
    isOpen: modalCreatingOpened,
    onClose: closeModalCreating,
    onOpen: openModalCreating,
  } = useDisclosure({
    id: "payroll-creating-modal",
    defaultIsOpen: !window.location.href.includes(
      "create-transactions/confirmation"
    ),
  });

  const {
    isOpen: modalTermOpened,
    onClose: closeModalTerm,
    onOpen: openModalTerm,
  } = useDisclosure({
    id: "schedule-term-modal",
  });

  const {
    isOpen: modalPinOpened,
    onClose: closeModalPin,
    onOpen: openModalPin,
  } = useDisclosure({
    id: "confirm-transactions-modal",
  });

  async function handleConfirmTransactions() {
    openModalPin();

    setDeathLine(dayjs().add(12, "minutes").toDate());

    try {
      await postWithAuth("/api/v1/mail/scheduled-payroll", {
        payroll: {
          companyId,
          name: `Repasse-${scheduledConfig?.workPeriodKey}`,
          summary: transactionValidated?.summary || [],
        },
      });

      toast({
        status: "success",
        title: "Token de confirmação enviado para seu e-mail cadastrado",
      });
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        title: "Não foi possível enviar token de confirmação!",
      });
    }
  }

  function handleScheduled() {
    closeModalTerm();
    toast({
      status: "success",
      title: "Declaro-me ciente dos termos e condições de agendamento!",
    });
    handleConfirmTransactions();
  }

  async function handleConfirmationToken(data: { pin: string }) {
    try {
      await patchWithAuth("/api/v1/payroll/confirm", {
        type: PayrollType.SCHEDULED,
        confirmationCode: Number(data.pin),
      });

      toast({
        status: "success",
        title:
          "Token confirmado. As transações serão agendadas de acordo com os termos assinalados!",
      });
      closeModalPin();

      props.goToNext();
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status !== 403) {
        return toast({
          status: "error",
          title: "Não foi possível confirmar o envio!",
          description: `Erro: ${error.response?.data.message}`,
          duration: 5000,
        });
      }

      setError("pin", {
        type: "Token Inválido",
        message: "Este token não é válido",
      });
      toast({
        status: "error",
        title: "Token inválido!",
      });
    }
  }

  const amount = (transactionValidated?.summary || []).reduce((acc, item) => {
    if (item.value === undefined || item.value === null) {
      return acc;
    }

    return (acc += item.value / 100);
  }, 0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(dayjs(deathLine).locale(ptBR).fromNow());
    }, 1000);

    return () => clearInterval(interval);
  }, [deathLine]);

  return (
    <VStack w="full" h="max-content" gap={8} pt={8}>
      <Modal
        isOpen={modalCreatingOpened}
        onClose={closeModalCreating}
        id="confirm-transactions-modal"
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="red">ATENÇÃO!</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={8}
          >
            Suas folhas de praça ainda estão em processo de conciliação. A
            operação pode levar um tempo. Por favor aguarde a finalização ou
            volte em alguns minutos.
          </ModalBody>

          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={closeModalCreating}>
              Aguardar!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalTermOpened}
        onClose={closeModalTerm}
        id="confirm-transactions-modal"
        size="5xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            TERMO DE ANUÊNCIA DE USO DOS SERVIÇOS DA PLATAFORMA TRAMPAY
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={4}
          >
            CONSIDERANDO que você, OPERADORA LOGÍSTICA (OL), disponibiliza para
            USUÁRIOS (pessoas entregadoras prestadoras de serviço da plataforma)
            diversos benefícios prestados pela empresa TRAMPAY (CNPJ nº
            35.454.968/0001-72) utilizando-se de plataforma white label em
            parceria com a empresa DOCK (CNPJ nº 08.744.817/0001-86), como o
            repasse de valores pagos por Plataformas de Delivery por meio da
            operacionalização dos valores das entregas enviadas pela OPERADORA
            LOGÍSTICA (OL), você autoriza o que segue:
          </ModalBody>

          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={4}
          >
            1) DO PROCESSAMENTO DO REPASSE DOS VALORES DEVIDOS AOS USUÁRIOS.
            Através do presente Termo, a OPERADORA LOGÍSTICA (OL) expressamente
            autoriza que os repasses de valores aos USUÁRIOS - referentes aos
            pagamentos realizados pela Plataforma de Delivery a título de
            remuneração pelas entregas realizadas pelos USUÁRIOS - e o repasse
            de valores à TRAMPAY - referente à prestação de serviços à OPERADORA
            LOGÍSTICA (OL) - sejam executados pela TRAMPAY imediatamente (no
            mesmo dia) em que ocorrer o pagamento dos valores pela Plataforma de
            Delivery. Dessa forma, o crédito será repassado pela Plataforma de
            Delivery e depositado à TRAMPAY e aos USUÁRIOS com base nos repasses
            enviados pela OPERADORA LOGÍSTICA (OL), sem haver a necessidade de
            atuação direta ou indireta da OPERADORA LOGÍSTICA (OL) sob os
            valores. Os valores transitarão junto a uma conta TRAMPAY
            utilizando-se de plataforma white label em parceria com a empresa
            DOCK em nome da OPERADORA LOGÍSTICA (OL), mas sobre a qual essa não
            terá o direito de realizar movimentações financeiras (denominada
            conta repasse), tudo como forma de: (i) assegurar que os USUÁRIOS
            sejam devidamente remunerados pelos serviços já prestados às
            Plataformas de Delivery, garantindo que os repasses sejam realizados
            sem qualquer entrave; e (ii) garantir que os pagamentos realizados
            pela Plataforma de Delivery sejam correta e tempestivamente
            repassados aos USUÁRIOS e à TRAMPAY. Assim, a OPERADORA LOGÍSTICA
            (OL) compromete-se a não contestar, dificultar ou impedir o repasse
            direto dos valores devidos aos USUÁRIOS no mesmo dia do pagamento do
            crédito realizado pela Plataforma de Delivery, bem como a não atuar,
            movimentar ou interferir de forma direta ou indireta sob o crédito
            depositado, perante possível pena de rescisão do contrato com a
            TRAMPAY e de indenização por eventuais perdas e danos à TRAMPAY, aos
            USUÁRIOS e à Plataforma de Delivery. Fica ainda expressamente
            ressalvado o fato de que a Plataforma de Delivery é a mandatária da
            operação, de modo que a TRAMPAY apenas disponibilizará os serviços
            da plataforma, atuando tão somente na operacionalização dos repasses
            a serem feitos em favor dos USUÁRIOS, executando as ordens de
            pagamento que a própria OPERADORA LOGÍSTICA (OL) remeter ao sistema
            por meio do upload dos repasses na plataforma TRAMPAY.
          </ModalBody>

          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={4}
          >
            2) DOS VALORES DAS ENTREGAS. Para isso, a OPERADORA LOGÍSTICA (OL)
            compromete-se a disponibilizar corretamente à TRAMPAY os valores das
            entregas realizadas pelos USUÁRIOS para agendamento dos repasses.
            Considerando a necessidade de precisão das informações fornecidos
            para a TRAMPAY, caso a OPERADORA LOGÍSTICA (OL) forneça-os contendo
            erros na identificação e/ou nos valores dos créditos do USUÁRIO, o
            equívoco gerará à OPERADORA LOGÍSTICA (OL) o dever de reprocessar os
            erros para execução correta dos valores devidos ao USUÁRIO. A
            TRAMPAY não será responsabilizada por eventual falha de informação
            entregue pela OPERADORA LOGÍSTICA (OL), a qual indenizará
            integralmente os USUÁRIOS por eventuais perdas e/ou danos pelo
            atraso no repasse decorrente da falha de informação.
          </ModalBody>

          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={4}
          >
            3) DOS VALORES DEVIDOS À OPERADORA LOGÍSTICA (OL). Por fim,
            esclarece-se que os valores pagos pela Plataforma de Delivery e
            devidos à OPERADORA LOGÍSTICA (OL) serão mantidos na conta TRAMPAY
            da OPERADORA LOGÍSTICA (OL) para seu uso livre.
          </ModalBody>
          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={closeModalTerm}>
              Fechar
            </Button>
            <Button color="white" onClick={handleScheduled}>
              De acordo!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalPinOpened}
        onClose={closeModalPin}
        id="confirm-transactions-modal"
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Entre com o PIN enviado ao seu e-mail</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={8}
          >
            <VStack
              gap={8}
              flexDir="column"
              as="form"
              onSubmit={handleSubmit(handleConfirmationToken)}
              id="confirmation-token-form"
            >
              <HStack>
                <Controller
                  control={control}
                  name="pin"
                  render={({ field }) => (
                    <PinInput
                      type="number"
                      size="lg"
                      colorScheme="red"
                      onChange={field.onChange}
                      value={field.value}
                      isInvalid={!!errors.pin}
                    >
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                      <PinInputField
                        _focus={{
                          borderColor: "green.500",
                        }}
                        type="number"
                      />
                    </PinInput>
                  )}
                />
              </HStack>

              <Heading size="md">Expira {timer}</Heading>
            </VStack>
          </ModalBody>

          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={closeModalPin}>
              Fechar
            </Button>
            <Button
              color="white"
              type="submit"
              form="confirmation-token-form"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              loadingText="Checando"
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Heading>{scheduledConfig?.workPeriodKey}</Heading>
      <Flex
        gap={[4, 8]}
        justifyContent="space-between"
        overflow="auto"
        h="max-content"
      >
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          minW={["0", "180px"]}
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>Valor total</StatLabel>
            <StatNumber fontSize={["xl", "3xl"]} color="green.500">
              {amount
                ? convertCentsToReais(amount * 100)
                : convertCentsToReais(0)}
            </StatNumber>
          </Stat>
        </Box>
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          minW={["0", "180px"]}
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>Data Agendada</StatLabel>
            <StatNumber fontSize={["xl", "3xl"]} color="green.500">
              {transactionValidated?.data[0]?.schedulingDate
                ? dayjs(transactionValidated?.data[0]?.schedulingDate)
                    .utc()
                    .format("DD/MM/YYYY")
                : "--/--/----"}
            </StatNumber>
          </Stat>
        </Box>
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize={["md", "xl"]}>Quantidade de saldos</StatLabel>
            <StatNumber fontSize={["xl", "3xl"]} color="green.500">
              {transactionValidated?.totalRecords}
            </StatNumber>
          </Stat>
        </Box>
      </Flex>
      <Flex alignSelf="end">
        <UpdatePixKeyButton />
      </Flex>
      <TableContainer
        w="full"
        overflow="auto"
        style={{ containerType: "inline-size" }}
      >
        <Table variant="striped" colorScheme="green">
          <Thead>
            <Tr>
              <Th textAlign="center">Folha</Th>
              <Th textAlign="center">Nome</Th>
              <Th textAlign="center">CPF/CNPJ</Th>
              <Th textAlign="center">Chave Pix</Th>
              <Th textAlign="center">Tipo da Chave Pix</Th>
              <Th textAlign="center">Tipo de transação</Th>
              <Th textAlign="center">Valor</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactionValidated?.data.map((item) => (
              <Tr key={item.id}>
                <Td textAlign="center">{item.payrollName}</Td>
                <Td textTransform="capitalize" textAlign="center">
                  {item.name}
                </Td>
                <Td textAlign="center">{formatCPF(item.document)}</Td>
                <Td textAlign="center">{item.pixKey}</Td>
                <Td textAlign="center">{item.pixType}</Td>
                <Td textAlign="center">{item.type}</Td>
                <Td textAlign="center">{convertCentsToReais(item.amount)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        page={page ? +page : 1}
        totalPages={transactionValidated?.totalPages as number}
        setPage={setPage}
      />
      <Flex justifyContent="flex-end" w="full" gap={4}>
        <Button
          size="lg"
          w={["full", "auto"]}
          color="white"
          isDisabled={
            payrollCreating || transactionValidated?.totalRecords === 0
          }
          onClick={openModalTerm}
        >
          Confirmar transações
        </Button>
      </Flex>
    </VStack>
  );
}
