import { VStack, Heading, Flex, HStack, Box } from "@chakra-ui/layout";
import {
  FormControl,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
  useToast,
  Portal,
  Select,
  FormLabel,
  Tooltip,
  Icon,
  ModalFooter,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ptBR } from "date-fns/locale";
import { DateRange, DayPicker } from "react-day-picker";
import {
  useCreatePayroll,
  usePayrollStore,
} from "../../../store/payroll.store";
import { useLoading } from "../../../store";
import { useTransactionsStore } from "../../../store/transaction.store";
import { Pagination } from "../../../components/pagination";
import { useSearchParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { PayrollDto, payrollTypeTextMap } from "../../../dto/payroll-dto";
import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { CompanyDto } from "../../../dto/company-dto";
import { getWithAuth } from "../../../services/basicService";
import { LoadingLogo } from "../../../components/loading";
import { isFranchisor } from "../../../utils/company-verifications";
import { PayrollType } from "../../../enums/payroll-type";
import { TransactionStatus } from "../../../enums/transaction-status";
import { useState } from "react";
dayjs.extend(utc);

const filtersFormSchema = z.object({
  dateRange: z
    .object({
      to: z.date().optional(),
      from: z.date().optional(),
    })
    .optional(),
  companyFranchiseeId: z
    .string()
    .transform((value) => {
      if (value) return Number(value);
    })
    .optional(),
  type: z.string().optional(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

export function Payments() {
  const me = useCurrentUserStore((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
    companyId: me?.companyId.toString() || "",
  });
  const page = searchParams.get("page");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const type = searchParams.get("type") as PayrollType;
  const companyFranchiseeId = searchParams.get("companyFranchiseeId");
  const formattedStartDate = startDate ? dayjs(startDate).toDate() : undefined;
  const formattedEndDate = endDate ? dayjs(endDate).toDate() : undefined;

  const toast = useToast();
  const navigate = useNavigate();

  const getSheets = usePayrollStore((state) => state.getSheets);
  const setPayroll = usePayrollStore((state) => state.setPayroll);
  const totalPages = usePayrollStore((state) => state.totalPages);
  const generateAllTransactionsPdf = useTransactionsStore(
    (state) => state.generateAllTransactionsPdf
  );
  const reprocess = usePayrollStore((state) => state.reprocessPayroll);
  const changePayrollStatus = useCreatePayroll(
    (state) => state.changePayrollStatus
  );
  const setReprocessingScheduledFlag = useCreatePayroll(
    (state) => state.setReprocessingScheduledFlag
  );
  const setReprocessingFlag = useCreatePayroll(
    (state) => state.setReprocessingFlag
  );
  const loading = useLoading((state) => state.loading);
  const setLoading = useLoading((state) => state.setLoading);
  const generateTransactionsByPayrollCsv = useTransactionsStore(
    (state) => state.generateTransactionsByPayrollCsv
  );
  const [showExportPayroll, setShowExportPayroll] = useState(false);
  const [selectedPayrollToExport, setSelectedPayrollToExport] = useState<{
    id: number;
    name: string;
  }>();
  const [exportingPayrollPdf, setExportingPayrollPdf] = useState(false);
  const [exportingPayrollCsv, setExportingPayrollCsv] = useState(false);

  const { handleSubmit, register, control } = useForm<filtersFormInput>({
    defaultValues: {
      dateRange: {
        from: formattedStartDate,
        to: formattedEndDate,
      },
      companyFranchiseeId: undefined,
      type: "",
    },
  });

  async function onSubmit(data: filtersFormInput) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("startDate");
      prev.delete("endDate");
      prev.delete("type");
      prev.delete("companyFranchiseeId");

      if (data?.dateRange?.from) {
        prev.set(
          "startDate",
          dayjs(data?.dateRange?.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.dateRange?.to) {
        prev.set(
          "endDate",
          dayjs(data?.dateRange?.to).utc().format("YYYY-MM-DD")
        );
      }

      if (data?.companyFranchiseeId) {
        prev.set("companyFranchiseeId", data?.companyFranchiseeId.toString());
      } else {
        prev.set("companyFranchiseeId", me?.companyId.toString() || "");
      }

      if (data?.type) {
        prev.set("type", data?.type);
      }

      return prev;
    });
  }

  const fetchFranchiseesOfCompany = useQuery(
    ["company-franchisees"],
    async () => {
      try {
        const response = await getWithAuth(
          `/api/v1/companies/franchisees/${me?.company?.id}`
        );
        return response?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: isFranchisor(me?.company as CompanyDto),
      initialData: () => ({ id: null, franchisees: [] as CompanyDto[] }),
    }
  );

  const { data, isFetching } = useQuery({
    queryKey: ["payroll-sheets", Array.from(searchParams)],
    queryFn: async () => {
      try {
        const response = await getSheets({
          filters: {
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            type: type || "",
          },
          page: page ? +page : 1,
          limit: 10,
          companyId: me?.companyId,
          companyFranchiseeId: companyFranchiseeId as string | undefined,
        });
        for (const payroll of response) {
          const transactions = payroll.transactions;

          const isTransactionError = transactions!.some(
            (transaction) =>
              transaction.status === TransactionStatus.ERROR ||
              transaction.status === TransactionStatus.NOT_EXECUTED
          );
          if (isTransactionError) {
            payroll.hasError = true;
          }

          payroll.isToShowReprocessingButtons =
            payroll.status === "processed" &&
            !transactions!.some(
              (transaction) => transaction.status === "pending"
            );
        }
        setPayroll(response);
        return response;
      } catch (error) {
        if (error instanceof AxiosError) {
          toast({
            status: "error",
            title: "Erro ao buscar informações",
            description: `${error?.response?.data.message}`,
          });
          console.log(error);
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  async function reprocessPayroll(
    payrollToBeReprocessed: PayrollDto,
    reprocessOnlyErrors: boolean
  ) {
    try {
      setLoading(true);

      changePayrollStatus(false);

      await reprocess(payrollToBeReprocessed, reprocessOnlyErrors);

      setReprocessingScheduledFlag(
        payrollToBeReprocessed.type === PayrollType.SCHEDULED ||
          Boolean(payrollToBeReprocessed.schedulingDate)
      );

      setReprocessingFlag(true);

      navigate(`/payments/create-transactions/${payrollToBeReprocessed.id}`, {
        relative: "route",
      });
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 504 || error.response?.status === 524)
      ) {
        console.log("Erro de timeout do lado do servidor.");

        toast({
          status: "error",
          title: "Erro ao enviar folha para reprocessamento",
          description:
            "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
        });
      } else {
        console.log(error);
        toast({
          status: "error",
          title: "Erro ao enviar folha para reprocessamento",
          description: `Erro: ${error}`,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleExportPayrollPdf() {
    setExportingPayrollPdf(true);
    try {
      if (!selectedPayrollToExport) {
        throw new Error("No payroll selected for export");
      }

      const { id, name } = selectedPayrollToExport;

      await generateAllTransactionsPdf(id, name);

      setShowExportPayroll(false);
    } catch (error) {
      toast({
        status: "error",
        title: "Erro ao fazer download do CSV",
        description: `Tente novamente mais tarde ou entre em contato com o suporte.`,
      });
    } finally {
      setExportingPayrollPdf(false);
    }
  }

  async function handleExportPayrollCsv() {
    setExportingPayrollCsv(true);
    try {
      if (!selectedPayrollToExport) {
        throw new Error("No payroll selected for export");
      }

      const { id } = selectedPayrollToExport;

      await generateTransactionsByPayrollCsv(String(id));

      setShowExportPayroll(false);
    } catch (error) {
      toast({
        status: "error",
        title: "Erro ao fazer download do PDF",
        description: `Tente novamente mais tarde ou entre em contato com o suporte.`,
      });
    } finally {
      setExportingPayrollCsv(false);
    }
  }

  async function handleExportPayroll(id: number, name: string) {
    setSelectedPayrollToExport({ id, name });

    setShowExportPayroll(true);
  }

  function formatPage() {
    const page = searchParams.get("page");

    if (page) {
      return parseInt(page);
    }
    return 1;
  }

  if (isFetching) {
    return <LoadingLogo />;
  }

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Folhas processadas
      </Heading>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={4}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={4}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={4}
          p={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex
            alignSelf="flex-start"
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
            gap={8}
          >
            <Flex gap={8} alignItems="end">
              <FormControl as="fieldset" w="max-content">
                <FormLabel as="legend">Data</FormLabel>
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button>Escolha a sua data</Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent bg="white" p={2} w="max-content">
                      <PopoverBody>
                        <Controller
                          control={control}
                          name="dateRange"
                          render={({ field }) => (
                            <DayPicker
                              id="dateFilter"
                              mode="range"
                              selected={field.value as DateRange}
                              onSelect={field.onChange}
                              locale={ptBR}
                              modifiersClassNames={{
                                disabled: "btn-disabled",
                                selected: "custom-selected",
                                outside: "outside-day",
                              }}
                            />
                          )}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Tipo da folha</FormLabel>
                <Select
                  variant="filled"
                  size="md"
                  {...register("type")}
                  focusBorderColor="green.500"
                  w="full"
                >
                  <option value="">Todos</option>
                  {Object.entries(PayrollType).map(([key, value]) => (
                    <option value={value} key={key}>
                      {payrollTypeTextMap[value]}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {isFranchisor(me?.company as CompanyDto) ? (
                <FormControl as="fieldset">
                  <FormLabel as="legend">Franqueada</FormLabel>
                  <Select
                    {...register("companyFranchiseeId")}
                    placeholder="Todas"
                    _placeholder={{
                      color: "gray.500",
                    }}
                    variant="filled"
                    focusBorderColor="green.500"
                  >
                    {fetchFranchiseesOfCompany?.data?.franchisees.map(
                      (company: CompanyDto) => (
                        <option value={company.id} key={company.id}>
                          {company.name}
                        </option>
                      )
                    )}
                  </Select>
                </FormControl>
              ) : null}

              <Button
                type="submit"
                bg="green.600"
                w="max-content"
                color="whiteAlpha.900"
                _hover={{
                  bg: "green.700",
                }}
                px={8}
                size="md"
              >
                Filtrar
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          p={4}
          gap={4}
          display="flex"
          flexDir="column"
          style={{ containerType: "inline-size" }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th w="20%">Data de envio</Th>
                <Th w="50%">Folha de pagamento</Th>
                <Th textAlign="center">Tipo</Th>
                {isFranchisor(me?.company as CompanyDto) && (
                  <Th textAlign="center">Empresa</Th>
                )}
                <Th textAlign="center">Exportar resumo</Th>
                {!isFranchisor(me?.company as CompanyDto) && (
                  <Th textAlign="center">Reprocessar resíduos</Th>
                )}
                {!isFranchisor(me?.company as CompanyDto) && (
                  <Th textAlign="center">Reprocessar folha</Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((payroll) => (
                <Tr
                  key={payroll.id}
                  cursor="pointer"
                  onClick={() =>
                    navigate(`details/${payroll.id}`, {
                      relative: "route",
                    })
                  }
                >
                  <Td>
                    {dayjs(payroll.created_at).utc().format("DD/MM/YYYY")}
                  </Td>
                  <Td>{payroll.name}</Td>
                  <Td textAlign="center">
                    <Flex gap={3} alignItems="center">
                      <Box>
                        {
                          payrollTypeTextMap[
                            payroll.type === PayrollType.INSTANT &&
                            Boolean(payroll.schedulingDate)
                              ? PayrollType.SCHEDULED
                              : payroll.type
                          ]
                        }
                      </Box>
                      {Boolean(
                        payroll.schedulingDate &&
                          payroll.type === PayrollType.INSTANT
                      ) && (
                        <Tooltip
                          hasArrow
                          placement="right"
                          label="Folha reprocessada de uma folha do tipo agendada"
                          fontSize="md"
                        >
                          <Icon fontSize={17} color="black" />
                        </Tooltip>
                      )}
                    </Flex>
                  </Td>
                  {isFranchisor(me?.company as CompanyDto) && (
                    <Td textAlign="center">{payroll.company.name}</Td>
                  )}
                  <Td textAlign="center">
                    {payroll.type !== "conciliated" && (
                      <Button
                        role="a"
                        size="md"
                        color="white"
                        isDisabled={showExportPayroll}
                        loadingText={"Exportando Folha"}
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await handleExportPayroll(payroll.id, payroll.name);
                        }}
                      >
                        Exportar
                      </Button>
                    )}
                  </Td>
                  {!isFranchisor(me?.company as CompanyDto) && (
                    <Td textAlign="center">
                      {payroll.isToShowReprocessingButtons &&
                        payroll.hasError && (
                          <Tooltip
                            label={
                              payroll.schedulingDate
                                ? "Reprocessar resíduos da folha agendada"
                                : ""
                            }
                          >
                            <Button
                              isLoading={loading}
                              isDisabled={loading}
                              role="a"
                              size="md"
                              bg="red.500"
                              color="white"
                              _hover={{
                                bg: "red.600",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                reprocessPayroll(payroll, true);
                              }}
                            >
                              {payroll.schedulingDate
                                ? "Reprocessar Resíduos"
                                : "Reprocessar Erros"}
                            </Button>
                          </Tooltip>
                        )}
                    </Td>
                  )}
                  {!isFranchisor(me?.company as CompanyDto) && (
                    <Td textAlign="center">
                      {payroll.isToShowReprocessingButtons &&
                        payroll.type !== "conciliated" &&
                        !payroll.schedulingDate && (
                          <Button
                            isLoading={loading}
                            isDisabled={loading}
                            role="a"
                            size="md"
                            color="white"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              reprocessPayroll(payroll, false);
                            }}
                          >
                            Reprocessar
                          </Button>
                        )}
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={formatPage()}
            setPage={(page) =>
              setSearchParams((prev) => {
                prev.set("page", String(page));
                return prev;
              })
            }
            totalPages={totalPages}
          />
        </HStack>
      </Box>

      <Modal
        isOpen={showExportPayroll}
        onClose={() => setShowExportPayroll(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Exportar folha</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection="column">
              <Flex mb={4}>Escolha o formato de exportação da folha</Flex>
              <Flex justifyContent="center" padding={8} gap={8}>
                <Button
                  isLoading={exportingPayrollPdf}
                  isDisabled={exportingPayrollPdf}
                  bg="green.500"
                  color="white"
                  variant="ghost"
                  _hover={{
                    bg: "green.600",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleExportPayrollPdf();
                  }}
                >
                  PDF
                </Button>

                <Button
                  isLoading={exportingPayrollCsv}
                  isDisabled={exportingPayrollCsv}
                  bg="green.500"
                  color="white"
                  variant="ghost"
                  _hover={{
                    bg: "green.600",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleExportPayrollCsv();
                  }}
                >
                  CSV
                </Button>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={() => setShowExportPayroll(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
