import { extendTheme } from "@chakra-ui/react";
import { inputTheme } from "./components/Input";
import { SelectTheme } from "./components/Select";
import { numberInputTheme } from "./components/NumberInput";
import { tableTheme } from "./components/Table";

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        fontSize: "sm",
        lineHeight: "tall",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        width: "16px",
        background: "whiteAlpha.800",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "24px",
        background: "green.500",
        width: "16px",
      },
      input: {
        color: "gray.700",
        _placeholder: {
          color: "gray.500",
        },
      },
      ".rdp-caption_label": {
        textTransform: "capitalize",
      },
      ".custom-selected:not([disabled])": {
        backgroundColor: "#399469",
        color: "white",
      },
      ".custom-selected:hover:not([disabled]):not(.rdp-day_selected)": {
        backgroundColor: "#276749",
      },
    },
    colors: {
      green: {
        50: "#f0fdf4",
        100: "#dcfce7",
        200: "#bbf7d0",
        300: "#86efac",
        400: "#4ade80",
        500: "#22c55e",
        600: "#16a34a",
        700: "#15803d",
        800: "#166534",
        900: "#14532d",
        950: "#052e16",
      },
    },
  },
  components: {
    Input: inputTheme,
    Select: SelectTheme,
    NumberInput: numberInputTheme,
    Button: {
      variants: {
        solid: {
          bg: "green.500",
          color: "gray.50",
          _hover: {
            bg: "green.600",
          },
        },
        ghost: {
          bg: "blackAlpha.100",
          _hover: {
            bg: "blackAlpha.200",
          },
        },
        danger: {
          bg: "red.500",
          color: "whiteAlpha.900",
          _hover: {
            bg: "red.400",
          },
        },
      },
    },
    Table: tableTheme,
  },
});

export default theme;
