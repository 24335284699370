import { VStack, Heading, Badge, HStack } from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useLoading } from "../../../../store";
import { useTransactionsStore } from "../../../../store/transaction.store";
import { convertCentsToReais } from "../../../../utils/convert-money";
import { useNavigate, useParams, useRouteError } from "react-router-dom";
import { CaretLeft, Download } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../../services/basicService";
import { Transaction } from "../../../../dto/transactions-dto";
import { LoadingLogo } from "../../../../components/loading";

export function DebtDetails() {
  const { id } = useParams();

  // depois refator com o refactor dos loaders
  const { data: transactions, isLoading } = useQuery({
    queryKey: ["transactions-by-debt-id", id],
    queryFn: async () => {
      const response = await getWithAuth(
        `/api/v1/transaction/allByDebtId/${id}`
      );

      return response?.data as Transaction[];
    },
    enabled: !!id,
  });

  const loading = useLoading((state) => state.loading);
  const setLoading = useLoading((state) => state.setLoading);
  const generatePdf = useTransactionsStore(
    (state) => state.generateTransactionPdf
  );

  const routeFetchError = useRouteError();
  const navigate = useNavigate();
  const toast = useToast();

  if (routeFetchError) {
    toast({
      status: "error",
      title: "Erro ao carregar informação",
      description: `Erro: ${routeFetchError}`,
    });
  }

  async function handleDownload(id: number) {
    try {
      setLoading(true);
      await generatePdf(id);
    } catch (error) {
      toast({
        status: "error",
        title: "Erro ao fazer download",
        description: `Tente novamente mais tarde.`,
      });
    } finally {
      setLoading(false);
    }
  }

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading>Detalhes do débito</Heading>
      </HStack>
      <Heading size="lg">Lista de transações</Heading>

      <TableContainer
        w="full"
        p={8}
        gap={4}
        display="flex"
        flexDir="column"
        border="1px"
        borderRadius={8}
        bg={"whiteAlpha.800"}
        borderColor={"gray.300"}
        shadow="base"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Conta origem</Th>
              <Th>Conta destino</Th>
              <Th>Valor</Th>
              <Th textAlign="center">Status</Th>
              <Th textAlign="center">Comprovante</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map((transaction) => (
              <Tr key={transaction.id}>
                <Td>
                  {transaction.accountNameFrom
                    ? transaction.accountNameFrom
                    : "-"}
                </Td>
                <Td>
                  {transaction.accountNameTo ? transaction.accountNameTo : "-"}
                </Td>
                <Td>{convertCentsToReais(transaction.amount)}</Td>
                <Td textAlign="center">
                  <Badge
                    variant="solid"
                    px={4}
                    backgroundColor={
                      transaction.status === "success" ? "green" : "red"
                    }
                  >
                    {transaction.status}
                  </Badge>
                </Td>
                <Td textAlign="center">
                  {transaction.status === "error" ? null : (
                    <Button
                      type="submit"
                      size="md"
                      alignSelf="flex-end"
                      onClick={() => handleDownload(transaction.id)}
                      isDisabled={loading}
                      isLoading={loading}
                    >
                      <Download weight="bold" size={24} />
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
}
