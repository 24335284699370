import { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { NotePencil } from "@phosphor-icons/react";
import { AccountLimit } from "../../dto/company-bank-limit-dto";
import { LIMIT_TYPE } from "../../enums/bank-limit";
import { postWithAuth } from "../../services/basicService";

const editAmountSchema = z.object({
  amount: z.number().positive("O número deve ser positivo").min(100),
});

const idServicesGroupMapper = {
  1: "PIX",
  2: "P2P",
  3: "BILL_PAYMENTS",
  4: "PIX_WITHDRAW_AND_CHANGE",
  5: "TED",
};

type editAmountFormInputs = z.infer<typeof editAmountSchema>;

type AmountType = "accountLimit" | "availableLimit";
type KeyOfLimitType = keyof typeof LIMIT_TYPE;

type IProps = {
  limits: AccountLimit[];
  limitType: KeyOfLimitType;
  amountType: AmountType;
  field: "Total" | "Disponível";
  idAccount: number;
};

export function TransactionLimitTd({
  limits,
  limitType,
  amountType,
  field,
  idAccount,
}: IProps) {
  const [editInput, setEditInput] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const hasLimit = useRef(
    limits.find((limit) => limit.limitType === LIMIT_TYPE[limitType])
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom",
  });

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<editAmountFormInputs>({
    resolver: zodResolver(editAmountSchema),
    defaultValues: {
      amount: hasLimit.current ? hasLimit.current[amountType] : 0,
    },
  });

  const onSubmit: SubmitHandler<editAmountFormInputs> = () => {
    if (!hasLimit.current) return;

    onOpen();
  };

  const submit = () => {
    if (loadingEdit || !hasLimit.current) return;

    setLoadingEdit(true);

    const { idServicesGroup, limitType, beneficiaryType } = hasLimit.current;

    const URL = `/api/v1/app/account-limit`;
    const body = {
      idAccount,
      idServicesGroup,
      limitType,
      requestLimit: getValues("amount"),
      beneficiaryType,
    };

    postWithAuth(URL, body)
      .then(() => {
        setEditInput(false);
        onClose();

        toast({
          status: "success",
          title: "Requisição feita com sucesso!",
          description: "A solicitação de mudança de limite foi realizada!",
        });
      })
      .catch((err) => {
        console.log(err);

        toast({
          status: "error",
          title: "Requisição com erro!",
          description:
            "Não foi possivel fazer a requisição de mudança de limite",
        });
      })
      .finally(() => setLoadingEdit(false));

    reset({ amount: getValues("amount") });
  };

  return (
    <Td textAlign="center">
      {hasLimit.current && !editInput && (
        <Flex alignItems="center" gap={2}>
          {getValues("amount").toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
          {field === "Total" && (
            <NotePencil
              size={18}
              cursor="pointer"
              onClick={() => setEditInput(true)}
            />
          )}
        </Flex>
      )}
      {hasLimit.current && editInput && (
        <Flex as="form" w="full" id="form" onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            isInvalid={!!errors?.amount}
            display="flex"
            gap={2}
            onKeyUp={(e) => {
              switch (e.key) {
                case "Escape":
                  reset();

                  setEditInput(false);
                  break;

                default:
                  break;
              }
            }}
            alignItems="center"
          >
            <NumberInput
              display="flex"
              gap={4}
              flex={1}
              alignItems="center"
              allowMouseWheel={false}
              inputMode="decimal"
              precision={2}
              step={0.1}
            >
              R$
              <NumberInputField
                textAlign="right"
                border="1px"
                borderColor="gray.300"
                bg="gray.100"
                type="number"
                w="150px"
                {...register(`amount`, { valueAsNumber: true })}
              />
              {errors.amount && (
                <FormErrorMessage>{errors.amount.message}</FormErrorMessage>
              )}
            </NumberInput>
          </FormControl>
        </Flex>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Mudança de limite
            </AlertDialogHeader>

            <AlertDialogBody>
              Você tem certeza que deseja mudar o limite do{" "}
              {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                idServicesGroupMapper[hasLimit.current?.idServicesGroup!]
              }
              {" " + hasLimit.current?.beneficiaryType + " "}
              Para{" "}
              {getValues("amount").toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                bg="red"
                ref={cancelRef}
                onClick={onClose}
                isDisabled={loadingEdit}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="green"
                onClick={submit}
                ml={3}
                isDisabled={loadingEdit}
                isLoading={loadingEdit}
              >
                Mudar limite
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Td>
  );
}
