import { VStack, Heading, Flex, HStack, Box } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
  useToast,
  Portal,
  Select,
} from "@chakra-ui/react";
import { ptBR } from "date-fns/locale";
import { DateRange, DayPicker } from "react-day-picker";
import { useLoading } from "../../../store";
import { usePayrollStore } from "../../../store/payroll.store";
import { useTransactionsStore } from "../../../store/transaction.store";
import { Pagination } from "../../../components/pagination";
import { useRouteError, useSearchParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../services/basicService";
import { payrollTypeTextMap } from "../../../dto/payroll-dto";
import { PayrollType } from "../../../enums/payroll-type";
dayjs.extend(utc);

const filtersFormSchema = z.object({
  dateRange: z
    .object({
      to: z.date().optional(),
      from: z.date().optional(),
    })
    .optional(),
  company: z.string().optional(),
  type: z.string().optional(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

export function PaymentsSupport() {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const companyId = searchParams.get("companyId");
  const type = searchParams.get("type") as PayrollType;

  const routeFetchError = useRouteError();

  const toast = useToast();
  const navigate = useNavigate();

  const { data: companies } = useQuery<{ name: string; id: string }[]>({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data;
    },
  });

  if (routeFetchError) {
    toast({
      status: "error",
      title: "Erro ao carregar informação",
      description: `Erro: ${routeFetchError}`,
    });
  }

  const payrolls = usePayrollStore((state) => state.result);
  const totalPages = usePayrollStore((state) => state.totalPages);
  const generateAllTransactionsPdf = useTransactionsStore(
    (state) => state.generateAllTransactionsPdf
  );
  const loading = useLoading((state) => state.loading);
  const setLoading = useLoading((state) => state.setLoading);
  const generateTransactionsByPayrollCsv = useTransactionsStore(
    (state) => state.generateTransactionsByPayrollCsv
  );

  async function onSubmit(data: filtersFormInput) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("endDate");
      prev.delete("startDate");
      prev.delete("companyId");
      prev.delete("type");

      if (data.dateRange?.from) {
        prev.set(
          "startDate",
          dayjs(data.dateRange.from).utc().format("YYYY-MM-DD")
        );
      }

      if (data.dateRange?.to) {
        prev.set(
          "endDate",
          dayjs(data.dateRange.to).utc().format("YYYY-MM-DD")
        );
      }

      if (data.company) {
        prev.set("companyId", data.company);
      }

      if (data?.type) {
        prev.set("type", data?.type);
      }

      return prev;
    });
  }

  function formatPage() {
    const page = searchParams.get("page");

    if (page) {
      return parseInt(page);
    }
    return 1;
  }

  const {
    handleSubmit,
    control,
    register,
    formState: { isDirty },
  } = useForm<filtersFormInput>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      company: companyId || undefined,
      dateRange: {
        from: startDate ? dayjs(startDate).toDate() : undefined,
        to: endDate ? dayjs(endDate).toDate() : undefined,
      },
      type: type || undefined,
    },
  });

  return (
    <VStack
      gap={8}
      w="full"
      flex={1}
      alignItems="flex-start"
      style={{ containerType: "inline-size" }}
    >
      <Heading fontWeight="extrabold" textAlign="left">
        Folhas processadas
      </Heading>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={4}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={8}
          p={8}
          onSubmit={handleSubmit(onSubmit)}
          style={{
            containerType: "inline-size",
          }}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex justifyContent="space-between" gap={8}>
            <Flex
              flex={1}
              gap={8}
              alignItems="end"
              flexDirection={{ md: "column", lg: "row" }}
            >
              <FormControl as="fieldset" w="max-content">
                <FormLabel as="legend">Data da folha</FormLabel>
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button>
                      {!startDate
                        ? "Escolha a sua data"
                        : !endDate
                          ? startDate
                          : `${startDate.replaceAll(
                              "-",
                              "/"
                            )} a ${endDate.replaceAll("-", "/")}`}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent bg="white" p={2} w="max-content">
                      <PopoverBody>
                        <Controller
                          control={control}
                          name="dateRange"
                          render={({ field }) => (
                            <DayPicker
                              id="dateFilter"
                              mode="range"
                              initialFocus={true}
                              selected={field.value as DateRange}
                              onSelect={field.onChange}
                              locale={ptBR}
                              modifiersClassNames={{
                                disabled: "btn-disabled",
                                selected: "custom-selected",
                                outside: "outside-day",
                              }}
                            />
                          )}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Tipo da folha</FormLabel>
                <Select
                  variant="filled"
                  size="md"
                  {...register("type")}
                  focusBorderColor="green.500"
                  w="full"
                >
                  <option value="">Todos</option>
                  {Object.entries(PayrollType).map(([key, value]) => (
                    <option value={value} key={key}>
                      {payrollTypeTextMap[value]}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl as="fieldset">
                <FormLabel as="legend">Empresa</FormLabel>
                <Select
                  variant="filled"
                  {...register("company")}
                  size="md"
                  w="full"
                >
                  <option value="">Todos</option>
                  {companies?.map((company) => (
                    <option value={company.id} key={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Button
                type="submit"
                bg="green.600"
                w="max-content"
                color="whiteAlpha.900"
                _hover={{
                  bg: "green.700",
                }}
                px={8}
                size="md"
                isDisabled={!isDirty}
              >
                Filtrar
              </Button>

              <Button
                w="max-content"
                px={10}
                size="md"
                isDisabled={!startDate && !companyId && !type}
                onClick={() => {
                  setSearchParams((prev) => {
                    prev.delete("type");
                    prev.delete("startDate");
                    prev.delete("endDate");
                    prev.delete("companyId");
                    return prev;
                  });
                }}
              >
                Limpar Filtro
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          p={8}
          gap={4}
          display="flex"
          flexDir="column"
          style={{
            containerType: "inline-size",
          }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th w="25%">Data de envio</Th>
                <Th>Folha de pagamento</Th>
                <Th>Empresa</Th>
                <Th textAlign="center">Tipo</Th>
                <Th w="25%" textAlign="center">
                  Exportar resumo PDF
                </Th>
                <Th textAlign="center">Exportar CSV</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payrolls?.map((payroll) => (
                <Tr
                  key={payroll.id}
                  cursor="pointer"
                  onClick={() =>
                    navigate(`details/${payroll.id}`, {
                      relative: "route",
                    })
                  }
                >
                  <Td>
                    {dayjs(payroll.created_at).utc().format("DD/MM/YYYY")}
                  </Td>
                  <Td>{payroll.name}</Td>
                  <Td>{payroll.company.name}</Td>
                  <Td textAlign="center">{payrollTypeTextMap[payroll.type]}</Td>
                  <Td textAlign="center">
                    <Button
                      role="a"
                      size="md"
                      color="white"
                      isDisabled={loading}
                      isLoading={loading}
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setLoading(true);
                        await generateAllTransactionsPdf(
                          payroll.id,
                          payroll.name
                        );
                        setLoading(false);
                      }}
                    >
                      Exportar
                    </Button>
                  </Td>
                  <Td textAlign="center">
                    <Button
                      role="a"
                      size="md"
                      color="white"
                      loadingText={"Gerando CSV"}
                      isLoading={loading}
                      isDisabled={loading}
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await generateTransactionsByPayrollCsv(
                          String(payroll.id)
                        );
                      }}
                    >
                      Exportar
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={formatPage()}
            setPage={(page) =>
              setSearchParams((prev) => {
                prev.set("page", String(page));
                return prev;
              })
            }
            totalPages={totalPages}
          />
        </HStack>
      </Box>
    </VStack>
  );
}
