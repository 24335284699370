import { useQuery } from "@tanstack/react-query";
import { CompanyDto } from "../../dto/company-dto";
import { useBaseCrud as baseCrud } from "./base-crud";
import { IPaginationOptions } from "../../dto/generics/pagination-options.dto";
import { getWithAuth } from "../basicService";

const baseURL = "/api/v1/companies";

const filterWithPagination = async (
  filter: object,
  paginationOptions: IPaginationOptions
): Promise<{
  companies: CompanyDto[];
  pages: number;
}> => {
  const params = new URLSearchParams();
  Object.entries({ ...filter, ...paginationOptions }).forEach(([key, value]) =>
    params.set(key, String(value))
  );
  const response = await getWithAuth(`${baseURL}`, {}, { params });
  return response?.data;
};

const findAll = async (): Promise<CompanyDto[]> => {
  const response = await getWithAuth(`${baseURL}/all`, {}, {});
  return response?.data;
};

export const companyService = () => {
  return {
    ...baseCrud<CompanyDto>(baseURL),
    filterWithPagination,
    findAll,
  };
};

export const companyQuery = () => ({
  useFilterWithPagination: (
    filter: Partial<CompanyDto> & { search?: string },
    paginationOptions: IPaginationOptions,
    onError?: (error: Error) => Promise<void> | void,
    onSuccess?: (result: unknown) => Promise<void> | void
  ) =>
    useQuery({
      queryKey: ["companies", ...Object.values(filter)],
      queryFn: async () => {
        try {
          const result = await companyService().filterWithPagination(
            filter,
            paginationOptions
          );

          if (onSuccess) {
            onSuccess(result);
          }
          return result;
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      },
    }),
  useFindByFilter: (
    filter: Partial<CompanyDto> & { search?: string },
    onError?: (error: Error) => Promise<void> | void,
    onSuccess?: (result: unknown) => Promise<void> | void
  ) =>
    useQuery({
      queryKey: ["companies", ...Object.values(filter)],
      queryFn: async () => {
        try {
          const companies: CompanyDto[] = [];
          let totalPages = 1;

          for (let page = 1; page <= totalPages; page++) {
            const paginationOptions: IPaginationOptions = { page, limit: 100 };
            const result = await companyService().filterWithPagination(
              filter,
              paginationOptions
            );

            if (!result.pages) {
              continue;
            }

            companies.push(...result.companies);
            totalPages = result.pages;
          }

          if (onSuccess) {
            onSuccess({ companies, pages: totalPages });
          }
          return { companies, pages: totalPages };
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      },
    }),
  useFindAll: (
    onError?: (error: Error) => Promise<void> | void,
    onSuccess?: (result: unknown) => Promise<void> | void
  ) =>
    useQuery({
      queryKey: ["companies"],
      queryFn: async () => {
        try {
          const result = await companyService().findAll();

          if (onSuccess) {
            onSuccess(result);
          }
          return result;
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      },
    }),
});
