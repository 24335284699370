import { VStack, Heading, Flex, Text } from "@chakra-ui/layout";
import { CompanyDto } from "../../../../dto/company-dto";
import { useState } from "react";
import { SelectCompanyForm } from "./components/select-company-form";
import { IfoodLoginForm } from "./components/login-form";

export function LoginIfoodSupport() {
  const [selectedCompany, setSelectedCompany] = useState<CompanyDto | null>(
    null
  );
  return (
    <VStack
      gap={8}
      w="full"
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Heading fontWeight="extrabold">Login ifood</Heading>

      <Flex
        w="full"
        flex={1}
        p={8}
        gap={4}
        display="flex"
        flexDir="column"
        border="1px"
        borderRadius={8}
        bg={"whiteAlpha.800"}
        borderColor={"gray.300"}
        shadow="base"
      >
        <SelectCompanyForm onCompanyChange={setSelectedCompany} />
        {selectedCompany ? (
          !selectedCompany.integrations?.trampayIfood?.renewable ? (
            <IfoodLoginForm companyId={selectedCompany.id} />
          ) : (
            <Text textAlign="center" fontSize="md">
              Empresa está logada ao ifood.
            </Text>
          )
        ) : (
          <Text textAlign="center" fontSize="md">
            Por favor, selecione uma empresa.
          </Text>
        )}
      </Flex>
    </VStack>
  );
}
