import { Flex, VStack } from "@chakra-ui/layout";
import { Outlet, useNavigation } from "react-router-dom";
import { LoadingLogo } from "../../components/loading";

export function ScalesLayout() {
  const { state } = useNavigation();

  return (
    <Flex
      gap={6}
      w="full"
      flexDir={["column", "column", "column", "row"]}
      flexWrap="wrap"
      px={4}
    >
      <Flex w="full">
        <VStack w="full" h="full" align={"flex-start"}>
          {state === "loading" ? <LoadingLogo /> : <Outlet />}
        </VStack>
      </Flex>
    </Flex>
  );
}
