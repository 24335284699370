import { Select } from "@chakra-ui/react";

export type OptionsSelect = {
  label: string;
  value: string | number | readonly string[] | undefined;
};

interface CustomSelectProps {
  name: string;
  options: OptionsSelect[] | undefined;
  value: string | number | readonly string[] | undefined;
  width: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

export const CustomSelect = ({
  name,
  options,
  value,
  width,
  onChange,
}: CustomSelectProps) => {
  return (
    <Select name={name} value={value} width={width} onChange={onChange}>
      <option value="">Selecione</option>
      {options?.map(({ label, value }, index: number) => {
        return (
          <option key={index} value={value}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};
