import { Badge, Divider, Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useUsersDashboardStore } from "../../../store/user-company.store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { Pagination } from "../../../components/pagination";
import { useState } from "react";
import { UserDto } from "../../../dto/user-dto";
import { patchWithAuth } from "../../../services/basicService";
import { isAxiosError } from "axios";

const searchFormSchema = z.object({
  search: z.string(),
});

enum STATUS {
  "Active" = "ativo",
  "Inactive" = "inativo",
}

type searchFormInputs = z.infer<typeof searchFormSchema>;

export function Users() {
  const [searchParams, setSearchParams] = useSearchParams({ page: "1" });
  const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);

  const page = Number(searchParams.get("page")) || 1;
  const search = searchParams.get("search") || undefined;

  const listUsers = useUsersDashboardStore((state) => state.listAllUsers);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["list-users", page, search],
    queryFn: () => listUsers(page, search),
    keepPreviousData: true,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<searchFormInputs>({
    resolver: zodResolver(searchFormSchema),
  });

  const statusChangeForm = useForm<{
    statusId: number;
    userId: number;
    reason: string;
  }>();

  async function handleSearch(data: searchFormInputs) {
    setSearchParams((prev) => {
      prev.set("search", data.search);
      prev.set("page", "1");
      return prev;
    });
  }

  const navigate = useNavigate();
  const toast = useToast({
    duration: 3000,
  });

  function onModalClose() {
    onClose();
    statusChangeForm.reset();
    setSelectedUser(null);
  }

  async function ChangeStatus(data: {
    statusId: number;
    userId: number;
    reason: string;
  }) {
    const newStatusId = data.statusId === 1 ? 2 : 1;

    const statusChangeBody = {
      statusId: newStatusId,
      id: data.userId,
      origin: "DASHBOARD",
      reason: data.reason,
    };

    try {
      await patchWithAuth("/api/v1/users/status", statusChangeBody);

      toast({
        status: "success",
        title: `Mudança de status feita com sucesso`,
        isClosable: true,
      });

      refetch();
      statusChangeForm.reset();
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          status: "error",
          title: `Não foi possível mudar o status`,
          description: "Error: " + error?.response?.data.message,
          isClosable: true,
        });
        return;
      }

      toast({
        status: "error",
        title: `Não foi possível mudar o status`,
        description: "Não foi possível mudar o status, contate o suporte",
        isClosable: true,
      });
    } finally {
      onModalClose();
    }
  }

  return (
    <VStack gap={8} flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Usuários
      </Heading>

      <Modal isOpen={isOpen} onClose={onModalClose} isCentered>
        <ModalOverlay />
        <ModalContent padding={4}>
          <ModalHeader>Mudar status do usuário</ModalHeader>
          <ModalCloseButton />

          <Divider />

          <ModalBody display="flex" flexDir="column" gap={4}>
            <Text fontSize="lg">Você deseja mudar o status deste usuário?</Text>
            <Text fontSize="lg" textTransform="capitalize">
              <strong>Nome:</strong>{" "}
              {`${selectedUser?.firstName} ${selectedUser?.lastName}`}
            </Text>
            <Text fontSize="lg" textTransform="capitalize">
              <strong>Empresa: </strong>
              {selectedUser?.company?.name}
            </Text>
            <Text fontSize="lg" textTransform="capitalize">
              <strong>Status: </strong>
              <Badge
                variant="solid"
                px={4}
                colorScheme={
                  selectedUser?.status?.name === "Active" ? "green" : "red"
                }
              >
                {selectedUser?.status
                  ? STATUS[selectedUser?.status.name as keyof typeof STATUS]
                  : "undefined"}
              </Badge>
            </Text>

            <form
              id="change-status-form"
              onSubmit={statusChangeForm.handleSubmit(ChangeStatus)}
            >
              <input
                hidden
                {...statusChangeForm.register("statusId", {
                  valueAsNumber: true,
                })}
                value={selectedUser?.status?.id}
              />
              <input
                hidden
                {...statusChangeForm.register("userId", {
                  valueAsNumber: true,
                })}
                value={selectedUser?.id}
              />
              <FormControl
                isInvalid={!!statusChangeForm.formState.errors.reason}
              >
                <FormLabel fontSize="lg" textTransform="capitalize">
                  <strong>Razão</strong>
                </FormLabel>
                <Textarea
                  {...statusChangeForm.register("reason", {
                    required: {
                      value: true,
                      message:
                        "Por favor insira uma razão para mudança de status",
                    },
                  })}
                  bg={"gray.50"}
                  color={"gray.700"}
                />
                {statusChangeForm.formState.errors.reason ? (
                  <FormErrorMessage>
                    {statusChangeForm.formState.errors.reason.message}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter
            display="flex"
            w="full"
            justifyContent="flex-end"
            gap={4}
          >
            <Button onClick={onModalClose}>Cancelar</Button>
            <Button
              form="change-status-form"
              type="submit"
              bg={selectedUser?.status?.id === 1 ? "red.500" : "green.500"}
              _hover={{
                bg: selectedUser?.status?.id === 1 ? "red.600" : "green.600",
              }}
            >
              Mudar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <VStack w="full" h="full" gap={6} borderRadius={8}>
        <Flex w="full" gap={4} alignItems="center">
          <Button
            type="button"
            role="a"
            size="lg"
            color="white"
            onClick={() => navigate("create-user", { relative: "route" })}
          >
            Criar usuário
          </Button>
        </Flex>

        <TableContainer
          w="full"
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          gap={8}
          shadow="base"
          justifyContent="space-between"
          display="flex"
          flexDir="column"
          bg="whiteAlpha.800"
        >
          <Flex
            as="form"
            w="full"
            gap={4}
            onSubmit={handleSubmit(handleSearch)}
            flexDir="column"
          >
            <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
              Filtros
            </Heading>
            <Flex w="full" gap={4}>
              <FormControl as="fieldset" isInvalid={!!errors.search} maxW="3xl">
                <Input
                  {...register("search")}
                  placeholder="Busque por nome ou email..."
                  size="lg"
                  maxW="3xl"
                />
                {errors.search ? (
                  <FormErrorMessage>{errors.search.message}</FormErrorMessage>
                ) : null}
              </FormControl>
              <Button type="submit" title="search" size="lg">
                <MagnifyingGlass weight="bold" />
              </Button>
            </Flex>
          </Flex>
          <Table variant="striped" colorScheme="green" layout="auto">
            <Thead>
              <Tr>
                <Th flex={1} textAlign="center">
                  Nome
                </Th>
                <Th textAlign="center">Email</Th>
                <Th textAlign="center">Cargo</Th>
                <Th textAlign="center">Data de entrada</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Mudar status</Th>
                <Th textAlign="center">Detalhes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? data?.users.map((user) => (
                    <Tr key={user.id}>
                      <Td textAlign="center">{`${user.firstName} ${user.lastName}`}</Td>
                      <Td textAlign="center">{user.email}</Td>
                      <Td textAlign="center">
                        {user?.roles?.map((role) => (
                          <Badge key={role.id}>{role?.name}</Badge>
                        ))}
                      </Td>

                      <Td textAlign="center" w="min-content">
                        {user.createdAt
                          ? dayjs(user.createdAt).format("DD/MM/YYYY")
                          : "undefined"}
                      </Td>
                      <Td textAlign="center">
                        <Badge
                          variant="solid"
                          px={4}
                          colorScheme={
                            user?.status?.name === "Active" ? "green" : "red"
                          }
                        >
                          {user.status
                            ? STATUS[user.status.name as keyof typeof STATUS]
                            : "undefined"}
                        </Badge>
                      </Td>
                      <Td textAlign="center" w="min-content">
                        <Button
                          onClick={() => {
                            setSelectedUser(user);
                            onOpen();
                          }}
                          size="sm"
                          colorScheme="yellow"
                        >
                          Mudar status
                        </Button>
                      </Td>

                      <Td w="10%" textAlign="center">
                        <Button
                          onClick={() =>
                            navigate(`edit-user/${user.id}`, {
                              relative: "route",
                            })
                          }
                          size="sm"
                        >
                          Detalhes
                        </Button>
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
          <Flex w="full" justifyContent="center" gap={3}>
            <Pagination
              page={page}
              setPage={(page) =>
                setSearchParams((prev) => {
                  prev.set("page", String(page));
                  return prev;
                })
              }
              totalPages={data?.pages || 1}
            />
          </Flex>
        </TableContainer>
      </VStack>
    </VStack>
  );
}
