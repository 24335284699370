import { AxiosResponse } from "axios";
import { getWithAuth, postWithAuth } from "../basicService";

const baseURL = "/api/v1/ifood_integration/jarvis_auth";

export const useJarvisAuthService = () => {
  const checkAuth = async () => {
    const response = await getWithAuth(`${baseURL}`, {});
    return response?.data;
  };

  const token = async (data: {
    email: string;
    code: string;
  }): Promise<AxiosResponse | undefined> => {
    const response = await postWithAuth(`${baseURL}`, data);
    return response;
  };

  return {
    token,
    checkAuth,
  };
};
