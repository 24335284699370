import { create } from "zustand";
import { getWithAuth } from "../../services/basicService";

interface VerifyReprocessButtonProps {
  isActivated: boolean;
  getVerifyReprocess: () => Promise<void>;
  setTimeoutVerifyReprocess: () => Promise<void>;
}

export const useVerifyReprocessButton = create<VerifyReprocessButtonProps>(
  (set) => ({
    isActivated: false,

    getVerifyReprocess: async () => {
      const ENDPOINT = "/api/v1/uploaded-file/financial/verify";

      try {
        const response = await getWithAuth(ENDPOINT);
        const data = response?.data;

        if (data.isActivated) {
          set({ isActivated: data.isActivated });
        }
      } catch (err) {}
    },

    setTimeoutVerifyReprocess: async () => {
      setTimeout(async () => {
        const ENDPOINT = "/api/v1/uploaded-file/financial/verify";

        try {
          const response = await getWithAuth(ENDPOINT);
          const data = response?.data;
          if (data.isActivated) {
            set({ isActivated: data.isActivated });
          }
        } catch (err) {}
      }, 5000);
    },
  })
);
