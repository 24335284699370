import { create } from "zustand";

export interface useLoadingInterface {
  loading: boolean;
  setLoading: (isLoading: boolean) => void;
}

export const useLoading = create<useLoadingInterface>((set) => ({
  loading: false,
  setLoading: (isLoading: boolean) => set({ loading: isLoading }),
}));
