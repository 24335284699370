import { Flex } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { DeliverymanDto } from "../../../../../../dto/deliveryman-dto";
import { EqualInstallments } from "./equal-installments";

const createDebtFormSchema = z.object({
  installmentType: z.enum(["equal", "custom"]),
});

type createDebtFormInputs = z.infer<typeof createDebtFormSchema>;

type IProps = {
  deliveryman: DeliverymanDto | undefined;
};

export function CreateDebtDeliverymanMonth({ deliveryman }: IProps) {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm<createDebtFormInputs>({
    resolver: zodResolver(createDebtFormSchema),
  });

  const installmentType = watch("installmentType");

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <Flex
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        p={8}
        borderRadius={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex gap={8} flexDirection="column" w="full">
          <FormControl
            width="fit-content"
            justifyContent="flex-start"
            as="fieldset"
            isInvalid={!!errors.installmentType}
          >
            <FormLabel as="legend">Tipo de parcelas</FormLabel>
            <Select {...register("installmentType")}>
              <option key="equal" value="equal">
                Parcelas iguais
              </option>
              <option key="custom" value="custom">
                Parcelas personalizadas
              </option>
            </Select>

            {errors.installmentType && (
              <FormErrorMessage>
                {errors.installmentType.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <EqualInstallments {...{ installmentType, deliveryman }} />
        </Flex>
      </Flex>
    </Flex>
  );
}
