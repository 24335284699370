import { Link, LinkProps } from "@chakra-ui/layout";
import { CaretRight } from "@phosphor-icons/react";
import {
  Link as LinkRoute,
  LinkProps as LinkRouteProps,
} from "react-router-dom";

type CustomLinkProps = LinkProps &
  LinkRouteProps & {
    isSelected: boolean;
    children: React.ReactNode;
  };

export function SubMenuButton({
  isSelected,
  children,
  ...props
}: CustomLinkProps) {
  if (isSelected) {
    return (
      <Link
        as={LinkRoute}
        p={2}
        borderRadius={4}
        bg="blackAlpha.800"
        color="whiteAlpha.900"
        fontWeight="bold"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
        w="full"
        {...props}
      >
        {children}
        <CaretRight weight="bold" />
      </Link>
    );
  }
  return (
    <Link
      as={LinkRoute}
      p={2}
      borderRadius={4}
      w="full"
      _hover={{
        bg: "gray.200",
        textDecoration: "none",
      }}
      {...props}
    >
      {children}
    </Link>
  );
}
