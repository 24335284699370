import { VStack, Heading, Flex, Box, HStack } from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stat,
  StatLabel,
  StatNumber,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { usePayrollStore } from "../../../store/payroll.store";
import { useTransactionsDraftStore } from "../../../store/transaction.store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { formatCPF } from "../../../utils/format-document";
import { convertCentsToReais } from "../../../utils/convert-money";
import { useNavigate, useRouteError, useSearchParams } from "react-router-dom";
import { Pagination } from "../../../components/pagination";
import { PayrollStatus } from "../../../enums/payroll-status";
import { payrollStatusTextMap } from "../../../dto/payroll-dto";
import { CaretLeft } from "@phosphor-icons/react";

const filterFormSchema = z.object({
  name: z.string().optional(),
  document: z
    .string()
    .max(11, "O CPF deve ter no máximo 11 caracteres")
    .optional(),
  status: z.string().optional(),
});

type filterFormInputs = z.infer<typeof filterFormSchema>;

export function ScheduledPayrollDetails() {
  const navigate = useNavigate();
  const transactions = useTransactionsDraftStore((state) => state.result);
  const totalRecords = useTransactionsDraftStore((state) => state.totalRecords);
  const totalPages = useTransactionsDraftStore((state) => state.totalPages);
  const payroll = usePayrollStore((state) => state.payroll);
  const routeFetchError = useRouteError();
  const toast = useToast();

  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const name = searchParams.get("name");
  const document = searchParams.get("document");

  if (routeFetchError) {
    toast({
      status: "error",
      title: "Erro ao carregar informação",
      description: `Erro: ${routeFetchError}`,
    });
  }

  function formatPage() {
    const page = searchParams.get("page");

    if (page) {
      return parseInt(page);
    }
    return 1;
  }

  async function onSubmit(data: filterFormInputs) {
    setSearchParams((prev) => {
      prev.set("page", "1");

      if (data.document) {
        prev.set("document", `${data.document}`);
      }
      if (data.name) {
        prev.set("name", `${data.name}`);
      }
      if (data.status) {
        prev.set("status", `${data.status}`);
      }
      if (!data.document) {
        prev.delete("document");
      }
      if (!data.name) {
        prev.delete("name");
      }
      if (!data.status) {
        prev.delete("status");
      }

      return prev;
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<filterFormInputs>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: {
      document: document || undefined,
      name: name || undefined,
    },
  });

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <HStack>
        <Button marginRight={2} onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading marginLeft={0} fontWeight="extrabold" textAlign="left">
          {`${payroll?.name}`}
        </Heading>
      </HStack>
      <Flex
        gap={4}
        alignItems="center"
        style={{ containerType: "inline-size" }}
        w="full"
      >
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize="xl">Total de registros:</StatLabel>
            <StatNumber fontSize="3xl" color="green.500">
              {totalRecords}
            </StatNumber>
          </Stat>
        </Box>

        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          h="full"
          shadow="base"
        >
          <Stat>
            <StatLabel fontSize="xl">Status:</StatLabel>
            <StatNumber fontSize="3xl" color="green.500">
              {payrollStatusTextMap[payroll?.status as PayrollStatus]}
            </StatNumber>
          </Stat>
        </Box>
      </Flex>

      <TableContainer
        w="full"
        flex={1}
        p={8}
        gap={4}
        display="flex"
        flexDir="column"
        border="1px"
        borderRadius={8}
        bg="whiteAlpha.800"
        borderColor="gray.300"
        shadow="base"
      >
        <Flex
          as="form"
          flexDir="column"
          gap={4}
          id="form"
          w="full"
          p={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>

          <Flex
            flexDir={{ base: "column", lg: "row" }}
            w="full"
            justifyContent="center"
            gap={8}
          >
            <FormControl as="fieldset">
              <FormLabel as="legend">Nome</FormLabel>
              <Input
                type="text"
                {...register("name")}
                fontWeight="bold"
                placeholder="Busque pelo nome"
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </FormControl>
            <FormControl as="fieldset" isInvalid={!!errors.document}>
              <FormLabel as="legend">CPF</FormLabel>
              <Input
                type="text"
                {...register("document")}
                bg="gray.100"
                fontWeight="bold"
                placeholder="Apenas números"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              {errors.document ? (
                <FormErrorMessage>{errors.document.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <Button
              type="submit"
              form="form"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={8}
              size="md"
              alignSelf="flex-end"
              isDisabled={!isDirty}
            >
              Filtrar
            </Button>
          </Flex>
        </Flex>

        <Table>
          <Thead>
            <Tr>
              <Th flex={1}>Nome</Th>
              <Th textAlign="center">CPF</Th>
              <Th isNumeric>Valor</Th>
              <Th textAlign="center">Chave PIX</Th>
              <Th textAlign="left">Tipo da chave</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map((transaction) => {
              return (
                <Tr fontWeight={600} key={transaction.id}>
                  <Td
                    maxW="200px"
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    textTransform={"capitalize"}
                  >
                    {transaction.name}
                  </Td>
                  <Td textAlign="center">{formatCPF(transaction.document)}</Td>
                  <Td isNumeric>{convertCentsToReais(transaction.amount)}</Td>
                  <Td textAlign="center">{transaction?.pix_key || "-"}</Td>
                  <Td textAlign="left">{transaction?.pix_type || "-"}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={formatPage()}
            setPage={(page) =>
              setSearchParams((prev) => {
                prev.set("page", String(page));
                return prev;
              })
            }
            totalPages={totalPages}
          />
        </HStack>
      </TableContainer>
    </VStack>
  );
}
