import {
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  UseStepsReturn,
  VStack,
  Heading,
  Box,
  Text,
} from "@chakra-ui/react";
import { InfoPeriod } from "./info-period";
import { GenerateCnab } from "./generate-cnab";
import { SaveCnab } from "./save-cnab";

const steps = [
  {
    title: "Selecione o Período e o Tipo de Documento",
    description:
      "Escolha o período de datas e o tipo de documento que deseja consultar",
    element: (props: UseStepsReturn) => <InfoPeriod {...props} />,
  },
  {
    title: "Verificação dos Dados",
    description: "Visualize as informações referentes ao período selecionado",
    element: (props: UseStepsReturn) => <GenerateCnab {...props} />,
  },
  {
    title: "Concluindo",
    description: "Conclua o processo para salvar seus arquivos no Drive",
    element: (props: UseStepsReturn) => <SaveCnab {...props} />,
  },
];

export function GenerateCnabProcess() {
  const stepsReturn = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        CNAB
      </Heading>

      <Stepper
        w="full"
        size={["sm", "lg"]}
        colorScheme="green"
        display={["none", "flex"]}
        flexWrap="wrap"
        index={stepsReturn.activeStep}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Text fontSize="xl">
        <strong>{`Passo ${stepsReturn.activeStep + 1}`}:</strong>
        {` ${steps[stepsReturn.activeStep].description}`}
      </Text>

      <VStack
        p={8}
        w="full"
        alignItems="center"
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Heading>{steps[stepsReturn.activeStep].description}</Heading>
        {steps[stepsReturn.activeStep].element(stepsReturn)}
      </VStack>
    </VStack>
  );
}
