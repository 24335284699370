import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Heading,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  useToast,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import { patchWithAuth } from "../../../services/basicService";
import { UploadResponseResults } from "../../../types/upload.type";

const createDeliverymanFormSchema = z.object({
  idEntregador: z.string().nonempty("O id do entregador é obrigatório"),
  nameIfood: z.string().nonempty("O nome do entregador no ifood é obrigatório"),
  nameTrampay: z
    .string()
    .nonempty("O nome do entregador na Trampay é obrigatório"),
  document: z.string().max(14, "O CPF deve no máximo conter 14 dígitos"),
  indexResult: z.number(),
  deliverymanId: z.number(),
});

type createDeliverymanFormInputs = z.infer<typeof createDeliverymanFormSchema>;

interface IDeliveryman extends UploadResponseResults {
  indexResult: number;
}

type IProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  deliverymenWithInvalidCPF: IDeliveryman[];
  setDeliverymenWithInvalidCPF: Dispatch<SetStateAction<IDeliveryman[]>>;
  results: UploadResponseResults[];
  setResults: Dispatch<SetStateAction<UploadResponseResults[]>>;
};

export function ErrorBalanceModal({
  open,
  setOpen,
  deliverymenWithInvalidCPF,
  setDeliverymenWithInvalidCPF,
  results,
  setResults,
}: IProps) {
  const [openForm, setOpenForm] = useState(false);
  const toast = useToast();

  const readErrors = useDisclosure({ id: "error-balance" });

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors: formErrors, isSubmitting },
  } = useForm<createDeliverymanFormInputs>({
    resolver: zodResolver(createDeliverymanFormSchema),
    defaultValues: {
      document: undefined,
      idEntregador: undefined,
      nameIfood: undefined,
      nameTrampay: undefined,
    },
  });

  async function onSubmit(data: createDeliverymanFormInputs) {
    const { indexResult, ...rest } = data;

    try {
      await patchWithAuth(`/api/v1/deliveryMan`, rest);
      const { document } = data;

      setDeliverymenWithInvalidCPF((prev) =>
        prev.filter(
          (deliveryman) =>
            deliveryman.id_da_pessoa_entregadora !== data.idEntregador
        )
      );
      results[indexResult] = { ...results[indexResult], document };
      reset();
      toast({
        status: "success",
        title: "Dados editados com sucesso!",
        duration: 2000,
      });
    } catch (err) {
      const error = err as AxiosError;

      if (error) {
        let description =
          "Não foi possível editar o entregador, contate o suporte";

        if (error.response?.status === 422) {
          const data = error.response.data as {
            statusCode: number;
            message: string[];
            error: string;
          };

          description = data.message.join(", ");
        }

        if (error.response?.status === 409) {
          const err = error.response.data as { message: string };

          description = err.message;
        }

        toast({
          status: "error",
          title: "Não foi possível editar o entregador",
          description,
          duration: 2000,
        });
      }
    }
  }

  function removeBalance(data: IDeliveryman) {
    setDeliverymenWithInvalidCPF((prev) =>
      prev.filter(
        (deliveryman) =>
          deliveryman.id_da_pessoa_entregadora !== data.id_da_pessoa_entregadora
      )
    );
    setResults((prev) =>
      prev.filter(
        (item) =>
          item.id_da_pessoa_entregadora !== data.id_da_pessoa_entregadora
      )
    );
  }

  useEffect(() => {
    if (deliverymenWithInvalidCPF.length === 0 && open) {
      setOpen(false);
      setOpenForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverymenWithInvalidCPF]);

  return (
    <Modal
      isOpen={open}
      onClose={readErrors.onClose}
      size="5xl"
      id="read-errors"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent px={4}>
        <ModalHeader textAlign="center" color="red.500" fontSize="x-large">
          Não foi possível cadastrar os saldos pois existem entregadores com
          CPF&apos;s invalídos
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={16}>
          <Accordion allowToggle mr={8}>
            {deliverymenWithInvalidCPF.map((user) => (
              <AccordionItem key={user.id_da_pessoa_entregadora}>
                <Heading fontSize="xl">
                  <AccordionButton
                    py={6}
                    _expanded={{ bg: "green.500", color: "white" }}
                    borderRadius={8}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      {user.name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </Heading>
                <AccordionPanel display="flex" flexDir="column">
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Flex
                      minWidth="max-content"
                      alignItems="center"
                      justifyContent="center"
                      gap="20"
                    >
                      <Button
                        p="2"
                        bg="red.500"
                        color="black"
                        _hover={{
                          bg: "red.600",
                        }}
                        size="lg"
                        onClick={() => removeBalance(user)}
                      >
                        Remover o saldo
                      </Button>
                      <Button
                        p="2"
                        bg="blue.500"
                        color="black"
                        _hover={{
                          bg: "blue.600",
                        }}
                        size="lg"
                        alignSelf="flex-end"
                        onClick={() => {
                          setOpenForm((prev) => !prev);
                          reset();
                          setValue("deliverymanId", user.deliverymanId);
                          setValue("indexResult", user.indexResult);
                          setValue("nameIfood", user.name);
                          setValue("nameTrampay", user.name);
                          setValue(
                            "idEntregador",
                            user.id_da_pessoa_entregadora
                          );
                        }}
                      >
                        Editar Entregador
                      </Button>
                    </Flex>
                    {openForm && (
                      <Flex
                        as="form"
                        flexDir="column"
                        w="max-content"
                        h="full"
                        gap={6}
                        p={8}
                        bg="whiteAlpha.800"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Heading fontSize="2xl">Editar entregador</Heading>
                        <Flex gap={8}>
                          <FormControl
                            as="fieldset"
                            isInvalid={!!formErrors.idEntregador}
                            isRequired
                          >
                            <FormLabel>ID do Entregador</FormLabel>
                            <Input {...register("idEntregador")} isReadOnly />
                            {formErrors.idEntregador ? (
                              <FormErrorMessage>
                                {formErrors.idEntregador.message}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>
                          <FormControl
                            as="fieldset"
                            isInvalid={!!formErrors.nameIfood}
                          >
                            <FormLabel>Nome Ifood</FormLabel>
                            <Input {...register("nameIfood")} isReadOnly />
                            {formErrors.nameIfood ? (
                              <FormErrorMessage>
                                {formErrors.nameIfood.message}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>
                        </Flex>
                        <Flex gap={8}>
                          <FormControl
                            as="fieldset"
                            isInvalid={!!formErrors.nameTrampay}
                            isRequired
                          >
                            <FormLabel>Nome trampay</FormLabel>
                            <Input {...register("nameTrampay")} />
                            {formErrors.nameTrampay ? (
                              <FormErrorMessage>
                                {formErrors.nameTrampay.message}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>
                          <FormControl
                            as="fieldset"
                            isInvalid={!!formErrors.document}
                            isRequired
                          >
                            <FormLabel>CPF</FormLabel>
                            <Input {...register("document")} isRequired />
                            {formErrors.document ? (
                              <FormErrorMessage>
                                {formErrors.document.message}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>
                        </Flex>

                        <Button
                          type="submit"
                          size="lg"
                          alignSelf="flex-end"
                          isLoading={isSubmitting}
                        >
                          Atualizar
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </ModalBody>

        <ModalFooter my={4}>
          <Button
            variant="ghost"
            mr={3}
            onClick={() => {
              setOpen(false);
              setOpenForm(false);
            }}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
