import { useQueries } from "@tanstack/react-query";
import { DockIndividualDocumentsList } from "../../../../dto/docker-individuals-dto";
import { getWithAuth } from "../../../../services/basicService";
import {
  Badge,
  Box,
  Flex,
  HStack,
  Heading,
  List,
  ListItem,
  SimpleGrid,
  StackDivider,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/layout";
import { Button, Card, CardBody, SkeletonText } from "@chakra-ui/react";
import { Download } from "@phosphor-icons/react";
import { Chip } from "../../../../components/chip/chip";
import {
  BlockedIdResponse,
  BlockedProposalUserDto,
} from "../../../../dto/deliveryman-blocked-dto";
import dayjs from "dayjs";

export type SPDIndividual = {
  statusId: number;
  name: string;
  reason: string;
  createDate: string;
};

export type IndividualDisplayOnly = {
  name: string;
  document: string;
  phone: string;
  email: string;
  address: {
    cep: string;
    logradouro: string;
    numero: number;
    complemento: string;
    pontoReferencia: string;
    bairro: string;
    cidade: string;
    uf: string;
  };
  status?: string;
  statusSPD?: SPDIndividual[];
};

export function UserDetailsDisplayOnly({
  document,
  externalId,
  status,
  createdAt,
  lastActiveDate,
}: {
  document: string;
  externalId: string;
  status: string;
  createdAt: string;
  lastActiveDate: string;
}) {
  const [
    { data: userInfo, isLoading: isLoadingInfo },
    { data: userDocuments, isLoading: isLoadingDocuments },
  ] = useQueries({
    queries: [
      {
        queryKey: ["app-user-details", document],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/userExternal/app-users/company/${document}`
          );

          return response?.data.individual as IndividualDisplayOnly;
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retryOnMount: false,
      },
      {
        queryKey: ["app-user-documents", document],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/userExternal/get-documents/${document}`
          );

          return response?.data as DockIndividualDocumentsList;
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retryOnMount: false,
      },
    ],
  });

  const [{ data: BlockedProposal }, { data: BlockedByIdEntregador }] =
    useQueries({
      queries: [
        {
          queryKey: ["blocked-proposal-user", document],
          queryFn: async () => {
            const response = await getWithAuth(
              `/api/v1/proposalUser/details?cpf=${document}`
            );

            return response?.data as BlockedProposalUserDto[];
          },
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          retryOnMount: false,
        },

        {
          queryKey: ["blocked-by-id-entregador", externalId],
          queryFn: async () => {
            const response = await getWithAuth(
              `/api/v1/deliveryMan/blockedByIdEntregador/${externalId}`
            );

            return response?.data as BlockedIdResponse;
          },
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          retryOnMount: false,
        },
      ],
    });

  return (
    <HStack
      w="full"
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      p={8}
      spacing={8}
      shadow="base"
      bg="whiteAlpha.800"
      divider={<StackDivider borderColor="gray.200" />}
      align="stretch"
      wrap={"wrap"}
    >
      <VStack align="stretch" w={"50%"} gap={4}>
        <Flex gap={4}>
          <Heading size="md" fontWeight="extrabold" textAlign="left">
            Dados do entregador
          </Heading>
        </Flex>

        <SkeletonText
          isLoaded={!isLoadingInfo}
          w="full"
          noOfLines={8}
          skeletonHeight={6}
        >
          <VStack align="self-start">
            <Text>
              <strong>Nome:</strong> {userInfo?.name}
            </Text>
            <Text>
              <strong>Documento:</strong> {userInfo?.document}
            </Text>
            <Text>
              <strong>E-mail:</strong> {userInfo?.email}
            </Text>
            <Text>
              <strong>Telefone:</strong> {userInfo?.phone}
            </Text>
            <Text>
              <strong>Endereço:</strong>
              <List paddingLeft={4}>
                <ListItem>
                  <strong>Logradouro: </strong>
                  {userInfo?.address.logradouro}
                </ListItem>
                <ListItem>
                  <strong>Número: </strong>
                  {userInfo?.address.numero}
                </ListItem>
                <ListItem>
                  <strong>CEP: </strong>
                  {userInfo?.address.cep}
                </ListItem>
                <ListItem>
                  <strong>Complemento: </strong>
                  {userInfo?.address.complemento}
                </ListItem>
                <ListItem>
                  <strong>Bairro: </strong>
                  {userInfo?.address.bairro}
                </ListItem>
                <ListItem>
                  <strong>Cidade: </strong>
                  {userInfo?.address.cidade}
                </ListItem>
                <ListItem>
                  <strong>Estado: </strong>
                  {userInfo?.address.uf}
                </ListItem>
              </List>
            </Text>
          </VStack>
        </SkeletonText>
      </VStack>
      <VStack align="stretch" gap={2} w={"40%"}>
        <Heading size="md" fontWeight="extrabold" textAlign="left">
          Documentos do entregador
        </Heading>

        <Flex align="stretch" flexDir="row" wrap="wrap" gap={4}>
          {!isLoadingDocuments ? (
            userDocuments?.result.map((document) => (
              <Card maxW="sm" key={document.documentId} minW="300px">
                <CardBody>
                  <Flex gap={4} justifyContent="space-between">
                    <VStack>
                      <Text>{document.category}</Text>
                      {document.invalidDetails ? (
                        <Badge colorScheme="red">
                          {`Inválido, código: ${document.invalidDetails.reasonCode}`}
                        </Badge>
                      ) : null}
                    </VStack>
                    <Button
                      onClick={async () => {
                        const response = await getWithAuth(
                          `/api/v1/userExternal/get-documents/download/${document.documentId}`
                        );

                        window.open(response?.data, "_blank");
                      }}
                      size="sm"
                      color="whiteAlpha.900"
                    >
                      <Download size={20} weight="bold" />
                    </Button>
                  </Flex>
                </CardBody>
              </Card>
            ))
          ) : (
            <SkeletonText w="full" noOfLines={8} skeletonHeight={6} />
          )}
        </Flex>
      </VStack>

      <VStack align="stretch" w="full" gap={4}>
        <Flex gap={4}>
          <Heading size="md" fontWeight="extrabold" textAlign="left">
            Dados gerais
          </Heading>
        </Flex>

        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          <Box borderRadius={8} shadow="base" bg="gray.100" height="auto">
            <UnorderedList padding={5}>
              <ListItem>
                Status de cadastro:{" "}
                <Chip key={1} value={status ? "Ativo" : "Inativo"} />
              </ListItem>
              <ListItem>
                Bloqueado pelo CPF:{" "}
                <Chip
                  key={
                    BlockedProposal && Number(BlockedProposal.length) > 0
                      ? BlockedProposal[0].id
                      : "1"
                  }
                  value={
                    BlockedProposal && Number(BlockedProposal.length) > 0
                      ? BlockedProposal[0].isblocked
                        ? "Sim"
                        : "Não"
                      : "Não"
                  }
                />
              </ListItem>
              <ListItem>
                Bloqueado pelo ID:{" "}
                <Chip
                  key={BlockedByIdEntregador?.id}
                  value={BlockedByIdEntregador?.isBlocked ? "Sim" : "Não"}
                />
              </ListItem>
              <ListItem>
                Data do cadastro:{" "}
                <Chip
                  key={1}
                  value={dayjs(createdAt || "").format("DD-MM-YYYY")}
                />
              </ListItem>
              <ListItem>
                Data do ativação:{" "}
                <Chip
                  key={1}
                  value={dayjs(lastActiveDate || "").format("DD-MM-YYYY")}
                />
              </ListItem>
            </UnorderedList>
          </Box>

          <Box borderRadius={8} shadow="base" bg="gray.100" height="auto">
            <UnorderedList padding={5}>
              <ListItem>
                Status de conta:{" "}
                {isLoadingInfo ? (
                  "Buscando informações...."
                ) : (
                  <Chip key={1} value={userInfo?.status || "-"} />
                )}
              </ListItem>
            </UnorderedList>
          </Box>
        </SimpleGrid>
      </VStack>
    </HStack>
  );
}
