import { create } from "zustand";

interface ModalStoreProps {
  showPendentDebts: boolean;
  generatingPdf: boolean;

  changeDebtsModalStatus: (showModal: boolean) => Promise<void>;
  changeGeneratingPdfStatus: (showModal: boolean) => Promise<void>;
}

export const useModalStore = create<ModalStoreProps>((set) => ({
  showPendentDebts: false,
  generatingPdf: false,

  changeDebtsModalStatus: async (showModal: boolean) => {
    set({ showPendentDebts: showModal });
  },

  changeGeneratingPdfStatus: async (showModal: boolean) => {
    set({ generatingPdf: showModal });
  },
}));
