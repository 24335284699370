export enum ConciliationValidationStatus {
  PENDING = "pending",
  SUCCESS = "success",
  FAILURE = "failure",
}

export const translateConciliationValidationStatusMapper = {
  [ConciliationValidationStatus.PENDING]: "pendente",
  [ConciliationValidationStatus.SUCCESS]: "sim",
  [ConciliationValidationStatus.FAILURE]: "não",
};
