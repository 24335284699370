import { DeliverymanDto } from "../../dto/deliveryman-dto";
import { postWithAuth } from "../basicService";

const baseURL = "/api/v1/deliveryMan";

const syncMany = async (
  companyId: number,
  idEntregador: string[]
): Promise<{
  newDeliverymen: DeliverymanDto[];
  updatedDeliverymen: DeliverymanDto[];
}> => {
  const response = await postWithAuth(`${baseURL}/sync/many`, {
    companyId,
    uuids: idEntregador,
  });
  return response?.data;
};

export const useDeliverymanService = () => {
  return {
    syncMany,
  };
};
