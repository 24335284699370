import { HStack } from "@chakra-ui/react";
import { HeadingComponent } from "./components/heading";
import { StatusSwitchComponent } from "./components/status-switch";
import { ByPassMessage } from "./components/message";

export function PayrollScheduleSettings() {
  return (
    <HStack
      w="100%"
      h="100%"
      display={"flex"}
      wrap={"wrap"}
      justifyContent="center"
      alignItems="start"
      spacing={4}
      p={4}
      gap={4}
    >
      <HeadingComponent />
      <StatusSwitchComponent />
      <ByPassMessage />
    </HStack>
  );
}
