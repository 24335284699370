import { Dispatch, MutableRefObject, SetStateAction, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { getWithAuth } from "../../../services/basicService";
import {
  UploadResponseErrors,
  UploadResponseResults,
} from "../../../types/upload.type";

type IProps = {
  setResults: Dispatch<SetStateAction<UploadResponseResults[]>>;
  setErrors: Dispatch<SetStateAction<UploadResponseErrors | null>>;
  reprocessUuid: MutableRefObject<string | null>;
  closeModal: () => void;
};

export function useReprocessFinancialWorksheets({
  setResults,
  setErrors,
  reprocessUuid,
  closeModal,
}: IProps) {
  const [loadingReprocess, setLoadingReprocess] = useState(false);
  const toast = useToast();

  const reprocessFinancialWorksheets = (startDate: Date, endDate: Date) => {
    if (loadingReprocess) return;
    setLoadingReprocess(true);

    const ENDPOINT = `/api/v1/uploaded-file/financial?startDate=${startDate}&endDate=${endDate}`;

    getWithAuth(ENDPOINT)
      .then((response) => {
        const data = response?.data;
        reprocessUuid.current = data.sendId;

        setResults(
          data.result.map((value: UploadResponseResults) => ({
            ...value,
            editedAmount: value.valor,
            valorMax: value.valor,
            isBalanceDeleted: false,
          }))
        );

        setErrors(data.errors);

        if (data.errors?.balancesWithIncorrectDates.length) {
          toast({
            status: "warning",
            title: "Há registros com datas de repasse errados",
            description: `Há ${data.errors?.balancesWithIncorrectDates.length} registros com data de repasse errados`,
          });
        }

        closeModal();
      })
      .catch((err) => {
        const error = err as AxiosError;

        if (error.response?.status === 404) {
          toast({
            status: "error",
            title: "Ação proibida",
            description: `Não é possivel reprocessar as planilhas pois sua empresa não mandou as planilhas hoje`,
          });
        }
      })
      .finally(() => setLoadingReprocess(false));
  };

  return { reprocessFinancialWorksheets, loadingReprocess };
}
