import { useState } from "react";
import { VStack, Heading, Badge, HStack, Flex, Box } from "@chakra-ui/layout";
import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Td,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Icon,
  useColorMode,
  Stat,
  StatLabel,
  StatNumber,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  Tooltip,
} from "@chakra-ui/react";
import { useAdvanceUserStore } from "../../../store/user-advance.store";
import { useCurrentUserStore } from "../../../store/current-user.store";
import dayjs from "dayjs";
import { Pagination } from "../../../components/pagination";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ArrowDown, ArrowUp } from "@phosphor-icons/react";
import { colorSchemeMap, textSchemeMap } from "../../../dto/advance-user-dto";
import { convertCentsToReais } from "../../../utils/convert-money";
import { AdvanceStatus } from "../../../enums/advance-status";
import { LoadingLogo } from "../../../components/loading";
import "react-day-picker/dist/style.css";
import { formatCPF } from "../../../utils/format-document";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { DownloadReceipt } from "./getReceipt";
import { useExportCsv } from "../../../hooks/adiantamento/use-export-csv";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../services/basicService";
import { COMPANY_TYPE, CompanyDto } from "../../../dto/company-dto";
import { isFranchisor } from "../../../utils/company-verifications";

const statuses = Object.values(AdvanceStatus);

const startHours = [
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const endHours = startHours.slice();
endHours.shift();
endHours.push("23:59");

const filterAdvanceFormSchema = z
  .object({
    search: z.string(),
    square: z.string(),
    status: z.string(),
    startHour: z
      .string()
      .refine(
        (value) =>
          value === "" || /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
      ),
    endHour: z
      .string()
      .refine(
        (value) =>
          value === "" || /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
      ),
    company: z
      .string()
      .transform((value) => Number(value))
      .optional(),
  })
  .refine(
    ({ startHour, endHour }) => {
      if (startHour === "" && endHour === "") {
        return true;
      }
      const startTime = new Date(`2000-01-01T${startHour}:00`);
      const endTime = new Date(`2000-01-01T${endHour}:00`);
      return startTime <= endTime;
    },
    {
      message: `O horário de encerramento deve ser maior do que o horário inicial para aplicar o filtro.`,
      path: ["endHour"],
    }
  );

type filterAdvanceFormInputs = z.infer<typeof filterAdvanceFormSchema>;

export function AdiantamentoEnterprise() {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [isOrderByDesc, setisOrderByDesc] = useState<boolean>(true);
  const [range, setRange] = useState<DateRange | undefined>();
  const me = useCurrentUserStore((state) => state.user);
  const { exportCsv, loading: loadingExportCsv } = useExportCsv();

  const advanceUserData = useAdvanceUserStore(
    (state) => state.advanceUserByEnterprise
  );

  const getAdvanceUserdata = useAdvanceUserStore(
    (state) => state.getAdvanceUserDataByEnterprise
  );

  const { colorMode } = useColorMode();

  const fetchAdvanceData = async () => {
    const dataForm = getValues();
    const filters = urlGenerate(dataForm);
    try {
      return await getAdvanceUserdata(
        `/api/v1/userAdvance/proposal/company?${filters}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: ["advance-data", page, limit, isOrderByDesc],
    queryFn: fetchAdvanceData,
  });

  const fetchFranchiseesOfCompany = useQuery(
    ["company-franchisees"],
    async () => {
      try {
        const response = await getWithAuth(
          `/api/v1/companies/franchisees/${me?.company?.id}`
        );
        return response?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: me?.company?.companyType === COMPANY_TYPE.FRANQUEADORA,
      initialData: () => ({ id: null, franchisees: [] as CompanyDto[] }),
    }
  );

  const handleItemsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(event.target.value));
    setPage(1);
    setisOrderByDesc(true);
  };

  const urlGenerate = (data: filterAdvanceFormInputs) => {
    const params = [];

    if (data.search) {
      params.push(`search=${data.search}`);
    }

    if (data.status !== "all" && data.status !== undefined) {
      params.push(`status=${data.status}`);
    }

    if (data.startHour) {
      params.push(`startHour=${data.startHour}`);
    }

    if (data.endHour) {
      params.push(`endHour=${data.endHour}`);
    }

    if (data.square) {
      params.push(
        `square=${data.square.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
      );
    }

    if (data.company) {
      params.push(`company=${data.company}`);
    } else {
      params.push(`company=${me?.company?.id}`);
    }

    if (range) {
      params.push(`startDay=${range.from?.toISOString()}`);
    }

    if (range?.to) {
      let date = range.to.toISOString();

      if (data.startHour === "") {
        date = dayjs(range.to).toISOString();
      }

      params.push(`endDay=${date}`);
    }

    if (range?.to === undefined && range?.from !== undefined) {
      let date = range.from.toISOString();

      if (data.startHour === "") {
        date = dayjs(range.from).toISOString();
      }

      params.push(`endDay=${date}`);
    }

    let url = `page=${page}&limit=${limit}`;

    if (isOrderByDesc) {
      url += `&orderBy=DESC`;
    } else {
      url += `&orderBy=ASC`;
    }

    url += `&${params.join("&")}`;

    return url;
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm<filterAdvanceFormInputs>({
    resolver: zodResolver(filterAdvanceFormSchema),
  });

  return (
    <VStack
      gap={8}
      w="full"
      alignItems="flex-start"
      style={{ containerType: "inline-size" }}
    >
      <Heading fontWeight="extrabold" textAlign="left">
        Adiantamentos
      </Heading>

      {isLoading ? (
        <LoadingLogo />
      ) : (
        <>
          <Flex gap={8} alignItems="flex-end">
            <Box
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              h="full"
              shadow="base"
              justifyContent="flex-end"
            >
              <Stat>
                <StatLabel fontSize="xl">Total de registros:</StatLabel>
                <StatNumber fontSize="3xl" color="green.500">
                  {advanceUserData.totalRecords}
                </StatNumber>
              </Stat>
            </Box>
            <Flex alignItems="center" gap={15}>
              <Button
                isLoading={loadingExportCsv}
                loadingText="Exportando"
                disabled={loadingExportCsv}
                role="a"
                size="lg"
                bg="green.500"
                color="white"
                _hover={{
                  bg: "green.600",
                }}
                onClick={() =>
                  exportCsv(
                    "/api/v1/userAdvance/company/csv",
                    `adiantamentos-${me?.company?.name}`,
                    urlGenerate(getValues())
                  )
                }
              >
                Exportar CSV
              </Button>
              <Tooltip
                hasArrow
                placement="top"
                label="Os dados exportados serão baseados no filtro dessa página"
                fontSize="md"
              >
                <Icon fontSize={20} color="black" />
              </Tooltip>
            </Flex>
          </Flex>
          <Box
            w="full"
            display="flex"
            flexDir="column"
            gap={4}
            flex={1}
            borderRadius={8}
            border="1px"
            borderColor="gray.300"
            p={8}
            shadow="base"
            bg="whiteAlpha.800"
          >
            <Flex
              as="form"
              flexDir="column"
              gap={4}
              id="form"
              w="full"
              p={4}
              onSubmit={handleSubmit(() => refetch())}
            >
              <Heading
                fontWeight="bold"
                textAlign="left"
                as="h2"
                fontSize="2xl"
              >
                Filtros
              </Heading>
              <Flex
                flexDir={{ base: "column", lg: "row" }}
                w="full"
                justifyContent="center"
                gap={8}
              >
                <FormControl as="fieldset">
                  <FormLabel as="legend">CPF/ID/Nome</FormLabel>
                  <Input
                    type="text"
                    {...register("search")}
                    fontWeight="bold"
                    bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                    color={colorMode === "dark" ? "gray.300" : "gray.700"}
                    focusBorderColor="green.500"
                    placeholder="Busque pelo CPF, ID ou nome do entregador"
                    _placeholder={{
                      color: "gray.500",
                    }}
                  />
                </FormControl>
                <FormControl as="fieldset">
                  <FormLabel as="legend">Status</FormLabel>
                  <Select
                    variant="filled"
                    {...register("status")}
                    size="md"
                    focusBorderColor="green.500"
                    flex={1}
                  >
                    <option value="all">Todos</option>
                    {statuses.map((status) => (
                      <option value={status} key={status}>
                        {textSchemeMap[status]}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl as="fieldset">
                  <FormLabel as="legend">Praça</FormLabel>
                  <Input
                    type="text"
                    {...register("square")}
                    bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                    fontWeight="bold"
                    color={colorMode === "dark" ? "gray.300" : "gray.700"}
                    focusBorderColor="green.500"
                    placeholder="Digite a praça do entregador"
                    _placeholder={{
                      color: "gray.500",
                    }}
                  />
                </FormControl>
                {me?.company?.companyType === COMPANY_TYPE.FRANQUEADORA ? (
                  <FormControl as="fieldset">
                    <FormLabel as="legend">Franqueada</FormLabel>
                    <Select
                      {...register("company")}
                      placeholder="Todas"
                      _placeholder={{
                        color: "gray.500",
                      }}
                      variant="filled"
                      focusBorderColor="green.500"
                      w="full"
                    >
                      {fetchFranchiseesOfCompany?.data?.franchisees.map(
                        (company: CompanyDto) => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>
                ) : null}
              </Flex>

              <Flex
                flexDir={{ base: "column", lg: "row" }}
                w="full"
                justifyContent="center"
                gap={8}
              >
                <FormControl as="fieldset" w="max-content">
                  <FormLabel as="legend">Data de solicitação</FormLabel>
                  <Popover placement="right-end">
                    <PopoverTrigger>
                      <Button>Escolha a sua data</Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        bg={colorMode === "dark" ? "gray.900" : "white"}
                        p={2}
                        w="max-content"
                      >
                        <PopoverBody>
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            selected={range}
                            onSelect={setRange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </FormControl>

                <FormControl
                  as="fieldset"
                  id="hoursFilter"
                  isInvalid={!!errors.endHour}
                  gap={4}
                  display="flex"
                >
                  <Flex flex={1} flexDir="column">
                    <FormLabel as="legend">Horário de:</FormLabel>
                    <Select
                      variant="filled"
                      {...register("startHour")}
                      size="md"
                      focusBorderColor="green.500"
                      w="full"
                    >
                      <option value="">Sem filtro</option>
                      {startHours.map((hour) => (
                        <option value={hour} key={hour}>
                          {hour}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Flex flex={1} flexDir="column">
                    <FormLabel as="legend" marginTop="2px">
                      Até:
                    </FormLabel>
                    <Select
                      variant="filled"
                      {...register("endHour")}
                      size="md"
                      focusBorderColor="green.500"
                      w="full"
                    >
                      <option value="">Sem filtro</option>
                      {endHours.map((hour) => (
                        <option value={hour} key={hour}>
                          {hour}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  {errors.endHour ? (
                    <FormErrorMessage>
                      {errors.endHour.message}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>

                <Button
                  type="submit"
                  form="form"
                  bg="green.600"
                  w="max-content"
                  color="whiteAlpha.900"
                  _hover={{
                    bg: "green.700",
                  }}
                  px={8}
                  size="md"
                  alignSelf="flex-end"
                  isDisabled={!(isDirty || range) || isFetching}
                  isLoading={isFetching}
                >
                  Filtrar
                </Button>
              </Flex>
            </Flex>
            <TableContainer
              w="full"
              flex={1}
              gap={4}
              display="flex"
              flexDir="column"
            >
              <Table colorScheme="green" variant="simple">
                <Thead>
                  <Tr>
                    <Th flex={1}>Nome</Th>
                    <Th textAlign="center">CPF</Th>
                    {isFranchisor(me?.company as CompanyDto) && (
                      <Th textAlign="center">Empresa</Th>
                    )}
                    <Th textAlign="center">ID Entregador</Th>
                    <Th textAlign="center">Praça</Th>
                    <Th isNumeric>Valor Pedido</Th>
                    <Th isNumeric>Taxa</Th>
                    <Th isNumeric>Valor a Receber</Th>
                    <Th
                      textAlign="center"
                      onClick={() => setisOrderByDesc(!isOrderByDesc)}
                      cursor="pointer"
                    >
                      Data de solicitação
                      {isOrderByDesc ? (
                        <Icon as={ArrowDown} />
                      ) : (
                        <Icon as={ArrowUp} />
                      )}
                    </Th>
                    <Th textAlign="center">Status</Th>
                    <Th textAlign="center">Comprovante</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {advanceUserData?.result?.map((advance) => {
                    return (
                      <Tr fontWeight={600} key={advance.id}>
                        <Td
                          maxW="200px"
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          textTransform={"capitalize"}
                        >
                          {advance.username
                            ? advance.username.toLowerCase()
                            : "-"}
                        </Td>
                        <Td textAlign="center">
                          {formatCPF(advance.document)}
                        </Td>
                        {isFranchisor(me?.company as CompanyDto) && (
                          <Td textAlign="center">{advance.companyName}</Td>
                        )}
                        <Td textAlign="center">
                          {advance.idEntregador ? advance.idEntregador : "-"}
                        </Td>
                        <Td textAlign="center">{advance.square}</Td>
                        <Td isNumeric>{convertCentsToReais(advance.amount)}</Td>
                        <Td isNumeric>
                          {convertCentsToReais(advance.amountFee)}
                        </Td>
                        <Td isNumeric>
                          {convertCentsToReais(advance.amountReceivable)}
                        </Td>
                        <Td textAlign="center">
                          {dayjs(advance.createdAt)
                            .subtract(3, "hours")
                            .format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td textAlign="center">
                          <Badge
                            variant="solid"
                            px={4}
                            colorScheme={colorSchemeMap[advance.status]}
                          >
                            {textSchemeMap[advance.status]}
                          </Badge>
                        </Td>
                        <Td textAlign="center">
                          {advance.status === "approved" ? (
                            <DownloadReceipt
                              key={advance.id}
                              id={advance.id}
                              username={advance.username}
                            />
                          ) : (
                            "-"
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <HStack gap={8} pt={8} justifyContent="flex-end">
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={advanceUserData.totalPages}
              />
              <Select
                variant="filled"
                size="md"
                focusBorderColor="green.500"
                w="100px"
                onChange={handleItemsPerPage}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </Select>
            </HStack>
          </Box>
        </>
      )}
    </VStack>
  );
}
