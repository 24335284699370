import { Flex, Heading } from "@chakra-ui/layout";
import { SubMenuButton } from "./sub-menu-button";
import { useLocation } from "react-router-dom";

interface SubMenuProps {
  title: string;
  buttons: {
    label: string;
    to: string;
  }[];
}

export function SubMenu({ title, buttons }: SubMenuProps) {
  const { pathname } = useLocation();

  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      flexDir="column"
      bg="whiteAlpha.800"
      borderRadius={8}
      h="min-content"
      gap={4}
      minW="sm"
      shadow="base"
      border="1px"
      borderColor="gray.300"
    >
      <Heading size="md">{title}</Heading>

      <Flex w="full" justifyContent="flex-start" flexDir="column" gap={2}>
        {buttons.map(({ to, label }, index) => {
          return (
            <SubMenuButton
              key={index}
              to={to}
              relative="route"
              isSelected={to === pathname}
              fontSize="md"
            >
              {label}
            </SubMenuButton>
          );
        })}
      </Flex>
    </Flex>
  );
}
