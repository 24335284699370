import { Flex, Heading } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useCreateCompanyForm } from "../../../../../store/company.store";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { INTEGRATION } from "../../../../../enums/company-integration";
import { COMPANY_TYPE, CompanyDto } from "../../../../../dto/company-dto";
import { getWithAuth } from "../../../../../services/basicService";
import { AxiosError } from "axios";

interface Props {
  goToNext: () => void;
}

const createCompanyFormSchema = z
  .object({
    name: z.string({ required_error: "Este campo é obrigatório" }),
    pracas: z.string().optional(),
    dailyFeePercentage: z.number(),
    dailyFeeFixed: z.number(),
    dailyDescriptionFee: z.string().optional(),
    weeklyFeePercentage: z.number(),
    weeklyFeeFixed: z.number(),
    weeklyDescriptionFee: z.string().optional(),
    integration: z.nativeEnum(INTEGRATION).optional(),
    socialReason: z.string(),
    cnpj: z.string().length(14, "Preencha um CNPJ válido de 14 dígitos"),
    companyType: z.nativeEnum(COMPANY_TYPE).default(COMPANY_TYPE.NORMAL),
    franchisorId: z.string().or(z.number()).optional(),
  })
  .refine(
    (data) => {
      if (data.companyType === COMPANY_TYPE.FRANQUEADO) {
        const hasFranchisorId = !!data.franchisorId;
        return hasFranchisorId;
      }

      return true;
    },
    {
      message: "Uma empresa franqueadora é necessária",
      path: ["franchisorId"],
    }
  );

type createCompanyFormInputs = z.infer<typeof createCompanyFormSchema>;

export function CreateCompanyStep1(props: Props) {
  const client = useQueryClient();
  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as CompanyDto[];
    },
  });

  const createCompany = useCreateCompanyForm((state) => state.createCompany);

  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<createCompanyFormInputs>({
    resolver: zodResolver(createCompanyFormSchema),
    defaultValues: {
      dailyFeeFixed: 0,
      dailyFeePercentage: 0,
      weeklyFeePercentage: 0,
      weeklyFeeFixed: 0,
      companyType: COMPANY_TYPE.NORMAL,
    },
  });

  const companyTypeWatch = watch("companyType");

  async function onNext(data: createCompanyFormInputs) {
    try {
      await createCompany({
        ...data,
        franchisorId:
          data?.franchisorId !== "" ? Number(data?.franchisorId) : undefined,
      });
      client.invalidateQueries(["companies"]);
      props.goToNext();

      toast({
        status: "success",
        title: "Empresa criada com sucesso",
        description:
          "A empresa foi criada com sucesso, no próximo passo vc poderá escolher suas permissões",
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          status: "error",
          duration: 4000,
          title: "Não foi possível atualizar os dados da empresa!",
          description: error.response?.data.message,
        });
        return;
      }
      if (error instanceof Error) {
        toast({
          status: "error",
          title: "Não foi possível criar a empresa",
          description: `A empresa não foi criada, contate o suporte para ter mais ajuda: ${error.message}`,
        });
        return;
      }
      toast({
        status: "error",
        title: "Não foi possível criar a empresa",
        description: `A empresa não foi criada, contate o suporte para ter mais ajuda`,
      });
    }
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <Heading>Dados da Empresa</Heading>

      <Flex
        as="form"
        flexDir="column"
        w="full"
        gap={8}
        flex={1}
        id="form"
        onSubmit={handleSubmit(onNext)}
      >
        <FormControl as="fieldset" isInvalid={!!errors.name} isRequired>
          <FormLabel as="legend">Nome da Empresa</FormLabel>
          <Input
            type="text"
            {...register("name")}
            color="gray.900"
            fontWeight="bold"
            _placeholder={{
              color: "gray.500",
            }}
          />
          {errors.name ? (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          ) : null}
        </FormControl>

        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.pracas}>
            <FormLabel as="legend">Praças</FormLabel>
            <Input
              type="text"
              {...register("pracas")}
              color="gray.900"
              fontWeight="bold"
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.pracas && (
              <FormErrorMessage>{errors.pracas.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.companyType}>
            <FormLabel as="legend">Tipo da empresa</FormLabel>
            <Select {...register("companyType")}>
              {Object.entries(COMPANY_TYPE).map(([label, value]) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
            {errors.companyType && (
              <FormErrorMessage>{errors.companyType.message}</FormErrorMessage>
            )}
          </FormControl>
          {companyTypeWatch === "franchisee" ? (
            <FormControl as="fieldset" isInvalid={!!errors.franchisorId}>
              <FormLabel as="legend">Franqueadora</FormLabel>
              <Select {...register("franchisorId")}>
                <option value={""}>Sem empresa franqueadora</option>
                {companies?.map((company) => (
                  <option value={String(company.id)} key={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
              {errors.franchisorId && (
                <FormErrorMessage>
                  {errors.franchisorId.message}
                </FormErrorMessage>
              )}
            </FormControl>
          ) : null}
        </Flex>

        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.dailyFeePercentage}>
            <FormLabel as="legend">Taxa Percentual Diária</FormLabel>
            <NumberInput borderRadius={4} step={0.2}>
              <NumberInputField
                {...register("dailyFeePercentage", { valueAsNumber: true })}
                color="gray.900"
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {errors.dailyFeePercentage && (
              <FormErrorMessage>
                {errors.dailyFeePercentage.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.dailyFeeFixed}>
            <FormLabel as="legend">Taxa Fixa Diária</FormLabel>
            <NumberInput borderRadius={4} step={0.2}>
              <NumberInputField
                {...register("dailyFeeFixed", { valueAsNumber: true })}
                color="gray.900"
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {errors.dailyFeeFixed && (
              <FormErrorMessage>
                {errors.dailyFeeFixed.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.dailyDescriptionFee}>
            <FormLabel as="legend">Descrição Taxa Diária</FormLabel>
            <Input
              type="text"
              {...register("dailyDescriptionFee")}
              color="gray.900"
              fontWeight="bold"
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.dailyDescriptionFee && (
              <FormErrorMessage>
                {errors.dailyDescriptionFee.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>

        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl as="fieldset" isInvalid={!!errors.weeklyFeePercentage}>
            <FormLabel as="legend">Taxa Percentual Semanal</FormLabel>
            <NumberInput borderRadius={4} step={0.2}>
              <NumberInputField
                {...register("weeklyFeePercentage", { valueAsNumber: true })}
                color="gray.900"
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {errors.weeklyFeePercentage && (
              <FormErrorMessage>
                {errors.weeklyFeePercentage.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.weeklyFeeFixed}>
            <FormLabel as="legend">Taxa Fixa Semanal</FormLabel>
            <NumberInput borderRadius={4} step={0.2}>
              <NumberInputField
                {...register("weeklyFeeFixed", { valueAsNumber: true })}
                color="gray.900"
                fontWeight="bold"
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {errors.weeklyFeeFixed && (
              <FormErrorMessage>
                {errors.weeklyFeeFixed.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.weeklyDescriptionFee}>
            <FormLabel as="legend">Descrição Taxa Semanal</FormLabel>
            <Input
              type="text"
              {...register("weeklyDescriptionFee")}
              color="gray.900"
              fontWeight="bold"
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.weeklyDescriptionFee && (
              <FormErrorMessage>
                {errors.weeklyDescriptionFee.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>

        <Flex flexDir={{ base: "column", lg: "row" }} gap={8}>
          <FormControl
            as="fieldset"
            isInvalid={!!errors.socialReason}
            isRequired
          >
            <FormLabel as="legend">Razão Social</FormLabel>
            <Input
              type="text"
              {...register("socialReason")}
              color="gray.900"
              fontWeight="bold"
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.socialReason && (
              <FormErrorMessage>{errors.socialReason.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.cnpj}>
            <FormLabel as="legend">CNPJ</FormLabel>
            <Input
              type="number"
              {...register("cnpj")}
              color="gray.900"
              fontWeight="bold"
              _placeholder={{
                color: "gray.500",
              }}
            />
            {errors.cnpj && (
              <FormErrorMessage>{errors.cnpj.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl as="fieldset" isInvalid={!!errors.integration}>
            <FormLabel as="legend">Integração</FormLabel>
            <Select {...register("integration")}>
              {Object.entries(INTEGRATION)?.map(
                ([label, value], index: number) => {
                  return (
                    <option key={index} value={value}>
                      {label}
                    </option>
                  );
                }
              )}
            </Select>

            {errors.integration && (
              <FormErrorMessage>{errors.integration.message}</FormErrorMessage>
            )}
          </FormControl>
        </Flex>
      </Flex>
      <Button
        type="submit"
        form="form"
        size="lg"
        alignSelf="flex-end"
        isLoading={isSubmitting}
      >
        Criar Empresa
      </Button>
    </Flex>
  );
}
