import { Box, Flex, Heading, VStack } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Switch,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CustomModal } from "../../../../../components/modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import userFeedbackQuestionService from "../../../../../services/api/user-feedback-question";

const createQuestionSchema = z.object({
  isRequired: z.boolean(),
  question: z.string().min(5),
});

type createQuestionInputs = z.infer<typeof createQuestionSchema>;

export function CreateQuestion() {
  const toast = useToast();
  const client = useQueryClient();

  const { mutate } = useMutation(userFeedbackQuestionService.create, {
    onSuccess: () => {
      client.invalidateQueries(["list-questions"]);
      toast({
        status: "success",
        title: "Pergunta criada com sucesso.",
        description: `Pergunta criada e já disponível aos usuários.`,
      });
      reset();
    },
    onError: () => {
      toast({
        status: "error",
        title: "Erro ao inativar pergunta.",
        description: `Não foi possível inativar a pergunta`,
      });
    },
  });

  const { register, reset, getValues } = useForm<createQuestionInputs>({
    resolver: zodResolver(createQuestionSchema),
    defaultValues: {
      isRequired: false,
    },
  });

  return (
    <VStack gap={8} w="full" h="full" flex={1} alignItems="flex-start">
      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={8}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex as="form" flexDir="column" w="full" gap={8}>
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Criar pergunta
          </Heading>
          <Flex
            alignItems={"center"}
            flexDirection={{ base: "column", md: "row" }}
            gap={8}
          >
            <FormControl as="fieldset" w="full">
              <FormLabel as="legend">Enunciado</FormLabel>
              <Textarea
                placeholder="Digite o texto da pergunta..."
                {...register("question")}
              />
            </FormControl>

            <FormControl as="fieldset" w="min-content">
              <FormLabel as="legend">É uma pergunta obrigatória?</FormLabel>
              <Switch
                id="email-alerts"
                {...register("isRequired")}
                size="lg"
                colorScheme="green"
              />
            </FormControl>
          </Flex>
          <Flex justifyContent="flex-end">
            <CustomModal
              confirmButtonText="Criar"
              modalHeader="ATENÇÃO"
              buttonText="Criar"
              buttonProps={{
                w: "max-content",
                color: "whiteAlpha.900",
                _hover: {
                  bg: "green.700",
                },
                px: 8,
              }}
              onConfirm={() => mutate(getValues())}
              modalBody="Ao criar a pergunta agora, ela já será ATIVADA em nosso sistema, aparecendo para os usuários. Realmente deseja criar a pergunta?"
            />
          </Flex>
        </Flex>
      </Box>
    </VStack>
  );
}
