import {
  TransactionDraftRequestItemDto,
  TransactionDraftResponseItemDto,
} from "../../screens/enterprises/payments/create-payroll-process/dtos/transaction-draft-dto";
import { getWithAuth, postWithAuth } from "../basicService";
import { useBaseCrud } from "./base-crud";

const baseURL = "/api/v1/transaction-draft";

const findByPayroll = async (
  payrollId: number
): Promise<TransactionDraftResponseItemDto[]> => {
  const response = await getWithAuth(`${baseURL}/payroll/${payrollId}`);
  return response?.data;
};

const syncPixFromTransactionDrafts = async (
  transactionDrafts: TransactionDraftRequestItemDto[]
) => {
  const response = await postWithAuth(
    `${baseURL}/conciliated/sync-pix`,
    transactionDrafts
  );

  return response?.data;
};

export const ApiTransactionDraftService = () => {
  return {
    ...useBaseCrud<TransactionDraftResponseItemDto>(baseURL),
    findByPayroll,
    syncPixFromTransactionDrafts,
  };
};
