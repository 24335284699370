export enum PixType {
  CPF = "CPF",
  CNPJ = "CNPJ",
  EMAIL = "EMAIL",
  PHONE = "TELEFONE",
  RANDOM = "ALEATORIO",
}

export enum PixKeyType {
  EMAIL = "email",
  CPF = "cpf",
  TELEFONE = "telefone",
  "CHAVE ALEATORIA" = "aleatorio",
  CNPJ = "cnpj",
}
