import { create } from "zustand";
import { Role } from "../dto/user-dto";
import { getWithAuth } from "../services/basicService";

interface RoleStoreProps {
  getRoleByName: (name: string) => Promise<Role>;
}

export const useRoleStore = create<RoleStoreProps>(() => ({
  getRoleByName: async (name: string) => {
    const role = await getWithAuth(`/api/v1/roles/findByName?name=${name}`);

    return role?.data as Role;
  },
}));
