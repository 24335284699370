export enum TransactionTypes {
  P2P = "P2P",
  PIX = "PIX",
  TEDIN = "IN",
  TEDOUT = "OUT",
  BILL_PAYMENT = "BILL_PAYMENT",
  OTHERS = "OTHERS",
}

export const transactionTypeTextMap = {
  [TransactionTypes.OTHERS]: "Outros",
  [TransactionTypes.P2P]: "P2P",
  [TransactionTypes.PIX]: "PIX",
  [TransactionTypes.TEDIN]: "TED IN",
  [TransactionTypes.TEDOUT]: "TED OUT",
  [TransactionTypes.BILL_PAYMENT]: "Boleto",
};
