import { Heading, Text, VStack } from "@chakra-ui/layout";

export function ScaleTab() {
  return (
    <VStack w="full" h="full" gap={4} p={8} borderRadius={8}>
      <Heading fontSize="3xl" textAlign="left">
        Agendamentos
      </Heading>
      <Text fontSize="lg" textAlign="left">
        Assista ao nosso vídeo tutorial para descobrir como funciona o sistema
        de agendamento.
      </Text>
      <iframe
        width="800px"
        height="500px"
        src="https://www.youtube.com/embed/WKnQG6HXC_E?si=Y0LPjN1gWQAVcyof"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </VStack>
  );
}
