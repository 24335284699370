import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Box, Flex, Heading, VStack } from "@chakra-ui/layout";
import GenericTable from "../table/generic-table";
import { LoadingLogo } from "../loading";

const addButton = (
  <Button as={Link} role="a" size="lg" color="white" to="create-one">
    Adicionar
  </Button>
);

interface ListProps {
  dataQuery: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any | undefined;
    isLoading: boolean;
    error: unknown;
  };
  title?: string;
  tableTitle?: string;
  beforeTable?: ReactNode;
  afterTable?: ReactNode;
  rowActions?: (data: unknown) => ReactNode;
  actionsLabel?: string;
  columns: {
    header: string;
    accessor: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataTransform?: (row: unknown | any) => ReactNode | string;
  }[];
}

export function GenericList({
  title,
  dataQuery,
  tableTitle,
  beforeTable,
  afterTable,
  rowActions,
  actionsLabel,
  columns,
}: ListProps) {
  if (dataQuery.isLoading) {
    return <LoadingLogo />;
  }

  if (dataQuery.error) {
    return <div>Error:{JSON.stringify(dataQuery.error, null, 2)}</div>;
  } else {
    return (
      <Flex
        flexDir={["column", "column", "column", "row"]}
        gap={8}
        px={4}
        width="full"
      >
        <VStack gap={4} mt={8} alignItems="flex-start" width="full">
          {title && (
            <Heading fontWeight="extrabold" textAlign="left">
              {title}
            </Heading>
          )}

          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            shadow="xs"
            justifyContent="space-between"
            alignContent="flex-start"
            w="full"
            gap={8}
            p={8}
          >
            <GenericTable
              title={tableTitle || ""}
              before={beforeTable || addButton}
              after={afterTable}
              columns={columns}
              data={dataQuery.data}
              actions={rowActions}
              actionsLabel={actionsLabel}
            />
          </Box>
        </VStack>
      </Flex>
    );
  }
}
