import { INTEGRATION } from "../enums/company-integration";
import { SpreadsheetTypes } from "../enums/spreadsheet-types.enum";
import { TypeAdvancePeriod } from "../enums/type-advance-period.enum";
import { IntegrationInfo } from "../types/integration-info.type";
import { Status } from "./user-dto";

export enum COMPANY_TYPE {
  FRANQUEADORA = "franchisor",
  FRANQUEADO = "franchisee",
  NORMAL = "default",
}

export enum WorkPeriodRecurrenceType {
  MONTHLY = "monthly",
  WEEKLY = "weekly",
}

export type WorkPeriodConfig = {
  workPeriodStart: number;
  workPeriodRecurrenceType: WorkPeriodRecurrenceType;
  correctionDeadlineDays: number;
  transferStartDeadlineDays: number;
};

export type CompanyPayrollConfig = WorkPeriodConfig[];

export enum CompanySaaSPlan {
  BY_TRANSACTION = "by_transaction",
  PACKAGE = "package",
}

export type TransactionBasedSaaSConfig = {
  numberOfTransactions: number;
  participatesInSaaS: boolean;
  plan: CompanySaaSPlan;
  billingDayOfMonth: number;
  SaaSValue: number;
  transactionValue: number;
  overLimitTransactionValue: number;
};

export type CompanyConfigurations = {
  payroll?: CompanyPayrollConfig;
  transactionBasedSaaS?: TransactionBasedSaaSConfig;
};

export interface CompanyDto {
  id: number;

  name: string;

  pracas: string;

  companyType: COMPANY_TYPE;

  franchisorId?: number;

  franchisor?: CompanyDto;

  franchisees?: CompanyDto[];

  dailyFeePercentage: number;

  dailyFeeFixed: number;

  weeklyFeePercentage: number;

  weeklyFeeFixed: number;

  dailyDescriptionFee: string;

  weeklyDescriptionFee: string;

  releaseTo: string;

  razaoSocial: string;

  cnpj: string;

  account_id?: number;

  paymentAccountId?: number;
  noAliasAccountId?: number;

  status?: Status;

  statusId?: number;

  integration?: INTEGRATION;

  integrations?: IntegrationInfo;

  periodType?: TypeAdvancePeriod;

  amountDaysDueDateAfterEnd?: number;

  spreadsheetTypes?: SpreadsheetTypes;

  scheduledSplitFlow?: boolean;

  configurations: CompanyConfigurations;

  createdAt?: Date;

  conciliatedPaymentSplitFlow?: boolean;
}
