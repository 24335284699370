import dayjs from "dayjs";

export enum format {
  BR = "DD-MM-YYYY",
  US = "YYYY-MM-DD",
}

export function formatDate(
  row: Record<string, unknown>,
  field: string,
  format: format,
  sep = "-"
): string {
  const date = row[field] as string;
  return dayjs(date).utc().format(format).replaceAll("-", sep);
}
