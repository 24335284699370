import { z } from "zod";
import {
  FormField,
  GenericForm,
} from "../../../../components/forms/generic-form";
import { useSubplazaCachedService } from "../../../../services/api/subplazas";
import { useShiftCachedService } from "../../../../services/api/shifts";
import { Shift } from "../../../../dto/shift-dto";
import { Subplaza } from "../../../../dto/subplaza-dto";
import dayjs from "dayjs";

const buildFormFields = (
  shifts: Shift[] | undefined,
  subplazas: Subplaza[] | undefined
) => {
  const formFields: FormField[] = [
    {
      label: "Turno",
      name: "shiftId",
      type: "select",
      options:
        shifts?.map(({ name, id }) => ({ label: name, value: id })) || [],
    },
    {
      label: "Sub-praça",
      name: "subplazaId",
      type: "select",
      options:
        subplazas?.map(({ name, id }) => ({ label: name, value: id })) || [],
    },
    {
      label: "Agendamento partir de",
      name: "fromDateScale",
      type: "date",
    },
    {
      label: "Agendamento até",
      name: "toDateScale",
      type: "date",
    },
  ];

  return formFields;
};

// TODO: change to use correctly form schema
const formSchema = z
  .object({
    subplazaId: z.string().optional(),
    shiftId: z.string().optional(),
    id: z.string().optional(),
    fromDateScale: z.string(),
    toDateScale: z.string(),
    maxDeliveryman: z.string().optional(),
    maxSurplusDeliveryman: z.string().optional(),
  })
  .refine(
    (data) => Date.parse(data.toDateScale) >= Date.parse(data.fromDateScale),
    {
      message: "Data inicial não pode ser maior que a data final",
      path: ["fromDateScale"],
    }
  );

interface SearchProps {
  updateFilter: (data: Record<string, unknown>) => void;
}

export function ExportScaledFilter({ updateFilter }: SearchProps) {
  const { useQueryAction: useSubplazaQueryAction } = useSubplazaCachedService();
  const subplazaQuery = useSubplazaQueryAction("filter", {});
  const subplazas = subplazaQuery.data;

  const { useQueryAction: useShiftQueryAction } = useShiftCachedService();
  const shiftQuery = useShiftQueryAction("filter", {});
  const shifts = shiftQuery.data;

  const formFields = buildFormFields(shifts, subplazas);

  return (
    <GenericForm
      formSchema={formSchema}
      fields={formFields}
      columns={5}
      layout="column"
      initialData={{
        fromDateScale: dayjs().subtract(2, "day").format("YYYY-MM-DD"),
        toDateScale: dayjs().add(1, "day").format("YYYY-MM-DD"),
      }}
      actionLabel="Exportar"
      onSubmit={updateFilter as (data: unknown) => void}
    />
  );
}
