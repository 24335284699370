import { RouterProvider } from "react-router-dom";
import { authenticatedRouter } from "./routes/authenticated-support-routes.tsx";
import { router } from "./routes/index.tsx";
import { useCurrentUserStore } from "./store/current-user.store.ts";
import { authenticatedCompaniesRouter } from "./routes/authenticated-enterprises-routes.tsx";
import "react-day-picker/dist/style.css";

export default function App() {
  const authenticated = useCurrentUserStore((state) => state.user);

  if (!authenticated) {
    return <RouterProvider router={router} />;
  }

  // Check execution mode
  if (import.meta.env.MODE === "development") {
    if (authenticated.company && Number(authenticated.company.id) === 1) {
      return <RouterProvider router={authenticatedRouter} />;
    }
    return <RouterProvider router={authenticatedCompaniesRouter} />;
  }

  // Check domain for enterprise routes
  if (
    window.location.href.includes("https://empresa.trampay.com/") ||
    window.location.href.includes("https://empresa-hml.trampay.com/")
  ) {
    return <RouterProvider router={authenticatedCompaniesRouter} />;
  }

  // Check domain for support routes
  if (
    window.location.href.includes("https://atendimento.trampay.com/") ||
    window.location.href.includes("https://atendimento-hml.trampay.com/")
  ) {
    if (authenticated.company && Number(authenticated.company.id) === 1) {
      return <RouterProvider router={authenticatedRouter} />;
    }

    // If not Trampay employee, rejects
    return <p>Not Allowed</p>;
  }

  // Returns enterprise route in last case
  return <RouterProvider router={authenticatedCompaniesRouter} />;
}
