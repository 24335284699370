import { VStack, Heading, Flex, HStack, Box } from "@chakra-ui/layout";
import {
  FormControl,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
  useToast,
  Portal,
  IconButton,
  Select,
  FormLabel,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import { ptBR } from "date-fns/locale";
import { DateRange, DayPicker } from "react-day-picker";
import {
  useCreatePayroll,
  usePayrollStore,
} from "../../../../store/payroll.store";
import { Pagination } from "../../../../components/pagination";
import { useRouteError, useSearchParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ArrowFatLinesRight, NotePencil, Trash } from "@phosphor-icons/react";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { CompanyDto } from "../../../../dto/company-dto";
import { getWithAuth, patchWithAuth } from "../../../../services/basicService";
import { isFranchisor } from "../../../../utils/company-verifications";
import { AxiosDataError } from "../../../../dto/axios-error-dto";
import { LoadingLogo } from "../../../../components/loading";
import { PayrollDto, payrollTypeTextMap } from "../../../../dto/payroll-dto";

dayjs.extend(utc);

const filtersFormSchema = z.object({
  dateRange: z
    .object({
      to: z.date().optional(),
      from: z.date().optional(),
    })
    .optional(),
  companyId: z
    .string()
    .transform((value) => {
      if (value) return Number(value);
    })
    .optional(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

const updatePayrollNameSchema = z.object({
  id: z.number(),
  name: z.string(),
});

type updatePayrollNameInputs = z.infer<typeof updatePayrollNameSchema>;

export function PayrollDraft() {
  const client = useQueryClient();
  const me = useCurrentUserStore((state) => state.user);
  const getPayrollDrafts = useCreatePayroll(
    (state) => state.getPayrollDraftById
  );
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
    companyId: me?.companyId.toString() || "",
  });
  const page = searchParams.get("page");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const actualDate = new Date();
  const formattedStartDate = startDate ? dayjs(startDate).toDate() : actualDate;
  const formattedEndDate = endDate ? dayjs(endDate).toDate() : undefined;
  const routeFetchError = useRouteError();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  if (routeFetchError) {
    toast({
      status: "error",
      title: "Erro ao carregar informação",
      description: `Erro: ${routeFetchError}`,
    });
  }

  // const drafts = usePayrollStore((state) => state.result);
  const totalPages = usePayrollStore((state) => state.totalPages);
  const changePayrollStatus = useCreatePayroll(
    (state) => state.changePayrollStatus
  );
  const deleteDraft = usePayrollStore((state) => state.deletePayroll);
  const getDrafts = usePayrollStore((state) => state.getSheetsDraft);
  const setIsScheduled = useCreatePayroll((state) => state.setScheduledFlag);
  const setReprocessingScheduledFlag = useCreatePayroll(
    (state) => state.setReprocessingScheduledFlag
  );

  const { handleSubmit, register, control } = useForm<filtersFormInput>({
    defaultValues: {
      dateRange: {
        from: formattedStartDate,
        to: formattedEndDate,
      },
      companyId: undefined,
    },
  });

  const updatePayrollNameForm = useForm<updatePayrollNameInputs>();

  const { data: drafts, isFetching } = useQuery({
    queryKey: ["payroll-draft-sheets", Array.from(searchParams)],
    queryFn: async () => {
      try {
        return await getDrafts({
          filters: {
            startDate: startDate || undefined,
            endDate: endDate || undefined,
          },
          page: page ? +page : 1,
          limit: 10,
          companyId:
            searchParams.get("companyId") &&
            isFranchisor(me?.company as CompanyDto)
              ? Number(searchParams.get("companyId"))
              : me?.companyId,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          toast({
            status: "error",
            title: "Erro ao buscar informações",
            description: `${error?.response?.data.message}`,
          });
          throw error;
        }
      }
    },
  });

  const fetchFranchiseesOfCompany = useQuery(
    ["company-franchisees"],
    async () => {
      try {
        const response = await getWithAuth(
          `/api/v1/companies/franchisees/${me?.company?.id}`
        );
        return response?.data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: isFranchisor(me?.company as CompanyDto),
      initialData: () => ({ id: null, franchisees: [] as CompanyDto[] }),
    }
  );

  const changePayrollName = useMutation({
    mutationFn: (payrollName: updatePayrollNameInputs) =>
      patchWithAuth(`/api/v1/payroll`, {
        ...payrollName,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Sucesso",
        description: `O nome da folha foi alterado com sucesso.`,
      });

      client.invalidateQueries(["payroll-draft-sheets"]);
    },
    onError: (err: AxiosError) => {
      const error = err.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao alterar nome da folha",
        description: `Erro! ${error.message}`,
      });
    },
  });

  const openEditModal = (draft: PayrollDto) => {
    updatePayrollNameForm.setValue("id", draft.id);
    updatePayrollNameForm.setValue("name", draft.name);
    onOpen();
  };

  const handleChangeName = async (payroll: updatePayrollNameInputs) => {
    if (changePayrollName.isLoading) return;

    changePayrollName.mutate(payroll);
    onClose();
    client.invalidateQueries(["payroll-draft-sheets"]);
  };

  async function onSubmit(data: filtersFormInput) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("endDate");

      if (data?.dateRange?.from) {
        prev.set(
          "startDate",
          dayjs(data?.dateRange?.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.dateRange?.to) {
        prev.set(
          "endDate",
          dayjs(data?.dateRange?.to).utc().format("YYYY-MM-DD")
        );
      }

      if (data?.companyId) {
        prev.set("companyId", data?.companyId.toString());
      } else {
        prev.set("companyId", me?.companyId.toString() || "");
      }

      return prev;
    });
  }

  function formatPage() {
    const page = searchParams.get("page");

    if (page) {
      return parseInt(page);
    }
    return 1;
  }

  if (isFetching) {
    return <LoadingLogo />;
  }

  const handleConsult = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    draft: PayrollDto
  ) => {
    e.preventDefault();
    e.stopPropagation();

    changePayrollStatus(true);
    setIsScheduled(false);
    setReprocessingScheduledFlag(!!draft?.schedulingDate);

    await getPayrollDrafts(String(draft.id));

    navigate(`/payments/create-transactions/${draft.id}`, {
      relative: "route",
    });
  };

  return (
    <VStack
      gap={8}
      w="full"
      flex={1}
      alignItems="flex-start"
      style={{ containerType: "inline-size" }}
    >
      <Heading fontWeight="extrabold" textAlign="left">
        Folhas Rascunho
      </Heading>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={4}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={4}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={4}
          p={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex gap={8} alignItems="end">
            <FormControl as="fieldset" w="max-content">
              <FormLabel as="legend">Data de envio</FormLabel>
              <Popover placement="right-end">
                <PopoverTrigger>
                  <Button>Escolha a sua data</Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent bg="white" p={2} w="max-content">
                    <PopoverBody>
                      <Controller
                        control={control}
                        name="dateRange"
                        render={({ field }) => (
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            selected={field.value as DateRange}
                            onSelect={field.onChange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        )}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </FormControl>
            {isFranchisor(me?.company as CompanyDto) ? (
              <FormControl as="fieldset">
                <FormLabel as="legend">Franqueada</FormLabel>
                <Select
                  {...register("companyId")}
                  placeholder="Escolha uma franqueada"
                  _placeholder={{
                    color: "gray.500",
                  }}
                >
                  {fetchFranchiseesOfCompany?.data?.franchisees.map(
                    (company: CompanyDto) => (
                      <option value={company.id} key={company.id}>
                        {company.name}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            ) : null}

            <Button
              type="submit"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={8}
              size="md"
            >
              Filtrar
            </Button>
          </Flex>
        </Flex>
        <TableContainer w="full" p={4} gap={4} display="flex" flexDir="column">
          <Table>
            <Thead>
              <Tr>
                <Th w="fit-content">Data de envio</Th>
                <Th w="fit-content">Ultima atualização</Th>
                <Th w="40%">Nome da folha</Th>
                {isFranchisor(me?.company as CompanyDto) ? (
                  <Th textAlign="center">Franqueada</Th>
                ) : null}
                {me?.company?.conciliatedPaymentSplitFlow && (
                  <Th w="fit-content" textAlign="center">
                    Tipo da folha
                  </Th>
                )}
                <Th w="fit-content" textAlign="center">
                  Enviado por
                </Th>
                <Th w="fit-content" textAlign="center">
                  Consultar
                </Th>
                <Th w="fit-content" textAlign="center">
                  Editar
                </Th>
                <Th w="fit-content" textAlign="center">
                  Deletar
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {drafts?.map((draft) => (
                <Tr key={draft.id}>
                  <Td>{dayjs(draft.created_at).format("DD/MM/YYYY")}</Td>
                  <Td>{dayjs(draft.updated_at).format("DD/MM/YYYY")}</Td>
                  <Td>{draft.name}</Td>
                  {isFranchisor(me?.company as CompanyDto) ? (
                    <Td textAlign="center">{draft.company.name}</Td>
                  ) : null}
                  {me?.company?.conciliatedPaymentSplitFlow && (
                    <Td textAlign="center">{payrollTypeTextMap[draft.type]}</Td>
                  )}
                  <Td textAlign="center">{draft.user?.firstName}</Td>
                  <Td textAlign="center">
                    <IconButton
                      aria-label="campo para ir a tela de detalhes da folha pendente"
                      role="a"
                      icon={<ArrowFatLinesRight size={20} />}
                      onClick={(e) => handleConsult(e, draft)}
                    />
                  </Td>

                  <Td textAlign="center">
                    <IconButton
                      aria-label="campo para editar o nome da folha pendente"
                      icon={<NotePencil size={20} />}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openEditModal(draft);
                      }}
                    />
                  </Td>
                  <Td textAlign="center">
                    {draft.schedulingDate ? (
                      <Tooltip
                        hasArrow
                        label="Não é possível deletar o rascunho gerado de uma folha agendada"
                      >
                        <IconButton
                          bgColor="red"
                          _hover={{
                            bg: "red.600",
                          }}
                          aria-label="campo para deletar uma folha pendente"
                          icon={
                            <Trash color="white" fontWeight="bold" size={20} />
                          }
                          isDisabled={true}
                        />
                      </Tooltip>
                    ) : (
                      <IconButton
                        bgColor="red"
                        _hover={{
                          bg: "red.600",
                        }}
                        aria-label="campo para deletar uma folha pendente"
                        icon={
                          <Trash color="white" fontWeight="bold" size={20} />
                        }
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await deleteDraft(draft.id);
                          client.invalidateQueries(["payroll-draft-sheets"]);
                        }}
                      />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={formatPage()}
            setPage={(page) =>
              setSearchParams((prev) => {
                prev.set("page", String(page));
                return prev;
              })
            }
            totalPages={totalPages}
          />
        </HStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Nome da Folha</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            as="form"
            id="change-payroll-name"
            onSubmit={updatePayrollNameForm.handleSubmit(handleChangeName)}
          >
            <Input
              {...updatePayrollNameForm.register("name")}
              // value={newName}
              // onChange={(e) => setNewName(e.target.value)}
              placeholder="Nome da folha"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              form="change-payroll-name"
              isLoading={updatePayrollNameForm.formState.isSubmitting}
            >
              Salvar
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
