/* eslint-disable prettier/prettier */
import { useRef, useState } from "react";
import {
  VStack,
  Heading,
  Flex,
  ListItem,
  UnorderedList,
  Box,
  Text,
} from "@chakra-ui/layout";
import { DragDrop, IRef } from "../../../components/forms/fileUpload";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Tooltip,
  useDisclosure,
  useToast,
  Table,
  Td,
  NumberInput,
  NumberInputField,
  UseDisclosureReturn,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { Pagination } from "../../../components/pagination";
import { convertCentsToReais } from "../../../utils/convert-money";
import {
  ArrowCounterClockwise,
  CaretRight,
  Info,
  Trash,
  WarningOctagon,
} from "@phosphor-icons/react";
import { postWithAuth } from "../../../services/basicService";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useVerifyReprocessButton } from "../../../store/components/reprocess-button.store";
import {
  UploadResponseResults,
  UploadResponseErrors,
} from "../../../types/upload.type";
import { useSaveBalances } from "../../../hooks/enterprises/performance-and-balances/use-save-balances";
import { ErrorBalanceModal } from "./error-balance-modal";
import { AxiosError } from "axios";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { CompanyStatus } from "../../../enums/company-status";
import { UpdateByIfoodApi } from "../../../components/performace-and-balances/update-by-ifood-api";
import { useConfigurationService } from "../../../services/api/configuration";
import { UploadBalancesResponse } from "../../../dto/upload-balances-response-dto";
import { ReprocessFinancialWorksheets } from "./reprocess-financial-worksheets";
import { useDeliverymanService } from "../../../services/api/deliveryman";
import { COMPANY_TYPE, CompanyDto } from "../../../dto/company-dto";
import { SimplifiedDeliveryman } from "../../../dto/deliveryman-dto";
import { Plaza } from "../../../dto/plaza-dto";

// interface UploadResponse {
//   result: UploadResponseResults[];
//   amount: number;
//   hasError: boolean;
//   errors: UploadResponseErrors;
// }

interface IDeliveryman extends UploadResponseResults {
  indexResult: number;
}

export function PerformanceAndBalances() {
  // check company inactive
  const userCompany = useCurrentUserStore.getState().user?.company;
  if (Number(userCompany?.statusId) === CompanyStatus.INACTIVE) {
    throw new Error("OL inativa. Entre em contato com o suporte!");
  }

  const [results, setResults] = useState<UploadResponseResults[]>([]);
  const [errors, setErrors] = useState<UploadResponseErrors | null>(null);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [page, setPage] = useState(1);
  const [isSending, setIsSending] = useState(false);
  const [deliverymenWithInvalidCPF, setDeliverymenWithInvalidCPF] = useState<
    IDeliveryman[]
  >([]);
  const { handleSaveData, openModal, setOpenModal, loadingSaveBalance } =
    useSaveBalances({
      setResults,
      setErrors,
      setPage,
      setDeliverymenWithInvalidCPF,
    });

  const financialRef = useRef<IRef>(null);
  const performanceRef = useRef<IRef>(null);
  const uuid = useRef<string>("");
  const reprocessUuid = useRef<string | null>(null);

  const { isActivated, setTimeoutVerifyReprocess } = useVerifyReprocessButton();

  const filteredResults: UploadResponseResults[] = results.filter(
    (item) =>
      item.document.includes(searchFilter) ||
      item.name.toLowerCase().includes(searchFilter.toLowerCase())
  );

  const amount = results.reduce(
    (acc, result) => (acc += result.editedAmount),
    0
  );

  const paginatedResults =
    filteredResults.length > 0
      ? filteredResults.slice((page - 1) * 10, page * 10)
      : results.slice((page - 1) * 10, page * 10);

  const pages = Math.ceil(results.length / 10);

  const uploadFiles = useDisclosure({ id: "upload-files" });
  const readErrors = useDisclosure({ id: "read-errors" });
  const registerDeliveryMan = useDisclosure({ id: "register-delivery-man" });

  enum SyncStrategyEnum {
    BY_IFOOD_API = "by_ifood_api",
    BY_CSV = "by_csv",
  }

  const [syncStrategy, setSyncStrategy] = useState<SyncStrategyEnum>();

  const configurationService = useConfigurationService();

  configurationService
    .show("sync_balances_and_performances_with_ifood_api")
    .then((config) => {
      if (config?.value === "true") {
        setSyncStrategy(SyncStrategyEnum.BY_IFOOD_API);
      } else {
        setSyncStrategy(SyncStrategyEnum.BY_CSV);
      }
    });

  const toast = useToast();

  function onSync(
    balanceSyncResult: UploadBalancesResponse,
    performanceSyncResult: unknown,
    sendId: string
  ) {
    uuid.current = sendId;
    processResults(balanceSyncResult, performanceSyncResult);
  }

  async function processResults(
    balanceResults: UploadBalancesResponse,
    performanceResults: unknown
  ) {
    const formattedResult = balanceResults.result.map((value) => ({
      ...value,
      editedAmount: value.valor,
      valorMax: value.valor,
      isBalanceDeleted: false,
    }));

    console.log(performanceResults);

    setResults(formattedResult);

    if (!balanceResults.hasError) {
      setErrors(null);
    }

    setErrors(balanceResults.errors);

    if (balanceResults.errors?.balancesWithIncorrectDates.length) {
      toast({
        status: "warning",
        title: "Há registros com datas de repasse errados",
        description: `Há ${balanceResults.errors?.balancesWithIncorrectDates.length} registros com data de repasse errados`,
      });
    }

    setTimeoutVerifyReprocess();
  }

  async function handleUpload() {
    setIsSending(true);
    uuid.current = crypto.randomUUID();

    const companyIncludeFinancial =
      userCompany?.spreadsheetTypes?.includes("financial");
    const companyIncludePerformance =
      userCompany?.spreadsheetTypes?.includes("performance");

    if (companyIncludeFinancial && companyIncludePerformance) {
      const bothFiles =
        financialRef.current?.hasFiles() && performanceRef.current?.hasFiles();

      if (!bothFiles) {
        toast({
          status: "error",
          title: "Planilha faltante",
          description:
            "É necessário enviar as planilhas de saldo e performance juntas",
        });
        setIsSending(false);
        return;
      }
    }

    let balanceResults = null;
    let performanceResults = null;

    try {
      balanceResults =
        await financialRef.current?.processUpload<UploadBalancesResponse>({
          sendId: uuid.current,
        });

      if (!balanceResults) return;

      setTimeoutVerifyReprocess();
      uploadFiles.onClose();
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 504 || error.response?.status === 524)
      ) {
        console.log("Erro de timeout do lado do servidor.");

        toast({
          status: "error",
          title: "Erro ao enviar folha para reprocessamento",
          description:
            "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
        });
      }
      if (error instanceof AxiosError && error.code === "ERR_NETWORK") {
        toast({
          status: "error",
          title: "Conexão com internet instável",
          description:
            "Verifique sua conexão com internet ou tente novamente mais tarde.",
          duration: 5000,
        });
      }

      if (
        error instanceof AxiosError &&
        (error.response?.status === 403 || error.response?.status === 422) &&
        error.response.data.message !== undefined
      ) {
        toast({
          status: "error",
          title: "Não foi possível enviar as planilhas",
          description: error.response.data.message,
          duration: 5000,
        });
      } else {
        toast({
          status: "error",
          title: "Não foi possível enviar as planilhas",
          description: String(error),
        });
      }
      setIsSending(false);
      return;
    }

    if (companyIncludePerformance) {
      try {
        performanceResults = await performanceRef.current?.processUpload({
          sendId: uuid.current,
        });
      } catch (error) {
        toast({
          status: "error",
          title: "Não foi possível enviar a planilha de performance",
          description: String(error),
        });
        setResults([]);
        setErrors(null);
        setPage(1);
        setIsSending(false);
        return;
      }
    }

    processResults(balanceResults, performanceResults);

    reprocessUuid.current = null;
    setIsSending(false);
    uploadFiles.onClose();
  }

  const format = (
    val: number,
    document: string,
    date: string,
    isBalanceDeleted: boolean
  ) => {
    if (isBalanceDeleted) return;

    const resultIndex = results.findIndex(
      (value) =>
        Number(value.document) === Number(document) &&
        value.data_do_periodo_de_referencia === date
    );

    const newResults = [...results];
    newResults[resultIndex].editedAmount = val;

    setResults(newResults);
  };

  function onDeliverymanRegister() {
    setResults([]);
    setPage(1);
  }

  function removeRecordFromResult(externalId: string, referenceDate: string) {
    const newResults = results.map((result) => {
      if (
        result.id_da_pessoa_entregadora === externalId &&
        result.data_do_periodo_de_referencia === referenceDate
      ) {
        result.valor = result.valorMax;
        result.editedAmount = result.valorMax;
        result.isBalanceDeleted = true;
      }

      return result;
    });

    setResults(newResults);
  }

  function restoreBalance(externalId: string, referenceDate: string) {
    const newResults = results.map((result) => {
      if (
        result.id_da_pessoa_entregadora === externalId &&
        result.data_do_periodo_de_referencia === referenceDate
      ) {
        result.isBalanceDeleted = false;
      }

      return result;
    });

    setResults(newResults);
  }

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Saldos e performance
      </Heading>

      <Flex justifyContent="space-between" w="full" flexDir="column" gap={8}>
        {errors ? (
          <Flex gap={4} flexDir={["column", "row"]}>
            <Button
              size="lg"
              variant="ghost"
              bg="yellow.300"
              _hover={{
                bgColor: "yellow.200",
              }}
              onClick={readErrors.onOpen}
              display="flex"
              gap={4}
            >
              <WarningOctagon color="orange" size={24} />
              Avisos na planilha de saldos
            </Button>

            {Object.keys(errors.deliverymenNotFound).length > 0 && (
              <>
                <Button
                  size="lg"
                  variant="ghost"
                  bg="red.200"
                  _hover={{
                    bgColor: "red.100",
                  }}
                  onClick={registerDeliveryMan.onOpen}
                  display="flex"
                  gap={4}
                >
                  <WarningOctagon color="red" size={24} />
                  Há entregadores não cadastrados
                </Button>
              </>
            )}
            {userCompany && (
              <RegisterDeliveryManModal
                clearState={onDeliverymanRegister}
                disclosure={registerDeliveryMan}
                error={errors.deliverymenNotFound}
                userCompany={userCompany}
              />
            )}

            <Modal
              isOpen={readErrors.isOpen}
              onClose={readErrors.onClose}
              size="5xl"
              id="read-errors"
              scrollBehavior="inside"
            >
              <ModalOverlay />
              <ModalContent px={4}>
                <ModalHeader>Erros na planilha</ModalHeader>
                <ModalCloseButton />
                <ModalBody px={16}>
                  <ErrorsContainer errors={errors} />
                </ModalBody>

                <ModalFooter my={4}>
                  <Button variant="ghost" mr={3} onClick={readErrors.onClose}>
                    Fechar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        ) : null}

        <Modal
          isOpen={uploadFiles.isOpen}
          onClose={uploadFiles.onClose}
          size="5xl"
          id="upload-files"
        >
          <ModalOverlay />
          <ModalContent mx={4}>
            <ModalHeader>Upload de Arquivos</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={16}>
              <Flex gap={16} flexDir="column">
                <Flex flexDir="column" gap={4}>
                  <Flex alignItems="center" gap={4}>
                    <Heading>Saldos</Heading>
                    <Button>
                      <a
                        href="/assets/template-csv-for-balance/template-csv.csv"
                        download
                      >
                        Baixar Modelo .CSV
                      </a>
                    </Button>
                  </Flex>
                  <DragDrop
                    uploadUrl="/api/v1/uploaded-file/financial"
                    maxFiles={17}
                    ref={financialRef}
                  />
                </Flex>
                {userCompany?.spreadsheetTypes?.includes("performance") && (
                  <Flex flexDir="column" gap={4}>
                    <Heading>Performance</Heading>
                    <DragDrop
                      uploadUrl="/api/v1/uploaded-file/performance"
                      maxFiles={17}
                      ref={performanceRef}
                    />
                  </Flex>
                )}
              </Flex>
            </ModalBody>

            <ModalFooter my={4}>
              <Button variant="ghost" mr={3} onClick={uploadFiles.onClose}>
                Fechar
              </Button>
              <Button
                color="white"
                onClick={handleUpload}
                isLoading={isSending}
                isDisabled={isSending}
              >
                Enviar .CSV
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Flex gap={[4, 8]} w="full" flexDir={["column", "row"]}>
        <Flex gap={[4, 8]} justifyContent="space-between">
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            minW={["0", "180px"]}
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>Valor total: </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {amount
                  ? convertCentsToReais(amount * 100)
                  : convertCentsToReais(0)}
              </StatNumber>
            </Stat>
          </Box>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>
                Quantidade de saldos:
              </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {results.length}
              </StatNumber>
            </Stat>
          </Box>
        </Flex>
        <Flex
          width="full"
          flexDir={["column", "row"]}
          justifyContent="space-between"
          alignItems={["center", "flex-end"]}
          gap={8}
        >
          {syncStrategy === "by_ifood_api" &&
          userCompany?.integration === "jarvis_ifood" ? (
            <UpdateByIfoodApi onSync={onSync}></UpdateByIfoodApi>
          ) : (
            syncStrategy && (
              <div>
                <Button
                  size="lg"
                  w={["full", "auto"]}
                  color="white"
                  onClick={() => {
                    uploadFiles.onOpen();
                  }}
                >
                  Enviar .CSV
                </Button>
              </div>
            )
          )}
          {!syncStrategy && (
            <div>
              <Button size="lg" w={["full", "auto"]} color="white" isLoading>
                Carregando
              </Button>
            </div>
          )}
          <ReprocessFinancialWorksheets
            {...{ setErrors, setResults, reprocessUuid, isActivated }}
          />
        </Flex>
      </Flex>

      <TableContainer
        w="full"
        display="flex"
        flexDir="column"
        alignItems={["flex-start", "center"]}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
        gap={8}
        overflow="auto"
        h="full"
      >
        <Flex flexDir="column" w="full" gap={4}>
          <Heading size="md">Filtros</Heading>

          <FormControl>
            <FormLabel>Pesquisa</FormLabel>

            <Input
              placeholder="Procure por nome ou documento..."
              value={searchFilter}
              maxW="3xl"
              onChange={(e) => setSearchFilter(e.currentTarget.value)}
            />
          </FormControl>
        </Flex>
        {results.length > 0 ? (
          <Button
            isLoading={loadingSaveBalance}
            loadingText="Salvando saldos"
            disabled={loadingSaveBalance}
            size="lg"
            color="white"
            isDisabled={results.length <= 0}
            alignSelf="flex-start"
            onClick={() => handleSaveData(results, { reprocessUuid, uuid })}
          >
            Adicionar saldos
          </Button>
        ) : null}

        <Table variant="striped" colorScheme="green">
          <Thead>
            <Tr>
              <Th textAlign="center">Nome</Th>
              <Th textAlign="center">Documento</Th>
              <Th textAlign="center">Data de referência</Th>
              <Th textAlign="center">Praça</Th>
              <Th textAlign="center">Tipo</Th>
              <Th textAlign="center">Valor (R$)</Th>
              <Th textAlign="center">Status</Th>
              <Th textAlign="center">Excluir/Restaurar</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedResults.map((result) => (
              <Tr
                key={`${result.id_da_pessoa_entregadora}-${result.data_do_periodo_de_referencia}`}
              >
                <Td textAlign="center">{result.name}</Td>
                <Td textAlign="center">{result.document}</Td>
                <Td textAlign="center">
                  {result.data_do_periodo_de_referencia}
                </Td>
                <Td textAlign="center">{result.praca}</Td>
                <Td textAlign="center">{result.type}</Td>
                <Td textAlign="center">
                  <Flex justify="center">
                    <NumberInput
                      defaultValue={result.editedAmount}
                      max={result.valorMax}
                      min={0}
                      onChange={(_, value) =>
                        format(
                          value,
                          result.document,
                          result.data_do_periodo_de_referencia,
                          result.isBalanceDeleted
                        )
                      }
                      onBlur={(input) => {
                        if (Number(input.target.value) === 0) {
                          removeRecordFromResult(
                            result.id_da_pessoa_entregadora,
                            result.data_do_periodo_de_referencia
                          );
                        }
                      }}
                      value={result.editedAmount}
                      w="max-content"
                      display="flex"
                      gap={4}
                      alignItems="center"
                      inputMode="decimal"
                      precision={2}
                      step={0.1}
                      isValidCharacter={(value) => /^[Ee0-9+\-.,]$/.test(value)}
                    >
                      R$
                      <NumberInputField
                        textAlign="right"
                        border="1px"
                        borderColor="gray.400"
                        type="number"
                      />
                      <Tooltip
                        label={`O valor máximo desse saldo é de R$ ${result.valorMax}`}
                        hasArrow
                      >
                        <Info size={30} color="black" />
                      </Tooltip>
                    </NumberInput>
                  </Flex>
                </Td>
                <Td textAlign="center">
                  {result.isBalanceDeleted ? (
                    <Text color="red">DELETADO</Text>
                  ) : (
                    <Text color="green">NORMAL</Text>
                  )}
                </Td>
                <Td textAlign="center">
                  {result.isBalanceDeleted ? (
                    <Button
                      onClick={() =>
                        restoreBalance(
                          result.id_da_pessoa_entregadora,
                          result.data_do_periodo_de_referencia
                        )
                      }
                    >
                      <ArrowCounterClockwise
                        size={24}
                        color="white"
                        weight="bold"
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      onClick={() =>
                        removeRecordFromResult(
                          result.id_da_pessoa_entregadora,
                          result.data_do_periodo_de_referencia
                        )
                      }
                    >
                      <Trash size={24} color="white" weight="bold" />
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex justifyContent="center" w="full">
        <Pagination page={page} setPage={setPage} totalPages={pages} />
      </Flex>
      <ErrorBalanceModal
        deliverymenWithInvalidCPF={deliverymenWithInvalidCPF}
        setDeliverymenWithInvalidCPF={setDeliverymenWithInvalidCPF}
        open={openModal}
        setOpen={setOpenModal}
        results={results}
        setResults={setResults}
      />
    </VStack>
  );
}

export function ErrorsContainer({ errors }: { errors: UploadResponseErrors }) {
  return (
    <Flex w="max-content" p={8} borderRadius={8} gap={4}>
      {errors.balancesWithIncorrectDates.length ? (
        <Flex
          shadow="md"
          gap={4}
          bg="red.100"
          borderRadius={4}
          p={4}
          flexDir="column"
        >
          <Heading size="sm">Datas incorretas nos saldos</Heading>
          {errors.balancesWithIncorrectDates.map((error) => (
            <UnorderedList key={error.message}>
              <ListItem>{error.message}</ListItem>
              <ListItem>{error.fileName}</ListItem>
              <ListItem>{error.possibleSolution}</ListItem>
            </UnorderedList>
          ))}
        </Flex>
      ) : null}

      {errors.formattingErrors.length ? (
        <Flex
          shadow="md"
          gap={4}
          bg="red.100"
          borderRadius={4}
          p={4}
          flexDir="column"
        >
          <Heading size="sm">Erro de formatação</Heading>
          {errors.formattingErrors.map((error) => (
            <UnorderedList key={error.message}>
              <ListItem>{error.message}</ListItem>
              <ListItem>{error.fileName}</ListItem>
              <ListItem>{error.possibleSolution}</ListItem>
            </UnorderedList>
          ))}
        </Flex>
      ) : null}

      {errors.transferDateWithErrors.length ? (
        <Flex
          shadow="md"
          gap={4}
          bg="red.100"
          borderRadius={4}
          p={4}
          flexDir="column"
        >
          <Heading size="sm">Data de transferência inválida</Heading>
          {errors.transferDateWithErrors.map((error) => (
            <UnorderedList key={error.message}>
              <ListItem>{error.message}</ListItem>
              <ListItem>{error.fileName}</ListItem>
              <ListItem>{error.possibleSolution}</ListItem>
            </UnorderedList>
          ))}
        </Flex>
      ) : null}

      {errors.customersWithBalanceBelowZero.length ? (
        <Flex
          shadow="md"
          gap={4}
          bg="red.100"
          borderRadius={4}
          p={4}
          flexDir="column"
        >
          <Heading size="sm">Clientes com saldo abaixo de zero</Heading>
          {errors.customersWithBalanceBelowZero.map((error) => (
            <UnorderedList key={error.id}>
              <ListItem>{error.name}</ListItem>
              <ListItem>{convertCentsToReais(error.amount * 100)}</ListItem>
            </UnorderedList>
          ))}
        </Flex>
      ) : null}

      {errors.inactiveDeliverymen.length ? (
        <Flex
          shadow="md"
          gap={4}
          bg="red.100"
          borderRadius={4}
          p={4}
          flexDir="column"
        >
          <Heading size="sm">Entregador Inativo</Heading>
          {errors.inactiveDeliverymen.map((error) => (
            <UnorderedList key={error.id}>
              <ListItem>Nome IFood: {error.nameIfood}</ListItem>
              <ListItem>Nome Trampay: {error.nameTrampay}</ListItem>
            </UnorderedList>
          ))}
        </Flex>
      ) : null}

      {errors.squaresNotFound.length ? (
        <Flex
          shadow="md"
          gap={4}
          bg="red.100"
          borderRadius={4}
          p={4}
          flexDir="column"
        >
          <Heading size="sm">Praça não encontrada</Heading>
          {errors.squaresNotFound.map((error) => (
            <UnorderedList key={error.value}>
              <ListItem>Tipo: {error.type}</ListItem>
              <ListItem>Praça: {error.value}</ListItem>
            </UnorderedList>
          ))}
        </Flex>
      ) : null}
    </Flex>
  );
}

interface RegisterDeliveryManModalProps {
  disclosure: UseDisclosureReturn;
  error: {
    [key: string]: {
      id: string;
      name: string;
      plaza?: string;
    }[];
  };
  clearState: () => void;
  userCompany: CompanyDto;
}

const createDeliverymanFormSchema = z.object({
  idEntregador: z.string().nonempty("O id do entregador é obrigatório"),
  nameIfood: z.string().nonempty("O nome do entregador é obrigatório"),
  document: z.string().max(14, "O CPF deve no máximo conter 14 dígitos"),
  plaza: z.string().nonempty("A praça deve ser preenchida."),
});

type createDeliverymanFormInputs = z.infer<typeof createDeliverymanFormSchema>;

function RegisterDeliveryManModal({
  disclosure,
  error,
  clearState,
  userCompany,
}: RegisterDeliveryManModalProps) {
  const [registeredDeliveryMans, setRegisteredDeliveryMans] = useState<
    string[]
  >([]);

  const registerByIntegration = false; // userCompany?.integration !== "none";
  const userCompanyId = userCompany?.id;

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    formState: { errors: formErrors, isSubmitting },
  } = useForm<createDeliverymanFormInputs>({
    resolver: registerByIntegration
      ? zodResolver(createDeliverymanFormSchema)
      : undefined,
    defaultValues: {
      document: undefined,
      idEntregador: undefined,
      nameIfood: undefined,
      plaza: undefined,
    },
  });

  const deliverymanService = useDeliverymanService();

  const toast = useToast();

  async function onSubmit(data: createDeliverymanFormInputs) {
    const newDeliveryman = data;

    reset();
    try {
      if (registerByIntegration) {
        const { newDeliverymen, updatedDeliverymen } =
          await deliverymanService.syncMany(userCompanyId, [
            newDeliveryman.idEntregador,
          ]);
        const deliverymen = [...newDeliverymen, ...updatedDeliverymen];
        const deliveryman = deliverymen[0];
        setValue("nameIfood", deliveryman.nameIfood);
        setValue("idEntregador", deliveryman.idEntregador);
      } else {
        // fazer service depois
        const createdDeliveryman: { data: SimplifiedDeliveryman } | undefined =
          await postWithAuth(`/api/v1/deliveryMan`, newDeliveryman);

        const verifiedPlaza: { data: Plaza } | undefined = await postWithAuth(
          `/api/v1/plazas/findOrCreate`,
          { name: data.plaza, companyId: userCompany }
        );

        await postWithAuth(`/api/v1/deliveryman_plazas`, {
          deliverymanId: createdDeliveryman?.data.id,
          plazaId: verifiedPlaza?.data.id,
        });
      }
      toast({
        status: "success",
        title: "Entregador criado com sucesso!",
        duration: 2000,
      });
      setRegisteredDeliveryMans((state) => state.concat(data.nameIfood));
      clearState();
    } catch (err) {
      const error = err as AxiosError;
      const errorData = error.response?.data as { message: string[] };

      let description =
        "Não foi possível criar o entregador, contate o suporte";
      if (
        error.response?.status === 422 ||
        error.response?.status === 404 ||
        error.response?.status === 403
      ) {
        description = Array.isArray(errorData.message)
          ? errorData.message.join(", ")
          : errorData.message;
      }

      if (error.response?.status === 409) {
        const err = error.response.data as { message: string };

        description = err.message;
      }

      toast({
        status: "error",
        title: "Não foi possível criar o entregador",
        description,
        duration: 4000,
      });
    }
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      size="6xl"
      id="read-errors"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Entregadores não cadastrados</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDir={["column", "row"]}
          position="relative"
        >
          <Flex flexDir="column" py={8} flex={1}>
            {registeredDeliveryMans.length > 0 ? (
              <Box
                display="flex"
                flexDir="column"
                gap={4}
                bg="yellow.300"
                borderRadius={8}
                shadow="sm"
                mr={8}
                px={8}
                py={4}
                overflow="auto"
                minH="130px"
              >
                <Heading fontSize={["md", "xl"]}>
                  Entregadores cadastrados
                </Heading>
                <Text fontWeight="bold">
                  Você registrou um entregador novo, por favor envie as
                  planilhas novamente
                </Text>

                <ul>
                  {registeredDeliveryMans.map((deliveryMan) => (
                    <li key={deliveryMan}>{deliveryMan}</li>
                  ))}
                </ul>
              </Box>
            ) : null}
            <Accordion allowMultiple allowToggle mr={8}>
              {Object.entries(error).map(([key, value]) => (
                <AccordionItem key={key}>
                  <Heading fontSize={["md", "xl"]}>
                    <AccordionButton
                      py={6}
                      _expanded={{ bg: "green.500", color: "white" }}
                      borderRadius={8}
                    >
                      <Box as="span" flex="1" textAlign="left">
                        {key}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel display="flex" flexDir="column">
                    {value
                      .sort(function (a, b) {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .filter(
                        (deliveryman) =>
                          !registeredDeliveryMans.includes(deliveryman.name)
                      )
                      .map((deliveryman) => (
                        <Button
                          variant="ghost"
                          key={deliveryman.id}
                          justifyContent="space-between"
                          py={8}
                          mr={8}
                          onClick={() => {
                            reset();
                            setValue("nameIfood", deliveryman.name);
                            setValue("idEntregador", deliveryman.id);
                            setValue(
                              "plaza",
                              deliveryman?.plaza ? deliveryman.plaza : "-"
                            );
                          }}
                        >
                          {deliveryman.name}
                          <CaretRight size={24} />
                        </Button>
                      ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
          {userCompany.companyType === COMPANY_TYPE.NORMAL && (
            <Flex
              as="form"
              flexDir="column"
              w={["full", "max-content"]}
              h="full"
              gap={6}
              p={8}
              bg="whiteAlpha.800"
              id="form"
              borderLeft={["0px", "1px"]}
              borderColor={["gray.200", "gray.200"]}
              onSubmit={handleSubmit(onSubmit)}
              position="sticky"
              top={0}
            >
              <Heading fontSize={["xl", "2xl"]}>Cadastrar entregador</Heading>
              <Flex gap={8} flexDir={["column", "row"]}>
                <FormControl
                  as="fieldset"
                  isInvalid={!!formErrors.idEntregador}
                >
                  <FormLabel>ID do Entregador</FormLabel>
                  <Input
                    {...register("idEntregador")}
                    bg="blackAlpha.100"
                    isReadOnly
                  />
                  {formErrors.idEntregador ? (
                    <FormErrorMessage>
                      {formErrors.idEntregador.message}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
                <FormControl as="fieldset" isInvalid={!!formErrors.nameIfood}>
                  <FormLabel>Nome</FormLabel>
                  <Input
                    {...register("nameIfood")}
                    bg="blackAlpha.100"
                    isReadOnly
                  />
                  {formErrors.nameIfood ? (
                    <FormErrorMessage>
                      {formErrors.nameIfood.message}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </Flex>
              <Flex gap={8} flexDir={["column", "row"]}>
                <FormControl as="fieldset" isInvalid={!!formErrors.plaza}>
                  <FormLabel>Praça</FormLabel>
                  <Input
                    {...register("plaza")}
                    bg="blackAlpha.100"
                    isReadOnly
                  />
                  {formErrors.plaza ? (
                    <FormErrorMessage>
                      {formErrors.plaza.message}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
                <FormControl as="fieldset" isInvalid={!!formErrors.document}>
                  <FormLabel>CPF</FormLabel>
                  <Input {...register("document")} bg="blackAlpha.100" />
                  {formErrors.document ? (
                    <FormErrorMessage>
                      {formErrors.document.message}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </Flex>

              <Button
                type="submit"
                form="form"
                bg="green.500"
                color="gray.100"
                _hover={{
                  bg: "green.600",
                }}
                size="lg"
                alignSelf="flex-end"
                isLoading={isSubmitting}
              >
                Criar Entregador
              </Button>
            </Flex>
          )}

          {userCompany.companyType === COMPANY_TYPE.FRANQUEADO &&
            userCompany.franchisorId ===
              +import.meta.env.VITE_IFOOD_COMPANY_ID && (
              <Flex
                flexDir="column"
                w={["full", "max-content"]}
                h="full"
                gap={6}
                p={8}
                bg="whiteAlpha.800"
                borderLeft={["0px", "1px"]}
                borderColor={["gray.200", "gray.200"]}
              >
                <Heading fontSize={["2xl"]}>Informações do entregador</Heading>

                <Box fontSize="xl">
                  <Text fontWeight="bold">Nome:</Text>
                  <Text>{getValues("nameIfood")}</Text>
                </Box>

                <Box fontSize="xl">
                  <Text fontWeight="bold">CPF:</Text>
                  <Text>{getValues("document")}</Text>
                </Box>

                <Box fontSize="xl">
                  <Text fontWeight="bold">Praça:</Text>
                  <Text>{getValues("plaza")}</Text>
                </Box>

                <Box fontSize="xl">
                  <Text fontWeight="bold">ID do Entregador:</Text>
                  <Text>{getValues("idEntregador")}</Text>
                </Box>
              </Flex>
            )}
        </ModalBody>

        <ModalFooter my={4}>
          <Button variant="ghost" mr={3} onClick={disclosure.onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
