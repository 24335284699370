import { z } from "zod";
import { Flex, HStack, Heading, VStack } from "@chakra-ui/layout";
import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { useShiftAssignmentCachedService } from "../../../../../services/api/shift-assignments";
import { Button, useToast } from "@chakra-ui/react";
import { CaretLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useShiftCachedService } from "../../../../../services/api/shifts";
import { useSubplazaCachedService } from "../../../../../services/api/subplazas";
import { Shift } from "../../../../../dto/shift-dto";
import { Subplaza } from "../../../../../dto/subplaza-dto";
import { MutateFunction } from "../../../../../services/api/use-cached-service";
import { ShiftAssignment } from "../../../../../dto/shift-assignment-dto";
import { useEffect } from "react";
import { AxiosError } from "axios";
import { AxiosDataError } from "../../../../../dto/axios-error-dto";

// const statusesEnum = z.enum(["criado", "publicado", "fechado"]);

const buildFormFields = (
  shifts: Shift[] | undefined,
  subplazas: Subplaza[] | undefined
) => {
  const formFields: FormField[] = [
    {
      label: "Turno",
      name: "shiftId",
      type: "select",
      options:
        shifts?.map(({ name, id }) => ({
          label: `${id} - ${name.toUpperCase()}`,
          value: id,
        })) || [],
    },
    {
      label: "Sub-praça",
      name: "subplazaId",
      type: "select",
      options:
        subplazas?.map(({ name, id }) => ({
          label: `${id} - ${name}`,
          value: id,
        })) || [],
    },
    {
      label: "Vagas",
      name: "maxDeliveryman",
      type: "number",
    },
    {
      label: "Excedente",
      name: "maxSurplusDeliveryman",
      type: "number",
    },
    {
      label: "Data do agendamento",
      name: "dateScale",
      type: "date",
    },
    {
      label: "Aceite",
      name: "acceptance",
      type: "select",
      options: [
        { value: "default", label: "padrão" },
        { value: "automatic", label: "automático" },
        { value: "manually", label: "manual" },
      ],
    },
  ];

  return formFields;
};

// TODO: change to use correctly from schema
const formSchema = z.object({
  subplazaId: z.string(),
  shiftId: z.string(),
  dateScale: z.string(),
  acceptance: z.string(),
  maxDeliveryman: z.string(),
  maxSurplusDeliveryman: z.string(),
});

type FormType = ShiftAssignment & { acceptance: string };

function buildHandleSubmit(createShiftAssignment: MutateFunction) {
  return (data: FormType) => {
    data.setting ||= { acceptance: data.acceptance };
    data.setting.acceptance = data.acceptance;

    createShiftAssignment(data);
  };
}

export function ShiftAssignmentFormCreate() {
  const { useQueryAction: useShiftQueryAction } = useShiftCachedService();
  const { useQueryAction: useSubplazaQueryAction } = useSubplazaCachedService();
  const { useMutationAction: useShiftAssignmentMutateAction } =
    useShiftAssignmentCachedService();

  const shiftQuery = useShiftQueryAction("filter", {});
  const subplazaQuery = useSubplazaQueryAction("filter", {});
  const [createShiftAssignment, watchers] =
    useShiftAssignmentMutateAction("create");

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const { status, error } = watchers;
    if (status) {
      if (status === "success") {
        toast({
          status: "success",
          duration: 3000,
          title: "Agendamento criada com sucesso!",
        });
        navigate(-1);
      }
      if (status === "error") {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data as AxiosDataError;
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao criar agendamento",
          description: `Falha ao criar agendamento: ${errorMessage.message}`,
        });
      }
    }
  }, [navigate, toast, watchers, watchers.status]);

  const shifts = shiftQuery.data;
  const subplazas = subplazaQuery.data;

  const formFields = buildFormFields(shifts, subplazas);
  const handleSubmit = buildHandleSubmit(createShiftAssignment) as (
    data: unknown
  ) => void;
  const title = "Criar Agendamento";
  return (
    <VStack gap={2} w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          {title}
        </Heading>
      </HStack>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        alignItems="flex-start"
        bg="whiteAlpha.800"
        shadow="base"
        w="full"
        h="full"
        border="1px"
        borderColor="gray.300"
        p={8}
      >
        <GenericForm
          formSchema={formSchema}
          fields={formFields}
          columns={3}
          layout="column"
          initialData={{}}
          actionLabel="Salvar"
          onSubmit={handleSubmit}
        />
      </Flex>
    </VStack>
  );
}
