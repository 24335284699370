import { TestCNPJ } from "../../utils/cnpj-validator";
import { TestCPF } from "../../utils/cpf-validator";
import { postWithAuth } from "../basicService";

export async function createWebviewToken(document: string) {
  const isValidCpf = TestCPF(document);
  const isValidCnpj = TestCNPJ(document);

  const isValidDocument = isValidCpf || isValidCnpj;

  if (!isValidDocument) throw new Error("Invalid CPF document");

  const token = await postWithAuth("/api/v1/webview-token", {
    document,
    uuid: crypto.randomUUID(),
  });

  return token;
}
