import { CompanyDebt } from "../types/bank-info.type";

export function calculateTotalDebtAmount(debts: CompanyDebt[]) {
  if (!debts || debts.length === 0) {
    return 0;
  }

  const total = debts.reduce((acc, debt) => acc + debt.amount - debt.paid, 0);

  return total;
}
