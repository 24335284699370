import { ShiftAssignmentPreferenceStatus as statusesEnum } from "../enums/shift-assignment-preference-status";
import { DeliverymanDto } from "./deliveryman-dto";
import { ShiftAssignment } from "./shift-assignment-dto";

export interface ShiftAssignmentPreference {
  id: number;
  surplus: boolean;
  status: statusesEnum;
  acceptanceTermMetadata: object;
  shiftAssignmentId: number;
  shiftAssignment: ShiftAssignment;
  deliverymanId: number;
  deliveryman: DeliverymanDto;
  companyId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface PaginateShiftAssignmentPreference {
  page: number;
  limit: number;
  nextPage: boolean;
  totalPages: number;
  result: ShiftAssignmentPreference[];
}

export interface ISearchShiftAssignmentPreferenceDto {
  surplus?: boolean;
  status?: statusesEnum;
  shiftAssignmentId?: number;
  deliverymanId?: number;
  companyId?: number;
}

export const textSchemeMap = {
  [statusesEnum.PENDING]: "Pendente",
  [statusesEnum.ACCEPTED]: "Aceito",
  [statusesEnum.UNACCEPTED]: "Rejeitado",
};

export const colorSchemeMap = {
  [statusesEnum.ACCEPTED]: "green",
  [statusesEnum.UNACCEPTED]: "red",
  [statusesEnum.PENDING]: "gray",
};
