import {
  VStack,
  Input,
  Button,
  Text,
  Icon,
  Box,
  HStack,
  CloseButton,
} from "@chakra-ui/react";
import { File } from "@phosphor-icons/react";
import { useSyncCsvContext } from "../context/sync-csv.context";
import { memo } from "react";

const Component = () => {
  const {
    file,
    dragActive,
    handleFileChange,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    setFile,
    handleSubmitCsv,
  } = useSyncCsvContext();

  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <VStack spacing={4}>
      <Box
        p={10}
        w="100%"
        maxW="400px"
        borderWidth="2px"
        borderStyle="dashed"
        borderColor={dragActive ? "blue.300" : "gray.300"}
        borderRadius="md"
        textAlign="center"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        position="relative"
        cursor="pointer"
        bg={dragActive ? "blue.50" : "gray.50"}
      >
        <Icon as={File} boxSize={12} color="gray.400" />
        <Text mt={2} color="gray.500">
          Arraste e solte aqui os arquivos
        </Text>
        <Input
          type="file"
          accept=".csv"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          opacity="0"
          onChange={handleFileChange}
        />
      </Box>
      {file?.name && (
        <HStack
          spacing={3}
          p={3}
          w="100%"
          maxW="400px"
          borderWidth="1px"
          borderRadius="md"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text>{file.name}</Text>
          <CloseButton onClick={handleRemoveFile} />
        </HStack>
      )}

      <Button colorScheme="green" onClick={handleSubmitCsv} isDisabled={!file}>
        Analisar CSV
      </Button>
    </VStack>
  );
};

export const UploadCsv = memo(Component);
