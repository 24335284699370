import {
  ShiftAssignment,
  PaginateShiftAssignment,
} from "../../dto/shift-assignment-dto";
import { getWithAuth, patchWithAuth } from "../basicService";
import { Service, useBaseCrud } from "./base-crud";
import { useCachedService } from "./use-cached-service";

const baseURL = "/api/v1/shift_assignments";

const paginateFilter = async (
  filter: object
): Promise<PaginateShiftAssignment | null> => {
  const params = new URLSearchParams();
  Object.entries(filter).forEach(([key, value]) => params.set(key, value));
  const response = await getWithAuth(`${baseURL}`, {}, { params });
  return response?.data || null;
};

const publish = async (params: {
  id: number;
}): Promise<ShiftAssignment | null> => {
  try {
    const resp = await patchWithAuth(`${baseURL}/${params.id}/publish`);

    return resp?.data || null;
  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
};

const close = async (params: { id: number }) => {
  try {
    const resp = await patchWithAuth(`${baseURL}/${params.id}/close`);

    return resp?.data || null;
  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
};

const baseSheet: () => Promise<void> = async (): Promise<void> => {
  try {
    const resp = await getWithAuth(
      `${baseURL}/create/base_sheet`,
      {
        "Content-Type": "text/csv",
      },
      { responseType: "arraybuffer" as ResponseType }
    );

    const xlsxBlob = new Blob([resp?.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(xlsxBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `planilha_base_para_envio.xlsx`);
    link.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    throw new Error(`Error: ${error}`);
  }
};

export const useShiftAssignmentService = () => {
  return {
    ...useBaseCrud<ShiftAssignment>(baseURL),
    paginateFilter,
    publish,
    close,
    baseSheet,
  };
};

export const useShiftAssignmentCachedService = () => {
  const shiftAssignmentService =
    useShiftAssignmentService() as unknown as Service<ShiftAssignment>;

  return useCachedService(shiftAssignmentService, "shiftAssignments");
};
