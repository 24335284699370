import {
  VStack,
  Heading,
  Flex,
  Box,
  Divider,
  SimpleGrid,
  Stack,
  Text,
  ListItem,
  List,
} from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Image,
  Skeleton,
  SkeletonText,
  Stat,
  StatArrow,
  StatLabel,
  StatNumber,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { getWithAuth, patchWithAuth } from "../../../../services/basicService";
import { useQuery } from "@tanstack/react-query";
import { Bank, Buildings, Vault } from "@phosphor-icons/react";
import { accountInfo } from "../../../../types/bank-info.type";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { documentFormatter } from "../../../../utils/format-document";
import { CopyText } from "../../../../components/forms/CopyText";
import { CreatePixModal } from "../../../enterprises/settings/add-balance-to-company";
import { convertCentsToReais } from "../../../../utils/convert-money";

const proposalFormSchema = z.object({
  changeState: z.boolean(),
});

type proposalFormInputs = z.infer<typeof proposalFormSchema>;

export function BalanceConfig() {
  const { status } = useLoaderData() as { status: boolean };
  const user = useCurrentUserStore((state) => state.user);

  const toast = useToast();

  const revalidator = useRevalidator();

  const { data, isLoading } = useQuery({
    queryKey: ["advanceAccountInfo"],
    queryFn: async (): Promise<accountInfo> => {
      const response = await getWithAuth("/api/v1/companies/accounts/advance");
      return response?.data as accountInfo;
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<proposalFormInputs>({
    resolver: zodResolver(proposalFormSchema),
    defaultValues: {
      changeState: status,
    },
  });

  async function onSubmit(data: proposalFormInputs) {
    try {
      await patchWithAuth(`/api/v1/configuration/companies-can-send-balance`, {
        key: "companies_can_send_balance",
        value: data.changeState,
      });

      toast({
        status: "success",
        title: `O status das configurações de saldo foi mudado para ${
          data.changeState ? "aberto" : "fechado"
        }`,
      });

      revalidator.revalidate();
    } catch (error) {
      toast({
        status: "error",
        title: `Não foi possivel alterar o status da configuração de saldo, entre em contato com a equipe tecnica`,
      });
    }
  }

  return (
    <VStack gap={8} w="full" alignItems="flex-start" h="max-content">
      <Heading fontWeight="extrabold" textAlign="left">
        Configurações de cadastramento de saldos
      </Heading>

      <Flex
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDir="column"
        w="full"
        h="full"
        gap={6}
        borderRadius={8}
      >
        <Flex gap={8}>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>Saldo total: </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {data
                  ? convertCentsToReais(data?.availableBalanceInCents)
                  : convertCentsToReais(0)}
              </StatNumber>
            </Stat>
          </Box>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            minW="300px"
            h="full"
            shadow="base"
          >
            <Stat border={1}>
              <StatLabel fontSize={["md", "xl"]}>Status</StatLabel>
              <StatNumber fontSize={["xl", "3xl"]}>
                {status ? (
                  <>
                    <StatArrow type="increase" boxSize="0.75em" />
                    Aberto
                  </>
                ) : (
                  <>
                    <StatArrow type="decrease" boxSize="0.75em" />
                    Fechado
                  </>
                )}
              </StatNumber>
            </Stat>
          </Box>
        </Flex>

        <Flex
          w="full"
          display="flex"
          flex={1}
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
          gap={8}
          style={{ containerType: "inline-size" }}
          wrap="wrap"
        >
          <VStack align="flex-start" flex={1} gap={4}>
            <Heading fontWeight="bold" textAlign="left" size="lg">
              Conta Trampay
            </Heading>

            <Divider />

            {isLoading ? (
              <Stack>
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
              </Stack>
            ) : (
              <SimpleGrid
                spacing={4}
                minChildWidth="150px"
                w="full"
                css={`
                  p {
                    color: var(--chakra-colors-blackAlpha-800);
                  }
                `}
              >
                <Box display="flex" flexDir="column" gap={2}>
                  <Flex
                    alignItems="center"
                    gap={4}
                    css={`
                      svg {
                        color: var(--chakra-colors-green-500);
                      }
                    `}
                  >
                    <Buildings size={28} weight="bold" />
                    <Heading
                      fontSize="2xl"
                      fontWeight="semibold"
                      color="blackAlpha.900"
                    >
                      Empresa
                    </Heading>
                  </Flex>
                  <Text fontSize="xl">{user?.company?.name}</Text>
                </Box>

                <Box display="flex" flexDir="column" gap={2}>
                  <Flex
                    alignItems="center"
                    gap={4}
                    css={`
                      svg {
                        color: var(--chakra-colors-green-500);
                      }
                    `}
                  >
                    <Bank size={28} weight="bold" />
                    <Heading
                      fontSize="2xl"
                      fontWeight="semibold"
                      color="blackAlpha.900"
                    >
                      Agência
                    </Heading>
                  </Flex>
                  <Text fontSize="xl">{data?.bankBranchNumber}</Text>
                </Box>
                <Box display="flex" flexDir="column" gap={2}>
                  <Flex
                    alignItems="center"
                    gap={4}
                    css={`
                      svg {
                        color: var(--chakra-colors-green-500);
                      }
                    `}
                  >
                    <Vault size={28} weight="bold" />
                    <Heading
                      fontSize="2xl"
                      fontWeight="semibold"
                      color="blackAlpha.900"
                    >
                      Conta
                    </Heading>
                  </Flex>
                  <Text fontSize="xl">{data?.bankAccountNumber}</Text>
                </Box>
              </SimpleGrid>
            )}

            <Flex flexDir="column" gap={4} w="full">
              <Heading fontWeight="bold" textAlign="left" size="lg">
                Dados de PIX
              </Heading>

              <Divider />

              {isLoading ? (
                <Stack direction="row" w="full" gap={4}>
                  <Skeleton width="250px" height="250px" />
                  <SkeletonText flex={1} height="30px" skeletonHeight={6} />
                </Stack>
              ) : (
                <Flex gap={4} wrap="wrap" w="full">
                  {data?.pix.qrCode ? (
                    <Flex justifyContent="center" w="max-content">
                      <Image
                        src={`data:image/png;base64,${data?.pix.qrCode.image}`}
                        alt="pix qr code"
                        w="xs"
                        h="xs"
                      />
                    </Flex>
                  ) : null}

                  <Flex flexDir="column" alignItems="center" gap={8} flex={1}>
                    <Heading fontSize="xl">Chaves cadastradas:</Heading>
                    {data?.pix.pixKeys && data?.pix.pixKeys.length > 0 ? (
                      <Flex as={List} flexDir="column" gap={4}>
                        {data?.pix.pixKeys.map((pix) => (
                          <ListItem key={pix.key}>
                            <CopyText
                              text={
                                pix.keyType !== "nationalRegistration"
                                  ? pix.key
                                  : documentFormatter(
                                      pix.key,
                                      pix.key.length > 11 ? "cnpj" : "cpf"
                                    )
                              }
                            />
                          </ListItem>
                        ))}
                      </Flex>
                    ) : (
                      <CreatePixModal idAccount={user?.company?.account_id} />
                    )}
                  </Flex>
                </Flex>
              )}
            </Flex>
          </VStack>
          <Divider orientation="vertical" />
          <Flex
            flexDir="column"
            as="form"
            gap={8}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Heading fontWeight="bold" textAlign="left" size="lg">
              Configurações de cadastramento de saldos
            </Heading>
            <FormControl
              display="flex"
              flexDir="column"
              alignItems="center"
              gap={4}
            >
              <FormLabel htmlFor="changeState" mb="0" fontSize="lg">
                Abrir/Fechar cadastramento de saldos?
              </FormLabel>
              <Switch id="changeState" {...register("changeState")} size="lg" />
            </FormControl>

            <Button
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
              size="lg"
              alignSelf="center"
              w="min-content"
            >
              Salvar Status
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </VStack>
  );
}
