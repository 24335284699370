import { Heading, Stack } from "@chakra-ui/layout";

import { memo } from "react";

const Component = () => {
  return (
    <Stack w="100%" alignItems="center">
      <Heading size="md">Configurações da Folha Agendada</Heading>
    </Stack>
  );
};

export const HeadingComponent = memo(Component);
