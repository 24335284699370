import { FormControl, FormLabel, Select, Box, Flex } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { CompanyDto } from "../../../../../dto/company-dto";
import { getWithAuth } from "../../../../../services/basicService";
import { LoadingLogo } from "../../../../../components/loading";

const selectCompanySchema = z.object({
  company: z.string(),
});

type selectCompanyInput = z.infer<typeof selectCompanySchema>;

interface SelectCompanyFormProps {
  onCompanyChange: (company: CompanyDto | null) => void;
}

export function SelectCompanyForm({ onCompanyChange }: SelectCompanyFormProps) {
  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as { name: string; id: string }[];
    },
    staleTime: 60 * 60 * 1000,
  });

  const selectCompanyForm = useForm<selectCompanyInput>({
    resolver: zodResolver(selectCompanySchema),
    defaultValues: {
      company: "",
    },
  });

  const { isFetching } = useQuery({
    queryKey: ["get-company", selectCompanyForm.watch("company")],
    queryFn: async () => {
      const response = await getWithAuth(
        `/api/v1/companies/${selectCompanyForm.watch("company")}`
      );

      onCompanyChange(response?.data);
      return response?.data as CompanyDto;
    },
    enabled: !!selectCompanyForm.watch("company"),
  });

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Flex flexDir="column" as="form" gap={8}>
        <FormControl as="fieldset">
          <FormLabel as="legend">Empresa</FormLabel>
          <Select
            variant="filled"
            {...selectCompanyForm.register("company")}
            size="md"
            w="full"
            placeholder="Selecione empresa"
          >
            {companies?.map((company) => (
              <option value={company.id} key={company.id}>
                {company.name}
              </option>
            ))}
          </Select>
        </FormControl>
        {isFetching && <LoadingLogo />}
      </Flex>
    </Box>
  );
}
