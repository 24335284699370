import { Box, Flex, HStack, Heading, VStack } from "@chakra-ui/layout";
import { Button, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { GenericList } from "../../../../components/scales/generic-list";
import { ShiftFormCreate } from "./create-one";
import { useState } from "react";
import { CaretLeft } from "@phosphor-icons/react";
import { useShiftCachedService } from "../../../../services/api/shifts";
import { ShiftFormEdit } from "./edit";
import { Shift } from "../../../../dto/shift-dto";
import { format, formatDate } from "../../../../utils/format-date";

export function Shifts() {
  const [action, setAction] = useState<string>("show");
  const [selectedShift, setSelectedShift] = useState<Shift>();

  const toast = useToast();

  function formatTime(
    row: Record<string, unknown>,
    field: string
  ): string | null {
    const time = row[field] as string;
    const regex = /^(\d{2}:\d{2})/;
    const correspondence = time.match(regex);
    return correspondence ? correspondence[1] : null;
  }

  const formatCreatedAt = (row: Record<string, unknown>) =>
    formatDate(row, "createdAt", format.BR, "/");
  const formatUpdatedAt = (row: Record<string, unknown>) =>
    formatDate(row, "updatedAt", format.BR, "/");
  const formatStartHour = (row: Record<string, unknown>) =>
    formatTime(row, "startHour");
  const formatEndHour = (row: Record<string, unknown>) =>
    formatTime(row, "endHour");

  const columns = [
    { header: "ID", accessor: "id" },
    { header: "Nome", accessor: "name" },
    { header: "Início", accessor: "startHour", dataTransform: formatStartHour },
    { header: "Final", accessor: "endHour", dataTransform: formatEndHour },
    {
      header: "Data de criação",
      accessor: "createdAt",
      dataTransform: formatCreatedAt,
    },
    {
      header: "Última atualização",
      accessor: "updatedAt",
      dataTransform: formatUpdatedAt,
    },
  ];

  const {
    useQueryAction: useShiftQueryAction,
    useMutationAction: useShiftMutationAction,
  } = useShiftCachedService();
  const filterShifts = useShiftQueryAction("filter", {});
  const [destroyShift] = useShiftMutationAction("destroy");

  const addShift = () => setAction("create");
  const showShifts = () => setAction("show");
  const editShift = (shift: Shift) => {
    setSelectedShift(shift);
    setAction("edit");
  };

  const onCreatedShift = () => {
    toast({
      status: "success",
      duration: 2000,
      title: "Turno criado com sucesso!",
    });
    showShifts();
  };

  const onUpdateShift = () => {
    toast({
      status: "success",
      duration: 2000,
      title: "Turno atualizada com sucesso!",
    });
    showShifts();
  };

  const addButton = (
    <HStack alignSelf="flex-start">
      <Button as={Link} role="a" size="lg" color="white" onClick={addShift}>
        Adicionar
      </Button>
    </HStack>
  );

  const rowActions = (shift: Shift) => {
    return (
      <Box>
        <Button size="sm" mr={2} onClick={() => editShift(shift)}>
          Editar
        </Button>
        <Button colorScheme="red" size="sm" onClick={() => destroyShift(shift)}>
          Arquivar
        </Button>
      </Box>
    );
  };

  const editScreen = (
    <VStack w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={showShifts}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Editar Turno
        </Heading>
      </HStack>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        alignItems="flex-start"
        bg="whiteAlpha.800"
        w="full"
        h="full"
        p={8}
      >
        {selectedShift && (
          <ShiftFormEdit shift={selectedShift} onUpdate={onUpdateShift} />
        )}
      </Flex>
    </VStack>
  );

  const createScreen = (
    <VStack w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={showShifts}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Novo Turno
        </Heading>
      </HStack>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        alignItems="flex-start"
        bg="whiteAlpha.800"
        w="full"
        h="full"
        p={8}
      >
        <ShiftFormCreate onCreated={onCreatedShift} />
      </Flex>
    </VStack>
  );

  return (
    <div>
      {action === "create" ? (
        createScreen
      ) : action === "edit" ? (
        editScreen
      ) : (
        <GenericList
          dataQuery={filterShifts}
          beforeTable={addButton}
          rowActions={rowActions as (data: unknown) => React.ReactNode}
          actionsLabel="Ações"
          columns={columns}
        />
      )}
    </div>
  );
}
