import { VStack, Heading, Flex, Box, Badge, HStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useDebtStore } from "../../../store/debt.store";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { convertCentsToReais, formatReais } from "../../../utils/convert-money";
import { z } from "zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCurrentUserStore } from "../../../store/current-user.store";
import { useForm, Controller } from "react-hook-form";
import {
  debtColorMap,
  debtTextMap,
  debtTypeTextMap,
} from "../../../dto/debt-dto";
import { useQuery } from "@tanstack/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { CompanyDto, CompanySaaSPlan } from "../../../dto/company-dto";
import { DateRange, DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { ArrowFatLinesRight, Info } from "@phosphor-icons/react";
import { Pagination } from "../../../components/pagination";
import { isFranchisor } from "../../../utils/company-verifications";
import { getWithAuth } from "../../../services/basicService";
import { formatCNPJ } from "../../../utils/format-document";
import { DebtType } from "../../../enums/debt-type";

dayjs.extend(utc);

const filtersFormSchema = z.object({
  dateRange: z
    .object({
      to: z.date().optional(),
      from: z.date().optional(),
    })
    .optional(),
  companyId: z.string().optional(),
  type: z.string().optional(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

export function DebtsEnterprise() {
  const totalOpenAmount = useDebtStore((state) => state.totalOpenAmount);
  const totalScheduledAmount = useDebtStore(
    (state) => state.totalScheduledAmount
  );
  const totalPages = useDebtStore((state) => state.totalPages);
  const me = useCurrentUserStore((state) => state.user);

  const getOpenedOrScheduledDebts = useDebtStore(
    (state) => state.getOpenedOrScheduledDebts
  );
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
  });
  const companyId = searchParams.get("companyId");
  const page = searchParams.get("page");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const type = searchParams.get("type");

  const { data: saasData } = useQuery({
    queryKey: ["saas-data"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/transaction/company/saas");

      return response?.data;
    },
  });

  const { data: debts, error } = useQuery({
    queryKey: [
      "debts-by-company",
      startDate,
      endDate,
      page,
      me,
      companyId,
      type,
    ],
    queryFn: async () =>
      await useDebtStore.getState().getDebtsByCompanyId({
        filters: {
          startDate: startDate || undefined,
          endDate: endDate || undefined,
          companyFranchiseeId: companyId || undefined,
          type: (type as DebtType) || undefined,
        },
        page: page ? +page : 1,
        limit: 10,
        id: me?.companyId as number,
      }),
  });
  if (error) {
    toast({
      status: "error",
      title: "Erro ao carregar informação",
      description: `Erro: ${error}`,
    });
  }
  async function onSubmit(data: filtersFormInput) {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("endDate");
      prev.delete("startDate");
      prev.delete("companyId");
      prev.delete("type");

      if (data?.dateRange?.from) {
        prev.set(
          "startDate",
          dayjs(data?.dateRange?.from).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.dateRange?.to) {
        prev.set(
          "endDate",
          dayjs(data?.dateRange?.to).utc().format("YYYY-MM-DD")
        );
      }
      if (data?.companyId) {
        prev.set("companyId", data?.companyId.toString());
      }
      if (data.type) {
        prev.set("type", data.type);
      }
      return prev;
    });
  }
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<filtersFormInput>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      companyId: companyId !== null ? String(companyId) : undefined,
      dateRange: {
        from: startDate ? dayjs(startDate).toDate() : undefined,
        to: endDate ? dayjs(endDate).toDate() : undefined,
      },
      type: type || undefined,
    },
  });
  const { data: companyDebt } = useQuery({
    queryKey: ["franchisees-companies"],
    queryFn: async () => {
      const response = await getWithAuth(
        `/api/v1/companies/franchisees/${me?.companyId as number}`
      );
      return response?.data.franchisees as { name: string; id: string }[];
    },
    enabled: !!me?.companyId,
  });
  const handlePageChange = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", page.toString());
      return prev;
    });
  };

  getOpenedOrScheduledDebts();

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Flex
        flexDir="row"
        alignItems="center"
        w="full"
        justifyContent="space-between"
      >
        <Heading fontWeight="extrabold">Débitos lançados</Heading>
        <Flex gap={24} alignSelf="flex-end">
          <Flex direction={"column"} gap={4}>
            {saasData?.saasConfiguration?.participatesInSaaS && (
              <>
                <Heading
                  fontWeight="semibold"
                  fontSize={"3xl"}
                  display="inline-flex"
                  alignItems="center"
                >
                  Serviço de transações{" "}
                  {`- período ${dayjs(saasData.currentPeriodDates.start).add(6, "hour").format("DD/MM")} à ${dayjs(saasData.currentPeriodDates.end).format("DD/MM")}`}
                  <Tooltip
                    label="O valor exibido reflete as transações do período atual. Com início e fim baseado pela data de corte"
                    aria-label="O valor exibido reflete as transações do período atual. Com início e fim baseado pela data de corte"
                    placement="bottom"
                  >
                    <Info size={20} style={{ marginLeft: "5px" }} />
                  </Tooltip>
                </Heading>

                <Flex gap={4} alignSelf="flex-end">
                  {saasData?.saasConfiguration?.plan ===
                    CompanySaaSPlan.PACKAGE && (
                    <>
                      <Box
                        bg="whiteAlpha.800"
                        border="1px"
                        borderColor="gray.300"
                        borderRadius={8}
                        p={4}
                        w="full"
                        h="full"
                        shadow="base"
                      >
                        <Stat w="max-content">
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            gap={2}
                          >
                            <StatLabel fontSize="xl">
                              Valor por transação excedente:
                            </StatLabel>
                            <StatNumber fontSize="3xl" color="green.500">
                              {saasData?.saasConfiguration
                                ?.overLimitTransactionValue
                                ? formatReais(
                                    saasData?.saasConfiguration
                                      ?.overLimitTransactionValue
                                  )
                                : "-"}
                            </StatNumber>
                          </Flex>
                        </Stat>
                      </Box>
                      <Box
                        bg="whiteAlpha.800"
                        border="1px"
                        borderColor="gray.300"
                        borderRadius={8}
                        p={4}
                        w="full"
                        h="full"
                        shadow="base"
                      >
                        <Stat w="max-content">
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            gap={2}
                          >
                            <StatLabel fontSize="xl">
                              Valor contratado:
                            </StatLabel>
                            <StatNumber fontSize="3xl" color="green.500">
                              {saasData?.saasConfiguration?.SaaSValue
                                ? formatReais(
                                    saasData?.saasConfiguration?.SaaSValue
                                  )
                                : "-"}
                            </StatNumber>
                          </Flex>
                        </Stat>
                      </Box>
                      <Box
                        bg="whiteAlpha.800"
                        border="1px"
                        borderColor="gray.300"
                        borderRadius={8}
                        p={4}
                        w="full"
                        h="full"
                        shadow="base"
                      >
                        <Stat w="max-content">
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            gap={2}
                          >
                            <StatLabel fontSize="xl">
                              Fora do contratado:
                            </StatLabel>
                            <StatNumber fontSize="3xl" color="green.500">
                              {saasData
                                ? saasData?.numberOfCurrentPeriodTransactionsOverLimit
                                : 0}
                            </StatNumber>
                          </Flex>
                        </Stat>
                      </Box>
                      <Box
                        bg="whiteAlpha.800"
                        border="1px"
                        borderColor="gray.300"
                        borderRadius={8}
                        p={4}
                        w="full"
                        h="full"
                        shadow="base"
                      >
                        <Stat w="max-content">
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            gap={2}
                          >
                            <StatLabel fontSize="xl">Restantes: </StatLabel>
                            <StatNumber fontSize="3xl" color="green.500">
                              {saasData
                                ? saasData?.saasConfiguration
                                    ?.numberOfTransactions -
                                  saasData?.currentPeriodSuccessTransactionsCount
                                : 0}
                            </StatNumber>
                          </Flex>
                        </Stat>
                      </Box>
                    </>
                  )}

                  {saasData?.saasConfiguration?.plan ===
                    CompanySaaSPlan.BY_TRANSACTION && (
                    <>
                      <Box
                        bg="whiteAlpha.800"
                        border="1px"
                        borderColor="gray.300"
                        borderRadius={8}
                        p={4}
                        w="full"
                        h="full"
                        shadow="base"
                      >
                        <Stat w="max-content">
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            gap={2}
                          >
                            <StatLabel fontSize="xl">
                              Valor por transação avulsa:
                            </StatLabel>
                            <StatNumber fontSize="3xl" color="green.500">
                              {saasData?.saasConfiguration?.transactionValue
                                ? formatReais(
                                    saasData?.saasConfiguration
                                      ?.transactionValue
                                  )
                                : "-"}
                            </StatNumber>
                          </Flex>
                        </Stat>
                      </Box>
                      <Box
                        bg="whiteAlpha.800"
                        border="1px"
                        borderColor="gray.300"
                        borderRadius={8}
                        p={4}
                        w="full"
                        h="full"
                        shadow="base"
                      >
                        <Stat w="max-content">
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            gap={2}
                          >
                            <StatLabel fontSize="xl">
                              Número de transações:{" "}
                            </StatLabel>
                            <StatNumber fontSize="3xl" color="green.500">
                              {saasData
                                ? saasData?.currentPeriodSuccessTransactionsCount
                                : 0}
                            </StatNumber>
                          </Flex>
                        </Stat>
                      </Box>
                      <Box
                        bg="whiteAlpha.800"
                        border="1px"
                        borderColor="gray.300"
                        borderRadius={8}
                        p={4}
                        w="full"
                        h="full"
                        shadow="base"
                      >
                        <Stat w="max-content">
                          <Flex
                            flexDir="column"
                            justifyContent="center"
                            gap={2}
                          >
                            <StatLabel fontSize="xl">Valor total:</StatLabel>
                            <StatNumber fontSize="3xl" color="green.500">
                              {saasData?.saasConfiguration?.transactionValue
                                ? formatReais(
                                    saasData?.saasConfiguration
                                      ?.transactionValue *
                                      saasData?.currentPeriodSuccessTransactionsCount
                                  )
                                : "-"}
                            </StatNumber>
                          </Flex>
                        </Stat>
                      </Box>
                    </>
                  )}
                </Flex>
              </>
            )}
          </Flex>

          <Flex direction={"column"} gap={4}>
            <Heading fontWeight="semibold" fontSize={"3xl"}>
              Débitos
            </Heading>

            <Flex gap={4} alignSelf="flex-end">
              <Box
                bg="whiteAlpha.800"
                border="1px"
                borderColor="gray.300"
                borderRadius={8}
                p={4}
                w="full"
                h="full"
                shadow="base"
              >
                <Stat w="max-content">
                  <Flex flexDir="column" justifyContent="center" gap={2}>
                    <StatLabel fontSize="xl">Abertos: </StatLabel>
                    <StatNumber fontSize="3xl" color="green.500">
                      {convertCentsToReais(totalOpenAmount)}
                    </StatNumber>
                  </Flex>
                </Stat>
              </Box>
              <Box
                bg="whiteAlpha.800"
                border="1px"
                borderColor="gray.300"
                borderRadius={8}
                p={4}
                w="full"
                h="full"
                shadow="base"
              >
                <Stat w="max-content">
                  <Flex flexDir="column" justifyContent="center" gap={2}>
                    <StatLabel fontSize="xl">Agendados: </StatLabel>
                    <StatNumber fontSize="3xl" color="green.500">
                      {convertCentsToReais(totalScheduledAmount)}
                    </StatNumber>
                  </Flex>
                </Stat>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <TableContainer
        w="full"
        p={8}
        gap={4}
        display="flex"
        flexDir="column"
        border="1px"
        borderRadius={8}
        bg={"whiteAlpha.800"}
        borderColor={"gray.300"}
        shadow="base"
      >
        <Flex
          as="form"
          flexDir="column"
          w="full"
          gap={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex
            justifyContent="space-between"
            flexDirection={{ base: "column", md: "row" }}
            gap={8}
          >
            <Flex gap={8} alignItems="end">
              {isFranchisor(me?.company as CompanyDto) && (
                <FormControl>
                  <FormLabel as="legend">Franqueada</FormLabel>
                  <Select
                    variant="filled"
                    {...register("companyId")}
                    size="md"
                    w="full"
                    focusBorderColor="green.500"
                    placeholder="Todas"
                  >
                    {companyDebt?.map((company) => (
                      <option value={company.id} key={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl as="fieldset" w="max-content">
                <FormLabel as="legend">Data do débito</FormLabel>
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button>
                      {!startDate
                        ? "Escolha a sua data"
                        : !endDate
                          ? startDate
                          : `${startDate.replaceAll(
                              "-",
                              "/"
                            )} a ${endDate.replaceAll("-", "/")}`}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent bg="white" p={2} w="max-content">
                      <PopoverBody>
                        <Controller
                          control={control}
                          name="dateRange"
                          render={({ field }) => (
                            <DayPicker
                              id="dateFilter"
                              mode="range"
                              initialFocus={true}
                              selected={field.value as DateRange}
                              onSelect={field.onChange}
                              locale={ptBR}
                              modifiersClassNames={{
                                disabled: "btn-disabled",
                                selected: "custom-selected",
                                outside: "outside-day",
                              }}
                            />
                          )}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </FormControl>

              <FormControl as="fieldset">
                <FormLabel as="legend">Tipo</FormLabel>
                <Select
                  {...register("type")}
                  placeholder="Selecione o tipo"
                  variant="filled"
                >
                  {Object.values(DebtType).map((value) => {
                    return (
                      <option key={value} value={value}>
                        {debtTypeTextMap[value]}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>

              <Button
                type="submit"
                w="max-content"
                _hover={{
                  bg: "green.700",
                }}
                px={8}
                size="md"
                alignSelf="flex-end"
                isDisabled={!isDirty}
              >
                Filtrar
              </Button>

              <Button
                w="max-content"
                px={8}
                size="md"
                alignSelf="flex-end"
                isDisabled={!startDate && !companyId}
                onClick={() => {
                  setSearchParams((prev) => {
                    prev.delete("startDate");
                    prev.delete("endDate");
                    prev.delete("companyId");
                    prev.delete("type");
                    return prev;
                  });
                  reset();
                }}
              >
                Limpar Filtros
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Table>
          <Thead>
            <Tr>
              {isFranchisor(me?.company as CompanyDto) && (
                <>
                  <Th textAlign="center">Empresa</Th>
                  <Th textAlign="center">CNPJ</Th>
                </>
              )}
              <Th textAlign="center">Valor</Th>
              <Th textAlign="center">Data de débito</Th>
              <Th textAlign="center">Data de quitação</Th>
              <Th textAlign="center">Status</Th>
              <Th textAlign="center">Tipo</Th>
              <Th textAlign="center">Ver detalhes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {debts?.map((debt) => (
              <Tr key={debt.id}>
                {isFranchisor(me?.company as CompanyDto) && (
                  <>
                    <Td textAlign="center">{debt.company.name}</Td>
                    <Td textAlign="center">
                      {debt.company.cnpj ? formatCNPJ(debt.company.cnpj) : "-"}
                    </Td>
                  </>
                )}
                <Td textAlign="center">{convertCentsToReais(debt.amount)}</Td>
                <Td textAlign="center">
                  {dayjs(debt.debt_date).format("DD/MM/YYYY")}
                </Td>
                <Td textAlign="center">
                  {debt.quit_date
                    ? dayjs(debt.quit_date).format("DD/MM/YYYY")
                    : "-"}
                </Td>
                <Td textAlign="center">
                  <Badge
                    colorScheme={debtColorMap[debt.status]}
                    variant="solid"
                    px={4}
                  >
                    {debtTextMap[debt.status]}
                  </Badge>
                </Td>
                <Td textAlign="center">
                  {debt.type && debtTypeTextMap[debt.type]}
                </Td>
                <Td textAlign="center">
                  <IconButton
                    aria-label="campo para ir a tela de detalhes da folha pendente"
                    role="a"
                    size="md"
                    bg="green.500"
                    icon={<ArrowFatLinesRight />}
                    color="white"
                    w="50%"
                    _hover={{
                      bg: "green.600",
                    }}
                    onClick={() => {
                      navigate(`details/${debt.id}`, {
                        relative: "path",
                      });
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={page ? Number(page) : 1}
            setPage={
              handlePageChange as React.Dispatch<React.SetStateAction<number>>
            }
            totalPages={totalPages || 1}
          />
        </HStack>
      </TableContainer>
    </VStack>
  );
}
