import { Box, Flex, VStack } from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UseStepsReturn,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { getWithAuth } from "../../../services/basicService";
import { useQuery } from "@tanstack/react-query";
import { LoadingLogo } from "../../../components/loading";

type Props = UseStepsReturn;
export function GenerateCnab(props: Props) {
  const [searchParams] = useSearchParams();
  const start = searchParams.get("start");
  const end = searchParams.get("end");
  const docType = searchParams.get("type");

  const { data: cnabInfo, isLoading } = useQuery({
    queryKey: ["cnab-info", start, end, docType],
    queryFn: async () => {
      const response = await getWithAuth(
        `/api/v1/cnab?start=${start}&end=${end}&type=${docType}`
      );
      return response?.data;
    },
  });

  return (
    <VStack
      as="form"
      w="full"
      gap={8}
      id="form"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Flex w="full" gap={8}>
        <FormControl
          w="full"
          display="flex"
          flexDir="column"
          alignItems="center"
        >
          {isLoading ? (
            <LoadingLogo />
          ) : (
            <>
              {cnabInfo && (
                <Box p={6} whiteSpace="pre-wrap">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Informações</Th>
                        <Th>Valor</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(cnabInfo).map(([key, value]) => (
                        <Tr key={key}>
                          <Td>{key}</Td>
                          <Td>{JSON.stringify(value)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              )}
            </>
          )}
        </FormControl>
        <Button
          type="submit"
          form="form"
          w="max-content"
          _hover={{
            bg: "green.700",
          }}
          px={8}
          size="md"
          alignSelf="flex-end"
          onClick={() => props.goToNext()}
        >
          Continuar
        </Button>
      </Flex>
    </VStack>
  );
}
