import { ReactNode, useEffect } from "react";
import {
  Box,
  Heading,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  UseStepsReturn,
  VStack,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import { CreatePayroll } from "./create-payroll";
import { PaymentSendSheet } from "./payments-send-sheet";
import { CreateReprocessingPayroll } from "./create-reprocessing-payroll";
import { EditPaymentsToReprocess } from "./edit-payments-to-reprocess";
import { SummaryTransactionsConfirmation } from "./summary-transactions-confirmation";
import { SummaryScheduledTransactionsConfirmation } from "./summary-scheduled-confirmation";
import { DoneCreatePayrollTransactions } from "./done";
import { useCreatePayroll } from "../../../../store/payroll.store";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { RoleEnum } from "../../../../enums/roles-enum";
import { AddConciliatedPayrollTransactions } from "./add-conciliated-payroll-transactions";

type stepsType = {
  title: string;
  description: string;
  element: (props: UseStepsReturn) => ReactNode;
};

const stepsAdmin = [
  {
    title: "Criar",
    description: "Criar pagamento",
    element: (props: UseStepsReturn) => <CreatePayroll {...props} />,
  },
  {
    title: "Transações",
    description: "Adicionar transações",
    element: (props: UseStepsReturn) => <PaymentSendSheet {...props} />,
  },
  {
    title: "Confirmação",
    description: "Confirmar pagamento",
    element: (props: UseStepsReturn) => (
      <SummaryTransactionsConfirmation {...props} />
    ),
  },
  {
    title: "Conclusão",
    description: "Pagamento em processamento!",
    element: () => <DoneCreatePayrollTransactions />,
  },
];

const stepsScheduled = [
  {
    title: "Criar",
    description: "Criar pagamento",
    element: (props: UseStepsReturn) => <CreatePayroll {...props} />,
  },
  {
    title: "Confirmação",
    description: "Confirmar pagamento",
    element: (props: UseStepsReturn) => (
      <SummaryScheduledTransactionsConfirmation {...props} />
    ),
  },
  {
    title: "Conclusão",
    description: "Pagamento em processamento!",
    element: () => <DoneCreatePayrollTransactions />,
  },
];

const stepsConciliated = [
  {
    title: "Criar",
    description: "Criar pagamento",
    element: (props: UseStepsReturn) => <CreatePayroll {...props} />,
  },
  {
    title: "Transações",
    description: "Adicionar transações",
    element: (props: UseStepsReturn) => (
      <AddConciliatedPayrollTransactions {...props} />
    ),
  },
  {
    title: "Confirmação",
    description: "Confirmar pagamento",
    element: (props: UseStepsReturn) => (
      <SummaryTransactionsConfirmation {...props} />
    ),
  },
  {
    title: "Conclusão",
    description: "Pagamento em processamento!",
    element: () => <DoneCreatePayrollTransactions />,
  },
];

const stepsReprocessScheduledPayroll = [
  {
    title: "Criar",
    description: "Nomear Reprocessamento",
    element: (props: UseStepsReturn) => (
      <CreateReprocessingPayroll {...props} />
    ),
  },
  {
    title: "Transações",
    description: "Editar transações",
    element: (props: UseStepsReturn) => <EditPaymentsToReprocess {...props} />,
  },
  {
    title: "Confirmação",
    description: "Confirmar pagamento",
    element: (props: UseStepsReturn) => (
      <SummaryTransactionsConfirmation {...props} />
    ),
  },
  {
    title: "Conclusão",
    description: "Pagamento em processamento!",
    element: () => <DoneCreatePayrollTransactions />,
  },
];

const stepsDefault = [
  {
    title: "Criar",
    description: "Criar pagamento",
    element: (props: UseStepsReturn) => <CreatePayroll {...props} />,
  },
  {
    title: "Transações",
    description: "Adicionar transações",
    element: (props: UseStepsReturn) => <PaymentSendSheet {...props} />,
  },
];

export function CreatePayrollProcess() {
  const user = useCurrentUserStore((state) => state.user);
  const hasData = useCreatePayroll((state) => state.transactionValidated);
  const isDraftPayroll = useCreatePayroll((state) => state.isDraftPayroll);
  const isReprocessingScheduledPayroll = useCreatePayroll(
    (state) => state.isReprocessingScheduledPayroll
  );

  const payroll = useCreatePayroll((state) => state.payroll);
  const isScheduledPayroll = useCreatePayroll(
    (state) => state.isScheduledPayroll
  );

  const isConciliated = payroll?.data?.type === "conciliated";
  //  const isInstantPayroll = payroll?.data?.type === "instant";

  const toast = useToast();

  const isAdmin = user?.roles?.some(
    (role) => role.name === RoleEnum.ADMIN_COMPANY
  );

  let steps: stepsType[] = stepsDefault;

  if (isAdmin) {
    steps = stepsAdmin;
  }

  if (isConciliated) {
    steps = stepsConciliated;
  }

  if (isScheduledPayroll) {
    steps = stepsScheduled;
  }

  if (isReprocessingScheduledPayroll) {
    steps = stepsReprocessScheduledPayroll;
  }

  const stepsReturn = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    if (
      (hasData?.hasError || isDraftPayroll || isConciliated) &&
      stepsReturn.activeStep <= 1
    ) {
      stepsReturn.setActiveStep(1);
    }

    if (isScheduledPayroll && !isConciliated) {
      if (!isAdmin) {
        toast({
          status: "error",
          description:
            "Você não está autorizado a revisar e confirmar uma folha agendada",
        });
        stepsReturn.setActiveStep(0);
        return;
      }

      if (stepsReturn.activeStep === 0 && isAdmin) {
        stepsReturn.setActiveStep(1);
      }
    }
  }, [
    hasData,
    stepsReturn,
    isDraftPayroll,
    isScheduledPayroll,
    isConciliated,
    isReprocessingScheduledPayroll,
    user?.roles,
    toast,
    isAdmin,
  ]);

  return (
    <VStack gap={8} w="full" flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Folha de Pagamento
      </Heading>

      <Stepper
        w="full"
        size={["sm", "lg"]}
        index={stepsReturn.activeStep}
        colorScheme="green"
        display={["none", "flex"]}
        flexWrap="wrap"
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box>
              <StepTitle>{step.title}</StepTitle>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Text fontSize="xl">
        <strong>{`Passo ${stepsReturn.activeStep + 1}`}:</strong>
        {` ${steps[stepsReturn.activeStep]?.description}`}
      </Text>

      <VStack
        p={8}
        w="full"
        alignItems="center"
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Heading>{steps[stepsReturn.activeStep]?.description}</Heading>
        {steps[stepsReturn.activeStep]?.element(stepsReturn)}
      </VStack>
    </VStack>
  );
}
