import { VStack, Heading } from "@chakra-ui/layout";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { SendToSomeTab } from "./send-to-some-tab";
import { SendToEveryoneTab } from "./send-to-everyone-tab";

export function AppMobile() {
  return (
    <VStack alignItems="flex-start" w="full" gap={8}>
      <Heading fontWeight="extrabold" textAlign="left">
        Envio de mensagem
      </Heading>
      <Tabs w="full" colorScheme="green">
        <TabList>
          <Tab>Enviar para todos os usuários</Tab>
          <Tab>Enviar para alguns CPF&apos;s</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <SendToEveryoneTab />
          </TabPanel>
          <TabPanel>
            <SendToSomeTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
