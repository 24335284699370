import { z } from "zod";
import { usePlazaCachedService } from "../../../../../services/api/plaza";
import {
  FormField,
  GenericForm,
} from "../../../../../components/forms/generic-form";
import { useSubplazaCachedService } from "../../../../../services/api/subplazas";
import {
  ISubplazaSettingDto,
  Subplaza,
  textSchemeMap as subplazaAcceptanceMap,
} from "../../../../../dto/subplaza-dto";
import {
  IPlazaSetting,
  Plaza,
  textSchemeMap as plazaAcceptanceMap,
} from "../../../../../dto/plaza-dto";
import { MutateFunction } from "../../../../../services/api/use-cached-service";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { acceptanceEnum } from "../../../../../enums/acceptance.enum";
import {
  convertTimeToTimeWithTimeZone,
  convertTimeWithTimeZoneToCurrentTimeZone,
} from "../../../../../utils/date";

const getAcceptanceType = (plaza: Plaza) => {
  const setting = plaza?.setting as IPlazaSetting;
  return plazaAcceptanceMap[
    (setting?.acceptance || acceptanceEnum.MANUALLY) as acceptanceEnum
  ];
};

const buildFormFields = (plazas: Plaza[] | undefined): FormField[] => [
  {
    label: "Plaza",
    name: "plazaId",
    type: "select",
    options:
      plazas?.map((plaza) => {
        const { name, id: value } = plaza;
        const label = `${getAcceptanceType(plaza)} - ${name}`;
        return { label, value };
      }) || [],
  },
  {
    label: "Nome",
    name: "name",
    type: "text",
  },
  {
    label: "Aceite",
    name: "acceptance",
    type: "select",
    options: [
      {
        value: acceptanceEnum.DEFAULT,
        label: subplazaAcceptanceMap[acceptanceEnum.DEFAULT],
      },
      {
        value: acceptanceEnum.AUTOMATIC,
        label: subplazaAcceptanceMap[acceptanceEnum.AUTOMATIC],
      },
      {
        value: acceptanceEnum.MANUALLY,
        label: subplazaAcceptanceMap[acceptanceEnum.MANUALLY],
      },
    ],
  },
  {
    label: "Publicar agendamento antes (dias)",
    name: "publishScaleDaysBefore",
    type: "number",
  },
  {
    label: "Publicar agendamento às",
    name: "publishScaleAt",
    type: "time",
  },
];

const formSchema = z.object({
  id: z.number(),
  name: z.string(),
  plazaId: z.number(),
  acceptance: z.string(),
  publishScaleDaysBefore: z.string().optional(),
  publishScaleAt: z.string().optional(),
});

type formType = Subplaza & {
  acceptance: string;
  publishScaleDaysBefore?: number;
  publishScaleAt?: string;
};

function buildHandleSubmit(updateSubplaza: MutateFunction) {
  return (formData: formType) => {
    const { acceptance, publishScaleDaysBefore, publishScaleAt } = formData;
    formData.setting = {
      acceptance,
    } as ISubplazaSettingDto;

    if (publishScaleDaysBefore && publishScaleAt) {
      formData.setting.publishScaleBefore = {
        daysBefore: publishScaleDaysBefore,
        at: convertTimeToTimeWithTimeZone(publishScaleAt),
      };
    }

    updateSubplaza({ id: formData.id, data: formData });
  };
}

interface FormProps {
  subplaza: Subplaza;
  onUpdate: () => void;
}

export const SubplazaFormEdit: React.FC<FormProps> = ({
  subplaza,
  onUpdate,
}) => {
  /** LOAD PLAZA */
  const { useQueryAction: usePlazaQueryAction } = usePlazaCachedService();
  const plazaQuery = usePlazaQueryAction("filter", {});
  const plazas = plazaQuery.data;

  const formFields = buildFormFields(plazas);

  const formData = subplaza as formType;
  formData.acceptance = subplaza.setting?.acceptance as string;
  formData.publishScaleDaysBefore =
    subplaza.setting?.publishScaleBefore?.daysBefore;
  if (subplaza.setting?.publishScaleBefore?.at) {
    const convertedTime = convertTimeWithTimeZoneToCurrentTimeZone(
      subplaza.setting?.publishScaleBefore?.at
    ).substring(0, 5);
    formData.publishScaleAt = convertedTime;
  }

  const { useMutationAction: useSubplazaMutationAction } =
    useSubplazaCachedService();
  const [updateSubplaza, watchers] = useSubplazaMutationAction("update");

  const toast = useToast();

  useEffect(() => {
    const { status, error } = watchers;
    if (status) {
      if (status === "success") {
        onUpdate();
      }
      if (status === "error") {
        toast({
          status: "error",
          duration: 3000,
          title: "Falha ao criar sub-praça",
          description: `Falha ao atualizar sub-praça: ${error}`,
        });
      }
    }
  }, [onUpdate, toast, watchers, watchers.status]);

  const handleSubmit = buildHandleSubmit(updateSubplaza);

  return (
    <GenericForm
      fields={formFields}
      formSchema={formSchema}
      initialData={formData}
      actionLabel="Salvar"
      onSubmit={handleSubmit as (data: unknown) => React.ReactNode}
    />
  );
};
