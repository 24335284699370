import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { CheckCircle } from "@phosphor-icons/react";
import { useMarkBalanceAsTransferred } from "../../../../../hooks/balance-migration/use-mark-balance-as-transferred";
import { useRef } from "react";
import { PartialUserExternal } from "../../../../../types/get-user-with-migrated-balances.type";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import { formatCPF } from "../../../../../utils/format-document";

type IProps = {
  userExternal: PartialUserExternal;
  refetch: () => void;
};

export function MarkBalanceAsTransferred({ userExternal, refetch }: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: `alert-${userExternal.id}`,
  });
  const { execute, loading } = useMarkBalanceAsTransferred({
    closeAlert: onClose,
    refetch,
  });
  const cancelRef = useRef(null);

  return (
    <>
      <Button
        bg="green.600"
        w="max-content"
        color="whiteAlpha.900"
        _hover={{
          bg: "green.700",
        }}
        px={4}
        size="md"
        alignSelf="flex-end"
        isDisabled={loading}
        isLoading={loading}
        onClick={onOpen}
      >
        <CheckCircle size={26} />
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Marcar saldo como transferido
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg">
              Você tem certeza que deseja marcar o saldo relacionado ao
              documento {formatCPF(userExternal.document)} com o valor de&ensp;
              {convertCentsToReais(userExternal.balance)} como transferido?
            </AlertDialogBody>

            <AlertDialogFooter>
              {loading ? (
                <Spinner size="md" />
              ) : (
                <>
                  <Button colorScheme="red" ref={cancelRef} onClick={onClose}>
                    Não
                  </Button>
                  <Button
                    colorScheme="green"
                    onClick={() => execute(userExternal.id)}
                    ml={3}
                  >
                    Sim
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
