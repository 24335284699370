import { useMutation } from "@tanstack/react-query";
import { ParseResult, unparse } from "papaparse";
import { createContext, useState } from "react";
import { usePapaParse } from "react-papaparse";
import { postWithAuth } from "../../../../services/basicService";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";

interface DeliveryPerson {
  deliverymanExternalId: string;
  name: string;
  document: string;
  companyId: string;
  companyName: string;
  valid?: boolean;
  errors: string[];
}
interface ISyncCsvContext {
  csvData: DeliveryPerson[];
  file: File | null;
  dragActive: boolean;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  handleSubmitCsv: () => void;
  handleCleanFile: () => void;
  handleValidateCsv: () => void;
  validateLoading: boolean;
  isValidated: boolean;
  handleDownloadErros: () => void;
  handleCreateDeliverymen: () => void;
  createLoading: boolean;
}

export const SyncCsvContext = createContext<ISyncCsvContext>(
  {} as ISyncCsvContext
);

export function SyncCsvProvider({ children }: { children: React.ReactNode }) {
  const { readString } = usePapaParse();
  const toast = useToast();
  const [csvData, setCsvData] = useState<DeliveryPerson[]>([]);
  const [isValidated, setIsValidated] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleCleanFile = () => {
    setCsvData([]);
    setIsValidated(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  const handleSubmitCsv = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target?.result?.toString();
        if (text) {
          readString<DeliveryPerson>(text, {
            complete: (result: ParseResult<DeliveryPerson>) => {
              setCsvData(result.data);
              console.log("CSV Data:", result.data);
            },
            header: true,

            transformHeader: (header) => {
              switch (header) {
                case "id_pessoa_entregadora":
                  return "deliverymanExternalId";
                case "nome":
                  return "name";
                case "cpf":
                  return "document";
                case "empresa_id":
                  return "companyId";
                case "empresa_nome":
                  return "companyName";
                default:
                  return header;
              }
            },
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const { mutate: validateCsv, isLoading: validateLoading } = useMutation({
    mutationKey: ["validate-csv"],
    mutationFn: async () => {
      const body = {
        data: csvData.map((row) => ({
          deliverymanExternalId: row.deliverymanExternalId,
          document: row.document,
        })),
      };

      const response = await postWithAuth(
        "api/v1/pending_deliverymen/validate",
        body
      );

      const updatedCsvData = csvData.map((row) => {
        const validationResult = response?.data.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (result: any) => result.document === row.document
        );

        if (validationResult) {
          return {
            ...row,
            valid: validationResult.valid,
            errors: validationResult.errors || [],
          };
        }
        return row;
      });

      setCsvData(updatedCsvData);
      setIsValidated(true);
    },
  });

  const handleValidateCsv = () => {
    validateCsv();
  };

  const handleDownloadErros = () => {
    const csvDataWithErrors = csvData.filter((row) => row.errors.length > 0);

    if (csvDataWithErrors.length > 0) {
      const csvString = unparse(csvDataWithErrors);

      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "erros.csv";
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      alert("Não há erros para baixar.");
    }
  };

  const { mutate: createDeliverymen, isLoading: createLoading } = useMutation({
    mutationKey: ["create-deliverymen"],
    mutationFn: async () => {
      const body = {
        data: csvData
          .filter((row) => row.valid)
          .map((row) => ({
            idEntregador: row.deliverymanExternalId,
            nameIfood: row.name || "Sem nome",
            document: row.document,
            companyId: Number(row.companyId.replace(",", "")),
            status: "new",
          })),
      };

      const response = await postWithAuth("api/v1/deliveryMan/many", body);

      if (response?.status === 201) {
        toast({
          status: "success",
          title: "Entregador criado com sucesso!",
        });
      } else {
        toast({
          status: "error",
          title: "Erro ao criar entregador!",
        });
      }
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        toast({
          status: "error",
          title: "Erro ao criar entregador!",
          description: error.response?.data.message,
        });
      }
    },
  });

  const handleCreateDeliverymen = () => {
    createDeliverymen();
  };

  const value = {
    csvData,
    setCsvData,
    file,
    setFile,
    dragActive,
    setDragActive,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    handleFileChange,
    handleSubmitCsv,
    handleCleanFile,
    handleValidateCsv,
    validateLoading,
    isValidated,
    handleDownloadErros,
    handleCreateDeliverymen,
    createLoading,
  };

  return (
    <SyncCsvContext.Provider value={value}>{children}</SyncCsvContext.Provider>
  );
}
