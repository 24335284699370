import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { useGetBalanceMigrationUsers } from "../../../../../hooks/balance-migration/get-balance-migration-users";
import { Pagination } from "../../../../../components/pagination";
import { formatCPF } from "../../../../../utils/format-document";
import { convertCentsToReais } from "../../../../../utils/convert-money";
import { LoadingLogo } from "../../../../../components/loading";
import dayjs from "dayjs";
import { useExportCsv } from "../../../../../hooks/adiantamento/use-export-csv";
import { MarkBalanceAsTransferred } from "./mark-balance-as-transferred";

export function ListPending() {
  const {
    data,
    isLoading,
    page,
    setPage,
    register,
    handleSubmit,
    refetch,
    getQueryParams,
  } = useGetBalanceMigrationUsers("/api/v1/userExternal/pending");
  const { exportCsv, loading: loadingCsv } = useExportCsv();

  const { colorMode } = useColorMode();

  return (
    <VStack gap={8} w="full" alignItems="flex-start">
      <Flex gap={8} alignItems="flex-end">
        <Box
          bg="whiteAlpha.800"
          border="1px"
          borderColor="gray.300"
          borderRadius={8}
          p={4}
          w="max-content"
          h="full"
          shadow="base"
          justifyContent="flex-end"
        >
          <Stat>
            <StatLabel fontSize="xl">Total de registros:</StatLabel>
            <StatNumber fontSize="3xl" color="green.500">
              {data?.totalRecords}
            </StatNumber>
          </Stat>
        </Box>
        <Flex alignItems="center" gap={15}>
          <Button
            isLoading={loadingCsv}
            loadingText="Exportando"
            disabled={loadingCsv}
            role="a"
            size="lg"
            color="white"
            onClick={() =>
              exportCsv(
                "/api/v1/userExternal/pending/csv",
                `saldos_pendetes_${dayjs().format("YYYY-MM-DD")}`,
                getQueryParams()
              )
            }
          >
            Exportar CSV
          </Button>
          <Tooltip
            hasArrow
            placement="top"
            label="Os dados exportados serão baseados no filtro dessa página"
            fontSize="md"
          >
            <Icon fontSize={20} color="black" />
          </Tooltip>
        </Flex>
      </Flex>
      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={8}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          gap={4}
          id="form"
          w="full"
          onSubmit={handleSubmit(() => refetch())}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            w="full"
            justifyContent="center"
            gap={8}
          >
            <FormControl as="fieldset">
              <FormLabel as="legend">CPF</FormLabel>
              <Input
                type="text"
                {...register("search")}
                fontWeight="bold"
                bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                color={colorMode === "dark" ? "gray.300" : "gray.700"}
                placeholder="Busque pelo CPF, ID ou nome do entregador"
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </FormControl>

            <Button
              type="submit"
              form="form"
              bg="green.600"
              w="max-content"
              color="whiteAlpha.900"
              _hover={{
                bg: "green.700",
              }}
              px={8}
              size="md"
              alignSelf="flex-end"
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit(() => refetch())}
            >
              Filtrar
            </Button>
          </Flex>
        </Flex>
        <TableContainer
          w="full"
          flex={1}
          pb={4}
          gap={4}
          display="flex"
          flexDir="column"
          style={{
            containerType: "inline-size",
          }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th flex={1}>Nome</Th>
                <Th textAlign="center">CPF</Th>
                <Th textAlign="center">Empresa</Th>
                <Th isNumeric>Saldo</Th>
                <Th textAlign="center">Marcar como transferido</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading ? (
                data?.result.map((userExternal) => {
                  return (
                    <Tr fontWeight={600} key={userExternal.id}>
                      <Td
                        maxW="200px"
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        textTransform={"capitalize"}
                        textAlign="center"
                      >
                        {userExternal?.name ?? "-"}
                      </Td>
                      <Td textAlign="center">
                        {formatCPF(userExternal.document)}
                      </Td>
                      <Td textAlign="center">
                        {userExternal.companyName
                          ? userExternal.companyName
                          : "-"}
                      </Td>
                      <Td isNumeric>
                        {convertCentsToReais(userExternal.balance)}
                      </Td>
                      <Td textAlign="center">
                        <MarkBalanceAsTransferred
                          userExternal={userExternal}
                          refetch={refetch}
                        />
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <LoadingLogo />
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={page}
            setPage={setPage}
            totalPages={data?.totalPages || 1}
          />
        </HStack>
      </Box>
    </VStack>
  );
}
