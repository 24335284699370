import { useRef, useState } from "react";
import { Flex, HStack, Heading, OrderedList, Text } from "@chakra-ui/layout";
import {
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  CaretLeft,
  CheckFat,
  Download,
  WarningOctagon,
} from "@phosphor-icons/react";
import { DragDrop, IRef } from "../../../components/forms/fileUpload";
import { AxiosError } from "axios";
import { SimplifiedDeliveryman } from "../../../dto/deliveryman-dto";
import { formatCPF } from "../../../utils/format-document";
import { useQueryClient } from "@tanstack/react-query";

export function CreateManyDeliverymans() {
  const [deliverymans, setDeliverymans] = useState<SimplifiedDeliveryman[]>([]);
  const deliverymansRef = useRef<IRef>(null);
  const uuid = useRef<string>(crypto.randomUUID());
  const [errors, setErrors] = useState<string[]>([]);

  const client = useQueryClient();

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const readErrors = useDisclosure({ id: "read-errors" });

  async function handleOnClose() {
    onClose();
    client.refetchQueries(["delivery-mans-data"]);
    navigate("/deliverymans");
  }

  async function handleUpload() {
    const csvFounded = deliverymansRef.current?.hasFiles();

    if (!csvFounded) {
      toast({
        status: "error",
        title: "Planilha faltante",
        description: "É necessário enviar uma planilha primeiro",
      });
      return;
    }

    try {
      const createdDeliverymans = (await deliverymansRef.current?.processUpload(
        {
          sendId: uuid.current,
        }
      )) as SimplifiedDeliveryman[];
      setDeliverymans(createdDeliverymans);
      onOpen();
    } catch (error) {
      const err = error as AxiosError;

      let description =
        "Tente novamente mais tarde ou entre em contato com o suporte";
      if (err.response?.status === 409 || err.response?.status === 422) {
        const errResponse = err.response.data as { message: string[] };

        setErrors(errResponse.message);

        description = 'Verifique os erros no botão "Erros da planilha"';
      }

      toast({
        status: "error",
        title: "Não foi possível cadastrar usuários",
        description,
        duration: 4000,
      });
    }
  }
  return (
    <Flex flexDir="column" alignItems="flex-start" w="full" h="full" gap={8}>
      <Modal
        blockScrollOnMount={true}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastros realizados com sucesso</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="bold" mb="1rem">
              Total de entregadores cadastrados: {deliverymans?.length}
            </Text>
            <List spacing={3}>
              {deliverymans.length > 0 ? (
                deliverymans.map((deliveryman: SimplifiedDeliveryman) => (
                  <ListItem key={deliveryman.id}>
                    <ListIcon as={CheckFat} color="green.600" />
                    CPF: {formatCPF(deliveryman.document)} - Nome IFood:{" "}
                    {deliveryman.nameIfood} - Nome Trampay:{" "}
                    {deliveryman.nameTrampay} - ID: {deliveryman.idEntregador}
                  </ListItem>
                ))
              ) : (
                <></>
              )}
            </List>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleOnClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <HStack gap={8}>
        <Button onClick={() => navigate(-1)}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading>Criar Entregadores</Heading>
      </HStack>
      <Heading size="lg" w="full" textAlign="center">
        Insira seu CSV com as informações dos entregadores
      </Heading>
      <Button
        color="white"
        as="a"
        href="https://trampay-planilha-v2.s3.sa-east-1.amazonaws.com/modelo-criacao-entregadores.csv"
        download
        gap={4}
      >
        <Download weight="bold" size={24} />
        Modelo
      </Button>
      <Flex
        w="full"
        h="full"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="30px"
      >
        {errors.length > 0 && (
          <Button
            size="lg"
            variant="ghost"
            bg="red.400"
            _hover={{
              bgColor: "red.300",
            }}
            onClick={readErrors.onOpen}
            display="flex"
            gap={4}
          >
            <WarningOctagon color="#ffff00" size={24} />
            Erros da planilha
          </Button>
        )}
        <DragDrop
          uploadUrl="/api/v1/uploaded-file/deliveryman"
          maxFiles={1}
          ref={deliverymansRef}
        />
        <Button color="white" onClick={handleUpload}>
          Enviar .CSV
        </Button>
      </Flex>

      <Modal
        isOpen={readErrors.isOpen}
        onClose={readErrors.onClose}
        size="5xl"
        id="read-errors"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent px={4}>
          <ModalHeader>Erros na planilha</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={16}>
            <OrderedList>
              {errors.map((message) => (
                <ListItem key={message} fontSize="medium">
                  {message}
                </ListItem>
              ))}
            </OrderedList>
          </ModalBody>

          <ModalFooter my={4}>
            <Button variant="ghost" mr={3} onClick={readErrors.onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
