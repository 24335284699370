import { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { usePapaParse } from "react-papaparse";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Button,
} from "@chakra-ui/react";
import { LoadingLogo } from "../loading";
import { useShiftCachedService } from "../../services/api/shifts";
import { useSubplazaCachedService } from "../../services/api/subplazas";

import { DataTableWeduka } from "./wedukaFormComponents/dataTableWedukaCSV";
interface CSVData {
  subPraza: string;
  period: string;
  vacancy: {
    date: string;
    vacancies: number;
    surplus: number;
  }[];
  shiftId?: number;
  subPlazaId?: number;
  plaza?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jsonToCSV = (data: any[], options: { delimiter: any; newline?: any }) => {
  const delimiter = options?.delimiter || ",";
  const newline = options?.newline || "\r\n";

  const headers = "turno,subpraca,data,vagas,excedente";
  const csvHeader = `${headers}${newline}`;

  const csvRows = data.map(
    (row: { [s: string]: unknown } | ArrayLike<unknown>) => {
      return Object.values(row)
        .map((value) => {
          if (typeof value === "string") {
            // Adiciona aspas e escapa as aspas internas
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(delimiter);
    }
  );

  return `${csvHeader}${csvRows.join(newline)}`;
};

const decodeSpecialChars = (str: string): string => {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = str;
  return textArea.value;
};

const Component = ({
  content,
  fileName,
  onContentChange,
}: {
  content: string;
  fileName: string;
  onContentChange: (fileName: string, newContent: string) => void;
}) => {
  const { readString } = usePapaParse();
  const [groupedData, setGroupedData] = useState<{ [key: string]: CSVData[] }>(
    {}
  );
  const [loading, setLoading] = useState(false);

  const { useQueryAction: useSubplazaQueryAction } = useSubplazaCachedService();
  const filterSubplazas = useSubplazaQueryAction("filter", {});

  const plazas = filterSubplazas.data;

  const { useQueryAction: useShiftQueryAction } = useShiftCachedService();
  const filterShifts = useShiftQueryAction("filter", {});
  const shifts = filterShifts.data;

  useEffect(() => {
    setLoading(true);
    readString(content, {
      worker: true,
      header: true,
      delimiter: ";",
      encoding: "iso-8859-1",
      complete: (result) => {
        const headers = result.meta.fields || [];
        const dateHeaders = headers.filter((header) =>
          header.match(/^\d{2}\/\d{2}\/\d{4}/)
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsedData = (result.data as any[])
          .filter(
            (item) => item?.Modal !== "CAR" && item["Pra�a"] !== undefined
          )
          .map((item) => {
            const plazaDecode = decodeSpecialChars(item["Pra�a"] || "");
            const shiftDecode = decodeSpecialChars(item["Per�odo"] || "");
            const plazaParts = plazaDecode.split(" - ");
            const subPlaza = plazaParts.length > 1 ? plazaParts[1] : "";
            const plazaName = plazaParts[0] || "";

            const shiftId = shifts?.find(
              (shift) => shift.name === shiftDecode
            )?.id;
            const subPlazaId = plazas?.find(
              (plaza) =>
                plaza?.name === subPlaza && plaza?.plaza?.name === plazaName
            )?.id;

            const vacancies = dateHeaders.map((date) => {
              const [vacancy] = (item[date] || "").split(" - ");
              return {
                date: date.split(" - ")[0].replace(/\//g, "-"),

                surplus: 0,
                vacancies: parseInt(vacancy, 10) || 0,
              };
            });

            return {
              subPraza: subPlaza,
              period: shiftDecode,
              vacancy: vacancies,
              shiftId,
              subPlazaId,
              plaza: plazaName,
            };
          });

        const grouped = parsedData.reduce(
          (acc, item) => {
            const key = item.subPraza;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          },
          {} as { [key: string]: CSVData[] }
        );

        setGroupedData(grouped);
      },
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, readString]);

  const modifyExistingCSV = () => {
    try {
      const modifiedData = Object.values(groupedData)
        .flat()
        .map((item) => {
          return item.vacancy.map((vaga) => ({
            turno: item?.shiftId?.toString() ?? "",
            subpraca: item.subPlazaId?.toString() ?? "",
            data: vaga.date,
            vagas: vaga.vacancies.toString(),
            excedente: vaga.surplus.toString(),
          }));
        })
        .flat();

      const csv = jsonToCSV(modifiedData, { delimiter: "," });

      onContentChange(fileName, csv);

      console.log("CSV atualizado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar o CSV:", error);
    }
  };

  const handleChangeSurplus = (
    subPlaza: string,
    date: string,
    shift: string,
    value: number,
    plaza?: string
  ) => {
    setGroupedData((prevGroupedData) => {
      const updatedGroupedData = { ...prevGroupedData };
      if (updatedGroupedData[subPlaza]) {
        updatedGroupedData[subPlaza] = updatedGroupedData[subPlaza].map(
          (item) => {
            if (item.period === shift && item?.plaza === plaza) {
              return {
                ...item,
                vacancy: item.vacancy.map((vacancy) => {
                  if (vacancy.date === date) {
                    return {
                      ...vacancy,
                      surplus: value,
                    };
                  }
                  return vacancy;
                }),
              };
            }
            return item;
          }
        );
      }
      return updatedGroupedData;
    });
  };

  const isWedukaCSV = Object.keys(groupedData).length > 0;

  return (
    <>
      {loading && <LoadingLogo />}

      {isWedukaCSV && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Text> {fileName}</Text>
          {Object.keys(groupedData).map((subPraza) => (
            <Box
              key={subPraza}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Praça: {groupedData[subPraza][0].plaza}
                </Text>
                <Text
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Sub-Praça: {subPraza}
                </Text>
              </Box>
              {groupedData[subPraza].map((item) => (
                <Accordion
                  key={item.period + subPraza}
                  allowMultiple
                  allowToggle
                >
                  <AccordionItem>
                    <AccordionButton>
                      <Box key={item.period}>
                        <Text
                          sx={{
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          Período: {item.period}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <DataTableWeduka
                        subPraza={subPraza}
                        period={item.period}
                        vacancy={item.vacancy}
                        handleChangeSurplus={handleChangeSurplus}
                        plaza={item.plaza}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ))}
            </Box>
          ))}
          <Button
            marginTop={2}
            onClick={() => {
              modifyExistingCSV();
            }}
          >
            Salvar formatação da planilha Weduka
          </Button>
        </Box>
      )}
    </>
  );
};

export const CSVContentWedukaForm = Component;
