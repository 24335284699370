import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AccountLimits } from "../../store/company.store";
import { AccountLimit } from "../../dto/company-bank-limit-dto";
import { LIMIT_TYPE } from "../../enums/bank-limit";
import { TransactionLimitTd } from "./transaction-limit-td";
type KeyOfLimitType = keyof typeof LIMIT_TYPE;

type IPropsAux = {
  accountLimits: AccountLimits;
  idAccount: number;
};

export function AccountLimitsAccordion({
  accountLimits,
  idAccount,
}: IPropsAux) {
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      h="full"
      gap={8}
    >
      <Flex flexDir="column" w="full" gap={8} flex={1}>
        <Accordion allowMultiple>
          <LimitItem
            title="Limite PIX para PF"
            limits={accountLimits.limitPIXtoPF}
            idAccount={idAccount}
          ></LimitItem>
          <LimitItem
            title="Limite PIX para PJ"
            limits={accountLimits.limitPIXtoPJ}
            idAccount={idAccount}
          ></LimitItem>
          <LimitItem
            title="Limite P2P para PF"
            limits={accountLimits.limitP2PtoPF}
            idAccount={idAccount}
          ></LimitItem>
          <LimitItem
            title="Limite P2P para PJ"
            limits={accountLimits.limitP2PtoPJ}
            idAccount={idAccount}
          ></LimitItem>
          {accountLimits.limitTEDtoPF && (
            <LimitItem
              title="Limite TED para PF"
              limits={accountLimits.limitTEDtoPF}
              idAccount={idAccount}
            ></LimitItem>
          )}
          {accountLimits.limitTEDtoPJ && (
            <LimitItem
              title="Limite TED para PJ"
              limits={accountLimits.limitTEDtoPJ}
              idAccount={idAccount}
            ></LimitItem>
          )}
        </Accordion>
      </Flex>
    </Flex>
  );
}

type IPropsAux2 = {
  title: string;
  limits: AccountLimit[];
  idAccount: number;
};

function LimitItem({ title, limits, idAccount }: IPropsAux2) {
  return (
    <AccordionItem>
      <Heading fontSize="md">
        <AccordionButton py={6}>
          <Box as="span" flex="1" textAlign="left" fontWeight="semibold">
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Heading>
      <AccordionPanel pb={4}>
        {limits?.length > 0 ? (
          <TableFactory {...{ limits, idAccount }} />
        ) : (
          <Text fontSize="md">Não foi possível obter as informações.</Text>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}

type IPropsAux3 = {
  limits: AccountLimit[];
  idAccount: number;
};

function TableFactory({ limits, idAccount }: IPropsAux3) {
  const accountLimitsType = Object.keys(LIMIT_TYPE) as KeyOfLimitType[];

  return (
    <TableContainer
      w="full"
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      p={8}
      gap={8}
      shadow="base"
      justifyContent="space-between"
      display="flex"
      flexDir="column"
      bg="whiteAlpha.800"
    >
      <Table variant="striped" colorScheme="green">
        <Thead>
          <Tr>
            <Th p={0}></Th>
            <Th textAlign="center">Limite diário por transação</Th>
            <Th textAlign="center">Limite diário total</Th>
            <Th textAlign="center">Limite mensal</Th>
            <Th textAlign="center">Limite noturno total</Th>
            <Th textAlign="center">Limite noturno por transação</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td p={0} textAlign="center">
              Total
            </Td>
            {accountLimitsType.map((limitType) => (
              <TransactionLimitTd
                key={limitType}
                {...{
                  limits,
                  limitType,
                  amountType: "accountLimit",
                  field: "Total",
                  idAccount,
                }}
              />
            ))}
          </Tr>
          <Tr>
            <Td p={0} textAlign="center">
              Disponível
            </Td>
            {accountLimitsType.map((limitType) => (
              <TransactionLimitTd
                key={limitType}
                {...{
                  limits,
                  limitType,
                  amountType: "availableLimit",
                  field: "Disponível",
                  idAccount,
                }}
              />
            ))}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
}
