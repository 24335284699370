import React, { useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { Download } from "@phosphor-icons/react";
import { useTransactionsStore } from "../../../store/transaction.store";

const Component = ({ transactionId }: { transactionId: number }) => {
  const [isLoading, setIsLoading] = useState(false);
  const generatePdf = useTransactionsStore(
    (state) => state.generateTransactionPdf
  );
  const toast = useToast();

  async function handleDownload(id: number) {
    if (id) {
      try {
        setIsLoading(true);
        await generatePdf(id);
      } catch (error) {
        toast({
          status: "error",
          title: "Erro ao fazer download do PDF",
          description: `Tente novamente mais tarde ou entre em contato com o suporte.`,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <Button
      bg="green.600"
      w="max-content"
      color="whiteAlpha.900"
      _hover={{ bg: "green.700" }}
      size="md"
      alignSelf="flex-end"
      isDisabled={isLoading}
      isLoading={isLoading}
      onClick={() => handleDownload(transactionId)}
    >
      <Download weight="bold" size={24} />
    </Button>
  );
};

export const TransactionDownloadButton = React.memo(Component);
