import {
  UseQueryResult,
  useMutation,
  useQuery as useBaseQuery,
  useQueryClient,
  QueryKey,
  QueryFunction,
  UseMutationResult,
} from "@tanstack/react-query";
import { Service } from "./base-crud";

export type MutateFunction = (...args: unknown[]) => void;
export type MutationResult<T> = Omit<UseMutationResult<T>, "mutate">;

export const useCachedService = <T>(service: Service<T>, queryKey: string) => {
  const queryClient = useQueryClient();

  function useQuery(key: QueryKey, func: QueryFunction): UseQueryResult<T[]> {
    return useBaseQuery(key, func);
  }

  function useQueryAction(
    action: string,
    params: unknown
  ): UseQueryResult<T[]> {
    return useQuery([queryKey, action, params], () => service[action](params));
  }

  function useMutationAction(
    action: string,
    onSucess?: () => void,
    onError?: (error: string) => void
  ): [MutateFunction, MutationResult<T>] {
    let params: unknown[];
    const { mutate: mutateAction, ...watchers } = useMutation(
      async () => {
        return await service[action](...params);
      },
      {
        onSuccess: () => {
          if (onSucess) onSucess();
          return queryClient.invalidateQueries([queryKey]);
        },
        onError: (error: { message: string }) => {
          if (onError) onError(error.message);
        },
      }
    );
    function mutate(...args: unknown[]): void {
      params = args;
      mutateAction();
    }
    return [mutate, watchers as MutationResult<T>];
  }

  return {
    useQueryAction,
    useMutationAction,
  };
};
