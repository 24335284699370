import { Flex, Heading } from "@chakra-ui/layout";
import { SubMenuButton } from "./sub-menu-button";
import { useLocation } from "react-router-dom";
import { useCurrentUserStore } from "../../../../store/current-user.store";

export function SubMenu() {
  const { pathname } = useLocation();
  const me = useCurrentUserStore((state) => state.user);
  return (
    <Flex
      p={4}
      justifyContent="flex-start"
      flexDir="column"
      bg="whiteAlpha.800"
      borderRadius={8}
      h="min-content"
      gap={4}
      minW="sm"
      shadow="base"
      border="1px"
      borderColor="gray.300"
    >
      <Heading size="md">Configurações</Heading>

      <Flex w="full" justifyContent="flex-start" flexDir="column" gap={2}>
        <SubMenuButton
          to="/settings"
          isSelected={pathname === "/settings"}
          fontSize="md"
        >
          Atualizar perfil
        </SubMenuButton>
        <SubMenuButton
          to="/settings/add-balance"
          isSelected={pathname.includes("add-balance")}
          fontSize="md"
        >
          Adicionar saldo
        </SubMenuButton>
        {me?.company?.scheduledSplitFlow && (
          <SubMenuButton
            to="/settings/jarvis-login"
            isSelected={pathname.includes("jarvis-login")}
            fontSize="md"
          >
            Login Jarvis
          </SubMenuButton>
        )}
      </Flex>
    </Flex>
  );
}
