import { UserFeedbackQuestionDto } from "../../dto/user-feedback-question-dto";
import { getWithAuth } from "../basicService";
import { useBaseCrud } from "./base-crud";

const baseURL = "/api/v1/user-feedback-question";

const disableQuetion = async (id: number) => {
  await getWithAuth(`${baseURL}/disable/${id}`);
};

const userFeedbackQuestionService = {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  ...useBaseCrud<UserFeedbackQuestionDto>(baseURL),
  disableQuetion,
};

export default userFeedbackQuestionService;
