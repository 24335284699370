import { VStack, Heading, Badge, Flex, Box } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  TableContainer,
  Input,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Table,
  Spinner,
  Tfoot,
} from "@chakra-ui/react";
import { Pagination } from "../../../components/pagination";
import { Link, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getWithAuth } from "../../../services/basicService";
import {
  DockIndividualStatusColors,
  DockIndividualsList,
} from "../../../dto/docker-individuals-dto";

export function AppUsers() {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    limit: "10",
  });

  const page = Number(searchParams.get("page"));
  const documentParam = searchParams.get("document");

  const { data, isLoading } = useQuery<DockIndividualsList>({
    queryKey: ["app-users", page, documentParam],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        page: String(page),
        limit: "10",
      });

      if (documentParam !== null) queryParams.set("document", documentParam);

      const response = await getWithAuth(
        `/api/v1/userExternal/app-users/?${queryParams.toString()}`
      );

      return response?.data;
    },
    keepPreviousData: true,
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<{ document: string }>({
    defaultValues: {
      document: documentParam || undefined,
    },
  });

  function handleSearch(data: { document: string }) {
    if (data.document.length > 0) {
      return setSearchParams((prev) => {
        prev.set("page", "1");
        prev.set("document", data.document);
        return prev;
      });
    }
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.delete("document");
      return prev;
    });
  }

  return (
    <VStack gap={8} flex={1} alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Usuários aplicativo Trampay
      </Heading>

      <VStack w="full" h="full" gap={6} borderRadius={8}>
        <Box
          w="full"
          display="flex"
          flexDir="column"
          gap={8}
          flex={1}
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
        >
          <Flex
            as="form"
            w="full"
            gap={4}
            onSubmit={handleSubmit(handleSearch)}
            flexDir="column"
          >
            <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
              Filtros
            </Heading>
            <Flex w="full" gap={4}>
              <FormControl
                as="fieldset"
                isInvalid={!!errors.document}
                maxW="3xl"
              >
                <Input
                  {...register("document")}
                  placeholder="Preencha o documento completo..."
                  size="lg"
                  maxW="3xl"
                />
                {errors.document ? (
                  <FormErrorMessage>{errors.document.message}</FormErrorMessage>
                ) : null}
              </FormControl>
              <Button
                type="submit"
                title="search"
                size="lg"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Filtrar
              </Button>
            </Flex>
          </Flex>
          <TableContainer
            w="full"
            pb={4}
            gap={8}
            justifyContent="space-between"
            display="flex"
            flexDir="column"
            style={{
              containerType: "inline-size",
            }}
          >
            <Table variant="striped" colorScheme="green" layout="auto">
              <Thead>
                <Tr>
                  <Th flex={1} textAlign="center">
                    Nome
                  </Th>
                  <Th textAlign="center">Documento</Th>
                  <Th textAlign="center">Nome da mãe</Th>
                  <Th textAlign="center">Número da conta</Th>
                  <Th textAlign="center">Email</Th>
                  <Th textAlign="center">Status</Th>
                </Tr>
              </Thead>
              {!isLoading ? (
                <Tbody>
                  {data?.items.map((user) => (
                    <Tr key={user.id}>
                      <Td textAlign="center">{user.name}</Td>
                      <Td textAlign="center">{user.document}</Td>
                      <Td textAlign="center">{user.motherName}</Td>
                      <Td textAlign="center">{user.accountNumber}</Td>
                      <Td textAlign="center">{user.email}</Td>
                      <Td textAlign="center">
                        <Badge
                          variant="solid"
                          px={4}
                          colorScheme={DockIndividualStatusColors[user?.status]}
                        >
                          {user.status ? user.status : "undefined"}
                        </Badge>
                      </Td>
                      <Td w="10%" textAlign="center">
                        <Button
                          as={Link}
                          to={`details/${user.id}/${user.document}`}
                          size="sm"
                        >
                          Detalhes
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              ) : (
                <Tfoot>
                  <Tr>
                    <Td colSpan={6}>
                      <Flex flex={1} justifyContent="center">
                        <Spinner />
                      </Flex>
                    </Td>
                  </Tr>
                </Tfoot>
              )}
            </Table>
          </TableContainer>
          <Flex w="full" justifyContent="center" gap={3}>
            <Pagination
              page={page}
              setPage={(page) =>
                setSearchParams((prev) => {
                  prev.set("page", String(page));
                  return prev;
                })
              }
              totalPages={data?.totalPages || 1}
            />
          </Flex>
        </Box>
      </VStack>
    </VStack>
  );
}
